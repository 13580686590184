import React, { useEffect } from "react";
import { useFieldArray } from "react-hook-form";

import IconClose from "../../assets/img/icon_close.png";

export default function Resume_project({ register, control, careerIndex, setValue }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `resume_career.${careerIndex}.career_proj`,
  });

  // When there are multiple fields, deleting the middle field modifies the field number value.
  useEffect(() => {
    for (let i = 0; i < fields.length; i++) {
      setValue(`resume_career[${careerIndex}].career_proj[${i}].proj_no`, i + 1);
    }
  }, [fields]);

  const handleAddProject = (careerIndex) => {
    append(
      {
        proj_no: fields.length + 1,
        proj_name: "",
        proj_start: "",
        proj_end: "",
        proj_tools: "",
        proj_desc: "",
      },
      careerIndex,
    );
  };

  return (
    <>
      {fields.map((project, projectIndex) => (
        <div className="certification_field" key={project.id}>
          <div className="input_wrapper">
            <input
              type="text"
              className="input label"
              {...register(`resume_career[${careerIndex}].career_proj[${projectIndex}].proj_name`)}
              defaultValue={project.proj_name}
              placeholder="프로젝트명"
            />
            <div className="department">
              <input
                type="date"
                className="input"
                {...register(`resume_career[${careerIndex}].career_proj[${projectIndex}].proj_start`)}
                defaultValue={project.proj_start}
                placeholder="시작일"
                max={'9999-12-31'}
              />
              <input
                type="date"
                className="input"
                {...register(`resume_career[${careerIndex}].career_proj[${projectIndex}].proj_end`)}
                defaultValue={project.proj_end}
                placeholder="종료일"
                max={'9999-12-31'}
              />
            </div>
            <input
              type="text"
              className="input label"
              {...register(`resume_career[${careerIndex}].career_proj[${projectIndex}].proj_tools`)}
              defaultValue={project.proj_tools}
              placeholder="사용 툴"
            />
            <input
              type="text"
              className="input label"
              {...register(`resume_career[${careerIndex}].career_proj[${projectIndex}].proj_order`)}
              defaultValue={project.proj_order}
              placeholder="발주처"
            />
            <button type="button" className="btn_delete" onClick={() => remove(projectIndex)}>
              <img src={IconClose} alt="close_icon" />
            </button>
          </div>
          <textarea
            cols={30}
            rows={10}
            className="textarea"
            {...register(`resume_career[${careerIndex}].career_proj[${projectIndex}].proj_desc`)}
            defaultValue={project.proj_desc}
            placeholder="상세내용을 입력해주세요."
          />
        </div>
      ))}
      <button type="button" className="com_btn blue" onClick={handleAddProject}>
        <span className="addition">+</span>프로젝트 추가
      </button>
    </>
  );
}
