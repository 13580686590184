import React from "react";
//component
import AiMatchTab from "../../components/myPage/_AiTab";

import "../../assets/scss/page/match.scss";

function AIMatch() {
  return (
    <div className="container">
      <h2 className="com_pg_tit">AI 매칭 지원 현황</h2>
      <AiMatchTab />
    </div>
  );
}

export default AIMatch;
