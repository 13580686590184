import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthenticatedQueryJson } from "../../../../../hooks/useAPI";
import { useForm } from "react-hook-form";
import { updateBackend } from "../../../../../api";
import { AuthContext } from "../../../../../context/Auth";
import { toast } from "react-toastify";

const MainAdmin = () => {
  const { adminUser, updateAdminUser } = useContext(AuthContext);

  const navigate = useNavigate();

  const [errors, setErrors] = useState({});

  const [isUpdating, setIsUpdating] = useState(false);

  const { data, isFetching, refetch } = useAuthenticatedQueryJson("admin-info", "/admin/my-info");

  const {
    formState: { touchedFields },
    handleSubmit,
    getValues,
    register,
    reset,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (!isFetching && data) {
      console;
      updateAdminUser(data.data.data_list[0]);
    }
    reset();
  }, [isFetching]);

  const handleUpdate = async () => {
    setIsUpdating(true);

    let updates = {};

    if (touchedFields["admin_username"] && adminUser["admin_username"] !== getValues("admin_username"))
      updates = { admin_username: getValues("admin_username") };

    if (touchedFields["new_user_pwd"] && touchedFields["new_user_pwd_match"])
      updates = { admin_pwd: getValues("new_user_pwd") };

    adminUser &&
      Object.keys(updates).length > 0 &&
      (await updateBackend("/admin/update-info", {
        data: { ...updates, admin_seq: adminUser["admin_seq"] },
        headers: { "Content-Type": "application/json" },
      }).then(async (data) => {
        console.log(data);
        await refetch();
        if (data.status === 200) toast("정보를 성공적으로 업데이트했습니다.");
      }));

    setIsUpdating(false);
  };

  return (
    <main>
      <h2 className="adm_com_pg_tit2 bottom_line">운영자 관리 {isFetching && <i className="gg-spinner-alt" />}</h2>
      <form className="adm_com_pg_content" onSubmit={handleSubmit(handleUpdate)}>
        <h2 className="sub_tit">- 관리자 계정 관리 </h2>
        <table className="adm_com_board_list type_row">
          <colgroup>
            <col width="10%" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              {/* User ID */}
              <th>사용자 ID</th>
              <td>{adminUser["admin_id"]}</td>
            </tr>
            <tr>
              {/* Username */}
              <th>사용자 이름</th>
              <td className="input_wrap">
                <input
                  type="text"
                  defaultValue={adminUser["admin_username"]}
                  {...register("admin_username")}
                  className="input"
                />
              </td>
            </tr>
            <tr>
              {/* New Password */}
              <th>새로운 비밀번호</th>
              <td className="input_wrap">
                <li className="input_field">
                  <input
                    type="password"
                    className="input"
                    placeholder="영문, 숫자, 특수문자 포함 8~16 자리"
                    {...register("new_user_pwd", {
                      minLength: 8,
                      maxLength: 32,
                    })}
                  />
                  {errors["mismatchedPassword"] && <span className="error_txt">* 비밀번호가 일치하지 않습니다.</span>}
                </li>
              </td>
            </tr>
            <tr>
              {/* Password Verify*/}
              <th>비밀번호 재입력</th>
              <td className="input_wrap">
                <li className="input_field">
                  <input
                    type="password"
                    className="input"
                    placeholder="영문, 숫자, 특수문자 포함 8~16 자리"
                    {...register("new_user_pwd_match", {
                      minLength: 8,
                      maxLength: 32,
                      validate: (val) => {
                        if (
                          (touchedFields["new_user_pwd"] || touchedFields["new_user_pwd_match"]) &&
                          getValues("new_user_pwd") !== val
                        ) {
                          setErrors((prev) => ({ ...prev, mismatchedPassword: true }));
                          return false;
                        }
                      },
                    })}
                  />
                  {errors["mismatchedPassword"] && <span className="error_txt">* 비밀번호가 일치하지 않습니다.</span>}
                </li>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="adm_com_btn_wrap center bottom">
          <button className="adm_com_btn gray" type="submit">
            수정하기 {isUpdating && <i className="gg-spinner-alt" />}
          </button>
          <button className="adm_com_btn gray" onClick={() => navigate(-1)} type="button">
            돌아가기
          </button>
        </div>
      </form>
    </main>
  );
};
export default MainAdmin;
