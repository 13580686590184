import React, { useEffect } from "react";
import { useFieldArray } from "react-hook-form";

import IconClose from "../../assets/img/icon_close.png";

export default function Resume_education({ control, register, setValue }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "resume_education",
  });

  // When there are multiple fields, deleting the middle field modifies the field number value.
  useEffect(() => {
    for (let i = 0; i < fields.length; i++) {
      setValue(`resume_education[${i}].edu_no`, i + 1);
    }
  }, [fields]);

  return (
    <>
      <li className="title_field">교육 </li>
      {fields.map((field, index) => (
        <li className="education_detail_field bottom_line" key={field.id}>
          <div className="input_wrapper">
            <div className="input_box">
              <input
                type="text"
                {...register(`resume_education[${index}].edu_name`)}
                className="input"
                placeholder="교육명"
                defaultValue={field.edu_name}
              />
              <input
                type="text"
                {...register(`resume_education[${index}].edu_insti`)}
                className="input"
                placeholder="교육기관"
                defaultValue={field.edu_insti}
              />
            </div>
            <div className="date">
              <input
                type="date"
                {...register(`resume_education[${index}].edu_start`)}
                className="input"
                defaultValue={field.edu_start}
                max={'9999-12-31'}
              />
              <input
                type="date"
                {...register(`resume_education[${index}].edu_end`)}
                className="input"
                defaultValue={field.edu_end}
                max={'9999-12-31'}
              />
              <button className="btn_delete" type="button" onClick={() => remove(index)}>
                <img src={IconClose} alt="close_icon" />
              </button>
            </div>
          </div>
          <input
            type="text"
            className="input description"
            {...register(`resume_education[${index}].edu_desc`)}
            defaultValue={field.edu_desc}
            placeholder="상세설명을 입력해주세요."
          />
        </li>
      ))}
      <button
        className="com_btn blue"
        type="button"
        onClick={() =>
          append({
            edu_no: fields.length + 1,
            edu_name: "",
            edu_insti: "",
            edu_start: "",
            edu_end: "",
            edu_desc: "",
          })
        }>
        <span className="addition">+</span> 추가
      </button>
    </>
  );
}
