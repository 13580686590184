import React, { useContext, useState } from "react";
import Modal from "../component/Modal";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../context/Auth";
import { useForm } from "react-hook-form";

function Login() {
  const [formSubmitting, setFormSubmitting] = useState(false);

  const navigate = useNavigate();

  const { adminLogin } = useContext(AuthContext);

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm();
  const onValidSubmit = async (data) => {
    setFormSubmitting(true);
    const isLoggedIn = await adminLogin(data.admin_username, data.admin_pwd).catch((err) => {
      console.log(err);
      return false;
    });
    setFormSubmitting(false);

    if (isLoggedIn) navigate("/boffice");
    else setFailedLogin(true);
  };

  const [failedLogin, setFailedLogin] = useState(false);

  const [modalAlert, setModalAlert] = useState(false);
  return (
    <main className="p_adm_login">
      <form className="box" onSubmit={handleSubmit(onValidSubmit)}>
        <h1 className="site_name">엔지니어스 Management System</h1>
        <h2 className="title">관리자 로그인</h2>
        <div className="adm_com_input_wrap">
          <section className="sec_input">
            <input
              type="text"
              placeholder="ID(이메일 입력)"
              className="input"
              required
              {...register("admin_username", {
                required: true,
              })}
            />
          </section>
          <section className="sec_input">
            <input
              type="password"
              placeholder="PW"
              className="input"
              required
              {...register("admin_pwd", {
                required: true,
                minLength: 8,
                maxLength: 32,
              })}
            />
          </section>
        </div>
        {(errors.admin_username || errors.admin_pwd || failedLogin) && (
          // <span className="txt_required">
          //   {/* Incorrect login information. Please correct the relevant fields and try again. */}
          //   잘못된 로그인 정보입니다. 관련 필드를 수정하고 다시 시도하십시오.
          // </span>
          <p className="error_txt">
            잘못된 로그인 정보입니다. <br /> 다시 입력해주세요.
          </p>
        )}
        <section className="check">
          <input type="checkbox" id="chk01" className="adm_com_chk" />
          <label htmlFor="chk01">ID저장</label>
        </section>
        <div className="adm_com_btn_wrap center">
          <button className="adm_com_btn m blue">로그인 {formSubmitting && <i className="gg-spinner-alt" />}</button>
        </div>
      </form>
      {/* Modal */}
      <Modal open={modalAlert} close={() => setModalAlert(false)}>
        <div className="content_container scroll">
          <p>
            5회 이상 ID 또는 PW 입력오류 입니다.
            <br />
            관리자에게 문의하세요.
          </p>
        </div>
        <div className="btn_container">
          <button className="adm_com_btn orange xs">확인</button>
        </div>
      </Modal>
    </main>
  );
}

export default Login;
