import React, { useState } from "react";
import Paging from "../../../component/Paging";
import Table from "../../../../screens/components/boffice/Table";
import MemberFilter from "../../../component/MemberFilter";
import { useCustomQuery } from "../../../../../../hooks/useAPI";

const columns = {
  user_seq: "번호",
  user_id: "아이디",
  user_name: "사용자 이름",
  user_email: "이메일",
  user_mobile: "전화번호",
  application_dtm: "승인일자",
  mentoring_cnt: "상품 등록 수",
  mentee_cnt: "총 멘티 수",
  // * If it is a link, it passes an object
  detail: {
    label: "상세",
    getLink: (item) => "/boffice/mentoring/manage/member/" + item["user_seq"],
    linkLabel: "상세",
  },
};

export default function MentorList() {
  const [page, setPage] = useState(1);
  const [memberPerPage, setMemberPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const [paramArguments, setParamArguments] = useState("");

  const [mentorList, setMentorList] = useState([]);

  const { isFetching } = useCustomQuery(
    `/mentoring/mentor/list?per_page=${memberPerPage}&curr_page=${page}&` + paramArguments,
    {
      queryKey: ["getMentors/", paramArguments, page, memberPerPage],
      select: (res) => res?.data,
      onSuccess: async (data) => {
        setTotalCount(data.total_cnt);
        setMentorList(data.data_list);
      },
      refetchOnWindowFocus: false,
    },
  );

  const handlePageClick = (toPage) => {
    if (Number.isInteger(toPage)) return setPage(toPage);

    switch (toPage) {
      case "first": {
        setPage(1);
        break;
      }
      case "next": {
        page < Math.floor(totalCount / memberPerPage) && setPage((prev) => prev + 1);
        break;
      }
      case "prev": {
        page > 1 && setPage((prev) => prev - 1);
        break;
      }
      case "last": {
        setPage(Math.ceil(totalCount / memberPerPage));
        break;
      }
    }
  };

  return (
    <main>
      <h2 className="adm_com_pg_tit2 bottom_line"> 멘토링 관리</h2>

      <div className="adm_com_pg_content">
        <h2 className="sub_tit">- 멘토 회원 관리 </h2>
        <MemberFilter
          columns={columns}
          dateLabel={"신청일자"}
          dataPerPage={memberPerPage}
          setDataPerPage={setMemberPerPage}
          defaultSearchColumn={"user_name"}
          setParamArguments={setParamArguments}
        />
        <div className="sub_tit">
          <span className="txt flex-start">
            {totalCount} 건 {isFetching && <i className="gg-spinner-alt" />}
          </span>
        </div>
        <Table data={mentorList} columns={columns} checked={false} handleCheck={null} />
        <Paging handlePageClick={handlePageClick} page={page} totalPageCount={Math.ceil(totalCount / memberPerPage)} />
      </div>
    </main>
  );
}
