import React, { useContext } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import CompanyInfo from "../views/screens/page/company/Info";
import CompanyJob from "../views/screens/page/company/Job";
import CompanyJobDetail from "../views/screens/page/company/JobDetail";
import CompanyJobDetailEdit from "../views/screens/page/company/JobDetailEdit";
import CompanyRecruit from "../views/screens/page/company/Recruit";
import CompanyVision from "../views/screens/page/company/Vision";
import CompanyCs from "../views/screens/page/company/Cs";
import CompanyNotice from "../views/screens/page/company/Notice";
import CompanyRecruitDetail from "../views/screens/page/company/RecruitDetail";
import CompanyResume from "../views/screens/page/company/Resume";
import CompanySideBar from "../views/screens/components/miscellaneous/CompanySideBar";

import "../views/screens/assets/scss/page/company.scss";
import "../views/screens/assets/scss/page/mypage.scss";
import "../views/screens/assets/scss/page/member.scss";
import { AuthContext } from "../../context/Auth";

function CompanyRoutes() {
  const PageStyle = {
    "/Company/Info": "p_company_info",
    "/Company/Job": "p_company_job",
    "/Company/Recruit": "p_company_recruit p_company_recruit_status",
    "/Company/Vision": "p_myFit",
    "/Company/Cs": "p_cs",
    "/Company/Notice": "p_notice",
    "/Company/RecruitDetail": "p_company_recruit_detail",
    "/Company/Resume": "p_company_resume",
    "/Company/RecruitStatus": "p_company_recruit_status",
  };

  const currentLocation = useLocation();

  const { user } = useContext(AuthContext);

  if (user && user.user_type === "PSN") return <Navigate to={"/member/corporate-only"} />;
  if (user.user_status !== "US10") {
    if (user.user_status === "US00") return <Navigate to={"/member/approving"} />;
    if (user.user_status === "US50") return <Navigate to={"/member/no-approve"} />;
  }

  const currentStyle = Object.keys(PageStyle).find((key) => currentLocation.pathname.startsWith(key));

  const styleClass = currentLocation.pathname.startsWith("/Company/Resume")
    ? PageStyle["Resume"]
    : PageStyle[currentStyle];
  return (
    <>
      <main className={`com_mypage com_company ${styleClass} `}>
        <div className="com_center_wrap">
          <CompanySideBar />
          <Routes>
            <Route path="/Info" element={<CompanyInfo />} />
            <Route path="/Job" element={<CompanyJob />} />
            <Route path="/Job/Detail/:recruit_seq" element={<CompanyJobDetail />} />
            <Route path="/Job/Detail/edit/:recruit_seq" element={<CompanyJobDetailEdit />} />
            <Route path="/Recruit" element={<CompanyRecruit />} />
            <Route path="/Vision" element={<CompanyVision />} />
            <Route path="/Cs" element={<CompanyCs />} />
            <Route path="/Notice" element={<CompanyNotice />} />
            <Route path="/RecruitDetail/:recruit_seq" element={<CompanyRecruitDetail />} />
            <Route path="/Resume" element={<CompanyResume />} />
            <Route path="/Resume/:resume_seq" element={<CompanyResume />} />
          </Routes>
        </div>
      </main>
    </>
  );
}

export default CompanyRoutes;
