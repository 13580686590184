import React, { useState } from "react";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { mutateBackend, mutateBackendFormData, updateBackend } from "../../../../../api";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import Upload from "../../../screens/components/Upload";
import Tag from "../../../screens/components/company/recruit/Tag";
import PopCreateTag from "../../../screens/components/company/recruit/_popCreateTag";

export default function AdminSeminarDetails() {
  const dateTime = new Date().toLocaleString("sv", { timeStyle: "short", dateStyle: "short" }).replace(" ", "T");
  const defaultValues = {
    semin_title: "",
    semin_sub_title: "",
    semin_tag: "",
    semin_address: "",
    semin_address_details: "",
    semin_limit: "",
    semin_price: "",
    semin_date: dateTime,
    reg_start_dtm: new Date().toLocaleDateString("sv"),
    reg_end_dtm: new Date().toLocaleDateString("sv"),
    semin_status: "Y",
    semin_content: "",
    semin_terms: "",
  };
  const navigate = useNavigate();
  const { id = "new" } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [openCreateTag, setOpenCreateTag] = useState(false);
  const [localFiles, setLocalFiles] = useState([]);

  const { handleSubmit, register, setValue, reset, watch, getValues } = useForm({
    defaultValues,
    mode: "all",
  });

  const { data: seminar = {} } = useCustomQuery(`/seminar?semin_seq=${id}`, {
    queryKey: ["getSeminar", id],
    select: (res) => res?.data?.seminars?.[0] ?? {},
    enabled: +id > 0,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      reset({
        ...defaultValues,
        semin_title: data?.semin_title,
        semin_sub_title: data?.semin_sub_title,
        semin_tag: data?.semin_tag,
        semin_address: data?.semin_address,
        semin_address_details: data?.semin_address_details,
        semin_limit: data?.semin_limit,
        semin_price: data?.semin_price,
        semin_date: new Date(data?.semin_date).toLocaleString("sv"),
        reg_start_dtm: new Date(data?.reg_start_dtm).toLocaleDateString("sv"),
        reg_end_dtm: new Date(data?.reg_end_dtm).toLocaleDateString("sv"),
        semin_status: data?.semin_status,
        semin_content: data?.semin_content,
        semin_terms: data?.semin_terms,
      });
    },
  });

  const openLocationModal = useDaumPostcodePopup("https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js");

  const handleAddressComplete = (data) => {
    const fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
    }
    setValue("semin_address", fullAddress);
    setValue("semin_address_details", extraAddress);
  };

  const handleAddTag = (tag) => {
    const tempTag = getValues("semin_tag");
    const tags = tempTag?.length > 0 ? tempTag?.split(",") : [];
    !tags?.includes(tag) && setValue("semin_tag", [...tags, tag].join());
  };

  const handleRemoveTag = (tag) => {
    const tempTag = getValues("semin_tag");
    const tags = tempTag?.length > 0 ? tempTag?.split(",") : [];
    tags?.includes(tag) && setValue("semin_tag", tags?.filter((item) => item !== tag)?.join());
  };

  const handleUpload = async (localFiles = []) => {
    const formData = new FormData();
    localFiles.forEach((file) => {
      formData.append("file", file);
    });
    formData.append("upload_ty", "seminar");

    const response = await mutateBackendFormData("/upload/image", formData)
      .then(async (data) => await data.json())
      .catch(() => ({ messageCode: 400 }));

    if (response.messageCode === 200) {
      console.log("handleUpload", response.data);
      return response?.data?.[0]?.up_file_seq;
    } else {
      console.log("file failed");
      return false;
    }
  };

  const onValidSubmit = async (data) => {
    setIsLoading(true);
    console.log({ data });
    if (+id) {
      const params = { ...data, semin_seq: +id };
      localFiles?.length > 0 && (params.up_file_seq = await handleUpload([...localFiles]));
      const response = await updateBackend("/seminar/details", {
        data: params,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (data) => await data.json())
        .catch((err) => {
          throw new Error(err);
        });
      if (response.messageString === "SUCCESS" && response.messageCode === 200) {
        console.log(response.data);
      } else {
        setIsLoading(false);
        console.log(response);
      }
      reset(defaultValues);
      navigate("/boffice/board/seminar/list");
    } else {
      localFiles?.length > 0 && (data.up_file_seq = await handleUpload());
      const response = await mutateBackend("/seminar/details", {
        data,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (data) => await data.json())
        .catch((err) => {
          throw new Error(err);
        });
      if (response.messageString === "SUCCESS" && response.messageCode === 200) {
        console.log(response.data);
        reset(defaultValues);
        navigate("/boffice/board/seminar/list");
      } else {
        setIsLoading(false);
        console.log(response);
      }
    }
  };

  return (
    <main>
      <h1 className="adm_com_pg_tit2">세미나 관리</h1>

      <h2 className="adm_com_pg_tit">
        <Link to={"/boffice/member/manage/company"} style={{ color: "#034da2", paddingBottom: "10px" }}>
          세미나 관리
        </Link>{" "}
        {" > "} 세부 사항
      </h2>

      <div className="adm_com_pg_content">
        <form onSubmit={handleSubmit(onValidSubmit)}>
          <table className="adm_com_board_list type_row">
            <colgroup>
              <col width="13%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>세미나 명</th>
                <td>
                  <input type="text" defaultValue={seminar?.semin_title} {...register("semin_title", {})} />
                </td>
              </tr>
              <tr>
                <th>부제목</th>
                <td>
                  <input type="text" defaultValue={seminar?.semin_sub_title} {...register("semin_sub_title", {})} />
                </td>
              </tr>
              <tr>
                <th>태그</th>
                <td>
                  <div className="input_box">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenCreateTag(true);
                      }}
                      className="com_btn line s">
                      태그 추가
                    </button>
                    <ul className="com_tag_list">
                      {watch("semin_tag")
                        ?.split(",")
                        ?.map(
                          (tag, i) =>
                            tag && (
                              <Tag
                                handleTagRemove={(e) => {
                                  e.preventDefault();
                                  handleRemoveTag(tag);
                                }}
                                key={i}
                                tag={tag}
                              />
                            ),
                        )}
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <th>썸네일 이미지</th>
                <td>
                  <Upload
                    className="common_upload_admin_btn"
                    title="파일첨부"
                    setFiles={setLocalFiles}
                    files={localFiles}
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {localFiles?.[0]?.name || seminar?.thumbnail}
                </td>
              </tr>
              <tr>
                <th>장소</th>
                <td>
                  <input
                    type="text"
                    defaultValue={seminar?.semin_address}
                    {...register("semin_address", {})}
                    className="input"
                  />
                  <button
                    style={{
                      padding: "13px 15px",
                      color: "black",
                      fontWeight: "bold",
                      backgroundColor: "grey",
                      fontSize: "11px",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      openLocationModal({ onComplete: handleAddressComplete });
                    }}>
                    주소찾기
                  </button>
                  <br />
                  <br />
                  <input
                    type="text"
                    defaultValue={seminar?.semin_address_details}
                    {...register("semin_address_details", {})}
                  />
                </td>
              </tr>
              <tr>
                <th>정원</th>
                <td>
                  <input
                    type="text"
                    className="input"
                    defaultValue={seminar?.semin_limit}
                    {...register("semin_limit", {})}
                  />
                </td>
              </tr>
              <tr>
                <th>금액</th>
                <td>
                  <input
                    type="text"
                    className="input"
                    defaultValue={seminar?.semin_price}
                    {...register("semin_price", {})}
                  />
                </td>
              </tr>
              <tr>
                <th>일정</th>
                <td>
                  <input
                    type="datetime-local"
                    className="input"
                    min={id === "new" && dateTime}
                    value={seminar?.semin_date}
                    {...register("semin_date", {})}
                  />
                </td>
              </tr>
              <tr>
                <th>신청기간</th>
                <td>
                  <input
                    type="date"
                    className="input"
                    min={id === "new" && new Date().toLocaleDateString("sv")}
                    value={seminar?.reg_start_dtm}
                    {...register("reg_start_dtm", {})}
                  />
                  <span style={{ fontWeight: "bold" }}> ~ </span>
                  <input
                    type="date"
                    className="input"
                    min={watch("reg_start_dtm")}
                    value={seminar?.reg_end_dtm}
                    {...register("reg_end_dtm", {})}
                  />
                </td>
              </tr>
              <tr>
                <th>노출여부</th>
                <td>
                  <select
                    name="semin_status"
                    defaultValue={seminar?.semin_status}
                    style={{ width: "10%" }}
                    {...register("semin_status", {})}>
                    <option value="Y">Y</option>
                    <option value="N">N</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>상세설명</th>
                <td>
                  <textarea defaultValue={seminar?.semin_content} {...register("semin_content", {})} />
                </td>
              </tr>
              <tr>
                <th>참여신청/취소 있는</th>
                <td>
                  <textarea defaultValue={seminar?.semin_terms} {...register("semin_terms", {})} />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="adm_com_btn_wrap center bottom">
            <button
              className="adm_com_btn gray"
              onClick={(e) => {
                e.preventDefault();
                reset({});
                navigate("/boffice/board/seminar/list");
              }}>
              돌아가기
            </button>
            <button className="adm_com_btn gray" type="submit">
              수정하기 {isLoading && <i className="gg-spinner-alt" />}
            </button>
          </div>
        </form>
      </div>
      <PopCreateTag
        open={openCreateTag}
        close={() => setOpenCreateTag(false)}
        currentTags={watch("semin_tag")?.split(",")}
        addTag={handleAddTag}
        handleTagRemove={handleRemoveTag}
      />
    </main>
  );
}
