import React from "react";
import { useNavigate } from "react-router-dom";
import { useCustomQuery } from "../../../../hooks/useAPI";

export default function MagazineFilter({
  total_count,
  isFetching,
  limit,
  search_query,
  date_from,
  date_to,
  status,
  handleFilterChange,
  handleResetFilter,
}) {
  const navigate = useNavigate();

  const { data: magCategories } = useCustomQuery(`/category?cate_gr=MAG_CATE`, {
    queryKey: ["getMagCategories/"],
    select: (res) => res?.data.categories,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <div className="adm_com_board_list__top">
        <div className="adm_com_date_select">
          <span className="tit">매거진 세부 사항</span>
        </div>
        <div className="adm_com_input_wrap flex_row">
          <span style={{ fontSize: "10px", fontWeight: "bold", margin: "0px 10px" }}>등록 날짜:</span>
          <input
            type="date"
            value={date_from}
            className="input"
            onChange={(e) => handleFilterChange("date_from", e.target.value)}
          />
          <span style={{ fontWeight: "bold" }}> ~ </span>
          <input
            type="date"
            multiple
            value={date_to}
            className="input"
            onChange={(e) => handleFilterChange("date_to", e.target.value)}
          />
          <span style={{ fontSize: "10px", fontWeight: "bold", margin: "0px 10px 0px 50px" }}>범주:</span>
          <select
            name="comp_explosure"
            style={{ width: "10%" }}
            value={status}
            onChange={(e) => handleFilterChange("category", e.target.value)}>
            <option value="">All</option>
            {magCategories?.map((category) => {
              return (
                <option key={category.cate_seq} value={category.cate_seq}>
                  {category.cate_name}
                </option>
              );
            })}
          </select>
          <span style={{ fontSize: "10px", fontWeight: "bold", margin: "0px 10px 0px 50px" }}>노출:</span>
          <select
            name="comp_explosure"
            style={{ width: "10%" }}
            value={status}
            onChange={(e) => handleFilterChange("exposed_YN", e.target.value)}>
            <option value="">All</option>
            <option value="Y">Y</option>
            <option value="N">N</option>
          </select>
          <input
            type="text"
            className="input name"
            placeholder="찾고있는 것을 입력하십시오"
            value={search_query}
            onChange={(e) => handleFilterChange("search_query", e.target.value)}
          />
          <button style={{ backgroundColor: "lightgray", color: "black", padding: "10px" }} onClick={handleResetFilter}>
            초기화
          </button>
        </div>
      </div>
      <div className="adm_com_btn_wrap between" style={{ marginBottom: "10px" }}>
        <span className="txt flex-start">
          {total_count} 건 {isFetching && <i className="gg-spinner-alt" />}
        </span>{" "}
        <select className="select" value={limit} onChange={(e) => handleFilterChange("limit", e.target.value)}>
          <option value={10}>10개씩 보기</option>
          <option value={20}>20개씩 보기</option>
        </select>
      </div>
      <div className="txt flex-end">
        <button className="adm_com_btn black" onClick={() => navigate("/boffice/board/magazine/details/new")}>
          매거진 등록
        </button>
        <button className="adm_com_btn red">삭제</button>
      </div>
    </>
  );
}
