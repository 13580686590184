import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../../assets/scss/page/member.scss";
import { mutateBackend, mutateBackendFormData } from "../../../../../api";
import { useForm } from "react-hook-form";
import Modal from "../../components/modal";
//component

function Inquiry() {
  const fileRef = useRef();
  const [uploadFile, setUploadFile] = useState("");
  const [modalAlert, setModalAlert] = useState({
    visible: false,
    message: "",
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      board_type: "INQUI",
      cate_seq: "",
      board_title: "",
      board_contents: "",
      phone_no: "",
      agreeStatus: false,
    },
  });

  const handleFileClick = () => {
    fileRef.current.click();
  };

  const handleFileUpload = async (board_seq) => {
    const formData = new FormData();
    formData.append("file", uploadFile);
    formData.append("upload_ty", "inquiry");

    const response = await mutateBackendFormData("/upload/file", formData)
      .then(async (data) => await data.json())
      .catch(() => ({ messageCode: 400 }));
    if (response.messageCode === 200) {
      handleCommunityUpload(
        board_seq,
        response.data.map((obj) => obj?.up_file_seq),
      );
    } else {
      if (response.messageCode === 403) {
        setModalAlert({ visible: true, message: "유효하지 않은 파일 형식입니다.." });
      }
      setModalAlert({ visible: true, message: "첨부파일 등록에 실패했습니다." });
      setUploadFile("");
    }
  };
  const handleCommunityUpload = async (board_seq, ids) => {
    const response = await mutateBackend("/community/upload", {
      data: { board_seq, ids },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (data) => await data.json())
      .catch((err) => {
        throw new Error(err);
      });

    if (response.messageString === "SUCCESS" && response.messageCode === 200) {
      setModalAlert({ visible: true, message: "제출되었습니다." });
      reset();
      setUploadFile("");
    } else {
      console.log("Upload BoardFile failed");
    }
  };

  const onValidSubmit = async (data) => {
    const responseData = await mutateBackend("/community", {
      data: {
        ...data,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    const response = await responseData.json();

    if (response.messageCode === 200) {
      if (uploadFile) {
        handleFileUpload(response.data.communityId);
      } else {
        setModalAlert({ visible: true, message: "제출되었습니다." });
        reset();
      }
    } else {
      console.log("Submit failed");
    }
  };

  return (
    <>
      <form className="container" onSubmit={handleSubmit(onValidSubmit)}>
        <h2 className="com_pg_tit">고객센터</h2>
        <section className="com_pg_tab">
          <Link to="/Mypage/Faq" className="tab">
            자주 묻는 질문
          </Link>
          <Link to="/Mypage/Inquiry" className="tab active">
            1:1 문의
          </Link>
        </section>
        <ul className="com_input_sec type_row">
          <li className="field_box">
            <span className="tit">
              제목<i className="icon_must">*</i>
            </span>
            <div className="input_box">
              <input
                type="text"
                required
                placeholder="Ex: 수수료 환전, 회원정보 관련 등 질문의 제목을 입력해주세요."
                {...register("board_title", {
                  required: true,
                })}
              />
            </div>
          </li>
          <li className="field_box">
            <span className="tit">
              설명<i className="icon_must">*</i>
            </span>
            <div className="input_box">
              <textarea
                required
                placeholder="상세한 문의 사항을 작성해주세요. 답변일은 영업일 기준 3일 이내에 답변 드릴 수 있도록 노력하겠습니다."
                {...register("board_contents", {
                  required: true,
                })}
              />
            </div>
          </li>
          <li className="field_box">
            <span className="tit">연락처</span>
            <div className="input_box">
              <input
                type="text"
                placeholder="“-” 없이 번호만 입력"
                {...register("phone_no", {
                  required: false,
                })}
              />
            </div>
          </li>
          <li className="field_box">
            <span className="tit">첨부 파일</span>
            <div className="input_box">
              <div className="input_wrap">
                <input type="text" value={uploadFile.name || ""} />
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={fileRef}
                  onChange={(e) => setUploadFile(e.target.files[0])}
                />
                <button type="button" className="com_btn blue line s" onClick={() => handleFileClick()}>
                  파일추가
                </button>
              </div>
            </div>
          </li>
        </ul>
        <section className="com_tip_box">
          <h3 className="tit">개인정보 수집 및 이용에 대한 동의 내용</h3>
          ①개인정보 수집 항목: 이메일, 연락처
          <br />
          ②수집목적: 고객식별, 문의 응대, 서비스 품질 향상
          <br />
          ③보유 및 이용기간: 수집 목적이 달성되면 지체없이 모든 개인정보를 파기합니다. <br />
          단, 관계법령에서 일정 기간 정보의 보관을 규정한 경우에 한해 분리 보관 후 파기합니다.
          <br />
          <br />
          *위 동의는 거부할 수 있으며, 거부 시 해당 문의를 처리할 수 없습니다.
        </section>
        <section className="sec_agree">
          <input
            type="checkbox"
            className="com_chk"
            id="agree01"
            {...register("agreeStatus", { required: "약관에 동의해주세요." })}
          />
          <label htmlFor="agree01">위 약관에 동의합니다.</label>
        </section>
        {errors.agreeStatus && <p style={{ color: "red" }}>{errors.agreeStatus.message}</p>}
        <section className="com_btn_wrap center">
          <button type="submit" className="com_btn blue s">
            제출
          </button>
          {/* <button className="com_btn blue line s">취소</button> */}
        </section>
      </form>
      <Modal open={modalAlert.visible} close={() => setModalAlert((prev) => ({ ...prev, visible: false }))}>
        <div className="content_container scroll">
          <p>{modalAlert.message}</p>
        </div>
        <div className="btn_container">
          <button
            className="adm_com_btn orange xs"
            onClick={() => setModalAlert((prev) => ({ ...prev, visible: false }))}>
            확인
          </button>
        </div>
      </Modal>
    </>
  );
}

export default Inquiry;
