/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import "../../assets/scss/page/member.scss";
//component
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import Pagination from "../../components/Pagination";
import Modal from "../../components/modal";
import { useCustomQuery, useAuthenticatedQuery} from "../../../../../hooks/useAPI";
import { deleteBackend, mutateBackend } from "../../../../../api";
import { toast } from "react-toastify";
import DeleteMentoringModal from "../../components/myPage/_PopDelete";
import { AuthContext } from "../../../../../context/Auth";
import MenteeManagement from "./MenteeView/MenteeManagement";


function ProductMentoring() {
  const { user } = useContext(AuthContext);
  const [cateState, setCateState] = useState(0);
  const [per_page, setPerPage] = useState(9);
  const [totalCount, setTotalCount] = useState(0);
  const [curr_page, setCurrPage] = useState(1);
  const [mentoringIds, setMentoringIds] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const cate = [
    { text: "멘토링 상품 관리", value: 0 },
    { text: "멘티 관리", value: 1 },
    { text: "완료된 멘티", value: 2 },
  ];

  const searchParams = new URLSearchParams();

  searchParams.append("curr_page", curr_page);
  searchParams.append("per_page", per_page);

  const queryString = searchParams.toString();
  const endpoint = `/mentoring/my-mentorings?${queryString}`;

  const {
    data: mentoringProducts,
    isFetching: isLoadingMentoring,
    refetch,
  } = useCustomQuery(endpoint, {
    queryKey: ["getMyMentorings"],
    select: (res) => res?.data,
    onSuccess: (res) => {
      setTotalCount(res?.total_count);
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    refetch();
  }, [curr_page, per_page, refetch]);

  const handleNextClick = () => {
    if (curr_page < Math.ceil(totalCount / per_page)) {
      setCurrPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevClick = () => {
    if (curr_page > 1) {
      setCurrPage((prevPage) => prevPage - 1);
    }
  };

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value, 10));
  };

  const handleCheckboxChange = (id) => {
    setMentoringIds((prevSelectedIds) => {
      const updatedIds = prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((item) => item !== id)
        : [...prevSelectedIds, id];

      console.log("====+++", updatedIds);
      return updatedIds;
    });
  };

  const handleDeleteMentoring = () => {
    deleteBackend("/mentoring", {
      data: {
        mentoringIds,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        refetch();
        toast.success("멘토링이 성공적으로 삭제되었습니다");
        setDeleteModalOpen(false);
      })
      .catch(() => {
        toast.error("멘토링을 삭제하지 못했습니다");
      });
  };

  const renderCate = useCallback(() => {
    return cate.map((v, idx) => {
      return (
        <button key={idx} className={`cate ${cateState === v.value && "active"}`} onClick={() => setCateState(v.value)}>
          {v.text}
        </button>
      );
    });
  }, [cate]);

  const [modalOpen, setModalOpen] = useState(false);
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  
//   const [page2, setPage2] = useState(1);
//   const [memberPerPage2, setMemberPerPage2] = useState(10);
//   const [totalCount2, setTotalCount2] = useState(0);

//   const [paramArguments2, setParamArguments2] = useState("");

//   const [mentorList2, setMentorList2] = useState([]);

//   const { isFetching } = useCustomQuery(
//     `/mentoring/my/menti?per_page=${memberPerPage2}&curr_page=${page2}&user_seq=${user.user_seq}`,
//     {
//       queryKey: ["selectMyMenti/", user.user_seq],
//       select: (res) => res?.data,
//       onSuccess: async (data) => {
//         setTotalCount2(data.total_cnt);
//         setMentorList2(data.data_list);
//       },
//       refetchOnWindowFocus: false,
//     },
//   );

//   console.log(mentorList2);

//   const getService = (item) => {
//       if(item === "MEET") return <><span className="com_pc">[</span>화상미팅<span className="com_pc">]</span><br/></>;
//       if(item === "CHAT") return <><span className="com_pc">[</span>채팅<span className="com_pc">]</span><br/></>;
//       if(item === "BOARD") return <><span className="com_pc">[</span>게시판<span className="com_pc">]</span><br/></>;
//   };

//   const viewStatus = (status, mento_seq, user_seq) => {
//     switch (status) {
//       case "MS10": {
//        return <><button type="button" className="com_btn line gray s oval" onClick={()=> handleStatusUpdate('MS30', mento_seq, user_seq)}>수락</button><button type="button" className="com_btn line gray s oval" onClick={()=> handleStatusUpdate('MS90', mento_seq, user_seq)}>거절</button></>;
//         // break;
//       }
//       case "MS30": {
//         return <><span className="txt">진행 중</span></>;
//         //break;
//       }
//       case "MS90": {
//         return <><span className="txt">거절</span></>;
//         //break;
//       }
//       case "MS50": {
//         return <><span className="txt">완료</span></>;
//         //break;
//       }
//       case "MS99": {
//         return <><span className="txt">삭제</span></>;
//         //break;
//       }
//     }
//   };

//   const handleStatusUpdate = async (menti_status, mento_seq, user_seq) => {

    
// //use_meet_cnt



//     const response = await mutateBackend("/mentoring/user/update", {
//       data: {
//         menti_status: menti_status,
//         mento_seq: mento_seq,
//         user_seq: user_seq
//       },
//       headers: { "Content-Type": "application/json" },
//     });

//     if (response.status === 200) {
//       toast.success("수정 되었습니다.");
//     } else {
//       toast.error("수정에 실패했습니다.");
//     }
//   };


  return (
    <>
      <div className="container">
        <h2 className="com_pg_tit">상품 관리</h2>
        <section className="com_pg_tab">
          <Link to="/Mypage/ProductInsight" className="tab">
            인사이트 상품
          </Link>
          <Link to="/Mypage/ProductMentoring" className="tab active">
            멘토링 상품
          </Link>
        </section>
        <section className="com_board_list__top">
          <article className="com_cate">{renderCate()}</article>
        </section>
        {(cateState === 0 && (
          <>
            <section className="com_board_list__top">
              <article className="btn_wrap">
                <button className="com_btn blue oval xs" onClick={() => setDeleteModalOpen(true)}>
                  삭제
                </button>
              </article>
            </section>
            <table className="com_board_list">
              <colgroup>
                <col width="6%" />
                <col />
                <col width="25%" />
                <col width="13%" />
              </colgroup>
              <thead>
                <tr>
                  <th></th>
                  <th>상품명</th>
                  <th>카테고리</th>
                  <th>관리</th>
                </tr>
              </thead>
              <tbody>
                {mentoringProducts?.mentors?.map((mento) => (
                  <tr key={mento.mento_seq}>
                    <td className="chk_field">
                      <input
                        type="checkbox"
                        className="com_chk"
                        id={mento.mento_seq}
                        checked={mentoringIds.includes(mento.mento_seq)}
                        onChange={() => handleCheckboxChange(mento.mento_seq)}
                      />
                      <label htmlFor={mento.mento_seq}></label>
                    </td>
                    <td className="tit_field">{mento.mento_title}</td>
                    <td className="state_field">{mento.cate_name}</td>
                    <td className="btn_field">
                      <Link to={`/Mentoring/MentoringProduct/${mento.mento_seq}`} className="com_btn oval line gray s">
                        수정
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )) ||
          (cateState === 1 && ( 
            <MenteeManagement />
            // <>
            //   <section className="com_board_list__top">
            //     <article className="btn_wrap">
            //       <button className="com_btn blue oval xs">삭제</button>
            //     </article>
            //   </section>
            //   <table className="com_board_list">
            //     <colgroup>
            //       <col width="6%" />
            //       <col width="10%" />
            //       <col />
            //       <col width="13%" />
            //       <col width="13%" />
            //       <col width="17%" />
            //       <col width="15%" />
            //     </colgroup>
            //     <thead>
            //       <tr>
            //         <th></th>
            //         <th>멘티명</th>
            //         <th>상품명</th>
            //         <th>서비스 상품</th>
            //         <th>결제금액</th>
            //         <th>상태</th>
            //         <th>채널</th>
            //       </tr>
            //     </thead>
            //     <tbody>
            //     {mentorList2.length > 0 && mentorList2.map((item, indx) => {
            //        const serviceArr = item.service_code.split(',');
            //       return <>
            //       <tr key={indx}>
            //         <td className="chk_field">
            //           <input type="checkbox" className="com_chk" id="chk01" />
            //           <label htmlFor="chk01"></label>
            //         </td>
            //         <td className="id_field">{item.user_name}</td>
            //         <td className="tit_field">{item.goods_name}</td>
            //         <td className="info_field">
            //           <span className="th_m">서비스 상품 :</span>
            //           {serviceArr.map((el) => getService(el))
            //           }
            //         </td>
            //         <td className="info_field last">
            //           <span className="th_m">결제금액 :</span>{item.amt}원
            //         </td>
            //         <td className="accept_field">
            //           {viewStatus(item.menti_status, item.mento_seq, item.user_seq)}
            //         </td>
            //         <td className="btn_field">
            //           <button className="com_btn oval line s">화상미팅 ({item.use_meet_cnt}/{item.supp_meet_cnt})</button>
            //         </td>
            //       </tr>
            //       </>

            //     })}
            //     </tbody>
            //   </table>
            // </>
          )) ||
          (cateState === 2 && (
            <>
              <section className="com_board_list__top">
                <article className="btn_wrap">
                  <button className="com_btn blue oval xs">삭제</button>
                </article>
              </section>
              <table className="com_board_list">
                <colgroup>
                  <col width="6%" />
                  <col width="10%" />
                  <col />
                  <col width="15%" />
                  <col width="15%" />
                  <col width="17%" />
                </colgroup>
                <thead>
                  <tr>
                    <th></th>
                    <th>멘티명</th>
                    <th>상품명</th>
                    <th>서비스 상품</th>
                    <th>결제금액</th>
                    <th>추천서</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="chk_field">
                      <input type="checkbox" className="com_chk" id="chk01" />
                      <label htmlFor="chk01"></label>
                    </td>
                    <td className="id_field">abcd123</td>
                    <td className="tit_field">자동차 설비에 관한 A~Z</td>
                    <td className="info_field">
                      <span className="th_m">서비스 상품 :</span>
                      <span className="com_pc">[</span>화상미팅<span className="com_pc">]</span>
                    </td>
                    <td className="info_field last">
                      <span className="th_m">결제금액 :</span>22,000원
                    </td>
                    <td className="btn_field">
                      <button className="com_btn oval line s" onClick={() => setModalOpen(true)}>
                        추천서 작성
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          ))}
        {!isLoadingMentoring && (
          <Pagination
            currentPage={curr_page}
            totalPages={Math.ceil(totalCount / per_page)}
            onNextClick={handleNextClick}
            onPrevClick={handlePrevClick}
            perPage={per_page}
            handlePerPageChange={handlePerPageChange}
          />
        )}{" "}
      </div>
      {/* top button */}
      <ScrollToTopButton />

      <DeleteMentoringModal
        open={deleteModalOpen}
        close={() => setDeleteModalOpen(false)}
        handleDelete={handleDeleteMentoring}
      />

      {/*멘토 추천서 팝업*/}
      <Modal open={modalOpen} close={handleCloseModal} popSize="s">
        <section className="pop_tit">멘토 추천서</section>
        <div className="content_container scroll">
          <ul className="com_input_sec">
            <li className="tit_field">제목</li>
            <li className="input_field">
              <input type="text" placeholder="스펀지같은 흡수력의 홍길동 멘티" />
            </li>
            <li className="tit_field">멘티가 우수한 점</li>
            <li className="input_field">
              <textarea />
            </li>
          </ul>
        </div>
        <section className="btn_container">
          <button className="com_btn blue s" onClick={() => handleCloseModal()}>
            작성하기
          </button>
          {/* <button className="com_btn line blue s">취소</button> */}
        </section>
      </Modal>
    </>
  );
}

export default ProductMentoring;
