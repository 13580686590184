import React, { useCallback, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Controller, Navigation, Pagination } from "swiper";
import { Link } from "react-router-dom";

import Item from "./_Item";
import TopBanner from "../mentoring/_TopBanner";
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";

import "../../assets/scss/page/magazine.scss";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import Paginationn from "./../../components/Pagination";

function List() {
  const [itemSwiper, setItemSwiper] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [per_page, setPerPage] = useState(6);
  const [curr_page, setCurrPage] = useState(1);

  const {
    data: { magazines = [], total_count = 0 } = {
      magazines: [],
      total_count: 0,
    },
    isFetching,
  } = useCustomQuery(`/magazine?category=${selectedCategory}&curr_page=${curr_page}&per_page=${per_page}`, {
    queryKey: ["getMagazineList", curr_page, per_page, selectedCategory],
    select: (res) => res?.data ?? { magazines: [], total_count: 0 },
  });

  const { isFetching: isFetchingCategories } = useCustomQuery(`/category?cate_gr=MAG_CATE`, {
    queryKey: ["getMagazineCategories"],
    select: (res) => res?.data.categories,
    onSuccess: (data) => {
      const newTabs = [{ text: "전체", value: "" }];
      for (const category of data) {
        newTabs.push({
          value: category.cate_seq,
          text: category.cate_name,
        });
      }
      setCategories(newTabs);
    },
    refetchOnWindowFocus: false,
  });

  const handleCategoryChange = useCallback((categoryValue) => {
    setSelectedCategory(categoryValue);
    setCurrPage(1);
  }, []);

  const handleNextClick = () => {
    if (curr_page < Math.ceil(total_count / per_page)) {
      setCurrPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevClick = () => {
    if (curr_page > 1) {
      setCurrPage((prevPage) => prevPage - 1);
    }
  };

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value, 10));
  };

  const renderTabWithSwiper = () => {
    return categories?.map((v, idx) => {
      return (
        <SwiperSlide key={idx}>
          <button
            key={idx}
            className={`tab ${selectedCategory === v.value ? "active" : ""}`}
            onClick={handleCategoryChange}>
            {v.text}
          </button>
        </SwiperSlide>
      );
    });
  };

  const renderCategories = () => {
    return categories.map((v, idx) => (
      <button
        key={idx}
        className={`tab ${(selectedCategory === v.value && "active") || ""}`}
        onClick={() => handleCategoryChange(v.value)}>
        {v.text}
      </button>
    ));
  };

  return (
    <main className="p_magazine">
      {/* long banner */}
      <TopBanner />
      <section className="sec_magazine com_bg">
        <div className="com_center_wrap">
          <div className="com_sub_tit_wrap">
            <div className="pg_tit_wrap">
              <h2 className="com_pg_tit">매거진</h2>
            </div>
            <div className="com_tab_wrap">
              <div className="content_tab">
                {!isFetchingCategories && (
                  <>
                    {renderCategories()}
                    <div className="swiper_wrap category">
                      <div className="swiper-button-prev"></div>
                      <Swiper
                        modules={[Pagination, Controller, Navigation]}
                        onSwiper={setItemSwiper}
                        controller={{ control: itemSwiper }}
                        navigation={{
                          nextEl: ".category .swiper-button-next",
                          prevEl: ".category .swiper-button-prev",
                        }}
                        autoplay={true}
                        breakpoints={{
                          500: {
                            slidesPerView: 3,
                            spaceBetween: 10,
                          },
                          300: {
                            slidesPerView: 3,
                            spaceBetween: 10,
                          },
                        }}>
                        {renderTabWithSwiper()}
                      </Swiper>
                      <div className="swiper-button-next"></div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="list_wrap">
            <h2 className="tit">
              COMPRESS 추천 매거진{" "}
              <Link to="/Magazine/RecommendList" className="more">
                더보기
              </Link>
            </h2>
            <ul className="com_card_wrap">
              {magazines.map((mag, index) => (
                <Item
                  key={index}
                  mag_seq={mag.mag_seq}
                  title={mag.mag_title}
                  description={mag.mag_content}
                  mag_image={mag.mag_image}
                  mag_tags={mag?.mag_tags}
                />
              ))}
            </ul>
          </div>
          {!isFetching && (
            <Paginationn
              currentPage={curr_page}
              totalPages={Math.ceil(total_count / per_page)}
              onNextClick={handleNextClick}
              onPrevClick={handlePrevClick}
              perPage={per_page}
              handlePerPageChange={handlePerPageChange}
            />
          )}
          <div className="list_wrap m-80">
            <h2 className="tit">
              Optima Tank 650 추천 매거진{" "}
              <Link to="/Magazine/RecommendList" className="more">
                더보기
              </Link>
            </h2>
            <ul className="com_card_wrap">
              {magazines.map((mag, index) => (
                <Item
                  key={index}
                  mag_seq={mag.mag_seq}
                  title={mag.mag_title}
                  description={mag.mag_content}
                  mag_image={mag.mag_image}
                  mag_tags={mag.mag_tags}
                />
              ))}
            </ul>
          </div>
        </div>
      </section>
      {/* top button */}
      <ScrollToTopButton />
    </main>
  );
}

export default List;
