import React from "react";
import Modal from "../modal";

function PopResume(props) {
  const { open, close } = props;
  return (
    <Modal open={open} close={close} type="pop_resume" popSize="s">
      <h2 className="pop_tit type_center">이력서 완성도란?</h2>
      <div className="content_container">
        <p className="point">각 항목별 정보를 채우면 이력서의완성도가 올라갑니다.</p>
        <p>완성도가 올라갈수록 매칭률이 올라가고원하는 기업을 찾을 확률이 높아집니다!</p>
        <ul className="dot_list">
          <li className="item">인적사항 10%</li>
          <li className="item">학력 10%</li>
          <li className="item">경력 10%</li>
          <li className="item">자기소개 10%</li>
          <li className="item">나의 성향 50%</li>
        </ul>
      </div>
    </Modal>
  );
}

export default PopResume;
