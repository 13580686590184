/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useContext } from "react";
// import useScript from "../../../../../hooks/useScript";
// import SnsApple from "../../assets/img/login_sns_apple.png";
import { AuthContext } from "../../../../../context/Auth";
import { useNavigate } from "react-router-dom";

const { AppleID } = window;

export default function AppleLogin({ formType, FormType }) {
  const navigate = useNavigate();

  const { snslogin } = useContext(AuthContext);

  useEffect(() => {
    AppleID.auth.init({
      clientId: "awsome.ces",
      scope: "name email",
      redirectURI: process.env.REACT_APP_APPLE_LOGIN_URL,
      state: "signin", //csrf, php의 openssl_random_pseudo_bytes
      nonce: "[NONCE]", //??뭔지 모르겠는디??
      usePopup: true, // or false defaults to false
    });
  }, []);

  useEffect(() => {
    //handle successful response 
    document.addEventListener('AppleIDSignInOnSuccess', async (data) => {
      console.log("성공", data);
      console.log("성공", data?.detail?.authorization?.id_token);
      const AppleIdToken = data?.detail?.authorization?.id_token; 
      //로그인 로직 필요
      //백엔드로 회원정보 보내기
      const result = await snslogin({ credentialResponse: AppleIdToken, type: "apple", userType: formType === FormType.General ? "PSN" : "COM"  });
      // console.log("result", result);
              //로그인 및 회원 가입 분기
              if (result === true) {
                const redirectPath = sessionStorage.getItem("redirectPath");
                if (redirectPath) {
                  if (redirectPath.toLocaleLowerCase().startsWith("/company") && formType === FormType.General) navigate("/");
                  else if (redirectPath.toLocaleLowerCase().startsWith("/mypage") && formType === FormType.Corporate)
                    navigate("/");
                  else navigate(redirectPath);
      
                  sessionStorage.removeItem("redirectPath");
                } else {
                  navigate("/");
                }
              } else {
                navigate(`/member/join?snstype=apple&email=${result.email}&cid=${result.cid}`);
              }
    });
    //Listen for authorization failures
    document.addEventListener("AppleIDSignInOnFailure", (error) => {
      console.log("실패", error);
    });
  }, []);

  return (
    <>
      <div
        id="appleid-signin"
        className="btn signin-button"
        data-mode="logo-only"
        data-color="black"
        data-border="true"
        data-type="sign in"
        data-border-radius="50"></div>

      {/* <button type="button" className="btn">
        <img src={SnsGoogle} alt="google" />
      </button> */}
    </>
  );
}
