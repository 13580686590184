import React from "react";
import { Route, Routes } from "react-router-dom";

import Success from "../views/screens/page/nice/success";
import Fail from "../views/screens/page/nice/fail";

function NiceRouter() {
  return (
    <>
      <Routes>
        <Route path="/success" element={<Success />} />
        <Route path="/fail" element={<Fail />} />
      </Routes>
    </>
  );
}

export default NiceRouter;
