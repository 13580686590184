import React from "react";
import { getImageLink } from "../../../../../utils/misc";

function Item({ handleDetail, seminar }) {
  return (
    <div onClick={handleDetail} className="com_card_item" style={{ cursor: "pointer" }}>
      <img src={getImageLink(seminar?.thumbnail)} className="thumb_img" alt={seminar?.semin_title} />
      <div className="info">
        <p className="title">{seminar?.semin_title}</p>
        <p className="detail">
          <span className="text_grey">일정: </span> {new Date(seminar?.semin_date).toLocaleString()}
        </p>
        <p className="detail">
          <span className="text_grey">장소: </span> {seminar?.semin_address}
        </p>
        <p className="detail">
          <span className="text_grey">정원: </span> {seminar?.semin_applications} / {seminar?.semin_limit}명
        </p>
      </div>
      {new Date(seminar?.reg_end_dtm) >= new Date() && +seminar?.semin_applications < +seminar?.semin_limit ? (
        <button className="com_btn line">참가신청</button>
      ) : (
        <button className="com_btn disabled">신청마감</button>
      )}
    </div>
  );
}

export default Item;
