import React from "react";
import { useSearchParams } from "react-router-dom";
import { useCustomQuery } from "../../../../../../hooks/useAPI";
import Table from "../../../../screens/components/boffice/Table";
import Paging from "../../../component/Paging";
import SeminarFilter from "../../../component/SeminarFilter";

const columns = {
  semin_apl_seq: "신청자 아이디",
  user_name: "이름",
  semin_title: "세미나 명",
  application_date: "신청일자",
  join_num: "신청 인원 수",
  semin_price: "결제금액",
  pay_status: "결제상태",
  detail: {
    label: "관리",
    getLink: (item) => "/boffice/board/seminar/application/details/" + item["semin_apl_seq"],
    linkLabel: "상세",
  },
};

const defaultSearchParams = {
  page: 1,
  limit: 10,
  search_query: "",
  date_from: "",
  date_to: "",
  status: "",
};

export default function AdminSeminarApplicationList() {
  const [searchParams, setSearchParams] = useSearchParams(defaultSearchParams);
  const page = +searchParams.get("page");
  const limit = +searchParams.get("limit");
  const date_from = searchParams.get("date_from");
  const date_to = searchParams.get("date_to");
  const status = searchParams.get("status");
  const search_query = searchParams.get("search_query");

  const {
    data: { seminarApplications = [], total_count = 0 } = {
      seminarApplications: [],
      total_count: 0,
    },
    isFetching,
  } = useCustomQuery(
    `/seminar/applications?page=${page}&limit=${limit}&search_query=${search_query}&date_from=${date_from}&date_to=${date_to}&pay_status=${status}`,
    {
      queryKey: ["getAdminSeminarApplicationList", page, limit, search_query, date_from, date_to, status],
      select: (res) => res?.data ?? { seminarApplications: [], total_count: 0 },
    },
  );

  return (
    <main>
      <h2 className="adm_com_pg_tit">세미나 관리</h2>
      <div className="adm_com_pg_content">
        <SeminarFilter
          defaultSearchParams={defaultSearchParams}
          setSearchParams={setSearchParams}
          total_count={total_count}
          isFetching={isFetching}
          limit={limit}
          search_query={search_query}
          date_from={date_from}
          date_to={date_to}
          status={status}
          title="세미나 신청 정보"
          dateTitle="신청일:"
          showDownload
        />
        <Table
          data={seminarApplications}
          columns={columns}
          handleCheck={(e) => {
            console.log(e);
          }}
        />
        <Paging page={page} totalPageCount={Math.ceil(total_count / limit)} setSearchParams={setSearchParams} />
      </div>
    </main>
  );
}
