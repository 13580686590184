/*global kakao*/
import React, { useEffect } from "react";
const KaKaoMap = ({ map_address, map_title }) => {

  // 카카오 API 호출
  useEffect(() => {
    const mapContainer = document.getElementById('map'), // 지도를 표시할 div 
      mapOption = {
        center: new kakao.maps.LatLng(37.413294, 127.269311), // 지도의 중심좌표 (서울 시청)
        level: 3 // 지도의 확대 레벨
      };

    // 지도를 생성합니다    
    const map = new kakao.maps.Map(mapContainer, mapOption);

    // 주소-좌표 변환 객체를 생성합니다
    const geocoder = new kakao.maps.services.Geocoder();

    // 주소로 좌표를 검색합니다
    geocoder.addressSearch(map_address, function (result, status) {

      // 정상적으로 검색이 완료됐으면 
      if (status === kakao.maps.services.Status.OK) {

        const coords = new kakao.maps.LatLng(result[0].y, result[0].x);

        // 결과값으로 받은 위치를 마커로 표시합니다
        const marker = new kakao.maps.Marker({
          map: map,
          position: coords
        });

        // 인포윈도우로 장소에 대한 설명을 표시합니다
        const infowindow = new kakao.maps.InfoWindow({
          content: `<div style="width:150px;text-align:center;padding:6px 0;">${map_title}</div>`
        });
        infowindow.open(map, marker);

        // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
        map.setCenter(coords);

        // mapContainer.style.width = '940px';
        // mapContainer.style.height = '600px';   
        map.setZoomable(false);//지도 확대 막기
        map.setDraggable(false);//지도 이동 막기   
      }
    });
  }, [map_address, map_title])

  return (

    <div className="map" id="map" style={{width:'940px', height: '600px'}} >Map</div>
  );
}

export default KaKaoMap;
