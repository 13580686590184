/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import Modal from "../../components/modal";

import { useCustomQuery } from "../../../../../hooks/useAPI";
import { getImageLink } from "../../../../../utils/misc";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../../context/Auth";
import { mutateBackend } from "../../../../../api";
import { toast } from "react-toastify";
import { /*Member, Mentee, Mentor, */NonMember, QuestionsList } from "./QnA_Board";
import MentoringReviewTab from "./_MentoringReviewTab";

function PopMentoringDetail(props) {
  const { user } = useContext(AuthContext);

  const { open, close, mento_seq, avg_rating } = props;

  const [tabState, setTabState] = useState(0);

  const [isRegistered, setIsRegistered] = useState(false);

  const [isMentorSameAsUser, setIsMentorSameAsUser] = useState(false);

  const [count, setCount] = useState(0);

  const tab = [
    { text: "화상미팅", value: 0 },
    { text: "질문과 답변 게시판", value: 1 },
    { text: "후기", value: 2 },
  ];

  const { data: mentoring, isFetching: isFetchingMentoringDetail } = useCustomQuery(`/mentoring/${+mento_seq}`, {
    queryKey: ["getMentoringDetail", { mento_seq }],
    select: (res) => res?.data,
    onSuccess: (mentoring) => {
      if (
        mentoring &&
        String(mentoring["attendee_seqs"])
          .split(",")
          .find((seq) => Number(seq) === Number(user.user_seq))
      ) {
        setIsRegistered(true);
      }
    },
  });

  const commonCondition = !isRegistered && mentoring; //(결제하기, 글쓰기, 답변하기)버튼 조건 

  const { data: resume, isFetching: isFetchingMentorInfo } = useCustomQuery(
    `/mentoring/mentor-info/${mentoring?.user_seq}`,
    {
      queryKey: ["getMentorInfo", { mento_seq }],
      select: (res) => res?.data,
      enabled: !!mentoring,
    },
  );

  const { data: reviews, isFetching: isFetchingReviews } = useCustomQuery(
    `/mentoring/user/review?per_page=6&curr_page=1&mento_seq=${mento_seq}`,
    {
      queryKey: ["getMentoringReview"],
      select: (res) => res?.data.data_list,
    },
  );

  const { refetch } = useCustomQuery(
    `/mentoring/complete/use?mento_seq=${mento_seq}&user_seq=${user.user_seq ? user.user_seq : 0}`,
    {
      queryKey: ["getCount/", mento_seq, user.user_seq],
      select: (res) => res?.data,
      onSuccess: async (data) => {

        console.log(data?.count);
        setCount(data?.count);
      },
      refetchOnWindowFocus: false,
    },
  );


  // useEffect(()=> {
  //   const getCount = async (mento_seq, user_seq) => {

  //     //use_meet_cnt
  //     const response = await mutateBackend("/mentoring/complete/use", {
  //       data: {
  //         mento_seq: mento_seq,
  //         user_seq: user_seq
  //       },
  //       headers: { "Content-Type": "application/json" },
  //     });
  
  //     if (response.status === 200) {
  //       console.log(response);
  //       console.log(response.body);
  //     } else {
  //       toast.error("조회에 실패했습니다.");
  //     }
  //   };

  //   getCount(mento_seq, user.user_seq);
  // }, [mento_seq, user.user_seq]);
  


  function calculateExperience(joinDate, leaveDate, status) {
    const startDate = new Date(joinDate);

    const endDate = status === "CS10" ? new Date(leaveDate) : new Date();

    const timeDiff = Math.abs(endDate - startDate);
    const yearsDiff = timeDiff / (1000 * 60 * 60 * 24 * 365.25);

    return Math.ceil(yearsDiff);
  }

  /**
   * @param {number} [rating]
   * @param {string} [reviewContent]
   * @param {number} [attachment]
   */
  async function handleSubmitReview(rating, reviewContent, attachment) {
    const response = await mutateBackend("/mentoring/user/review", {
      data: {
        mento_seq: mento_seq,
        user_seq: user.user_seq,
        rate_score: rating,
        review_contents: reviewContent,
        att_file_seq: attachment,
      },
      headers: { "Content-Type": "application/json" },
    }).then(async (data) => await data.json());

    if (response.messageCode === 200) {
      toast.success("성공적으로 검토를 제출했습니다.");
      setTabState(0);
    } else if (response.messageCode === 409) toast.error("하나의 리뷰 만 제출할 수 있습니다.");
    else toast.error("검토를 제출하지 못했습니다.");
  }

  useEffect(() => {
    if (
      mentoring &&
      String(mentoring["attendee_seqs"])
        .split(",")
        .find((seq) => Number(seq) === Number(user.user_seq))
    ) {
      setIsRegistered(true);
    }
  }, []);

  useEffect(() => {
    if(tabState !== 1) setIsMentorSameAsUser(false); 
  }, [tab])

  return (
    <Modal open={open} close={close} type="pop_apply pop_mentoring_detail">
      <h2 className="pop_tit">멘토링 상품 설명</h2>
      {!isFetchingMentorInfo && !isFetchingMentoringDetail && !isFetchingReviews ? (
        <div className="content_container scroll">
          <div className="info_bg">
            <div className="info_wrap">
              <img src={getImageLink(mentoring?.images[0])} className="img_logo" alt="mentoring1" />
              {resume && (
                <div className="info_tit">
                  <strong>{resume?.basicInfo?.resume_name}</strong>
                  <div className="info_detail">
                    <div className="detail_box">
                      <div className="tit">직무 및 경력</div>
                      <pre className="desc">{mentoring?.mento_job}</pre>
                    </div>
                    {/* <div className="bar"></div>
                    <div className="detail_box">
                      <div className="tit">경력</div>
                      <ul className="desc">
                        {resume?.career?.map((career) => {
                          return (
                            <li key={career.career_no}>
                              • {calculateExperience(career.join_date, career.leave_date, career.career_status)} years
                              of {career.career_posi} experience
                            </li>
                          );
                        })}
                      </ul>
                    </div> */}
                    <div className="bar"></div>
                    <div className="detail_box">
                      <div className="tit">커리어 히스토리</div>
                      <p className="desc">{mentoring.mento_carrer}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="info_wrap">
            <p className="input_tit first">서비스 상품</p>
            <div className="content_tab">
              {tab.map((v, idx) => {
                return (
                  <button
                    key={idx}
                    // className={`tab ${(tabState === v.value && "active") || ""}`}
                    className={`tab ${(tabState === v.value && "active") || ""}`}
                    onClick={() => setTabState(v.value)}>
                    {v.text}
                  </button>
                );
              })}
            </div>
            {/* Mentoring Information */}
            {tabState !== 2 && (
              <div className="tab_contents">
                <p className="input_tit">멘토링 서비스 설명</p>
                <div className="tab_box">
                  {mentoring?.mento_desc}
                  <div className="img_wrap">
                    {mentoring?.images?.map((img_seq) => {
                      return <img key={img_seq} src={getImageLink(img_seq)} className="img" alt="image" />;
                    })}
                  </div>
                </div>
                <div className="tab_box bg_box">화상 미팅 : 월 {mentoring?.mento_meet_cnt}회 이용가능</div>
              </div>
            )}{" "}
            {tabState === 1 && (
              <div className="tab_contents">
                <p className="input_tit">질문과 답변 게시판</p>
                <div className="tab_box">
                  { !isMentorSameAsUser && <QuestionsList mento_seq={mento_seq}/>}
                  { isMentorSameAsUser && <NonMember user={user} mento_seq={mento_seq}/>} 
                  {/* {user.user_seq !== mentoring.user_seq && <QuestionsList />}  */}
                  {/* {(user.user_seq === mentoring.user_seq && user?.mento_status === "MS20") && <Mentor />}
                  {false && <Mentee />}
                  {false && <Member />} */}
                </div>
              </div>
            )}
            {/* Mentoring Review */}
            {tabState === 2 && (
              <div className="tab_contents">
                <p className="input_tit">후기</p>
                <div className="tab_box">
                  <MentoringReviewTab
                    avg_rating={avg_rating}
                    handleSubmitReview={handleSubmitReview}
                    isRegistered={isRegistered}
                    reviews={reviews}
                    user={user}
                  />
                </div>
              </div>
            )}
            {/* <div className="content_tab bottom">
              {tab2.map((v, idx) => {
                if(v.value === 2 && count === 0 && count !== undefined){
                  return false;
                }
                return (
                  <button
                    key={idx}
                    className={`tab ${(tabState === v.value && "active") || ""}`}
                    onClick={() => setTabState(v.value)}>
                    {v.text}
                  </button>
                );
              })}
            </div> */}
          </div>
        </div>
      ) : (
        <span className="txt flex-center">
          <i className="gg-spinner-alt" />
        </span>
      )}
      <div className="btn_container center">
        {(commonCondition && tabState === 0) ? (
          <Link to={"/Mentoring/MentoringPay/" + mento_seq} className="com_btn blue m">
            결제하기
          </Link>
        ) : (commonCondition && tabState === 1 && user.user_seq !== mentoring.user_seq && !isMentorSameAsUser) ? (
          <button className="com_btn blue m" onClick={() => setIsMentorSameAsUser(true)}>
            글쓰기
          </button>
        ) : null}
      </div>
    </Modal>
  );
}

export default PopMentoringDetail;
