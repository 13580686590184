import React, { useState } from "react";
import Modal from "../modal";

function PopAddUrl(props) {
  const { open, close, handleUrlAdd } = props;
  const [url, setUrl] = useState("http://");

  const handleInputChange = (e) => {
    setUrl(e.target.value);
  };

  const handleAddUrl = (e) => {
    e.preventDefault();

    if (url) {
      handleUrlAdd(url);
      setUrl("http://");
    }
  };

  return (
    <Modal open={open} close={close}>
      <h2 className="pop_tit type_center">URL 추가</h2>
      <div className="content_container">
        <div>
          <input type="text" value={url} className="input" onChange={handleInputChange} />
        </div>
        <section className="com_btn_wrap center">
          <button className="com_btn blue s" onClick={handleAddUrl}>
            등록
          </button>
          <button className="com_btn line blue s" onClick={close}>
            취소
          </button>
        </section>
      </div>
    </Modal>
  );
}

export default PopAddUrl;
