import React, { useState, useCallback } from "react";
import "../../assets/scss/page/member.scss";
//component
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import RecommendedTalent from "./_RecommendedTalent";
import FavoriteTalent from "./_FavoriteTalent";
import ResumeInterviewApplied from "./_ResumeInterviewApplied";
import RecruitmentStatus from "./_RecruitmentStatus";

function Recruit() {
  const [tabState, setTabState] = useState(0);
  const tab = [
    { text: "추천 인재", value: 0 },
    { text: "찜한 인재", value: 1 },
    { text: "제안을 보낸 인재", value: 2 },
    { text: "면접 문구 설정", value: 3 },
    { text: "채용상태", value: 4 },
  ];

  const renderTab = useCallback(() => {
    return tab.map((v, idx) => {
      return (
        <button
          key={idx}
          className={`tab ${(tabState === v.value && "active") || ""}`}
          onClick={() => setTabState(v.value)}>
          {v.text}
        </button>
      );
    });
  }, [tab]);
  return (
    <>
      <div className="container">
        <h2 className="com_pg_tit">채용관리</h2>
        <div className="com_pg_tab">{renderTab()}</div>
        {/* Recommended Talent */}
        {tabState === 0 && <RecommendedTalent />}
        {/* Favorite Talent */}
        {tabState === 1 && <FavoriteTalent />}
        {/* Resumes that Applied for Interview */}
        {tabState === 2 && <ResumeInterviewApplied />}
        {tabState === 3 && (
          <div className="tab_content">
            <ul className="com_input_sec type_row">
              <li className="field_box">
                <span className="tit">제목</span>
                <div className="input_box">
                  <input type="text" placeholder="설비 공고" className="input" />
                </div>
              </li>
              <li className="field_box">
                <span className="tit">내용</span>
                <div className="input_box">
                  <textarea
                    className="textarea l"
                    placeholder="OOO님의 역량이 ㈜삼성 전기 엔지니어 포지션에적합하다고 판단되어, 면접 요청을 제안합니다.이 제안은 22.11.17까지 유효합니다.(주) 삼성은 국내 최고의 대기업이며, 꾸준한
연구개발 투자와 고객만족 경영, 마케팅을 바탕으로 세계적인 기업으로 성장해왔습니다.

일정: 2022.11.13 (수)
장소: 서울특별시 서초구 서초대로74길 11  2층

세계적인 기업으로의 성장을 함께할 당신을
기다리겠습니다!

문의 이메일: abcd123@awesome8.kr
면접 날짜 수정이 필요할 경우 위 이메일로 회신 주세요."></textarea>
                </div>
              </li>
              <li className="field_box">
                <span className="tit m_hide"></span>
                <div className="input_box">
                  <div className="btn_wrap">
                    <button className="com_btn line oval xs btn_refresh">초기화</button>
                    <button className="com_btn bg blue oval xs">문구 수정</button>
                  </div>
                </div>
              </li>
              <li className="field_box">
                <span className="tit m_hide"></span>
                <div className="input_box">
                  <div className="com_attach_section">
                    <div className="img_box active">
                      <span>면접 기본 문구</span>
                      <button type="button" className="btn_del" onClick={() => {}}></button>
                    </div>
                    <div className="img_box">
                      <span>엔지니어 공고</span>
                      <button type="button" className="btn_del" onClick={() => {}}></button>
                    </div>
                    <div className="com_input_upload">
                      <button className="btn_upload"></button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        )}
        {tabState === 4 && <RecruitmentStatus />}
      </div>
      {/* top button */}
      <ScrollToTopButton />
      {/* Modal - interview suggestion */}
    </>
  );
}

export default Recruit;
