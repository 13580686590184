import React from "react";
import { Link } from "react-router-dom";
import iconGraph from "../../assets/img/icon_match_graph.png";
export default function JopItem({ employmentTypes, recruitItem }) {
  return (
    
    <li className="com_job_item" style={{width:'280px'}}>
      <p className="tit">{recruitItem["recruit_title"]}</p>
      <div className="info">
        <p className="info_tit">
        {recruitItem["career_min"] && `경력 ${recruitItem["career_min"]}년 이상, `}{" "}
          {recruitItem["comp_address"] + recruitItem["close_date"]
            ? " 마감: ~ " + `${recruitItem["close_date"].split("T")[0]}까지`
            : ""}
        </p>
        <p className="info_s">
        {recruitItem["comp_name"]}{" "} 
        <span className="icon">
          {" "}
          {employmentTypes.find((emp_type) => emp_type["comm_code"] === recruitItem["emp_type"])["code_nm_kr"]}
        </span>
        </p>
        <ul className="com_tag_list">
        {recruitItem["recruit_tags"] &&
            recruitItem["recruit_tags"] !== "" &&
            recruitItem["recruit_tags"].split(",").map((tag, i) => (
              <li className="com_btn xs" key={i}>
                #{tag}
              </li>
            ))}
        </ul>
      </div>
      <Link to={`/match/Detail/${recruitItem["recruit_seq"]}`} className="btn_more"></Link>
      <button className="btn_like" />
      {/* 아이콘 active 시 active class 추가 */}
      <div className="graph">
        <span className='percent'>95%</span>
        <span className='icon_graph'><img src={iconGraph} alt="graph icon" /></span>
      </div>
    </li>
    /*
    <li className="com_job_item">
      <p className="tit">{recruitItem["recruit_title"]}</p>
      <div className="info">
        <p className="info_tit">
          {recruitItem["career_min"] && `경력 ${recruitItem["career_min"]}년 이상, `}{" "}
          {recruitItem["comp_address"] + recruitItem["close_date"]
            ? " 마감: ~ " + `${recruitItem["close_date"].split("T")[0]}까지`
            : ""}
        </p>
        <p className="info_s">
          {recruitItem["comp_name"]}{" "}
          <span className="icon">
            {" "}
            {employmentTypes.find((emp_type) => emp_type["comm_code"] === recruitItem["emp_type"])["code_nm_kr"]}
          </span>
        </p>
        <ul className="com_tag_list">
          {recruitItem["recruit_tags"] &&
            recruitItem["recruit_tags"] !== "" &&
            recruitItem["recruit_tags"].split(",").map((tag, i) => (
              <li className="com_btn lightblue" key={i}>
                #{tag}
              </li>
            ))}
        </ul>
      </div>
      <Link to="/Match" className="btn_more"></Link>
      <button className="btn_like" />
    
      <div className="graph">
        <span className="percent">95%</span>
        <span className="icon_graph">
          <img src={iconGraph} alt="graph icon" />
        </span>
      </div>
    </li>
    */

  );
}
