import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { mutateBackend, mutateBackendFormData, updateBackend } from "../../../../../api";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import Tag from "../../../screens/components/company/recruit/Tag";
import PopCreateTag from "../../../screens/components/company/recruit/_popCreateTag";
import { toast } from "react-toastify";
import { getImageLink } from "../../../../../utils/misc";
import useCommCate from "../../../../../hooks/useCommCate";
import CustomEditor from "../../../screens/components/Editor";

const defaultValues = {
  mag_title: "",
  cate_name: "",
  exposed_YN: "Y",
  mag_tags: "",
  mag_image: null,
  mag_category: "",
};

export default function MagazineDetail() {
  const navigate = useNavigate();
  const { id = "new" } = useParams();
  const uploadInput = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [imageSeq, setImageSeq] = useState(null);
  const [openCreateTag, setOpenCreateTag] = useState(false);

  const { handleSubmit, register, setValue, reset, watch, getValues } = useForm({
    defaultValues,
    mode: "all",
  });

  const { data: magazine = {} } = useCustomQuery(`/magazine?mag_seq=${id}`, {
    queryKey: ["getMagazine", id],
    select: (res) => res?.data?.magazines?.[0] ?? {},
    enabled: +id > 0,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      reset({
        ...defaultValues,
        mag_title: data?.mag_title,
        exposed_YN: data?.exposed_YN,
        mag_tags: data?.mag_tags,
        mag_image: data?.mag_image,
        mag_category: data?.mag_category,
        mag_content: data?.mag_content,
      });
    },
  });

  const { data: magCategories } = useCommCate("MAG_CATE");

  useEffect(() => {
    setValue("mag_image", imageSeq);
  }, [imageSeq]);

  const handleAddTag = (tag) => {
    const tempTag = getValues("mag_tags");
    const tags = tempTag?.length > 0 ? tempTag?.split(",") : [];
    !tags?.includes(tag) && setValue("mag_tags", [...tags, tag].join());
  };

  const handleRemoveTag = (tag) => {
    const tempTag = getValues("mag_tags");
    const tags = tempTag?.length > 0 ? tempTag?.split(",") : [];
    tags?.includes(tag) && setValue("mag_tags", tags?.filter((item) => item !== tag)?.join());
  };

  async function handleUpload(event) {
    const formData = new FormData();

    formData.append("file", event.target.files[0]);
    formData.append("upload_ty", new Date().toISOString());

    const response = await mutateBackendFormData("/upload/file", formData)
      .then(async (data) => await data.json())
      .catch(() => ({ messageCode: 400 }));

    if (response.messageCode === 200) {
      console.log("handleUpload", response.data);
      setImageSeq(response.data[0].up_file_seq);
      setValue("mag_image", response?.data?.[0]?.up_file_seq);
    } else {
      console.log("file failed");
    }
  }

  const onValidSubmit = async (data) => {
    setIsLoading(true);
    console.log({ data });
    if (+id) {
      const params = { ...data, mag_seq: +id };
      // localFiles?.length > 0 && (params.up_file_seq = await handleUpload([...localFiles]));
      const response = await updateBackend("/magazine", {
        data: params,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (data) => await data.json())
        .catch((err) => {
          throw new Error(err);
        });
      if (response.messageString === "SUCCESS" && response.messageCode === 200) {
        console.log(response.data);
        toast.success("Magazine is updated successfully!");
      } else {
        console.log(response);
        toast.error("Failed to upadate magazine!");
      }
      setIsLoading(false);
    } else {
      // localFiles?.length > 0 && (data.up_file_seq = await handleUpload());
      const response = await mutateBackend("/magazine/create", {
        data,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (data) => await data.json())
        .catch((err) => {
          throw new Error(err);
        });
      if (response.messageString === "SUCCESS" && response.messageCode === 200) {
        console.log(response.data);
        toast.success("Magazine is added successfully!");
        reset(defaultValues);
        navigate("/boffice/board/magazine/list");
      } else {
        setIsLoading(false);
        toast.error("Failed to create magazine!");
        console.log(response);
      }
    }
  };

  useEffect(() => {
    register("mag_content", { required: true, minLength: 11 });
  }, [register]);

  const onMagContentChange = (editorState) => {
    setValue("mag_content", editorState);
  };

  const magContent = watch("mag_content");
  console.log(magContent);

  return (
    <main>
      <h1 className="adm_com_pg_tit2">
        <Link to={"/boffice/member/manage/company"} style={{ color: "#034da2", paddingBottom: "10px" }}>
          매거진 관리
        </Link>{" "}
        {" > "} 세부 사항
      </h1>

      <h2 className="adm_com_pg_tit">매거진 세부 정보</h2>
      <div className="adm_com_pg_content">
        <form onSubmit={handleSubmit(onValidSubmit)}>
          <table className="adm_com_board_list type_row">
            <colgroup>
              <col width="13%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>매거진 제목</th>
                <td>
                  <input type="text" defaultValue={magazine?.mag_title} {...register("mag_title", {})} />
                </td>
              </tr>
              <tr>
                <th>태그</th>
                <td>
                  <div className="input_box">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenCreateTag(true);
                      }}
                      className="com_btn line s">
                      태그 추가
                    </button>
                    <ul className="com_tag_list">
                      {watch("mag_tags")
                        ?.split(",")
                        ?.map(
                          (tag, i) =>
                            tag && (
                              <Tag
                                handleTagRemove={(e) => {
                                  e.preventDefault();
                                  handleRemoveTag(tag);
                                }}
                                key={i}
                                tag={tag}
                              />
                            ),
                        )}
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <th>매거진 카테고리</th>
                <td>
                  <select
                    name="comp_explosure"
                    style={{ width: "10%" }}
                    defaultValue={magCategories?.[0].cate_seq}
                    {...register("mag_category")}>
                    <option value="">All</option>
                    {magCategories?.map((category) => {
                      return (
                        <option key={category.cate_seq} value={category.cate_seq}>
                          {category.cate_name}
                        </option>
                      );
                    })}
                  </select>
                </td>
              </tr>
              <tr>
                <th>매거진 이미지</th>
                <td
                  style={{
                    display: "flex",
                    gap: "20px",
                  }}>
                  <input
                    type="file"
                    style={{
                      display: "none",
                    }}
                    ref={uploadInput}
                    onChange={handleUpload}
                    accept="image/*, .pdf, .doc"
                    multiple={true}
                  />
                  <button
                    type="button"
                    onClick={() => uploadInput.current && uploadInput.current.click()}
                    style={{
                      padding: "13px 15px",
                      color: "black",
                      fontWeight: "bold",
                      backgroundColor: "grey",
                      fontSize: "11px",
                    }}>
                    파일첨부
                  </button>
                  {imageSeq && (
                    <div>
                      <img
                        src={getImageLink(imageSeq)}
                        className="img_upload"
                        alt="upload image"
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                      <button
                        type="button"
                        style={{
                          background: "gray",
                          padding: "2px",
                          borderRadius: "25px",
                          marginLeft: "4px",
                        }}
                        onClick={() => setImageSeq(null)}>
                        ⨉
                      </button>
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <th>상태</th>
                <td>
                  <select
                    name="exposed_YN"
                    defaultValue={magazine?.exposed_YN}
                    style={{ width: "10%" }}
                    {...register("exposed_YN", {})}>
                    <option value="Y">Y</option>
                    <option value="N">N</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>매거진 내용</th>
                <td>
                  {/* <textarea defaultValue={magazine?.mag_content} {...register("mag_content", {})} /> */}
                  <CustomEditor height={400} data={magContent} newContent={onMagContentChange} />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="adm_com_btn_wrap center bottom">
            <button
              className="adm_com_btn gray"
              onClick={(e) => {
                e.preventDefault();
                reset({});
                navigate("/boffice/board/magazine/list");
              }}>
              돌아가기
            </button>
            <button className="adm_com_btn gray" type="submit">
              수정하기 {isLoading && <i className="gg-spinner-alt" />}
            </button>
          </div>
        </form>
      </div>
      <PopCreateTag
        open={openCreateTag}
        close={() => setOpenCreateTag(false)}
        currentTags={watch("mag_tags")?.split(",")}
        addTag={handleAddTag}
        handleTagRemove={handleRemoveTag}
      />
    </main>
  );
}
