import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { mutateBackend } from "../../../../../api";
import {
  getUserCountByLicense,
  isEmailTaken,
  isIdTaken,
  isMobilePhoneTaken2,
  isNicknameTaken,
  getCompanyInfo
} from "../../../../../api/miscellaneous";
import Logo from "../../assets/img/logo.png";
import "../../assets/scss/page/member.scss";
import PopAgree from "../../components/PopAgreement";
import CorporateForm from "../../components/join/CorporateMemberForm";
import GeneralMemberForm from "../../components/join/GeneralMemberForm";
import SNSMemberForm from "../../components/join/SNSMemberForm";
import SNSCorporateForm from "../../components/join/SNSCorporateMemberForm";
import Modal from "../../components/modal";
import { AuthContext } from "../../../../../context/Auth";

const FormType = Object.freeze({
  General: 0,
  Corporate: 1,
});

const tabs = [
  { type: FormType.General, text: "일반회원" },
  { type: FormType.Corporate, text: "기업회원" },
];

const defaultValues = {
  user_id: "",
  user_pwd: "",
  user_name: "",
  user_email: "",
  user_nickname: "",
  user_mobile: "",
  recomm_code: "", //optional
  user_department: "", //optional
  comp_name: "",
  comp_reg_no: "",
  reg_certi_seq: null, //attachment file seq, response from Upload API.
  ceo_name: "",
  comp_address: "",
  comp_add_detail: "",
  comp_intro: "",
  comp_home_url: "",
  join_type: "", //optional
  agree01: false,
  agree02: false,
  agree03: false,
  agree_all: false,
};
function Join() {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const joinType = searchParams.get("snstype"); // offset 값 변수에 저장
  // const joinEmail = searchParams.get("email"); // offset 값 변수에 저장
  const joinCid = searchParams.get("cid"); // offset 값 변수에 저장
  const formTypeParam = searchParams.get("type");

  const { login } = useContext(AuthContext);

  const [formType, setFormType] = useState(formTypeParam === "COM" ? 1 : 0);

  const [showAgreeCompany, setShowAgreeCompany] = useState(false);
  const [showRecruitService, setShowRecruitService] = useState(false);
  const [showAgree, setShowAgree] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errMsg, setErrMsg] = useState([]);
  const [isVisible, setVisible] = useState(false);
  const [companyUserSave, setcompanyUserSave] = useState(true);


  const { formState, handleSubmit, register, reset, setError, setValue, trigger, watch } = useForm({
    defaultValues,
    mode: "onChange",
  });

  const [formSubmitting, setFormSubmitting] = useState(false);

  const checkId = async (user_id) => {
    const idTaken = await isIdTaken(user_id).catch(() => {
      setShowErrorModal(true);
      return true;
    });

    if (idTaken) {
      setError("user_id", {
        type: "userIdTaken",
        message: "이미 사용중인 ID 입니다.", // User Id already taken
      });
    }
    return idTaken;
  };

  const checkNickname = async (nickname) => {
    const nicknameTaken = await isNicknameTaken(nickname).catch(() => {
      setShowErrorModal(true);
      return true;
    });
    if (nicknameTaken) {
      setError("user_nickname", {
        type: "required",
        message: "이미 사용중인 닉네임 입니다.", // Nickname already taken
      });
    }
    return nicknameTaken;
  };

  const checkEmail = async (user_email) => {
    const emailTaken = await isEmailTaken(user_email).catch(() => {
      setShowErrorModal(true);
      return true;
    });
    if (emailTaken) {
      setError("user_email", {
        type: "required",
        message: "이미 가입된 이메일 입니다.", // Email already taken
      });
    }
    return emailTaken;
  };

  const checkMobilePhone = async (user_mobile) => {
    const mobileNumberTaken = await isMobilePhoneTaken2(user_mobile, formType === FormType.General ? "PSN" : "COM").catch(() => {
      setShowErrorModal(true);
      return true;
    });

    if (mobileNumberTaken) {
      setError("user_mobile", {
        type: "required",
        message: "이미 사용중인 가입된 번호 입니다.", // Mobile phone already taken
      });
    }
    return mobileNumberTaken;
  };

  async function userCountUnderLicenseValid(comp_reg_no) {

    return await getUserCountByLicense(comp_reg_no).catch(() => {
      //user_ids
      setShowErrorModal(true);
      return { show: false, comp_name: "", user_emails: [], user_ids: [] };
    });
  }

  const onValidSubmit = async (data) => {

    setFormSubmitting(true);

    const emailTaken = await isEmailTaken(data.user_email);
    const idTaken = await checkId(data.user_id);
    const nicknameTaken = await checkNickname(data.user_nickname);
    const mobileNumberTaken = await checkMobilePhone(data.user_mobile);

    if (emailTaken || idTaken || nicknameTaken || mobileNumberTaken) {
      if (emailTaken) {
        setErrMsg((prevErr) => [...prevErr, "이미 등록된 이메일입니다."]);
      }
      if (idTaken) {
        setErrMsg((prevErr) => [...prevErr, "이미 등록된 아이디입니다."]);
      }
      if (nicknameTaken) {
        setErrMsg((prevErr) => [...prevErr, "이미 등록된 닉네임입니다."]);
      }
      if (mobileNumberTaken) {
        setErrMsg((prevErr) => [...prevErr, "이미 등록된 휴대폰 번호입니다."]);
      }

      setShowErrorModal(true);
      setFormSubmitting(false);
      return;
    }

    if (formType === FormType.General) {
      const response = await mutateBackend("/user/join", {
        data: {
          ...data,
          join_type: "NOR",
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (data) => await data.json())
        .catch((err) => {
          throw new Error(err);
        });

      if (response.messageString === "SUCCESS" && response.messageCode === 200) {
        await login(data.user_id, data.user_pwd);
        navigate("/member/JoinOk", { state: { user_type: formType, user_name: data.user_name } });
      } else {
        setShowErrorModal(true);
        setFormSubmitting(false);
      }
    } else if (formType === FormType.Corporate) {
      //기업회원 회원가입 횟수 초과 시도시
      if(!companyUserSave){
        setErrMsg((prevErr) => [...prevErr, "회원가입 가능한 횟수를 초과 합니다."]);
        setShowErrorModal(true);
        setFormSubmitting(false);
        return false;
      }
      const { count } = await getUserCountByLicense(data.comp_reg_no);

      if (count > 5) {
        setShowErrorModal(true);
        setFormSubmitting(false);
        return;
      }

      const response = await mutateBackend("/user/join-company", {
        data,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (data) => data.json())
        .catch((err) => {
          setFormSubmitting(false);
          setShowErrorModal(true);
          throw new Error(err);
        });

      if (response.messageString === "SUCCESS" && response.messageCode === 200) {
        await login(data.user_id, data.user_pwd);

        navigate("/member/JoinOk", { state: { user_type: formType, user_name: data.user_name } });
      } else {
        setShowErrorModal(true);
        setFormSubmitting(false);
      }
    }
  };

  const onValidSubmitSns = async (data) => {
    setFormSubmitting(true);

    const idTaken = await checkId(joinCid);
    const nicknameTaken = await checkNickname(data.user_nickname);
    const mobileNumberTaken = await checkMobilePhone(data.user_mobile);


    if (idTaken || nicknameTaken || mobileNumberTaken ) {
      if (idTaken) {
        setErrMsg((prevErr) => [...prevErr, "이미 등록된 아이디입니다."]);
      }
      if (nicknameTaken) {
        setErrMsg((prevErr) => [...prevErr, "이미 등록된 닉네임입니다."]);
      }
      if (mobileNumberTaken) {
        setErrMsg((prevErr) => [...prevErr, "이미 등록된 휴대폰 번호입니다."]);
      }

      setShowErrorModal(true);
      setFormSubmitting(false);
      return;
    }

    if (formType === FormType.General) {
      const response = await mutateBackend("/user/join", {
        data: {
          user_id: `${joinType}_${joinCid}_p`,
          userType: formType === FormType.General ? "PSN" : "COM",
          join_type: "SNS",
          user_pwd: `${joinType}_${joinCid}`,
          user_name: data.user_name,
          user_email: data.user_email,
          user_nickname: data.user_nickname,
          user_mobile: data.user_mobile,
          recomm_code: data.recomm_code,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (data) => await data.json())
        .catch((err) => {
          throw new Error(err);
        });

      if (response.messageString === "SUCCESS" && response.messageCode === 200) {
        await login(data.user_email, data.user_pwd);
        navigate("/member/JoinOk", { state: { user_type: formType, user_name: data.user_name } });
      } else {
        setShowErrorModal(true);
        setFormSubmitting(false);
      }
    } else if (formType === FormType.Corporate) {
      //기업회원 회원가입 횟수 초과 시도시
      if(!companyUserSave){
        setErrMsg((prevErr) => [...prevErr, "회원가입 가능한 횟수를 초과 합니다."]);
        setShowErrorModal(true);
        setFormSubmitting(false);
        return false;
      }
      const { count } = await getUserCountByLicense(data.comp_reg_no);
      if (count > 5) {
        setShowErrorModal(true);
        setFormSubmitting(false);
        return;
      }

      // const { count } = await getUserCountByLicense(data.comp_reg_no);

      // if (count != 0) {
      //   setShowErrorModal(true);
      //   setFormSubmitting(false);
      //   return;
      // }

      const response = await mutateBackend("/user/join-company", {
        data: {
          user_id: `${joinType}_${joinCid}_c`,
          user_pwd: `${joinType}_${joinCid}`,
          user_name: data.user_name,
          user_email: data.user_email,
          user_nickname: data.user_nickname,
          user_mobile: data.user_mobile,
          recomm_code: data.recomm_code,
          user_department: data.user_department,
          comp_name: data.comp_name,
          comp_reg_no: data.comp_reg_no,
          reg_certi_seq: data.reg_certi_seq,
          ceo_name: data.ceo_name,
          comp_address: data.comp_address,
          comp_add_detail: data.comp_add_detail,
          comp_intro: data.comp_intro,
          comp_home_url: data.comp_home_url
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (data) => data.json())
        .catch((err) => {
          setFormSubmitting(false);
          setShowErrorModal(true);
          throw new Error(err);
        });

      if (response.messageString === "SUCCESS" && response.messageCode === 200) {
        await login(data.user_email, data.user_pwd);

        navigate("/member/JoinOk", { state: { user_type: formType, user_name: data.user_name } });
      } else {
        setShowErrorModal(true);
        setFormSubmitting(false);
      }
    }
  };

  useEffect(() => {
    // reset(defaultValues);
  }, [reset, formType]);

  function fnPopup() {

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${process.env.REACT_APP_ENV === "development" ? process.env.REACT_APP_SERVER_BASE_URL_DEV : process.env.REACT_APP_SERVER_BASE_URL_PROD}/nice/EncData`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const CertData = JSON.parse(result);

        document.getElementById("EncodeData").value = CertData.data.sEncData;
        window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
        document.form_chk.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
        document.form_chk.target = "popupChk";
        document.form_chk.submit();
        setVisible(true);
      })
      .catch((error) => console.log("error", error));
  }

  return (
    <>
      <main className="p_join com_member">
        <h1 className="logo">
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </h1>

        {/* 나이스 본인인증 form */}
        <form name="form_chk" method="post">
          <input type="hidden" name="m" id="m" value="checkplusService" />
          <input type="hidden" name="EncodeData" id="EncodeData" value="" />
          <input type="hidden" name="birthdate" id="birthdate" value="" />
          <input type="hidden" name="requestnumber" id="requestnumber" value="" />
          <input type="hidden" name="responsenumber" id="responsenumber" value="" />
          <input type="hidden" name="authtype" id="authtype" value="" />
          <input type="hidden" name="name" id="name" value="" />
          <input type="hidden" name="gender" id="gender" value="" />
          <input type="hidden" name="nationalinfo" id="nationalinfo" value="" />
          <input type="hidden" name="dupinfo" id="dupinfo" value="" />
          <input type="hidden" name="mobileno" id="mobileno" value="" />
          <input type="hidden" name="mobileco" id="mobileco" value="" />
        </form>

        <form className="box" onSubmit={handleSubmit(joinCid && joinCid !== null ? onValidSubmitSns : onValidSubmit)}>
          <section className="com_tab">
            {tabs?.map((tab, idx) => {
              return (
                <button
                  key={idx}
                  className={tab.type === formType ? "tab active" : "tab"}
                  onClick={(e) => {
                    e.preventDefault();
                    setFormType(tab.type);
                  }}>
                  {tab.text}
                </button>
              );
            })}
          </section>
          {formType === FormType.General ? (
            joinCid && joinCid !== null ? (
              <SNSMemberForm
                checkEmail={checkEmail}
                checkId={checkId}
                checkMobilePhone={checkMobilePhone}
                checkNickname={checkNickname}
                formState={formState}
                register={register}
                trigger={trigger}
                watch={watch}
                fnPopup={fnPopup}
                isVisible={isVisible}
                setValue={setValue}
              />
            ) : (
              <GeneralMemberForm
                checkEmail={checkEmail}
                checkId={checkId}
                checkMobilePhone={checkMobilePhone}
                checkNickname={checkNickname}
                formState={formState}
                register={register}
                setValue={setValue}
                trigger={trigger}
                watch={watch}
                fnPopup={fnPopup}
                isVisible={isVisible}
              />
            )
          ) : (
            joinCid && joinCid !== null ? (
              <SNSCorporateForm
                checkEmail={checkEmail}
                checkId={checkId}
                checkMobilePhone={checkMobilePhone}
                checkNickname={checkNickname}
                formState={formState}
                register={register}
                setError={setError}
                setValue={setValue}
                trigger={trigger}
                userCountUnderLicenseValid={userCountUnderLicenseValid}
                watch={watch}
                fnPopup={fnPopup}
                isVisible={isVisible}
                getCompanyInfo={getCompanyInfo}
                setcompanyUserSave={setcompanyUserSave}
              />
            ) : (
              <CorporateForm
                checkEmail={checkEmail}
                checkId={checkId}
                checkMobilePhone={checkMobilePhone}
                checkNickname={checkNickname}
                formState={formState}
                register={register}
                setError={setError}
                setValue={setValue}
                trigger={trigger}
                userCountUnderLicenseValid={userCountUnderLicenseValid}
                watch={watch}
                fnPopup={fnPopup}
                isVisible={isVisible}
                getCompanyInfo={getCompanyInfo}
                setcompanyUserSave={setcompanyUserSave}
              />)
          )}
          <section className="com_agree_section">
            <article className="all">
              <input
                type="checkbox"
                className="com_chk"
                id="all"
                onClick={() => {
                  const agree_all = !watch("agree_all");
                  setValue("agree01", agree_all);
                  setValue("agree02", agree_all);
                  setValue("agree03", agree_all);
                }}
                {...register("agree_all")}
              />
              <label htmlFor="all">모두 동의합니다.</label>
            </article>
            <ul className="list">
              <li className="item">
                <div>
                  <input
                    type="checkbox"
                    className="com_chk"
                    id="agree01"
                    onClick={() =>
                      (watch("agree01") && setValue("agree_all", !watch("agree01"))) ||
                      (!watch("agree01") &&
                        watch("agree02") &&
                        watch("agree03") &&
                        setValue("agree_all", !watch("agree01")))
                    }
                    {...register("agree01", { required: true })}
                  />
                  <label htmlFor="agree01">
                    이용약관에 동의합니다. <span className="txt_required">(필수)</span>
                  </label>
                </div>
                <button className="com_btn txt" onClick={() => setShowAgree(true)}>
                  자세히
                </button>
              </li>
              {formState.errors.agree01 && <span className="txt_required">필수 선택 사항입니다.</span>}
              <li className="item">
                <div>
                  <input
                    type="checkbox"
                    className="com_chk"
                    id="agree02"
                    onClick={() =>
                      (watch("agree02") && setValue("agree_all", !watch("agree02"))) ||
                      (watch("agree01") &&
                        !watch("agree02") &&
                        watch("agree03") &&
                        setValue("agree_all", !watch("agree02")))
                    }
                    {...register("agree02", { required: true })}
                  />
                  <label htmlFor="agree02">
                    개인정보처리방침에 동의합니다. <span className="txt_required">(필수)</span>
                  </label>
                </div>
                <button className="com_btn txt" onClick={() => setShowPrivacy(true)}>
                  자세히
                </button>
              </li>
              {formState.errors.agree02 && <span className="txt_required">필수 선택 사항입니다.</span>}
              <li className="item">
                <div>
                  <input
                    type="checkbox"
                    className="com_chk"
                    id="agree03"
                    onClick={() =>
                      (watch("agree03") && setValue("agree_all", !watch("agree03"))) ||
                      (watch("agree01") &&
                        watch("agree02") &&
                        !watch("agree03") &&
                        setValue("agree_all", !watch("agree03")))
                    }
                    {...register("agree03")}
                  />
                  <label htmlFor="agree03">마케팅 활용(앱 push) 동의 및 광고 수신 동의 (선택)</label>
                </div>
                <button className="com_btn txt" onClick={() => setShowTerms(true)}>
                  자세히
                </button>
              </li>
            </ul>
          </section>
          <section className="com_btn_wrap">
            <button className="com_btn blue l" type="submit">
              가입 완료 {formSubmitting && <i className="gg-spinner-alt" />}
            </button>
          </section>
        </form>
      </main>
      {/*약관내용 팝업*/}
      <PopAgree
        open={showAgreeCompany}
        close={() => setShowAgreeCompany(false)}
        title="기업회원 이용약관"
        type="agreeCompany"
      />
      <PopAgree open={showAgree} close={() => setShowAgree(false)} title="이용약관" type="agree" />
      <PopAgree open={showPrivacy} close={() => setShowPrivacy(false)} title="개인정보 처리방침" type="privacy" />
      <PopAgree open={showTerms} close={() => setShowTerms(false)} title="서비스 이용약관" type="terms" />
      <PopAgree
        open={showRecruitService}
        close={() => setShowRecruitService(false)}
        title="적극구직 서비스 이용약관"
        type="RecruitService"
      />

      <Modal open={showErrorModal} close={() => setShowErrorModal(false)}>
        <h2 className="pop_tit required" style={{ color: "red" }}>
          ERROR
        </h2>
        <div className="content_container scroll pop_company_chk">
          <ul className="tit">
            {errMsg
              ? errMsg?.map((err, idx) => <li key={idx}>{err}</li>)
              : "서버에 문제가 있는 것 같습니다. 다시 시도해 주세요."}
          </ul>
        </div>
      </Modal>
    </>
  );
}

export default Join;
