import React from "react";
import Modal from "../../components/modal";

function PopDelete(props) {
  const { open, close } = props;

  return (
    <div className="p_modal modal_filter">
      {/* modal */}
      <Modal open={open} close={close} type="pop_apply pop_filter">
        <h2 className="pop_tit">Delete Post</h2>
        <p className="info_wrap">Are you sure want to delete this Post?</p>
        <div className="btn_container">
          <button className="com_btn m BtnPopClose" onClick={close}>
            Cancel
          </button>
          <button className="com_btn blue m BtnPopClose" onClick={() => props?.onSubmit?.()}>
            Delete
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default PopDelete;
