import React, { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { mutateBackend } from "../../../../../api";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import PopAgree from "../../components/PopAgreement";
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import Modal from "../../components/modal";
import { toast } from "react-toastify";

function Apply() {
  const { id = "new" } = useParams();
  const { state } = useLocation();
  const ticketCount = state?.ticketCount || 1;

  const [modalOpen, setModalOpen] = useState(false);
  const [modalAgreeOpen, setModalAgreeOpen] = useState(false);
  const [usedPoints, setUsedPoints] = useState(0);
  const [termsAgree, setTermsAgree] = useState(false);

  const { data: seminar = {} } = useCustomQuery(`/seminar?semin_seq=${id}`, {
    queryKey: ["getSeminar", id],
    select: (res) => res?.data?.seminars?.[0] ?? {},
    enabled: !!+id,
  });

  const { data: points = {} } = useCustomQuery(`/points/current`, {
    queryKey: ["getPoints"],
    select: (res) => res?.data,
    enabled: !!+id,
  });

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSeminarApplication = async (payRes) => {
    const seminResponse = await mutateBackend("/seminar/apply", {
      data: {
        semin_seq: seminar?.semin_seq,
        pay_seq: payRes.pay_seq,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (data) => await data.json())
      .catch((err) => {
        throw new Error(err);
      });
    if (seminResponse.messageString === "SUCCESS" && seminResponse.messageCode === 200) {
      console.log(seminResponse.data);
      setModalOpen(true);
    } else {
      console.log(seminResponse);
    }
  };

  const handleUpdateUserPoint = async (payRes) => {
    const pointsResponse = await mutateBackend("/points", {
      data: {
        points_val: payRes?.request_amt,
        rel_tables: "ces_seminar",
        rel_seq: seminar?.semin_seq,
        points_desc: "상품구매",
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (data) => await data.json())
      .catch((err) => {
        throw new Error(err);
      });
    if (pointsResponse.messageString === "SUCCESS" && pointsResponse.messageCode === 200) {
      console.log(pointsResponse.data);
      await handleSeminarApplication(payRes);
    } else {
      console.log(pointsResponse);
    }
  };

  const handlePayment = async () => {
    if (!termsAgree) {
      toast.error("구매조건 및 결제대행 서비스 약관에 동의하여야 합니다.(필수)");
      return;
    }
    if (usedPoints != seminar?.semin_price * ticketCount) {
      toast.error("Please enter valid points!");
      return;
    }

    const response = await mutateBackend("/payments", {
      data: {
        pay_status: "PS10",
        goods_type: "SEMINAR",
        goods_seq: seminar?.semin_seq,
        goods_name: seminar?.semin_title,
        dc_amt: 0,
        use_point: usedPoints,
        pay_method: "POINTS",
        pay_amt: 0,
        trans_id: "",
        join_num: ticketCount,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (data) => await data.json())
      .catch((err) => {
        throw new Error(err);
      });
    if (response.messageString === "SUCCESS" && response.messageCode === 200) {
      console.log(response.data);
      await handleUpdateUserPoint(response.data);
    } else {
      console.log(response);
    }
  };

  return (
    <main className="com_pay">
      <section className="sec_apply">
        <h2 className="pg_tit">세미나 신청하기</h2>
        <div className="box">
          <ul className="com_info_sec">
            <li className="field_box">
              <span className="tit">세미나명</span>
              <div className="info_box">{seminar?.semin_title}</div>
            </li>
            <li className="field_box">
              <span className="tit">세미나 일정</span>
              <div className="info_box">{new Date(seminar?.semin_date).toLocaleString()}</div>
            </li>
            <li className="field_box">
              <span className="tit">구매일자</span>
              <div className="info_box">{new Date().toLocaleString()}</div>
            </li>
            <li className="field_box">
              <span className="tit">취소가능 날짜</span>
              <div className="info_box">{new Date(seminar?.reg_end_dtm).toLocaleString()}까지</div>
            </li>
            <li className="field_box">
              <span className="tit">결제금액</span>
              <div className="info_box">{seminar?.semin_price}원</div>
            </li>
            <li className="field_box">
              <span className="tit">보유 포인트</span>
              <div className="info_box">{points.points || 0}P</div>
            </li>
            <li className="field_box">
              <span className="tit">보유 이벤트 포인트</span>
              <div className="info_box">0P</div>
            </li>
            <li className="field_box">
              <span className="tit">사용 포인트</span>
              <div className="info_box">
                <input
                  type="number"
                  className="input_num"
                  max={points.points || 0}
                  onChange={(e) => setUsedPoints(e.target.value)}
                />{" "}
                P <span className="block_m">(이벤트 포인트가 먼저 차감됩니다)</span>
              </div>
            </li>
            <li className="field_box">
              <span className="tit">총 결제금액</span>
              <div className="info_box">
                {seminar?.semin_price * ticketCount}원{" "}
                {ticketCount > 1 && `(${seminar?.semin_price}원 X ${ticketCount} tickets)`}
              </div>
            </li>
          </ul>
          <section className="com_agree_section">
            <ul className="list">
              <li className="item">
                <div>
                  <input
                    type="checkbox"
                    className="com_chk"
                    id="agree01"
                    checked={termsAgree}
                    onChange={(e) => setTermsAgree(e.target.checked)}
                  />
                  <label htmlFor="agree01">
                    구매조건 및 결제대행 서비스 약관에 동의하여야 합니다.<span className="txt_required">(필수)</span>{" "}
                    <br />
                    구매조건 및 개인정보취급방침에 동의합니다.
                  </label>
                </div>
                <button className="com_btn txt" onClick={() => setModalAgreeOpen(true)}>
                  자세히
                </button>
              </li>
            </ul>
          </section>
          <div className="com_btn_wrap center">
            <Link to="/Seminar" className="com_btn line m">
              이전으로
            </Link>
            <button className="com_btn blue m" onClick={handlePayment}>
              결제하기
            </button>
          </div>
        </div>
      </section>
      {/* top button */}
      <ScrollToTopButton />
      {/* modal */}
      {/*약관내용 팝업*/}
      <PopAgree open={modalAgreeOpen} close={() => setModalAgreeOpen(false)} title="이용약관" type="terms" />
      {/* alert */}
      <Modal open={modalOpen} close={handleCloseModal} type="type_alert">
        <div className="content_container scroll">
          <strong>결제가 완료되었습니다!</strong> <br />세미나 신청내역은 마이페이지에서 <br /> 확인 하실 수 있습니다.
        </div>
        <div className="btn_container">
          <Link to="/Seminar" className="com_btn blue s">
            확인
          </Link>
        </div>
      </Modal>
    </main>
  );
}

export default Apply;
