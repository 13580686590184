import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { deleteBackend, mutateBackend, mutateBackendFormData, updateBackend } from "../../../../../api";
import { AuthContext } from "../../../../../context/Auth";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import { getImageLink } from "../../../../../utils/misc";
import "../../assets/scss/page/community.scss";
import Upload from "../../components/Upload";
import FormInput from "../../components/join/form/FormInput";
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import PopCategory from "./_popCategory";

function Write() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [localFiles, setLocalFiles] = useState([]);

  const defaultValues = {
    board_type: "COMMTY",
    cate_seq: location?.state?.cate_seq || null,
    board_title: "",
    board_contents: "",
  };
  const { formState, handleSubmit, register, reset, trigger, watch, setValue } = useForm({
    defaultValues,
    mode: "all",
  });

  const { data: categories } = useCustomQuery(`/category?cate_gr=COMMU_CATE`, {
    queryKey: ["getCategoryList"],
    select: (res) => res?.data?.categories,
    onSuccess: async (data) => {
      isNaN(id) && !location?.state?.cate_seq && setValue("cate_seq", data?.[0]?.cate_seq);
    },
  });

  const { data: article } = useCustomQuery(`/community?board_seq=${+id}`, {
    queryKey: ["getCommunityArticle", { id }],
    select: (res) => ({ ...res?.data?.communities?.[0], files: res?.data?.communities?.[0]?.files?.split(",") }),
    onSuccess: async (data) => {
      reset({
        ...defaultValues,
        cate_seq: data?.cate_seq,
        board_title: data?.board_title,
        board_contents: data?.board_contents,
      });
    },
    enabled: +id > 0,
  });

  const handleCommunityUpload = async (board_seq, ids = []) => {
    const response = await mutateBackend("/community/upload", {
      data: { board_seq, ids },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (data) => await data.json())
      .catch((err) => {
        throw new Error(err);
      });

    if (response.messageString === "SUCCESS" && response.messageCode === 200) {
      console.log("handleCommunityUpload", response.data);
      setIsLoading(false);
      return;
    } else {
      setIsLoading(false);
      return;
    }
  };

  const handleUpload = async (board_seq) => {
    const formData = new FormData();
    localFiles.forEach((file) => {
      formData.append("file", file);
    });
    formData.append("upload_ty", "community-board");

    const response = await mutateBackendFormData("/upload/image", formData)
      .then(async (data) => await data.json())
      .catch(() => ({ messageCode: 400 }));

    if (response.messageCode === 200) {
      console.log("handleUpload", response.data);
      response.data?.length > 0 &&
        (await handleCommunityUpload(
          board_seq,
          response.data.map((obj) => obj?.up_file_seq),
        ));
      return;
    } else {
      console.log("file failed");
      return;
    }
  };

  const handleDeleteFile = async (id) => {
    const response = await deleteBackend(`/upload/${id}`)
      .then(async (data) => await data.json())
      .catch(() => ({ messageCode: 400 }));

    if (response.messageCode === 200) {
      console.log({ response });
    } else {
      console.log("file failed");
    }
  };

  const onValidSubmit = async (data) => {
    setIsLoading(true);
    console.log({ data });
    if (+id) {
      if (
        article?.board_title !== data.board_title ||
        article?.board_contents !== data.board_contents ||
        article?.cate_seq !== data.cate_seq
      ) {
        const params = { board_seq: +id };

        article?.board_title !== data.board_title && (params.board_title = data.board_title);
        article?.board_contents !== data.board_contents && (params.board_contents = data.board_contents);
        article?.cate_seq !== data.cate_seq && (params.cate_seq = data.cate_seq);

        const response = await updateBackend("/community", {
          data: params,
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then(async (data) => await data.json())
          .catch((err) => {
            throw new Error(err);
          });
        if (response.messageString === "SUCCESS" && response.messageCode === 200) {
          console.log(response.data);
        } else {
          setIsLoading(false);
          console.log(response);
        }
      }
      if (localFiles.length > 0) await handleUpload(+id);
      navigate(`/community/detail/${article?.board_seq}`, { state: { cate_seq: data.cate_seq } });
    } else {
      const response = await mutateBackend("/community", {
        data: {
          ...data,
          user_seq: user.user_seq,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (data) => await data.json())
        .catch((err) => {
          throw new Error(err);
        });
      if (response.messageString === "SUCCESS" && response.messageCode === 200) {
        console.log(response.data);
        if (localFiles.length > 0) await handleUpload(response.data.communityId);
        navigate("/community", { state: { cate_seq: data.cate_seq } });
      } else {
        setIsLoading(false);
        console.log(response);
      }
    }
  };

  return (
    <main className="com_pay p_write">
      <h2 className="pg_tit">{+id > 0 ? "게시물 수정" : "게시물 작성"}</h2>
      <form className="box" onSubmit={handleSubmit(onValidSubmit)}>
        <ul className="com_input_sec">
          <li className="tit_field">카테고리 선택</li>
          <li className="input_field">
            <div className="com_tag_list">
              {categories?.length > 0 &&
                categories?.map((obj, index) => (
                  <span
                    key={index}
                    onClick={() => setValue("cate_seq", obj.cate_seq)}
                    className={"com_btn bg xs lightblue " + (obj.cate_seq === watch("cate_seq") ? "active" : "")}>
                    {obj.cate_name}
                  </span>
                ))}
              <button type="button" onClick={() => setIsCategoryOpen(true)} className="com_btn bg xs lightblue">
                ...
              </button>
            </div>
            <FormInput
              id={"board_title"}
              formState={formState}
              label={"제목"}
              placeholder={"제목을 입력해주세요."}
              register={register}
              required="제목을 입력해주세요."
              rules={{ minLength: 2 }}
              type={"text"}
              trigger={trigger}
              watch={watch}
            />
            <FormInput
              id={"board_contents"}
              formState={formState}
              label={"내용"}
              placeholder={"내용 입력."}
              register={register}
              required="내용 입력."
              rules={{ minLength: 2 }}
              type={"textarea"}
              trigger={trigger}
              watch={watch}
            />
          </li>
        </ul>
        <div className="com_attach_section type_community">
          {article?.files?.length > 0 &&
            article?.files?.map((up_file_seq) => (
              <p className="img_box" key={up_file_seq}>
                <img src={getImageLink(up_file_seq)} className="img_upload" alt={up_file_seq} />
                <button type="button" className="btn_del" onClick={() => handleDeleteFile(up_file_seq)} />
              </p>
            ))}
          {localFiles?.length > 0 &&
            localFiles?.map((obj, i) => {
              const url = URL.createObjectURL(obj);
              return (
                <p className="img_box" key={i}>
                  <img src={url} className="img_upload" alt={obj.up_file_seq} />
                  <button
                    type="button"
                    className="btn_del"
                    onClick={() => setLocalFiles(localFiles?.filter((_, index) => index !== i))}
                  />
                </p>
              );
            })}
          <Upload multiple setFiles={(newFiles) => setLocalFiles([...localFiles, ...newFiles])} files={localFiles} />
        </div>
        <section className="com_btn_wrap center">
          <button
            onClick={() => navigate("/community", { state: { cate_seq: watch("cate_seq") } })}
            className="com_btn line m">
            작성취소
          </button>
          <button type="submit" className="com_btn blue m">
            {+id > 0 ? "게시물 수정" : "게시물 작성"} {isLoading && <i className="gg-spinner-alt" />}
          </button>
        </section>
      </form>
      {/* top button */}
      <ScrollToTopButton />
      {/* Modal */}
      <PopCategory
        open={isCategoryOpen}
        categories={categories}
        close={() => setIsCategoryOpen(false)}
        currentCategory={watch("cate_seq")}
        setValue={setValue}
      />
    </main>
  );
}

export default Write;
