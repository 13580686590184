import React from "react";
import { Link } from "react-router-dom";
import { getImageLink } from "../../../../../utils/misc";

function Item(props) {
  const {
    insig_name,
    insig_seq,
    insig_thumb,
    insig_price,
    insig_tags,
    insig_views,
    insig_likes,
    insig_comments,
    user_name,
  } = props;

  return (
    <Link to={`/Insight/${insig_seq}`} className="com_card_item">
      <div className="thumb">
        <span className="price">{insig_price}</span>
        {/* <img src={getImageLink(insig_thumb)} className="thumb_img" alt="mentoring1" /> */}
        <img
          src={Number(insig_thumb) > 0 ? getImageLink(insig_thumb) : insig_thumb}
          className="thumb_img"
          alt="mentoring1"
        />
      </div>
      <div className="info">
        <div className="icon_wrap">
          {insig_tags.map((tag) => (
            <span key={tag} className="icon_line_blue">
              {tag}
            </span>
          ))}
          <div className="icon_wrapper">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
            {insig_tags.map((tag) => (
              <span key={tag} className="icon_line_blue">
                {tag}
              </span>
            ))}
          </div>
        </div>
        <p className="title">{insig_name}</p>
        <p className="info_s">
          <span className="com_point_txt">{user_name} </span>
          <span className="sm_remove"> ㆍ </span>좋아요 {insig_likes} ㆍ 댓글 {insig_comments} ㆍ 조회수 {insig_views}
        </p>
      </div>
    </Link>
  );
}

export default Item;
