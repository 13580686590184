/* eslint-disable */
import React, { useEffect, useState } from "react";
import Paging from "../../component/Paging";
import { useAuthenticatedQuery } from "../../../../../hooks/useAPI";
import { Link } from "react-router-dom";
import Table from "../../../screens/components/boffice/Table";
import MemberFilter from "../../component/MemberFilter";

const columns = {
  user_seq: "번호",
  user_id: "아이디",
  user_name: "이름",
  user_nickname: "닉네임",
  user_email: "이메일",
  user_mobile: "휴대폰 번호",
  reg_dtm: "가입일자",
  withdrawn_at: "탈퇴일자",
};

function WithdrawnMemberList() {
  const [page, setPage] = useState(1);
  const [withdrawnUserPerPage, setWithdrawnUserPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const [withdrawnMemberList, setWithdrawnMemberList] = useState([]);

  const [paramArguments, setParamArguments] = useState("");

  const { data, isFetching } = useAuthenticatedQuery(
    ["getWithdrawnUserList/" + paramArguments, page, withdrawnUserPerPage],
    `/admin/users/withdrawn/search?per_page=${withdrawnUserPerPage}&curr_page=${page}&` + paramArguments,
  );

  useEffect(() => {
    const updateData = async (data) => {
      const response = await data.json();

      if (response.messageCode === 200) {
        setTotalCount(response.data.total_cnt);
        setWithdrawnMemberList(response.data.data_list);
      }
    };

    !isFetching && data && !data.bodyUsed && updateData(data);
  }, [data, isFetching]);

  const handlePageClick = (toPage) => {
    if (Number.isInteger(toPage)) return setPage(toPage);

    switch (toPage) {
      case "first": {
        setPage(1);
        break;
      }
      case "next": {
        page < Math.floor(totalCount / withdrawnUserPerPage) && setPage((prev) => prev + 1);
        break;
      }
      case "prev": {
        page > 1 && setPage((prev) => prev - 1);
        break;
      }
      case "last": {
        setPage(Math.ceil(totalCount / withdrawnUserPerPage));
        break;
      }
    }
  };

  return (
    <main>
      <h2 className="adm_com_pg_tit2 bottom_line"> 회원 관리</h2>
      <div className="adm_com_pg_content">
        <h2 className="sub_tit">- 탈퇴 회원 관리</h2>
        <MemberFilter
          additionalFields={<WithdrawnDateFilter setParamArguments={setParamArguments} />}
          columns={columns}
          dataPerPage={withdrawnUserPerPage}
          defaultSearchColumn={"user_name"}
          setDataPerPage={setWithdrawnUserPage}
          setParamArguments={setParamArguments}
        />
        <div className="sub_tit">
          <span className="txt flex-start">
            {totalCount} 건 {isFetching && <i className="gg-spinner-alt" />}
          </span>
        </div>
        <Table data={withdrawnMemberList} columns={columns} />
        <Paging
          handlePageClick={handlePageClick}
          page={page}
          totalPageCount={Math.ceil(totalCount / withdrawnUserPerPage)}
        />
      </div>
    </main>
  );
}

const WithdrawnDateFilter = ({ setParamArguments }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleDateFilter = (days) => {
    const today = new Date();
    const newEndDate = new Date();
    const newStartDate = new Date();

    newEndDate.setDate(today.getDate());
    newStartDate.setDate(today.getDate() - days);

    setStartDate(newStartDate.toISOString().split("T")[0]);
    setEndDate(newEndDate.toISOString().split("T")[0]);
  };

  useEffect(() => {
    const params = new URLSearchParams();

    if (startDate !== "") params.set("withdrawal_start_date", startDate);
    if (endDate !== "") params.set("withdrawal_end_date", endDate);

    setParamArguments(params.toString());
  }, [startDate, endDate]);

  return (
    <div className="adm_com_date_select">
      <span className="tit">검색 인출 날짜</span>
      <input
        type="date"
        className="adm_com_datepicker"
        placeholder={new Date().toLocaleDateString()}
        value={startDate}
        onChange={handleStartDateChange}
      />
      <span className="divider"> ~ </span>
      <input
        type="date"
        className="adm_com_datepicker"
        placeholder={new Date().toLocaleDateString()}
        value={endDate}
        onChange={handleEndDateChange}
      />
      {/* <button className="adm_com_btn gray">조회</button> */}
      <div className="adm_com_btn_wrap">
        <button className="adm_com_btn line gray" onClick={() => handleDateFilter(0)}>
          오늘
        </button>
        <button className="adm_com_btn line gray" onClick={() => handleDateFilter(3)}>
          3일전
        </button>
        <button className="adm_com_btn line gray" onClick={() => handleDateFilter(7)}>
          일주일
        </button>
        <button className="adm_com_btn line gray" onClick={() => handleDateFilter(30)}>
          1개월
        </button>
        <button className="adm_com_btn line gray" onClick={() => handleDateFilter(90)}>
          3개월
        </button>
        <button
          className="adm_com_btn line gray"
          onClick={() => {
            setStartDate("");
            setEndDate("");
          }}>
          전체
        </button>
      </div>
    </div>
  );
};

export default WithdrawnMemberList;
