// @ts-nocheck
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm, Controller  } from "react-hook-form";
import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import { mutateBackend, mutateBackendFormData } from "../../../../../../api";
import { AuthContext } from "../../../../../../context/Auth";
import { getImageLink } from "../../../../../../utils/misc";
import Modal from "../../modal";
import Tag from "./Tag";
import PopCreateTag from "./_popCreateTag";
import useCommCode from "../../../../../../hooks/useCommCode";

export default function NewRecruit({ categoryTypes, defaultParent, employmentTypes, recruitTypes }) {
  const navigate = useNavigate();

  const { user } = useContext(AuthContext);
  const [modalWriteOpen, setModalWriteOpen] = useState(false);
  const [showAddressSelect, setShowAddressSelect] = useState(false);

  const [openCreateTag, setOpenCreateTag] = useState(false);

  const [formSubmitting, setFormSubmitting] = useState(false);

  const [recruitTags, setRecruitTags] = useState([]);
  const [parentCategory, setParentCategory] = useState(defaultParent);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [minCloseDate, setMinCloseDate] = useState(new Date().toISOString().split("T")[0]);

  const [imageSeq, setImageSeq] = useState(0);
  const [jobLocation, setJobLocation] = useState("");

  const [isDateInputEnabled, setIsDateInputEnabled] = useState(true);
  const [isNewcomerInputEnabled, setIsNewcomerInputEnabled] = useState(true);

  const { data: locationTypeData } = useCommCode(`LOCA_CD`);

  const handleCloseWriteModal = () => {
    setModalWriteOpen(false);
  };

  const { clearErrors, formState: { errors }, getValues, handleSubmit, register, setError, setValue, control } = useForm();

  const uploadInput = useRef();

  useEffect(() => {
    setValue("image_seq", imageSeq);
  }, [imageSeq]);

  useEffect(() => {
    setValue("job_location", jobLocation["comm_code"]);
  }, [jobLocation]);

  const getCateText = (cate) => {
    const cateIn = categoryTypes.find((cat) => cat && cat["cate_seq"] == cate);
    if (cateIn && cateIn["cate_name"]) return cateIn["cate_name"];
    else return null;
  };

  const handleAddAnnouncementTag = (newTag) => {
    if (recruitTags.find((x) => x === newTag) || newTag === "") return;
    recruitTags.push(newTag);
    setRecruitTags(recruitTags);
  };

  const handleAnnouncementTagRemove = (i) => {
    const previousTags = recruitTags;
    const newTags = previousTags.filter((tag, k) => k !== i);
    setRecruitTags(newTags);
  };

  const handleAddCategory = (cate_seq) => {
    if (selectedCategories.length === 3) return;
    if (selectedCategories.find((x) => x === cate_seq)) return;
    setSelectedCategories([...selectedCategories, cate_seq]);
  };

  const handleCategoryTagRemove = (i) => {
    const newTags = selectedCategories.filter((tag, k) => k !== i);
    setSelectedCategories(newTags);
  };

  async function handleUpload(event) {
    const formData = new FormData();

    formData.append("file", event.target.files[0]);
    formData.append("upload_ty", new Date().toISOString());

    const response = await mutateBackendFormData("/upload/file", formData)
      .then(async (data) => await data.json())
      .catch(() => ({ messageCode: 400 }));

    if (response.messageCode === 200) {
      setImageSeq(response.data[0].up_file_seq);
    }
  }

  const handleCreateRecruit = async (data) => {
    const validateFields = (data) => {
      let recruitTypeValid = false;
      recruitTypes.forEach((type) => {
        recruitTypeValid = recruitTypeValid || Boolean(data[type["comm_code"]]);
      });

      if (!recruitTypeValid) {
        setError("recruitType", { message: "recruitType" });
        return false;
      }

      // if (!data.image_seq || data.image_seq === 0) {
      //   setError("image_seq", { message: "image_seq" }, { shouldFocus: true });
      //   return false;
      // }

      return true;
    };

    const fieldsAreValid = validateFields(data);

    if (!fieldsAreValid) {
      handleCloseWriteModal();
      return;
    }

    setFormSubmitting(true);

    const recruitTagString = recruitTags.join(",");
    const selectedCategoryString = selectedCategories.join(",");

    let finalData = {
      ...data,
      comp_seq: user.comp_seq,
      recruit_location: data.job_location,
      recruit_status: "OPEN",
      recruit_tags: recruitTagString,
      recruit_categories: selectedCategoryString,
      newcomer_YN: data.newcomer_YN ? "Y" : "N",
    };

    recruitTypes.forEach((type) => {
      if (Boolean(data[type["comm_code"]]) === true) finalData = { ...finalData, close_type: type["comm_code"] };
    });

    // console.log(finalData);

    const response = await mutateBackend("/recruit", {
      data: finalData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (data) => data.json())
      .catch((err) => {
        setFormSubmitting(false);
        // toast.error("작업 게시물을 제출하지 못했습니다.");
        setModalWriteOpen(true)
        throw new Error(err);
      });

    if (response.messageCode === 200) {
      // toast.success("성공적으로 작업을 게시했습니다.");
      setModalWriteOpen(true)
      // navigate(0);
    }
    setFormSubmitting(false);
  };


  const handleCheckbox = (btn) => {
    if(btn === "PERIOD" || btn === "ANY"){
      setIsDateInputEnabled(true)
    }
  }


  return (
    <form onSubmit={handleSubmit(handleCreateRecruit)}
      className="tab_content">
      <ul className="com_input_sec type_row">
        {/* Image Field */}
        <li className="field_box">
          <span className="tit">공고 이미지</span>
          <div className="input_box">
            <div className="input_wrap">
              <div className="com_attach_section">
                <div className="com_input_upload">
                  <input
                    type="file"
                    className="input_file"
                    ref={uploadInput}
                    onChange={handleUpload}
                    accept="image/*, .pdf, .doc"
                    multiple={true}
                  />
                  <button
                    type="button"
                    onClick={() => uploadInput.current && uploadInput.current.click()}
                    // disabled={props?.files?.length > 4}
                    className={`btn_upload com_btn line s`}>
                    {"이미지 찾기"}
                  </button>
                </div>
                {imageSeq !== 0 && (
                  <>
                    <p className="img_box">
                      <img src={getImageLink(imageSeq)} className="img_upload" alt="upload image" />
                      <button type="button" className="btn_del" onClick={() => setImageSeq(0)}></button>
                    </p>
                    <span className="txt">공고 배경.jpg</span>
                  </>
                )}
                <span className="txt">170px * 170px 사이즈로 이미지를 올려주세요.</span>
              </div>
            </div>
            {(Object.keys(errors).length !== 0 && getValues('image_seq') === 0) && (
              <p style={{ color: "red" }}>공고 이미지를 첨부해주세요</p>
            )}
          </div>
        </li>
        {/* Title Field */}
        <li className="field_box">
          <span className="tit">공고 제목</span>
          <div className="input_box">
            <input type="text" {...register("recruit_title", { required: "공고제목을 입력해주세요." })} className="input" />
            {errors.recruit_title && (
              <p style={{ color: "red" }}>{errors.recruit_title.message}</p>
            )}
          </div>
        </li>
        {/* Tags Field */}
        <li className="field_box">
          <span className="tit">공고 태그</span>
          <div className="input_box">
            <button onClick={() => setOpenCreateTag(true)} className="com_btn line s">
              태그 추가
            </button>
            <ul className="com_tag_list">
              {recruitTags.map(
                (tag, i) => tag && <Tag handleTagRemove={() => handleAnnouncementTagRemove(i)} key={i} tag={tag} />,
              )}
            </ul>
          </div>
        </li>
        {/* Employment Type Field */}
        <li className="field_box">
          <span className="tit">고용형태</span>
          <div className="input_box">
            <select className="select s" defaultValue={""} {...register("emp_type", { required: true })}>
              {employmentTypes.map((emp_type, i) => (
                <option value={emp_type["comm_code"]} key={i}>
                  {emp_type["code_nm_kr"]}
                </option>
              ))}
            </select>
          </div>
        </li>
        {/* Posting Date Fields */}
        <div className="input_box">
          <li className="field_box">
          <span className="tit">공고기간</span>
          <div className="input_box">
            <div className="input_wrap date">
              <article className="date_select type_input">
                <input
                  type="date"
                  min={new Date().toISOString().split("T")[0]}
                  defaultValue={new Date().toISOString().split("T")[0]}
                  className="com_datepicker"
                  {...register("open_date", {
                    required: "시작일을 입력해주세요.",
                    onChange: (e) => setMinCloseDate(new Date(e.target.value).toISOString().split("T")[0]),
                    // setValueAs: (v) => new Date(v).toISOString().split("T")[0],
                  })}
                />
                {errors.open_date && (
                  <div style={{ color: "red" }}>{errors.open_date.message}</div>
                )}
             </article>
             <article className="date_select type_input">
                <span className="icon">~</span>
                <input
                  type="date"
                  min={minCloseDate}
                  className="com_datepicker"
                  {...register("close_date", { required: isDateInputEnabled ? !getValues("ANY")  : false })}
                  disabled={!isDateInputEnabled}
                />
                {isDateInputEnabled && errors.close_date && (
                  <div style={{ color: "red", marginLeft: "14%"}}>{"마감일을 입력하세요."}</div>
                )}
              </article>
            
              <div className="chk_wrap">
                {recruitTypes.map((type, i) => (
                  <div key={i}>
                    <input
                      type="checkbox"
                      id={type["comm_code"]}
                      className="com_chk"
                      value={getValues(type["comm_code"])}
                      {...register(type["comm_code"], {
                        onChange: (e) => {
                          clearErrors("recruitType");
                          handleCheckbox(type["comm_code"]);
                          recruitTypes.map((otherType) => {
                            if (e.target.checked && type["comm_code"] !== otherType["comm_code"]) {
                              setValue(otherType["comm_code"], false);
                            }
                            if (type["comm_code"] === "ANY" || type["comm_code"] === "UNTI") {
                              setIsDateInputEnabled(false); // 마감일 입력 비활성화
                            } else {
                              setIsDateInputEnabled(true);
                            }
                          });
                        },
                        setValueAs: (v) => Boolean(v),
                      })}
                    />
                      
                    <label className="label" htmlFor={type["comm_code"]}>
                      {type["code_nm_kr"]}
                    </label>
                  </div>
                ))}
                {errors.recruitType && (
                  <p style={{ color: "red" }}>채용 유형을 선택해주세요.</p>
                )}
              </div>
            </div>
          </div>
        </li>
        </div>
        {/* Job Category Fields */}
        <li className="field_box">
          <span className="tit">직무</span>
          <div className="input_box">
            {/* <div className="input_wrap">
              <select
                className="select s"
                onChange={(e) => {
                  setParentCategory(Number(e.target.value));
                }}>
                <option value="">직무를 선택하세요</option>
                {categoryTypes.map(
                  (category, i) =>
                    category &&
                    category["parent_seq"] === 0 && (
                      <option value={category["cate_seq"]} key={i}>
                        {category["cate_name"]}
                      </option>
                    ),
                )}
              </select>
              <select
                className="select s"
                onChange={(e) => handleAddCategory(Number(e.target.value))}
                >
                <option value="">상세직무를 선택하세요</option>
                {categoryTypes.map((category, i) => {
                  return (
                    category &&
                    category["parent_seq"] === parentCategory && (
                      <option value={category["cate_seq"]} key={i}>
                        {category["cate_name"]}
                      </option>
                    )
                  );
                })}
              </select>
            </div> */}
            <div className="input_wrap">
            <Controller
              name="parentCategory"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <select
                  className="select s"
                  onChange={(e) => {
                    setParentCategory(Number(e.target.value));
                    field.onChange(e);
                  }}
                >
                  <option value="">직무를 선택하세요</option>
                  {categoryTypes.map(
                    (category, i) =>
                      category &&
                      category["parent_seq"] === 0 && (
                        <option value={category["cate_seq"]} key={i}>
                          {category["cate_name"]}
                        </option>
                      )
                  )}
                </select>
              )}
            />
            <Controller
              name="category"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <select
                  className="select s"
                  onChange={(e) => {
                    handleAddCategory(Number(e.target.value));
                    field.onChange(e);
                  }}
                >
                  <option value="">상세직무를 선택하세요</option>
                  {categoryTypes.map(
                    (category, i) =>
                      category &&
                      category["parent_seq"] === parentCategory && (
                        <option value={category["cate_seq"]} key={i}>
                          {category["cate_name"]}
                        </option>
                      )
                  )}
                </select>
              )}
            />
          </div>
            <span className="txt">직무는 최대 3개까지 선택 가능합니다.</span>
            <ul className="com_tag_list select_list">
              {selectedCategories.map(
                (tag, i) =>
                  getCateText(tag) && (
                    <Tag handleTagRemove={() => handleCategoryTagRemove(i)} key={i} tag={getCateText(tag)} />
                  ),
              )}
            </ul>
          </div>
        </li>
        {/* Career Requirements Field */}
         <li className="field_box">
          <span className="tit">경력</span>
          <div className="input_box">
            <div className="input_wrap ">
              <input
                type="number"
                className="input input_carrer"
                {...register("career_min", {
                  // required: getValues("newcomer_YN") !== "Y",
                  required: isNewcomerInputEnabled ? !getValues("newcomer_YN")  : false 
                })}
               disabled={!isNewcomerInputEnabled}
              />
              <span className="icon">~</span>
              <input
                type="number"
                className="input input_carrer"
                {...register("career_max", {
                  // required: getValues("newcomer_YN") !== "Y",
                  // valueAsNumber: true,
                  // validate: (v) => v >= getValues("career_min"),
                  required: isNewcomerInputEnabled ? !getValues("newcomer_YN")  : false 
                })}
                disabled={!isNewcomerInputEnabled}
              />
              <span className="icon">년</span>
              <div className="chk_wrap">
                <input
                  type="checkbox"
                  id="career"
                  className="com_chk"
                  value={getValues("newcomer_YN")}
                  {...register("newcomer_YN", { setValueAs: (v) => (v ? "Y" : "N"),
                    onChange: (e) => {
                      clearErrors("newcomer_YN");
                      if (getValues("newcomer_YN")){
                        setIsNewcomerInputEnabled(false);
                      }else if(!e.target.checked){
                        setIsNewcomerInputEnabled(true);
                      }
                    }
                  })}  
                />
                <label className="label" htmlFor="career">
                  신입
                </label>
              </div>
            </div>
            {((Object.keys(errors).length !== 0 && ((getValues('newcomer_YN') === false) && (errors.career_max || errors.career_max)))) && <p style={{ color: "red" }}>경력을 입력해주세요.</p>}
          </div>
        </li>
        {/* Job Location Field */}
        <li className="field_box">
          <span className="tit">근무지역</span>
          <div className="input_box">
            <div className="input_wrap">
              <input
                type="text"
                placeholder="서울"
                className="input"
                style={{ maxWidth: "20%" }}
                disabled
                value={jobLocation ? jobLocation.code_nm_kr : ""}
              />
              <input type="text" hidden disabled {...register("job_location", { required: "근무지역을 선택해주세요." })} />
              <button
                type="button"
                className="com_btn line s"
                onClick={() => {
                  setShowAddressSelect(true);
                  setJobLocation("");
                }}>
                지역선택
              </button>
            </div>
            {errors.job_location && <p style={{ color: "red" }}>{errors.job_location.message}</p>}
          </div>
        </li>

        <li className="field_box">
          <span className="tit">주요업무</span>
          <div className="input_box">
            <textarea
              className="textarea"
              placeholder="내용입력"
              {...register("recruit_job", { required: "주요업무를 입력해주세요." })}></textarea>
            {errors.recruit_job && <p style={{ color: "red" }}>{errors.recruit_job.message}</p>}
          </div>
        </li>
        <li className="field_box">
          <span className="tit">자격요건</span>
          <div className="input_box">
            <textarea
              className="textarea"
              placeholder="내용입력"
              {...register("recruit_qualify", { required: "자격요건을 입력해주세요." })}></textarea>
              {errors.recruit_qualify && <p style={{ color: "red" }}>{errors.recruit_qualify.message}</p>}
          </div>
        </li>
        <li className="field_box">
          <span className="tit">우대사항</span>
          <div className="input_box">
            <textarea
              className="textarea"
              placeholder="내용입력"
              {...register("recruit_consider", { required: "우대사항을 입력해주세요." })}></textarea>
              {errors.recruit_consider && <p style={{ color: "red" }}>{errors.recruit_consider.message}</p>}
          </div>
        </li>
        <li className="field_box">
          <span className="tit">혜택 및 복지</span>
          <div className="input_box">
            <textarea
              className="textarea"
              placeholder="내용입력"
              {...register("recruit_benefit", { required: "혜택 및 복지를 입력해주세요." })}></textarea>
              {errors.recruit_benefit && <p style={{ color: "red" }}>{errors.recruit_benefit.message}</p>}
          </div>
        </li>
      </ul>
      <div className="com_btn_wrap center">
        <button
          className="com_btn blue s"
          type="submit">
          공고 등록{formSubmitting && <i className="gg-spinner-alt" />}
        </button>
        <button className="com_btn line blue s">취소</button>
      </div>{" "}
      <PopCreateTag
        open={openCreateTag}
        close={() => setOpenCreateTag(false)}
        currentTags={recruitTags}
        addTag={handleAddAnnouncementTag}
        handleTagRemove={handleAnnouncementTagRemove}
      />
      <Modal open={modalWriteOpen} close={handleCloseWriteModal} type="type_alert">
        <div className="content_container scroll">
          <strong>공고를 등록 하였습니다</strong>
        </div>
        <section className="btn_container">
          <button className="com_btn blue s" type="submit" onClick={() => {handleCloseWriteModal(); navigate(0);}}>
            확인{formSubmitting && <i className="gg-spinner-alt" />}
          </button>
          {/* <button className="com_btn line blue s" onClick={() => handleCloseWriteModal()}>
            취소
          </button> */}
        </section>
      </Modal>
      <Modal open={showAddressSelect} close={() => setShowAddressSelect(false)} type="pop_desired_work" popSize="s">
        <h2 className="pop_tit type_center">희망근무지 추가</h2>
        <div className="content_container">
          <div className="list">
            {locationTypeData?.map((option) => (
              <React.Fragment key={option.comm_code}>
                <input
                  type="checkbox"
                  className="com_chk type_btn"
                  id={option.comm_code}
                  checked={jobLocation.comm_code == option.comm_code}
                  onChange={() => setJobLocation(option)}
                />
                <label htmlFor={option.comm_code}>{option.code_nm_kr}</label>
              </React.Fragment>
            ))}
          </div>
          <section className="com_btn_wrap">
            <button className="com_btn blue s" onClick={() => setShowAddressSelect(false)}>
              추가
            </button>
            <button className="com_btn line blue s" onClick={() => setShowAddressSelect(false)}>
              취소
            </button>
          </section>
        </div>
      </Modal>
    </form>
  );
}
