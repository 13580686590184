import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { mutateBackend } from "../../../../../api";
import FormInput from "../../components/join/form/FormInput";
import Modal from "../../components/modal";

function PopCategoryMake(props) {
  const { open, close } = props;
  const defaultValues = {
    req_cate_nm: "",
    req_cate_desc: "",
    req_status: "COMMU_CATE",
  };
  const [isLoading, setIsLoading] = useState(false);
  const { formState, handleSubmit, register, reset, trigger, watch } = useForm({
    defaultValues,
    mode: "onChange",
  });

  useEffect(() => {
    reset(defaultValues);
  }, []);

  const onValidSubmit = async (data) => {
    setIsLoading(true);
    console.log({ data });
    const response = await mutateBackend("/category/reqCate", {
      data,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (data) => await data.json())
      .catch((err) => {
        throw new Error(err);
      });

    if (response.messageString === "SUCCESS" && response.messageCode === 200) {
      close();
    } else {
      setIsLoading(false);
    }
  };

  return (
    <Modal open={open} close={close} type="pop_apply pop_share" isLoading={isLoading}>
      <h2 className="pop_tit">카테고리</h2>
      <form onSubmit={handleSubmit(onValidSubmit)}>
        <div className="content_container">
          <div className="info_wrap">
            <ul className="com_input_sec">
              <FormInput
                id={"req_cate_nm"}
                formState={formState}
                label={"카테고리 이름"}
                placeholder={"카테고리 이름을 넣어주세요."}
                register={register}
                required="카테고리 이름을 넣어주세요."
                rules={{ minLength: 2 }}
                type={"text"}
                trigger={trigger}
                watch={watch}
              />
              <FormInput
                id={"req_cate_desc"}
                formState={formState}
                label={"개설이유"}
                placeholder={"카테고리 개설 이유를 말씀해주세요."}
                register={register}
                required="카테고리 개설 이유를 말씀해주세요."
                rules={{ minLength: 2 }}
                type={"text"}
                trigger={trigger}
                watch={watch}
              />
            </ul>
            <ul className="rule_list">
              <li className="rule">
                <span className="dot blue"></span>
                해당 내용은 관리자에게 전송되며, 승인 또는 반려될 수있습니다.
              </li>
              <li className="rule">
                <span className="dot blue"></span>
                카테고리 개설 승인 및 반려는 영업일 기준 3일 이내에처리됩니다.
              </li>
            </ul>
          </div>
        </div>
        <div className="btn_container">
          <button className="com_btn blue m BtnPopClose" disabled={isLoading}>
            전송 {isLoading && <i className="gg-spinner-alt" />}
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default PopCategoryMake;
