import React, { useContext } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Exchange from "../views/screens/page/mypage/exchange";
import MyPageSideBar from "../views/screens/components/miscellaneous/MyPageSideBar";
import ScrollToTopButton from "../views/screens/components/miscellaneous/ScrollToTopButton";
import MyResume from "../views/screens/page/mypage/myResume";
import "../views/screens/assets/scss/page/member.scss";
import "../views/screens/assets/scss/page/mypage.scss";
import Attachment from "../views/screens/page/mypage/attachment";
import ResumeWriting from "../views/screens/page/mypage/resumeWriting";
import MyFit from "../views/screens/page/mypage/MyFit";
import MyFit2 from "../views/screens/page/mypage/MyFit2";
import MySeminar from "../views/screens/page/mypage/Seminar";
import Notification from "../views/screens/page/mypage/Push";
import AIMatch from "../views/screens/page/mypage/AIMatch";
import Faq from "../views/screens/page/mypage/Faq";
import Inquiry from "../views/screens/page/mypage/Inquiry";
import PayInsight from "./../views/screens/page/mypage/PayInsight";
import ProductInsight from "./../views/screens/page/mypage/ProductInsight";
import ProductMentoring from "../views/screens/page/mypage/ProductMentoring";
import PayList from "../views/screens/page/mypage/PayList";
import MyCommunity from "../views/screens/page/mypage/Community";
import PointCharge from "../views/screens/page/mypage/PointCharge";
import PayMentoring from "../views/screens/page/mypage/PayMentoring";
import Profile from "../views/screens/page/mypage/Profile";
import Leave from "../views/screens/page/mypage/Leave";
import { AuthContext } from "../../context/Auth";
import PayMentoringWebcam from "../views/screens/components/myPage/Webcam";
import Chat from "../views/screens/components/myPage/Chat";
import QnaList from "../views/screens/components/myPage/QnaList";
import QnaWrite from "../views/screens/components/myPage/QnaWrite";
import QnaView from "../views/screens/components/myPage/QnaView";

/**
 *
 * @path
 * /member/*
 */
export default function RouterMyPage() {
  const currentLocation = useLocation();

  const { user } = useContext(AuthContext);

  if (user.user_type === "COM") return <Navigate to={"/Company/Info"} />;

  const PageStyle = {
    Exchange: "p_exchange",
    MyResume: "com_myresume p_resume",
    Attachment: "com_myresume p_attachment",
    ResumeWriting: "com_myresume p_resumewriting",
    Myfit: "com_mypage p_myFit",
    Faq: "p_faq",
    Inquiry: "p_inquiry",
    PayInsight: "p_pay_insight",
    ProductMentoring: "p_myproduct",
    PayList: "p_paylist",
    "ai-match": "com_my_match",
    Profile: "p_profile",
    Leave: "p_leave",
    Webcam: "com_mypage p_webcam",
    Chat: "com_mypage p_chat",
    QnaList: "com_mypage p_qna",
    QnaWrite: "com_mypage p_qna_write",
    QnaView: "com_mypage com_detail type_community p_qna_view",
  };

  const styleClass = currentLocation.pathname.startsWith("/ResumeWriting")
    ? PageStyle.ResumeWriting
    : PageStyle[currentLocation.pathname.split("/").pop()];

  return (
    <main className={`com_mypage com_myresume p_resumewriting ${styleClass}`}>
      <div className="com_center_wrap">
        <MyPageSideBar />
        <Routes>
          <Route path="/Exchange" element={<Exchange />} />
          <Route path="/MyResume" element={<MyResume />} />
          <Route path="/Attachment" element={<Attachment />} />
          <Route path="/ResumeWriting" element={<ResumeWriting />} />
          <Route path="/ResumeWriting/:resume_seq" element={<ResumeWriting />} />
          <Route path="/Myfit" element={<MyFit />} />
          <Route path="/Myfit2" element={<MyFit2 />} />
          <Route path="/Seminar" element={<MySeminar />} />
          <Route path="/Motification" element={<Notification />} />
          <Route path="/ai-match" element={<AIMatch />} />
          <Route path="/Faq" element={<Faq />} />
          <Route path="/Inquiry" element={<Inquiry />} />
          <Route path="/Inquiry" element={<Inquiry />} />
          <Route path="/Payinsight" element={<PayInsight />} />
          <Route path="/PayMentoring" element={<PayMentoring />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/Leave" element={<Leave />} />
          <Route path="/Productinsight" element={<ProductInsight />} />
          <Route path="/Productmentoring" element={<ProductMentoring />} />
          <Route path="/Paylist" element={<PayList />} />
          <Route path="/Community" element={<MyCommunity />} />
          <Route path="/PointCharge" element={<PointCharge />} />
          <Route path="/Webcam" element={<PayMentoringWebcam />} />
          <Route path="/Chat" element={<Chat />} />
          <Route path="/QnaList" element={<QnaList />} />
          <Route path="/QnaWrite" element={<QnaWrite />} />
          <Route path="/QnaView" element={<QnaView />} />
        </Routes>
        <ScrollToTopButton />
      </div>
    </main>
  );
}
