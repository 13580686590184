import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
//css
import "../../assets/scss/page/member.scss";
import ScrollToTopButton from "../miscellaneous/ScrollToTopButton";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import moment from "moment";
import Pagination from "../Pagination";
//component

export default function QnaList() {
  const location = useLocation();
  const { mento_seq } = location.state;

  const [per_page, setPerPage] = useState(6);
  const [curr_page, setCurrPage] = useState(1);

  const { data: { communities: mentoringQnaList, total_count } = { communities: [], total_count: 0 } } = useCustomQuery(
    `/community?board_type=MENTO&page=${curr_page}&limit=${per_page}&cate_seq=${mento_seq}`,
    {
      queryKey: ["get", curr_page, per_page, mento_seq],
      select: (res) => res?.data,
    },
  );
  const handleNext = () => {
    setCurrPage((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setCurrPage((prev) => prev - 1);
  };

  return (
    <>
      {/* <Lnb PageTitle="결제 콘텐츠 목록" MenuPayContents="active" /> */}
      <div className="container">
        <h2 className="com_pg_tit">질문과 답변 게시판</h2>
        <table className="com_board_list">
          <colgroup>
            <col />
            <col width="10%" />
            <col width="13%" />
          </colgroup>
          <thead>
            <tr>
              <th>제목</th>
              <th>작성자</th>
              <th>작성일</th>
            </tr>
          </thead>
          <tbody>
            {mentoringQnaList &&
              mentoringQnaList.map((qna) => (
                <tr key={qna.board_seq}>
                  <td className="tit_field">
                    <Link
                      to="/Mypage/QnaView"
                      state={{
                        mento_seq: mento_seq,
                        parent_seq: qna.parent_seq === 0 ? qna.board_seq : qna.parent_seq,
                      }}
                      className="link_view has_re">
                      <p className="txt">{qna.parent_seq > 0 ? `[답변] ${qna.board_title}` : qna.board_title}</p>
                      {/* <span className="re_num">15</span> */}
                    </Link>
                  </td>
                  <td className="id_field">{qna.user_name}</td>
                  <td className="date_field">{moment(qna.reg_dtm).format("YYYY.MM.DD")}</td>
                </tr>
              ))}
          </tbody>
        </table>
        <section className="com_board_list__bottom">
          <Link to="/Mypage/QnaWrite" state={{ mento_seq: mento_seq, parent_seq: 0 }} className="com_btn blue xs oval">
            질문 작성하기
          </Link>
        </section>
        {/* <Paging handlePageClick={handlePageClick} page={curr_page} totalPageCount={Math.ceil(total_count / per_page)} /> */}
        <Pagination
          currentPage={curr_page}
          totalPages={Math.ceil(total_count / per_page)}
          onNextClick={handleNext}
          onPrevClick={handlePrevious}
          perPage={per_page}
          handlePerPageChange={(e) => setPerPage(e.target.value)}
        />
      </div>
      {/* top button */}
      <ScrollToTopButton />
    </>
  );
}
