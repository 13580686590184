/* eslint-disable */
import React, { useCallback, useContext, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Controller, Navigation, Pagination as SwiperPagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { AuthContext } from "../../../../../context/Auth";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import useDebounce from "../../../../../hooks/useDebounce";
import { getImageLink } from "../../../../../utils/misc";
import ProfileImg from "../../assets/img/user-profile-placeholder.svg";
import Pagination from "../../components/Pagination";
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import TopBanner from "../mentoring/_TopBanner";
import PopCategoryMake from "./_popCategoryMake";

function List() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, isAuthenticated } = useContext(AuthContext);

  const [itemSwiper, setItemSwiper] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(location.state?.cate_seq || 0);
  const [modalCategoryMake, setModalCategoryMake] = useState(false);
  const [per_page, setPerPage] = useState(6);
  const [curr_page, setCurrPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const { data: categories } = useCustomQuery(`/category?cate_gr=COMMU_CATE`, {
    queryKey: ["getCategoryList"],
    select: (res) => res?.data?.categories,
    onSuccess: (data) => {
      !currentCategory && setCurrentCategory(data?.[0]?.cate_seq);
    },
  });

  const { data: community } = useCustomQuery(
    `/community?board_type=COMMTY&cate_seq=${currentCategory}&page=${curr_page}&limit=${per_page}&search_query=${debouncedSearchQuery}`,
    {
      queryKey: ["getCommunityList", { currentCategory, curr_page, per_page, searchQuery: debouncedSearchQuery }],
      select: (res) => res?.data,
      enabled: !!currentCategory,
    },
  );

  const checkAuthentication = async () => {
    if (!isAuthenticated) {
      sessionStorage.setItem("redirectPath", location.pathname);
      navigate("/member/login");
    }
    return true;
  };

  const tab = useMemo(
    () => (categories?.length > 0 && categories?.map((org) => ({ text: org.cate_name, value: org.cate_seq }))) || [],
    [categories],
  );

  const renderTab = useCallback(() => {
    return tab.map((v, idx) => {
      return (
        <button
          key={idx}
          className={`tab ${currentCategory === v.value ? "active" : ""}`}
          onClick={() => {
            setCurrentCategory(v.value);
            setCurrPage(1);
          }}>
          {v.text}
        </button>
      );
    });
  }, [currentCategory]);

  const renderTabWithSwiper = useCallback(() => {
    return tab.map((v, idx) => {
      return (
        <SwiperSlide>
          <button
            key={idx}
            className={`tab ${currentCategory === v.value ? "active" : ""}`}
            onClick={() => {
              setCurrentCategory(v.value);
              setCurrPage(1);
            }}>
            {v.text}
          </button>
        </SwiperSlide>
      );
    });
  }, [currentCategory]);

  const handleNextClick = () => {
    if (curr_page < Math.ceil(community?.total_count / per_page)) {
      setCurrPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevClick = () => {
    if (curr_page > 1) {
      setCurrPage((prevPage) => prevPage - 1);
    }
  };

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value, 10));
    setCurrPage(1);
  };

  return (
    <main className="p_community">
      {/* long banner */}
      <TopBanner />
      <section className="sec_community com_bg">
        <div className="com_center_wrap">
          <div className="com_sub_tit_wrap">
            <div className="pg_tit_wrap">
              <h2 className="com_pg_tit">커뮤니티</h2>
            </div>
            <div className="com_tab_wrap">
              <div className="content_tab">
                {renderTab()}
                <div className="swiper_wrap category">
                  <div className="swiper-button-prev"></div>
                  <Swiper
                    modules={[SwiperPagination, Controller, Navigation]}
                    onSwiper={setItemSwiper}
                    controller={{ control: itemSwiper }}
                    navigation={{
                      nextEl: ".category .swiper-button-next",
                      prevEl: ".category .swiper-button-prev",
                    }}
                    autoplay={true}
                    breakpoints={{
                      500: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                      },
                      300: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                      },
                    }}>
                    {renderTabWithSwiper()}
                  </Swiper>
                  <div className="swiper-button-next"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="career">
            <Link to="/community/write/new" state={{ cate_seq: currentCategory }} className="com_btn icon_pencil">
              커리어에 대해 자유롭게 이야기 해주세요!
            </Link>
            <div className="input_box icon_search">
              <input
                type="text"
                placeholder="관심있는 내용을 검색해주세요!"
                className="input"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <button
              onClick={async () => (await checkAuthentication()) && setModalCategoryMake(true)}
              className="com_btn btn_category">
              카테고리 만들기
            </button>
          </div>
          {community?.communities?.length > 0 ? (
            <>
              <div className="community_list">
                {community?.communities?.map((obj) => (
                  <Link
                    to={`/community/detail/${obj.board_seq}`}
                    state={categories}
                    key={obj.board_seq}
                    className="com_community_card">
                    <div className="img">
                      {obj.user_img ? (
                        <img src={getImageLink(obj.user_img)} alt={obj.user_name} />
                      ) : (
                        <img src={ProfileImg} alt="프로필" />
                      )}
                    </div>
                    <div className="info">
                      <div className="profile">
                        <p className="name">{obj.user_name}</p>
                        <span className="desc">{new Date(obj.reg_dtm).toLocaleString()}</span>
                      </div>
                      <div className="tit">{obj.board_title}</div>
                      <div className="description">{obj.board_contents}</div>
                      <div className="comment_box">
                        <span className="com_icon eye">{obj.hit_cnt}</span>
                        <span className={`com_icon ${obj?.likedBy?.includes(user?.user_seq) ? "liked" : "like"}`}>
                          {obj.favor_cnt}
                        </span>
                        <span className="com_icon comment">{obj.comnt_cnt}</span>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
              <Pagination
                currentPage={curr_page}
                totalPages={Math.ceil(community?.total_count / per_page)}
                onNextClick={handleNextClick}
                onPrevClick={handlePrevClick}
                perPage={per_page}
                handlePerPageChange={handlePerPageChange}
              />
            </>
          ) : (
            <section className="com_no_data">커뮤니티를 찾을 수 없습니다.</section>
          )}
        </div>
      </section>
      {/* top button */}
      <ScrollToTopButton />
      <PopCategoryMake open={modalCategoryMake} close={() => setModalCategoryMake(false)} />
    </main>
  );
}

export default List;
