import React from "react";
import { Link } from "react-router-dom";
import { getImageLink } from "../../../../../utils/misc";

function Item(props) {
  const { title, description, mag_image, mag_tags, mag_seq } = props;

  const div = document.createElement("div");
  div.innerHTML = description;
  const plainTextContent = div.innerText;

  const slicedDescription = (description) => description.split(" ").slice(0, 10).join(" ");

  return (
    <Link to={`/Magazine/${mag_seq}`} className="com_card_item">
      <div className="thumb">
        <img src={getImageLink(mag_image)} className="thumb thumb_img" alt="mentoring1" />
      </div>
      <div className="info">
        <div className="icon_wrap">
          {mag_tags?.split(",").map((tag, index) => {
            return (
              <span key={index} className="icon_line_blue">
                {tag}
              </span>
            );
          })}
        </div>
        <p className="title">{title}</p>
        <p className="info_s">{slicedDescription(plainTextContent)}</p>
      </div>
    </Link>
  );
}

export default Item;
