import React from "react";
import Modal from "./modal";
import Privacy from "../page/etc/Privacy";
import Terms from "../page/etc/Terms";
import Agree from "../page/etc/Agree";
import AgreeCompany from "../page/etc/AgreeCompany";
import RecruitService from "../page/etc/RecruitService";

function PopAgree(props) {
  const { open, close, title, type } = props;
  return (
    <Modal open={open} close={close} type="pop_agree">
      <h2 className="pop_tit">{title}</h2>
      <div className="content_container scroll">
        { type === 'agree' ? (
          //개인회원 가입약관
          <Agree />
        ) : type === 'agreeCompany' ? (
          //기업회원 가입약관
          <AgreeCompany />
        ) : type === 'terms' ? (
          // 서비스 이용약관
          <Terms />
        ) : type === 'recruitService' ? ( 
          // 적극 구직 서비스 신청
          <RecruitService />
        ) : type === 'privacy' ? (
          // 개인정보 처리방침
          <Privacy />
        ) : <></> }
      </div>
    </Modal>
  );
}

export default PopAgree;
