import React from "react";
import { Link, useNavigate } from "react-router-dom";

export default function RecruitList({ handleCloseRecruit, handleDeleteRecruit, recruitList }) {
  const navigate = useNavigate();

  return (
    <ul className="card_list">
      {recruitList.map((item, index) => (
        <li className="item" key={index}>
          <span className="status">모집중</span>
          <Link to={"/Company/Job/Detail/" + item["recruit_seq"]}>
            <div className="tit">{item["recruit_title"]}</div>
          </Link>
          <div className="info">
            <p className="info_s">
              <span className="name">등록일</span>
              <span className="bar"></span>
              <span className="date">{new Date(item["reg_dtm"]).toLocaleDateString()}</span>
            </p>
            
            <p className="info_s">
              <span className="name">마감일</span>
              <span className="bar"></span>
              <span className="date">{item?.close_type === "ANY" ? "상시 채용" : item?.close_type === "UNTI" ? "채용 시 마감" : (new Date(item?.close_date).toLocaleDateString() === "1899-11-29" ? "-" : new Date(item?.close_date).toLocaleDateString())}</span>
            </p>
          </div>
          <div className="com_btn_wrap">
            <button className="com_btn xs line" onClick={() => navigate("/Company/Job/Detail/edit/" + item["recruit_seq"])}>
              세부 정보 수정
            </button>
            <button
              className="com_btn xs bg blue"
              onClick={() => {
                handleCloseRecruit(item["recruit_seq"]);
              }}>
              마감하기
            </button>
          </div>
          <button
            className="com_btn bg xxs gray oval btn_del"
            style={{ zIndex: 2 }}
            onClick={() => handleDeleteRecruit(item["recruit_seq"])}>
            삭제
          </button>
          {/* finish item */}
          {item["recruit_status"] === "CLOSED" && (
            <div className="com_overlay type_circle">
              <div className="bg"></div>
              <div className="alert_box">
                <span className="txt">마감된 공고</span>
              </div>
            </div>
          )}
        </li>
      ))}
    </ul>
  );
}
