import React, { useState, useEffect } from "react";
import Modal from "../../components/modal";
import moment from "moment/moment";
import iconAlertBlue from "../../assets/img/icon_alert_blue.png";
import IconNaver from "../../assets/img/icon_certify_naver.png";
import IconKakao from "../../assets/img/icon_certify_kakao.png";
import IconSamsung from "../../assets/img/icon_certify_samsung.png";
import IconShinhan from "../../assets/img/icon_certify_shinhan.png";
import IconToss from "../../assets/img/icon_certify_toss.png";
import IconPass from "../../assets/img/icon_certify_pass.png";
import IconPayco from "../../assets/img/icon_certify_payco.png";
import IconKb from "../../assets/img/icon_certify_kb.png";

import PopAgree from "../../components/PopAgreement";
import Loading from "../../components/Loading";

function CareerPopup(props) {
  const { open, close, fields, setValue } = props;

  const [name, setName] = useState("");
  const [SecurityNumber, setSecurityNumber] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");

  const [CheckAll, setCheckAll] = useState(false);
  const [CheckOne, setCheckOne] = useState(false);
  const [CheckTwo, setCheckTwo] = useState(false);
  const [CheckThree, setCheckThree] = useState(false);

  const [CertiLoding, setCertiLoding] = useState(false);

  useEffect(() => {
    if (CheckAll) {
      setCheckOne(true);
      setCheckTwo(true);
      setCheckThree(true);
    } else {
      setCheckOne(false);
      setCheckTwo(false);
      setCheckThree(false);
    }
  }, [CheckAll]);
  const [modalAgreeOpen, setModalAgreeOpen] = useState(false);
  //"loginOrgCd":
  const [loginOrgCd, setLoginOrgCd] = useState("");

  //mobileCo
  const [mobileCo, setMobileCo] = useState(""); //통신사(S : SKT, K : KT, L : LGT)

  function sortDate(list) {
    const sorted_list = list
      .sort(function (a, b) {
        return new Date(a.join_date).getTime() - new Date(b.join_date).getTime();
      })
      .reverse();
    return sorted_list;
  }

  function sortList(list) {
    const sorted_list = [];

    list?.map((el, i) => {
      sorted_list.push({
        career_no: i + 1,
        career_type: el.career_type,
        career_company: el.career_company,
        career_depart: el.career_depart,
        career_posi: el.career_posi,
        join_date: el.join_date,
        leave_date: el.leave_date,
        career_status: el.career_status,
        career_desc: el.career_desc,
      });
    });
    return sorted_list;
  }

  const getData = async () => {
    if (!loginOrgCd) {
      alert("간편인증 방법을 선택해주세요.");
      return false;
    } else if (!name) {
      alert("이름을 입력해주세요.");
      return false;
    } else if (!SecurityNumber) {
      alert("생년월일을 입력해주세요.");
      return false;
    } else if (!mobileCo) {
      alert("통신사를 선택해주세요.");
      return false;
    } else if (!PhoneNumber) {
      alert("휴대폰 번호를 입력해주세요.");
      return false;
    } else if (!CheckOne || !CheckTwo || !CheckThree) {
      alert("약관에 동의해주세요.");
      return false;
    }

    setCertiLoding(true);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("loginOrgCd", loginOrgCd);
    urlencoded.append("Name", name);
    urlencoded.append("SecurityNumber", SecurityNumber);
    urlencoded.append("mobileCo", mobileCo);
    urlencoded.append("PhoneNumber", PhoneNumber);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    console.log(loginOrgCd, name, SecurityNumber, mobileCo, PhoneNumber);

    fetch(`${process.env.REACT_APP_SERVER_BASE_URL_PROD}/resume-certificate/getCerti`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const CertData = JSON.parse(result);
        console.log(CertData);
        const { common, data } = CertData.data;
        //대기시간이 있는경우
        console.log(common);
        if (common?.errYn === "Y" && common?.errMsg !== "") {
          setCertiLoding(false);
          alert(common.errMsg);
          return false;
        }

        //result 데이터가 있는경우
        if (data?.list?.length > 0) {
          // const list = data?.list.filter((item) => !fields.filter((i) => i.career_company === item.firmNm).length > 0);
          // 1차 데이터 (직장가입자 추출)
          const list = data?.list;
          const A_Arr = [];
          list.map((el) => {
            if (el.memberDiv === "직장가입자") {
              A_Arr.push({
                career_no: 0,
                career_type: "CERTI",
                career_company: el.firmNm,
                career_depart: "",
                career_posi: "",
                join_date: moment(el.acqDate).format("YYYY-MM-DD"),
                leave_date: el.loseDate ? moment(el.loseDate).format("YYYY-MM-DD") : "",
                career_status: el.loseDate ? "CS10" : "CS20",
                career_desc: "",
              });
            }
          });
          // 2차 데이터 (날짜순서 정렬)
          const B_Arr = sortDate(A_Arr);

          //3차 fields 와 B_Arr 데이터 비교
          const newArr = [];
          B_Arr.map((el) => {
            const ckeckData = fields.find(
              (item) =>
                item.career_company === el.career_company &&
                item.join_date === el.join_date &&
                !item.leave_date === el.leave_date,
            );
            //득실 내역이 업데이트 된 경우
            if (ckeckData) {
              const dat = ckeckData?.leave_date === "0000-00-00" ? "" : ckeckData?.leave_date;
              const dat2 = el.leave_date !== "" ? moment(el.leave_date).format("YYYY-MM-DD") : el.leave_date;
              if (dat !== dat2) {
                newArr.push({
                  career_no: el.career_no,
                  career_type: el.career_type,
                  career_company: el.career_company,
                  career_depart: el.career_depart,
                  career_posi: el.career_posi,
                  join_date: el.join_date,
                  leave_date: el.leave_date,
                  career_status: el.career_status,
                  career_desc: el.career_desc,
                });
              }
            } else {
              //새로운 득실내역
              const ckeckData = fields.find(
                (item) =>
                  item.career_company === el.career_company &&
                  item.join_date === el.join_date &&
                  item.leave_date === (el.leave_date === "" ? "0000-00-00" : el.leave_date),
              );
              if (!ckeckData) {
                newArr.push({
                  career_no: el.career_no,
                  career_type: el.career_type,
                  career_company: el.career_company,
                  career_depart: el.career_depart,
                  career_posi: el.career_posi,
                  join_date: el.join_date,
                  leave_date: el.leave_date,
                  career_status: el.career_status,
                  career_desc: el.career_desc,
                });
              }
            }
          });

          const newArrT = [...newArr, ...fields];

          const ttt = sortDate(newArrT);
          const te = sortList(ttt);

          setValue(`resume_career`, te);

          setCertiLoding(false);
          close();
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <Modal open={open} close={close} type="pop_certify">
      <h2 className="pop_tit">간편인증</h2>
      <div className="content_container scroll">
        <div className="info_wrap">
          <div className="box">
            <div className="list">
              <input type="radio" name="radio1" className="com_chk circle s" id="radio1_1" />
              <label htmlFor="radio1_1" onClick={() => setLoginOrgCd("naver")}>
                <p className="txt">네이버</p>
                <img src={IconNaver} className="icon" alt="alert" />
              </label>
              <input type="radio" name="radio1" className="com_chk circle s" id="radio1_2" />
              <label htmlFor="radio1_2" onClick={() => setLoginOrgCd("kakao")}>
                <p className="txt">카카오톡</p>
                <img src={IconKakao} className="icon" alt="alert" />
              </label>
              <input type="radio" name="radio1" className="com_chk circle s" id="radio1_3" />
              <label htmlFor="radio1_3" onClick={() => setLoginOrgCd("kica")}>
                <p className="txt">삼성패스</p>
                <img src={IconSamsung} className="icon" alt="alert" />
              </label>
              <input type="radio" name="radio1" className="com_chk circle s" id="radio1_4" />
              <label htmlFor="radio1_4" onClick={() => setLoginOrgCd("shinhan")}>
                <p className="txt">신한인증서</p>
                <img src={IconShinhan} className="icon" alt="alert" />
              </label>
              <input type="radio" name="radio1" className="com_chk circle" id="radio1_5" />
              <label htmlFor="radio1_5" onClick={() => setLoginOrgCd("toss")}>
                <p className="txt">
                  토스
                  <br />
                </p>
                <img src={IconToss} className="icon" alt="alert" />
              </label>
              <input type="radio" name="radio1" className="com_chk circle" id="radio1_6" />
              <label htmlFor="radio1_6" onClick={() => setLoginOrgCd("pass")}>
                <p className="txt">
                  통신사패스
                  <br />
                  (SKT, KT, LGU+)
                </p>
                <img src={IconPass} className="icon" alt="alert" />
              </label>
              <input type="radio" name="radio1" className="com_chk circle" id="radio1_7" />
              <label htmlFor="radio1_7" onClick={() => setLoginOrgCd("payco")}>
                <p className="txt">
                  페이코
                  <br />
                </p>
                <img src={IconPayco} className="icon" alt="alert" />
              </label>
              <input type="radio" name="radio1" className="com_chk circle" id="radio1_8" />
              <label htmlFor="radio1_8" onClick={() => setLoginOrgCd("kb")}>
                <p className="txt">
                  KB모바일
                  <br />
                  인증서
                </p>
                <img src={IconKb} className="icon" alt="alert" />
              </label>
            </div>
          </div>
          <div className="input_area">
            <ul className="com_input_sec">
              <li className="field_box">
                <span className="tit">이름</span>
                <div className="input_wrap">
                  <input value={name} type="text" className="input" onChange={(e) => setName(e.target.value)} />
                </div>
              </li>
              <li className="field_box">
                <span className="tit">생년월일 여덟자리</span>
                <div className="input_wrap">
                  <input
                    value={SecurityNumber}
                    className="input"
                    type="text"
                    onChange={(e) => setSecurityNumber(e.target.value)}
                  />
                </div>
              </li>
              <li className="field_box">
                <span className="tit">휴대번번호</span>
                <div className="input_wrap">
                  <select className="select" value={mobileCo} onChange={(e) => setMobileCo(e.target.value)}>
                    <option value={""}>선택</option>
                    <option value={"S"}>SKT</option>
                    <option value={"K"}>KT</option>
                    <option value={"L"}>LGT</option>
                  </select>
                  {/* <select className="select" defaultValue={'010'}>
                    <option value={"010"}>010</option>
                    <option value={"011"}>011</option>
                    <option value={"019"}>019</option>
                  </select> */}
                  <input
                    value={PhoneNumber}
                    className="input"
                    type="text"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
              </li>
            </ul>
            <section className="com_agree_section">
              <article className="all">
                <input
                  type="checkbox"
                  className="com_chk"
                  id="all"
                  checked={CheckAll === true ? true : false}
                  onChange={(e) => setCheckAll(e.target.checked)}
                />
                <label htmlFor="all">서비스 이용에 대한 동의(전체동의)</label>
              </article>
              <ul className="list">
                <li className="item">
                  <div>
                    <input
                      type="checkbox"
                      className="com_chk"
                      id="agree01"
                      checked={CheckOne === true ? true : false}
                      onChange={(e) => setCheckOne(e.target.checked)}
                    />
                    <label htmlFor="agree01">
                      개인정보이용동의<span className="txt_required">(필수)</span>
                    </label>
                  </div>
                  <button type="button" className="com_btn txt" onClick={() => setModalAgreeOpen(true)}>
                    자세히
                  </button>
                </li>
                <li className="item">
                  <div>
                    <input
                      type="checkbox"
                      className="com_chk"
                      id="agree02"
                      checked={CheckTwo === true ? true : false}
                      onChange={(e) => setCheckTwo(e.target.checked)}
                    />
                    <label htmlFor="agree02">
                      제3자정보제공동의<span className="txt_required">(필수)</span>
                    </label>
                  </div>
                  <button type="button" className="com_btn txt" onClick={() => setModalAgreeOpen(true)}>
                    자세히
                  </button>
                </li>
                <li className="item">
                  <div>
                    <input
                      type="checkbox"
                      className="com_chk"
                      id="agree03"
                      checked={CheckThree === true ? true : false}
                      onChange={(e) => setCheckThree(e.target.checked)}
                    />
                    <label htmlFor="agree03">
                      고유식별정보처리동의<span className="txt_required">(필수)</span>
                    </label>
                  </div>
                  <button type="button" className="com_btn txt" onClick={() => setModalAgreeOpen(true)}>
                    자세히
                  </button>
                </li>
              </ul>
            </section>
          </div>
        </div>
        {CertiLoding === true ? <Loading /> : null}
      </div>
      <div>
        <p className="notice">
          <i className="icon_alert">
            <img src={iconAlertBlue} className="icon" alt="alert" />
          </i>
          사용방법 매뉴얼 안내
        </p>
      </div>

      <div className="btn_container">
        <button type="button" className="com_btn m gray BtnPopClose btn_pop_close">
          닫기
        </button>
        <button type="button" className="com_btn blue m BtnPopClose" onClick={() => getData()}>
          인증요청
        </button>
      </div>

      {/*약관내용 팝업*/}
      <PopAgree open={modalAgreeOpen} close={() => setModalAgreeOpen(false)} title="이용약관" type="terms" />
    </Modal>
  );
}

export default CareerPopup;
