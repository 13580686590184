import React, { useState } from "react";
import Modal from "../../modal";

export default function PopCreateTag({ addTag, currentTags, open, close, handleTagRemove }) {
  const [currentTag, setCurrentTag] = useState("");

  return (
    <Modal open={open} close={close} type="pop_apply">
      <h2 className="pop_tit">태그 추가</h2>

      <div>
        <div className="content_container">
          <div className="info_wrap">
            <ul className="com_input_sec">
              <ul className="com_tag_list">
                {currentTags?.map(
                  (tag, i) =>
                    tag && (
                      <div className="com_btn blue s" key={i} onClick={() => handleTagRemove(i)}>
                        {"#" + tag}
                      </div>
                    ),
                )}
              </ul>
              <input
                type="text"
                className="input"
                value={currentTag}
                onChange={(e) => setCurrentTag(e.target.value)}
              />
            </ul>
          </div>
        </div>
        <div className="btn_container">
          <button
            className="com_btn blue s"
            onClick={() => {
              addTag(currentTag);
              setCurrentTag("");
            }}>
            추가
          </button>
          <button className="com_btn blue s" type="button" onClick={close}>
            완료
          </button>
        </div>
      </div>
    </Modal>
  );
}
