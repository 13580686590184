import React, {useEffect, useState} from "react";

function Success() {

    const params = new URLSearchParams(window.location.search);
    const data = params.get("EncodeData");

    const [success , setSuccess] = useState();

    useEffect(()=>{
        //백엔드 통신
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const requestOptions = {
        method: "POST",
        body: JSON.stringify({EncodeData : data}),
        headers: myHeaders,
        };
        fetch(`${process.env.REACT_APP_SERVER_BASE_URL_PROD}/nice/success`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                const CertData = JSON.parse(result);
                console.log(CertData);
                if(CertData.messageCode === 200 && CertData.messageString === "SUCCESS"){
                    const {requestnumber, responsenumber, authtype, name, birthdate, gender, nationalinfo, dupinfo, mobileno, mobileco } = CertData.data;
                    if(requestnumber && responsenumber && authtype && name && birthdate && gender && nationalinfo && dupinfo && mobileno && mobileco){
                        setSuccess(true);
                        const parent = window.opener;
                        parent.document.getElementById('requestnumber').value = requestnumber;
                        parent.document.getElementById('responsenumber').value = responsenumber;
                        parent.document.getElementById('authtype').value = authtype;
                        parent.document.getElementById('name').value = name;
                        parent.document.getElementById('birthdate').value = birthdate;
                        parent.document.getElementById('gender').value = gender;
                        parent.document.getElementById('nationalinfo').value = nationalinfo;
                        parent.document.getElementById('dupinfo').value = dupinfo;
                        parent.document.getElementById('mobileno').value = mobileno;
                        parent.document.getElementById('mobileco').value = mobileco;
                        parent.document.getElementById('user_mobile').value = mobileno;
                        window.close();
                    }else{
                        setSuccess(false);
                        //sRtnMSG , requestnumber , authtype , errcode
                        window.close();
                    }
                }   
            })
        .catch((error) => console.log("error", error));
    }, [data]);

  return (
    <>  
        {success === true ?  <h2>인증 성공</h2> :  <h2>인증 실패</h2>}

    </>
  );
}

export default Success;
