import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
//image
//component
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import PopReview from "./_PopReview";
import CompanyInfo from "./_CompanyInfo";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import useCommCate from "../../../../../hooks/useCommCate";
import ResumeItem from "./_ResumeItem";

export default function RecruitDetail() {
  const { recruit_seq } = useParams();

  const [recruit, setRecruit] = useState(null);

  const [resumeList, setResumeList] = useState([]);

  const { isFetching } = useCustomQuery(`/recruit/list?per_page=${1}&curr_page=${1}&recruit_seq=${recruit_seq}`, {
    queryKey: ["getRecruitList"],
    select: (res) => res?.data,
    onSuccess: (data) => {
      const recruit = data.data_list[0];
      setRecruit(recruit);
    },
    refetchOnWindowFocus: false,
  });

  const { isFetching: isResumeLoading } = useCustomQuery(`/resume/list?per_page=20`, {
    queryKey: ["getResumes", recruit_seq],
    select: (res) => res?.data?.resumes,
    onSuccess: (data) => {
      setResumeList(data);
    },
  });

  const { data: desiredJobs } = useCommCate("RECU_JOB");

  const [modalMatchOpen, setModalMatchOpen] = useState(false);

  return (
    <>
      <div className="container type_noBg">
        <div className="top_notice">
          AI로 매칭된 <span className="com_point_txt">[2023년 신영그룹 신입/경력 엔지니어 수시 채용]</span> 공고에
          알맞은 인재입니다.
        </div>
        <div className="tab_content">
          {/* company info */}
          <div className="com_top_box">
            {isFetching && !recruit ? <i className="gg-spinner-alt" /> : <CompanyInfo data={recruit} />}
          </div>
          <hr className="divider" />
          <div className="detail_wrap">
            <div className="pg_tit_wrap">
              <h2 className="com_pg_tit">추천 인재</h2>
              <button className="com_btn line blue xs oval btn_refresh" onClick={() => setModalMatchOpen(true)}>
                인재 재 요청
              </button>
            </div>
            <ul className="profile_list">
              {isResumeLoading ? (
                <i className="gg-spinner-alt" />
              ) : (
                resumeList.map((item, index) => <ResumeItem desiredJobs={desiredJobs} resume={item} key={index} />)
              )}
            </ul>
          </div>
          <div className="com_btn_wrap center">
            <Link to="/Company/Recruit" className="com_btn blue s">
              추천 인재 목록으로
            </Link>
          </div>
        </div>
      </div>
      {/* top button */}
      <ScrollToTopButton />
      {/* 인재 재요청 버튼 클릭 시 */}
      <PopReview open={modalMatchOpen} close={() => setModalMatchOpen(false)} />
    </>
  );
}
