import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../context/Auth";

function AdminRoute({ children }) {
  const { isAuthenticatedAdmin } = useContext(AuthContext);

  if (!isAuthenticatedAdmin) return <Navigate to="/boffice/login" />;

  return children;
}

export default AdminRoute;
