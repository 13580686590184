import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import "../../assets/scss/page/seminar.scss";
import Pagination from "../../components/Pagination";
import TopBanner from "../mentoring/_TopBanner";
import Item from "./_Item";

export default function List() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams({ page: 1, limit: 6 });
  const page = +searchParams.get("page");
  const limit = +searchParams.get("limit");

  const { data: { seminars = [], total_count = 0 } = { seminars: [], total_count: 0 } } = useCustomQuery(
    `/seminar?page=${page}&limit=${limit}`,
    {
      queryKey: ["getSeminarList", page, limit],
      select: (res) => res?.data ?? { seminars: [], total_count: 0 },
    },
  );

  return (
    <main className="p_seminar">
      {/* long banner */}
      <TopBanner />
      <section className="sec_seminar com_bg">
        <div className="com_center_wrap">
          <div className="com_sub_tit_wrap">
            <div className="pg_tit_wrap" style={{ display: "flex", justifyContent: "space-between" }}>
              <h2 className="com_pg_tit">세미나</h2>
            </div>
          </div>
          <div className="list_wrap">
            <ul className="com_card_wrap">
              {seminars.map((item) => (
                <Item
                  key={item?.semin_seq}
                  seminar={item}
                  handleDetail={() => navigate(`/seminar/detail/${item?.semin_seq}`)}
                />
              ))}
            </ul>
          </div>
          <Pagination
            currentPage={page}
            totalPages={Math.ceil(total_count / limit)}
            perPage={limit}
            setSearchParams={setSearchParams}
          />
        </div>
      </section>
    </main>
  );
}
