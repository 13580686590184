import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import useCommCode from "../../../../../hooks/useCommCode";
import moment from "moment";
import { updateBackend } from "../../../../../api";
import { toast } from "react-toastify";
import { getNameCommCode } from "../../../../../utils/Common.utils";

const getRecruitStatus = (recruit) => {
  if (recruit.close_type === "ANY") return "상시채용";
  if (recruit.close_type === "UNTI") return `채용 시 마감: ${moment(recruit.open_date).format("YYYY-MM-DD")} ~`;
  return `${moment(recruit.open_date).format("YYYY-MM-DD")} ~ ${moment(recruit.close_date).format("YYYY-MM-DD")}`;
};

export default function AiMatchingApplyDetail() {
  const navigate = useNavigate();

  const { recruit_seq } = useParams();

  const [recruit, setRecruit] = useState("");
  const [specificApplicationList, setSpecificApplicationList] = useState("");
  const [totalCount, setTotalCount] = useState("");

  const { isFetching } = useCustomQuery("/recruit/list?per_page=10&curr_page=1&recruit_seq=" + recruit_seq, {
    queryKey: ["getRecruitApplication/" + recruit_seq],
    select: (res) => res?.data,
    onSuccess: async (data) => {
      if (!data) navigate(-1);
      setRecruit(data.data_list[0]);
    },
  });

  const { isFetching: isResumeLoading, refetch } = useCustomQuery(
    `/application/list?per_page=10&curr_page=1&recruit_seq=${recruit_seq}`,
    {
      queryKey: ["getApplications", recruit_seq],
      select: (res) => res?.data,
      onSuccess: (data) => {
        setSpecificApplicationList(data.data_list);
        setTotalCount(data.total_cnt);
      },
    },
  );

  const { data: employmentType = [] } = useCommCode("EMP_TYPE");
  const { data: locationType = [] } = useCommCode("LOCA_CD");
  const { data: applyStatus } = useCommCode("SUPP_STATUS");

  const handleApplyStatus = async (applicant, status) => {
    const response = await updateBackend("/application", {
      data: {
        resume_seq: applicant.resume_seq,
        recruit_seq: applicant.recruit_seq,
        appli_status: status,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (data) => await data.json());

    console.log(response);
    if (response.messageCode === 200) {
      toast.success("지원 상태를 변경했습니다");
    } else toast.error("지원 상태를 변경하지 못했습니다.");

    refetch();
  };

  if (isFetching || !recruit) return <i className="gg-spinner-big" />;

  return (
    <main>
      <h2 className="adm_com_pg_tit2 bottom_line"> 지원 관리</h2>
      <div className="adm_com_pg_content">
        <h2 className="sub_tit">- 지원 관리 &gt; 상세</h2>
        <table className="adm_com_board_list">
          <colgroup>
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
          </colgroup>
          <thead>
            <tr>
              <th>회사명</th>
              <th>공고 제목</th>
              <th>고용형태</th>
              <th>직무</th>
              <th>경력</th>
              <th>근무지역</th>
              <th>지원기간</th>
              <th>상태</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{recruit.comp_name}</td>
              <td>{recruit.recruit_title}</td>
              <td>{getNameCommCode(recruit.emp_type, employmentType)}</td>
              <td>{}</td>
              <td>{recruit.newcomer_YN === "Y" ? "신입" : `${recruit.career_min} ~ ${recruit.career_max}년`}</td>
              <td>{getNameCommCode(recruit.recruit_location, locationType)}</td>
              <td>{getRecruitStatus(recruit)}</td>
              <td>{recruit.recruit_status === "OPEN" ? "모집중" : "마감"}</td>
            </tr>
          </tbody>
        </table>
        <div className="adm_com_btn_wrap center bottom">
          <button className="adm_com_btn gray" onClick={() => navigate(-1)}>
            목록
          </button>
        </div>

        <div className="sub_tit">
          <span className="txt flex-start">
            {`총 지원자: ${totalCount} 명`}
            {(isResumeLoading || !specificApplicationList) && <i className="gg-spinner-alt" />}
          </span>
        </div>
        <table className="adm_com_board_list">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <thead>
            <tr>
              <th>아이디</th>
              <th>지원자</th>
              <th>지원일</th>
              <th>지원 이력서</th>
              <th>승인 여부</th>
              <th>상태</th>
            </tr>
          </thead>
          <tbody>
            {specificApplicationList &&
              specificApplicationList.map((el, index) => (
                <tr key={index}>
                  <td>{el.user_id}</td>
                  <td>{el.name}</td>
                  <td>{moment(el.reg_dtm).format("YYYY.MM.DD")}</td>
                  <td>
                    <Link
                      to={`/boffice/ai/resume/${el.resume_seq}`}
                      className="adm_com_btn gray"
                      style={{ marginLeft: "0px" }}>
                      이력서 보기
                    </Link>
                  </td>
                  <td>{el.appli_status === "SS00" ? "승인대기" : "승인완료"}</td>
                  <td>
                    <select value={el.appli_status} onChange={(e) => handleApplyStatus(el, e.target.value)}>
                      {applyStatus &&
                        applyStatus
                          .filter((apply) => !(el.appli_status !== "SS00" && apply.comm_code === "SS00"))
                          .map((apply, index) => (
                            <option key={index} value={apply.comm_code}>
                              {apply.code_nm_kr}
                            </option>
                          ))}
                    </select>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </main>
  );
}
