import React, { useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";
import Resume_project from "./resume_project";
import IconClose from "../../assets/img/icon_close.png";
import MyCompanyLogo from "../../assets/img/logo.png";
import CareerPopup from "./CareerPopup";

export default function Resume_career({ control, register, errors, carrStatusData, setValue }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "resume_career",
  });

  const [open, setOpen] = useState(false);

  // When there are multiple fields, deleting the middle field modifies the field number value.
  useEffect(() => {
    for (let i = 0; i < fields.length; i++) {
      setValue(`resume_career[${i}].career_no`, i + 1);
    }
  }, [fields]);

  const handleAddCareer = () => {
    append({
      career_no: fields.length + 1,
      career_type: "ADD",
      career_company: "",
      career_depart: "",
      career_posi: "",
      join_date: "",
      leave_date: "",
      career_status: "",
      career_desc: "",
    });
  };

  console.log("errors :", errors);

  return (
    <>
      {" "}
      <CareerPopup append={append} setValue={setValue} fields={fields} open={open} close={() => setOpen(false)} />
      <li className="title_field">
        인증경력 <span className="desc"> 경력 정보를 불러오면 경력 인증마크가 채워져요!</span>{" "}
        <button type="button" className="right" onClick={() => setOpen(true)}>
          경력 불러오기
        </button>
      </li>
      <li className="certification_wrapper">
        {fields.map((career, careerIndex) => (
          <div className="certification_field bottom_line" key={career.id}>
            <div className="input_wrapper">
              {/* <input
                type="text"
                className="input label"
                {...register(`careers[${careerIndex}].career_type`)}
                defaultValue={career.career_type}
              /> */}
              <div className="department">
                <input
                  type="text"
                  className="input"
                  {...register(`resume_career[${careerIndex}].career_company`)}
                  defaultValue={career.career_company}
                  placeholder="회사명"
                />
                <input
                  type="text"
                  className="input"
                  {...register(`resume_career[${careerIndex}].career_depart`)}
                  defaultValue={career.career_depart}
                  placeholder="부서명"
                />
                <input
                  type="text"
                  className="input"
                  {...register(`resume_career[${careerIndex}].career_posi`)}
                  defaultValue={career.career_posi}
                  placeholder="직책"
                />
              </div>
              <div className="time">
                <input
                  type="date"
                  className="input label"
                  {...register(`resume_career[${careerIndex}].join_date`)}
                  defaultValue={career.join_date}
                  max={'9999-12-31'}
                />
                <input
                  type="date"
                  className="input label"
                  {...register(`resume_career[${careerIndex}].leave_date`)}
                  defaultValue={career.leave_date}
                  max={'9999-12-31'}
                />
              </div>
              <select
                className="input label"
                name={`resume_career[${careerIndex}]`}
                {...register(`resume_career[${careerIndex}].career_status`)}
                defaultValue={career.career_status}>
                <option value="">경력 상태</option>
                {carrStatusData?.map((carr) => {
                  return (
                    <option key={carr.comm_code} value={carr.comm_code}>
                      {carr.code_nm_kr}
                    </option>
                  );
                })}
              </select>
              {career.career_type === "CERT" ? (
                <div className="logo">
                  <img src={MyCompanyLogo} alt="" />
                </div>
              ) : (
                ""
              )}

              <button type="button" className="btn_delete" onClick={() => remove(careerIndex)}>
                <img src={IconClose} alt="close_icon" />
              </button>
            </div>
            <textarea
              cols={30}
              rows={10}
              className="textarea"
              {...register(`resume_career[${careerIndex}].career_desc`)}
              defaultValue={career.career_desc}
              placeholder="주요 업무 내용을 입력해주세요."
            />
            <div
              style={{
                paddingTop: "5%",
              }}>
              <p className="desc">수행 프로젝트: 상기 재직 중 수행했던 프로젝트별로 기재해주세요.</p>

              <Resume_project careerIndex={careerIndex} {...{ control, register, setValue }} />
            </div>
            {/* <ul>
              {errors.resume_career && errors.resume_career[careerIndex] && (
                <li style={{ color: "red" }}>{errors.resume_career[careerIndex].career_type?.message}</li>
              )}
              {errors.resume_career && errors.resume_career[careerIndex] && (
                <li style={{ color: "red" }}>{errors.resume_career[careerIndex].career_company?.message}</li>
              )}
              {errors.resume_career && errors.resume_career[careerIndex] && (
                <li style={{ color: "red" }}>{errors.resume_career[careerIndex].career_depart?.message}</li>
              )}
              {errors.resume_career && errors.resume_career[careerIndex] && (
                <li style={{ color: "red" }}>{errors.resume_career[careerIndex].career_posi?.message}</li>
              )}
              {errors.resume_career && errors.resume_career[careerIndex] && (
                <li style={{ color: "red" }}>{errors.resume_career[careerIndex].join_date?.message}</li>
              )}
              {errors.resume_career && errors.resume_career[careerIndex] && (
                <li style={{ color: "red" }}>{errors.resume_career[careerIndex].leave_date?.message}</li>
              )}
              {errors.resume_career && errors.resume_career[careerIndex] && (
                <li style={{ color: "red" }}>{errors.resume_career[careerIndex].career_status?.message}</li>
              )}
            </ul> */}
          </div>
        ))}
      </li>
      <button type="button" className="com_btn blue" onClick={handleAddCareer}>
        <span className="addition">+</span>경력 추가
      </button>
    </>
  );
}
