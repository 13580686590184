import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../assets/scss/page/mentoring.scss";
import TopBanner from "./_TopBanner";
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import Pagination from "../../components/Pagination";
import { useAuthenticatedQuery } from "../../../../../hooks/useAPI";
import useDebounce from "../../../../../hooks/useDebounce";
import { AuthContext } from "./../../../../../context/Auth";
import MentoringItem from "./_MentoringItem";
import useCommCate from "../../../../../hooks/useCommCate";
import { toast } from "react-toastify";

function MentorList() {
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [categories, setCategories] = useState([]);
  const [mentors, setMentors] = useState([]);
  const [per_page, setPerPage] = useState(9);
  const [totalCount, setTotalCount] = useState(0);
  const [curr_page, setCurrPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [permitionInfo, setPermitionInfo] = useState();

  const navigate = useNavigate();

  const { user } = useContext(AuthContext);
  

  const { data: categoryData, isFetching: isFetchingCategories } = useCommCate("MENTO_CATE");

  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const location = useLocation();
  const selectedMentoring = location.state?.selectedMentoring || null;

  const searchParams = new URLSearchParams();

  if (debouncedSearchQuery !== "") {
    searchParams.append("search_query", debouncedSearchQuery);
  }

  if (selectedCategory !== null) {
    searchParams.append("category", selectedCategory);
  }
  searchParams.append("curr_page", curr_page);
  searchParams.append("per_page", per_page);

  const queryString = searchParams.toString();
  const endpoint = `/mentoring?${queryString}`;

  //2023-11-22 회원 권한 - KSR
  const {
    data: permitionInfoData,
    isFetching: isLoadingPermition
  } = useAuthenticatedQuery(["getPermision"], '/user/qualification');

  //권한 데이터
  useEffect(() => {
    const updateData = async (data) => {
      const response = await data.json();
      if (response.messageCode === 200) {
        setPermitionInfo(response.data);
        console.log(response.data);
      }
    };

    !isLoadingPermition && permitionInfoData && !permitionInfoData.bodyUsed && updateData(permitionInfoData);
  }, [permitionInfoData]);

  const {
    data: mentoringProducts,
    isFetching: isLoadingMentoring,
    refetch,
  } = useAuthenticatedQuery(["getMentorings", selectedCategory, curr_page, debouncedSearchQuery], endpoint);

  useEffect(() => {
    const updateData = async (categories) => {
      const newTabs = [{ text: "전체", value: 0 }];
      for (const category of categories) {
        newTabs.push({
          value: category.cate_seq,
          text: category.cate_name,
        });
      }
      setCategories(newTabs);
    };

    !isFetchingCategories && categoryData && updateData(categoryData);
  }, [categoryData]);

  const handleCategoryChange = useCallback((categoryValue) => {
    setSelectedCategory(categoryValue);
    setCurrPage(1);
  }, []);

  useEffect(() => {
    refetch();
  }, [selectedCategory, curr_page, per_page, refetch]);

  useEffect(() => {
    const updateData = async (data) => {
      const response = await data.json();

      if (response.messageCode === 200) {
        setMentors(response.data.data_list);
        setTotalCount(response.data.total_count);
      }
    };

    !isLoadingMentoring && mentoringProducts && !mentoringProducts.bodyUsed && updateData(mentoringProducts);
  }, [mentoringProducts]);

  const handleNextClick = () => {
    if (curr_page < Math.ceil(totalCount / per_page)) {
      setCurrPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevClick = () => {
    if (curr_page > 1) {
      setCurrPage((prevPage) => prevPage - 1);
    }
  };

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value, 10));
  };

  const renderCategories = () => {
    return categories.map((v, idx) => (
      <button
        key={idx}
        className={`tab ${(selectedCategory === v.value && "active") || ""}`}
        onClick={() => handleCategoryChange(v.value)}>
        {v.text}
      </button>
    ));
  };

  return (
    <main className="com_mentoring">
      <TopBanner />
      <section className="sec_mentoring com_bg">
        <div className="com_center_wrap">
          <div className="com_sub_tit_wrap">
            <div className="pg_tit_wrap">
              <h2 className="com_pg_tit">멘토링</h2>
              <div className="com_btn_wrap">
                <Link to="/Mentoring/Intro" className="com_btn m black icon_arrow btn_all_list">
                  멘토링이란?
                </Link>
                <Link to="/Mypage/ProductMentoring" className="com_btn m black icon_arrow btn_all_list">
                  내 상품 관리
                </Link>
                <button
                  className="com_btn m black icon_arrow btn_all_list"
                  onClick={() => {
                    if(user?.user_seq > 0 ) {
                      if (permitionInfo?.mento_status === "MS20") {
                        navigate("/Mentoring/MentoringProduct");
                      } else if(permitionInfo?.mento_status === "MS10") {
                        toast.error("멘토링 지원 승인대기 중입니다.");
                      } else {
                        toast.error("멘토 지원을 먼저 해야 합니다.");
                        navigate("/Mentoring/Intro");
                      }
                    } else {
                      navigate("/member/login");
                    }

                  }}>
                  멘토 상품 등록
                </button>
              </div>
            </div>
            <div className="com_tab_wrap">
              <div className="content_tab">{!isFetchingCategories && renderCategories()}</div>
              <div className="input_box icon_search">
                <input
                  type="text"
                  placeholder="키워드를 입력해주세요."
                  className="input"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
          </div>
          {isLoadingMentoring ? (
            <i className="gg-spinner-alt" />
          ) : mentors.length !== 0 ? (
            <ul className="com_card_wrap">
              {mentors.map((mento, index) => (
                <MentoringItem
                  key={index}
                  mento_title={mento.mento_title}
                  mento_cate={mento.cate_name}
                  mento_seq={mento.mento_seq}
                  image_seq={mento.image_seq}
                  avg_rating={Number(mento.avg_rating).toFixed(1)}
                  selectedMentoring={selectedMentoring}
                  user_name={mento.user_name}
                  career_history={mento.mento_carrer}
                  user={user}
                />
              ))}
            </ul>
          ) : (
            <section className="com_no_data">등록된 멘토링이 없습니다.</section>
          )}
          {!isLoadingMentoring && (
            <Pagination
              currentPage={curr_page}
              totalPages={Math.ceil(totalCount / per_page)}
              onNextClick={handleNextClick}
              onPrevClick={handlePrevClick}
              perPage={per_page}
              handlePerPageChange={handlePerPageChange}
            />
          )}
        </div>
      </section>
      <ScrollToTopButton />
    </main>
  );
}

export default MentorList;
