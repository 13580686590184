import React, { useEffect, useState } from "react";

export default function MemberFilter({
  additionalFields = null,
  dateLabel = "가입기간검색",
  columns,
  dataPerPage,
  defaultSearchColumn,
  setDataPerPage,
  setParamArguments,
}) {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [filterItems, setFilterItems] = useState({});

  const [currentSearchColumn, setCurrentSearchColumn] = useState(defaultSearchColumn);

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleDateFilter = (days) => {
    const today = new Date();
    const newEndDate = new Date();
    const newStartDate = new Date();

    newEndDate.setDate(today.getDate());
    newStartDate.setDate(today.getDate() - days);

    setStartDate(newStartDate.toISOString().split("T")[0]);
    setEndDate(newEndDate.toISOString().split("T")[0]);
  };

  const resetFilter = () => {
    setFilterItems((prev) => ({ ...prev, [currentSearchColumn]: "" }));
    setStartDate("");
    setEndDate("");
    setParamArguments("");
  };

  useEffect(() => {
    const params = new URLSearchParams();

    Object.keys(filterItems).forEach((key) => params.set(key, filterItems[key]));

    if (startDate !== "") params.set("start_date", startDate);
    if (endDate !== "") params.set("end_date", endDate);

    setParamArguments(params.toString());
  }, [filterItems, startDate, endDate]);

  return (
    <>
      <div className="adm_com_board_list__top">
        <div className="adm_com_date_select">
          <span className="tit">{dateLabel}</span>
          <input
            type="date"
            className="adm_com_datepicker"
            placeholder={new Date().toLocaleDateString()}
            value={startDate}
            onChange={handleStartDateChange}
          />
          <span className="divider"> ~ </span>
          <input
            type="date"
            className="adm_com_datepicker"
            placeholder={new Date().toLocaleDateString()}
            value={endDate}
            onChange={handleEndDateChange}
          />
          {/* <button className="adm_com_btn gray">조회</button> */}
          <div className="adm_com_btn_wrap">
            <button className="adm_com_btn line gray" onClick={() => handleDateFilter(0)}>
              오늘
            </button>
            <button className="adm_com_btn line gray" onClick={() => handleDateFilter(3)}>
              3일전
            </button>
            <button className="adm_com_btn line gray" onClick={() => handleDateFilter(7)}>
              일주일
            </button>
            <button className="adm_com_btn line gray" onClick={() => handleDateFilter(30)}>
              1개월
            </button>
            <button className="adm_com_btn line gray" onClick={() => handleDateFilter(90)}>
              3개월
            </button>
            <button
              className="adm_com_btn line gray"
              onClick={() => {
                setStartDate("");
                setEndDate("");
              }}>
              전체
            </button>
          </div>
        </div>

        {columns && (
          <div className="adm_com_input_wrap flex_row">
            <span className="tit">회원검색</span>
            <select
              className="select"
              value={currentSearchColumn}
              onChange={(e) => setCurrentSearchColumn(e.target.value)}>
              {Object.keys(columns).map(
                (key, i) =>
                  typeof columns[key] === "string" && (
                    <option value={key} key={i}>
                      {columns[key]}
                    </option>
                  ),
              )}
            </select>
            <input
              type="text"
              className="input name"
              placeholder="이름을 입력하세요"
              value={filterItems[currentSearchColumn] ? filterItems[currentSearchColumn] : ""}
              onChange={(e) => setFilterItems((prev) => ({ ...prev, [currentSearchColumn]: e.target.value }))}
            />
            {/* <button className="adm_com_btn blue">조회</button> */}
            <button className="adm_com_btn black" onClick={resetFilter}>
              초기화
            </button>
          </div>
        )}
        {additionalFields && additionalFields}
      </div>
      <div className="adm_com_btn_wrap between">
        <button className="adm_com_btn red xs">엑셀 데이터 다운로드</button>
        <select className="select" value={dataPerPage} onChange={(e) => setDataPerPage(Number(e.target.value))}>
          <option value={10}>10개씩 보기</option>
          <option value={20}>20개씩 보기</option>
        </select>
      </div>
    </>
  );
}
