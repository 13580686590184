import React, { useEffect } from "react";
import { useFieldArray } from "react-hook-form";

import IconClose from "../../assets/img/icon_close.png";

export default function Resume_certificate({ control, register, setValue }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "resume_certificate",
  });

  // When there are multiple fields, deleting the middle field modifies the field number value.
  useEffect(() => {
    for (let i = 0; i < fields.length; i++) {
      setValue(`resume_certificate[${i}].certi_no`, i + 1);
    }
  }, [fields]);

  return (
    <>
      <li className="title_field">자격증</li>
      <li className="certificate_wrapper">
        {fields.map((certificate, index) => (
          <div className="certificate_field bottom_line" key={certificate.id}>
            <div className="wrapper">
              <input
                type="text"
                className="input"
                {...register(`resume_certificate[${index}].certi_name`)}
                defaultValue={certificate.certi_name || ""}
                placeholder="자격증명을 입력해주세요."
              />
            </div>
            <div className="s">
              <input
                type="text"
                className="input"
                {...register(`resume_certificate[${index}].certi_insti`)}
                defaultValue={certificate.certi_insti || ""}
                placeholder="발행처를 입력해주세요."
              />
              <input
                type="text"
                className="input"
                {...register(`resume_certificate[${index}].certi_acqu_date`)}
                defaultValue={certificate.certi_acqu_date || ""}
                placeholder="취득년월을 입력해주세요."
              />

              <button className="btn_delete" type="button" onClick={() => remove(index)}>
                <img src={IconClose} alt="close_icon" />
              </button>
            </div>
          </div>
        ))}
        <button
          className="com_btn blue"
          type="button"
          onClick={() =>
            append({
              certi_no: fields.length + 1,
              certi_name: "",
              certi_insti: "",
              certi_acqu_date: "",
            })
          }>
          <span className="addition">+</span>추가
        </button>
      </li>
    </>
  );
}
