/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useEffect, useContext } from "react";
import "../../../assets/scss/page/member.scss";
//component

import { useCustomQuery } from "../../../../../../hooks/useAPI";
import { deleteBackend, mutateBackend, mutateBackendFormData } from "../../../../../../api";
import { toast } from "react-toastify";
import DeleteMentoringModal from "../../../components/myPage/_PopDelete";
import { AuthContext } from "../../../../../../context/Auth";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import Modal from "../../../components/modal";


function MenteeManagement() {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [per_page, setPerPage] = useState(9);
  const [curr_page, setCurrPage] = useState(1);
  const [errMsg, setErrMsg] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const searchParams = new URLSearchParams();

  searchParams.append("curr_page", curr_page);
  searchParams.append("per_page", per_page);

  const queryString = searchParams.toString();

  const [modalOpen, setModalOpen] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [page2, setPage2] = useState(1);
  const [memberPerPage2, setMemberPerPage2] = useState(10);
  const [totalCount2, setTotalCount2] = useState(0);

  const [paramArguments2, setParamArguments2] = useState("");

  const [mentorList2, setMentorList2] = useState([]);

  const { refetch } = useCustomQuery(
    `/mentoring/my/menti?per_page=${memberPerPage2}&curr_page=${page2}&user_seq=${user.user_seq}`,
    {
      queryKey: ["selectMyMenti/", user.user_seq],
      select: (res) => res?.data,
      onSuccess: async (data) => {
        setTotalCount2(data.total_cnt);
        setMentorList2(data.data_list);
      },
      refetchOnWindowFocus: false,
    },
  );

  console.log(mentorList2);

  const getService = (item) => {
    if (item === "MEET") return <><span className="com_pc">[</span>화상미팅<span className="com_pc">]</span><br /></>;
    if (item === "CHAT") return <><span className="com_pc">[</span>채팅<span className="com_pc">]</span><br /></>;
    if (item === "BOARD") return <><span className="com_pc">[</span>게시판<span className="com_pc">]</span><br /></>;
  };

  const getService2 = (item, use_meet_cnt, supp_meet_cnt) => {
    if (item === "MEET") return <><button className="com_btn oval line s">화상미팅 ({item.use_meet_cnt}/{item.supp_meet_cnt})</button><br /></>;
    if (item === "CHAT") return <><button className="com_btn oval line s">채팅 ({item.use_meet_cnt}/{item.supp_meet_cnt})</button><br /></>;
    if (item === "BOARD") return <><button className="com_btn oval line s">게시판 ({item.use_meet_cnt}/{item.supp_meet_cnt})</button><br /></>;
  };

  const viewStatus = (status, mento_seq, user_seq) => {
    switch (status) {
      case "MS10": {
        return <><button type="button" className="com_btn line gray s oval" onClick={() => handleStatusUpdate('MS30', mento_seq, user_seq)}>수락</button><button type="button" className="com_btn line gray s oval" onClick={() => handleStatusUpdate('MS90', mento_seq, user_seq)}>거절</button></>;
        // break;
      }
      case "MS30": {
        return <><span className="txt">진행 중</span></>;
        //break;
      }
      case "MS90": {
        return <><span className="txt">거절</span></>;
        //break;
      }
      case "MS50": {
        return <><span className="txt">완료</span></>;
        //break;
      }
      case "MS99": {
        return <><span className="txt">삭제</span></>;
        //break;
      }
    }
  };



  const handleStatusUpdate = async (menti_status, mento_seq, user_seq) => {

    //use_meet_cnt
    const response = await mutateBackend("/mentoring/user/update", {
      data: {
        menti_status: menti_status,
        mento_seq: mento_seq,
        user_seq: user_seq
      },
      headers: { "Content-Type": "application/json" },
    });

    if (response.status === 200) {
      toast.success("수정 되었습니다.");
      await refetch();
    } else {
      toast.error("수정에 실패했습니다.");
    }
  };

  const getChatRoom = async (user_seq, dealTable, mentoSeq) => {

    const formData = new URLSearchParams();

    console.log(user_seq, dealTable, mentoSeq);
    formData.append("user_mem_idx", user_seq);
    formData.append("corp_mem_idx", user.user_seq);
    formData.append("my_idx", user.user_seq);
    formData.append("deal_table", dealTable);
    formData.append("propose_idx", mentoSeq);
    formData.append("user_type", user.user_type);

    //이미지인 경우
    const response = await mutateBackendFormData("/chat/room", formData)
      .then(async (data) => await data.json())
      .catch(() => ({ messageCode: 400 }));

    console.log(response);
    if (response.messageCode === 200) {
      console.log(response.data?.room_idx);
      navigate(`/Mypage/Chat?roomIdx=${response.data?.room_idx}`);
    } else {
      return false;
    };
  }


  const getMeetRoom = async (mento_seq, menti_user_seq, end_date, cmu_seq) => {

    const toDAY = moment().format("YYYY-MM-DD HH:mm:ss");
    const endDAY = moment(end_date).format("YYYY-MM-DD HH:mm:ss");

    //멘토 이용기간 체크
    const bool = moment(toDAY).isBefore(endDAY); // true

    if (bool) {
      const formData = new URLSearchParams();

      console.log(mento_seq, menti_user_seq);
      formData.append("mento_seq", mento_seq);
      formData.append("menti_user_seq", menti_user_seq);
      formData.append("cmu_seq", cmu_seq);

      //이미지인 경우
      const response = await mutateBackendFormData("/mentoring/video/room", formData)
        .then(async (data) => await data.json())
        .catch(() => ({ messageCode: 400 }));

      console.log(response);
      if (response.messageCode === 200) {
        console.log(response.data);
        window.open(response.data.join_url_mento, "_blank", "noopener, noreferrer");
        await refetch();
      } else {
        return false;
      };
    } else {
      setErrMsg((prevErr) => [...prevErr, '멘토 사용기간이 종료 되었습니다.']);
      setShowErrorModal(true);
    }


  };

  const closeMeetRoom = async (mento_seq, menti_user_seq, end_date, cmu_seq, useMeetCnt) => {

    const toDAY = moment().format("YYYY-MM-DD HH:mm:ss");
    const endDAY = moment(end_date).format("YYYY-MM-DD HH:mm:ss");

    //멘토 이용기간 체크
    const bool = moment(toDAY).isBefore(endDAY); // true

    if (bool) {
      const formData = new URLSearchParams();

      console.log(mento_seq, menti_user_seq);
      formData.append("mento_seq", mento_seq);
      formData.append("menti_user_seq", menti_user_seq);
      formData.append("cmu_seq", cmu_seq);
      formData.append("useMeetCnt", useMeetCnt);
      //useMeetCnt

      //이미지인 경우
      const response = await mutateBackendFormData("/mentoring/video/close", formData)
        .then(async (data) => await data.json())
        .catch(() => ({ messageCode: 400 }));

      console.log(response);
      if (response.messageCode === 200) {
        console.log(response.data);
        //window.open(response.data.join_url_mento, "_blank", "noopener, noreferrer");
        await refetch();
      } else {
        return false;
      };
    } else {
      setErrMsg((prevErr) => [...prevErr, '멘토 사용기간이 종료 되었습니다.']);
      setShowErrorModal(true);
    }


  }

  const modalClose = () => {
    setErrMsg([]);
    setShowErrorModal(false);
  }

  return (
    <>
      <section className="com_board_list__top">
        <article className="btn_wrap">
          <button className="com_btn blue oval xs">삭제</button>
        </article>
      </section>
      <table className="com_board_list">
        <colgroup>
          <col width="6%" />
          <col width="10%" />
          <col />
          <col width="13%" />
          <col width="13%" />
          <col width="17%" />
          <col width="15%" />
        </colgroup>
        <thead>
          <tr>
            <th></th>
            <th>멘티명</th>
            <th>상품명</th>
            <th>서비스 상품</th>
            <th>결제금액</th>
            <th>상태</th>
            <th>채널</th>
          </tr>
        </thead>
        <tbody>
          {mentorList2.length > 0 && mentorList2.map((item, indx) => {
            const serviceArr = item.service_code.split(',');
            return <>
              <tr key={indx}>
                <td className="chk_field">
                  <input type="checkbox" className="com_chk" id="chk01" />
                  <label htmlFor="chk01"></label>
                </td>
                <td className="id_field">{item.user_name}</td>
                <td className="tit_field">{item.goods_name}</td>
                <td className="info_field">
                  <span className="th_m">서비스 상품 :</span>
                  {serviceArr.reverse().map((el) => getService(el))}
                </td>
                <td className="info_field last">
                  <span className="th_m">결제금액 :</span>{item.amt}원
                </td>
                <td className="accept_field">
                  {viewStatus(item.menti_status, item.mento_seq, item.user_seq)}
                </td>
                <td className="btn_field">
                  {item.service_code.includes('MEET') ? <><button type="button" onClick={() => item.join_url_mento ? closeMeetRoom(item.mento_seq, item.user_seq, item.end_date, item.cmu_seq, item.use_meet_cnt) : getMeetRoom(item.mento_seq, item.user_seq, item.end_date, item.cmu_seq)} className="com_btn oval line s"> {item.join_url_mento ? `미팅닫기 (${item.supp_meet_cnt}/${item.use_meet_cnt})` : `미팅생성 (${item.supp_meet_cnt}/${item.use_meet_cnt})`}</button><br /></> : null}
                  {item.service_code.includes('CHAT') ? <><button type="button" onClick={() => getChatRoom(item.user_seq, 'ces_mentoring', item.mento_seq)} className="com_btn oval line s">채팅</button><br /></> : null}
                  {item.service_code.includes('BOARD') ? <><button className="com_btn oval line s">게시판</button><br /></> : null}
                  {/* <button className="com_btn oval line s">채팅</button><br/>
                  <button className="com_btn oval line s">게시판</button><br/> */}
                </td>
              </tr>
            </>

          })}
        </tbody>
      </table>

      <Modal open={showErrorModal} close={() => modalClose()}>
        <h2 className="pop_tit required" style={{ color: "red" }}>
          ERROR
        </h2>
        <div className="content_container scroll pop_company_chk">
          <ul className="tit">
            {errMsg
              ? errMsg.map((err, idx) => <li key={idx}>{err}</li>)
              : "서버에 문제가 있는 것 같습니다. 다시 시도해 주세요."}
          </ul>
        </div>
      </Modal>
    </>
  );
}

export default MenteeManagement;
