import React from "react";
import { Link } from "react-router-dom";

export default function SeminarFilter({
  title = "세미나 정보",
  dateTitle = "등록일:",
  showPaymentStatus = false,
  showPageLimit = true,
  showRegister = false,
  showDelete = false,
  showDownload = false,
  isFetching = false,
  total_count = 0,
  limit = 10,
  search_query = "",
  status = "",
  date_from = "",
  date_to = "",
  defaultSearchParams = {},
  setSearchParams = () => false,
}) {
  const handleFilterChange = (key, value) => {
    setSearchParams?.((prev) => {
      prev.set(key, value);
      return prev;
    });
  };
  return (
    <>
      <div className="adm_com_board_list__top">
        <div className="adm_com_date_select">
          <span className="tit">{title}</span>
        </div>
        <div className="adm_com_input_wrap flex_row">
          <span style={{ fontSize: "10px", fontWeight: "bold", margin: "0px 10px" }}>{dateTitle}</span>
          <input
            type="date"
            value={date_from}
            className="input"
            onChange={(e) => handleFilterChange("date_from", e.target.value)}
          />
          <span style={{ fontWeight: "bold" }}> ~ </span>
          <input
            type="date"
            multiple
            value={date_to}
            className="input"
            onChange={(e) => handleFilterChange("date_to", e.target.value)}
          />
          <span style={{ fontSize: "10px", fontWeight: "bold", margin: "0px 10px 0px 50px" }}>
            {showPaymentStatus ? "Payment Status:" : "Exposure:"}
          </span>
          <select
            name="comp_explosure"
            style={{ width: "10%" }}
            value={status}
            onChange={(e) => handleFilterChange("status", e.target.value)}>
            <option value="">All</option>
            {showPaymentStatus ? (
              <>
                <option value="COMPLETED">완료</option>
                <option value="PENDING">보류</option>
                <option value="FAILED">실패</option>
              </>
            ) : (
              <>
                <option value="Y">Y</option>
                <option value="N">N</option>
              </>
            )}
          </select>
          <input
            type="text"
            className="input name"
            placeholder="Please enter what you are searching for"
            value={search_query}
            onChange={(e) => handleFilterChange("search_query", e.target.value)}
          />
          <button
            style={{ backgroundColor: "lightgray", color: "black", padding: "10px" }}
            onClick={() => setSearchParams?.(defaultSearchParams)}>
            Reset
          </button>
        </div>
      </div>
      <div className="adm_com_btn_wrap between">
        <div className="txt flex-start">
          {total_count} 건 {isFetching && <i className="gg-spinner-alt" />}
        </div>
        {showPageLimit && (
          <select className="select" value={limit} onChange={(e) => handleFilterChange("limit", e.target.value)}>
            <option value={10}>10개씩 보기</option>
            <option value={20}>20개씩 보기</option>
          </select>
        )}
        <div className="txt flex-end">
          {showRegister && (
            <Link className="adm_com_btn black" to="/boffice/board/seminar/details/new">
              세미나 등록
            </Link>
          )}
          {showDelete && <button className="adm_com_btn red">Delete</button>}
          {showDownload && <button className="adm_com_btn black">엑셀 데이터 다운로드</button>}
        </div>
      </div>
    </>
  );
}
