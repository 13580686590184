import React, { useState, useRef, useEffect, useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, EffectFade } from "swiper";
import "../../assets/scss/page/member.scss";
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import { mutateBackend } from "../../../../../api";
import { AuthContext } from "../../../../../context/Auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// question type single
function SingleChoiceSlider({ question, selectedAnswerIndex, handleAnswerSelect }) {
  return (
    <div className="q_wrap">
      <p className="q_tit">{question.que_name}</p>
      <ul className="q_list">
        {question?.options.map((choice, answerIndex) => {
          return (
            <li className="q_item" key={`${question.que_cate}-${answerIndex}`}>
              <input
                type="radio"
                name={`question-${question.que_name}`}
                id={`choice-${answerIndex}-${question.que_cate}-${question.que_seq}-${choice.ex_no}`}
                className="com_chk"
                onChange={() => handleAnswerSelect(choice.ex_no)}
                checked={selectedAnswerIndex && selectedAnswerIndex?.ans_no === choice.ex_no ? true : false}
              />
              <label htmlFor={`choice-${answerIndex}-${question.que_cate}-${question.que_seq}-${choice.ex_no}`}>
                {choice.ex_text}
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

// question type double
function MultiSelectSlider({ question, selectedAnswerIndices, handleAnswerSelect }) {
  // Count the number of selected choices
  const selectedCount = selectedAnswerIndices.length;

  return (
    <div className="q_wrap">
      <p className="q_tit">{question.que_name}</p>
      <ul className="com_tag_list type_select">
        {question?.options.map((choice, answerIndex) => (
          <li
            className={`com_btn bg white s ${
              selectedAnswerIndices.some((el) => el.ans_no === choice.ex_no) ? "active" : ""
            } ${
              selectedCount >= question.max_cnt && !selectedAnswerIndices.some((el) => el.ans_no === choice.ex_no)
                ? "disabled"
                : ""
            }`}
            onClick={() => handleAnswerSelect(choice.ex_no)}
            key={`${question.que_cate}-${answerIndex}`}>
            {choice.ex_text}
          </li>
        ))}
      </ul>
    </div>
  );
}

function MyFit() {
  const [tabState, setTabState] = useState("QC01"); // temporary
  const [selectedAnswers, setSelectedAnswers] = useState([]);

  const { user, refetchUserInfo } = useContext(AuthContext);

  const swiperRef = useRef(null);
  const navigate = useNavigate();

  const {
    data: { dataList: tendencyData, answeredCate: answeredCate } = { dataList: [], answeredCate: [] },
    refetch: refetchTendency,
  } = useCustomQuery(`/tendency?code_gr=QUE_CODE`, {
    queryKey: ["selectTendencyData"],
    select: (res) => res?.data ?? { data_list: [], answeredCate: [] },
  });

  useEffect(() => {
    const newSelectedAnswers = tendencyData?.flatMap((category) =>
      category.question.map((question) => ({
        que_cate: category.que_cate,
        que_seq: question.que_seq,
        ans_no: "",
      })),
    );
    setSelectedAnswers(newSelectedAnswers);
  }, [tendencyData, answeredCate]);

  const handleSingleChoiceAnswerSelect = (tabIndex, questionIndex, answerIndex) => {
    const updatedAnswers = [...selectedAnswers];

    const selectAnswerIndex = updatedAnswers.findIndex(
      (el) => el.que_cate === tabIndex && el.que_seq === questionIndex,
    );

    if (selectAnswerIndex !== -1) {
      updatedAnswers[selectAnswerIndex].ans_no = answerIndex;
    } else {
      updatedAnswers.push({ que_cate: tabIndex, que_seq: questionIndex, ans_no: answerIndex });
    }
    setSelectedAnswers(updatedAnswers);
  };

  const handleMultiSelectAnswerSelect = (tabIndex, questionIndex, answerIndex) => {
    const updatedAnswers = [...selectedAnswers];
    const selectAnswer = updatedAnswers.filter((el) => el.que_cate === tabIndex && el.que_seq === questionIndex);

    const selectAnswerIndex = updatedAnswers.findIndex(
      (el) => el.que_cate === tabIndex && el.que_seq === questionIndex && el.ans_no === answerIndex,
    );
    if (selectAnswerIndex !== -1) {
      if (selectAnswer.length === 1) {
        selectAnswer[0].ans_no = "";
      } else {
        updatedAnswers.splice(selectAnswerIndex, 1);
      }
    } else {
      if (selectAnswer.length === 1 && selectAnswer[0]?.ans_no === "") {
        selectAnswer[0].ans_no = answerIndex;
      } else if (selectAnswer.length < 5) {
        updatedAnswers.push({ que_cate: tabIndex, que_seq: questionIndex, ans_no: answerIndex });
      } else return;
    }
    setSelectedAnswers(updatedAnswers);
  };

  const handleSubmitAnswer = async () => {
    const response = await mutateBackend(`/tendency/${user.user_seq}`, {
      data: {
        selectAnswer: selectedAnswers,
        type: "user",
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (data) => await data.json())
      .catch((err) => {
        throw new Error(err);
      });
    if (response.messageString === "SUCCESS" && response.messageCode === 200) {
      refetchUserInfo();
      refetchTendency();
      toast.success("제출이 완료되었습니다.");
      navigate("/mypage/Myfit2");
    } else {
      console.log(response);
    }
  };

  return (
    <>
      <div className="container com_mypage p_myFit">
        <h2 className="com_pg_tit">나의성향 설문지</h2>
        <div className="com_pg_tab">
          {tendencyData?.map((v) => (
            <button key={v.que_cate} className={`tab ${tabState === v.que_cate ? "active" : ""}`}>
              {v.cate_name}
            </button>
          ))}
        </div>

        <div className="tab_content">
          <section className="sec_survey">
            <div className="swiper_wrap jobFit">
              <div className="custom_pagination">
                <div className="swiper-scrollbar"></div>
                <div className="swiper-button-prev"></div>
                <div className="swiper_fraction"></div>
              </div>
              <Swiper
                modules={[Pagination, Navigation, Scrollbar, EffectFade]}
                onSwiper={(swiper) => (swiperRef.current = swiper)}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                scrollbar={{ el: ".swiper-scrollbar" }}
                pagination={{
                  el: ".jobFit .swiper_fraction",
                  type: "custom",
                  renderCustom: function (swiper, current, total) {
                    return (
                      '<span class="com_swiper_fraction current">' +
                      current +
                      '</span> / <span class="com_swiper_fraction">' +
                      total +
                      "<span>"
                    );
                  },
                }}
                slidesPerView={1}
                spaceBetween={10}
                effect="slide">
                {tendencyData &&
                  tendencyData?.map((cate) =>
                    cate?.question?.map((tab, tabIndex) => (
                      <>
                        <SwiperSlide key={`${cate.que_cate}-${tabIndex}`}>
                          {tab.type === "single" ? (
                            <SingleChoiceSlider
                              question={{ ...tab, que_cate: cate.que_cate }}
                              selectedAnswerIndex={selectedAnswers.find(
                                (el) => el.que_cate === cate.que_cate && el.que_seq === tab.que_seq,
                              )}
                              handleAnswerSelect={(answer) =>
                                handleSingleChoiceAnswerSelect(cate.que_cate, tab.que_seq, answer)
                              }
                            />
                          ) : (
                            <MultiSelectSlider
                              question={{ ...tab, que_cate: cate.que_cate }}
                              selectedAnswerIndices={selectedAnswers.filter(
                                (el) => el.que_cate === cate.que_cate && el.que_seq === tab.que_seq,
                              )}
                              handleAnswerSelect={(answer) =>
                                handleMultiSelectAnswerSelect(cate.que_cate, tab.que_seq, answer)
                              }
                            />
                          )}
                          {
                            <div className="com_btn_wrap center">
                              {selectedAnswers
                                // .filter((answer) => answer.que_cate === tabState)
                                .every((answer) => answer.ans_no !== "") && tabIndex === cate.question.length - 1 ? (
                                <button className="com_btn bg blue m" onClick={() => handleSubmitAnswer(tabState)}>
                                  제출하기
                                </button>
                              ) : (
                                <button
                                  disabled={
                                    tab.type === "single"
                                      ? selectedAnswers.find(
                                          (el) => el.que_cate === cate.que_cate && el.que_seq === tab.que_seq,
                                        )?.ans_no === ""
                                      : selectedAnswers.filter(
                                          (el) =>
                                            el.que_cate === cate.que_cate &&
                                            el.que_seq === tab.que_seq &&
                                            el.ans_no === "",
                                        ).length > 0
                                  }
                                  className="com_btn bg blue m"
                                  onClick={() => {
                                    if (swiperRef.current) {
                                      if (tabIndex === cate.question.length - 1) {
                                        if (tabState === "QC01") setTabState("QC02");
                                        if (tabState === "QC02") setTabState("QC03");
                                      }
                                      swiperRef.current.slideNext();
                                    }
                                  }}>
                                  다음으로
                                </button>
                              )}
                            </div>
                          }
                        </SwiperSlide>
                      </>
                    )),
                  )}
              </Swiper>
            </div>
          </section>
        </div>
      </div>
      <ScrollToTopButton />
    </>
  );
}

export default MyFit;
