import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCustomQuery } from "../../../../../../hooks/useAPI";
import Table from "../../../../screens/components/boffice/Table";
import useCommCate from "../../../../../../hooks/useCommCate";

export default function MentorDetail() {
  const navigate = useNavigate();

  const { user_seq } = useParams();

  const [mentor, setMentor] = useState();
  const [products, setProducts] = useState();

  const { isFetching } = useCustomQuery("/mentoring/mentor/" + user_seq, {
    queryKey: ["getMentoring/" + user_seq],
    select: (res) => (res?.data.data_list.length > 0 ? res?.data.data_list : null),
    onSuccess: async (data) => {
      if (!data) navigate(-1);

      setProducts(data);
      setMentor(data[0]);
    },
    refetchOnWindowFocus: false,
  });

  const { data: categories } = useCommCate("MENTO_CATE");

  if (isFetching || !mentor) return <i className="gg-spinner-big" />;

  return (
    <main>
      <h2 className="adm_com_pg_tit2 bottom_line"> 멘토링 관리</h2>
      {/* User Information */}
      <div className="adm_com_pg_content">
        <h2 className="sub_tit">- 멘토 회원 관리 &gt; 상세</h2>
        <div className="sub_tit"> 멘토 신청자 정보</div>
        <table className="adm_com_board_list type_row">
          <colgroup>
            <col width="10%" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              {/* User ID */}
              <th>사용자 ID</th>
              <td>{mentor["user_id"]}</td>
            </tr>
            <tr>
              {/* User name */}
              <th>이름</th>
              <td>{mentor["user_name"]}</td>
            </tr>
            <tr>
              {/* Email */}
              <th>이메일</th>
              <td>{mentor["user_email"]}</td>
            </tr>
            <tr>
              {/* Phone Number */}
              <th>전화 번호</th>
              <td>{mentor["user_mobile"]}</td>
            </tr>
            <tr>
              {/* Application Date */}
              <th>승인일</th>
              <td>{new Date(mentor["application_dtm"]).toISOString().split("T")[0]}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* Mentor Products */}
      <div className="adm_com_pg_content">
        <div className="sub_tit"> 등록한 상품</div>
        <Table
          columns={{
            mento_seq: "번호",
            mento_title: "상품명",
            mento_amt: "상품 금액",
            mento_cate: "카테고리",
            mentee_cnt: "멘티 수",
            avg_rating: "후기 총 평점",
            reg_dtm: "등록일자",
            detail: {
              label: "상세",
              getLink: (item) => "/boffice/mentoring/manage/member/" + user_seq + "/product/" + item["mento_seq"],
              linkLabel: "상세",
            },
          }}
          data={
            products &&
            Array.isArray(products) &&
            products.map((product) => ({
              ...product,
              mento_cate:
                categories.find((cate) => cate["comm_code"] === product["mento_cate"]) &&
                categories.find((cate) => cate["comm_code"] === product["mento_cate"])["code_nm_kr"],
              avg_rating: Number(product["avg_rating"]) === 0 ? "N/A" : `${Number(product["avg_rating"])}/5`,
            }))
          }
        />
      </div>
      <div className="adm_com_btn_wrap center bottom">
        <button className="adm_com_btn gray" onClick={() => navigate(-1)}>
          돌아가기
        </button>
      </div>
    </main>
  );
}
