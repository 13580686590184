import React, { useEffect } from "react";

function Modal(props) {
  const { open, close, type, popSize } = props;
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "";
      };
    }
  });
  return (
    <>
      {open ? (
        <section className={`adm_com_popup ${type ? type : ""}`}>
          <div className={`pop_container ${popSize ? popSize : ""}`}>
            <button type="button" className="btn_close" onClick={close}></button>
            {props.children}
          </div>
          <i className="bg_close" onClick={close}></i>
        </section>
      ) : null}
    </>
  );
}

export default Modal;
