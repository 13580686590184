import React, { useState } from "react";
import Upload from "../../components/Upload";
import { mutateBackendFormData } from "../../../../../api";
import { getUserImageLink } from "../../../../../utils/misc";
import { Rating, RenderStars } from "./_Rating";

export default function MentoringReviewTab({ avg_rating, handleSubmitReview, isRegistered, reviews, user }) {
  const [reviewContent, setReviewContent] = useState("");
  const [attachment, setAttachment] = useState();

  const [rating, setRating] = useState(0);

  async function handleUpload(files) {
    const formData = new FormData();

    formData.append("file", files[0]);
    formData.append("upload_ty", new Date().toISOString());

    const response = await mutateBackendFormData("/upload/file", formData)
      .then(async (data) => await data.json())
      .then((res) => res.data[0]["up_file_seq"])
      .catch(() => ({ messageCode: 400 }));

    setAttachment(response);
  }

  return (
    <div className="tab_contents" style={{ minHeight: 200 }}>
      {Number(avg_rating) > 0 && (
        <>
          <div className="star-component">
            <div className="rating-text-title">이 제품을 평가하십시오</div>
            <div className="rating-text">{avg_rating}</div>
            <RenderStars rating={avg_rating} />
          </div>
          <div className="review-list">
            {reviews &&
              // Array(10)
              //   .fill(reviews[0])
              reviews.map((review, i) => (
                <div className="tab_box bg_box" key={i}>
                  <div className=" review-header">
                    <div className="info">
                      <p className="profile_img">
                        <img src={getUserImageLink(review["user_img"])} alt="이미지" />
                      </p>
                      <div className="profile">
                        <p className="name">{review["user_id"]}</p>
                        <span className="desc">{new Date().toISOString().split("T")[0]}</span>
                      </div>
                    </div>
                    <RenderStars rating={Number(review["rate_score"]).toFixed(1)} />
                  </div>
                  <div className="info">
                    <div className="profile">
                      <p className="review-content">{review["review_contents"]}</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmitReview(rating, reviewContent, attachment);
        }}>
        <div className="tab_box bg_box">
          <div className="info">
            <p className="profile_img">
              <img src={getUserImageLink(user["user_img"])} alt="이미지" />
            </p>
            <div className="profile">
              <p className="name">{user["user_name"]}</p>
              <span className="desc">{new Date().toISOString().split("T")[0]}</span>
            </div>
          </div>
        </div>
        <ul className="com_input_sec">
          <li className="tit_field">
            내용 <i className="icon_must">*</i>
          </li>
          <li className="input_field">
            <textarea
              className="textarea"
              value={reviewContent}
              onChange={(e) => setReviewContent(e.target.value)}
              required
            />
          </li>
          <li className="tit_field">
            <Rating handleRate={(rating) => setRating(rating)} />
          </li>
          <li className="tit_field">
            <strong>파일 첨부</strong> <span>(선택 , 5mb미만의 이미지 파일(.jpg,.png.gif)만 가능)</span>
          </li>
          <li className="input_field">
            <Upload setFiles={handleUpload} files={attachment} title="파일선택" className="com_btn line m btn_upload" />
          </li>
          <li className="tit_field">{attachment ? 1 : 0} 개 항목을 첨부했습니다.</li>
        </ul>
        <div className="com_btn_wrap center">
          {isRegistered && (
            <button className="com_btn blue m btn_answer" disabled={!isRegistered}>
              글올리기
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
