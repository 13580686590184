import React, { useState } from "react";
import Modal from "../../components/modal";
import { useCustomQuery } from "../../../../../hooks/useAPI";

function PopFilter(props) {
  const { open, close, setFilter } = props;

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isPaid, setIsPaid] = useState(null);

  const { data: tags } = useCustomQuery(`/category?cate_gr=INSIG_TAG`, {
    queryKey: ["getInsightTags/"],
    select: (res) => res?.data.categories,
    onSuccess: (data) => {
      setFilter(data);
    },
  });

  const handleCheckboxChange = (id) => {
    if (selectedOptions.includes(id)) {
      setSelectedOptions((prevOptions) => prevOptions.filter((option) => option !== id));
    } else if (selectedOptions.length < 3) {
      setSelectedOptions((prevOptions) => [...prevOptions, id]);
    }
  };

  const handleApplyButtonClick = () => {
    setFilter({ isPaid, tags: selectedOptions });

    close();
  };

  return (
    <Modal open={open} close={close} type="pop_apply pop_filter">
      <h2 className="pop_tit">필터</h2>
      <div className="content_container scroll">
        <div className="info_wrap">
          <div className="box">
            <p className="input_tit first">유료/무료</p>
            <div className="list">
              <input
                type="radio"
                name="radio1"
                className="com_chk type_btn"
                id="radio1_1"
                onClick={() => setIsPaid(null)}
              />
              <label htmlFor="radio1_1">전체</label>
              <input
                type="radio"
                name="radio1"
                className="com_chk type_btn"
                id="radio1_2"
                onClick={() => setIsPaid(true)}
              />
              <label htmlFor="radio1_2">유료</label>
              <input
                type="radio"
                name="radio1"
                className="com_chk type_btn"
                id="radio1_3"
                onClick={() => setIsPaid(false)}
              />
              <label htmlFor="radio1_3">무료</label>
            </div>
          </div>
          <div className="box">
            <p className="input_tit">태그</p>
            <div className="list">
              {tags?.map((option) => (
                <React.Fragment key={option.cate_seq}>
                  <input
                    type="checkbox"
                    className="com_chk type_btn"
                    id={option.cate_seq}
                    checked={selectedOptions?.includes(option)}
                    onChange={() => handleCheckboxChange(option)}
                  />
                  <label htmlFor={option.cate_seq}>{option.cate_name}</label>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="btn_container">
        <button className="com_btn blue m BtnPopClose" onClick={handleApplyButtonClick}>
          적용하기
        </button>
      </div>
    </Modal>
  );
}

export default PopFilter;
