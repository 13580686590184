import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import "../../assets/scss/page/member.scss";
//component
import { useCustomQuery } from "../../../../../hooks/useAPI";
import Pagination from "../../components/Pagination";
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";

function PayList() {
  const [searchParams, setSearchParams] = useSearchParams({ page: 1, limit: 6 });
  const page = +searchParams.get("page");
  const limit = +searchParams.get("limit");

  const { isFetching, data: { dataList = [], totalCnt = 0 } = { seminars: [], total_count: 0 } } = useCustomQuery(
    `/payments/my?curr_page=${page}&per_page=${limit}`,
    {
      queryKey: ["getPaymentListPoints", page, limit],
      select: (res) => res?.data ?? { seminars: [], total_count: 0 },
    },
  );

  return (
    <>
      <div className="container">
        <h2 className="com_pg_tit">결제내역</h2>
        <table className="com_board_list">
          <colgroup>
            <col width="10%" />
            <col width="12%" />
            <col />
            <col width="13%" />
            <col width="13%" />
            <col width="13%" />
            <col width="10%" />
            <col width="10%" />
          </colgroup>
          <thead>
            <tr>
              <th>날짜</th>
              <th>사용처</th>
              <th>상품명</th>
              <th>결제 포인트</th>
              <th>이벤트 포인트</th>
              <th>
                현재 포인트
                <br />
                (이벤트 포인트)
              </th>
              <th>상태</th>
              <th>포인트 환불</th>
            </tr>
          </thead>
          <tbody>
            {isFetching ? (
              <i className="gg-spinner-alt" />
            ) : dataList?.length > 0 ? (
              dataList?.map((obj) => (
                <tr key={obj.pay_seq}>
                  <td className="info_field">
                    <span className="th_m">날짜 :</span>
                    {new Date(obj.pay_dtm).toLocaleDateString()}
                  </td>
                  <td className="info_field">
                    <span className="th_m">사용처 :</span>
                    {obj.goods_type}
                  </td>
                  <td className="tit_field">
                    <Link to="" className="link_view">
                      {obj.goods_name}
                    </Link>
                  </td>
                  <td className="info_field">
                    <span className="th_m">결제 포인트 :</span>-{obj.use_point}
                  </td>
                  <td className="info_field">
                    <span className="th_m">이벤트 포인트 :</span>0p
                  </td>
                  <td className="info_field last">
                    <span className="th_m">현재 포인트(이벤트 포인트) :</span>0p(0p)
                  </td>
                  <td className="state_field">충전완료</td>
                  <td className="btn_field">
                    <p className="com_btn oval line s gray">환불</p>
                  </td>
                </tr>
              ))
            ) : (
              <h3>등록된 내역이 없습니다.</h3>
            )}
          </tbody>
        </table>

        <Pagination
          currentPage={page}
          totalPages={Math.ceil(totalCnt / limit)}
          perPage={limit}
          setSearchParams={setSearchParams}
        />
      </div>

      {/* top button */}
      <ScrollToTopButton />
    </>
  );
}

export default PayList;
