import React from "react";
import { Route, Routes } from "react-router-dom";
import Apply from "../views/screens/page/seminar/Apply";
import Detail from "../views/screens/page/seminar/Detail";
import List from "../views/screens/page/seminar/List";
import ProtectedRoute from "./ProtectedRoute";

function SeminarRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<List />} />
        <Route path="/detail/:id" element={<Detail />} />
        <Route
          path="/apply/:id"
          element={
            <ProtectedRoute>
              <Apply />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
}

export default SeminarRoutes;
