import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../context/Auth";
import IconAlarm from "../assets/img/icon_alarm.png";
import IconMy from "../assets/img/icon_my.png";
import Logo from "../assets/img/logo.png";

function Header() {
  const { logout, isAuthenticated, user } = useContext(AuthContext);

  const navigate = useNavigate();

  const [isActive, setIsActive] = useState(false);

  function toggleMenu() {
    setIsActive(!isActive);
  }

  const gnbData = useMemo(
    () => [
      {
        title: "AI매칭",
        link: user.user_type === "COM" ? "/match/JobList" : "/match",
      },
      {
        title: "멘토링",
        link: "/mentoring",
      },
      {
        title: "인사이트",
        link: "/insight",
      },
      {
        title: "커뮤니티",
        link: "/community",
      },
      {
        title: "매거진",
        link: "/magazine",
      },
      {
        title: "세미나",
        link: "/seminar",
      },
      // {
      //   title: "1:1매니징 서비스",
      //   link: "/Managing",
      // },
    ],
    [],
  );

  const renderGnb = useCallback(() => {
    return gnbData.map((v, idx) => {
      return (
        <li className="depth01" key={idx}>
          <NavLink to={v.link} onClick={() => setIsActive(false)} className={`menu ${isActive ? "active" : ""}`}>
            {v.title}
          </NavLink>
        </li>
      );
    });
  }, [gnbData, isActive]);

  useEffect(() => {
    if (isActive) {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "";
      };
    }
  });

  return (
    <header className={"head" + (isActive ? " active" : "")}>
      <div className="com_center_wrap">
        <Link to="/" onClick={() => setIsActive(false)} className="logo">
          <img src={Logo} alt="logo" />
        </Link>
        <div className="gnb_container">
          <ul className="gnb_menu">{renderGnb()}</ul>
        </div>
        <section className="gnb_link">
          {isAuthenticated ? (
            <>
              {user.user_type === "COM" ? (
                <Link to="/company/info" onClick={() => setIsActive(false)} className="link btn_company_home">
                  기업 회원 홈
                </Link>
              ) : (
                <Link to={"/mypage/MyResume"} onClick={() => setIsActive(false)} className="link btn_my_page">
                  <img
                    src={IconMy}
                    style={{
                      height: 20,
                      width: 22,
                      filter: `invert(63%) sepia(49%) saturate(530%) hue-rotate(86deg) brightness(89%) contrast(87%)`,
                    }}
                    alt="로그아웃"
                  />{" "}
                  My
                </Link>
              )}
              {/* Logout Button */}
              <button
                onClick={async () => {
                  logout();
                  navigate("/");
                }}
                className="link btn_logout">
                로그아웃
              </button>
              <Link
                to={`/${user.user_type === "COM" ? "Company" : "MyPage"}/Notification`}
                onClick={() => setIsActive(false)}
                className="link btn_push">
                <img src={IconAlarm} alt="alarm" />
                <i className="push">2</i>
              </Link>
            </>
          ) : (
            <Link to="/member/login" onClick={() => setIsActive(false)} className="link btn_my">
              <img src={IconMy} alt="로그아웃" />
            </Link>
          )}
        </section>
        <button
          className="btn_bar BtnBar"
          onClick={() => {
            toggleMenu();
          }}></button>
      </div>
    </header>
  );
}

export default Header;
