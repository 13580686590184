import React, { useRef } from "react";

function Upload({ className = "common_upload_icon_btn", ...props }) {
  const uploadInput = useRef();
  const fnUpload = () => {
    uploadInput.current.click();
  };

  return (
    <>
      <input
        type="file"
        className="common_upload_input_file"
        ref={uploadInput}
        onChange={(e) => {
          if ((props?.files?.length ?? 0) + e.target.files?.length > 5) {
            alert("You are only allowed to upload a maximum of 5 files at a time");
            return;
          }
          props?.setFiles?.(e.target.files);
        }}
        multiple={props?.multiple}
      />
      <button type="button" onClick={fnUpload} disabled={props?.files?.length > 4} className={className}>
        {props.title}
      </button>
    </>
  );
}

export default Upload;
