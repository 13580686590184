import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { queryBackend } from "../../../../../api";
import Logo from "../../assets/img/logo.png";
import "../../assets/scss/page/member.scss";

function SearchPw() {
  const { handleSubmit, register, getValues } = useForm();
  const navigate = useNavigate();

  // const onValidSubmit = async (data) => {
  //   console.log({ data });
  //   const res = await queryBackend("/user/findPw");
  //   console.log({ res });
  //   navigate("/screens/member/newPw");
  // };
  const onValidSubmit = async () => {
    const value = document.getElementById('name').value;
    if (!value) {
      alert('휴대폰 본인 인증 진행해주세요.');
      return false;
    }
    const userInfo = await queryBackend(`/user/search/userId?user_name=${getValues("user_name")}&user_mobile=${getValues("user_mobile")}&user_id=${getValues("user_id")}`).then(
      async (data) => (await data.json()).data,
    );

    console.log(userInfo);
    if (userInfo.count > 0) {
      console.log(userInfo);
      navigate(`/member/newPw?userSeq=${userInfo.userSeq}`);
    } else {
      alert('존재하지 않는 회원 정보입니다.');
      return false;
    }
  };

  async function handleMobileCheck(event) {
    event.preventDefault();
    fnPopup();
  }

  function fnPopup() {

    //백엔드 통신
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${process.env.REACT_APP_ENV === "development" ? process.env.REACT_APP_SERVER_BASE_URL_DEV : process.env.REACT_APP_SERVER_BASE_URL_PROD}/nice/EncData`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const CertData = JSON.parse(result);
        console.log(CertData);

        document.getElementById("EncodeData").value = CertData.data.sEncData;

        window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
        document.form_chk.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
        document.form_chk.target = "popupChk";
        document.form_chk.submit();
        // setVisible(true);
      })
      .catch((error) => console.log("error", error));
  }


  return (
    <>
      <main className="com_member">
        {/* 나이스 본인인증 form */}
        <form name="form_chk" method="post">
          <input type="hidden" name="m" id="m" value="checkplusService" />
          <input type="hidden" name="EncodeData" id="EncodeData" value="" />
          <input type="hidden" name="birthdate" id="birthdate" value="" />
          <input type="hidden" name="requestnumber" id="requestnumber" value="" />
          <input type="hidden" name="responsenumber" id="responsenumber" value="" />
          <input type="hidden" name="authtype" id="authtype" value="" />
          <input type="hidden" name="name" id="name" value="" />
          <input type="hidden" name="gender" id="gender" value="" />
          <input type="hidden" name="nationalinfo" id="nationalinfo" value="" />
          <input type="hidden" name="dupinfo" id="dupinfo" value="" />
        </form>

        <h1 className="logo">
          <Link to="/front/">
            <img src={Logo} alt="logo" />
          </Link>
        </h1>
        <div className="box">
          <h2 className="pg_tit">비밀번호 찾기</h2> {/* find password */}
          <p className="pg_txt">회원가입 시 등록했던 정보를 입력하세요.</p>{" "}
          {/*
          Enter the information you registered when signing up for membership.
          */}
          <form onSubmit={handleSubmit(onValidSubmit)}>
            <ul className="com_input_sec">
              <li className="tit_field">
                아이디<i className="icon_must">*</i>
              </li>{" "}
              {/* ID */}
              <li className="input_field">
                <input type="text" placeholder="아이디를 입력해주세요" {...register("user_id", { required: true })} />
              </li>
              <li className="tit_field">
                이름<i className="icon_must">*</i>
              </li>{" "}
              {/* Name */}
              <li className="input_field">
                <input type="text" placeholder="이름을 입력해주세요" {...register("user_name", { required: true })} />
              </li>
              {/* phone number */}
              <li className="tit_field">
                휴대폰 번호 <i className="icon_must">*</i>
              </li>{" "}
              <li className="input_field">
                <input type="text" placeholder="'-'빼고 숫자만 입력해주세요. (예: 01012345678)" {...register("user_mobile", { required: true })} />
              </li>
              <li className="bottom_btn_field">
                <button type="button" className="com_btn m blue line" onClick={handleMobileCheck}>휴대폰 본인 인증</button> {/* Mobile phone authentication */}
              </li>
            </ul>
            <section className="com_btn_wrap">
              <button onClick={() => navigate("/member/Login")} className="com_btn gray l">
                취소
              </button>{" "}
              {/* Cancellation */}
              <button className="com_btn blue l" type="submit">
                확인
              </button>{" "}
              {/* Check */}
            </section>
          </form>
        </div>
      </main>
    </>
  );
}

export default SearchPw;
