import React from "react";
import { Route, Routes } from "react-router-dom";

import Pay from "./../views/screens/page/insight/Pay";
import Detail from "./../views/screens/page/insight/Detail";
import List from "./../views/screens/page/insight/List";
import Write from "./../views/screens/page/insight/Write";
import ProtectedRoute from "./ProtectedRoute";

import "../views/screens/assets/scss/page/insight.scss";

function InsightRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<List />} />
        <Route
          path="/Write"
          element={
            <ProtectedRoute>
              <Write />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Write/:insig_seq"
          element={
            <ProtectedRoute>
              <Write />
            </ProtectedRoute>
          }
        />
        <Route path="/:insight_seq" element={<Detail />} />
        <Route
          path="/Pay/:insight_seq"
          element={
            <ProtectedRoute>
              <Pay />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
}

export default InsightRoutes;
