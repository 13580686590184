import React from "react";
import { Route, Routes } from "react-router-dom";

import List from "../views/screens/page/magazine/List";
import Detail from "../views/screens/page/magazine/Detail";
import RecommendList from "../views/screens/page/magazine/RecommendList";

function MagazineRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<List />} />
        <Route path="/:mag_seq" element={<Detail />} />
        <Route path="/RecommendList" element={<RecommendList />} />
      </Routes>
    </>
  );
}

export default MagazineRoutes;
