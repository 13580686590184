import React, { useEffect, useState } from "react";
import Paging from "../../../component/Paging";
import Table from "../../../../screens/components/boffice/Table";
import MemberFilter from "../../../component/MemberFilter";
import { useCustomQuery } from "../../../../../../hooks/useAPI";

const columns = {
  user_seq: "번호",
  user_id: "아이디",
  user_name: "사용자 이름",
  user_email: "이메일",
  user_mobile: "전화번호",
  mento_status: "승인상태",
  application_dtm: "등록 날짜",
  // * If it is a link, it passes an object
  detail: {
    label: "상세",
    getLink: (item) => "/boffice/mentoring/manage/apply/" + item["user_seq"],
    linkLabel: "상세보기",
  },
};

const MentoStatus = Object.freeze({
  MS10: "승인대기",
  MS20: "승인완료",
});

export default function MentorApplyList() {
  const [page, setPage] = useState(1);
  const [memberPerPage, setMemberPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const [mentorshipStatus, setMentorshipStatus] = useState("");

  const [paramArguments, setParamArguments] = useState("");

  const [memberList, setMemberList] = useState([]);

  const { isFetching } = useCustomQuery(
    `/mentoring/application/list?per_page=${memberPerPage}&curr_page=${page}&` + paramArguments,
    {
      queryKey: ["getMentoringApplication/", paramArguments, page, memberPerPage],
      select: (res) => res?.data,
      onSuccess: async (data) => {
        setTotalCount(data.total_cnt);
        setMemberList(data.data_list);
      },
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    const params = new URLSearchParams(paramArguments);
    if (mentorshipStatus !== "") params.set("mento_status", mentorshipStatus);
    else params.delete("mento_status");

    setParamArguments(params.toString());
  }, [mentorshipStatus]);

  const handlePageClick = (toPage) => {
    if (Number.isInteger(toPage)) return setPage(toPage);

    switch (toPage) {
      case "first": {
        setPage(1);
        break;
      }
      case "next": {
        page < Math.floor(totalCount / memberPerPage) && setPage((prev) => prev + 1);
        break;
      }
      case "prev": {
        page > 1 && setPage((prev) => prev - 1);
        break;
      }
      case "last": {
        setPage(Math.ceil(totalCount / memberPerPage));
        break;
      }
    }
  };

  return (
    <main>
      <h2 className="adm_com_pg_tit2 bottom_line"> 멘토링 관리</h2>
      <div className="adm_com_pg_content">
        <h2 className="sub_tit">- 멘토 신청 내역</h2>

        <MemberFilter
          columns={columns}
          dateLabel={"신청일자"}
          dataPerPage={memberPerPage}
          setDataPerPage={setMemberPerPage}
          defaultSearchColumn={"user_name"}
          setParamArguments={setParamArguments}
          additionalFields={
            <div className="adm_com_input_wrap flex_row">
              <span className="tit">승인상태</span>
              <select className="select" onChange={(e) => setMentorshipStatus(e.target.value)}>
                <option value={""}>전체</option>
                <option value={"MS10"}>{MentoStatus["MS10"]}</option>
                <option value={"MS20"}>{MentoStatus["MS20"]}</option>
              </select>
            </div>
          }
        />

        <div className="sub_tit">
          <span className="txt flex-start">
            {totalCount} 건 {isFetching && <i className="gg-spinner-alt" />}
          </span>
        </div>
        <Table
          data={memberList.map((member) => ({ ...member, mento_status: MentoStatus[member.mento_status] }))}
          columns={columns}
          checked={false}
          handleCheck={null}
        />
        <Paging handlePageClick={handlePageClick} page={page} totalPageCount={Math.ceil(totalCount / memberPerPage)} />
      </div>
    </main>
  );
}

// TODO Filter with Application Date
// const ApplicationDateFilter = ({ setParamArguments }) => {
//   const [startDate, setStartDate] = useState("");
//   const [endDate, setEndDate] = useState("");

//   const handleStartDateChange = (event) => {
//     setStartDate(event.target.value);
//   };

//   const handleEndDateChange = (event) => {
//     setEndDate(event.target.value);
//   };

//   const handleDateFilter = (days) => {
//     const today = new Date();
//     const newEndDate = new Date();
//     const newStartDate = new Date();

//     newEndDate.setDate(today.getDate());
//     newStartDate.setDate(today.getDate() - days);

//     setStartDate(newStartDate.toISOString().split("T")[0]);
//     setEndDate(newEndDate.toISOString().split("T")[0]);
//   };

//   useEffect(() => {
//     const params = new URLSearchParams();

//     if (startDate !== "") params.set("withdrawal_start_date", startDate);
//     if (endDate !== "") params.set("withdrawal_end_date", endDate);

//     setParamArguments(params.toString());
//   }, [startDate, endDate]);

//   return (
//     <div className="adm_com_date_select">
//       <span className="tit">검색 인출 날짜</span>
//       <input
//         type="date"
//         className="adm_com_datepicker"
//         placeholder={new Date().toLocaleDateString()}
//         value={startDate}
//         onChange={handleStartDateChange}
//       />
//       <span className="divider"> ~ </span>
//       <input
//         type="date"
//         className="adm_com_datepicker"
//         placeholder={new Date().toLocaleDateString()}
//         value={endDate}
//         onChange={handleEndDateChange}
//       />
//       {/* <button className="adm_com_btn gray">조회</button> */}
//       <div className="adm_com_btn_wrap">
//         <button className="adm_com_btn line gray" onClick={() => handleDateFilter(0)}>
//           오늘
//         </button>
//         <button className="adm_com_btn line gray" onClick={() => handleDateFilter(3)}>
//           3일전
//         </button>
//         <button className="adm_com_btn line gray" onClick={() => handleDateFilter(7)}>
//           일주일
//         </button>
//         <button className="adm_com_btn line gray" onClick={() => handleDateFilter(30)}>
//           1개월
//         </button>
//         <button className="adm_com_btn line gray" onClick={() => handleDateFilter(90)}>
//           3개월
//         </button>
//         <button
//           className="adm_com_btn line gray"
//           onClick={() => {
//             setStartDate("");
//             setEndDate("");
//           }}>
//           전체
//         </button>
//       </div>
//     </div>
//   );
// };
