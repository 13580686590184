import React, { useEffect, useState } from "react";
import Paging from "../../component/Paging";
import { useAuthenticatedQuery } from "../../../../../hooks/useAPI";
import Table from "../../../screens/components/boffice/Table";
import MemberFilter from "../../component/MemberFilter";

const columns = {
  user_seq: "번호",
  user_id: "아이디",
  user_nickname: "닉네임",
  user_name: "사용자 이름",
  user_email: "이메일",
  user_mobile: "전화번호",
  reg_dtm: "등록 날짜",
  // * If it is a link, it passes an object
  detail: {
    label: "상세",
    getLink: (item) => "/boffice/member/manage/user/" + item["user_seq"],
    linkLabel: "상세",
  },
};

function MemberList() {
  const [page, setPage] = useState(1);
  const [memberPerPage, setMemberPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const [memberShipType, setMemberShipType] = useState("PSN");

  const [paramArguments, setParamArguments] = useState("");

  const [memberList, setMemberList] = useState([]);

  const { data, isFetching } = useAuthenticatedQuery(
    ["getGeneralUsers/" + paramArguments, page, memberPerPage, paramArguments],
    `/user/search?per_page=${memberPerPage}&curr_page=${page}&` + paramArguments,
  );

  useEffect(() => {
    const updateData = async (data) => {
      const response = await data.json();

      if (response.messageCode === 200) {
        setTotalCount(response.data.total_cnt);
        setMemberList(response.data.data_list);
      }
    };

    !isFetching && data && !data.bodyUsed && updateData(data);
  }, [data, isFetching]);

  useEffect(() => {
    const params = new URLSearchParams(paramArguments);
    params.set("user_type", memberShipType);

    setParamArguments(params.toString());
  }, [memberShipType]);

  const handlePageClick = (toPage) => {
    if (Number.isInteger(toPage)) return setPage(toPage);

    switch (toPage) {
      case "first": {
        setPage(1);
        break;
      }
      case "next": {
        page < Math.floor(totalCount / memberPerPage) && setPage((prev) => prev + 1);
        break;
      }
      case "prev": {
        page > 1 && setPage((prev) => prev - 1);
        break;
      }
      case "last": {
        setPage(Math.ceil(totalCount / memberPerPage));
        break;
      }
    }
  };

  return (
    <main>
      <h2 className="adm_com_pg_tit2 bottom_line"> 회원관리</h2>
      <div className="adm_com_pg_content">
        <h2 className="sub_tit">- 일반 회원 관리</h2>
        <MemberFilter
          columns={columns}
          dataPerPage={memberPerPage}
          setDataPerPage={setMemberPerPage}
          defaultSearchColumn={"user_name"}
          setParamArguments={setParamArguments}
          additionalFields={
            <div className="adm_com_input_wrap flex_row">
              <span className="tit">회원구분</span>
              <select className="select" onChange={(e) => setMemberShipType(e.target.value)}>
                <option value={""}>전체</option>
                <option value={"PSN"}>일반 사용자</option>
                <option value={"COM"}>기업 사용자</option>
              </select>
            </div>
          }
        />

        <div className="sub_tit">
          <span className="txt flex-start">
            {totalCount} 건 {isFetching && <i className="gg-spinner-alt" />}
          </span>
        </div>
        <Table data={memberList} columns={columns} />
        <Paging handlePageClick={handlePageClick} page={page} totalPageCount={Math.ceil(totalCount / memberPerPage)} />
      </div>
    </main>
  );
}

export default MemberList;
