import React, { useContext, useState } from "react";
import Pagination from "../../components/Pagination";
import { Link, useNavigate } from "react-router-dom";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import { AuthContext } from "../../../../../context/Auth";
import useCommCode from "../../../../../hooks/useCommCode";

const getLocationFromComm = (comm_code, locationTypeData) =>
  locationTypeData ? locationTypeData.find((location) => location["comm_code"] == comm_code)["code_nm_kr"] : "";
export default function RecommendedTalent() {
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const [recruitsPerPage, setRecruitsPerPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);

  const [totalRecruitCount, setTotalRecruitCount] = useState(0);
  const [recruitList, setRecruitList] = useState([]);

  useCustomQuery(`/recruit/list?per_page=${recruitsPerPage}&curr_page=${currentPage}&comp_seq=${user.comp_seq}`, {
    queryKey: ["getRecruitList", recruitsPerPage, currentPage, user],
    select: (res) => res?.data,
    onSuccess: (data) => {
      // console.log(data);
      setTotalRecruitCount(data.total_cnt);
      setRecruitList(data.data_list);
    },
    refetchOnWindowFocus: false,
  });

  const { data: locationTypeData } = useCommCode(`LOCA_CD`);

  const handleNext = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setCurrentPage((prev) => prev - 1);
  };

  return (
    <div className="tab_content">
      <ul className="card_list">
        {recruitList.map((item, index) => (
          <li className="item" key={index}>
            <span className="status">모집중</span>
            <Link to={"/Company/RecruitDetail/" + item["recruit_seq"]} state={{ recruit: item }}>
              <div className="tit">{item["recruit_title"]}</div>
            </Link>
            <p className="region">
              {getLocationFromComm(item["recruit_location"], locationTypeData)}
              <span className="dot" />
              일산
            </p>
            <div className="info">
              <p className="info_s">
                <span className="name">등록일</span>
                <span className="bar"></span>
                <span className="date">{new Date(item["reg_dtm"]).toLocaleDateString()}</span>
              </p>
              {item["close_type"] !== "ANY" && item["close_date"] && (
                <p className="info_s">
                  <span className="name">마감일</span>
                  <span className="bar"></span>
                  <span className="date">{new Date(item["close_date"]).toLocaleDateString()}</span>
                </p>
              )}
            </div>
            <div className="com_btn_wrap">
              <button
                className="com_btn xs line"
                onClick={() => navigate("/Company/RecruitDetail/" + item["recruit_seq"])}>
                추천 인재 5명
              </button>
            </div>
          </li>
        ))}
      </ul>
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(totalRecruitCount / recruitsPerPage)}
        onNextClick={handleNext}
        onPrevClick={handlePrevious}
        perPage={recruitsPerPage}
        handlePerPageChange={(e) => setRecruitsPerPage(e.target.value)}
      />
    </div>
  );
}
