import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCustomQuery } from "../../../../../../hooks/useAPI";
import Table from "../../../../screens/components/boffice/Table";
import Paging from "../../../component/Paging";
import useCommCate from "../../../../../../hooks/useCommCate";

export default function ProductDetail() {
  const navigate = useNavigate();

  const { product_seq } = useParams();

  const [page, setPage] = useState(1);
  const mentoringUserPerPage = 6;
  const [totalCount, setTotalCount] = useState(0);

  const [product, setProduct] = useState();
  const [mentoringUser, setMentoringUser] = useState();

  const { isFetching } = useCustomQuery("/mentoring/" + product_seq, {
    queryKey: ["getMentoring/" + product_seq],
    select: (res) => res?.data,
    onSuccess: async (data) => {
      if (!data) navigate(-1);
      setProduct(data);
    },
    refetchOnWindowFocus: false,
  });

  const { isFetching: attendeesLoading } = useCustomQuery(
    `/mentoring/user/list?per_page=${mentoringUserPerPage}&curr_page=${page}&mento_seq=${product_seq}`,
    {
      queryKey: ["getMentoringUsers/" + product_seq],
      select: (res) => res?.data,
      onSuccess: async (data) => {
        setTotalCount(data.total_cnt);
        setMentoringUser(data.data_list);
      },
      enabled: !!product,
      refetchOnWindowFocus: false,
    },
  );

  const { data: categories } = useCommCate("MENTO_CATE");

  const handlePageClick = (toPage) => {
    if (Number.isInteger(toPage)) return setPage(toPage);

    switch (toPage) {
      case "first": {
        setPage(1);
        break;
      }
      case "next": {
        page < Math.floor(totalCount / mentoringUserPerPage) && setPage((prev) => prev + 1);
        break;
      }
      case "prev": {
        page > 1 && setPage((prev) => prev - 1);
        break;
      }
      case "last": {
        setPage(Math.ceil(totalCount / mentoringUserPerPage));
        break;
      }
    }
  };

  if (isFetching || !product) return <i className="gg-spinner-big" />;

  return (
    <main>
      <h2 className="adm_com_pg_tit2 bottom_line"> 멘토 상품 관리</h2>
      <div className="adm_com_pg_content">
        <h2 className="sub_tit">- 상품 내역 &gt; 상세 </h2>
        <h2 className="sub_tit"> 멘토 회원 정보</h2>

        <table className="adm_com_board_list type_row">
          <colgroup>
            <col width="10%" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              {/* User ID */}
              <th>사용자 ID</th>
              <td>{product["user_id"]}</td>
            </tr>
            <tr>
              {/* User name */}
              <th>이름</th>
              <td>{product["user_name"]}</td>
            </tr>
            <tr>
              {/* Email */}
              <th>이메일</th>
              <td>{product["user_email"]}</td>
            </tr>
            <tr>
              {/* Phone Number */}
              <th>전화 번호</th>
              <td>{product["user_mobile"]}</td>
            </tr>
            <tr>
              {/* Application Date */}
              <th>승인일</th>
              <td>{new Date(product["application_dtm"]).toISOString().split("T")[0]}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* Product Information */}
      <div className="adm_com_pg_content">
        <h2 className="sub_tit"> 상품 정보</h2>
        {/* <div className="sub_tit">- 멘토 신청자 정보</div> */}
        <table className="adm_com_board_list type_row">
          <colgroup>
            <col width="10%" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              {/* Product Name */}
              <th>상품명</th>
              <td>{product["mento_title"]}</td>
            </tr>
            <tr>
              {/* Mentor Product */}
              <th>상품 금액</th>
              <td>{product["mento_amt"]}</td>
            </tr>
            <tr>
              {/* Mentor Category */}
              <th>카테고리</th>
              <td>
                {categories.find((cate) => cate["comm_code"] === product["mento_cate"]) &&
                  categories.find((cate) => cate["comm_code"] === product["mento_cate"])["code_nm_kr"]}
              </td>
            </tr>
            <tr>
              {/* Mentee Count */}
              <th>멘티 수</th>
              <td>
                {Number(product["mentee_cnt"]) !== 0
                  ? Number(product["mentee_cnt"])
                  : "이 제품에 대한 멘티는 없습니다."}
              </td>
            </tr>
            <tr>
              {/* Mentor Rating */}
              <th>후기 총 평점</th>
              <td>{Number(product["avg_rating"]) !== 0 ? `${Number(product["avg_rating"])}/5` : "등급 없음."}</td>
            </tr>
            <tr>
              {/* Registration Date */}
              <th>등록일자</th>
              <td>{new Date(product["reg_dtm"]).toISOString().split("T")[0]}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Mentor Products */}
      {attendeesLoading ? (
        <i className="gg-spinner-big" />
      ) : (
        <div className="adm_com_pg_content">
          <div className="sub_tit"> 멘티 리스트</div>
          <Table
            columns={{
              user_seq: "번호",
              user_id: "멘티 아이디",
              user_name: "멘티 이름",
              reg_dtm: "신청일자",
              mento_period: "멘토 기간",
              use_meet_cnt: "화상 미팅 수",
              rate_score: "후기 평점",
              //   q_and_a: {
              //     label: "화상 미팅 수",
              //     getLink: (item) => "/boffice/mentoring/manage/member/" + user_seq + "/product/" + item["mento_seq"],
              //     linkLabel: "상세",
              //   },
              //   chatting: {
              //     label: "질문과 답변",
              //     getLink: (item) => "/boffice/mentoring/manage/member/" + user_seq + "/product/" + item["mento_seq"],
              //     linkLabel: "상세",
              //   },
              //   recommendationLetter: {
              //     label: "추천서",
              //     getLink: (item) => "/boffice/mentoring/manage/member/" + user_seq + "/product/" + item["mento_seq"],
              //     linkLabel: "상세",
              //   },
            }}
            data={
              mentoringUser &&
              mentoringUser.map((mentee) => ({
                ...mentee,
                mento_period: `${
                  mentee["start_date"] !== null ? new Date(mentee["start_date"]).toISOString().split("T")[0] : ""
                } - ${mentee["end_date"] !== null ? new Date(mentee["end_date"]).toISOString().split("T")[0] : ""}`,
                rate_score: Number(mentee["rate_score"]) === 0 ? "N/A" : `${Number(mentee["rate_score"])}/5`,
              }))
            }
          />
          <Paging
            handlePageClick={handlePageClick}
            page={page}
            totalPageCount={Math.ceil(totalCount / mentoringUserPerPage)}
          />
        </div>
      )}
      <div className="adm_com_btn_wrap center bottom">
        <button className="adm_com_btn gray" onClick={() => navigate(-1)}>
          돌아가기
        </button>
      </div>
    </main>
  );
}
