import React, { Component } from "react";

import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { createGlobalStyle } from "styled-components";

const editorConfiguration = {
  toolbar: [
    "heading",
    "|",
    "fontFamily",
    "fontSize",
    "fontColor",
    "fontBackgroundColor",
    "bold",
    "italic",
    "highlight",
    "specialCharacters",
    "horizontalLine",
    "link",
    "bulletedList",
    "numberedList",
    "|",
    "outdent",
    "indent",
    "|",
    "alignment",
    // 'imageUpload',
    "imageInsert",
    "blockQuote",
    "insertTable",
    "mediaEmbed",
    "undo",
    "redo",
    "sourceEditing",
  ],
  heading: {
    options: [
      {
        model: "paragraph",
        view: "p",
        title: "본문",
        class: "ck-heading_paragraph",
      },
      {
        model: "heading1",
        view: "h1",
        title: "헤더1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "헤더2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: "h3",
        title: "헤더3",
        class: "ck-heading_heading3",
      },
    ],
  },
  fontSize: {
    options: [10, 11, 12, 13, 14, "default", 16, 17, 18, 20, 25, 30],
  },
  simpleUpload: {
    // The URL that the images are uploaded to.
    uploadUrl: process.env.REACT_APP_SERVER_BASE_URL_PROD + "/upload/editor",
    withCredentials: false,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  },
  alignment: {
    options: ["justify", "left", "center", "right"],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
};

const OverrideEditorStyle = createGlobalStyle`
    .ck-editor__editable {
        min-height: ${({ height }) => height}px;
    }
`;

class CustomEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <OverrideEditorStyle height={this.props.height} />
        <CKEditor
          editor={Editor}
          config={editorConfiguration}
          data={this.props.data || ""}
          onReady={
            (/* editor */) => {
              // You can store the "editor" and use when it is needed.
              // console.log('Editor is ready to use!', editor);
            }
          }
          onChange={(event, editor) => {
            const data = editor.getData();

            // console.log( { event, editor, data } );

            this.props.newContent(data);
          }}
          onBlur={
            (/* event, editor */) => {
              // console.log('Blur.', editor);
            }
          }
          onFocus={
            (/* event, editor */) => {
              // console.log('Focus.', editor);
            }
          }
        />
      </>
    );
  }
}

export default CustomEditor;
