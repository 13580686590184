import React, { useState } from "react";
import "../../assets/scss/page/mypage.scss";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import MatchRead from "../../page/mypage/ai-match/MatchRead";
import MatchUnread from "../../page/mypage/ai-match/MatchUnread";
import MatchInterview from "../../page/mypage/ai-match/MatchInterview";
import MatchRecruit from "../../page/mypage/ai-match/MatchRecruit";
import MatchWishCompany from "../../page/mypage/ai-match/MatchWishCompany";
import { useSearchParams } from "react-router-dom";

function AiTab() {
  const [tabs, setTabs] = useState([
    { name: "matchread", status: "SS15", label: "열람", num: 0, active: true },
    { name: "matchunread", status: "SS10", label: "미열람", num: 0, active: false },
    { name: "matchinterview", status: "SS30", label: "면접제안", num: 0, active: false },
    { name: "matchrecruit", status: "all", label: "채용상태", num: 0, active: false },
    { name: "matchwishcompany", status: "favorite", label: "찜한공고", num: 0, active: false },
  ]);

  const [searchParams, setSearchParams] = useSearchParams({ page: 1, limit: 6 });
  const page = +searchParams.get("page");
  const limit = +searchParams.get("limit");

  const { data: { data_list: jobList, total_page } = { data_list: [], total_page: 0 }, refetch: refetchJobList } =
    useCustomQuery(`/recruit/favorite?curr_page=${page}&per_page=${limit}`, {
      queryKey: ["getRecruitList", page, limit],
      select: (res) => res?.data ?? { data_list: [], total_page: 0 },
      onSuccess: (data) => {
        if (data) {
          setTabs((tabs) => {
            return tabs.map((tab) => {
              if (tab.status === "favorite") {
                return { ...tab, num: data.total_cnt };
              }
              return tab;
            });
          });
        }
      },
    });

  useCustomQuery(`/application/list?user_type=PSN&status_count=all`, {
    queryKey: ["getRecruitList"],
    select: (res) => res?.data,
    onSuccess: (data) => {
      if (data) {
        setTabs((tabs) => {
          return tabs.map((tab) => {
            if (tab.status === "all") {
              return { ...tab, num: data.total_cnt };
            } else if (data[tab.status]) {
              return { ...tab, num: data[tab.status] };
            }
            return tab;
          });
        });
      }
    },
  });

  const handleTabClick = (tabName) => {
    const updatedTabs = tabs.map((tab) => {
      if (tab.name === tabName) {
        return { ...tab, active: true };
      } else {
        return { ...tab, active: false };
      }
    });
    setTabs(updatedTabs);
  };

  return (
    <>
      <section className="com_ai_tab">
        {tabs.map((tab, index) => (
          <div key={index} className={`tab ${tab.active && "active"}`} onClick={() => handleTabClick(tab.name)}>
            <span className="txt">{tab.label}</span>
            <span className="num">{tab.num}</span>
          </div>
        ))}
      </section>
      {tabs.map(
        (tab) =>
          tab.active && (
            <div key={tab.name}>
              {tab.name === "matchread" && <MatchRead />}
              {tab.name === "matchunread" && <MatchUnread />}
              {tab.name === "matchinterview" && <MatchInterview />}
              {tab.name === "matchrecruit" && <MatchRecruit />}
              {tab.name === "matchwishcompany" && (
                <MatchWishCompany
                  jobList={jobList}
                  total_page={total_page}
                  refetchJobList={refetchJobList}
                  setSearchParams={setSearchParams}
                  page={page}
                  limit={limit}
                />
              )}
            </div>
          ),
      )}
    </>
  );
}

export default AiTab;
