import React from "react";

import ArrowLeft from "../assets/img/arrow_left.png";
import ArrowRightActive from "../assets/img/arrow_right_active.png";

function Pagination({
  currentPage = 1,
  totalPages = 1,
  perPage = 6,
  pageSizeCounts = [6, 10, 15, 20, 30],
  onNextClick = () => {},
  onPrevClick = () => {},
  handlePerPageChange = () => {},
  setSearchParams = () => {},
}) {
  
  return (
    <div>
      <div className="com_paging">
        <button
          className="btn_prev"
          onClick={() => {
            if (currentPage > 1) {
              onPrevClick?.();
              setSearchParams?.((prev) => {
                prev.set("page", currentPage - 1);
                return prev;
              });
            }
          }}
          disabled={currentPage === 1}>
          <img src={ArrowLeft} alt="prev" />
        </button>
        <span className="btn_page active">{currentPage}</span>
        <span> / </span>
        <span className="btn_page">{totalPages}</span>
        <button
          className="btn_next"
          onClick={() => {
            if (currentPage < totalPages) {
              onNextClick?.();
              setSearchParams?.((prev) => {
                prev.set("page", currentPage + 1);
                return prev;
              });
            }
          }}
          disabled={currentPage === totalPages}>
          <img src={ArrowRightActive} alt="next" />
        </button>
      </div>
      {pageSizeCounts?.length > 0 && (
        <div>
          <span>Per Page: </span>
          <select
            value={perPage}
            onChange={(e) => {
              handlePerPageChange?.(e);
              setSearchParams?.((prev) => {
                prev.set("page", 1);
                prev.set("limit", e.target.value);
                return prev;
              });
            }}
            style={{
              width: "60px",
            }}>
            {pageSizeCounts?.map((perPageSize) => (
              <option key={perPageSize} value={perPageSize}>
                {perPageSize}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
}

export default Pagination;
