import React, { useState, useEffect, useContext } from "react";
import "../../assets/scss/page/mentoring.scss";
//component
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";

import PopAgree from "../../components/PopAgreement";
import Upload from "../../components/Upload";
import { useNavigate, useParams } from "react-router-dom";
import { Checkbox, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { useForm } from "react-hook-form";
import { useAuthenticatedQuery, useCustomQuery } from "../../../../../hooks/useAPI";
import { mutateBackend, mutateBackendFormData, queryBackend, updateBackend } from "../../../../../api";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../../context/Auth";

function MentoringProduct() {
  const [modalAgreeOpen, setModalAgreeOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [createMode, setCreateMode] = useState(true);
  const [localFiles, setLocalFiles] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);

  const navigate = useNavigate();
  const { mento_seq } = useParams();

  const handleChangeServices = (event) => {
    setSelectedServices(event.target.value);
  };

  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user.mento_status !== "MS20") {
      navigate("/mentoring");
    }
  }, []);

  const { data, isFetching } = useAuthenticatedQuery(["selectAllCategories"], `/category?cate_gr=MENTO_CATE`);

  const { data: serviceTypes } = useCustomQuery(`/comm/list?code_gr=MENTO_SVC`, {
    queryKey: ["selectSchoolTypeCate"],
    select: (res) => res?.data?.dataList,
  });

  useEffect(() => {
    const updateData = async (data) => {
      const response = await data.json();

      if (response.messageCode === 200) {
        setCategories(response.data.categories);
        setValue("mento_cate", response.data.categories[0].cate_seq);
      }
    };

    !isFetching && data && !data.bodyUsed && updateData(data);
  }, [data, isFetching]);

  useEffect(() => {
    if (mento_seq) {
      const fetchMentoringData = async () => {
        try {
          const response = await queryBackend(`/mentoring/${mento_seq}`);
          const { data } = await response.json();

          setValue("mento_title", data.mento_title);
          setValue("mento_job", data.mento_job);
          setValue("mento_cate", data.mento_cate);
          setValue("mento_desc", data.mento_desc);
          setValue("mento_carrer", data.mento_carrer);
          setValue("mento_ans_code", data.mento_ans_code);
          setValue("mento_meet_cnt", data.mento_meet_cnt);
        } catch (error) {
          console.error("Error fetching mentoring data:", error);
        }
      };

      fetchMentoringData();
      setCreateMode(false);
    }
  }, [mento_seq]);

  useEffect(() => {
    setValue("service_types", selectedServices);
  }, [selectedServices]);

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      mento_title: "",
      mento_job: "",
      mento_cate: "",
      mento_desc: "",
      mento_carrer: "",
      mento_ans_code: "",
      mento_meet_cnt: "",
      mento_amt: 0,
      mento_period: 0,
      period_unit: "day",
      service_types: [],
    },
  });

  const handleUpload = async (mento_seq) => {
    const formData = new FormData();
    localFiles.forEach((file) => {
      formData.append("file", file);
    });
    formData.append("upload_ty", "mentoring-board");

    const response = await mutateBackendFormData("/upload/image", formData)
      .then(async (data) => await data.json())
      .catch(() => ({ messageCode: 400 }));

    if (response.messageCode === 200) {
      console.log("handleUpload", response.data);
      response.data?.length > 0 &&
        (await handleMentoringUpload(
          mento_seq,
          response.data.map((obj) => obj?.up_file_seq),
        ));
      return;
    } else {
      console.log("file failed");
      return;
    }
  };

  const handleMentoringUpload = async (mento_seq, ids = []) => {
    const response = await mutateBackend("/mentoring/upload", {
      data: { mento_seq, ids, table_name: "ces_mentoring" },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (data) => await data.json())
      .catch((err) => {
        throw new Error(err);
      });

    if (response.messageString === "SUCCESS" && response.messageCode === 200) {
      console.log("handleCommunityUpload", response.data);
      return;
    } else {
      return;
    }
  };

  // const handleDeleteFile = async (id) => {
  //   const response = await deleteBackend(`/upload/${id}`)
  //     .then(async (data) => await data.json())
  //     .catch(() => ({ messageCode: 400 }));

  //   if (response.messageCode === 200) {
  //     console.log({ response });
  //   } else {
  //     console.log("file failed");
  //   }
  // };

  const onValidSubmit = async (data) => {
    setFormSubmitting(true);

    try {
      let responseData;
      if (mento_seq) {
        responseData = await updateBackend(`/mentoring/${mento_seq}`, {
          data: {
            ...data,
          },
          headers: {
            "Content-Type": "application/json",
          },
        });

        const response = await responseData.json();

        if (localFiles.length > 0) await handleUpload(+mento_seq);
        navigate(`/mentoring`);

        if (response.messageCode === 200) {
          toast.success("멘토링 상품을 등록하였습니다.");
          navigate("/mentoring");
        }
      } else {
        responseData = await mutateBackend("/mentoring/create", {
          data: {
            ...data,
          },
          headers: {
            "Content-Type": "application/json",
          },
        });
        const response = await responseData.json();

        if (localFiles.length > 0) await handleUpload(response.data?.mentoringId);

        if (response.messageCode === 200) {
          toast.success("멘토링 상품을 등록하였습니다.");
          navigate("/mentoring");
        }
      }
    } catch (error) {
      toast.error("멘토링 상품을 등록하는데 오류가 생겼습니다.");
    }

    setFormSubmitting(false);
  };

  return (
    <main className="com_pay p_write">
      <h2 className="pg_tit">멘토링 상품 등록</h2>
      <form className="box" onSubmit={handleSubmit(onValidSubmit)}>
        <ul className="com_input_sec">
          <li className="tit_field">멘토링 상품명</li>
          <li className="input_field">
            <input
              type="text"
              placeholder="Ex: 그동안 놓치고 있던 회로설계"
              className="input"
              {...register("mento_title", {
                required: true,
              })}
            />
          </li>
          {errors.mento_title && <span style={{ color: "red" }}>멘토링 상품명을 입력해주세요.</span>}

          <li className="tit_field">카테고리</li>
          <li className="input_field">
            <select className="select" defaultValue={categories[0]?.cate_seq} {...register("mento_cate")}>
              {!isFetching &&
                categories?.map((category) => {
                  return (
                    <option key={category.cate_seq} value={category.cate_seq}>
                      {category.cate_name}
                    </option>
                  );
                })}
            </select>
          </li>

          <li className="tit_field">멘토 금액</li>
          <li className="input_field">
            <input
              type="number"
              placeholder="멘토 금액을 입력해주세요."
              className="input"
              {...register("mento_amt", {
                required: true,
              })}
            />
          </li>
          {errors.mento_amt && <span style={{ color: "red" }}>멘토 금액을 입력해주세요.</span>}

          <li className="tit_field">멘토링 기간</li>
          <li className="input_field">
            <div
              style={{
                display: "flex",
              }}>
              <input
                type="number"
                placeholder="멘토 금액을 입력하십시오"
                className="input"
                {...register("mento_period", {
                  required: true,
                })}
              />
              <select
                className="select"
                defaultValue={"4시간 이내에 답변"}
                {...register("period_unit", {
                  required: true,
                })}>
                <option value="day">일</option>
                <option value="month">개월</option>
              </select>
            </div>
          </li>
          {errors.mento_period && <span style={{ color: "red" }}>멘토링 기간을 선택해주세요.</span>}

          <li className="tit_field">서비스 타입</li>
          <li className="input_field">
            <div>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel>서비스 타입</InputLabel>
                <Select
                  multiple
                  value={selectedServices}
                  onChange={handleChangeServices}
                  input={<OutlinedInput label="Service Type" />}
                  renderValue={(selected) =>
                    selected
                      .map((value) => serviceTypes.find((service) => service.comm_code === value).code_nm_kr)
                      .join(", ")
                  }>
                  {serviceTypes?.map((service) => (
                    <MenuItem key={service.comm_code} value={service.comm_code}>
                      <Checkbox checked={selectedServices.indexOf(service.comm_code) > -1} /> {/* Added Checkbox */}
                      {service.code_nm_kr}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </li>

          {errors.mento_period && <span style={{ color: "red" }}>서비스 타입을 선택해주세요.</span>}

          <li className="tit_field">커리어 히스토리</li>
          <li className="input_field">
            <input
              type="text"
              placeholder="Ex : 경기 1000명 규모 대기업"
              className="input"
              {...register("mento_carrer", {
                required: true,
              })}
            />
          </li>
          {errors.mento_carrer && <span style={{ color: "red" }}>커리어 히스토리를 입력해주세요.</span>}

          <li className="tit_field">직무 및 경력</li>
          <li className="input_field">
            <div className="input_wrap">
              <textarea
                className="textarea"
                placeholder="전자 엔지니어 경력 12년 전기 엔지니어 경력 8년 자동차 설비 엔지니어 경력 16년"
                {...register("mento_job", {
                  required: true,
                })}
              />
            </div>
          </li>
          {errors.mento_job && <span style={{ color: "red" }}>직무 및 경력을 입력해주세요.</span>}

          <li className="tit_field flex">
            화상미팅은 월 몇 회 가능하신가요?
            <span className="sub">횟수는 30회 이하만 입력 가능합니다.</span>
          </li>
          <li className="input_field">
            <select className="select" {...register("mento_meet_cnt")} defaultValue="1">
              {[...Array(10).keys()].map((index) => (
                <option key={index} value={index + 1}>{`${index + 1} 회`}</option>
              ))}
            </select>
          </li>

          <li className="tit_field">질문과 답변 게시판 개설 시 몇 시간 이내에 답변할 수 있으신가요?</li>
          <li className="input_field">
            <select
              className="select"
              defaultValue={"4시간 이내에 답변"}
              {...register("mento_ans_code", {
                required: true,
              })}>
              {[...Array(10).keys()].map((index) => (
                <option key={index} value={`${index + 1}시간 이내에 답변`}>{`${index + 1} 시간 이내에 답변`}</option>
              ))}
            </select>
          </li>

          {errors.mento_ans_code && <span style={{ color: "red" }}>답변 가능한 시간을 선택해주세요.</span>}

          <li className="tit_field">멘토링 서비스 설명</li>
          <li className="input_field">
            <div className="input_wrap">
              <textarea
                className="textarea"
                placeholder="전자 · 전기 회로 설계 분야 13년차 직장인입니다. 질문할 내용과 막히는 부분들을 위주로 리스트를 가져오시면 상세히 답변드려요"
                {...register("mento_desc", {
                  required: true,
                })}
              />
            </div>
          </li>
          {errors.mento_desc && <span style={{ color: "red" }}>멘토링 서비스 설명을 입력해주세요.</span>}
        </ul>
        <div className="com_attach_section">
          {/* {mentoring?.files?.length > 0 &&
            mentoring?.files?.map((up_file_seq) => (
              <p className="img_box" key={up_file_seq}>
                <img src={getImageLink(up_file_seq)} className="img_upload" alt={up_file_seq} />
                <button type="button" className="btn_del" onClick={() => handleDeleteFile(up_file_seq)} />
              </p>
            ))} */}
          {localFiles?.length > 0 &&
            localFiles?.map((obj, i) => {
              const url = URL.createObjectURL(obj);
              return (
                <p className="img_box" key={i}>
                  <img src={url} className="img_upload" alt={obj.up_file_seq} />
                  <button
                    type="button"
                    className="btn_del"
                    onClick={() => setLocalFiles(localFiles?.filter((_, index) => index !== i))}
                  />
                </p>
              );
            })}
          <Upload multiple setFiles={(newFiles) => setLocalFiles([...localFiles, ...newFiles])} files={localFiles} />
        </div>
        <span style={{ color: "red" }}>380px * 280px 사이즈로 이미지를 올려주세요.</span>
        {createMode && (
          <section className="com_agree_section">
            <ul className="list">
              <li className="item">
                <div>
                  <input
                    type="checkbox"
                    className="com_chk"
                    id="agree01"
                    {...(createMode && register("agree01", { required: true }))}
                  />
                  <label htmlFor="agree01">
                    개인정보 제3자 제공<span className="txt_required"> (필수)</span>
                  </label>
                </div>
                <button className="com_btn txt" onClick={() => setModalAgreeOpen(true)}>
                  자세히
                </button>
              </li>
              {errors.agree01 && <span className="txt_required">필수 선택 사항입니다.</span>}

              <li className="item">
                <div>
                  <input
                    type="checkbox"
                    className="com_chk"
                    id="agree02"
                    {...(createMode && register("agree02", { required: true }))}
                  />
                  <label htmlFor="agree02">
                    판매자 이용 약관<span className="txt_required"> (필수)</span>
                  </label>
                </div>
                <button className="com_btn txt" onClick={() => setModalAgreeOpen(true)}>
                  자세히
                </button>
              </li>
              {errors.agree02 && <span className="txt_required">필수 선택 사항입니다.</span>}

              <li className="item">
                <div>
                  <input
                    type="checkbox"
                    className="com_chk"
                    id="agree03"
                    {...(createMode && register("agree03", { required: true }))}
                  />
                  <label htmlFor="agree03">
                    중도 포기 시 수익 미지급 동의<span className="txt_required"> (필수)</span>
                  </label>
                </div>
                <button className="com_btn txt" onClick={() => setModalAgreeOpen(true)}>
                  자세히
                </button>
              </li>
              {errors.agree03 && <span className="txt_required">필수 선택 사항입니다.</span>}
            </ul>
          </section>
        )}

        <section className="com_btn_wrap center">
          <button type="submit" className="com_btn blue m">
            상품 {createMode ? " 등록" : " 수정"} {formSubmitting && <i className="gg-spinner-alt" />}{" "}
          </button>
        </section>
      </form>
      {/* top button */}
      <ScrollToTopButton />

      {/*약관내용 팝업*/}
      <PopAgree open={modalAgreeOpen} close={() => setModalAgreeOpen(false)} title="이용약관" type="terms" />
    </main>
  );
}

export default MentoringProduct;
