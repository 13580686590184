import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { mutateBackend, queryBackend } from "../../../../../api";
//import { useCustomQuery } from "../../../../../hooks/useAPI";
import { getImageLink } from "../../../../../utils/misc";
import Modal from "../../components/modal";

function PopApply({ open, close, jobDetails, resumes = [], attachments = [] }) {
  const defaultValues = { resume_seq: 0, link_seq: null };
  const { register, handleSubmit, reset } = useForm({
    defaultValues,
    mode: "onChange",
  });

  const [selectedResume, setSelectedResume] = useState(0);  //선택한 이력서
  const [modalAlert, setModalAlert] = useState( { visible: false, message: "" } ); //알럿 모달

  const [qualCondition, setQualCondition] = useState( false ); //신청 자격

  //EV::제출하기
  const onSubmit = async (data) => {
        
    if(selectedResume > 0){

      //지원 자격 체크
      if(qualCondition){
        const response = await mutateBackend("/application", {
          data: {
            ...data,
            resume_seq: selectedResume,
            recruit_seq: jobDetails?.recruit_seq,
            appli_status: "SS00", //2023-11-13 모든 입사지원은 관리자 거쳐서... - KSR
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then(async (data) => await data.json())
          .catch((err) => {
            throw new Error(err);
          });
        if (response.messageString === "SUCCESS" && response.messageCode === 200) {
          setModalAlert({ visible: true, message: "입사지원이 완료되었습니다." });
          close();
        } else {
          console.log(response);
          toast.error(
            response.messageCode === 409 ? "이미 지원한 공고입니다." : "제출과정에서 오류가 발생하였습니다.",
          );
        }
      } else {
        setModalAlert({ visible: true, message: "입사지원을 위한 이력서 완성도가 부족합니다." });
        return;
      }

    } else {
      toast.error('제출할 이력서를 선택하세요');
      return false;
    }

  };

  //지원자격(점수) 가져오기
  const getQual = async () => {
    const qualData = await queryBackend("/user/qualification?qual_type=recruit&resume_seq=" + selectedResume).then(
      async (data) => (await data.json()).data,
    );
    const qualCondition = qualData.qualCondition === "Y" ? true : false;
    setQualCondition(qualCondition);
  }
  
  //이력서(선택) 변경
  useEffect(() => {
    if(selectedResume > 0) getQual();
  }, [selectedResume]);

  //EV::이력서 선택
  const handleChangeRadio = (e) => {
    const { value } = e.target;
    setSelectedResume(Number(value));
  };

  return (
    <>
    <Modal
      open={open}
      close={() => {
        reset(defaultValues);
        close();
      }}
      type="pop_apply">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className="pop_tit">지원하기</h2>
        <div className="content_container scroll x">
          <div className="info_bg com_job_item">
            <div className="info_wrap">
              {jobDetails?.comp_image_seqs && (
                <img className="img_logo" src={getImageLink(jobDetails?.comp_image_seqs?.split(",")?.[0])} alt="logo" />
              )}
              <div className="info_tit">
                <p className="info_s">
                  {jobDetails?.comp_name} {jobDetails?.emp_type_name &&<span className="icon">{jobDetails?.emp_type_name}</span>}
                </p>
                <strong>{jobDetails?.recruit_title}</strong>
              </div>
            </div>
          </div>
          <div className="info_wrap">
            <div className="box">
              <p className="input_tit first">이력서 선택</p>
              <ul className="list">
                {resumes?.length > 0 &&
                  resumes?.map((item, index) => (
                    <li className="item" key={index}>
                      <input
                        type="radio"
                        className="com_chk"
                        id={`resume_seq-${index}`}
                        value={item?.resume_seq}
                        checked={selectedResume === item?.resume_seq}
                        onChange={handleChangeRadio}
                      />
                      <label htmlFor={`resume_seq-${index}`}>
                        <span className="sel_tit">
                          {item?.resume_name} | {item?.resume_title}
                        </span>
                        <span className="percent">{item?.resume_progr}%</span>
                      </label>
                    </li>
                  ))}
              </ul>
              <Link to="/mypage/MyResume" className="btn_upload com_btn line m">
                + 이력서 관리
              </Link>
            </div>
            <div className="box">
              <p className="input_tit">파일첨부</p>
              <ul className="list">
                {attachments?.length > 0 &&
                  attachments?.map((item, index) => (
                    <li className="item" key={index}>
                      <input
                        type="radio"
                        className="com_chk"
                        id={`link_seq-${index}`}
                        value={item?.link_seq}
                        {...register("link_seq")}
                      />
                      <label htmlFor={`link_seq-${index}`}>
                        <span className="sel_tit">{item?.link_title}</span>
                      </label>
                    </li>
                  ))}
              </ul>
              <Link to="/mypage/Attachment" className="btn_upload com_btn line m">
                + 파일 업로드
              </Link>
            </div>
          </div>
        </div>
        <div className="btn_container">
          <button type="submit" className="com_btn blue m">
            제출하기
          </button>
        </div>
      </form>
    </Modal>

    {/* 알럿 */}
    <Modal open={modalAlert.visible} close={() => setModalAlert((prev) => ({ ...prev, visible: false }))}>
      <div className="content_container scroll">
        <p>{modalAlert.message}</p>
      </div>
      <div className="btn_container">
        <button
          className="adm_com_btn orange xs"
          onClick={() => setModalAlert((prev) => ({ ...prev, visible: false }))}>
          확인
        </button>
      </div>
    </Modal>

    </>
  );
}

export default PopApply;
