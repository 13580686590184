/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import "../../assets/scss/page/etc.scss";
function Privacy() {
  return (
    <>
      <main className='com_term'>
        <div className='com_center_wrap'>
          <h2 className='pg_tit'>개인정보 처리방침</h2>
          <div className='box'>
            <dl className='sec'>
              <dt className='tit'>엔지니어스 개인정보 처리방침</dt>
              <dd className='txt'>
                주식회사 씨이에스(이하 “회사” 또는 “사이트”)는 정보주체의 자유와 권리 보호를 위해 개인정보보호법 및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다.
                <br />이에 개인정보보호법 제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립•공개 합니다.
              </dd>
              <dt className='tit'>1. 개인정보의 수집 및 이용 목적</dt>
              <dd className='txt'>
                회사는 다음 목적을 위하여 개인정보를 수집하고 있으며 다음 목적 이외의 용도로는 수집한 개인정보를 이용하지 않으며, 이용 목적이 변경되는 경우에는 개인정보보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
                <br />① 회원관리
                회원제 서비스 이용에 따른 본인확인, 본인의 의사확인, 고객문의에 대한 응답, 새로운 정보의 소개 및 고지사항 전달
                <br />② 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
                본인인증, 채용정보 매칭, 면접 연습 및 콘텐츠 제공을 위한 개인식별, 회원간의 상호 연락, 구매 및 요금 결제, 물품 및 증빙발송, 부정 이용방지와 비인가 사용방지
                <br />③ 서비스 개발 및 마케팅 • 광고 활용
                맞춤 서비스 제공, 서비스 안내 및 이용권유, 서비스 개선 및 신규 서비스 개발을 위한 통계 및 접속 빈도 파악, 통계학적 특성에 따른 광고, 이벤트 정보 및 참여기회 제공
                ④ 고용 및 취업동향 파악을 위한 통계학적 분석, 서비스 고도화를 위한 데이터 분석
              </dd>
              <dt className='tit'>2. 수집하는 개인정보 항목 및 수집방법</dt>
              <dd className='txt'>
                가. 수집하는 개인정보의 항목
                <br />1) 회원가입 시 수집하는 항목
                <br />① 소셜회원가입
                <br />• 카카오
                <br />  - 필수항목 : 카카오계정(이메일), 사용자 이름
                <br />• 네이버
                <br />  - 필수항목 : 네이버계정(이메일), 사용자 이름
                <br />• 페이스북
                <br />  - 필수항목 : 이메일 주소, 사용자 이름
                <br />• 구글
                <br />  - 필수항목 : 이메일 주소, 사용자 이름
                <br />• 애플
                <br />  - 필수항목 : 이메일 주소, 사용자 이름
                <br />② 기업회원
                <br />• 필수항목 : 사업자등록번호, 중복가입확인정보(DI), 회사명, 대표자명, 회사주소, 사업자등록증첨부파일, 담당자 아이디/비밀번호, 담당자 이름, 담당자 이메일, 부서, 담당자 본인인증 정보, 담당자 휴대폰 번호
                <br />• 선택항목 : 회사소개, 홈페이지 URL, 이용목적, 채용계획
                <br />③ 프로필 전문분야 설정 시 수집하는 항목
                <br />• 필수항목 : 직군, 직무, 경력
                <br />• 선택항목 : 연봉, 스킬
                <br />④ 이력서 등록 시 수집하는 항목
                <br />• 필수항목 : 이름, 연락처(전화번호/휴대폰/이메일)
                <br />• 선택항목 : 자기소개, 경력사항, 업무성과, 학력사항, 수상 및 기타, 홈페이지, 주소
                <br />⑤ 모바일 서비스 이용 시 수집되는 항목
                <br />• 모바일 서비스의 특성상 단말기 모델 정보가 수집될 수 있으나, 이는 개인을 식별할 수 없는 형태입니다.
                <br />⑥ 포인트 환전 서비스 이용 시 수집하는 항목
                <br />• 필수항목 : 본인 계좌정보(은행, 계좌번호), 주민등록번호(근거 : 소득세법)
                <br />⑦ 서비스 이용과정이나 사업처리 과정에서 자동 수집되는 항목
                <br />• IP Address, 쿠키, 방문일시, 서비스 이용 기록, 불량 이용 기록, 광고 ID, 접속 환경
              </dd>
              <dd className="txt">
                나. 개인정보 수집방법
                <br />홈페이지, 서비스 이용, 요금정산, 이벤트 응모, 고객센터 문의
              </dd>
              <dt className='tit'>3. 개인정보의 제공 및 처리위탁</dt>
              <dd className='txt'>
                “회사”는 개인정보를 1.개인정보의 수집 및 이용목적에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이 동 범위를 초과하여 이용하지 않습니다.
                <br />가. 처리위탁
                <br />“회사” 서비스 향상을 위해 아래와 같이 개인정보를 위탁하고 있으며, 관계 법령에 따라 위탁 계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다. 변동사항 발생시 공지사항 또는 개인정보처리방침을 통해 고지하도록 하겠습니다.
                <table className="table">
                  <thead>
                    <tr>
                      <th>수탁업체</th>
                      <th>위탁업무내용</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </dd>
              <dt className="tit">나. 다음의 경우에는 합당한 절차를 통하여 개인정보를 제공 또는 이용할 수 있습니다.</dt>
              <dd className='txt'>1) 채용공고에 지원하는 경우
                <br />이용자가 기업회원의 채용공고에 지원하는 경우, 이용자의 연락처, 이력서 등 개인정보가 채용 절차 진행을 위해 제공됩니다.
                <br />2) 기업 회원에게 제공되는 경우
                <br />프로필 조회를 허용한 이용자에 한하여 기업회원의 면접제안 조회가 가능하며, 이용자가 제안 수락을 한 경우에 한하여 개인정보 열람이 가능합니다.
                <table className="table">
                  <thead>
                    <tr>
                      <th>개인정보를 제공받는 자</th>
                      <th>개인정보를 제공받는 자의 개인정보 이용 목적</th>
                      <th>제공하는 개인정보의 항목</th>
                      <th>개인정보를 제공받는 자의 개인정보 보유 및 이용 기간</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>기업회원
                      [전체보기] 버튼
                      </td>
                      <td>면접제안 이용자 확인</td>
                      <td>프로필, 경력</td>
                      <td>제휴 계약 종료 시</td>
                    </tr>
                  </tbody>
                </table>
              </dd>
              <dd className="txt">
                3) 다만, 아래의 경우에는 예외로 합니다.
                <br />관계법령에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
              </dd>
              <dt className="tit">4. 개인정보의 보유 및 이용기간</dt>
              <dd className="txt">
                “사이트”는 회원가입일로부터 서비스를 제공하는 기간 동안에 한하여 이용자의 개인정보를 보유 및 이용하게 됩니다. 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 수집 및 이용목적이 달성되거나 이용기간이 종료한 경우 개인정보를 지체 없이 파기합니다.
                <br />단, 다음의 경우에 대해서는 각각 명시한 이유와 기간 동안 보존합니다.
                <br />1) 상법 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 법령에서 규정한 보존기간 동안 거래내역과 최소한의 기본정보를 보유합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용합니다.
                <br />① 계약 또는 청약철회 등에 관한 기록 : 5년
                <br />② 대금결제 및 재화 등의 공급에 관한 기록 : 5년
                <br />③ 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
                <br />④ 부정이용 등에 관한 기록 : 5년
                <br />⑤ 웹사이트 방문기록(로그인 기록, 접속기록) : 1년
                <br />2) 회원 탈퇴 요청 시 회사는 탈퇴처리와 동시에 지체 없이 개인정보를 파기하는 것을 원칙으로 합니다. 단, 회사를 통한 지원 이력이 있는 회원의 탈퇴 시, 회사는 다음과 같은 보존 이유로 탈퇴 후 5년 동안 지원내역 및 지원 내역과 관련된 개인정보를 보관합니다.
                <br />① 회사를 통해 취업이 완료되었음에도 기업과의 담합을 통해 취업 사실을 공유하지 않고 기업의 부정이용에 동참하는 것 방지
                <br />② 회사의 서비스 제공에 관한 기업과의 계약 이행을 완료하기 위해 회원의 지원정보를 보관할 필요가 있음
                <br />3) 보유기간을 미리 공지하고 그 보유기간이 경과하지 아니한 경우와 개별적으로 동의를 받은 경우에는 약정한 기간 동안 보유합니다.
              </dd>
              <dt className="tit">5. 개인정보 파기절차 및 파기방법</dt>
              <dd className="txt">
                이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. “회사”의 개인정보 파기절차 및 방법은 다음과 같습니다.
                <br />1) 파기절차
                <br />이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련법령에 의해 정보보호 사유에 따라 일정 기간 저장된 후 파기됩니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.
                <br />2) 파기방법
                <br />종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통해 파기합니다. 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
              </dd>
              <dt className="tit">6. 개인정보 자동 수집 장치의 설치, 운영 및 거부에 관한 사항</dt>
              <dd className="txt">
                1) 쿠키란
                <br />웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 작은 텍스트 파일로 이용자의 하드디스크에 저장됩니다.
                <br />2) 쿠키의 사용 목적
                <br />“사이트”가 쿠키를 통해 수집하는 정보는 ‘2. 수집하는 개인정보 항목 및 수집방법’과 같으며, ‘1.개인정보의 수집 및 이용목적’외의 용도로는 이용되지 않습니다.
                <br />3) 쿠키 설치, 운영 및 거부
                <br />이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)은 다음과 같습니다.
                <br />예) 웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보
                <br />단, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부 서비스 이용에 어려움이 있을 수 있습니다.
              </dd>
              <dt className="tit">7. 개인정보의 기술적, 관리적 보호대책</dt>
              <dd className="txt">
                1) 개인정보 암호화
                <br />이용자의 개인정보는 암호화하거나 파일 잠금 기능을 통해 별도의 보안기능을 통해 보고하고 있습니다.
                <br />2) 해킹 등에 대비한 대책
                <br />모든 데이터가 고도의 보안이 유지되는 데이터 센터에 보관되고 있습니다. 개인정보 데이터의 접근을 사용 권한을 나눠 제한하고 있으며, 개인PC나 외부 침입이 우려되는 오프라인 공간에 저장하지 않습니다.
                <br />3) 개인정보 처리 직원의 교육
                <br />개인정보관련 처리 직원은 최소한의 인원으로 구성되며, 새로운 보안기술 습득 및 개인정보보호 의무에 관해 정기적인 교육을 실시하며 내부 감사 절차를 통해 보안이 유지되도록 시행하고 있습니다.
                <br />4) 개인 아이디와 정보 관리
                <br />“회사”는 이용자의 개인정보를 보호하기 위하여 최선의 노력을 다하고 있습니다. 단, 이용자의 개인적인 부주의로 이메일(또는 구글 등 외부 서비스와의 연동을 통해 이용자가 설정한 계정 정보) 등 개인정보가 유출되어 발생한 문제와 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다.
              </dd>
              <dt className="tit">8. 링크</dt>
              <dd className="txt">“사이트”는 다양한 배너와 링크를 포함할 수 있습니다. 많은 경우 타 사이트의 페이지와 연결되어 있으며 이는 광고주와의 계약관계에 의하거나 제공받은 컨텐츠의 출처를 밝히기 위한 조치입니다. “사이트”가 포함하고 있는 링크를 클릭하여 타 사이트의 페이지로 이동할 경우 해당 사이트의 개인정보처리방침은 “사이트”와 무관하므로 새로 방문한 사이트의 정책을 검토해 보시기 바랍니다.</dd>
              <dt className="tit">9. 아동의 개인정보 보호</dt>
              <dd className="txt">“회사”는 인터넷 리크루팅의 특성으로 볼 때 만14세 미만의 아동은 이력서를 등록하여 구직활동을 할 수 없다고 판단하여 만14세 미만 아동의 회원가입을 받지 않습니다.</dd>
              <dt className="tit">10. 비회원의 개인정보 보호</dt>
              <dd className="txt">“사이트”에서는 회원 가입을 하지 않아도 공개를 희망하지 않는 개인정보와 이력서 주요 정보를 제외한 대부분의 컨텐츠를 열람할 수 있습니다.</dd>
              <dt className="tit">11. 이용자의 권리와 그 행사방법</dt>
              <dd className="txt">이용자 및 법정대리인은 언제든지 등록되어 있는 자신 혹은 당해 미성년자의 정보를 열람, 공개 및 비공개 처리, 수정, 삭제할 수 있습니다. 이용자 및 법정대리인은 개인정보 조회/수정/가입해지(동의철회)를 ‘회원정보관리’를 통해 처리가 가능하며, 개인정보보호 책임자에게 이메일로 연락하시는 경우에는 본인 확인 절차를 거친 후 조치하겠습니다. 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다. “회사”는 이용자 요청에 의해 해지 또는 삭제된 개인정보는 ‘4. 개인정보의 보유 및 이용기간’에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</dd>
              <dt className="tit">12. 개인정보 처리부서 및 민원서비스</dt>
              <dd className="txt">“회사”는 이용자의 개인정보를 보호하고 개인정보와 관련한 고충처리를 위하여 아래와 같이 개인정보 처리 담당자 및 연락처를 지정하고 있습니다.
                <table className="table">
                  <tbody>
                    <tr>
                      <th>개인정보 처리 책임자</th>
                    </tr>
                    <tr>
                      <td>
                        부서 : HR플랫폼사업부 <br />
                        이름 : 서경식 <br />
                        직급 : 이사 <br />
                        연락처 : (ksseo@cescom.co.kr / 010-4249-6514)
                      </td>
                    </tr>
                    <tr>
                      <th>개인정보 처리 담당자</th>
                    </tr>
                    <tr>
                      <td>
                        부서 : HR플랫폼사업부 <br />
                        이름 : 유상일 <br />
                        직급 : 차장 <br />
                        연락처 : (siyoo@cescom.co.kr / 010-6737-0568)
                      </td>
                    </tr>
                  </tbody>
                </table>
              </dd>
              <dd className="txt">기타 개인정보에 관한 상담이 필요한 경우에는 아래 기관에 문의하실 수 있습니다.</dd>
              <dd className="txt">
                <table className="table">
                  <tbody>
                    <tr>
                      <td>개인정보침해신고센터</td>
                      <td><Link to="http://privacy.kisa.or.kr/">http://privacy.kisa.or.kr/</Link> 국번없이 118</td>
                    </tr>
                    <tr>
                      <td>대검찰청 사이버수사과</td>
                      <td><Link to="http://privacy.kisa.or.kr/">http://privacy.kisa.or.kr/</Link> 국번없이 1201</td>
                    </tr>
                    <tr>
                      <td>경찰청 사이버안전국</td>
                      <td><Link to="http://www.police.go.kr/">http://www.police.go.kr/</Link> 국번없이 182</td>
                    </tr>
                  </tbody>
                </table>
              </dd>
              <dt className="tit">부칙</dt>
              <dd className="txt">본 개인정보처리방침은 2023년 11월 01일부터 시행하며, 본 방침이 제정되기 전에 가입한 회원에게도 동일하게 적용됩니다.</dd>
            </dl>
          </div>
        </div>
      </main>
    </>
  );
}

export default Privacy;
