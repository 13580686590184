import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import "../../assets/scss/page/member.scss";
import Pagination from "../../components/Pagination";
import Refund from "../../components/myPage/_PopRefund";
import { mutateBackend } from "../../../../../api";

function MySeminar() {
  const [searchParams, setSearchParams] = useSearchParams({ page: 1, limit: 6 });
  const page = +searchParams.get("page");
  const limit = +searchParams.get("limit");
  const [modalRefund, setModalRefund] = useState(false);
  const [refundSeminar, setRefundSeminar] = useState(null);

  const {
    isFetching,
    data: { seminarApplications = [], total_count = 0 } = { seminarApplications: [], total_count: 0 },
  } = useCustomQuery(`/seminar/user-applications?page=${page}&limit=${limit}`, {
    queryKey: ["getUserSeminarApplications", page, limit],
    select: (res) => res?.data ?? { seminarApplications: [], total_count: 0 },
  });

  const handleRefund = async (refund_reason = "") => {
    console.log(refund_reason, refundSeminar);
    if (refund_reason && refundSeminar) {
      const response = await mutateBackend("/seminar/refund-requests", {
        data: {
          semin_seq: refundSeminar?.semin_seq,
          pay_seq: refundSeminar?.pay_seq,
          refund_reason,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (data) => await data.json())
        .catch((err) => {
          throw new Error(err);
        });
      if (response.messageString === "SUCCESS" && response.messageCode === 200) {
        console.log(response.data);
        setModalRefund(false);
      } else {
        console.log(response);
      }
    }
  };

  return (
    <>
      <div className="container">
        <h2 className="com_pg_tit">세미나 신청 내역</h2>
        <section className="com_board_list__top">
          <article className="btn_wrap">
            <button className="com_btn blue oval xs">삭제</button>
          </article>
        </section>
        <table className="com_board_list">
          <colgroup>
            <col width="6%" />
            <col />
            <col width="13%" />
            <col width="13%" />
            <col width="13%" />
            <col width="10%" />
            <col width="13%" />
          </colgroup>
          <thead>
            <tr>
              <th></th>
              <th>신청한 세미나</th>
              <th>신청일자</th>
              <th>세미나 일정</th>
              <th>결제 금액</th>
              <th>인원 수</th>
              <th>환불</th>
            </tr>
          </thead>
          <tbody>
            {isFetching ? (
              <i className="gg-spinner-alt" />
            ) : seminarApplications?.length > 0 ? (
              seminarApplications?.map((obj) => (
                <tr key={obj?.semin_apl_seq}>
                  <td className="chk_field">
                    <input type="checkbox" className="com_chk" id={obj?.semin_apl_seq} />
                    <label htmlFor={obj?.semin_apl_seq}></label>
                  </td>
                  <td className="tit_field">{obj?.semin_title}</td>
                  <td className="info_field">
                    <span className="th_m">신청일자</span>
                    {new Date(obj.application_date).toLocaleDateString()}
                  </td>
                  <td className="info_field">
                    <span className="th_m">세미나 일정</span>
                    {new Date(obj.semin_date).toLocaleDateString()}
                  </td>
                  <td className="info_field">
                    <span className="th_m">결제 금액</span>
                    {obj?.pay_method === "POINTS" ? `${obj?.use_point}P` : `${+obj?.semin_price * +obj?.join_num}원`}
                  </td>
                  <td className="info_field last">
                    <span className="th_m">인원 수</span>
                    {obj?.join_num}명
                  </td>
                  <td className="btn_field">
                    <span className="block_pc">결제완료</span>
                    <button
                      className="com_btn oval line s"
                      onClick={() => {
                        setRefundSeminar(obj);
                        setModalRefund(true);
                      }}>
                      환불하기
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <h3>등록된 내역이 없습니다.</h3>
            )}
          </tbody>
        </table>

        <Pagination
          currentPage={page}
          totalPages={Math.ceil(total_count / limit)}
          perPage={limit}
          setSearchParams={setSearchParams}
        />
      </div>

      {/*환불하기 팝업*/}
      <Refund open={modalRefund} close={handleRefund} />
    </>
  );
}

export default MySeminar;
