import React from "react";
import { Route, Routes } from "react-router-dom";

import CommunityDetail from "../views/screens/page/community/Detail";
import CommunityList from "../views/screens/page/community/List";
import CommunityWrite from "../views/screens/page/community/Write";
import ProtectedRoute from "./ProtectedRoute";

function RouterCommunity() {
  return (
    <>
      <Routes>
        <Route path="/" element={<CommunityList />} />
        <Route
          path="/Write/:id"
          element={
            <ProtectedRoute>
              <CommunityWrite />
            </ProtectedRoute>
          }
        />
        <Route path="/Detail/:id" element={<CommunityDetail />} />
      </Routes>
    </>
  );
}

export default RouterCommunity;
