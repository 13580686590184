import React, { useEffect, useState } from "react";
import Modal from "../../component/Modal";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthenticatedQuery, useCustomQuery } from "../../../../../hooks/useAPI";
import { useForm } from "react-hook-form";
import { updateBackend } from "../../../../../api";
import { isEmailTaken, isMobilePhoneTaken, isNicknameTaken } from "../../../../../api/miscellaneous";
import Points from "./Points";

const MemberDetail = () => {
  const navigate = useNavigate();

  const { user_seq } = useParams();

  const [passwordModalActive, setPasswordModalActive] = useState(false);

  const { data, isFetching, isRefetching, refetch } = useAuthenticatedQuery(
    ["getMember/" + user_seq],
    "/user/info/" + user_seq,
  );

  const [user, setUser] = useState({});

  const [errors, setErrors] = useState({});

  const [isChecking, setIsChecking] = useState(false);

  const [isUpdating, setIsUpdating] = useState(false);

  const { data: points = {} } = useCustomQuery(`/points/current?user_seq=${user_seq}`, {
    queryKey: ["getPoints", user_seq],
    select: (res) => res?.data,
  });


  useEffect(() => {
    const updateUser = async () => {
      const response = await data?.json();

      if (response.data.data_list.length === 0 || isFetching) {
        return;
      } else {
        setUser(response.data.data_list[0]);
      }
    };

    data && !data.bodyUsed && updateUser();
  }, [data]);

  const {
    formState: { touchedFields },
    getValues,
    register,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => setErrors({}), []);

  const handleUpdate = async () => {
    setIsUpdating(true);

    const updates = Object.keys(touchedFields).reduce(
      (keys, currentKey) =>
        user[currentKey] !== getValues(currentKey)
          ? {
              ...keys,
              [currentKey]: getValues(currentKey),
            }
          : { ...keys },
      {},
    );

    user &&
      (await updateBackend("/admin/user/" + user["user_seq"], {
        data: { ...updates },
        headers: { "Content-Type": "application/json" },
      }));

    await refetch();
    setIsUpdating(false);
  };

  const handlePasswordUpdate = async () => {
    if (getValues("new_user_pwd") !== getValues("new_user_pwd_match")) {
      setErrors((prev) => ({ ...prev, mismatchedPassword: true }));
      return;
    }
    setIsUpdating(true);

    if (user) {
      setIsUpdating(true);

      const response = await updateBackend("/admin/user/update-password", {
        data: {
          user_seq: user["user_seq"],
          current_password: getValues("old_user_pwd"),
          new_password: getValues("new_user_pwd"),
        },
        headers: { "Content-Type": "application/json" },
      }).then(async (data) => await data.json());

      setIsUpdating(false);

      console.log(response);

      if (response.messageCode === 404) setErrors((prev) => ({ ...prev, incorrectCurrentPassword: true }));
      else if (response.messageCode === 200) setPasswordModalActive(false);

      await refetch();
    }
  };

  const handleNicknameCheck = async () => {
    setIsChecking(true);

    const nicknameTaken = await isNicknameTaken(getValues("user_nickname")).catch((err) => {
      console.log(err);
      return true;
    });
    if (nicknameTaken) {
      setErrors((prev) => ({ ...prev, user_nickname: true }));
    } else setErrors((prev) => ({ ...prev, user_nickname: false }));

    setIsChecking(false);
  };

  const handleMobileCheck = async () => {
    setIsChecking(true);
    const mobileNumberTaken = await isMobilePhoneTaken(getValues("user_mobile")).catch(() => {
      return true;
    });

    if (mobileNumberTaken) {
      setErrors((prev) => ({ ...prev, user_mobile: true }));
    } else setErrors((prev) => ({ ...prev, user_mobile: false }));

    setIsChecking(false);
  };

  const handleEmailCheck = async () => {
    setIsChecking(true);

    const emailTaken = await isEmailTaken(getValues("user_email")).catch(() => {
      return true;
    });
    if (emailTaken) {
      setErrors((prev) => ({ ...prev, user_email: true }));
    } else setErrors((prev) => ({ ...prev, user_email: false }));

    setIsChecking(false);
  };

  if (isFetching && !isRefetching) return <i className="gg-spinner-alt" />;

  if (user)
    return (
      <main>
        <h2 className="adm_com_pg_tit2 bottom_line"> 회원 관리</h2>
        <div className="adm_com_pg_content">
          <h2 className="sub_tit">- 일반 회원 관리 &gt; 상세 </h2>
          <table className="adm_com_board_list type_row">
            <colgroup>
              <col width="10%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                {/* User ID */}
                <th>아이디</th>
                <td>{user["user_id"]}</td>
              </tr>
              <tr>
                {/* Password */}
                <th>비밀번호</th>
                <td className="input_wrap">
                  <button onClick={() => setPasswordModalActive(true)} className="adm_com_btn gray">
                    비밀번호 변경
                  </button>
                </td>
              </tr>
              <tr>
                {/* Username */}
                <th>이름</th>
                <td className="input_wrap">
                  <input type="text" defaultValue={user["user_name"]} {...register("user_name")} className="input" />
                  {/* <button onClick={handleUsernameCheck} className="adm_com_btn gray ">
                    확인하다 {isChecking && <i className="gg-spinner-alt" />}
                    {!errors["user_name"] && <i className="gg-check" />}
                    {errors["user_name"] && <span style={{ color: "red" }}>이미 가져온 가치</span>}
                  </button> */}
                </td>
              </tr>
              <tr>
                {/* Nickname */}
                <th>닉네임</th>
                <td className="input_wrap">
                  <input
                    type="text"
                    defaultValue={user["user_nickname"]}
                    {...register("user_nickname", {})}
                    className="input"
                  />
                  <button onClick={handleNicknameCheck} className="adm_com_btn gray ">
                    중복확인 {isChecking && <i className="gg-spinner-alt" />}
                    {!errors["user_nickname"] && <i className="gg-check" />}
                    {errors["user_nickname"] && <span style={{ color: "red" }}>이미 가져온 가치</span>}
                  </button>
                </td>
              </tr>
              <tr>
                {/* Phone Number */}
                <th>휴대폰 번호</th>
                <td className="input_wrap">
                  <input
                    type="text"
                    defaultValue={user["user_mobile"]}
                    {...register("user_mobile")}
                    className="input"
                  />
                  <button onClick={handleMobileCheck} className="adm_com_btn gray ">
                    중복확인 {isChecking && <i className="gg-spinner-alt" />}
                    {!errors["user_mobile"] && <i className="gg-check" />}
                    {errors["user_mobile"] && <span style={{ color: "red" }}>이미 가져온 가치</span>}
                  </button>
                </td>
              </tr>
              <tr>
                {/* Email */}
                <th>이메일</th>
                <td className="input_wrap">
                  <input type="text" defaultValue={user["user_email"]} {...register("user_email")} className="input" />
                  <button onClick={handleEmailCheck} className="adm_com_btn gray ">
                    중복확인 {isChecking && <i className="gg-spinner-alt" />}
                    {!errors["user_email"] && <i className="gg-check" />}
                    {errors["user_email"] && <span style={{ color: "red" }}>이미 가져온 가치</span>}
                  </button>
                </td>
              </tr>
              <tr>
                {/* Recommender */}
                <th>추천인</th>
                <td>N/A</td>
              </tr>
              <tr>
                {/* Actively Seeking Employment */}
                <th> 적극 구직</th>
                <td>
                  <input type="checkbox" className="com_chk" id="No" />
                  <label htmlFor="No">No</label>
                  <br />
                  <input type="checkbox" className="com_chk" id="Yes" />
                  <label htmlFor="Yes">Yes</label>
                </td>
              </tr>
              <tr>
                {/* Registration Date */}
                <th>가입일자</th>
                <td>{new Date(user["reg_dtm"]).toLocaleString()}</td>
              </tr>
              <tr>
                {/* Membership Type */}
                <th>회원유형</th>
                <td>
                  {user["user_type"] === "PSN" ? "일반회원" : "기업회원"}
                  {/* <select className="select" defaultValue={user["user_type"]} {...register("user_type")}>
                    <option value={"COM"}>기업회원</option>
                    <option value={"PSN"}>일반회원</option>
                  </select> */}
                </td>
              </tr>
               {/* Points */}
              <Points points={points}/>
            </tbody>
          </table>
        </div>
        <div className="adm_com_btn_wrap center bottom">
          <button className="adm_com_btn gray" onClick={handleUpdate}>
            수정하기 {isUpdating && <i className="gg-spinner-alt" />}
          </button>
          <button className="adm_com_btn gray" onClick={() => navigate(-1)}>
            돌아가기
          </button>
        </div>
        {/* Password Modal */}
        <Modal open={passwordModalActive} close={() => setPasswordModalActive(false)}>
          <div className="pop_tit">비밀번호 변경</div>
          <div className="content_container scroll">
            <ul className="adm_com_input_sec">
              <li className="tit_field">현재 비밀번호</li>
              <li className="input_field">
                <input
                  type="password"
                  className="input"
                  placeholder="영문, 숫자, 특수문자 포함 8~16 자리"
                  {...register("old_user_pwd", {
                    minLength: 8,
                    maxLength: 32,
                    validate: (val) => {
                      if (getValues("user_pwd") !== val) {
                        setErrors((prev) => ({ ...prev, mismatchedPassword: true }));
                        return false;
                      }
                    },
                  })}
                />
                {errors["incorrectCurrentPassword"] && (
                  <span className="error_txt">* 보안에 취약한 비밀번호 입니다.</span>
                )}

                {/* <span className="error_txt">* 보안에 취약한 비밀번호 입니다.</span> */}
              </li>
              <li className="tit_field">새로운 비밀번호</li>
              <li className="input_field">
                <input
                  type="password"
                  className="input"
                  placeholder="영문, 숫자, 특수문자 포함 8~16 자리"
                  {...register("new_user_pwd", {
                    minLength: 8,
                    maxLength: 32,
                  })}
                />
                {errors["mismatchedPassword"] && <span className="error_txt">* 비밀번호가 일치하지 않습니다.</span>}
              </li>
              <li className="tit_field">비밀번호 재입력</li>
              <li className="input_field">
                <input
                  type="password"
                  className="input"
                  placeholder="영문, 숫자, 특수문자 포함 8~16 자리"
                  {...register("new_user_pwd_match", {
                    minLength: 8,
                    maxLength: 32,
                    validate: (val) => {
                      if (getValues("new_user_pwd") !== val) {
                        setErrors((prev) => ({ ...prev, mismatchedPassword: true }));
                        return false;
                      }
                    },
                  })}
                />
                {errors["mismatchedPassword"] && <span className="error_txt">* 비밀번호가 일치하지 않습니다.</span>}
              </li>
            </ul>
          </div>
          <div className="btn_container">
            <button
              className="adm_com_btn line gray xs"
              onClick={async () => {
                setPasswordModalActive(false);
                await refetch();
              }}>
              취소
            </button>
            <button className="adm_com_btn gray xs" onClick={handlePasswordUpdate}>
              변경 {isUpdating && <i className="gg-spinner-alt" />}
            </button>
          </div>
        </Modal>
      </main>
    );
};

export default MemberDetail;
