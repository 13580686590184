import React from "react";
import { Route, Routes } from "react-router-dom";
import "../views/boffice/assets/scss/styles.scss";
import BofficeHeader from "../views/boffice/component/Header";
import Main from "../views/boffice/page/Main";
import AdminList from "../views/boffice/page/admin-settings/List";
import MainAdmin from "../views/boffice/page/admin-settings/MainAdmin";
import RouterBofficeMagazine from "./BofficeMagazine";
import RouterBofficeMember from "./BofficeMemberRouter";
import RouterBofficeMentoring from "./BofficeMentoring";
import RouterBofficeInsight from "./BofficeInsight";
import BofficeSeminarRouter from "./BofficeSeminarRouter";
import BOfficeCommunityRouter from "./BofficeCommunityRouter";
import BofficeAiMatchingRouter from "./BofficeAiMatchingRouter";

export default function RouterBoffice() {
  // Authentication logic is done in the router, for now the /screens
  return (
    <div className="adm_com_container">
      <BofficeHeader />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/setting/manager/main" element={<MainAdmin />} />
        <Route path="/setting/manager/list" element={<AdminList />} />
        <Route path="/member/*" element={<RouterBofficeMember />} />
        <Route path="/mentoring/*" element={<RouterBofficeMentoring />} />
        <Route path="/insight/*" element={<RouterBofficeInsight />} />
        <Route path="/board/magazine/*" element={<RouterBofficeMagazine />} />
        <Route path="/board/seminar/*" element={<BofficeSeminarRouter />} />
        <Route path="/board/community/*" element={<BOfficeCommunityRouter />} />
        <Route path="/board/community/*" element={<BOfficeCommunityRouter />} />
        <Route path="/ai/*" element={<BofficeAiMatchingRouter />} />
      </Routes>
    </div>
  );
}
