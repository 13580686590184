/* eslint-disable react/no-this-in-sfc */
import React, { useState, useEffect, useContext, useRef } from "react";
// import { Link } from "react-router-dom";
import $ from 'jquery';
import "../../assets/scss/page/member.scss";
import "../../assets/scss/page/chat.scss";
//component
import io from 'socket.io-client'; //소켓
import { mutateBackendFormData } from "../../../../../api";
// import ProfileImg from "../../assets/img/img_mentor03.png";
import AddFileImg from "../../assets/img/icon_add_file.png";
import ScrollToTopButton from "../miscellaneous/ScrollToTopButton";
import { AuthContext } from "../../../../../context/Auth";
import { getImageLink, getFileLink } from "../../../../../utils/misc";
import ChatEvents from "./ChatEvents";


const ENDPOINT = process.env.REACT_APP_SERVER_CHAT_URL_PROD;
const ALLOW_FILE_EXTENSION = "png,gif,jpg,jpeg";

let socket;
export default function Chat() {

  const params = new URLSearchParams(location.search);

  const roomIdx = params.get("roomIdx");
  // const corpUserSeq = params.get("corpUserSeq");
  // const dealTable = params.get("dealTable");

  const [name] = useState();
  const [room] = useState(roomIdx);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [events, setEvents] = useState([]);
  const { user } = useContext(AuthContext);
  const uploadInput = useRef();
  const scrollRef = useRef();


  useEffect(() => {

    //채팅방 연결
    console.log(roomIdx, user.user_seq);

    console.log(Number(roomIdx) > 0 && user.user_seq);
    if (Number(roomIdx) > 0 && user.user_seq) {
      console.log('연결!!!');
      socket = io.connect(ENDPOINT, { forceNew: true });
      socket.on('connect', () => {
        socket.emit('newUser', { room: roomIdx, name: user.user_seq })
        socket.emit('get_chat', { room: roomIdx, name: user.user_seq })
        socket.emit('message', { room: roomIdx, type: 'NOTI', message: `${user.user_name}님이 입장하셨습니다.`, name: name, cate: null, img: null });
      });

      return function cleanup() {
        socket.emit('message', { room: roomIdx, type: 'NOTI', message: `${user.user_name}님이 퇴장하셨습니다.`, name: name, cate: null, img: null });
        socket.disconnect();
      }
    }
  }, []);

  useEffect(() => {

    const pushEvent = (event) => {
      setEvents(events => [...events, event]); // functional update
    };

    socket.on('update', (event) => {
      //채팅 읽음 처리
      if (event.readYN === "N" && Number(event.name) !== user.user_seq) {
        socket.emit('read_check', { chat_idx: event.chatIdx })
      }
      pushEvent(event);
    });

    socket.on('event2', (event) => {
      pushEvent(event);
    });

  }, []);


  useEffect(() => {
    const prevs = [];
    //중복 제거
    events.reduce((prev, now) => {
      if (!prevs.some(obj => obj.chatIdx === now.chatIdx)) {
        prevs.push(now);
      }
      return prev;
    }, []);
    setMessages(prevs);
  }, [events]);

  const sendMessage = () => {
    socket.emit('message', { room: room, type: 'CHAT', message: message, name: name, cate: null, img: null });
    setMessage('');
  }



  async function handleUpload(event) {
    const target = event.currentTarget;
    const files = target.files[0];

    if (fileExtensionValid(files)) {
      imgUpload(event);
    } else {
      fileUpload(event);
    }
  }

  async function imgUpload(event) {
    const target = event.currentTarget;
    const formData = new FormData();

    formData.append("file", target.files[0]);
    formData.append("upload_ty", new Date().toISOString());

    //이미지인 경우
    const response = await mutateBackendFormData("/upload/image", formData)
      .then(async (data) => await data.json())
      .catch(() => ({ messageCode: 400 }));

    if (response.messageCode === 200) {
      const imgLink = getImageLink(response.data[0].up_file_seq);
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function () {
        const image = new Image();
        image.src = reader.result;
        image.onload = function () {
          //이미지 파일의 넓이와 높이
          const typ = 'IMG';
          const cate = '';
          socket.emit('message', { room: room, type: typ, cate: cate, message: '이미지', img: imgLink, img_w: this.width, img_h: this.height });

        }
      }
    } else {
      console.log("file failed");
      return false;
    }
  }

  async function fileUpload(event) {
    const target = event.currentTarget;
    const formData = new FormData();

    formData.append("file", target.files[0]);
    formData.append("upload_ty", new Date().toISOString());

    //파일인 경우
    const response = await mutateBackendFormData("/upload/file", formData)
      .then(async (data) => await data.json())
      .catch(() => ({ messageCode: 400 }));

    if (response.messageCode === 200) {

      const fileLink = getFileLink(response.data[0].up_file_seq);
      const typ = 'FILE';
      const cate = '';
      socket.emit('message', { room: room, type: typ, cate: cate, message: '파일', img: fileLink, img_w: '', img_h: '' });

    } else {
      console.log("file failed");
      return false;
    }
  }

  function onClickUpload() {
    const myInput = document.getElementById("input_file");
    myInput.click();
  }

  useEffect(() => {
    // 현재 스크롤 위치 === scrollRef.current.scrollTop
    // 스크롤 길이 === scrollRef.current.scrollHeight
    const q = document.getElementById("chatMessages").scrollHeight;
    const w = document.getElementById("chatMessages").scrollTop;
    console.log(q, w);
    // scrollRef.current.scrollTop = (parseInt(scrollRef.current.scrollHeight) + 1000);
    console.log(scrollRef.current.scrollTop, scrollRef.current.scrollHeight);
    const scrollHeight = parseInt($(".scroll_body")[0].scrollHeight);
    $(".scroll_body").scrollTop(scrollHeight + 1000);
  });

  const fileExtensionValid = ({ name }) => {
    // 파일 확장자
    const extension = removeFileName(name);

    /**
     * 허용가능한 확장자가 있는지 확인하는 부분은 indexOf를 사용해도 괜찮고, 
     * 새롭게 나온 includes를 사용해도 괜찮고, 그밖의 다른 방법을 사용해도 좋다.
     * 성능과 취향의 따라 사용하면 될것같다.
     * 
     * indexOf의 경우
     * 허용가능한 확장자가 있을경우 
     * ALLOW_FILE_EXTENSION 상수의 해당 확장자 첫 index 위치값을 반환
     */
    if (!(ALLOW_FILE_EXTENSION.indexOf(extension) > -1) || extension === '') {
      // 해당 if문이 수행되는 조건은
      // 1. 허용하지 않은 확장자일경우
      // 2. 확장자가 없는경우이다.
      return false;
    }
    return true;
  }

  const removeFileName = (originalFileName) => {
    // 마지막 .의 위치를 구한다
    // 마지막 .의 위치다음이 파일 확장자를 의미한다
    const lastIndex = originalFileName.lastIndexOf(".");

    // 파일 이름에서 .이 존재하지 않는 경우이다.
    // 이경우 파일 확장자가 존재하지 않는경우(?)를 의미한다.
    if (lastIndex < 0) {
      return "";
    }

    // substring을 함수를 이용해 확장자만 잘라준다
    // lastIndex의 값은 마지막 .의 위치이기 때문에 해당 위치 다음부터 끝까지 문자열을 잘라준다.
    // 문자열을 자른 후 소문자로 변경시켜 확장자 값을 반환 해준다.
    return originalFileName.substring(lastIndex + 1).toLowerCase();
  }

  return (
    <>
      {/* <Lnb PageTitle="결제 콘텐츠 목록" MenuPayContents="active"/> */}
      <div className="container">
        <h2 className="com_pg_tit">채팅방</h2>
        <div className="chat_wrap">
          {/* <section className="list_sec">
            <div className="list scroll">
              
            </div>
            <button className="com_btn blue s">채팅방나가기</button>
          </section> */}
          <section className="chat_sec">
            <div className="chat_mid">
              {/*채팅내용 스크롤*/}
              <div className="scroll_body scroll" id="chatMessages" ref={scrollRef}>
                {messages.length > 0 && (
                  <ChatEvents events={messages} socket={socket} user={user}/>
                )}

                {/* <article className="notice">멘티님께서 입장하셨습니다.</article>
                <p className="date_area">
                  <span>2023.5.30</span>
                </p> */}

              </div>
              {/*메시지 입력*/}
              <article className="chat_bottom">
                <input
                  style={{ display: 'none' }}
                  type="file"
                  className="input_file"
                  id="input_file"
                  ref={uploadInput}
                  onChange={handleUpload}
                  accept="image/*, .pdf, .doc"
                  multiple={true}
                />
                <button type="button" className="btn_file" onClick={() => onClickUpload()}>
                  <img src={AddFileImg} alt="" />
                </button>
                <textarea name="input_msg" id="input_msg" placeholder="내용 입력" className="textarea" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                <button type="button" id="submit" className="com_btn white s btn_send" onClick={() => sendMessage()}>
                  전송
                </button>
              </article>
            </div>
          </section>
        </div>
      </div>
      {/* top button */}
      <ScrollToTopButton />
    </>
  );
}
