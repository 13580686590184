import ProfileImg from "../contents/views/screens/assets/img/user-profile-placeholder.svg";
// import NoImg from "../contents/views/screens/assets/img/image-placeholder.svg";

const backendUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_SERVER_BASE_URL_DEV
    : process.env.REACT_APP_SERVER_BASE_URL_PROD;

export const getFileLink = (id) => backendUrl + "/upload/download/" + id;

export const getImageLink = (id) => (id && Number(id) !== 0 ? backendUrl + "/upload/viewer/" + id : "");

export const getUserImageLink = (id) => (id ? backendUrl + "/upload/viewer/" + id : ProfileImg);

//숫자에 콤마 넣기
export const addComma = (getNumber) => {
  let tmpNumber = Number(getNumber);
  let isMinus = false;

  if(tmpNumber < 0) {
    isMinus = true;
    tmpNumber = Math.abs(tmpNumber)
  }

  let rtnStr = tmpNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if(isMinus) rtnStr = `-${rtnStr}`;

  return rtnStr;
}
