import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../assets/scss/page/member.scss";
//component
import Pagination from "../../components/Pagination";
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import { deleteBackend } from "../../../../../api";
import { toast } from "react-toastify";
import DeleteInsightModal from "../../components/myPage/_PopInsightDelete";

function ProductInsight() {
  const [per_page, setPerPage] = useState(6);
  const [totalCount, setTotalCount] = useState(0);
  const [curr_page, setCurrPage] = useState(1);
  const [insightIds, setInsightIds] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const {
    data: insights,
    isFetching: isFetchingInsights,
    refetch: refetchInsights,
  } = useCustomQuery(`/insight/my-insights?curr_page=${curr_page}&per_page=${per_page}`, {
    queryKey: ["getInsights"],
    select: (res) => res?.data,
    onSuccess: (res) => {
      setTotalCount(res?.total_count);
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    refetchInsights();
  }, [curr_page, per_page, refetchInsights]);

  const handleNextClick = () => {
    if (curr_page < Math.ceil(totalCount / per_page)) {
      setCurrPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevClick = () => {
    if (curr_page > 1) {
      setCurrPage((prevPage) => prevPage - 1);
    }
  };

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value, 10));
  };

  const handleCheckboxChange = (id) => {
    setInsightIds((prevSelectedIds) => {
      const updatedIds = prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((item) => item !== id)
        : [...prevSelectedIds, id];

      console.log("====+++", updatedIds);
      return updatedIds;
    });
  };

  const handleDeleteInsight = () => {
    deleteBackend("/insight", {
      data: {
        insightIds,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        refetchInsights();
        toast.success("멘토링이 성공적으로 삭제되었습니다");
        setDeleteModalOpen(false);
      })
      .catch(() => {
        toast.error("멘토링을 삭제하지 못했습니다");
      });
  };


  console.log(insights?.insights);
  return (
    <>
      <div className="container">
        <h2 className="com_pg_tit">상품 관리</h2>
        <section className="com_pg_tab">
          <Link to="/Mypage/ProductInsight" className="tab active">
            인사이트 상품
          </Link>
          <Link to="/Mypage/ProductMentoring" className="tab">
            멘토링 상품
          </Link>
        </section>
        <section className="com_board_list__top">
          <article className="btn_wrap">
            <button className="com_btn blue oval xs" onClick={() => setDeleteModalOpen(true)}>
              삭제
            </button>
          </article>
        </section>
        <table className="com_board_list">
          <colgroup>
            <col width="6%" />
            <col />
            <col width="13%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="13%" />
            <col width="10%" />
          </colgroup>
          <thead>
            <tr>
              <th></th>
              <th>상품명</th>
              <th>상태</th>
              <th>카테고리</th>
              <th>조회 수</th>
              <th>구매자 수</th>
              <th>댓글 수</th>
              <th>등록일</th>
              <th>관리</th>
            </tr>
          </thead>
          <tbody>
            {insights?.insights?.map((insig) => (

              <tr key={insig.insig_seq}>
                <td className="chk_field">
                  <input
                    type="checkbox"
                    className="com_chk"
                    id={insig.insig_seq}
                    checked={insightIds.includes(insig.insig_seq)}
                    onChange={() => handleCheckboxChange(insig.insig_seq)}
                  />
                  <label htmlFor={insig.insig_seq}></label>
                </td>
                <td className="tit_field">{insig.insig_name}</td>
                <td className="tit_field">{insig.encoding_YN === 'N' ? '인코딩 중':'완료'} </td>
                <td className="state_field">{insig.cate_name}</td>
                <td className="info_field">
                  <span className="th_m">조회 수 :</span>
                  {insig.insig_views}
                </td>
                <td className="info_field">
                  <span className="th_m">구매자 수 :</span>1201
                </td>
                <td className="info_field last">
                  <span className="th_m">댓글 수 :</span>
                  {insig.insig_comments}
                </td>
                <td className="date_field">
                  <span className="th_m">등록일 :</span>
                  {insig.reg_dtm.split("T")[0]}
                </td>
                <td className="btn_field">
                  <Link to={`/insight/write/${insig.insig_seq}`} className="com_btn oval line gray s">
                    수정
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {!isFetchingInsights && (
          <Pagination
            currentPage={curr_page}
            totalPages={Math.ceil(insights?.total_count / per_page)}
            onNextClick={handleNextClick}
            onPrevClick={handlePrevClick}
            perPage={per_page}
            handlePerPageChange={handlePerPageChange}
          />
        )}
      </div>
      {/* top button */}
      <ScrollToTopButton />

      <DeleteInsightModal
        open={deleteModalOpen}
        close={() => setDeleteModalOpen(false)}
        handleDelete={handleDeleteInsight}
      />
    </>
  );
}

export default ProductInsight;
