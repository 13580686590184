import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../assets/scss/page/member.scss";
//image
//component
import Pagination from "../../components/Pagination";
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import { updateBackend } from "../../../../../api";
import { toast } from "react-toastify";

export default function RecruitStatus({ currentRecruit, goBack, recruitStatus }) {
  const [applicationList, setApplicationList] = useState([]);

  const {
    isFetching: isResumeLoading,
    isRefetching,
    refetch,
  } = useCustomQuery(
    `/application/list?per_page=10&curr_page=1&recruit_seq=${currentRecruit}&appli_status=${recruitStatus}`,
    {
      queryKey: ["getApplications", currentRecruit, recruitStatus],
      select: (res) => res?.data?.data_list,
      onSuccess: (data) => {
        setApplicationList(data);
      },
    },
  );

  if (isResumeLoading && !isRefetching) return <i className="gg-spinner-alt" />;

  const updateApplicantStatus = async (application, new_status) => {
    toast.loading("사용자 애플리케이션 업데이트 ....", { toastId: "ApplicationUpdating" });

    const response = await updateBackend("/application", {
      data: {
        ...application,
        appli_status: new_status,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (data) => await data.json())
      .catch((err) => {
        throw new Error(err);
      });
    if (response.messageString === "SUCCESS" && response.messageCode === 200) {
      toast("사용자 애플리케이션 상태 업데이트되었습니다!");
    } else {
      console.log(response);
      toast.error("뭔가 잘못되었습니다!다시 시도하십시오.");
    }

    await refetch();

    toast.dismiss("ApplicationUpdating");
  };

  return (
    <>
      <div className="flex-start flex-in-between">
        <h2 className="com_pg_tit">채용관리</h2>
        <button className="com_btn  s" onClick={() => goBack()}>
          뒤로 가기 ⤴{" "}
        </button>
      </div>

      <div className="tab_content">
        <section className="com_board_search">
          <select className="seclect">
            <option>전체</option>
          </select>
          <article className="date_select type_btns">
            <button className="com_btn blue line s">전체</button>
            <button className="com_btn lightgray s">1개월</button>
            <button className="com_btn lightgray s">3개월</button>
          </article>
          <article className="date_select type_input">
            <input type="date" className="com_datepicker" placeholder="2022. 01. 01" />
            <input type="date" className="com_datepicker" placeholder="2022. 01. 01" />
            <button className="com_btn blue s">조회</button>
          </article>
        </section>
        <table className="com_board_list">
          <colgroup>
            <col width="20%" />
            <col width="20%" />
            <col />
            <col width="20%" />
          </colgroup>
          <thead>
            <tr>
              <th>지원자</th>
              <th>지원일</th>
              <th>이력서</th>
              <th>상태</th>
            </tr>
          </thead>
          <tbody>
            {applicationList.map((item, index) => (
              <tr key={index}>
                <td className="info_field">
                  <span className="th_m">지원자 :</span>
                  {item["name"]}
                </td>
                <td className="info_field info_apply_date">
                  <span className="th_m">지원일 :</span>
                  {new Date(item["reg_dtm"]).toLocaleDateString()}
                </td>
                <td className="info_field info_btn">
                  <Link to={"/Company/Resume/" + item["resume_seq"]} className="com_btn oval line s">
                    이력서 보기
                  </Link>
                </td>
                <td className="info_field info_btn">
                  <select
                    className="select"
                    defaultValue={item["appli_status"]}
                    onChange={async (e) => await updateApplicantStatus(item, e.target.value)}>
                    <option value="SS10">지원중</option>
                    <option value="SS15">열람</option>
                    <option value="SS20">서류통과</option>
                    <option value="SS30">면접제안</option>
                    <option value="SS50">합격</option>
                    <option value="SS90">서류거절</option>
                    <option value="SS99">불합격</option>
                  </select>
                  {/* 합격 */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination />
      </div>
      {/* top button */}
      <ScrollToTopButton />
    </>
  );
}
