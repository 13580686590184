/* eslint-disable */
import React, { useEffect, useState } from "react";
import Paging from "../../component/Paging";
import { useAuthenticatedQuery } from "../../../../../hooks/useAPI";
import Table from "../../../screens/components/boffice/Table";
import MemberFilter from "../../component/MemberFilter";

const columns = {
  comp_seq: "번호",
  user_seq: "사용자 번호",
  comp_name: "회사 이름",
  comp_reg_no: "담당자 휴대 전화 번호",
  reg_dtm: "등록 날짜",
  user_status: "승인",
  // * If it is a link, it passes an object
  company_detail: {
    label: "회사 세부 사항",
    getLink:
      /*
       * @param item, instance of data
       */
      (item) => "/boffice/member/manage/company/" + item["comp_seq"],
    linkLabel: "상세",
  },
};

function CorporateMemberList() {
  const [page, setPage] = useState(1);
  const [companyPerPage, setCompanyPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const [companyList, setCompanyList] = useState([]);

  const [paramArguments, setParamArguments] = useState("");

  const { data, isFetching } = useAuthenticatedQuery(
    ["getCompanyList/" + paramArguments, page, companyPerPage],
    `/admin/company/search?per_page=${companyPerPage}&curr_page=${page}&sort_by=comp_seq&` + paramArguments,
  );

  useEffect(() => {
    const updateData = async (data) => {
      const response = await data.json();

      if (response.messageCode === 200) {
        setTotalCount(response.data.total_cnt);
        setCompanyList(response.data.data_list);
      }
    };

    !isFetching && data && !data.bodyUsed && updateData(data);
  }, [data, isFetching]);

  const handlePageClick = (toPage) => {
    if (Number.isInteger(toPage)) return setPage(toPage);

    switch (toPage) {
      case "first": {
        setPage(1);
        break;
      }
      case "next": {
        page < Math.floor(totalCount / companyPerPage) && setPage((prev) => prev + 1);
        break;
      }
      case "prev": {
        page > 1 && setPage((prev) => prev - 1);
        break;
      }
      case "last": {
        setPage(Math.ceil(totalCount / companyPerPage));
        break;
      }
    }
  };

  return (
    <main>
      <h2 className="adm_com_pg_tit2 bottom_line"> 회원 관리</h2>
      <div className="adm_com_pg_content">
        <h2 className="sub_tit">- 기업 회원 관리 </h2>
        <MemberFilter
          additionalFields={undefined}
          columns={columns}
          dataPerPage={companyPerPage}
          defaultSearchColumn={"user_name"}
          setDataPerPage={setCompanyPerPage}
          setParamArguments={setParamArguments}
        />
        <div className="sub_tit">
          <span className="txt flex-start">
            {totalCount} 건 {isFetching && <i className="gg-spinner-alt" />}
          </span>
        </div>
        <Table data={companyList} columns={columns} />
        <Paging handlePageClick={handlePageClick} page={page} totalPageCount={Math.ceil(totalCount / companyPerPage)} />
      </div>
    </main>
  );
}

export default CorporateMemberList;
