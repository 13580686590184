import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../../assets/scss/page/match.scss";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import { AuthContext } from "../../../../../context/Auth";
import TopSlideBanner from "./_TopSlideBanner";
//image
import iconAlertBlue from "../../assets/img/icon_alert_blue.png";
import JobItem from "./_JobItem";
import useCommCode from "../../../../../hooks/useCommCode";

function MatchList() {
  const { user } = useContext(AuthContext);
  const { data: employmentTypes } = useCommCode("EMP_TYPE");
  const [isLogin, setIsLogin] = useState(false);

  const navigate = useNavigate();

  const { data: matchDataList } = useCustomQuery(`/recruit/list/ai`, {
    queryKey: ["getMatchList"],
    select: (res) => res?.data?.data_list,
    enabled: isLogin,
  });

  useEffect(() => {
    if(user?.user_seq > 0) setIsLogin(true);
  }, []);

  return (
    <main className="p_match">
      {/* long banner */}
      <TopSlideBanner />
      {/* job list */}
      <section className="sec_job">
        <div className="com_center_wrap">
          <div className="job_wrap">
            <div className="pg_tit_wrap">
              {(user?.user_type === "PSN" && user?.user_seq > 0) &&
              <h2 className="com_pg_tit">
                AI로 매칭된 <span className="com_point_txt">{user?.user_name}</span>님과 딱 맞는 기업입니다.
              </h2>
              }
              <button className="com_btn m black icon_arrow btn_all_list" onClick={() => navigate("/match/JobList")}>
                전체 공고보기
              </button>
            </div>
            {(user.user_type === "PSN" || Object.keys(user).length === 0) &&
            <div className="list_wrap">
              <ul className="list">
                {user?.tendency_score === 100 && matchDataList?.map((item, index) => (
                  <JobItem key={index} employmentTypes={employmentTypes} recruitItem={item} />
                ))}
              </ul>
              <button className="btn_more_job arrow_right">더보기</button>
                <div className="com_overlay">
                {(user?.user_seq > 0 && user?.tendency_score === 100) ? ('') : (
                  <>
                    <div className="bg"></div>
                    <Link to="/mypage/MyFit" className="alert_box">
                      <img src={iconAlertBlue} className="icon" alt="alert" />
                      <span className="txt">
                        {(user?.user_seq > 0)? '' : '회원가입 및' } 나의 성향을 작성하시면 ai 매칭을 통해 <br />
                        나에게 맞는 정확한 공고를 추천해드려요!
                      </span>
                    </Link>
                  </>
                )
                }
              </div>
            </div>
            }
          </div>
        </div>
      </section>

    </main>
  );
}

export default MatchList;
