import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import "../../assets/scss/page/member.scss";
//component
import { useDaumPostcodePopup } from "react-daum-postcode";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { mutateBackend, mutateBackendFormData, updateBackend } from "../../../../../api";
import { isNicknameTaken } from "../../../../../api/miscellaneous";
import { AuthContext } from "../../../../../context/Auth";
import { useAuthenticatedQueryJson } from "../../../../../hooks/useAPI";
import { getImageLink } from "../../../../../utils/misc";
import ArrowCircle from "../../assets/img/arrow_circle.png";
import DotRevolve from "../../assets/img/dot-revolve.svg";
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import Modal from "../../components/modal";

export default function Info() {
  const { user, refetchUserInfo } = useContext(AuthContext);

  const { data, isFetching, refetch } = useAuthenticatedQueryJson("company-info", "/user/company-info");

  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [checkingNickname, setCheckingNickname] = useState(false);

  const [isUpdating, setIsUpdating] = useState(false);

  const [address, setAddress] = useState({ fullAddress: "", extraAddress: "" });

  const [images, setImages] = useState([]);
  const [loadingImages, setLoadingImages] = useState(0);

  const [tabState, setTabState] = useState(0);
  const tab = [
    { text: "회사정보", value: 0 },
    { text: "담당자 정보", value: 1 },
  ];

  console.log(data);
  const {
    formState: { errors, touchedFields },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
  } = useForm({
    mode: "onChange",
  });

  const openLocationModal = useDaumPostcodePopup("https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js");

  const handleAddressComplete = (data) => {
    const fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      setAddress((prev) => ({ ...prev, fullAddress, extraAddress }));
      //   fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
  };

  const selectAddress = () => {
    openLocationModal({ onComplete: handleAddressComplete });
  };

  useEffect(() => {
    setValue("comp_address", address.fullAddress);
    setValue("comp_address_detail", address.extraAddress);
  }, [address]);

  const handleCompanyUpdate = async () => {
    setIsUpdating(true);

    const updates = Object.keys(touchedFields).reduce(
      (keys, currentKey) =>
        user[currentKey] !== getValues(currentKey)
          ? {
              ...keys,
              [currentKey]: getValues(currentKey),
            }
          : { ...keys },
      {},
    );

    const imageDataUpdateResponse = await mutateBackend("/company/images", {
      data: { comp_seq: user.comp_seq, image_list: images },
      headers: { "Content-Type": "application/json" },
    });

    if (imageDataUpdateResponse.status !== 200) {
      toast.error("이미지 업로드 실패.");
      return;
    }

    if (user && Object.keys(updates).length > 0) {
      const dataUpdateResponse = await updateBackend("/user/update-company", {
        data: {
          ...updates,
          user_seq: user["user_seq"],
          comp_address: getValues("comp_address") + ", " + getValues("comp_address_detail"),
        },
        headers: { "Content-Type": "application/json" },
      });

      if (dataUpdateResponse.status === 200) toast("정보를 성공적으로 업데이트했습니다.");
      else toast.error("정보 업데이트가 실패했습니다.");
    }

    await refetchUserInfo();
    setIsUpdating(false);
  };

  const handleUpdate = async () => {
    setIsUpdating(true);

    const updates = Object.keys(touchedFields).reduce(
      (keys, currentKey) =>
        user[currentKey] !== getValues(currentKey)
          ? {
              ...keys,
              [currentKey]: getValues(currentKey),
            }
          : { ...keys },
      {},
    );

    if (touchedFields["email1"] || touchedFields["email2"])
      updates["user_email"] = getValues("email1") + "@" + getValues("email2");

    user &&
      Object.keys(updates).length > 0 &&
      (await updateBackend("/user/update", {
        data: { ...updates, user_seq: user["user_seq"] },
        headers: { "Content-Type": "application/json" },
      }).then(async (data) => {
        if (data.status === 200) toast("정보를 성공적으로 업데이트했습니다.");
        if (data.status === 409) {
          const response = await data.json();
          setError(response.data.split(" ")[response.data.split(" ").length - 1].slice(1, -1), {
            message: "Duplicate key.",
          });
        }
      }));

    await refetch();

    setIsUpdating(false);
  };

  const handlePasswordUpdate = async (/** @type {{ [x: string]: any; }} */ data) => {
    if (data["new_user_pwd"] !== data["new_user_pwd_match"]) {
      setError("new_user_pwd_match", { message: "암호가 일치하지 않습니다." });
      return false;
    }

    if (user) {
      setIsUpdating(true);
      const response = await updateBackend("/user/update-password", {
        data: {
          user_seq: user["user_seq"],
          current_password: getValues("old_user_pwd"),
          new_password: getValues("new_user_pwd"),
        },
        headers: { "Content-Type": "application/json" },
      })
        .then(async (data) =>
          data.status === 200 ? { ...data, ...(await data.json()) } : { messageCode: data.status },
        )
        .catch((err) => {
          setIsUpdating(false);
          console.log(err);
          return err;
        });
      setIsUpdating(false);

      console.log(response);

      if (response.messageCode === 404) setError("old_user_pwd", { message: "잘못된 비밀번호" });
      else if (response.messageCode === 200) {
        toast.success("비밀번호가 성공적으로 변경되었습니다!", { position: "bottom-right" });
        setPasswordModalOpen(false);
        reset();
      }
    }
  };

  const checkNickname = async (/** @type {string} */ user_nickname) => {
    setCheckingNickname(true);

    const nicknameTaken = await isNicknameTaken(user_nickname).catch(() => {
      // TODO Add server error
      toast.error("서버 오류");
      return true;
    });

    setCheckingNickname(false);
    return !nicknameTaken;
  };

  async function handleUpload(event) {
    setLoadingImages(Object.keys(event.target.files).length);
    const uploadImage = async (file) => {
      const formData = new FormData();

      formData.append("file", event.target.files[file]);
      formData.append("upload_ty", new Date().toISOString());

      const response = await mutateBackendFormData("/upload/file", formData)
        .then(async (data) => await data.json())
        // .then((data) => data.messageCode && setImages([...images, data.data[0]]))
        .catch(() => ({ messageCode: 400 }));

      return response.data[0];
    };

    const promises = Object.keys(event.target.files).map(uploadImage);
    const newImages = (await Promise.all(promises)).map((image) => image["up_file_seq"]);

    setLoadingImages(0);
    setImages([...images, ...newImages]);
  }

  useEffect(() => {
    if (!isFetching && data) {
      const updatedUser = data.data.data_list[0];
      refetchUserInfo;
      if (updatedUser.comp_image_seqs) setImages(updatedUser.comp_image_seqs.split(",").map((seq) => Number(seq)));
    }
  }, [isFetching]);

  const renderTab = useCallback(() => {
    return tab.map((v, idx) => {
      return (
        <button
          key={idx}
          className={`tab ${(tabState === v.value && "active") || ""}`}
          onClick={() => setTabState(v.value)}>
          {v.text}
        </button>
      );
    });
  }, [tab]);

  const uploadInput = useRef();

  return (
    <>
      <div className="container">
        <h2 className="com_pg_tit">
          <span className="flex-start">기업정보{isFetching && <i className="gg-spinner-alt" />}</span>
        </h2>

        <div className="com_pg_tab">{renderTab()}</div>

        {tabState === 0 ? (
          // Company Information
          <form className="tab_content" onSubmit={handleSubmit(handleCompanyUpdate)}>
            <ul className="com_input_sec type_row">
              <li className="field_box">
                <span className="tit">회사명</span>
                <div className="input_box">
                  <input type="text" className="input s" disabled defaultValue={user["comp_name"]} />
                </div>
              </li>
              <li className="field_box">
                <span className="tit">사업자등록번호</span>
                <div className="input_box">
                  <input type="text" className="input s" disabled defaultValue={user["comp_reg_no"]} />
                </div>
              </li>
              <li className="field_box">
                <span className="tit">사업자등록증 첨부</span>
                <div className="input_box">
                  <input type="text" value={getImageLink(user["reg_certi_seq"])} className="input s" disabled />
                  <Link style={{ marginInline: "12px" }} to={getImageLink(user["reg_certi_seq"])} target="_blank">
                    <img width={20} src={ArrowCircle} alt="프로필" />
                  </Link>
                </div>
              </li>
              <li className="field_box">
                <span className="tit">대표자명</span>
                <div className="input_box">
                  <input
                    type="text"
                    placeholder="좋은생각"
                    className="input s"
                    disabled
                    defaultValue={user["ceo_name"]}
                  />
                </div>
              </li>
              <li className="field_box">
                <span className="tit">회사주소</span>
                <div className="input_box">
                  <div className="input_wrap">
                    <input
                      type="text"
                      placeholder="서울특별시 강남구 봉은사로 211"
                      className="input"
                      defaultValue={user["comp_address"]}
                      {...register("comp_address")}
                    />
                    <button className="com_btn line s" onClick={() => selectAddress()}>
                      주소찾기
                    </button>
                  </div>
                  <input
                    type="text"
                    placeholder="10층 awesome"
                    className="input"
                    defaultValue={user["comp_add_detail"]}
                    {...register("comp_add_detail")}
                  />
                </div>
              </li>
              <li className="field_box">
                <span className="tit">회사소개</span>
                <div className="input_box">
                  <textarea
                    className="textarea"
                    placeholder="소프트웨어 테스팅 자동화 분야에서 국내 유일하게 독자적인 기술을 개발한 강소기업으로,
                      Mission-Critic(고신뢰) 산업분야의 소프트웨어 안전성 검증을 위한 테스트 자동화 도구 및 검증 서비스를 제공하는
                      소프트웨어 품질 전문기업입니다. 소프트웨어로 더 안전한 세상을 만들고자 하는 비전 아래 사회적 책임을 가지고 있습니다."
                    defaultValue={user["comp_intro"]}
                    {...register("comp_intro")}></textarea>
                </div>
              </li>
              <li className="field_box">
                <span className="tit"></span>
                <div className="input_box">
                  <div className="com_attach_section">
                    {loadingImages !== 0 &&
                      [...Array(loadingImages).keys()].map((key, i) => (
                        <p className="img_box" key={i}>
                          <img src={DotRevolve} className="img_upload" alt="upload image" />
                          <button type="button" className="btn_del"></button>
                        </p>
                      ))}
                    {images.map((curr_image, i) => (
                      <p className="img_box" key={curr_image["up_file_seq"]}>
                        <img src={getImageLink(curr_image)} className="img_upload" alt="upload image" />
                        <button
                          type="button"
                          className="btn_del"
                          onClick={() => setImages((prev) => prev.filter((img, index) => i !== index))}></button>
                      </p>
                    ))}
                    <div className="com_input_upload">
                      <input
                        type="file"
                        className="input_file"
                        ref={uploadInput}
                        onChange={handleUpload}
                        multiple={true}
                      />
                      <button
                        type="button"
                        onClick={() => uploadInput.current && uploadInput.current.click()}
                        // disabled={props?.files?.length > 4}
                        className={`btn_upload `}></button>
                    </div>
                    {/* <Upload /> */}
                  </div>
                </div>
              </li>
            </ul>
            <div className="com_btn_wrap center">
              <button className="com_btn blue s" type="submit">
                수정 완료 {isUpdating && <i className="gg-spinner-alt" />}
              </button>
              {/* <button className="com_btn line blue s" type="button">
                취소
              </button> */}
            </div>
          </form>
        ) : (
          // Contact Information
          <form className="tab_content" onSubmit={handleSubmit(handleUpdate)}>
            <ul className="com_input_sec type_row">
              <li className="field_box">
                <span className="tit">아이디</span>
                <div className="input_box">
                  {/* User ID */}
                  <input type="text" className="input s" disabled defaultValue={user["user_id"]} />
                </div>
              </li>
              <li className="field_box">
                <span className="tit">비밀번호 변경</span>
                <div className="input_box">
                  {/* Change Password */}
                  <button
                    className="com_btn line s"
                    onClick={() => {
                      setPasswordModalOpen(true);
                    }}>
                    비밀번호 변경
                  </button>
                </div>
              </li>
              <li className="field_box">
                <span className="tit">닉네임</span>
                {/* User Nickname */}
                <div className="input_box">
                  <div className="input_wrap s">
                    <input
                      type="text"
                      placeholder="닉네임을 입력해주세요"
                      className="input s"
                      defaultValue={user["user_nickname"]}
                      {...register("user_nickname", {
                        validate: {
                          checkNicknameAvailable: async (val) =>
                            touchedFields["user_nickname"] && (await checkNickname(val)),
                        },
                      })}
                    />
                    <button
                      className="com_btn line s "
                      type="button"
                      onClick={async () => await checkNickname(getValues("user_nickname"))}>
                      중복 확인
                      {checkingNickname && <i className="gg-spinner-alt" />}
                      {touchedFields["user_nickname"] && !errors["user_nickname"] && <i className="gg-check" />}
                      {touchedFields["user_nickname"] && errors["user_nickname"] && (
                        <div className="tooltip blue">
                          ⓘ<span className="tooltiptext">별명이 이미 가져 왔습니다.</span>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </li>
              <li className="field_box">
                <span className="tit">이름</span>
                {/* User Name */}
                <div className="input_box">
                  <input
                    type="text"
                    placeholder="실명을 입력해주세요"
                    className="input s"
                    defaultValue={user["user_name"]}
                    {...register("user_name")}
                  />
                </div>
              </li>
              <li className="field_box">
                <span className="tit">
                  이메일
                  {(touchedFields["email1"] || touchedFields["email2"]) && errors["user_email"] && (
                    <div className="tooltip blue">
                      ⓘ<span className="tooltiptext">이미 찍은 이메일.</span>
                    </div>
                  )}
                </span>
                <div className="input_box">
                  <div className="input_wrap email">
                    <input
                      type="text"
                      className="input"
                      defaultValue={user["user_email"] && user["user_email"].split("@")[0]}
                      {...register("email1")}
                    />
                    <span className="icon">@</span>
                    <input
                      type="text"
                      className="input"
                      defaultValue={user["user_email"] && user["user_email"].split("@")[1]}
                      {...register("email2")}
                    />
                    <select className="select" onChange={(e) => setValue("email2", e.target.value)}>
                      <option value="직접입력">직접입력</option>
                      <option value="naver.com">naver.com</option>
                      <option value="gmail.com">gmail.com</option>
                    </select>
                  </div>
                </div>
              </li>
              <li className="field_box">
                <span className="tit">휴대폰 번호</span>
                <div className="input_box">
                  <div className="input_wrap s">
                    <input
                      type="text"
                      placeholder="- 없이 숫자를 입력해주세요"
                      className="input"
                      defaultValue={user["user_mobile"]}
                      {...register("user_mobile")}
                    />
                    <button className="com_btn line s">휴대폰 인증</button>
                  </div>
                </div>
              </li>
              {user["user_type"] === "COM" && (
                <li className="field_box">
                  <span className="tit">소속</span>
                  <div className="input_box">
                    <input
                      type="text"
                      placeholder="기획팀"
                      className="input s"
                      defaultValue={user["user_department"]}
                      {...register("user_department")}
                    />
                  </div>
                </li>
              )}
            </ul>
            <div className="com_btn_wrap center">
              <button className="com_btn blue s" type="submit">
                수정 완료 {isUpdating && <i className="gg-spinner-alt" />}
              </button>
              {/* <button className="com_btn line blue s" type="button">
                취소
              </button> */}
            </div>
          </form>
        )}
      </div>

      {/* top button */}
      <ScrollToTopButton />
      {passwordModalOpen && (
        <Modal open={passwordModalOpen} close={() => setPasswordModalOpen(false)}>
          <form onSubmit={handleSubmit(handlePasswordUpdate)}>
            <div className="pop_tit">비밀번호 변경</div>
            <div className="content_container scroll">
              <ul className="adm_com_input_sec">
                <li className="tit_field">현재 비밀번호</li>
                <li className="input_field">
                  <input
                    type="password"
                    className="input"
                    placeholder="영문, 숫자, 특수문자 포함 8~16 자리"
                    {...register("old_user_pwd", {
                      minLength: 8,
                      maxLength: 32,
                    })}
                    required
                  />
                  {errors["old_user_pwd"] && <span className="error_txt">* 보안에 취약한 비밀번호 입니다.</span>}
                </li>
                <li className="tit_field">새로운 비밀번호</li>
                <li className="input_field">
                  <input
                    type="password"
                    className="input"
                    placeholder="영문, 숫자, 특수문자 포함 8~16 자리"
                    {...register("new_user_pwd", {
                      minLength: 8,
                    })}
                    required
                  />
                  {errors["new_user_pwd"] && <span className="error_txt">* 비밀번호가 일치하지 않습니다.</span>}
                </li>
                <li className="tit_field">비밀번호 재입력</li>
                <li className="input_field">
                  <input
                    type="password"
                    className="input"
                    placeholder="영문, 숫자, 특수문자 포함 8~16 자리"
                    {...register("new_user_pwd_match", {
                      required: true,
                      minLength: 8,
                      validate: (val) => {
                        if (getValues("new_user_pwd") !== val) {
                          setError("new_user_pwd_match", { message: "암호가 일치하지 않습니다." });
                          return false;
                        }
                      },
                    })}
                    required
                  />
                  {errors["new_user_pwd_match"] && <span className="error_txt">* 비밀번호가 일치하지 않습니다.</span>}
                </li>
              </ul>
            </div>
            <div className="btn_container">
              <button className="com_btn blue s" type="submit">
                변경 {isUpdating && <i className="gg-spinner-alt" />}
              </button>
              <button
                className="com_btn line blue s"
                onClick={async () => {
                  setPasswordModalOpen(false);
                  reset();
                }}>
                취소
              </button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
}
