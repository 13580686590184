import React, { useEffect, useState } from "react";

import PopAgree from "../../components/PopAgreement";
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import { mutateBackend, mutateBackendFormData, queryBackend, updateBackend } from "../../../../../api";
import { useForm } from "react-hook-form";
import { useAuthenticatedQuery } from "../../../../../hooks/useAPI";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import base64 from "base-64";

import Loading from "../../components/Loading";

function Write() {
  const navigate = useNavigate();
  const [modalAgreeOpen, setModalAgreeOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [createMode, setCreateMode] = useState(true);
  const [selectedVideoFile, setSelectedVideoFile] = useState(null);
  const [selectedThumbnailFile, setSelectedThumbnailFile] = useState(null);

  const [selectedTags, setSelectedTags] = useState([]);

  //midibus State
  const [Token, setToken] = useState("");
  const [CategoryId, setCategoryId] = useState("");
  const [ChannelId, setChannelId] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [MediaId, setMediaId] = useState("");
  const [MediLoding, setMediLoding] = useState(false);
  //midibus State

  const str = `${process.env.REACT_APP_MIDIBUS_EMAIL}:${process.env.REACT_APP_MIDIBUS_APIKEY}`;
  const enc = base64.encode(str);

  const handleTagChange = (selectedOption) => {
    setSelectedTags(selectedOption);
  };

  useEffect(() => {
    const tagValues = selectedTags.map((tag) => tag.value);
    setValue("insig_tags", tagValues.join(","));
  }, [selectedTags]);

  const { insig_seq } = useParams();

  const { data, isFetching } = useAuthenticatedQuery(["selectAllCategories"], `/category?cate_gr=INSIG_CATE`);
  const { data: tagsData, isFetching: isFetchingTags } = useAuthenticatedQuery(
    ["selectTags"],
    `/category?cate_gr=INSIG_TAG`,
  );

  useEffect(() => {
    const updateData = async (data) => {
      const response = await data.json();

      if (response.messageCode === 200) {
        setCategories(response.data.categories);
        setValue("insig_cate", response.data.categories[0].cate_seq);
      }
    };

    !isFetching && data && !data.bodyUsed && updateData(data);
  }, [data, isFetching]);

  useEffect(() => {
    const updateData = async (tagsData) => {
      const response = await tagsData.json();

      if (response.messageCode === 200) {
        setTags(response.data.categories);
      }
    };

    !isFetchingTags && tagsData && !tagsData.bodyUsed && updateData(tagsData);
  }, [tagsData, isFetchingTags]);

  useEffect(() => {
    if (insig_seq) {
      const fetchInsightData = async () => {
        try {
          const response = await queryBackend(`/insight/${insig_seq}`);
          const { data } = await response.json();

          setValue("insig_cate", data.insig_cate);
          setValue("insig_name", data.insig_name);
          setValue("insig_price", data.insig_price);
          setValue("insig_url", data.insig_url);
          setValue("insig_thumb", data.insig_thumb);
          setValue("insig_desc", data.insig_desc);
          setValue("media_id", data.media_id);

          console.log(data);

          const cateSeqArray = data.insig_tags.split(",");

          const defaultSelectedTags = cateSeqArray.map((seq, index) => ({
            value: seq,
            label: data.tags[index],
          }));

          setSelectedTags(defaultSelectedTags);
        } catch (error) {
          console.error("Error fetching insight data:", error);
        }
      };

      fetchInsightData();
      setCreateMode(false);
    }
  }, [insig_seq]);

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
  } = useForm({
    defaultValues: {
      insig_cate: "",
      insig_name: "",
      insig_price: 0,
      insig_url: "",
      insig_thumb: "",
      insig_desc: "",
      media_id: "",
    },
    mode: "onChange",
  });

  useEffect(() => {
    //get MIDIBUS TOKEN
    const getBusToken = async () => {
      if (!Token || Token === "") {
        const six_hours_later = new Date();
        six_hours_later.setHours(six_hours_later.getHours() + 15);
        const sliceRs = String(six_hours_later.getTime()).slice(0, 10);

        if (sliceRs) {
          const myHeaders = new Headers();
          myHeaders.append("Authorization", `Basic ${enc}`);
          myHeaders.append("Content-Type", "application/json");

          const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
          };

          await fetch(`${process.env.REACT_APP_MIDIBUS_API}/v2/token?expire=${sliceRs}`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
              const res = JSON.parse(result);
              setToken(res.token);
            })
            .catch((error) => console.log("error", error));
        }
      }
    };

    getBusToken();
  }, []);

  useEffect(() => {
    //GET MIDIBUS INFO
    const getData = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("X-Mbus-Token", Token);
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        fetch(`${process.env.REACT_APP_MIDIBUS_API}/v2/media`, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            const res = JSON.parse(result);
            const newData = res.category_list.filter((e) => e.category_name === "engenius");
            if (newData) {
              setCategoryId(newData[0]?.category_id);
              setChannelId(newData[0]?.channel_id_list[0]);
            }
          })
          .catch((error) => console.log("error", error));
      } catch (error) {
        console.log(error);
      }
    };

    if (Token) {
      getData();
    }
  }, [Token]);

  const handleVideoUpload = async (video) => {
    //MIDIBUS MEDIA UPLOAD
    try {
      setMediLoding(true);
      const formdata = new FormData();

      formdata.append("file", video);
      /************ media upload start *************/
      const myHeaders = new Headers();
      myHeaders.append("X-Mbus-Token", Token);
      myHeaders.append("X-Mbus-Channel", ChannelId);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      fetch(`${process.env.REACT_APP_MIDIBUS_API}/v2/media/${CategoryId}`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);

          const myHeaders = new Headers();
          myHeaders.append("X-Mbus-Token", Token);
          myHeaders.append("Content-Type", "application/json");

          if (res.media_id) {
            console.log(res);
            setMediaId(res.media_id);
            setValue("media_id", res.media_id);
            setMediLoding(false);
          }
        })
        .catch((error) => console.log("error :", error));
      /************ media upload end *************/
    } catch (e) {
      console.log(e);
    }
  };

  const handleThumbnailUpload = async (thumbnail) => {
    const formData = new FormData();
    formData.append("file", thumbnail);
    formData.append("upload_ty", "create_insight_thumb");

    const response = await mutateBackendFormData("/upload/image", formData)
      .then(async (data) => await data.json())
      .catch(() => ({ messageCode: 400 }));

    if (response.messageCode === 200) {
      console.log(response.data[0]?.up_file_seq);
      setValue("insig_thumb", response.data[0]?.up_file_seq);
      return;
    } else {
      console.log("file failed");
      return;
    }
  };

  const onValidSubmit = async (data) => {
    setFormSubmitting(true);
    try {
      console.log(data);
      let responseData;
      if (insig_seq) {
        responseData = await updateBackend(`/insight/${insig_seq}`, {
          method: "PUT",
          data: {
            ...data,
          },
          headers: {
            "Content-Type": "application/json",
          },
        });
      } else {
        responseData = await mutateBackend("/insight/create", {
          data: {
            ...data,
          },
          headers: {
            "Content-Type": "application/json",
          },
        });
      }

      const response = await responseData.json();

      if (response.messageCode === 200) {

        //미디버스 인코딩 체크

        Token, ChannelId
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("X-Mbus-Token", Token);
        myHeaders.append("X-Mbus-Channel", ChannelId);
        myHeaders.append("Authorization", `Basic ${enc}`);

        const requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        fetch(`https://mapi.midibus.kinxcdn.com/v2/media/${process.env.REACT_APP_MIDIBUS_CATEGORY_ID}/${MediaId}`, requestOptions)
          .then(response => response.text())
          .then(result => {
            const jsonData = JSON.parse(result);
            if (jsonData?.encoded != "" && jsonData?.encoded != null) {
              const { media_id, poster_url, duration, object_simple_list } = jsonData;
              Noti(media_id, poster_url, duration, object_simple_list);

            } else {
              toast.success("Insight registered successfully!");
              // navigate("/insight");
              navigate("/Mypage/ProductInsight");
            }
          }

          )
          .catch(error => console.log('error', error));

        // toast.success("Insight registered successfully!");
        // navigate("/insight");
      }
    } catch (error) {
      toast.error("뭔가 잘못되었습니다! 다시 시도하십시오!");
    }

    setFormSubmitting(false);
  };

  const Noti = async (MediaId, ImgPath, Duration, ObjectId) => {

    console.log('Noti');
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "MediaId": MediaId,
      "ImgPath": ImgPath,
      "Duration": Duration,
      "ObjectId": [ObjectId[0]?.object_id]
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_SERVER_BASE_URL_DEV}/insight/midibus/noti`, requestOptions)
      .then(response => response.text())
      .then(result => {
        console.log(result);
        const jsonData = JSON.parse(result);
        if (jsonData.messageCode === 200 && jsonData.messageString === "SUCCESS") {
          toast.success("Insight registered successfully!");
          navigate("/Mypage/ProductInsight");
        }
      }
      )
      .catch(error => console.log('error', error));
  }


  return (
    <main className="com_pay p_write">
      {MediLoding === true ? <Loading /> : null} {/* 첨부파일 업로드 로딩 화면 지우지 말것!!! */}
      <h2 className="pg_tit">인사이트 등록</h2>
      <form className="box" onSubmit={handleSubmit(onValidSubmit)}>
        <input type="hidden" className="media_id" id="media_id" {...register("media_id", { require: true })} />
        <ul className="com_input_sec">
          <li className="tit_field">상품명</li>
          <li className="input_field">
            <input
              type="text"
              placeholder="상품명을 입력해주세요."
              className="input"
              {...register("insig_name", { require: true })}
            />
          </li>
          {errors.insig_name && <span style={{ color: "red" }}>이 필드가 필요합니다</span>}

          <li className="tit_field">카테고리</li>
          <li className="input_field">
            <select className="select" defaultValue={categories[0]?.cate_seq} {...register("insig_cate")}>
              {!isFetching &&
                categories?.map((category) => {
                  return (
                    <option key={category.cate_seq} value={category.cate_seq}>
                      {category.cate_name}
                    </option>
                  );
                })}
            </select>
          </li>
          {errors.insig_cate && <span style={{ color: "red" }}>이 필드가 필요합니다</span>}

          <li className="tit_field label_tag">
            <span>태그</span>
            <span className="text_blue">복수 선택 가능</span>
          </li>
          <li className="input_field">
            {!isFetchingTags && (
              <Select
                isMulti
                options={tags.map((tag) => ({ value: tag.cate_seq, label: tag.cate_name }))}
                value={selectedTags}
                onChange={handleTagChange}
              />
            )}
          </li>

          <li className="tit_field">가격 설정</li>
          <li className="input_field">
            <div className="input_box">
              <input
                type="text"
                id="price"
                placeholder="판매할 가격을 입력해주세요."
                {...register("insig_price", {
                  pattern: {
                    value: /^[0-9]*$/,
                    message: "가격은 유효한 숫자 여야합니다",
                  },
                  validate: (value) => {
                    if (value < 0) {
                      return "가격은 0보다 커야합니다";
                    }
                    return true;
                  },
                })}
              />
              <span className="price">P</span>
            </div>
          </li>
          {errors.insig_price && <span style={{ color: "red" }}>이 필드가 필요합니다</span>}

          <li className="tit_field">동영상 파일</li>
          <li className="input_field">
            <div className="input_file">
              <label className="com_btn line" htmlFor="video">
                파일선택
              </label>
              <input
                type="file"
                id="video"
                className="video"
                {...register("insig_url", { require: true })}
                onChange={(e) => {
                  setSelectedVideoFile(e.target?.files[0]);
                  handleVideoUpload(e.target?.files[0]);
                }}
              />
              <span className="message">{selectedVideoFile && selectedVideoFile.name}</span>
            </div>
          </li>
          {errors.insig_url && <span style={{ color: "red" }}>이 필드가 필요합니다</span>}

          <li className="tit_field">동영상 썸네일</li>
          <li className="input_field">
            <div className="input_file">
              <label className="com_btn line" htmlFor="thumbnail">
                파일선택
              </label>
              <input
                type="file"
                id="thumbnail"
                className="video"
                {...register("insig_thumb", { require: true })}
                onChange={(e) => {
                  setSelectedThumbnailFile(e.target?.files[0]);
                  handleThumbnailUpload(e.target.files[0]);
                }}
              />
              <span className="message">{selectedThumbnailFile && selectedThumbnailFile.name}</span>
            </div>
          </li>
          {errors.insig_thumb && <span style={{ color: "red" }}>이 필드가 필요합니다</span>}

          <li className="tit_field">동영상 소개</li>
          <li className="input_field">
            <textarea
              placeholder="동영상 소개"
              name="intro"
              id="intro"
              {...register("insig_desc", { require: true })}></textarea>
            <span className="counter">0 / 3,000</span>
          </li>
        </ul>

        {createMode && (
          <section className="com_agree_section">
            <ul className="list">
              <li className="item">
                <div>
                  <input
                    type="checkbox"
                    className="com_chk"
                    id="agree01"
                    {...(createMode && register("agree01", { required: true }))}
                  />
                  <label htmlFor="agree01">
                    개인정보 제3자 제공 동의합니다.<span className="txt_required">(필수)</span>
                  </label>
                </div>
                <button className="com_btn txt" onClick={() => setModalAgreeOpen(true)}>
                  자세히
                </button>
              </li>
              {errors.agree01 && <span className="txt_required">이것은 필요합니다.계속하기로 동의해야합니다.</span>}

              <li className="item">
                <div>
                  <input
                    type="checkbox"
                    className="com_chk"
                    id="agree02"
                    {...(createMode && register("agree02", { required: true }))}
                  />
                  <label htmlFor="agree02">
                    판매자 이용 약관에 동의합니다.<span className="txt_required">(필수)</span>
                  </label>
                </div>
                <button className="com_btn txt" onClick={() => setModalAgreeOpen(true)}>
                  자세히
                </button>
              </li>
              {errors.agree02 && <span className="txt_required">이것은 필요합니다.계속하기로 동의해야합니다.</span>}
            </ul>
          </section>
        )}

        <section className="com_btn_wrap center">
          <button type="submit" className="com_btn blue m">
            통찰력 {createMode ? " 등록" : " 수정"} {formSubmitting && <i className="gg-spinner-alt" />}{" "}
          </button>{" "}
        </section>
      </form>
      {/* top button */}
      <ScrollToTopButton />
      {/*약관내용 팝업*/}
      <PopAgree open={modalAgreeOpen} close={() => setModalAgreeOpen(false)} title="이용약관" type="terms" />
    </main>
  );
}

export default Write;
