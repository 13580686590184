import React, { useState } from "react";

export default function ApplyFilter({
  additionalFields = null,
  columns,
  dataPerPage,
  defaultSearchColumn,
  setDataPerPage,
  setParamArguments,
  filterItems,
  setFilterItems,
  handleSearchParamsChange,
}) {
  const [currentSearchColumn, setCurrentSearchColumn] = useState(defaultSearchColumn);

  const handleStartDateChange = (event) => {
    setFilterItems((prev) => ({ ...prev, start_date: event.target.value }));
  };

  const handleEndDateChange = (event) => {
    setFilterItems((prev) => ({ ...prev, end_date: event.target.value }));
  };

  const handleDateFilter = (days) => {
    const today = new Date();
    const newEndDate = new Date();
    const newStartDate = new Date();

    newEndDate.setDate(today.getDate());
    newStartDate.setDate(today.getDate() - days);
    setFilterItems((prev) => ({
      ...prev,
      start_date: newStartDate.toISOString().split("T")[0],
      end_date: newEndDate.toISOString().split("T")[0],
    }));
  };

  const resetFilter = () => {
    setFilterItems({});
    setParamArguments("");
  };

  return (
    <>
      <div className="adm_com_board_list__top">
        <div className="adm_com_date_select">
          {/* <span className="tit">{dateLabel}</span> */}

          <input
            type="date"
            className="adm_com_datepicker"
            placeholder={new Date().toLocaleDateString()}
            value={filterItems?.start_date || ""}
            onChange={handleStartDateChange}
          />
          <span className="divider"> ~ </span>
          <input
            type="date"
            className="adm_com_datepicker"
            placeholder={new Date().toLocaleDateString()}
            value={filterItems?.end_date || ""}
            onChange={handleEndDateChange}
          />
          <div className="adm_com_btn_wrap">
            <button
              className="adm_com_btn line gray"
              onClick={() => {
                setFilterItems((prev) => ({ ...prev, start_date: "", end_date: "" }));
              }}>
              전체
            </button>
            <button className="adm_com_btn line gray" onClick={() => handleDateFilter(0)}>
              오늘
            </button>
            <button className="adm_com_btn line gray" onClick={() => handleDateFilter(3)}>
              3일전
            </button>
            <button className="adm_com_btn line gray" onClick={() => handleDateFilter(7)}>
              일주일
            </button>
            <button className="adm_com_btn line gray" onClick={() => handleDateFilter(30)}>
              1개월
            </button>
            <button className="adm_com_btn line gray" onClick={() => handleDateFilter(90)}>
              3개월
            </button>
          </div>
          {additionalFields && additionalFields}
        </div>

        {columns && (
          <div className="adm_com_input_wrap flex_row">
            <select
              className="select"
              value={currentSearchColumn}
              onChange={(e) => setCurrentSearchColumn(e.target.value)}>
              <option value="">전체</option>
              <option value="recruit_title">공고제목</option>
              <option value="comp_name">회사명</option>
            </select>
            <input
              type="text"
              className="input name"
              placeholder="이름을 입력하세요"
              value={filterItems[currentSearchColumn] ? filterItems[currentSearchColumn] : ""}
              onChange={(e) => setFilterItems((prev) => ({ ...prev, [currentSearchColumn]: e.target.value }))}
            />
            <button className="adm_com_btn blue" onClick={() => handleSearchParamsChange()}>
              조회
            </button>
            <button className="adm_com_btn black" onClick={resetFilter}>
              초기화
            </button>
          </div>
        )}
      </div>
      <div className="adm_com_btn_wrap between">
        <button className="adm_com_btn red xs">엑셀 데이터 다운로드</button>
        <select className="select" value={dataPerPage} onChange={(e) => setDataPerPage(Number(e.target.value))}>
          <option value={10}>10개씩 보기</option>
          <option value={20}>20개씩 보기</option>
        </select>
      </div>
    </>
  );
}
