import React, { useContext, useEffect, useRef, useState } from "react";
import "../../assets/scss/page/company.scss";

import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { mutateBackendFormData, updateBackend } from "../../../../../api";
import { AuthContext } from "../../../../../context/Auth";
import { useCustomQuery } from "../../../../../hooks/useAPI";

import { getImageLink } from "../../../../../utils/misc";
import Tag from "../../components/company/recruit/Tag";
import PopCreateTag from "../../components/company/recruit/_popCreateTag";
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import Modal from "../../components/modal";
import useCommCode from "../../../../../hooks/useCommCode";
import useCommCate from "../../../../../hooks/useCommCate";

export default function JobDetailEdit() {
  const { recruit_seq } = useParams();

  const navigate = useNavigate();

  const { user } = useContext(AuthContext);

  const [modalWriteOpen, setModalWriteOpen] = useState(false);

  const [openCreateTag, setOpenCreateTag] = useState(false);

  const [showAddressSelect, setShowAddressSelect] = useState(false);

  const [formSubmitting, setFormSubmitting] = useState(false);

  const [showCloseDate, setShowCloseDate] = useState(true);

  const [recruit, setRecruit] = useState();

  const [recruitTags, setRecruitTags] = useState([]);
  const [parentCategory, setParentCategory] = useState();
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [minCloseDate, setMinCloseDate] = useState(new Date().toISOString().split("T")[0]);

  const [imageSeq, setImageSeq] = useState(0);
  const [jobLocation, setJobLocation] = useState("");

  const [isDateInputEnabled, setIsDateInputEnabled] = useState(true);
  console.log(isDateInputEnabled)

  const { isFetching } = useCustomQuery(`/recruit/list?per_page=${1}&curr_page=${1}&recruit_seq=${recruit_seq}`, {
    queryKey: ["getRecruitList"],
    select: (res) => res?.data,
    onSuccess: (data) => {
      const recruit = data.data_list[0];
      setRecruit(recruit);
      setImageSeq(recruit.image_seq);
      setRecruitTags(String(recruit.recruit_tags).split(","));
      setSelectedCategories(
        String(recruit.recruit_categories)
          .split(",")
          .map((cate_seq) => Number(cate_seq)),
      );
    },
    refetchOnWindowFocus: false,
  });

  const { isFetching: LocationDataFetching, data: locationTypeData } = useCommCode(`LOCA_CD`);

  const { isFetching: EmpTypeIsFetching, data: employmentTypes } = useCommCode("EMP_TYPE");

  const { isFetching: RecrTypeIsFetching, data: recruitTypes } = useCommCode("RECU_CLOSE");

  const { isFetching: RecrJobTypeIsFetching, data: categoryTypes } = useCommCate("RECU_JOB");

  const handleCloseWriteModal = () => {
    setModalWriteOpen(false);
  };

  const {
    clearErrors,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    trigger,
    setError,
    setValue,
    watch
  } = useForm();

  const uploadInput = useRef();

  useEffect(() => {
    setValue("image_seq", imageSeq);
  }, [imageSeq]);

  useEffect(() => {
    recruit &&
      recruit &&
      setJobLocation(locationTypeData?.find((location) => location?.comm_code === recruit["recruit_location"]));
  }, [recruit, locationTypeData]);

  useEffect(() => {
    jobLocation !== "" && setValue("job_location", jobLocation?.code_nm_kr);
  }, [jobLocation, locationTypeData]);

  const getCateText = (cate_seq) => {
    const cateIn = categoryTypes.find((cat) => cat && cat["cate_seq"] == cate_seq);
    if (cateIn && cateIn["cate_name"]) return cateIn["cate_name"];
    else return null;
  };

  const handleAddAnnouncementTag = (newTag) => {
    if (recruitTags.find((x) => x === newTag) || newTag === "") return;
    recruitTags.push(newTag);
    setRecruitTags(recruitTags);
  };

  const handleAnnouncementTagRemove = (i) => {
    const previousTags = recruitTags;
    const newTags = previousTags.filter((tag, k) => k !== i);
    setRecruitTags(newTags);
  };

  const handleAddCategory = (cate_seq) => {
    if (selectedCategories.length === 3) return;
    if (selectedCategories.find((x) => x === cate_seq)) return;
    setSelectedCategories([...selectedCategories, cate_seq]);
  };

  const handleCategoryTagRemove = (i) => {
    const newTags = selectedCategories.filter((tag, k) => k !== i);
    setSelectedCategories(newTags);
  };

  async function handleUpload(event) {
    const formData = new FormData();

    formData.append("file", event.target.files[0]);
    formData.append("upload_ty", new Date().toISOString());

    const response = await mutateBackendFormData("/upload/file", formData)
      .then(async (data) => await data.json())
      .catch(() => ({ messageCode: 400 }));

    if (response.messageCode === 200) {
      setImageSeq(response.data[0].up_file_seq);
    }
  }

  const handleUpdateRecruit = async (data) => {
    const validateFields = (data) => {
      let recruitTypeValid = false;
      recruitTypes.forEach((type) => {
        recruitTypeValid = recruitTypeValid || Boolean(data[type["comm_code"]]);
      });

      if (!recruitTypeValid) {
        setError("recruitType", { message: "" });
        return false;
      }

      if (!data.image_seq || data.image_seq === 0) {
        setError("image_seq", { message: "" }, { shouldFocus: true });
        return false;
      }

      return true;
    };

    const fieldsAreValid = validateFields(data);

    if (!fieldsAreValid) {
      handleCloseWriteModal();
      return;
    }

    setFormSubmitting(true);

    const recruitTagString = recruitTags.join(",");

    let finalData = {
      ...data,
      comp_seq: user.comp_seq,
      recruit_seq,
      recruit_location: locationTypeData.find((location) => location.code_nm_kr === data.job_location)["comm_code"],
      recruit_status: "OPEN",
      recruit_tags: recruitTagString,
      newcomer_YN: data.newcomer_YN ? "Y" : "N",
    };
    recruitTypes.forEach((type) => {
      if (Boolean(data[type["comm_code"]]) === true) finalData = { ...finalData, close_type: type["comm_code"] };
    });

    // console.log(finalData);

    const response = await updateBackend("/recruit", {
      data: finalData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (data) => data.json())
      .catch((err) => {
        setFormSubmitting(false);
        toast.error("작업 게시물을 제출하지 못했습니다.");
        throw new Error(err);
      });

    if (response.messageCode === 200) {
      toast.success("성공적으로 작업을 게시했습니다.");
    }
    handleCloseWriteModal();
    setFormSubmitting(false);
  };

  const handleCheckbox = (btn) => {
    if(btn === "PERIOD" || btn === "ANY"){
      setIsDateInputEnabled(true)
    }
  }

  useEffect(() => {
    if (recruit && recruit["close_type"] === "ANY") {
      setShowCloseDate(false);
      setValue("close_date", "");
    }
  }, [recruit]);

  const defaultCloseDate =
    recruit && recruit["close_date"]
      ? new Date(recruit["close_date"]).toISOString().split("T")[0] === "1899-11-29"
        ? ""
        : new Date(recruit["close_date"]).toISOString().split("T")[0]
      : "";


  useEffect(() => {
    (watch("ANY") === "false" || watch("UNTI") === "false" ? setValue("close_date", "") : setValue("close_date", defaultCloseDate));
  }, [watch("PERIOD"), watch("ANY")]);

  if (isFetching || EmpTypeIsFetching || RecrTypeIsFetching || RecrJobTypeIsFetching || LocationDataFetching)
    return (
      <div className="container">
        <i className="gg-spinner-big" />
      </div>
    );

  if (!recruit) return <></>;




  return (
    <>
      <div className="container">
        <h2 className="com_pg_tit flex-start">
          공고 수정
          {(isFetching || EmpTypeIsFetching || RecrTypeIsFetching || RecrJobTypeIsFetching) && (
            <i className="gg-spinner-alt" />
          )}
        </h2>

        <form
          onSubmit={handleSubmit(handleUpdateRecruit, (x) => {
            console.log(x);
            handleCloseWriteModal();
          })}>
          <ul className="com_input_sec type_row">
            {/* Image Field */}
            <li className="field_box">
              <span className="tit">공고 이미지</span>
              <div className="input_box">
                <div className="input_wrap">
                  <div className="com_attach_section">
                    <div className="com_input_upload">
                      <input
                        type="file"
                        className="input_file"
                        ref={uploadInput}
                        onChange={handleUpload}
                        accept="image/*, .pdf, .doc"
                        multiple={true}
                      />
                      <button
                        type="button"
                        onClick={() => uploadInput.current && uploadInput.current.click()}
                        // disabled={props?.files?.length > 4}
                        className={`btn_upload com_btn line s`}>
                        {"이미지 찾기"}
                      </button>
                    </div>
                    {imageSeq !== 0 && (
                      <>
                        <p className="img_box">
                          <img src={getImageLink(imageSeq)} className="img_upload" alt="upload image" />
                          <button type="button" className="btn_del" onClick={() => setImageSeq(0)}></button>
                        </p>
                        <span className="txt">공고 배경.jpg</span>
                      </>
                    )}

                    <span className="txt">170px * 170px 사이즈로 이미지를 올려주세요.</span>
                  </div>
                </div>
                <input hidden {...register("image_seq", { required: true })} />
                {errors.image_seq && (
                  <span style={{ color: "red", fontSize: "11px", paddingTop: "3px" }}>이미지가 필요합니다.</span>
                )}
              </div>
            </li>
            {/* Title Field */}
            <li className="field_box">
              <span className="tit">공고 제목</span>
              <div className="input_box">
                <input
                  type="text"
                  defaultValue={recruit["recruit_title"]}
                  {...register("recruit_title", { required: true })}
                  className="input"
                />
              </div>
            </li>
            {/* Tags Field */}
            <li className="field_box">
              <span className="tit">공고 태그</span>
              <div className="input_box">
                <button onClick={() => setOpenCreateTag(true)} className="com_btn line s">
                  태그 추가
                </button>
                <ul className="com_tag_list">
                  {recruitTags.map(
                    (tag, i) => tag && <Tag handleTagRemove={() => handleAnnouncementTagRemove(i)} key={i} tag={tag} />,
                  )}
                </ul>
              </div>
            </li>
            {/* Employment Type Field */}
            <li className="field_box">
              <span className="tit">고용형태</span>
              <div className="input_box">
                <select
                  className="select s"
                  defaultValue={recruit["emp_type"]}
                  {...register("emp_type", { required: true })}>
                  {employmentTypes.map((emp_type, i) => (
                    <option value={emp_type["comm_code"]} key={i}>
                      {emp_type["code_nm_kr"]}
                    </option>
                  ))}
                </select>
              </div>
            </li>
            {/* Posting Date Fields */}
            <li className="field_box">
              <span className="tit">공고기간</span>
              <div className="input_box">
                <div className="input_wrap date">
                  <article className="date_select type_input">
                    <input
                      type="date"
                      min={new Date(recruit["open_date"]).toISOString().split("T")[0]}
                      className="com_datepicker"
                      defaultValue={new Date(recruit["open_date"]).toISOString().split("T")[0]}
                      {...register("open_date", {
                        required: true,
                        onChange: (e) => setMinCloseDate(new Date(e.target.value).toISOString().split("T")[0]),
                        // setValueAs: (v) => new Date(v).toISOString().split("T")[0],
                      })}
                    />
                    {showCloseDate && (
                      <>
                        <span className="icon">~</span>
                        <input
                          type="date"
                          min={minCloseDate}
                          className="com_datepicker"
                          defaultValue={defaultCloseDate
                          }
                          {...register("close_date", { required: watch("PERIOD") === "false" ? true : (watch("ANY") === "false" || watch("UNTI") === "false") ? false : true})}
                          disabled={watch("ANY") === true || watch("UNTI") === true || watch("ANY") === "false" || watch("UNTI") === "false"}
                        />
                        {recruit?.PERIOD && errors.close_date && (
                  <div style={{ color: "red", marginLeft: "14%"}}>{"마감일을 입력하세요."}</div>
                )}
                      </>
                    )}
                  </article>
                  <div className="chk_wrap">
                    {recruitTypes.map((type, i) => (
                      <div key={i}>
                        <input
                          type="checkbox"
                          id={type["comm_code"]}
                          className="com_chk"
                          defaultChecked={recruit["close_type"] == type["comm_code"]}
                          value={getValues(type["comm_code"])}
                          {...register(type["comm_code"], {
                            onChange: (e) => {
                              clearErrors("recruitType");
                              handleCheckbox(type["comm_code"]);
                              recruitTypes.map((otherType) => {
                                if (e.target.checked && type["comm_code"] !== otherType["comm_code"]) {
                                  setValue(otherType["comm_code"], false);
                                }
                                if (type["comm_code"] === "ANY" || type["comm_code"] === "UNTI") {
                                  setIsDateInputEnabled(false); // 마감일 입력 비활성화
                                } else {
                                  setIsDateInputEnabled(true);
                                }
                              });
                            },
                            setValueAs: (v) => Boolean(v),
                          })}
                        />
                        <label className="label" htmlFor={type["comm_code"]}>
                          {type["code_nm_kr"]}
                        </label>
                      </div>
                    ))}
                    {errors.recruitType && (
                      <span style={{ color: "red", fontSize: "11px", paddingTop: "3px" }}>
                        채용 유형을 선택해야합니다
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </li>
            {/* Job Category Fields */}
            <li className="field_box">
              <span className="tit">직무</span>
              <div className="input_box">
                <div className="input_wrap">
                  <select
                    className="select s"
                    onChange={(e) => {
                      setParentCategory(Number(e.target.value));
                    }}>
                    {categoryTypes.map(
                      (category, i) =>
                        category &&
                        category["parent_seq"] === 0 && (
                          <option value={category["cate_seq"]} key={i}>
                            {category["cate_name"]}
                          </option>
                        ),
                    )}
                  </select>
                  <select className="select s" onChange={(e) => handleAddCategory(Number(e.target.value))}>
                    {categoryTypes.map((category, i) => {
                      return (
                        category &&
                        category["parent_seq"] === parentCategory && (
                          <option value={category["cate_seq"]} key={i}>
                            {category["cate_name"]}
                          </option>
                        )
                      );
                    })}
                  </select>
                </div>
                <span className="txt">직무는 최대 3개까지 선택 가능합니다.</span>
                <ul className="com_tag_list select_list">
                  {selectedCategories.map(
                    (tag, i) =>
                      getCateText(tag) && (
                        <Tag handleTagRemove={() => handleCategoryTagRemove(i)} key={i} tag={getCateText(tag)} />
                      ),
                  )}
                </ul>
              </div>
            </li>
            {/* Career Requirements Field */}
            <li className="field_box">
              <span className="tit">경력</span>
              <div className="input_box">
                <div className="input_wrap ">
                  <input
                    type="number"
                    className="input input_carrer"
                    defaultValue={recruit["career_min"]}
                    {...register("career_min", {
                      required: true,
                    })}
                  />
                  <span className="icon">~</span>
                  <input
                    type="number"
                    className="input input_carrer"
                    defaultValue={recruit["career_max"]}
                    {...register("career_max", {
                      required: true,
                      valueAsNumber: true,
                      validate: (v) => v >= getValues("career_min"),
                    })}
                  />
                  <span className="icon">년</span>
                  <div className="chk_wrap">
                    <input
                      type="checkbox"
                      id="career"
                      className="com_chk"
                      defaultChecked={recruit["newcomer_YN"] == "Y" ? true : false}
                      value={getValues("newcomer_YN")}
                      {...register("newcomer_YN", { setValueAs: (v) => (v ? "Y" : "N") })}
                    />
                    <label className="label" htmlFor="career">
                      신입
                    </label>
                  </div>
                </div>
              </div>
            </li>
            {/* Job Location Field */}
            <li className="field_box">
              <span className="tit">근무지역</span>
              <div className="input_box">
                <div className="input_wrap">
                  <input
                    type="text"
                    placeholder="서울"
                    className="input"
                    style={{ maxWidth: "20%" }}
                    disabled
                    defaultValue={
                      locationTypeData.find((location) => location.comm_code === recruit.recruit_location)["code_nm_kr"]
                    }
                    value={jobLocation ? jobLocation.code_nm_kr : ""}
                    {...register("job_location", { required: true })}
                  />
                  <button
                    className="com_btn line s"
                    onClick={() => {
                      setShowAddressSelect(true);
                      setJobLocation("");
                    }}>
                    지역선택
                  </button>
                </div>
              </div>
            </li>
            <li className="field_box">
              <span className="tit">주요업무</span>
              <div className="input_box">
                <textarea
                  className="textarea"
                  placeholder="내용입력"
                  defaultValue={recruit["recruit_job"]}
                  {...register("recruit_job", { required: true })}></textarea>
              </div>
            </li>
            <li className="field_box">
              <span className="tit">자격요건</span>
              <div className="input_box">
                <textarea
                  className="textarea"
                  placeholder="내용입력"
                  defaultValue={recruit["recruit_qualify"]}
                  {...register("recruit_qualify", { required: true })}></textarea>
              </div>
            </li>
            <li className="field_box">
              <span className="tit">우대사항</span>
              <div className="input_box">
                <textarea
                  className="textarea"
                  placeholder="내용입력"
                  defaultValue={recruit["recruit_consider"]}
                  {...register("recruit_consider", { required: true })}></textarea>
              </div>
            </li>
            <li className="field_box">
              <span className="tit">혜택 및 복지</span>
              <div className="input_box">
                <textarea
                  className="textarea"
                  placeholder="내용입력"
                  defaultValue={recruit["recruit_benefit"]}
                  {...register("recruit_benefit", { required: true })}></textarea>
              </div>
            </li>
          </ul>
          <div className="com_btn_wrap center">
            <button
              className="com_btn blue s"
              onClick={() => {
                trigger();
                setModalWriteOpen(true);
              }}
              type="button">
              공고 수정
            </button>
            <button className="com_btn line blue s" type="button" onClick={() => navigate(-1)}>
              취소
            </button>
          </div>{" "}
          <PopCreateTag
            open={openCreateTag}
            close={() => setOpenCreateTag(false)}
            currentTags={recruitTags}
            addTag={handleAddAnnouncementTag}
            handleTagRemove={handleAnnouncementTagRemove}
          />
          <Modal open={modalWriteOpen} close={handleCloseWriteModal} type="type_alert">
            <div className="content_container scroll">
              <strong>공고를 수정 하시겠습니까?</strong>
            </div>
            <section className="btn_container">
              <button className="com_btn blue s" type="submit">
                확인 {formSubmitting && <i className="gg-spinner-alt" />}
              </button>
              <button className="com_btn line blue s" onClick={() => handleCloseWriteModal()}>
                취소
              </button>
            </section>
          </Modal>
          <Modal open={showAddressSelect} close={() => setShowAddressSelect(false)} type="pop_desired_work" popSize="s">
            <h2 className="pop_tit type_center">희망근무지 추가</h2>
            <div className="content_container">
              <div className="list">
                {locationTypeData?.map((option) => (
                  <React.Fragment key={option.comm_code}>
                    <input
                      type="checkbox"
                      className="com_chk type_btn"
                      id={option.comm_code}
                      checked={jobLocation.comm_code == option.comm_code}
                      onChange={() => setJobLocation(option)}
                    />
                    <label htmlFor={option.comm_code}>{option.code_nm_kr}</label>
                  </React.Fragment>
                ))}
              </div>
              <section className="com_btn_wrap">
                <button className="com_btn blue s" type="button" onClick={() => setShowAddressSelect(false)}>
                  추가
                </button>
                <button className="com_btn line blue s" type="button" onClick={() => setShowAddressSelect(false)}>
                  취소
                </button>
              </section>
            </div>
          </Modal>
        </form>
      </div>
      <ScrollToTopButton />
    </>
  );
}
