import React, { useContext } from "react";
import SnsFacebook from "../../assets/img/login_sns_facebook.png";
import { AuthContext } from "../../../../../context/Auth";
import { useNavigate } from "react-router-dom";
import FacebookLogin from "@greatsumini/react-facebook-login";

export default function FaceLogin({ formType, FormType }) {
  const navigate = useNavigate();

  const { snslogin } = useContext(AuthContext);
  // const FACEBOOK_ID= process.env.REACT_APP_FACEBOOK_ID;
  return (
    <>
      <FacebookLogin
        appId="1264967497542525"
        onSuccess={async (response) => {
          console.log("Login Success!", response);
          //백엔드로 토큰값 보내기
          const result = await snslogin({ credentialResponse: response, type: "facebook", userType: formType === FormType.General ? "PSN" : "COM"  });
          //로그인 및 회원 가입 분기
          if (result === true) {
            const redirectPath = sessionStorage.getItem("redirectPath");
            if (redirectPath) {
              if (redirectPath.toLocaleLowerCase().startsWith("/company") && formType === FormType.General)
                navigate("/");
              else if (redirectPath.toLocaleLowerCase().startsWith("/mypage") && formType === FormType.Corporate)
                navigate("/");
              else navigate(redirectPath);

              sessionStorage.removeItem("redirectPath");
            } else {
              navigate("/");
            }
          } else {
            navigate(`/member/join?snstype=facebook&email=${result.email}&cid=${result.cid}`);
          }
        }}
        onFail={(error) => {
          console.log("Login Failed!", error);
        }}
        onProfileSuccess={(response) => {
          console.log("Get Profile Success!", response);
        }}
        render={({ onClick }) => (
          <button type="button" onClick={onClick} className="btn">
            <img src={SnsFacebook} alt="facebook" />
          </button>
        )}
      />
    </>
  );
}
