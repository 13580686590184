import React, { useEffect, useState } from "react";
import Modal from "../modal";
import { mutateBackend, mutateBackendFormData } from "../../../../../api";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useCustomQuery } from "../../../../../hooks/useAPI";

function PopAttachFile(props) {
  const { open, close, refetch } = props;
  const [fileUploadOption, setUploadOption] = useState("file_upload");
  const [url, setUrl] = useState("https://");
  const [formSubmitting, setFormSubmitting] = useState(false);

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    getValues
  } = useForm({
    mode: "onChange",
  });

  console.log("-> ", errors);

  const fileSeq = getValues("link_val");
  const handleFileUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_ty", "resume-writing");

    const response = await mutateBackendFormData("/upload/file", formData);

    if (response.ok) {
      const { data } = await response.json();
      setValue("link_val", data[0].up_file_seq);
    } else {
      console.log("File upload failed");
    }
  };

  useEffect(() => {
    if (url && isNaN(fileSeq)) {
      setValue("link_val", url);
    }
  }, [url, fileSeq]);

  const { data: linkCategories } = useCustomQuery(`/comm/list?code_gr=LINK_CATE`, {
    queryKey: ["selectLinkCategories"],
    select: (res) => res?.data?.dataList,
  });

  const onValidSubmit = async (data) => {
    console.log("----->> ", data);

    setFormSubmitting(true);

    try {
      const responseData = await mutateBackend("/resume-link/create", {
        data: {
          ...data,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      const response = await responseData.json();

      if (response.messageCode === 200) {
        toast.success("첨부 파일이 성공적으로 생성되었습니다!");
        setUrl("https://");
        refetch();
        close();
      }
    } catch (error) {
      toast.error("뭔가 잘못되었습니다! 다시 시도하십시오!");
    }

    setFormSubmitting(false);
  };

  return (
    <Modal open={open} close={close} type="pop_attachment">
      <div className="pop_tit">첨부파일 업로드</div>
      <div className="content_container scroll">
        <form className="box" onSubmit={handleSubmit(onValidSubmit)}>
          <ul className="com_input_sec">
            <li className="tit_field">파일 제목</li>
            <li className="input_field">
              <input type="text" placeholder="파일제목을 입력해주세요" {...register("link_title")} />
            </li>
            <li className="tit_field">종류 구분</li>
            <li className="input_field">
              <select name="종류 선택" id="종류 선택" {...register("link_cate")}>
                <option value="-1">종류 선택</option>
                {linkCategories?.map((cate) => {
                  return (
                    <option key={cate.comm_code} value={cate.comm_code}>
                      {cate.code_nm_kr}
                    </option>
                  );
                })}
              </select>
            </li>
            <li className="tit_field">
              <input
                type="radio"
                name="file_type"
                id="file_upload"
                value="file"
                checked={fileUploadOption === "file_upload"}
                className="com_chk circle"
                {...register("link_type")}
                onChange={() => {
                  setUploadOption("file_upload");
                }}
              />
              <label htmlFor="file_upload" className="circle_radio">
                파일업로드
              </label>
              <input
                type="radio"
                name="file_type"
                id="url"
                value="url"
                className="com_chk circle"
                {...register("link_type")}
                onChange={() => {
                  setUploadOption("url");
                }}
              />
              <label htmlFor="url" className="circle_radio">
                URL 등록
              </label>
            </li>
            <li className="tit_field">파일 첨부</li>
            <li className="input_field">
              {fileUploadOption === "file_upload" ? (
                <div className="wrapper">
                  <input
                    type="file"
                    className="file"
                    name="file"
                    id="file"
                    onChange={(e) => handleFileUpload(e.target.files[0])}
                  />
                  <label htmlFor="file" className="com_btn line blue s">
                    파일 추가
                  </label>
                </div>
              ) : (
                <input
                  type="text"
                  placeholder="파일제목을 입력해주세요"
                  onChange={(e) => setUrl(e.target.value)}
                  defaultValue={url}
                />
              )}
            </li>
          </ul>
          <section className="btn_container">
            <button className="com_btn blue s" type="submit">
              등록 {formSubmitting && <i className="gg-spinner-alt" />}{" "}
            </button>
            <button type="button" className="com_btn line blue s" onClick={close}>
              취소
            </button>
          </section>
        </form>
      </div>

    </Modal>
  );
}

export default PopAttachFile;
