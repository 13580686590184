import React, { useCallback, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

//component
import { useCustomQuery } from "../../../../../hooks/useAPI";
import { getImageLink } from "../../../../../utils/misc";
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import JobItem from "../match/_JobItem";
import CompanyInfo from "./_CompanyInfo";
import useCommCode from "../../../../../hooks/useCommCode";
import { AuthContext } from "../../../../../context/Auth";

export default function JobDetail() {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { data: employmentTypes } = useCommCode("EMP_TYPE");

  const [recruitList, setRecruitList] = useState([]);
  const { recruit_seq } = useParams();
  const [perPage, setPerPage] = useState(4);

  const { data: recruit, isFetching } = useCustomQuery(
    `/recruit/list?curr_page=1&per_page=1&recruit_seq=${recruit_seq}`,
    {
      queryKey: ["getRecruit", recruit_seq],
      select: (res) => res?.data?.data_list[0],
      enabled: !!recruit_seq,
      refetchOnWindowFocus: false,
    },
  );

  const { isFetching: recruitListFetching } = useCustomQuery(`/recruit/list?per_page=${perPage}&curr_page=${1}&comp_seq=${recruit?.comp_seq}`, {
    queryKey: ["getRecruitList", recruit?.comp_seq, perPage],
    select: (res) => res?.data,
    onSuccess: (data) => {
      setRecruitList(data.data_list);
    },
    refetchOnWindowFocus: false,
  });

  const [tabState, setTabState] = useState(0);
  const tab = [
    { text: "주요 업무", value: 0 },
    { text: "회사 소개", value: 1 },
  ];
  const renderTab = useCallback(() => {
    return tab.map((v, idx) => {
      return (
        <button
          key={idx}
          className={`tab ${(tabState === v.value && "active") || ""}`}
          onClick={() => setTabState(v.value)}>
          {v.text}
        </button>
      );
    });
  }, [tab]);

  if (isFetching || !recruit)
    return (
      <>
        <div className="container">
          {/* company info */}
          <div className="com_top_box">
            <i className="gg-spinner-big" />
          </div>
        </div>
      </>
    );

  return (
    <>
      <div className="container">
        {/* company info */}
        <div className="com_top_box">
          <CompanyInfo data={recruit} />
        </div>
        <hr className="divider" />
        <div className="com_pg_tab type_detail">{renderTab()}</div>
        {(tabState === 0 && (
          <div className="tab_content">
            <ul className="com_tag_list">
              {recruit["recruit_tags"] &&
                recruit["recruit_tags"] !== "" &&
                recruit["recruit_tags"].split(",").map((tag, i) => (
                  <li className="com_btn lightblue" key={i}>
                    #{tag}
                  </li>
                ))}
            </ul>
            <div className="detail_wrap">
              <img src={getImageLink(recruit["image_seq"])} alt="" />
              <p className="tit">고용형태</p>
              <p className="txt">{recruit?.emp_type_name}</p>
              <p className="tit">직무</p>
              <p className="txt">{recruit?.recruit_categories_name}</p>
              <p className="tit">경력</p>
              <p className="txt">{recruit?.newcomer_YN === "Y" ? "신입" : `경력 ${recruit?.career_min} ~ ${recruit?.career_max}년`}</p>
              <p className="tit">근무지역</p>
              <p className="txt">{recruit?.location}</p>
              <p className="tit">주요업무</p>
              <pre className="txt">{recruit?.recruit_job}</pre>
              <p className="tit">자격요건</p>
              <pre className="txt">{recruit?.recruit_qualify}</pre>
              <p className="tit">우대사항</p>
              <pre className="txt">{recruit?.recruit_consider}</pre>
              <p className="tit">혜택 및 복지</p>
              <pre className="txt">{recruit?.recruit_benefit}</pre>
            </div>
            <hr className="divider" />
          </div>
        )) ||
          (tabState === 1 && (
            <div className="tab_content">
              <div className="detail_wrap">
                {/* <img src={getImageLink(recruit["comp_image_seqs"].split(",")[0])} alt="" /> */}
                <ul className="com_tag_list">
                  {recruit?.recruit_tags &&
                    recruit?.recruit_tags?.split(",")?.map((tag, i) => (
                      <li key={i} className="com_btn lightblue">
                        #{tag}
                      </li>
                    ))}
                </ul>
                <p className="tit">회사소개</p>
                {user.comp_image_seqs &&
                  user.comp_image_seqs.split(",").map((imageSeq, index) => (
                    <img
                      key={index}
                      src={getImageLink(Number(imageSeq))}
                      alt={`기업로고-${index}`}
                    />
                  ))}
                <pre className="txt">{recruit["comp_intro"]}</pre>
              </div>
              <hr className="divider" />
              {/* job list */}
              {!recruitListFetching && (
                <div className="job_wrap">
                  <div className="pg_tit_wrap">
                    <h2 className="com_pg_tit">채용공고</h2>
                  </div>
                  <div className="list_wrap">
                    <ul className="list">
                      {recruitList.map((item, index) => (
                        <JobItem
                          key={index}
                          recruitItem={item}
                          employmentTypes={employmentTypes ? employmentTypes : []}
                        />
                      ))}
                    </ul>
                    <button className="btn_more_job arrow_right" onClick={() => {setPerPage("all")}}>더보기</button>
                  </div>
                </div>
              )}
            </div>
          ))}
        <div className="com_btn_wrap center">
          <button className="com_btn blue s" onClick={() => navigate(-1)}>
            채용 공고 목록으로
          </button>
          <button className="com_btn line blue s" onClick={() => navigate("/Company/Job/Detail/edit/" + recruit_seq)}>
            공고 수정
          </button>
        </div>
      </div>

      {/* top button */}
      <ScrollToTopButton />
    </>
  );
}
