import React, { useCallback, useContext, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { AuthContext } from "../../../../context/Auth";

export default function BofficeHeader() {
  const { logout } = useContext(AuthContext);

  const pathName = useLocation().pathname;
  const [isActive, setIsActive] = useState(false);
  const [gnbIdx, setGnbIdx] = useState(0);
  //const [subIdx, setSubIdx] = useState(0);
  //상단 Gnb
  const gnbData = [
    { title: "환경설정", link: "/boffice/setting/manager/main", cssActive: "/boffice/setting" }, // /boffice/setting 일 경우 active
    { title: "회원관리", link: "/boffice/member/manage/user", cssActive: "/boffice/member" },
    { title: "AI매칭", link: "/boffice/ai/resume", cssActive: "/boffice/ai" },
    { title: "멘토링", link: "/boffice/mentoring/cate", cssActive: "/boffice/mentoring" },
    { title: "인사이트", link: "/boffice/insight/cate", cssActive: "/boffice/insight" },
    { title: "게시판 관리", link: "/boffice/board/community/cate", cssActive: "/boffice/board" },
  ];
  //Left Sub Gnb Array
  const subSetting = [
    {
      title: "운영자 관리",
      link: "/boffice/setting/manager/main",
      cssActive: "/boffice/setting/manager/",
      depth: "depth1",
    },
    {
      title: "관리자 계정 관리",
      link: "/boffice/setting/manager/main",
      cssActive: "/boffice/setting/manager/main",
      depth: "depth2",
    },
    {
      title: "부관리자 계정 관리",
      link: "/boffice/setting/manager/list",
      cssActive: "/boffice/setting/manager/list",
      depth: "depth2",
    },
    {
      title: "디자인 관리",
      link: "/boffice/setting/design/slide",
      cssActive: "/boffice/setting/design/",
      depth: "depth1",
    },
    {
      title: "슬라이드 관리",
      link: "/boffice/setting/design/slide",
      cssActive: "/boffice/setting/design/slide",
      depth: "depth2",
    },
    {
      title: "팝업관리",
      link: "/boffice/setting/design/popup",
      cssActive: "/boffice/setting/design/popup",
      depth: "depth2",
    },
    {
      title: "배너 관리",
      link: "/boffice/setting/design/banner",
      cssActive: "/boffice/setting/design/banner",
      depth: "depth2",
    },
  ];
  const subMember = [
    { title: "회원관리", link: "/boffice/member/manage/user", cssActive: "/boffice/member/manage", depth: "depth1" },
    {
      title: "일반 회원 관리",
      link: "/boffice/member/manage/user",
      cssActive: "/boffice/member/manage/user",
      depth: "depth2",
    },
    {
      title: "기업 회원 관리",
      link: "/boffice/member/manage/company",
      cssActive: "/boffice/member/manage/company",
      depth: "depth2",
    },
    {
      title: "탈퇴 회원 관리",
      link: "/boffice/member/manage/leave",
      cssActive: "/boffice/member/manage/leave",
      depth: "depth2",
    },
    { title: "포인트관리", link: "/boffice/member/point/mange", cssActive: "/boffice/member/point", depth: "depth1" },
    {
      title: "이벤트 포인트 적립 관리",
      link: "/boffice/member/point/mange",
      cssActive: "/boffice/member/point/mange",
      depth: "depth2",
    },
    {
      title: "포인트 변동 내역",
      link: "/boffice/member/point/list",
      cssActive: "/boffice/member/point/list",
      depth: "depth2",
    },
    {
      title: "포인트 환전 관리",
      link: "/boffice/member/point/exchange",
      cssActive: "/boffice/member/point/exchange",
      depth: "depth2",
    },
  ];
  const subAi = [
    { title: "이력서 관리", link: "/boffice/ai/resume", cssActive: "/boffice/ai/resume", depth: "depth1" },
    { title: "공고 관리", link: "/boffice/ai/job/tag", cssActive: "/boffice/ai/job", depth: "depth1" },
    { title: "태그 관리", link: "/boffice/ai/job/tag", cssActive: "/boffice/ai/job/tag", depth: "depth2" },
    { title: "공고 이미지 관리", link: "/boffice/ai/job/img", cssActive: "/boffice/ai/job/img", depth: "depth2" },
    { title: "공고 내역", link: "/boffice/ai/job/list", cssActive: "/boffice/ai/job/list", depth: "depth2" },
    { title: "지원 관리", link: "/boffice/ai/apply", cssActive: "/boffice/ai/apply", depth: "depth1" },
    { title: "면접 관리", link: "/boffice/ai/interview", cssActive: "/boffice/ai/interview", depth: "depth1" },
  ];
  const subMentoring = [
    { title: "카테고리 관리", link: "/boffice/mentoring/cate", cssActive: "/boffice/mentoring/cate", depth: "depth1" },
    {
      title: "멘토링 관리",
      link: "/boffice/mentoring/manage/apply",
      cssActive: "/boffice/mentoring/manage",
      depth: "depth1",
    },
    {
      title: "멘토 신청 내역",
      link: "/boffice/mentoring/manage/apply",
      cssActive: "/boffice/mentoring/manage/apply",
      depth: "depth2",
    },
    {
      title: "멘토 회원 관리",
      link: "/boffice/mentoring/manage/member",
      cssActive: "/boffice/mentoring/manage/member",
      depth: "depth2",
    },
    {
      title: "멘토 상품 관리",
      link: "/boffice/mentoring/product/list",
      cssActive: "/boffice/mentoring/product",
      depth: "depth1",
    },
    {
      title: "상품 내역",
      link: "/boffice/mentoring/product/list",
      cssActive: "/boffice/mentoring/product/list",
      depth: "depth2",
    },
    {
      title: "상품 결제 내역",
      link: "/boffice/mentoring/product/pay",
      cssActive: "/boffice/mentoring/product/pay",
      depth: "depth2",
    },
    {
      title: "상품 환불 내역",
      link: "/boffice/mentoring/product/refund",
      cssActive: "/boffice/mentoring/product/refund",
      depth: "depth2",
    },
  ];
  const subInsight = [
    { title: "카테고리 관리", link: "/boffice/insight/cate", cssActive: "/boffice/insight/cate", depth: "depth1" },
    { title: "태그 관리", link: "/boffice/insight/tag", cssActive: "/boffice/insight/tag", depth: "depth1" },
    {
      title: "인사이트 관리",
      link: "/boffice/insight/manage/member",
      cssActive: "/boffice/insight/manage",
      depth: "depth1",
    },
    {
      title: "인사이트 신청 내역",
      link: "/boffice/insight/manage/apply",
      cssActive: "/boffice/insight/manage/apply",
      depth: "depth2",
    },
    {
      title: "인사이트 회원 관리",
      link: "/boffice/insight/manage/member",
      cssActive: "/boffice/insight/manage/member",
      depth: "depth2",
    },
    {
      title: "인사이트 상품 관리",
      link: "/boffice/insight/product/list",
      cssActive: "/boffice/insight/product",
      depth: "depth1",
    },
    {
      title: "상품 내역",
      link: "/boffice/insight/product/list",
      cssActive: "/boffice/insight/product/list",
      depth: "depth2",
    },
    {
      title: "상품 결제 내역",
      link: "/boffice/insight/product/pay",
      cssActive: "/boffice/insight/product/pay",
      depth: "depth2",
    },
    {
      title: "상품 환불 내역",
      link: "/boffice/insight/product/refund",
      cssActive: "/boffice/insight/product/refund",
      depth: "depth2",
    },
  ];
  const subBoard = [
    {
      title: "커뮤니티 관리",
      link: "/boffice/board/community/cate",
      cssActive: "/boffice/board/community/",
      depth: "depth1",
    },
    {
      title: "카테고리 관리",
      link: "/boffice/board/community/cate",
      cssActive: "/boffice/board/community/",
      depth: "depth2",
    },
    {
      title: "등급 관리",
      link: "/boffice/board/community/grade",
      cssActive: "/boffice/board/community/grade",
      depth: "depth2",
    },
    {
      title: "게시글 관리",
      link: "/boffice/board/community/list",
      cssActive: "/boffice/board/community/list",
      depth: "depth2",
    },

    {
      title: "매거진 관리",
      link: "/boffice/board/magazine/cate",
      cssActive: "/boffice/board/magazine",
      depth: "depth1",
    },
    {
      title: "카테고리 관리",
      link: "/boffice/board/magazine/cate",
      cssActive: "/boffice/board/magazine/cate",
      depth: "depth2",
    },
    {
      title: "매거진 내역",
      link: "/boffice/board/magazine/list",
      cssActive: "/boffice/board/magazine/list",
      depth: "depth2",
    },

    {
      title: "세미나 관리",
      link: "/boffice/board/seminar/list",
      cssActive: "/boffice/board/seminar/",
      depth: "depth1",
    },
    {
      title: "세미나 내역",
      link: "/boffice/board/seminar/list",
      cssActive: "/boffice/board/seminar/list",
      depth: "depth2",
    },
    {
      title: "세미나 신청 내역",
      link: "/boffice/board/seminar/application/list",
      cssActive: "/boffice/board/seminar/application/list",
      depth: "depth2",
    },
    {
      title: "세미나 환불 내역",
      link: "/boffice/board/seminar/refund-request/list",
      cssActive: "/boffice/board/seminar/refund-request/list",
      depth: "depth2",
    },

    {
      title: "1:1 매니징 서비스",
      link: "/boffice/board/managing",
      cssActive: "/boffice/board/managing",
      depth: "depth1",
    },

    { title: "고객센터", link: "/boffice/board/cs/manage", cssActive: "/boffice/board/cs", depth: "depth1" },
    { title: "구분 관리", link: "/boffice/board/cs/manage", cssActive: "/boffice/board/cs/manage", depth: "depth2" },
    { title: "자주 묻는 질문", link: "/boffice/board/cs/faq", cssActive: "/boffice/board/cs/faq", depth: "depth2" },
    { title: "문의 내역", link: "/boffice/board/cs/qna", cssActive: "/boffice/board/cs/qna", depth: "depth2" },
    { title: "신고관리", link: "/boffice/board/report", cssActive: "/boffice/board/report", depth: "depth1" },
  ];
  const renderGnb = useCallback(() => {
    return gnbData.map((v, idx) => {
      return (
        <NavLink
          to={v.link}
          key={idx}
          onClick={() => {
            setGnbIdx(idx + 1);
          }}
          // className={`link ${isGnbActive || gnbIdx === idx + 1 || pathName.includes(v.cssActive) ? "active" : ""}`}>
          className={`link ${pathName.startsWith(v.cssActive) ? "active" : ""}`}>
          {v.title}
        </NavLink>
      );
    });
  }, [gnbIdx, pathName]);

  const renderSub = useCallback(() => {
    let arr = [];
    if (pathName.startsWith("/boffice/login")) {
      return <></>;
    } else if (pathName.startsWith("/boffice/setting")) {
      arr = subSetting;
    } else if (pathName.startsWith("/boffice/member")) {
      arr = subMember;
    } else if (pathName.startsWith("/boffice/ai")) {
      arr = subAi;
    } else if (pathName.startsWith("/boffice/mentoring")) {
      arr = subMentoring;
    } else if (pathName.startsWith("/boffice/insight")) {
      arr = subInsight;
    } else if (pathName.startsWith("/boffice/board")) {
      arr = subBoard;
    } else {
      // arr = 'subSetting';
    }
    return (
      <div className="gnb_sub_wrap">
        <NavLink to="/" className="logo">
          <h1>엔지니어스</h1>
        </NavLink>
        <div className="list">
          {arr.map((v, idx) => {
            return (
              <NavLink
                to={v.link}
                key={idx}
                onClick={() => {
                  setIsActive(false);
                }}
                className={`link ${v.depth} ${isActive || pathName.includes(v.cssActive) ? "lnb_active" : ""}`}>
                {v.title}
              </NavLink>
            );
          })}
        </div>
      </div>
    );
  }, [pathName]);

  return (
    <header className={`adm_head  ${pathName.includes("/login") ? `isHideGnb` : ``}`}>
      <section className="gnb_wrap">
        <div className="adm_com_center_wrap">
          <section className="gnb_list">{renderGnb()}</section>
          <section className="menu_list">
            <NavLink
              to="/"
              className="link"
              onClick={() => {
                setGnbIdx(0);
              }}>
              관리자 정보
            </NavLink>
            <NavLink
              to="/"
              className="link"
              onClick={() => {
                setGnbIdx(0);
              }}>
              웹사이트이동
            </NavLink>
            <NavLink
              to="/boffice/login"
              className="link"
              onClick={() => {
                logout();
                setGnbIdx(0);
              }}>
              로그아웃
            </NavLink>
            <NavLink
              to="/Login"
              className="link"
              onClick={() => {
                setGnbIdx(0);
              }}>
              로그인
            </NavLink>
          </section>
        </div>
      </section>
      {renderSub()}
    </header>
  );
}
