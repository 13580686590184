import React, { useEffect } from "react";
import { useFieldArray } from "react-hook-form";

import IconClose from "../../assets/img/icon_close.png";

export default function Resume_academic({ control, register, errors, schoolTypeData, schoolStatusData, setValue, watch /*getValues,*/ }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "resume_academic",
  });

  // When there are multiple fields, deleting the middle field modifies the field number value.
  useEffect(() => {
    for (let i = 0; i < fields.length; i++) { //fields가 2개씩 생김
      setValue(`resume_academic[${i}].acad_no`, i + 1);
    }
  }, [fields]); 



 console.log(schoolStatusData);

  return (
    <>
      <li className="title_field">
        학력 <span className="required">*</span>
      </li>
        {(Object.keys(errors).length !== 0 && fields.length === 0 && watch("resume_info[resume_status]") ==="DONE") && <p style={{ color: "red" }}>{"학력을 추가해주세요."}</p>}
      <li className="education_wrapper">
        {fields?.map((academic, index) => (
          <div className="education_field bottom_line" key={academic.id}>
            <div className="university">
              <select
                className="input"
                name={`resume_academic[${index}]`}
                {...register(`resume_academic[${index}].acad_type`, { required: watch("resume_info.resume_status") === "DONE" && "학교를 선택해주세요." })}
                defaultValue={academic.acad_type}
              >
                <option value="">학교 선택</option>
                {schoolTypeData?.map((schType) => {
                  return (
                    <option key={schType.comm_code} value={schType.comm_code}>
                      {schType.code_nm_kr}
                    </option>
                  );
                })}
              </select>
              <input
                type="text"
                className="input"
                {...register(`resume_academic[${index}].acad_name`, {
                  required: watch("resume_info.resume_status") ==="DONE" && "학교명을 입력해주세요.",
                })}
                defaultValue={academic.acad_name || ""}
                placeholder="학교명을 입력해주세요."
              />
            </div>
            <input
              type="text"
              className="input department"
              {...register(`resume_academic[${index}].acad_major`, {
                required: watch("resume_info.resume_status") ==="DONE" && "전공을 입력해주세요.",
              })}
              defaultValue={academic.acad_major || ""}
              placeholder="전공을 입력해주세요."
            />
            <div className="date">
              <input
                type="date"
                className="input icon_calender"
                {...register(`resume_academic[${index}].entrance_date`, {
                  required: watch("resume_info.resume_status") ==="DONE" && "입학년월을 선택해주세요.",
                })}
                defaultValue={academic.entrance_date || ""}
                placeholder="입학년월"
                max={'9999-12-31'}
              />
              <input
                type="date"
                className="input icon_calender"
                {...register(`resume_academic[${index}].finish_date`, {
                  required: watch(`resume_academic[${index}][acad_status]`) === "SS01" || watch(`resume_academic[${index}][acad_status]`) === "SS04" ? watch("resume_info.resume_status") ==="DONE" &&  "졸업년월을 선택해주세요." : false,
                })}
                defaultValue={academic.finish_date || ""}
                placeholder="졸업년월"
                max={'9999-12-31'}
              />
              <select
                className="input status"
                {...register(`resume_academic[${index}].acad_status`, {
                  required: watch("resume_info?.resume_status", "DONE") && "학업 상태를 선택해주세요.",
                })}
                defaultValue={academic.acad_status}
              >
                <option value="">학업 상태</option>
                {schoolStatusData?.map((status) => {
                  return (
                    <option key={status.comm_code} value={status.comm_code}>
                      {status.code_nm_kr}
                    </option>
                  );
                })}
              </select>
              {index !== 0 && (
                <button style={{marginLeft: "-1%"}} className="btn_delete" type="button" onClick={() => remove(index)}>
                  <img src={IconClose} alt="close_icon" />
                </button>
              )}
            </div>
            {(watch("resume_info.resume_status") ==="DONE" && fields?.length > 0 && watch(`resume_academic[${index}][acad_type]`) === "") && (
              <p style={{ color: "red" }}>학교를 선택해주세요.</p>
            )}
            {(watch("resume_info.resume_status") ==="DONE" && fields?.length > 0 && watch(`resume_academic[${index}][acad_name]`) === "") && (
              <p style={{ color: "red" }}>학교명을 입력해주세요.</p>
            )}
            {(watch("resume_info.resume_status") ==="DONE" && fields?.length > 0 && watch(`resume_academic[${index}][acad_major]`) === "") && (
              <p style={{ color: "red" }}>전공을 입력해주세요.</p>
            )}
            {(watch("resume_info.resume_status") ==="DONE" && fields?.length > 0 && watch(`resume_academic[${index}][entrance_date]`) === "") && (
              <p style={{ color: "red" }}>입학년월을 선택해주세요.</p>
            )}
            {watch(`resume_academic[${index}][acad_status]`) === "SS01" || watch(`resume_academic[${index}][acad_status]`) === "SS04" ? (watch("resume_info.resume_status") ==="DONE" && fields?.length > 0 && watch(`resume_academic[${index}][finish_date]`) === "") && (
              <p style={{ color: "red" }}>졸업년월을 선택해주세요.</p>
            ) : null}
            {(watch("resume_info.resume_status") ==="DONE" && fields?.length > 0 && watch(`resume_academic[${index}][acad_status]`) === "") && (
              <p style={{ color: "red" }}>학업상태를 선택해주세요.</p>
            )}
          </div>
        ))}

        <button
          className="com_btn blue"
          type="button"
          onClick={() =>
            append({
              acad_no: fields.length + 1,
              acad_type: "",
              acad_name: "",
              acad_major: "",
              entrance_date: "",
              finish_date: "",
              acad_status: "",
            })
          }>
          <span className="addition">+</span> 추가
        </button>
      </li>
    </>
  );
}
