import React from "react";
import Modal from "../../components/modal";

function PopSuggestionResume(props) {
  const { open, close } = props;
  return (
    <Modal open={open} close={close} type="pop_recruit">
      <h2 className="pop_tit type_center">면접제안</h2>
      <div className="content_container scroll ">
        <p className="notice com_point_txt">
          공고제목 노출
        </p>
        <div className="detail_txt">
        ***님의 역량이 ㈜삼성 전기 엔지니어 포지션에 적합하다고 판단되어 입사 제안을 합니다. <br />
          <br />
          이 제안은 22.11.17까지 유효합니다.
          <br />
          <br />
          (주) 삼성은 국내 최고의 대기업이며, 꾸준한 연구개발 투자와 고객만족 경영, 마케팅을 바탕으로 세계적인 기업으로
          성장해왔습니다.
          <br />
          <br />
          세계적인 기업으로 성장할 함께할 당신을 기다리겠습니다. 
          
        </div>
        <ul className="com_input_sec">
          <li className="tit_field">면접일시</li>
          <li className="input_field"><input type="text" className="input" /></li>
          <li className="tit_field">면접장소</li>
          <li className="input_field"><input type="text" className="input" /></li>
        </ul>
      </div>
      {props.children}
    </Modal>
  );
}

export default PopSuggestionResume;
