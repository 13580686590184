import React, { createContext, useEffect, useState } from "react";
import { mutateBackend, queryBackend } from "../api";

export const AuthContext = createContext({
  adminLogin: (admin_username, admin_pwd) => Promise.resolve(false && admin_username && admin_pwd),
  login: (user_id, user_pwd, user_type) => Promise.resolve(false && user_id && user_pwd && user_type),
  snslogin: (credentialResponse) => Promise.resolve(false && credentialResponse),
  logout: () => {},
  isAuthenticated: sessionStorage.getItem("accessToken") ? true : false,
  isAuthenticatedAdmin: sessionStorage.getItem("adminAccessToken") ? true : false,
  user: {},
  adminUser: {},
  updateAdminUser: (data) => data,
  refetchUserInfo: async () => {},
});

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(sessionStorage.getItem("accessToken") ? true : false);
  const [isAuthenticatedAdmin, setIsAuthenticatedAdmin] = useState(
    sessionStorage.getItem("adminAccessToken") ? true : false,
  );
  const [user, setUser] = useState({});
  const [adminUser, setAdminUser] = useState({});

  const login = async (user_id, user_pwd, user_type) => {
    try {
      removeSession();
      const response = await mutateBackend("/user/auth/login", {
        data: { user_id, user_pwd, user_type },
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
      }).then(async (data) => data.json());

      if (response.messageCode === 200) {
        sessionStorage.setItem("accessToken", response.data.accessToken);
        sessionStorage.setItem("refreshToken", response.data.refreshToken);
        sessionStorage.setItem("expiresAt", response.data.expiresAt);

        let userDataResponse = await queryBackend("/user/my-info").then(
          async (data) => await data.json().then((data) => data.data.data_list[0]),
        );

        if (userDataResponse.user_type == "COM") {
          const compDataResponse = await queryBackend("/user/company-info").then(
            async (data) => await data.json().then((data) => data.data.data_list[0]),
          );
          userDataResponse = { ...userDataResponse, ...compDataResponse };
        }
        updateUser(userDataResponse);

        setIsAuthenticated(true);
        return true;
      } else {
        console.error("Authentication failed:", response.message);
        return false;
      }
    } catch (error) {
      console.error("Authentication failed:", error);
      throw new Error("Authentication failed");
    }
  };

  const snslogin = async (credentialResponse) => {
    try {
      removeSession();
      const response = await mutateBackend("/user/sns/login", {
        data: {credentialResponse},
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
      }).then(async (data) => data.json());

      if (response.messageCode === 200) {
        sessionStorage.setItem("accessToken", response.data.accessToken);
        sessionStorage.setItem("refreshToken", response.data.refreshToken);
        sessionStorage.setItem("expiresAt", response.data.expiresAt);

        let userDataResponse = await queryBackend("/user/my-info").then(
          async (data) => await data.json().then((data) => data.data.data_list[0]),
        );

        if (userDataResponse.user_type == "COM") {
          const compDataResponse = await queryBackend("/user/company-info").then(
            async (data) => await data.json().then((data) => data.data.data_list[0]),
          );
          userDataResponse = { ...userDataResponse, ...compDataResponse };
        }
        updateUser(userDataResponse);

        setIsAuthenticated(true);
        return true;
      } else {
        return response.data;
      }
    } catch (error) {
      console.error("Authentication failed:", error);
      throw new Error("Authentication failed");
    }
  };

  const adminLogin = async (admin_id, admin_pwd) => {
    try {
      removeSession();
      const response = await mutateBackend("/admin/auth/login", {
        data: { admin_id, admin_pwd },
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
      }).then(async (data) => data.json());

      if (response.messageCode === 200) {
        sessionStorage.setItem("adminAccessToken", response.data.accessToken);
        sessionStorage.setItem("adminRefreshToken", response.data.refreshToken);
        sessionStorage.setItem("expiresAt", response.data.expiresAt);

        updateAdminUser(response.data.user);

        setIsAuthenticatedAdmin(true);
        return true;
      } else {
        console.error("Authentication failed:", response.message);
        return false;
      }
    } catch (error) {
      console.error("Authentication failed:", error);
      throw new Error("Authentication failed");
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    setIsAuthenticatedAdmin(false);
    setAdminUser({});
    setUser({});
    sessionStorage.clear();
  };

  const updateUser = (newInfo) => {
    const prevUser = user;
    setUser((prev) => ({ ...prev, ...newInfo }));
    sessionStorage.setItem("user", JSON.stringify({ ...prevUser, ...newInfo }));
  };

  const updateAdminUser = (user) => {
    setAdminUser((prev) => ({ ...prev, ...user }));
    sessionStorage.setItem("adminUser", JSON.stringify(adminUser));
  };

  const refetchUserInfo = async () => {
    let userDataResponse = await queryBackend("/user/my-info").then(
      async (data) => await data.json().then((data) => data.data.data_list[0]),
    );

    if (userDataResponse.user_type == "COM") {
      const compDataResponse = await queryBackend("/user/company-info").then(
        async (data) => await data.json().then((data) => data.data.data_list[0]),
      );
      userDataResponse = { ...userDataResponse, ...compDataResponse };
    }

    updateUser(userDataResponse);
  };

  useEffect(() => {
    setIsAuthenticated(sessionStorage.getItem("accessToken") ? true : false);
    setIsAuthenticatedAdmin(sessionStorage.getItem("adminAccessToken") ? true : false);
    const userData = sessionStorage.getItem("user");
    userData && setUser(JSON.parse(userData));
    const adminUserData = sessionStorage.getItem("adminUser");
    adminUserData && setAdminUser(JSON.parse(adminUserData));
  }, []);

  //로그인 시 세션 스토리지 삭제
  const removeSession =() =>{
    setIsAuthenticated(false);
    setIsAuthenticatedAdmin(false);
    setAdminUser({});
    setUser({});
    sessionStorage.clear();
  }

  return (
    <AuthContext.Provider
      value={{
        adminLogin,
        login,
        logout,
        isAuthenticated,
        isAuthenticatedAdmin,
        user,
        adminUser,
        refetchUserInfo,
        updateAdminUser,
        snslogin,
      }}>
      {children}
    </AuthContext.Provider>
  );
}
