import React, { useState } from "react";
import { useFieldArray } from "react-hook-form";
import IconClose from "../../assets/img/icon_close.png";

import PopAddUrl from "../../components/myPage/_PopAddUrl1";
import PopAddFile from "../../components/myPage/_PopAddFile";
import PopAddUrl2 from "../../components/myPage/_PopAddUrl2";
import { mutateBackendFormData } from "../../../../../api";
import { getFileLink } from "../../../../../utils/misc";
export default function Resume_links({ register, control, linkTypeData, errors, getValues, watch }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "link_url",
  });

  const [addUrlModal, setAddUrlModal] = useState(false);
  const [addUrl2Modal, setAddUrl2Modal] = useState(false);
  const [addFileModal, setAddFileModal] = useState(false);

  const handleFileUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_ty", "resume-writing");

    const response = await mutateBackendFormData("/upload/file", formData);

    if (response.ok) {
      const data = await response.json();
      console.log("handleFileUpload", data);

      const uploadedFile = {
        link_type: "FILE",
        link_val: data.data[0].up_file_seq,
        link_cate: -1,
      };

      append(uploadedFile);
    } else {
      console.log("File upload failed");
    }
  };

  const handleUrlAdd = (url) => {
    const newUrl = {
      link_type: "LINK",
      link_val: url,
      link_cate: "",
    };
    append(newUrl);
    setAddUrlModal(false);
  };

  const handleAttach = (e, selectedAttachments) => {
    e.preventDefault();
    console.log("_)(*&^%$#@ ", selectedAttachments);
    selectedAttachments.map((attachment) => {
      console.log(attachment);
      append(attachment);
    });
  };


  return (
    <>
      <li className="title_field link_tit">
        포트폴리오<span className="required">*</span>
        <button
          type="button"
          className="right"
          onClick={() => {
            setAddUrl2Modal(true);
          }}>
          내 첨부파일
        </button>
      </li>
      <li className="link_wrapper">
        {fields.map((field, linkIndex) => (
          <div className="link_field" key={field.id} style={{ gap: "20px" }}>
            {/* <input type="text" className="input" {...register(`links[${linkIndex}].link_val`)} /> */}
            <select
              className="select"
              {...register(`link_url[${linkIndex}].link_cate`, {
                required: watch("resume_info.resume_status") === "DONE" && "포트폴리오 종류를 선택해 주세요",
              })}>
              <option value="">종류</option>
              {linkTypeData?.map((link) => {
                return (
                  <option key={link.comm_code} value={link.comm_code}>
                    {link.code_nm_kr}
                  </option>
                );
              })}
            </select>
            {field.link_type.toUpperCase() === "FILE" ? (
              <a href={getFileLink(field.link_val)}>{field.file_real_name ? field.file_real_name : field.link_val}</a>
            ) : (
              <a href={field.link_val} target="_blank" rel="noreferrer">
                {field.file_real_name ? field.file_real_name : field.link_val}
              </a>
            )}
            <button className="btn_delete" type="button" onClick={() => remove(linkIndex)}>
              <img src={IconClose} alt="close_icon" />
            </button>
            {errors.link_url && errors.link_url[linkIndex]?.link_cate && (
              <li style={{ color: "red" }}>{errors.link_url[linkIndex]?.link_cate?.message}</li>
            )}
          </div>

        ))}
        {(Object.keys(errors).length !== 0 && watch("resume_info.resume_status") === "DONE" && getValues('link_url').length === 0) && (
          <p style={{ color: "red" }}>링크를 첨부해주세요</p>
        )}
        <button
          className="com_btn blue"
          onClick={(e) => {
            e.preventDefault();
            setAddUrlModal(true);
          }}>
          <span className="addition">+</span>URL 추가
        </button>
        <button
          className="com_btn blue"
          onClick={(e) => {
            e.preventDefault();
            setAddFileModal(true);
          }}>
          <span className="addition">+</span>파일추가
        </button>
      </li>

      {/* Modals */}
      <PopAddUrl open={addUrlModal} close={() => setAddUrlModal(false)} handleUrlAdd={handleUrlAdd} />
      <PopAddUrl2
        open={addUrl2Modal}
        close={() => setAddUrl2Modal(false)}
        handleAttach={handleAttach}
        fields={fields}
      />
      <PopAddFile open={addFileModal} close={() => setAddFileModal(false)} handleFileAdd={handleFileUpload} />
    </>
  );
}
