import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../assets/scss/page/member.scss";
//component
import Pagination from "../../components/Pagination";
import Modal from "../../components/modal";
import PopAttachFile from "../../components/myPage/_PopAttachFile";
import { deleteBackend } from "../../../../../api";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import { toast } from "react-toastify";

function Attachment() {
  const [uploadFileModal, setUploadFileModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [per_page, setPerPage] = useState(9);
  const [curr_page, setCurrPage] = useState(1);
  const [selectedAtt, setSelectedAtt] = useState(null);

  const {
    data: attachments,
    isFetching: isFetchingAttachments,
    refetch,
  } = useCustomQuery(`/resume-link`, {
    queryKey: ["getAttachments"],
    select: (res) => res?.data,
    refetchOnWindowFocus: false,
  });

  const handleNextClick = () => {
    if (curr_page < Math.ceil(attachments?.total_count / per_page)) {
      setCurrPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevClick = () => {
    if (curr_page > 1) {
      setCurrPage((prevPage) => prevPage - 1);
    }
  };

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value, 10));
  };

  const handleAttachmentDelete = async (e) => {
    e.preventDefault();

    try {
      await deleteBackend(`/resume-link`, {
        data: {
          resume_seq: selectedAtt,
        },
        headers: { "Content-Type": "application/json" },
      });
      refetch();
      toast.success("첨부 파일이 성공적으로 삭제되었습니다!");
      setDeleteModal(false);
    } catch (error) {
      toast.error("첨부 파일을 삭제하지 못했습니다!");
    }
  };

  return (
    <>
      <div className="container">
        <h2 className="com_pg_tit">이력서</h2>
        <section className="com_pg_tab">
          <Link to="/mypage/MyResume" className="tab">
            나의 이력서
          </Link>
          <Link to="/mypage/MyFit2" className="tab">
            나의 성향
          </Link>
          <Link to="/mypage/Attachment" className="tab active">
            첨부파일
          </Link>
        </section>
        <div className="input_description_box">
          <textarea
            name=""
            id=""
            cols={30}
            rows={10}
            placeholder="첨부파일 설명문구 들어갑니다."
            className="input_description"></textarea>
          <section className="btn_wrapper">
            <button className="com_btn blue" onClick={() => setUploadFileModal(true)}>
              파일 업로드
            </button>
          </section>
        </div>
        <table className="com_board_list">
          <colgroup>
            <col width="7.2%" />
            <col width="12%" />
            <col width="8%" />
            <col />
            <col width="10%" />
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>등록날짜</th>
              <th>종류</th>
              <th>파일제목</th>
              <th>관리</th>
            </tr>
          </thead>
          <tbody>
            {isFetchingAttachments ? (
              <i className="gg-spinner-alt" />
            ) : attachments?.attachments?.length !== 0 ? (
              attachments?.attachments.map((attachment, index) => {
                console.log(attachment);
                return (
                  <tr key={attachment.link_seq}>
                    <td className="td_pc">{index + 1}</td>
                    <td className="date_field">{attachment.reg_dtm.split("T")[0]}</td>
                    <td className="type_field">{attachment.link_type === 'LINK' ? '링크' : '파일'}</td>
                    <td className="info_field last">{attachment.link_type === 'LINK' ? attachment.link_val : attachment.file_real_name}</td>
                    <td className="btn_field">
                      <button
                        className="com_btn s gray line oval"
                        onClick={() => {
                          setSelectedAtt(attachment.link_seq);
                          setDeleteModal(true);
                        }}>
                        삭제
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <h3>등록된 내역이 없습니다.</h3>
            )}
          </tbody>
        </table>
        {!isFetchingAttachments && (
          <Pagination
            currentPage={curr_page}
            totalPages={Math.ceil(attachments.total_count / per_page)}
            onNextClick={handleNextClick}
            onPrevClick={handlePrevClick}
            perPage={per_page}
            handlePerPageChange={handlePerPageChange}
          />
        )}
      </div>
      {/* modal */}
      <Modal open={deleteModal} close={() => setDeleteModal(false)} type="type_apply">
        <p className="message">파일을 삭제 하시겠습니까?</p>
        <div className="btn_wrapper">
          <button className="com_btn blue" onClick={handleAttachmentDelete}>
            파일 삭제
          </button>
          <button className="com_btn line" onClick={() => setDeleteModal(false)}>
            취소
          </button>
        </div>
      </Modal>
      <PopAttachFile open={uploadFileModal} close={() => setUploadFileModal(false)} refetch={refetch} />
    </>
  );
}

export default Attachment;
