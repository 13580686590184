import React, { useContext, useEffect, useState } from "react";

import Logo from "../../assets/img/logo.png";
import KakaoLogin from "./Kakao";
import GoogleDiv from "./Google";
import NaverLogin from "./Naver";
import FacebookLogin from "./Facebook";
import AppleLogin from "./Apple";

import "../../assets/scss/page/member.scss";

import { useNavigate, Link, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../../../../context/Auth";

const FormType = Object.freeze({
  General: 0,
  Corporate: 1,
});

// const BusinessApprovalStatus = Object.freeze({
//   Waiting: 0,
//   UserApproved: 1,
//   UserRejected: 2,
// });

export default function Login() {
  const [formType, setFormType] = useState(0);

  const [showGoBack, setShowGoBack] = useState(false);

  const [formSubmitting, setFormSubmitting] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const { login } = useContext(AuthContext);

  const {
    // watch,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm();
  const onValidSubmit = async (data) => {
    setFormSubmitting(true);
    const isLoggedIn = await login(data.user_id, data.user_pwd, formType === FormType.General ? "PSN" : "COM").catch((err) => {
      console.log(err);
      return false;
    });
    setFormSubmitting(false);

    if (isLoggedIn) {
      // const redirectPath = sessionStorage.getItem("redirectPath");
      // if (redirectPath) {
      //   if (redirectPath.toLocaleLowerCase().startsWith("/company") && formType === FormType.General) navigate("/");
      //   else if (redirectPath.toLocaleLowerCase().startsWith("/mypage") && formType === FormType.Corporate)
      //     navigate("/");
      //   else navigate(redirectPath);

      //   sessionStorage.removeItem("redirectPath");
      // } else {
      // }
      navigate("/");
    } else {
      setFailedLogin(true);
    }
  };

  const [failedLogin, setFailedLogin] = useState(false);

  useEffect(() => {
    sessionStorage.getItem("redirectPath") && setShowGoBack(true);
  }, []);


  
  const handleOnKeyPress = e => {
    if (e.key === 'Enter') {
      console.log('엔터키@@@');
      //subButton
      document.getElementById('subButton').click();
    }
  };

  return (
    <>
      <main className="p_login com_member">
        {showGoBack && (
          <div className="com_btn_top">
            <button
              className="btn_back"
              onClick={() => {
                console.log(location.key);
                location.pathname.startsWith("/member") || location.pathname.startsWith("/company")
                  ? navigate("/")
                  : navigate(-3);
                sessionStorage.removeItem("redirectPath");
              }}>
              <h3>🔙 Go Back</h3>
            </button>
          </div>
        )}
        <h1 className="logo">
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </h1>

        <form className="box" onSubmit={handleSubmit(onValidSubmit)}>
           <section className="com_tab">
            <button
              type="button"
              className={formType === FormType.General ? "tab active" : "tab"}
              onClick={(e) => {
                e.preventDefault();
                setFormType(FormType.General);
              }}>
              일반회원
            </button>
            <button
              type="button"
              className={formType === FormType.Corporate ? "tab active" : "tab"}
              onClick={(e) => {
                e.preventDefault();
                setFormType(FormType.Corporate);
              }}>
              기업회원
            </button>
          </section>
          <section className="sec_input">
            <input
              type="text"
              placeholder="아이디는 4~16자 입니다"
              className="input"
              {...register("user_id", {
                required: true,
                minLength: 4,
                maxLength: 32,
              })}
            />
            <input
              type="password"
              placeholder="비밀번호를 입력해주세요."
              className="input"
              required
              {...register("user_pwd", {
                required: true,
                minLength: 8,
              })}
              onKeyDown={handleOnKeyPress} 
            />
            {(errors.user_id || errors.user_pwd || failedLogin) && (
              <p style={{ color: "red" }}>잘못된 로그인 정보입니다. <br /> 다시 입력해주세요.</p>
            )}

            <button id="subButton" className="com_btn blue m mt-2" type="submit">
              로그인
              {formSubmitting && <i className="gg-spinner-alt" />}
            </button>
          </section>
          <section className="find_idpw">
            <Link to="/member/SearchId" className="link">
              아이디 찾기
            </Link>
            <Link to="/member/SearchPw" className="link">
              비밀번호 찾기
            </Link>
          </section>
          <section className="sns_login">
            <h2 className="tit">SNS계정으로 간편하게 로그인하세요.</h2>
            <article className="btn_wrap">
              <NaverLogin formType={formType} FormType={FormType} />
              <KakaoLogin formType={formType} FormType={FormType} />
              <FacebookLogin formType={formType} FormType={FormType} />
              <AppleLogin formType={formType} FormType={FormType} />
              <GoogleDiv formType={formType} FormType={FormType} />
            </article>
          </section>
          <section className="sec_join">
            <h2 className="tit">
              {/*일반회원*/}
              아직 엔지니어스 회원이 아니신가요?
              {/*기업*/}
              인재를 찾고 계신가요?
            </h2>
            <Link
              to={"/member/join?type=" + (formType === FormType.General ? "PSN" : "COM")}
              className="com_btn line blue m">
              회원가입
            </Link>
          </section>
        </form>
      </main>
    </>
  );
}
