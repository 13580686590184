import React, { useState, useEffect } from "react";
/* eslint-disable no-unused-vars */
// import Errors from "../../../../../utils/forms/ErrorMap";
import FormInput from "./form/FormInput";

export default function SNSMemberForm({
  // checkId,
  checkEmail,
  checkMobilePhone,
  checkNickname,
  formState,
  register,
  trigger,
  watch,
  setValue,
  fnPopup,
  isVisible
}) {
  // const [isValid, setIsValid] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);

  async function handleMobileCheck(event) {
    event.preventDefault();
    // const user_mobile = watch("user_mobile");
    // // const isUnformatted = user_mobile.match(/^\d{11}$/);

    // // if (isUnformatted) {
    // //   user_mobile = user_mobile.substring(0, 3) + "-" + user_mobile.substring(3, 7) + "-" + user_mobile.substring(7);
    // // }
    // setValue("user_mobile", user_mobile);

    // setCheckLoading(true);
    // trigger("user_mobile");
    // const notValid = await checkMobilePhone(user_mobile);

    // setCheckLoading(false);
    // // setIsValid(!notValid && !formState.errors["user_mobile"]);
    fnPopup();
  }

  const user_mobile = document.getElementById("user_mobile")?.value;
  useEffect(() => {

    async function fetchCheckMobileNo() {
      setValue("user_mobile", document.getElementById("user_mobile").value);
      setCheckLoading(true);
      trigger("user_mobile");
      await checkMobilePhone(document.getElementById("user_mobile").value);
      setCheckLoading(false);
    }
    if (user_mobile) {
      fetchCheckMobileNo();
    }
  }, [user_mobile]);

  return (
    <>
      <ul className="com_input_sec">
        <FormInput
          id={"user_name"}
          formState={formState}
          label={"이름"}
          placeholder={"이름을 입력해주세요"}
          register={register}
          required="이름을 입력해주세요."
          rules={{ minLength: 2 }}
          type={"text"}
          trigger={trigger}
          watch={watch}
        />

        <FormInput
          check={checkNickname}
          id={"user_nickname"}
          formState={formState}
          label={"닉네임"}
          placeholder={"닉네임은 2~8자 입니다."}
          register={register}
          required="닉네임을 입력해주세요."
          rules={{
            minLength: { value: 2, message: "닉네임은 2~8자 입니다." },
            maxLength: { value: 8, message: "닉네임은 2~8자 입니다." },
          }}
          type={"text"}
          trigger={trigger}
          watch={watch}
        />

        <FormInput
          check={checkEmail}
          id={"user_email"}
          formState={formState}
          label={"이메일"}
          placeholder={"이메일을 입력해주세요.(@ 포함)"}
          register={register}
          required="이메일을 입력해주세요."
          type={"text"}
          trigger={trigger}
          watch={watch}
          rules={{
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "이메일을 확인해주세요.",
            },
          }}
        />

        <FormInput
          id={"recomm_code"}
          formState={formState}
          label={"추천인"}
          placeholder={"추천인 코드를 입력해주세요"}
          register={register}
          required={false}
          type={"text"}
          trigger={trigger}
          watch={watch}
        />

        {/* 휴대폰 Cellphone - user_mobile */}
        <FormInput
          id={"user_mobile"}
          formState={formState}
          label={"휴대폰 번호"}
          placeholder={"휴대폰 번호"}
          register={register}
          required="휴대폰 번호를 입력해주세요."
          // rules={{
          //   pattern: {
          //     value: /^(\d{11}|\d{3}-\d{4}-\d{4})$/,
          //     message: "올바른 형식으로 휴대폰 번호를 입력하십시오. (예: 01012345678 또는 010-1234-5678)",
          //   },
          //   validate: (value) => {
          //     if (value && value.replace(/\D/g, "").length > 11) {
          //       return "최대 11자까지 입력 가능합니다.";
          //     }
          //     return true;
          //   },
          // }}
          type={"text"}
          trigger={trigger}
          watch={watch}
          readonly={true}
        />

        <li className="bottom_btn_field">
          <button
            className="com_btn m blue line"
            onClick={async (ev) => {
              ev.preventDefault();
              // setCheckLoading(true);
              // trigger("user_mobile");
              // const notValid = await checkMobilePhone(watch("user_mobile"));
              // setCheckLoading(false);
              // setIsValid(!notValid && !formState.errors["user_mobile"]);
              handleMobileCheck(ev);
            }}>
            휴대폰 본인 인증
            {checkLoading && <i className="gg-spinner-alt" />}
            {isVisible && <i className="gg-check" />}
          </button>
        </li>
      </ul>
    </>
  );
}
