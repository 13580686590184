import React, { useState, useContext } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import "../../assets/scss/page/member.scss";
import Pagination from "../../components/Pagination";
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import Review from "./_PopReview";
import { mutateBackendFormData, queryBackend } from "../../../../../api";
import { AuthContext } from "../../../../../context/Auth";
import Modal from '../../../front/component/Inc/Modal';


function PayMentoring() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams({ page: 1, limit: 6 });
  const page = +searchParams.get("page");
  const limit = +searchParams.get("limit");
  const [modalAlert, setModalAlert] = useState(false);
  const [message, setMessage] = useState('');

  const { user } = useContext(AuthContext);

  const [currentMento, setCurrentMento] = useState(null);

  const { isFetching, data: { dataList = [], totalCnt = 0 } = { seminars: [], total_count: 0 } } = useCustomQuery(
    `/payments/mentoring?curr_page=${page}&per_page=${limit}`,
    {
      queryKey: ["getPaymentMentoringList", page, limit],
      select: (res) => res?.data,
    },
  );
  const [modalReview, setModalReview] = useState(false);

  const getChatRoom = async (corpUserSeq, dealTable, mentoSeq) => {
    const formData = new URLSearchParams();

    console.log(corpUserSeq, dealTable, mentoSeq);
    formData.append("user_mem_idx", user.user_seq);
    formData.append("corp_mem_idx", corpUserSeq);
    formData.append("my_idx", user.user_seq);
    formData.append("deal_table", dealTable);
    formData.append("propose_idx", mentoSeq);
    formData.append("user_type", user.user_type);

    const response = await mutateBackendFormData("/chat/room", formData)
      .then(async (data) => await data.json())
      .catch(() => ({ messageCode: 400 }));

    console.log(response);
    if (response.messageCode === 200) {
      console.log(response.data?.room_idx);
      navigate(`/Mypage/Chat?roomIdx=${response.data?.room_idx}`);
    } else {
      return false;
    }
  };


  const getMeetRoom = async (mento_seq) => {


    const response = await queryBackend(`/payments/mentiurl?mento_seq=${mento_seq}`);
    const { data } = await response.json();
    console.log(data);
    if(data){
      if(data[0]?.join_url_menti){
        window.open(data[0]?.join_url_menti);
      }else{
        setMessage('접속가능한 화상미팅이 없습니다.');
        setModalAlert(true);
      }
    }    
  };

  console.log(dataList);

  return (
    <>
      <div className="container">
        <h2 className="com_pg_tit">결제 콘텐츠 목록</h2>
        <section className="com_pg_tab">
          <Link to="/Mypage/PayInsight" className="tab">
            결제 인사이트 목록
          </Link>
          <Link to="/Mypage/PayMentoring" className="tab active">
            결제 멘토링 목록
          </Link>
        </section>
        <table className="com_board_list">
          <colgroup>
            <col />
            <col width="12%" />
            <col width="12%" />
            <col width="24%" />
            <col width="12%" />
            <col width="12%" />
          </colgroup>
          <thead>
            <tr>
              <th>상품명</th>
              <th>결제일자</th>
              <th>만기일자</th>
              <th>채널</th>
              <th>상태</th>
              <th>취소 및 환불</th>
            </tr>
          </thead>
          <tbody>
            {isFetching ? (
              <i className="gg-spinner-alt" />
            ) : dataList?.length > 0 ? (
              dataList?.map((obj) => (
                <tr key={obj.mento_seq}>
                  <td className="tit_field">{obj.goods_name}</td>
                  <td className="info_field">
                    <span className="th_m">결제일자 :</span>
                    {new Date(obj.reg_dtm).toLocaleString()}
                  </td>
                  <td className="info_field last">
                    <span className="th_m">만기일자 :</span>
                    {new Date(obj.end_date).toLocaleString()}
                  </td>
                  <td className="channel_field">
                    {/* <Link to="/Mypage/Webcam" className="com_btn blue line oval s btn_meeting">
                      <span>화상미팅</span> {obj.supp_meet_cnt}/{obj.use_meet_cnt}
                    </Link> */}
                    {obj.supp_meet_cnt > 0?
                        <button onClick={()=> getMeetRoom(obj.mento_seq)} className="com_btn blue line oval s btn_meeting">
                          <span>화상미팅</span> {obj.supp_meet_cnt}/{obj.use_meet_cnt}
                        </button>
                      :
                        null
                    }
                    
                    {/* <Link to={`/Mypage/Chat?mentoSeq=${obj.mento_seq}&corpUserSeq=${obj.user_seq}&dealTable=ces_mentoring`} className="com_btn blue line s oval">
                      채팅
                    </Link> */}
                    <a
                      onClick={() => getChatRoom(obj.user_seq, "ces_mentoring", obj.mento_seq)}
                      className="com_btn blue line s oval">
                      채팅
                    </a>
                    <Link
                      to="/Mypage/QnaList"
                      state={{ mento_seq: obj.mento_seq }}
                      className="com_btn blue line s oval btn_qna">
                      질문과 답변
                    </Link>
                  </td>
                  <td className="state_field">{obj.goods_type}</td>
                  {obj["rate_score"] ? (
                    <td className="info_field">{Number(obj["rate_score"])}/5</td>
                  ) : (
                    <td className="btn_field">
                      <button
                        className="com_btn gray s line oval"
                        onClick={() => {
                          setCurrentMento(obj["mento_seq"]);
                          setModalReview(true);
                        }}>
                        후기작성
                      </button>
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <h3>등록된 내역이 없습니다.</h3>
            )}
          </tbody>
        </table>

        <Pagination
          currentPage={page}
          totalPages={Math.ceil(totalCnt / limit)}
          perPage={limit}
          setSearchParams={setSearchParams}
        />
      </div>
      {/* top button */}
      <ScrollToTopButton />
      {/*후기작성 팝업*/}
      <Review open={modalReview} close={() => setModalReview(false)} mento_seq={currentMento} />
      <Modal open={modalAlert} close={() => setModalAlert(false)} type="type_alert" >
          <div className="content_container scroll">
            <strong>{message}</strong>
          </div>
          <div className="btn_container">
            <button className="com_btn blue s" onClick={() => setModalAlert(false)}>확인</button>
          </div>
        </Modal>
    </>
  );
}

export default PayMentoring;
