import React, { useCallback, useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import Item from "./_Item";
//import Modal from "../../components/modal";
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import Pagination from "../../components/Pagination";
import PopFilter from "./_popFilter";
import PopApply from "./_popApply";
import TopBanner from "../mentoring/_TopBanner";
import { AuthContext } from "./../../../../../context/Auth";

import "../../assets/scss/page/insight.scss";
import { useAuthenticatedQuery } from "../../../../../hooks/useAPI";
import { toast } from "react-toastify";

function List() {
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const [modalApply, setModalApply] = useState(false);
  const [modalFilter, setModalFilter] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState(0);
  const [categories, setCategories] = useState([]);
  const [insights, setInsights] = useState([]);
  const [permitionInfo, setPermitionInfo] = useState();
  const [per_page, setPerPage] = useState(9);
  const [totalCount, setTotalCount] = useState(0);
  const [curr_page, setCurrPage] = useState(1);
  const [filter, setFilter] = useState({});
  const [applySucess, setApplySucess] = useState(false);  //공유자 신청 팝업 성공 여부
  //const [showErrorModal, setShowErrorModal] = useState(false);  //모달관련
  //const [errMsg, setErrMsg] = useState([]);                     //모달관련


  const { data: categoryData, isFetching: isFetchingCategories } = useAuthenticatedQuery(
    ["selectAllCategories"],
    `/category?cate_gr=INSIG_CATE`,
  );

  const searchParams = new URLSearchParams();

  if (selectedCategory !== null) {
    searchParams.append("category", selectedCategory);
  }
  searchParams.append("curr_page", curr_page);
  searchParams.append("per_page", per_page);

  if (filter) {
    if (filter?.isPaid !== undefined && filter?.isPaid !== null) {
      searchParams.append("isPaid", filter.isPaid);
    }
    if (filter.tags && filter?.tags?.length !== 0) {
      const tagIds = filter.tags.map((tag) => tag.cate_seq);
      searchParams.append("tags", tagIds?.join(","));
    }
  }

  const queryString = searchParams.toString();
  const endpoint = `/insight?${queryString}`;

  const {
    data: insightProducts,
    isFetching: isLoadingInsight,
    refetch,
  } = useAuthenticatedQuery(["getInsights", selectedCategory, curr_page, filter], endpoint);

  //회원 권한
  const {
    data: permitionInfoData,
    isFetching: isLoadingPermition
  } = useAuthenticatedQuery(["getPermision", selectedCategory, curr_page, filter], '/user/qualification');
  

  useEffect(() => {
    const updateData = async (data) => {
      const response = await data.json();

      if (response.messageCode === 200) {
        const newTabs = [{ text: "전체", value: 0 }];
        for (const category of response.data.categories) {
          newTabs.push({
            value: category.cate_seq,
            text: category.cate_name,
          });
        }
        setCategories(newTabs);
      }
    };

    !isFetchingCategories && categoryData && !categoryData.bodyUsed && updateData(categoryData);
  }, [categoryData]);

  const handleCategoryChange = useCallback((categoryValue) => {
    setSelectedCategory(categoryValue);
    setCurrPage(1);
  }, []);

  useEffect(() => {
    refetch();
  }, [selectedCategory, curr_page, per_page, refetch]);

  //인사이트 데이터
  useEffect(() => {
    const updateData = async (data) => {
      const response = await data.json();
      if (response.messageCode === 200) {
        setInsights(response.data.insights);
        setTotalCount(response.data.total_count);
      }
    };

    !isLoadingInsight && insightProducts && !insightProducts.bodyUsed && updateData(insightProducts);
  }, [insightProducts]);

  //권한 데이터
  useEffect(() => {
    const updateData = async (data) => {
      const response = await data.json();
      if (response.messageCode === 200) {
        setPermitionInfo(response.data);
        console.log(response.data);
      }
    };

    !isLoadingPermition && permitionInfoData && !permitionInfoData.bodyUsed && updateData(permitionInfoData);
  }, [permitionInfoData]);

  const handleNextClick = () => {
    if (curr_page < Math.ceil(totalCount / per_page)) {
      setCurrPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevClick = () => {
    if (curr_page > 1) {
      setCurrPage((prevPage) => prevPage - 1);
    }
  };

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value, 10));
  };

  const renderCategories = () => {
    return categories.map((v, idx) => (
      <button
        key={idx}
        className={`tab ${(selectedCategory === v.value && "active") || ""}`}
        onClick={() => handleCategoryChange(v.value)}>
        {v.text}
      </button>
    ));
  };

  return (
    <main className="p_insight">
      {/* long banner */}
      <TopBanner />
      <section className="sec_insight com_bg">
        <div className="com_center_wrap">
          <div className="com_sub_tit_wrap">
            <div className="pg_tit_wrap">
              <h2 className="com_pg_tit">인사이트</h2>
              <div className="com_btn_wrap">
                <Link to="/Mypage/ProductInsight" className="com_btn m black icon_arrow">
                  상품관리
                </Link>
                <Link to="/Mypage/PayInsight" className="com_btn m black icon_arrow">
                  결제 콘텐츠 목록 보기
                </Link>
                <button className="com_btn m black icon_arrow btn_all_list"
                  onClick={() => {
                    if(user?.user_seq > 0 ) {
                      if (permitionInfo?.insig_status == "IS20") {
                        navigate("/Insight/Write");
                      } else if(permitionInfo?.insig_status == "IS10") {
                        toast.error("공유자 신청 승인대기 중입니다.");
                      } else {
                        if(applySucess){
                          toast.error("공유자 신청 승인대기 중입니다.");
                        } else {
                          setModalApply(true);
                        }
                      }
                    } else {
                      navigate("/member/login");
                    }
                  }}
                >
                  인사이트 등록
                </button>
                {/*
                <Link to={"/Insight/Write"} className="com_btn m black icon_arrow btn_all_list">
                  인사이트 등록
                </Link>
              */}
              </div>
            </div>
            <div className="com_tab_wrap">
              <div className="content_tab">{!isFetchingCategories && renderCategories()}</div>
              <button className="btn_filter" onClick={() => setModalFilter(true)}>
                필터
              </button>
            </div>
          </div>
          <div className="list_wrap">
            {isLoadingInsight ? (
              <i className="gg-spinner-alt" />
            ) : insights.length !== 0 ? (
              <ul className="com_card_wrap">
                {insights.map((insight, index) => (
                  <Item
                    key={index}
                    insig_name={insight.insig_name}
                    insig_cate={insight.cate_name}
                    insig_desc={insight.insig_desc}
                    insig_seq={insight.insig_seq}
                    insig_thumb={Number(insight.insig_thumb) > 0 ? insight.insig_thumb : insight.media_thumb}
                    insig_price={insight.insig_price}
                    insig_tags={insight.tag_names}
                    insig_views={insight.insig_views}
                    insig_likes={insight.insig_likes}
                    insig_comments={insight.insig_comments}
                    user_name={insight.user_name}
                  />
                ))}
              </ul>
            ) : (
              <section className="com_no_data">등록된 인사이트가 없습니다.</section>
            )}

            {!isLoadingInsight && (
              <Pagination
                currentPage={curr_page}
                totalPages={Math.ceil(totalCount / per_page)}
                onNextClick={handleNextClick}
                onPrevClick={handlePrevClick}
                perPage={per_page}
                handlePerPageChange={handlePerPageChange}
              />
            )}
          </div>
        </div>
      </section>
      {/* top button */}
      <ScrollToTopButton />
      {modalFilter && (
        <PopFilter open={modalFilter} close={() => setModalFilter(false)} filter={filter} setFilter={setFilter} />
      )}{" "}

      {/* 공여자 신청 팝업 */}
        <PopApply open={modalApply} close={() => setModalApply(false)} user={user} setApplySucess={setApplySucess} />

      {/* 신청자격 미달 알럭 띄우자...
      <Modal open={showErrorModal} close={() => setShowErrorModal(false)}>
        <h2 className="pop_tit required" style={{ color: "red" }}>
          ERROR
        </h2>
        <div className="content_container scroll pop_company_chk">
          <ul className="tit">
            {errMsg
              ? errMsg.map((err, idx) => <li key={idx}>{err}</li>)
              : "서버에 문제가 있는 것 같습니다. 다시 시도해 주세요."}
          </ul>
        </div>
      </Modal>
      */}

    </main>
  );
}

export default List;
