import React from "react";
import "../../assets/scss/page/mypage.scss";
import Modal from "../modal";

function DeleteMentoringModal(props) {
  const { open, close, handleDelete } = props;
  return (
    <>
      <Modal open={open} close={close} popSize="s" type="pop_review">
        <section className="pop_tit">멘토링을 삭제하십시오</section>
        <div className="content_container scroll">멘토링을 삭제 하시겠습니까? </div>
        <section className="btn_container">
          <button className="com_btn blue s" onClick={handleDelete}>
            삭제
          </button>
          <button className="com_btn line blue s" onClick={close}>
            취소
          </button>
        </section>
      </Modal>
    </>
  );
}

export default DeleteMentoringModal;
