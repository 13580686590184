import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper"; // 추가
// import "swiper/css";
// import "swiper/css/pagination";

import imgBanner from "../../assets/img/img_long_banner.png";
import imgBanner_m from "../../assets/img/img_visual_m.png";

function TopSlideBanner() {
  const [resize, setResize] = useState();

  const handleResize = () => {
    setResize(window.innerWidth);
  };

  useEffect(() => {
    setResize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section className="sec_banner">
      <div className="swiper_wrap">
        <Swiper
          modules={[Pagination, Autoplay]}
          pagination={{
            el: ".custom_pagination",
            clickable: true,
            renderBullet: (index, className) => {
              return '<span className="com_swiper_bullet ' + className + '"></span>';
            },
          }}
          // autoplay={{
          //   delay: 1500,
          //   disableOnInteraction: false,
          // }}
          loop={true}
          slidesPerView={1}>
          {[1, 2, 3].map((item, index) => (
            <SwiperSlide key={index}>
              <div
                className="img"
                style={{ backgroundImage: resize <= 1200 ? `url(${imgBanner_m})` : `url(${imgBanner})` }}></div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="custom_pagination"></div>
      </div>
    </section>
  );
}

export default TopSlideBanner;
