import React from "react";
import { Routes, Route } from "react-router-dom";
import "../views/boffice/assets/scss/styles.scss";
import AiMatchingApplyList from "../views/boffice/page/ai-match/ApplyList";
import AiMatchingApplyDetail from "../views/boffice/page/ai-match/ApplyDetail";
import JobList from "../views/boffice/page/ai-match/JobList";
import ResumeView from "../views/boffice/page/ai-match/Resumeview";
import RecruitView from "../views/boffice/page/ai-match/RecruitView";

export default function BofficeAiMatchingRouter() {
  // Authentication logic is done in the router, for now the /screens
  return (
    <Routes>
      <Route path="/apply" element={<AiMatchingApplyList />} />
      <Route path="/apply/detail/:recruit_seq" element={<AiMatchingApplyDetail />} />
      <Route path="/job/list" element={<JobList />} />
      <Route path="/job/detail/:recruit_seq" element={<RecruitView />} />
      <Route path="/resume/:resume_seq" element={<ResumeView />} />
    </Routes>
  );
}
