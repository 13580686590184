import React, { useState } from "react";
import Modal from "../../components/modal";

function PopDelete(props) {
  const { open, close } = props;
  const [reason, setReason] = useState("");

  return (
    <div className="p_modal modal_filter">
      {/* modal */}
      <Modal open={open} close={close} type="pop_apply pop_filter">
        <h2 className="pop_tit">Report Post</h2>
        <div className="info_wrap">
          <label htmlFor="reason" className="input_tit">
            Reason
          </label>
          <input type="text" name="reason" id="reason" onChange={(e) => setReason(e.target.value)} className="" />
        </div>
        <div className="btn_container">
          <button className="com_btn m BtnPopClose" onClick={close}>
            Cancel
          </button>
          <button className="com_btn blue m BtnPopClose" onClick={() => props?.onSubmit?.(reason)}>
            Report
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default PopDelete;
