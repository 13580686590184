// eslint-disable-next-line import/no-named-as-default
import styled from "styled-components";

export const Background = styled.div`
  position: absolute;
  width: 100%; //100vw -> 100%
  height: 100%; //100vw -> 100%
  top: 0;
  left: 0;
  background: #ffffffb7;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LoadingText = styled.div`
  font: 1rem "Noto Sans KR";
  text-align: center;
`;
