import React, { useEffect, useState } from "react";
import Modal from "../../component/Modal";
import { useNavigate, useParams } from "react-router-dom";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import { useForm } from "react-hook-form";
import Table from "../../../screens/components/boffice/Table";
import Pagination from "../../../screens/components/Pagination";
import { mutateBackend } from "../../../../../api";

const Point = ({ points }) => {
  const navigate = useNavigate();

  const { user_seq } = useParams();

  const [pointsModalActive, setPointsModalActive] = useState(false);

  const [pointsAddActive, setPointsAddActive] = useState(false);

  const [pointsAlert, setPointsAlert] = useState(false);

  const [noPointsAlert, setNoPointsAlert] = useState(false);

  const [selectedPointType, setSelectedPointType] = useState('add');

  const [per_page, setPerPage] = useState(10);
  const [curr_page, setCurrPage] = useState(1);


  const { 
    data: myPointsList,
    refetch,
  } = useCustomQuery(`/points/list?user_seq=${user_seq}&per_page=${per_page}&curr_page=${curr_page}`, {
    queryKey: ["getPointsList"],
    select: (res) => res?.data,
    refetchOnWindowFocus: false,
  });


  const {
    getValues,
    register,
  } = useForm({
    mode: "onChange",
  });

  
  const handlePoints = async () => {
    
    if (getValues("points") === "") {
      setPointsAlert(true)
      return;
    }else{
      if(points.points === 0 && selectedPointType === "sub"){
        setPointsAlert(true)
        return;
      }
      setPointsAddActive(true); //모달
    }
  }

  const handleConfirmClick = async() => {

    const data = {
      user_seq: user_seq,
      points_val: selectedPointType === "add" ? Number(getValues("points")) : getValues("points") * -1,
      rel_tables: "admin",
      rel_seq: 1,
      points_desc: `포인트 ${selectedPointType === "add" ? "적립" : "회수"}`
    };

    const response = await mutateBackend("/points", {
      data: data,
      headers: { "Content-Type": "application/json" },
    }).then(async (data) => await data.json());

    console.log("response ",response)

  
    if (response.messageCode === 200) navigate(0)
    else console.log("포인트를 적립/회수하는중에 오류가 생겼습니다.")
  };

  const haneldPointHistory = () => {
    myPointsList.totalCnt === 0 ? setNoPointsAlert(true) : setPointsModalActive(true);
  }
  
  useEffect(() => {
    refetch();
  },[per_page, curr_page]);

  //이전 페이지
  const handlePrevClick = () => {
    if (curr_page > 1) {
      setCurrPage((curr_page) => curr_page - 1);
    }
  };

  //다음 페이지
  const handleNextClick = () => {
    if (curr_page < Math.ceil(myPointsList?.totalCnt / per_page)) {
      setCurrPage((curr_page) => curr_page + 1);
    }
  };

  //per page 변경
  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value));
  };


  return (
    <>
      <tr>
        <th>포인트 적립</th>
        <td className="input_wrap">
          <input type="number" className="input" {...register("points")}/>
          <input
            id="add"
            className="com_chk"
            type="radio"
            name="pointType"
            value="add"
            defaultChecked
            onChange={() => setSelectedPointType('add')}
          />
          <label htmlFor="add">적립</label>
          <input
            id="sub"
            className="com_chk"
            type="radio"
            name="pointType"
            value="sub"
            onChange={() => setSelectedPointType('sub')}
          />
          <label htmlFor="sub">회수</label>
          <button onClick={handlePoints} className="adm_com_btn gray ">
            적용
          </button>
        </td>
      </tr>
      <tr>
        <th>남은 포인트</th>
        <td className="input_wrap">
          <label>{new Intl.NumberFormat().format(points?.points)}p</label>
          <button onClick={() => haneldPointHistory()} className="adm_com_btn gray">
            포인트 내역
          </button>
        </td>
      </tr>
          
      <Modal open={pointsModalActive} close={() => setPointsModalActive(false)}>
        <div className="pop_tit">포인트 내역</div>
        <Table
          data={myPointsList?.dataList}
          columns={{
            // points_: "구분",
            points_desc: "적립 내용",
            points_val: "변동금액",
            reg_dtm: "변동일자",
          }}
        />
        <Pagination
          currentPage={curr_page}
          totalPages={Math.ceil(myPointsList?.totalCnt / per_page) === 0 ? 1 : Math.ceil(myPointsList?.totalCnt / per_page)}
          onNextClick={handleNextClick}
          onPrevClick={handlePrevClick}
          perPage={per_page}
          handlePerPageChange={handlePerPageChange}
        />
      </Modal>
      <Modal open={pointsAddActive} close={() => setPointsAddActive(false)} type="type_alert">
        <div className="content_container scroll">
          <strong>{`포인트를 ${selectedPointType === "add" ? "적립" : "회수"}하시겠습니까?`}</strong>
        </div>
        <section className="btn_container">
          <button className="com_btn blue s" type="button" onClick={() => {setPointsAddActive(false); handleConfirmClick()}}>
            확인
          </button>
          <button className="com_btn line blue s" onClick={() => setPointsAddActive(false)}>
            취소
          </button>
        </section>
      </Modal>
      <Modal open={pointsAlert} close={() => setPointsAlert(false)} type="type_alert">
        <div className="content_container scroll">
          <strong>{(points.points === 0 && selectedPointType === "sub") ? "남은 포인트가 0p 입니다." : "포인트를 입력해주세요."}</strong>
        </div>
        <section className="btn_container">
          <button className="com_btn blue s" type="submit" onClick={() => setPointsAlert(false)}>
            확인
          </button>
        </section>
      </Modal>
      <Modal open={noPointsAlert} close={() => setNoPointsAlert(false)} type="type_alert">
        <div className="content_container scroll">
          <strong>{"포인트 내역이 없습니다."}</strong>
        </div>
        <section className="btn_container">
          <button className="com_btn blue s" type="submit" onClick={() => setNoPointsAlert(false)}>
            확인
          </button>
        </section>
      </Modal>
    </>
  );
};

export default Point;
