import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import React, { useState } from "react";
import Modal from "../modal";

function PopDesiredJob(props) {
  const { open, close, jobCategoryData = [], addDesiredJobCategories } = props;

  const [selectedParent, setSelectedParent] = useState(-1);
  const [selectedChildren, setSelectedChildren] = useState({});

  const parentCategories = jobCategoryData.filter((category) => category.parent_seq === 0);

  const childCategories =
    selectedParent !== -1 ? jobCategoryData.filter((category) => category.parent_seq === selectedParent) : [];

  const handleChangeParent = (event) => {
    setSelectedParent(event.target.value);
    setSelectedChildren({});
  };

  const handleChangeChildren = (event) => {
    // const selectedChildren = {
    //   id: event.target.value,
    //   cate_name: categories.find((category) => category.cate_seq === event.target.value).cate_name,
    // };
    const selectChildren = childCategories.find((category) => category.cate_seq === event.target.value);
    setSelectedChildren(selectChildren);
  };

  return (
    <Modal open={open} close={close} type="pop_desired_job" popSize="s">
      <h2 className="pop_tit type_center">희망 직무 추가</h2>
      <div className="content_container">
        <div className="box">
          <FormControl>
            <InputLabel>직무</InputLabel>
            <Select value={selectedParent} onChange={handleChangeParent}>
              <MenuItem value="-1">대분류</MenuItem>
              {parentCategories.map((category) => (
                <MenuItem key={category.cate_seq} value={category.cate_seq}>
                  {category.cate_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel>소분류</InputLabel>
            <Select
              value={selectedChildren.cate_seq || ""}
              onChange={handleChangeChildren}
              input={<OutlinedInput label="Tag" />}
              disabled={selectedParent === -1}>
              {childCategories.map((category) => (
                <MenuItem key={category.cate_seq} value={category.cate_seq}>
                  {category.cate_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <section className="com_btn_wrap">
          <button
            className="com_btn blue s"
            onClick={() => {
              if (selectedChildren) {
                const { cate_name: parentName } = jobCategoryData.find(
                  (category) => category.cate_seq === selectedParent,
                );
                addDesiredJobCategories(parentName, selectedChildren);
                setSelectedParent("-1");
                setSelectedChildren([]);
                close();
              }
            }}>
            추가
          </button>
          <button className="com_btn line blue s" onClick={close}>
            취소
          </button>
        </section>
      </div>
    </Modal>
  );
}

export default PopDesiredJob;
