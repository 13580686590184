import React, { useState } from "react";
import Modal from "../modal";

function PopAddFile(props) {
  const { open, close, handleFileAdd } = props;
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileInputChange = (e) => {
    setSelectedFile(e.target?.files[0]);
  };

  const handleAddFile = () => {
    if (selectedFile) {
      handleFileAdd(selectedFile);
      setSelectedFile(null);
      close();
    }
  };

  return (
    <Modal open={open} close={close}>
      <h2 className="pop_tit type_center">파일 추가</h2>
      <div className="content_container">
        <div className="com_input_sec">
          <div className="input_field">
            <div className="input_wrap">
              <input type="file" className="input" onChange={handleFileInputChange} />
              {/* Optionally, display the selected file name */}
            </div>
          </div>
        </div>
        <section className="com_btn_wrap center">
          <button className="com_btn blue s" onClick={handleAddFile}>
            등록
          </button>
          <button className="com_btn line s" onClick={close}>
            취소
          </button>
        </section>
      </div>
    </Modal>
  );
}

export default PopAddFile;
