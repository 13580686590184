/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { mutateBackendFormData } from "../../../../../api";
import Errors from "../../../../../utils/forms/ErrorMap";
import Modal from "../modal";
import FormInput from "./form/FormInput";

export default function SNSCorporateForm({
  checkEmail,
  checkMobilePhone,
  checkNickname,
  formState,
  register,
  setError,
  setValue,
  trigger,
  userCountUnderLicenseValid,
  watch,
  fnPopup,
  isVisible,
  getCompanyInfo,
  setcompanyUserSave
}) {
  const [showIdRegistered, setShowIdRegistered] = useState({ show: false, comp_name: "", user_emails: [] });

  const [showFileUploadFailed, setShowFileUploadFailed] = useState(false);

  const [isValid, setIsValid] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);
  const [companyLoad, setCompanyLoad] = useState(false);

  const handleCloseCompanyModal = () => {
    setShowIdRegistered({ show: false, comp_name: "", user_emails: [] });
  };

  async function handleUpload(event) {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("upload_ty", "business-registration-number");

    const response = await mutateBackendFormData("/upload/file", formData)
      .then(async (data) => await data.json())
      .catch(() => ({ messageCode: 400 }));

    // console.log(response);
    if (response.messageCode === 200) {
      setValue("reg_certi_seq", response.data[0].up_file_seq);
    } else {
      console.log("file failed");
      setShowFileUploadFailed(true);
      setValue("reg_certi_seq", null);
      setError("reg_certi_seq", Errors.required);
    }

    // return true;
  }

  async function userLicenseInvalid() {
    let comp_reg_no = watch("comp_reg_no");

    const isUnformatted = comp_reg_no.match(/^\d{10}$/);

    if (isUnformatted)
      comp_reg_no = comp_reg_no.substring(0, 3) + "-" + comp_reg_no.substring(3, 5) + "-" + comp_reg_no.substring(5);

    setValue("comp_reg_no", comp_reg_no);

    const { count, comp_name, user_emails, user_ids } = await userCountUnderLicenseValid(comp_reg_no);

    if (count > 0) {
      if (count === 5) {
        setcompanyUserSave(false);
        setShowIdRegistered({ show: true, count, comp_name, user_emails, user_ids });
      } else {
        setcompanyUserSave(true);
        setShowIdRegistered({ show: true, count, comp_name, user_emails, user_ids });
      }
      // setShowIdRegistered({ show: true, comp_name, user_emails });
      // setValue("comp_reg_no", "");

      return false;
    }
  }

  async function handleMobileCheck(event) {
    event.preventDefault();
    fnPopup();
  }

  const open = useDaumPostcodePopup("https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js");

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    setValue("comp_address", fullAddress);
  };

  const selectAddress = () => {
    open({ onComplete: handleComplete });
  };

  const user_mobile = document.getElementById("user_mobile")?.value;

  useEffect(() => {

    async function fetchCheckMobileNo() {
      setValue("user_mobile", document.getElementById("user_mobile").value);
      setCheckLoading(true);
      trigger("user_mobile");
      await checkMobilePhone(document.getElementById("user_mobile").value);
      setCheckLoading(false);
    }

    if (user_mobile) {
      fetchCheckMobileNo();
    }
  }, [user_mobile]);

  const CompanyInfo =async ()=>{
    const comp_reg_no = watch("comp_reg_no");
    //get /company/info/:comp_reg_no
    const data = await getCompanyInfo(comp_reg_no);

    // reg_certi_seq
    setValue("comp_name", data.info.comp_name);
    setValue("ceo_name", data.info.ceo_name);
    setValue("comp_address", data.info.comp_address);
    setValue("comp_add_detail", data.info.comp_add_detail);
    setValue("comp_intro", data.info.comp_intro);
    setValue("comp_home_url", data.info.comp_home_url);
    setValue("reg_certi_seq", 0);
    setCompanyLoad(true);
    setShowIdRegistered({ show: false, count: 0, comp_name: "", user_emails: [], user_ids: [] });
   
  }

    // useEffect(() => {

    //   const userMobile = document.getElementById("user_mobile");
    //   userMobile.value = '01002166612';
    //   console.log(userMobile);

    // }, []);

  return (
    <>
      <h2 className="com_input_sec__tit">기업정보</h2>
      <ul className="com_input_sec">
        <FormInput
          check={userLicenseInvalid}
          formState={formState}
          id={"comp_reg_no"}
          label={"사업자등록번호"}
          placeholder={"사업자등록번호를 입력해주세요.(-제외)"}
          register={register}
          required="사업자 등록 번호를 입력해주세요."
          rules={{
            pattern: {
              value: /^\d{10}$|^\d{3}-\d{2}-\d{5}$/,
              message: "라이센스를 10 자리 번호 또는 XXX-XX-XXXXX 형식으로 수정하십시오.",
            },
          }}
          trigger={trigger}
          type={"text"}
          watch={watch}
        />
        <FormInput
          formState={formState}
          id={"comp_name"}
          label={"회사명"}
          placeholder={"회사명을입력해주세요"}
          register={register}
          require="회사명을 입력해주세요."
          trigger={trigger}
          type={"text"}
          watch={watch}
          readonly={companyLoad}
        />

        <FormInput
          formState={formState}
          id={"ceo_name"}
          label={"대표자명"}
          placeholder={"회사명을입력해주세요"}
          register={register}
          required="대표자명을 입력해주세요."
          trigger={trigger}
          type={"text"}
          watch={watch}
          readonly={companyLoad}
        />

        <li className="tit_field">
          회사주소
          <i className="icon_must">*</i>
        </li>
        <li className="input_field">
          <div className="input_wrap">
            <input
              type="text"
              placeholder="버튼을 클릭하여 주소를 입력해주세요."
              className="input"
              {...register("comp_address", {
                required: { value: true, message: "주소를 입력해주세요." },
              })}
              readOnly={companyLoad}
            />
            <button className="com_btn line blue s" onClick={selectAddress}>
              주소 찾기
            </button>
          </div>
        </li>
        {/*         
        <FormInput
          check={() => false}
          buttonText={"주소 찾기"}
          formState={formState}
          id={"comp_address"}
          label={"회사주소"}
          placeholder={""}
          register={register}
          required="주소를 입력해주세요."
          trigger={trigger}
          type={"text"}
          watch={watch}
        /> */}

        <FormInput
          formState={formState}
          id={"comp_add_detail"}
          placeholder={"상세주소를 입력해주세요."}
          register={register}
          trigger={trigger}
          type={"text"}
          watch={watch}
          readonly={companyLoad}
        />

        <FormInput
          formState={formState}
          id={"comp_intro"}
          label={"회사 설명"}
          placeholder={"회사에 대한 간단한 설명."}
          register={register}
          required={{ value: true, message: "회사 설명을 입력해주세요." }}
          trigger={trigger}
          type={"text"}
          watch={watch}
          readonly={companyLoad}
        />

        <FormInput
          formState={formState}
          id={"comp_home_url"}
          label={"회사 웹사이트 주소"}
          placeholder={"https://example.com"}
          register={register}
          required={{ value: true, message: "회사 웹사이트 주소를 입력해주세요." }}
          trigger={trigger}
          type={"text"}
          watch={watch}
          readonly={companyLoad}
        />
        {!companyLoad ?
          <FormInput
            buttonText={"파일 찾기"}
            handleUpload={handleUpload}
            formState={formState}
            id={"reg_certi_seq"}
            label={"사업자등록증 첨부"}
            placeholder={"사업자등록증을 첨부해주세요."}
            register={register}
            required={{ value: true, message: "사업자등록증을 첨부해주세요." }}
            trigger={trigger}
            type={"file"}
            watch={watch}
          />
          :
          null
        }
      </ul>
      <h2 className="com_input_sec__tit">담당자 정보</h2>
      <ul className="com_input_sec">
        <FormInput
          id={"user_name"}
          formState={formState}
          label={"이름"}
          placeholder={"이름을 입력해주세요"}
          register={register}
          required="이름을 입력해주세요."
          rules={{ minLength: 2 }}
          type={"text"}
          trigger={trigger}
          watch={watch}
        />

        <FormInput
          check={checkNickname}
          id={"user_nickname"}
          formState={formState}
          label={"닉네임"}
          placeholder={"닉네임을 입력해주세요"}
          register={register}
          required="닉네임을 입력해주세요."
          rules={{
            minLength: { value: 2, message: "닉네임은 2~8자 입니다." },
            maxLength: { value: 8, message: "닉네임은 2~8자 입니다." },
          }}
          type={"text"}
          trigger={trigger}
          watch={watch}
        />

        <FormInput
          check={checkEmail}
          id={"user_email"}
          formState={formState}
          label={"이메일"}
          placeholder={"이메일을 입력해주세요.(@ 포함)"}
          register={register}
          required="이메일을 입력해주세요."
          type={"text"}
          trigger={trigger}
          watch={watch}
          rules={{
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "이메일을 확인해주세요.",
            },
          }}
        />

        <FormInput
          id={"user_department"}
          formState={formState}
          label={"부서"}
          placeholder={"담당자분의 부서명을 입력해주세요."}
          register={register}
          required="부서를 입력해주세요."
          type={"text"}
          trigger={trigger}
          watch={watch}
        />

        {/* 휴대폰 Cellphone - user_mobile */}
        <FormInput
          id={"user_mobile"}
          formState={formState}
          label={"휴대폰 번호"}
          placeholder={"휴대폰 번호"}
          register={register}
          required="휴대폰 번호를 입력해주세요."
          // rules={{
          //   pattern: {
          //     value: /^(\d{11}|\d{3}-\d{4}-\d{4})$/,
          //     message: "올바른 형식으로 휴대폰 번호를 입력하십시오. (예: 01012345678 또는 010-1234-5678)",
          //   },
          //   validate: (value) => {
          //     if (value && value.replace(/\D/g, "").length > 11) {
          //       return "최대 11자까지 입력 가능합니다.";
          //     }
          //     return true;
          //   },
          // }}
          type={"text"}
          trigger={trigger}
          watch={watch}
          readonly={true}
        />
        <li className="bottom_btn_field">
          <button className="com_btn m blue line" onClick={handleMobileCheck}>
            휴대폰 본인 인증
            {checkLoading && <i className="gg-spinner-alt" />}
            {isVisible && <i className="gg-check" />}
          </button>
        </li>
      </ul>
     {/*등록확인 팝업*/}
     <Modal open={showIdRegistered.show} close={handleCloseCompanyModal}>
        <h2 className="pop_tit">등록 확인</h2>
        <div className="content_container scroll pop_company_chk">
          <h3 className="tit">{showIdRegistered.comp_name} 정보로 가입된 회원입니다.</h3>
          <section className="id_sec">
            {showIdRegistered?.user_ids?.map((user, i) => (
              <article key={i} className="item">
                {user}
              </article>
            ))}
          </section>
          <section className="detail">
            현재 총 {showIdRegistered.count}개의 아이디가 가입되어 있습니다.
            <br />
            (중복가입은 한 사업체 당 5개까지 가능합니다.)
          </section>
        </div>
        <div className="btn_container">
          {showIdRegistered.count < 5 ?
            <>
              <button
                type="button"
                className="com_btn blue m"
                // disabled={showIdRegistered.user_ids?.length >= 5}
              >
                취소
              </button>
              <button
                type="button"
                className="com_btn blue m"
                // disabled={showIdRegistered.user_ids.length >= 5}
                onClick={() => CompanyInfo()}
              >
                정보 불러오기
              </button>
            </>

            :
            <button
              type="button"
              className="com_btn blue m"
              onClick={handleCloseCompanyModal}>
              확인
            </button>
          }

        </div>
      </Modal>
      <Modal open={showFileUploadFailed} close={() => setShowFileUploadFailed(false)}>
        <h2 className="pop_tit required" style={{ color: "red" }}>
          ERROR
        </h2>
        <div className="content_container scroll pop_company_chk">
          <h3 className="tit">파일을 업로드하지 못했습니다. 다시 시도해 주세요.</h3>
        </div>
      </Modal>
    </>
  );
}
