import React from "react";
import { useSearchParams } from "react-router-dom";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import Table from "../../../screens/components/boffice/Table";
import Paging from "../../component/Paging";
import MagazineFilter from "./../../component/MagazineFIlter";

const columns = {
  mag_seq: "숫자",
  mag_title: "매거진 제목",
  reg_dtm: "등록 날짜",
  cate_name: "범주",
  exposed_YN: "노출",
  detail: {
    label: "관리",
    getLink: (item) => "/boffice/board/magazine/details/" + item["mag_seq"],
    linkLabel: "상세",
  },
};

const defaultSearchParams = {
  page: 1,
  limit: 10,
  search_query: "",
  date_from: "",
  date_to: "",
  exposed_YN: "",
  category: "",
};

function AdminMagazineList() {
  const [searchParams, setSearchParams] = useSearchParams(defaultSearchParams);
  const page = +searchParams.get("page");
  const limit = +searchParams.get("limit");
  const date_from = searchParams.get("date_from");
  const date_to = searchParams.get("date_to");
  const exposed_YN = searchParams.get("exposed_YN");
  const search_query = searchParams.get("search_query");
  const mag_category = searchParams.get("category");

  const {
    data: { magazines = [], total_count = 0 } = {
      magazines: [],
      total_count: 0,
    },
    isFetching,
  } = useCustomQuery(
    `/magazine?category=${mag_category}&page=${page}&limit=${limit}&search_query=${search_query}&date_from=${date_from}&date_to=${date_to}&exposed_YN=${exposed_YN}`,
    {
      queryKey: ["getMagazineList", page, limit, search_query, date_from, date_to, exposed_YN, mag_category],
      select: (res) => res?.data ?? { magazines: [], total_count: 0 },
    },
  );

  const handleFilterChange = (key, value) => {
    setSearchParams((prev) => {
      prev.set(key, value);
      return prev;
    });
  };

  const handlePageClick = (toPage) => {
    if (Number.isInteger(toPage))
      return setSearchParams((prev) => {
        prev.set("page", toPage);
        return prev;
      });

    switch (toPage) {
      case "first": {
        setSearchParams((prev) => {
          prev.set("page", 1);
          return prev;
        });
        break;
      }
      case "next": {
        page < Math.floor(total_count / limit) &&
          setSearchParams((prev) => {
            prev.set("page", page + 1);
            return prev;
          });
        break;
      }
      case "prev": {
        page > 1 &&
          setSearchParams((prev) => {
            prev.set("page", page - 1);
            return prev;
          });
        break;
      }
      case "last": {
        setSearchParams((prev) => {
          prev.set("page", Math.ceil(total_count / limit));
          return prev;
        });
        break;
      }
    }
  };

  return (
    <main>
      <h2 className="adm_com_pg_tit">매거진 목록</h2>
      <div className="adm_com_pg_content">
        <MagazineFilter
          handleFilterChange={handleFilterChange}
          handleResetFilter={() => setSearchParams(defaultSearchParams)}
          total_count={total_count}
          isFetching={isFetching}
          limit={limit}
          search_query={search_query}
          date_from={date_from}
          date_to={date_to}
          exposed_YN={exposed_YN}
        />
        <Table data={magazines} columns={columns} />
        <Paging handlePageClick={handlePageClick} page={page} totalPageCount={Math.ceil(total_count / limit)} />
      </div>
    </main>
  );
}

export default AdminMagazineList;
