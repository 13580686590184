import React from "react";
import "../../assets/scss/styles.scss";
import "../../assets/scss/page/table.css";
import { Link } from "react-router-dom";
import useCommCode from "../../../../../hooks/useCommCode";
import moment from "moment";

const getFormattedData = (columnId, data, index, page, commcodeList) => {
  // 공통 코드 전체 변환으로 수정
  const commCode = commcodeList.find((el) => el.comm_code === data[columnId]);
  if (["reg_dtm", "up_dtm", "withdrawn_at", "semin_date", "application_dtm", "application_date"].includes(columnId))
    return moment(data[columnId]).format("YYYY-MM-DD");

  // 공통코드 변환
  if (commCode) return commCode["code_nm_kr"];
  // 인덱스(번호)
  if (columnId === "index") return (page - 1) * 10 + index + 1;
  // 경력 표시
  if (columnId === "career") {
    if (data["newcomer_YN"] === "Y") return "신입";
    else return `${data["career_min"]} ~ ${data["career_max"]}년`;
  }
  // 공고기간 표시
  if (columnId === "period") {
    if (data["close_type"] === "ANY") return "상시채용";
    if (data["close_type"] === "UNTI") return `채용 시 마감: ${moment(data["open_date"]).format("YYYY-MM-DD")} ~`;
    else
      return `${moment(data["open_date"]).format("YYYY-MM-DD")} ~ ${moment(data["close_date"]).format("YYYY-MM-DD")}`;
  }

    // 포인트 표시
    if (columnId === "points_val") {
      return `${new Intl.NumberFormat().format(data[columnId])}p`;
    }
  return data[columnId];
};
/**
 *
 * @param {Object} props
 * @param {Array} props.data, list of data to be shown in table
 * @param {Boolean} props.checked, list of data to be shown in table
 * @param {null | ((e) => void ) } props.handleCheck, list of data to be shown in table
 * @param {Object.<string, string | Object.<string, string | Function>>} props.columns, list of columns and their labels, or columns and their links if they are buttons
 * @returns A table of data sorted to columns
 */
export default function Table({ data, columns, checked = false, handleCheck = null, page }) {
  const { data: commcodeList = [] } = useCommCode("");

  return (
    <table className="adm_com_board_list">
      <colgroup>
        {Object.values(columns).map((_, i) => (
          <col key={i} />
        ))}
      </colgroup>
      <thead>
        <tr>
          {Object.values(columns).map((label, index) =>
            typeof label === "string" ? (
              <th key={index}>{label}</th>
            ) : (
              <th key={index}>{typeof label.label === "string" && label.label}</th>
            ),
          )}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            {[
              ...Object.keys(columns).map((columnId, columnIndex) =>
                typeof columns[columnId] === "string" ? (
                  columnIndex === 0 && checked ? (
                    <td key={columnIndex}>
                      <input
                        type="checkbox"
                        className="com_chk"
                        id={String(index)}
                        checked={!!item["selected"]}
                        onChange={() => handleCheck && handleCheck(index)}
                      />
                      <label htmlFor={String(index)}>
                        {getFormattedData(columnId, item, index, page, commcodeList)}
                      </label>
                    </td>
                  ) : (
                    <td key={columnIndex}>{getFormattedData(columnId, item, index, page, commcodeList)}</td>
                  )
                ) : (
                  // Links
                  <td key={columnIndex}>
                    <Link to={columns[columnId]["getLink"](item)} className="adm_com_btn gray">
                      {" "}
                      {columns[columnId]["linkLabel"]}
                    </Link>
                  </td>
                ),
              ),
            ]}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
