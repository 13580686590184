import React from "react";
import { Routes, Route } from "react-router-dom";
import "../views/boffice/assets/scss/styles.scss";

import MemberList from "../views/boffice/page/member/List";
import MemberDetail from "../views/boffice/page/member/Detail";
import CorporateMemberList from "../views/boffice/page/company/List";
import CorporateDetail from "../views/boffice/page/company/Detail";
import WithdrawnMemberList from "../views/boffice/page/withdraw/List";

export default function RouterBofficeMember() {
  // Authentication logic is done in the router, for now the /screens
  return (
    <Routes>
      <Route path="/manage/user/" element={<MemberList />} />
      <Route path="/manage/user/:user_seq" element={<MemberDetail />} />
      <Route path="/manage/company/" element={<CorporateMemberList />} />
      <Route path="/manage/company/:user_seq" element={<CorporateDetail />} />
      <Route path="/manage/leave/" element={<WithdrawnMemberList />} />
    </Routes>
  );
}
