import React, { useState } from "react";
import Modal from "../../components/modal";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import { mutateBackend } from "../../../../../api";
import { toast } from "react-toastify";

function PopApply(props) {
  const { open, close, user, setApplySucess } = props;

  //const [isApplying, setIsApplying] = useState(false);
  const [selectedResume, setSelectedResume] = useState(0);

  //const [showResumeIncomplete, setShowResumeIncomplete] = useState(true);

  const { data: resumes } = useCustomQuery(`/resume/my/list?resume_status=DONE`, {
    queryKey: ["getResumes"],
    select: (res) => res?.data?.resumes,
    enabled: open,
  });

  //EV::제출
  const handleMentorshipApplication = async () => {
    if(selectedResume > 0) {
      const response = await mutateBackend("/insight/apply", {
        data: {
          user_seq: user.user_seq,
          resume_seq: selectedResume,
        },
        headers: { "Content-Type": "application/json" },
      }).then(async (data) => await data.json());

      if (response.messageCode === 200) {
        setApplySucess(true);
        toast.success("인사이트 공유자 신청이 완료되었습니다.");
        close();
      } else {
        toast.error("인사이트 공유자 신청에 실패하였습니다.");
      }
    } else {
      toast.error("이력서를 선택하세요.");
    }
    //setIsApplying(false);
  };

  //EV::이력서 선택
  const handleChangeRadio = (e) => {
    const { value } = e.target;
    console.log('value', value);
    setSelectedResume(Number(value));
  };

  return (
    <Modal open={open} close={close} type="pop_apply pop_share">
      <h2 className="pop_tit">인사이트 공유자 신청</h2>
      <div className="content_container scroll">
        <div className="info_wrap">
          <div className="box">
            <ul className="rule_list">
              <li className="rule">
                <span className="dot blue"></span>
                인사이트 공유자 신청을 하기 위해서는 인증된 경력이 필요합니다.
              </li>
              <li className="rule">
                <span className="dot blue"></span>
                등록된 이력서 제출 후 관리자 승인을 통해 영업일 기준 2~3일 내에 인사이트 공유 권한을 드립니다.
              </li>
            </ul>
          </div>
          <div className="box">
            <p className="input_tit">이력서 선택</p>
            <ul className="list scroll">
              {resumes?.length > 0 &&
                resumes?.map((item, index) => (
                  <li className="item" key={index}>
                    <input
                      type="radio"
                      className="com_chk"
                      id={`rate-${index}`}
                      value={item?.resume_seq}
                      checked={selectedResume === item?.resume_seq}
                      onChange={handleChangeRadio}
                    />
                    <label htmlFor={`rate-${index}`}>
                      <span className="sel_tit">
                        {item?.resume_name} | {item?.resume_title}
                      </span>
                    </label>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="btn_container">
        <button className="com_btn blue m BtnPopClose" onClick={() => handleMentorshipApplication()}>
          제출하기
        </button>
      </div>
    </Modal>
  );
}

export default PopApply;
