import React, { useState } from "react";
import Table from "../../../../screens/components/boffice/Table";
import { useNavigate } from "react-router-dom";
import Modal from "../../../../screens/components/modal";
import { deleteBackend } from "../../../../../../api";
import { toast } from "react-toastify";
import { useCustomQuery } from "../../../../../../hooks/useAPI";

const columns = {
  cate_seq: "번호",
  cate_name: "항목명",
  cate_desc: "설명",
  use_YN: "사용여부",
  // * If it is a link, it passes an object
  detail: {
    label: "관리",
    getLink: (item) => "/boffice/board/magazine/cate/" + item["cate_seq"],
    linkLabel: "상세",
  },
};

export default function CategoryList() {
  const navigate = useNavigate();

  const [magazineCategories, setMagazineCategories] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { isFetching, refetch } = useCustomQuery(`/category?cate_gr=MAG_CATE`, {
    queryKey: ["getMagazineCategories/"],
    select: (res) => res?.data.categories,
    onSuccess: (data) => {
      setMagazineCategories(data);
    },
    refetchOnWindowFocus: false,
  });

  const handleCheck = (category_index) => {
    let checkCategory = magazineCategories[category_index];

    if (!checkCategory) return;

    checkCategory = { ...checkCategory, selected: !checkCategory.selected };
    magazineCategories[category_index] = checkCategory;
    setMagazineCategories([...magazineCategories]);
  };

  const handleCategoryDelete = async () => {
    const selectedCategories = magazineCategories.filter((curr) => curr?.selected).map((curr) => curr["cate_seq"]);

    setIsDeleting(true);
    const response = await deleteBackend("/category", {
      data: {
        categories: selectedCategories,
      },
      headers: { "Content-Type": "application/json" },
    }).then(async (data) => await data.json());

    if (response.messageCode === 200) {
      toast.success("카테고리를 성공적으로 삭제했습니다.");
    } else toast.error("카테고리 삭제가 실패했습니다.");

    setIsDeleting(false);
    setShowConfirmDelete(false);
    await refetch();
  };

  console.log(magazineCategories);
  return (
    <main>
      <h2 className="adm_com_pg_tit bottom_line">카테고리 관리</h2>
      <div className="adm_table_btn flex-end">
        {/* Add Category */}
        <button className="adm_com_btn gray" onClick={() => navigate("/boffice/board/magazine/cate/new")}>
          카테고리 추가
        </button>
        {/* Select Delete */}
        <button
          className="adm_com_btn gray"
          onClick={() => magazineCategories.filter((curr) => curr?.selected).length > 0 && setShowConfirmDelete(true)}>
          선택 삭제
        </button>
      </div>
      <div className="adm_com_pg_content">
        <div className="sub_tit">
          <span className="txt flex-start">{isFetching && <i className="gg-spinner-alt" />}</span>
        </div>
        <Table data={magazineCategories} columns={columns} checked={true} handleCheck={handleCheck} />
      </div>
      <Modal open={showConfirmDelete} close={() => setShowConfirmDelete(false)}>
        <div className="content_container ">
          <h2>다음 범주를 삭제하려고합니다</h2>
          <table>
            {magazineCategories
              .filter((curr) => curr?.selected)
              .map((curr, i) => (
                <tr key={i}>
                  <label>{curr["cate_name"]}</label>
                </tr>
              ))}
          </table>
        </div>
        <div className="adm_com_btn_wrap center" style={{ paddingBottom: "10px" }}>
          <button className="adm_com_btn gray" onClick={handleCategoryDelete}>
            삭제를 확인하십시오 {isDeleting && <i className="gg-spinner-alt" />}
          </button>
          <button className="adm_com_btn gray" type="button" onClick={() => setShowConfirmDelete(false)}>
            취소
          </button>
        </div>
      </Modal>
    </main>
  );
}
