import React, { useState, useCallback, useContext } from "react";
import "../../assets/scss/page/member.scss";

//component
import Pagination from "../../components/Pagination";
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import Modal from "../../components/modal";
import NewRecruit from "../../components/company/recruit/NewRecruit";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import RecruitList from "../../components/company/recruit/RecruitList";
import { deleteBackend, updateBackend } from "../../../../../api";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../../context/Auth";
import useCommCate from "../../../../../hooks/useCommCate";
import useCommCode from "../../../../../hooks/useCommCode";

export default function Recruit() {
  const { user } = useContext(AuthContext);
  const [recruitsPerPage, setRecruitsPerPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);

  const [totalRecruitCount, setTotalRecruitCount] = useState(0);
  const [recruitList, setRecruitList] = useState([]);

  const [selectedRecruitId, setSelectedRecruitId] = useState(0);
  const [isSubmittingForm, setIsSubmitting] = useState(false);

  const [closeRecruitModalOpen, setRecruitModalOpen] = useState(false);
  const [deleteRecruitModalOpen, setDeleteRecruitModalOpen] = useState(false);

  const handleCloseRecruitModal = () => {
    setRecruitModalOpen(false);
  };

  const handleCloseDeleteRecruitModal = () => {
    setDeleteRecruitModalOpen(false);
  };

  const { isFetching, refetch } = useCustomQuery(
    `/recruit/list?per_page=${recruitsPerPage}&curr_page=${currentPage}&comp_seq=${user.comp_seq}`,
    {
      queryKey: ["getRecruitList", recruitsPerPage, currentPage, user],
      select: (res) => res?.data,
      onSuccess: (data) => {
        // console.log(data);
        setTotalRecruitCount(data.total_cnt);
        setRecruitList(data.data_list);
      },
      refetchOnWindowFocus: false,
    },
  );

  const { isFetching: EmpTypeIsFetching, data: employmentTypes } = useCommCode("EMP_TYPE");
  const { isFetching: RecrTypeIsFetching, data: recruitTypes } = useCommCode("RECU_CLOSE");
  const { isFetching: RecrJobTypeIsFetching, data: categoryTypes } = useCommCate("RECU_JOB");

  const handleNext = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const handleCloseRecruit = async (closingRecruitId) => {
    if (closingRecruitId === 0) return;

    setIsSubmitting(true);
    const response = await updateBackend("/recruit", {
      data: {
        comp_seq: user.comp_seq,
        recruit_seq: closingRecruitId,
        recruit_status: "CLOSED",
      },
      headers: { "Content-Type": "application/json" },
    }).then(async (data) => await data.json());

    setIsSubmitting(false);
    setSelectedRecruitId(0);

    if (response.messageCode === 200) {
      toast.success("게시물이 성공적으로 닫혔습니다.");
      refetch();
    } else toast.error("게시 폐쇄가 실패했습니다.");

    handleCloseRecruitModal();
  };

  const handleDeleteRecruit = async (closingRecruitId) => {
    if (closingRecruitId === 0) return;

    setIsSubmitting(true);
    const response = await deleteBackend("/recruit", {
      data: {
        comp_seq: user.comp_seq,
        recruit_seq: closingRecruitId,
      },
      headers: { "Content-Type": "application/json" },
    }).then(async (data) => await data.json());

    setIsSubmitting(false);
    setSelectedRecruitId(0);

    if (response.messageCode === 200) {
      toast.success("게시물을 성공적으로 삭제했습니다.");
      refetch();
    } else toast.error("사후 삭제가 실패했습니다.");

    handleCloseDeleteRecruitModal();
  };

  const [tabState, setTabState] = useState(0);
  const tab = [
    { text: "채용 공고 목록", value: 0 },
    { text: "채용 공고 등록", value: 1 },
  ];
  const renderTab = useCallback(() => {
    return tab.map((v, idx) => {
      return (
        <button
          key={idx}
          className={`tab ${(tabState === v.value && "active") || ""}`}
          onClick={() => setTabState(v.value)}
          disabled={isFetching || EmpTypeIsFetching || RecrTypeIsFetching || RecrJobTypeIsFetching}>
          {v.text}
        </button>
      );
    });
  }, [tab]);
  return (
    <>
      <div className="container">
        <h2 className="com_pg_tit flex-start">
          공고관리
          {(isFetching || EmpTypeIsFetching || RecrTypeIsFetching || RecrJobTypeIsFetching) && (
            <i className="gg-spinner-alt" />
          )}
        </h2>
        <div className="com_pg_tab">{renderTab()}</div>

        {(tabState === 0 && (
          <div className="tab_content">
            <RecruitList
              recruitList={recruitList}
              handleCloseRecruit={(recruit_seq) => {
                setSelectedRecruitId(recruit_seq);
                setRecruitModalOpen(true);
              }}
              handleDeleteRecruit={(recruit_seq) => {
                setSelectedRecruitId(recruit_seq);
                setDeleteRecruitModalOpen(true);
              }}
            />
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(totalRecruitCount / recruitsPerPage)}
              onNextClick={handleNext}
              onPrevClick={handlePrevious}
              perPage={recruitsPerPage}
              handlePerPageChange={(e) => setRecruitsPerPage(e.target.value)}
            />
          </div>
        )) ||
          (tabState === 1 && (
            <NewRecruit
              employmentTypes={employmentTypes}
              recruitTypes={recruitTypes}
              categoryTypes={categoryTypes}
              defaultParent={categoryTypes ? categoryTypes[0]["cate_seq"] : null}
            />
          ))}
      </div>
      {/* top button */}
      <ScrollToTopButton />
      <Modal open={closeRecruitModalOpen} close={handleCloseRecruitModal} type="type_alert">
        <div className="content_container scroll">
          <strong>공고를 마감 하시겠습니까?</strong>
        </div>
        <section className="btn_container">
          <button
            className="com_btn blue s"
            onClick={() => {
              handleCloseRecruit(selectedRecruitId);
              handleCloseRecruitModal();
            }}>
            확인 {isSubmittingForm && <i className="gg-spinner-alt" />}
          </button>
          <button
            className="com_btn line blue s"
            onClick={() => {
              setSelectedRecruitId(0);
              handleCloseRecruitModal();
            }}>
            취소
          </button>
        </section>
      </Modal>
      <Modal open={deleteRecruitModalOpen} close={handleCloseDeleteRecruitModal} type="type_alert">
        <div className="content_container scroll">
          <strong>이 게시물을 삭제 하시겠습니까?</strong>
        </div>
        <section className="btn_container">
          <button
            className="com_btn blue s"
            onClick={() => {
              handleDeleteRecruit(selectedRecruitId);
              handleCloseRecruitModal();
            }}>
            확인 {isSubmittingForm && <i className="gg-spinner-alt" />}
          </button>
          <button
            className="com_btn line blue s"
            onClick={() => {
              setSelectedRecruitId(0);
              handleCloseRecruitModal();
            }}>
            취소
          </button>
        </section>
      </Modal>
    </>
  );
}
