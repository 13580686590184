import React from "react";
import { Route, Routes } from "react-router-dom";

import JobList from "../views/screens/page/main/JobList";
import MatchList from "../views/screens/page/match/MatchList";
import RecruitView from "../views/screens/page/match/Detail";

function RouterMatch() {
  return (
    <>
      <Routes>
        <Route path="/" element={<MatchList />} />
        <Route path="/JobList" element={<JobList />} />
        <Route path="/Detail/:recruit_seq" element={<RecruitView />} />
      </Routes>
    </>
  );
}

export default RouterMatch;
