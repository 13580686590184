import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { updateBackend } from "../../../../../api";
import Errors from "../../../../../utils/forms/ErrorMap";
import Logo from "../../assets/img/logo.png";
import "../../assets/scss/page/member.scss";
import FormInput from "../../components/join/form/FormInput";

function NewPw() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const query = searchParams.get('userSeq');
  console.log(query);

  const defaultValues = {
    user_pwd: "",
    user_pwd_match: "",
  };
  const { formState, handleSubmit, register, reset, trigger, watch, getValues } = useForm();

  const [formSubmitting, setFormSubmitting] = useState(false);

  const onValidSubmit = async () => {
    setFormSubmitting(true);
    await updateBackend("/user/change/password", {
      data: { user_seq: query, new_password: getValues('user_pwd') },
      headers: { "Content-Type": "application/json" },
    }).then(async (data) => {
      setFormSubmitting(false);
      if (data.statusText === "OK" && data.status === 200) navigate("/member/login");
    })
  };

  useEffect(() => {
    reset(defaultValues);
  }, [reset]);

  return (
    <>
      <main className="com_member">
        <h1 className="logo">
          <Link to="/front/">
            <img src={Logo} alt="logo" />
          </Link>
        </h1>
        <div className="box">
          <h2 className="pg_tit">비밀번호 재설정</h2>
          <p className="pg_txt">회원님의 비밀번호를 다시 설정해주세요.</p>
          <form onSubmit={handleSubmit(onValidSubmit)}>
            <ul className="com_input_sec">
              <li className="input_field">
                <FormInput
                  id={"user_pwd"}
                  formState={formState}
                  label={"새 비밀번호"}
                  placeholder={"영문, 숫자, 특수문자를 입력해서 8자 이상 입력해주세요."}
                  register={register}
                  required
                  rules={{ minLength: 8, maxLength: 32 }}
                  subText={"사용가능한 특수문자 ~ * ! @  # $ % < > ^ & * *"}
                  type={"password"}
                  trigger={trigger}
                  watch={watch}
                />
              </li>
              <FormInput
                id={"user_pwd_match"}
                formState={formState}
                label={"새 비밀번호 확인"}
                placeholder={"비밀번호를 한번 더 입력해주세요"}
                register={register}
                required
                rules={{
                  minLength: 8,
                  maxLength: 32,
                  validate: (val) => {
                    if (watch("user_pwd") !== val) {
                      return Errors.mismatchedPassword;
                    }
                  },
                }}
                type={"password"}
                trigger={trigger}
                watch={watch}
              />
            </ul>
            <section className="com_btn_wrap">
              <button className="com_btn blue l" disabled={formSubmitting}>
                변경하기 {formSubmitting && <i className="gg-spinner-alt" />}
              </button>
            </section>
          </form>
        </div>
      </main>
    </>
  );
}

export default NewPw;
