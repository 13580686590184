import React, {memo} from "react";
import ProfileImg from "../../assets/img/img_mentor03.png";
// import moment from "moment";

const ChatEvents = ({ events, user }) => {

  async function handleFileDownload(downloadUrl) {
    // const today = moment();  
    const url =downloadUrl;
    const a = document.createElement('a');
    // a.download = `chat_${today.format("YYYYMMDD")}`;
    a.href = url;
   
    document.body.appendChild(a);
    a.click();
    // eslint-disable-next-line no-unused-vars
    setTimeout((_) => {
        window.URL.revokeObjectURL(url);
    }, 60000);
    a.remove();
  }
  console.log(user.user_seq);
  return (<>
        {events?.map((el) => {
          console.log(el.to);
          // if (el.snd_name === "0") {
          if (el.to !== user.user_seq) {
            return <>
              <article className="get">
                <div className="wrap">
                  {(() => {
                    switch (el.type) {
                      case "CHAT":
                        return <><div className="box">{el.message}</div><div className="date_field">{el.senddate}</div></>;
                      case "IMG":
                        return <><div className="img"><img src={el.img} alt="" /></div><div className="date_field">{el.senddate}</div></>;
                      case "INFO":
                        return <><div className='info_area'><p className='date_field'>{el.senddate}</p></div><div className="date_field">{el.senddate}</div></>;
                      case "FILE":
                        return <><div className="box">{el.message}<br /><button type="button" onClick={()=> handleFileDownload(el.img)} style={{backgroundColor:'orangered', color: 'white', width : '140px', height : '40px'}}>Download</button></div><div className="date_field">{el.senddate}</div></>;
                      case "NOTI":
                        return  <><article className="notice">{el.message}</article>
                        <p className="date_area">
                          <span>{el.senddate}</span>
                        </p></>;
                      case "AUTO":
                        return <><div className="img"><img src={ProfileImg} alt="" /></div><div className="date_field">{el.senddate}</div></>;
                      default:
                        return null;
                    }
                  })()}
                  
                </div>
              </article>
            </>;

          } else if (el.to === user.user_seq) {
            return <><article className="send">
              <div className="wrap">
                {(() => {
                  switch (el.type) {
                    case "CHAT":
                      return <><div className="box">{el.message}</div><div className="date_field">{el.senddate}</div></>;
                    case "IMG":
                      return <><div className="img"><img src={el.img} alt="" /></div><div className="date_field">{el.senddate}</div></>;
                    case "INFO":
                      return <><div className='info_area'><p className='date_field'>{el.senddate}</p></div><div className="date_field">{el.senddate}</div></>;
                    case "FILE":
                      return <><div className="box">{el.message}<br /><button type="button" style={{backgroundColor:'orangered', color: 'white', width : '140px', height : '40px'}} onClick={()=> handleFileDownload(el.img)}>Download</button></div><div className="date_field">{el.senddate}</div></>;
                    case "NOTI":
                      return <><article className="notice">{el.message}</article>
                      <p className="date_area">
                        <span>{el.senddate}</span>
                      </p></>;
                    case "AUTO":
                      return <><div className="img"><img src={ProfileImg} alt="" /></div><div className="date_field">{el.senddate}</div></>;
                    default:
                      return null;
                  }
                })()}
                
              </div>
            </article></>;
          }
        })

        }
      </>

  );
}
export default memo(ChatEvents)