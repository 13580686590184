import React from "react";
import { Route, Routes } from "react-router-dom";
import Approve from "../views/screens/page/member/Approve";
import Approving from "../views/screens/page/member/Approving";
import CorporateOnly from "../views/screens/page/member/CorporateOnly";
import Join from "../views/screens/page/member/Join";
import JoinOk from "../views/screens/page/member/JoinOk";
import Login from "../views/screens/page/member/Login";
import NewPw from "../views/screens/page/member/NewPw";
import NoApprove from "../views/screens/page/member/NoApprove";
import SearchId from "../views/screens/page/member/SearchId";
import SearchIdOk from "../views/screens/page/member/SearchIdOk";
import SearchPw from "../views/screens/page/member/SearchPw";
/**
 *
 * @path
 * /member/*
 */
export default function RouterMember() {
  return (
    <>
      <Routes>
        <Route path="/join" element={<Join />} />
        <Route path="/login" element={<Login />} />
        <Route path="/corporate-only" element={<CorporateOnly />} />
        <Route path="/approve" element={<Approve />} />
        <Route path="/approving" element={<Approving />} />
        <Route path="/no-approve" element={<NoApprove />} />
        <Route path="/JoinOk" element={<JoinOk />} />
        <Route path="/SearchId" element={<SearchId />} />
        <Route path="/SearchIdOk" element={<SearchIdOk />} />
        <Route path="/SearchPw" element={<SearchPw />} />
        <Route path="/NewPw" element={<NewPw />} />
      </Routes>
    </>
  );
}
