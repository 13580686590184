import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCustomQuery } from "../../../../../../hooks/useAPI";
import { useForm } from "react-hook-form";
import { updateBackend } from "../../../../../../api";
import { toast } from "react-toastify";

export default function CategoryDetail() {
  const navigate = useNavigate();

  const { cate_seq } = useParams();

  const [category, setCategory] = useState();
  const [isUpdating, setIsUpdating] = useState(false);

  const { isFetching, isRefetching, refetch } = useCustomQuery("/category?cate_seq=" + cate_seq, {
    queryKey: ["getCategory/" + cate_seq],
    select: (res) => (res?.data.categories.length > 0 ? res?.data.categories[0] : null),
    onSuccess: (data) => {
      setCategory(data);
    },
    refetchOnWindowFocus: false,
  });

  const { handleSubmit, register } = useForm({
    mode: "onChange",
  });

  const handleUpdateCategory = async (data) => {
    if (!category) return;
    setIsUpdating(true);
    const finalData = {
      cate_seq: Number(cate_seq),
      sort_id: category["sort_id"],
      cate_gr: "MENTO_CATE",
      parent_seq: category["parent_seq"],
      ...data,
    };

    const response = await updateBackend("/category", {
      data: finalData,
      headers: { "Content-Type": "application/json" },
    }).then(async (data) => await data.json());

    setIsUpdating(false);

    if (response.messageCode === 200) {
      toast.success("성공적으로 카테고리를 생성했습니다");
    } else toast.error("카테고리를 만들지 못했습니다.");

    await refetch();
  };

  if (isFetching || !category) return <i className="gg-spinner-big" />;

  return (
    <form onSubmit={handleSubmit(handleUpdateCategory)}>
      <h1 className="adm_com_pg_tit2">카테고리 관리</h1>
      <h2 className="adm_com_pg_tit bottom_line">- 카테고리 추가 {isRefetching && <i className="gg-spinner-alt" />}</h2>
      <div className="adm_com_pg_content">
        <div className="sub_tit"> </div>
        <table className="adm_com_board카테고리 추가ow" style={{ width: "50%" }}>
          <colgroup>
            <col width="10%" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              {/* Category Name */}
              <th>항목명</th>
              <td className="input_wrap">
                <input
                  type="text"
                  required
                  {...register("cate_name")}
                  defaultValue={category["cate_name"]}
                  className="input"
                />
              </td>
            </tr>
            <tr>
              {/* Category Description */}
              <th>카테고리 설명</th>
              <td className="input_wrap">
                <input
                  type="text"
                  required
                  {...register("cate_desc")}
                  className="input"
                  defaultValue={category["cate_desc"]}
                />
              </td>
            </tr>
            <tr>
              {/* Actively Seeking Employment */}
              <th>사용여부</th>
              <td>
                <select required {...register("use_YN")} defaultValue={category["use_YN"]}>
                  <option value={"Y"}>Y</option>
                  <option value={"N"}>N</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="adm_com_btn_wrap center bottom">
        <button className="adm_com_btn gray" type="submit">
          업데이트 카테고리 {isUpdating && <i className="gg-spinner-alt" />}
        </button>
        <button className="adm_com_btn gray" type="button" onClick={() => navigate(-1)}>
          돌아가기
        </button>
      </div>
    </form>
  );
}
