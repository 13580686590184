import React, { useEffect, useState } from "react";
import Modal from "../../component/Modal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthenticatedQuery } from "../../../../../hooks/useAPI";
import { useForm } from "react-hook-form";
import { updateBackend } from "../../../../../api";
import { getFileLink } from "../../../../../utils/misc";
import Table from "../../../screens/components/boffice/Table";

export default function CompanyDetail() {
  const navigate = useNavigate();

  const location = useLocation();

  const [passwordModalActive, setPasswordModalActive] = useState(false);
  const [pointsModalActive, setPointsModalActive] = useState(false);

  const { data, isFetching, isRefetching, refetch } = useAuthenticatedQuery(
    ["company/" + location.pathname.split("/").pop()],
    "/admin/company/" + location.pathname.split("/").pop(),
  );

  const [compData, setCompData] = useState({});

  const [errors, setErrors] = useState({});

  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    const updateCompData = async () => {
      const response = await data?.json();

      if (response.data.data_list.length === 0 || isFetching) {
        return;
      } else {
        setCompData(response.data.data_list[0]);
      }
    };

    data && !data.bodyUsed && updateCompData();
  }, [data]);

  const {
    formState: { touchedFields },
    handleSubmit,
    register,
    getValues,
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
  });

  const handleUserUpdate = async () => {
    setIsUpdating(true);

    let updates = Object.keys(touchedFields).reduce(
      (keys, currentKey) =>
        compData[currentKey] !== getValues(currentKey)
          ? {
              ...keys,
              [currentKey]: getValues(currentKey),
            }
          : { ...keys },
      {},
    );

    if (watch("user_reject"))
      updates = { ...updates, rejection_reason: watch("rejection_reason"), user_status: "US50" };
    if (watch("user_accept")) updates = { ...updates, user_status: "US10" };

    compData &&
      (await updateBackend("/admin/user/" + compData["user_seq"], {
        data: { ...updates },
        headers: { "Content-Type": "application/json" },
      }));

    await refetch();
    setIsUpdating(false);
  };

  const handlePasswordUpdate = async () => {
    if (getValues("new_user_pwd") !== getValues("new_user_pwd_match")) {
      setErrors((prev) => ({ ...prev, mismatchedPassword: true }));
      return;
    }
    setIsUpdating(true);

    if (compData) {
      setIsUpdating(true);

      const response = await updateBackend("/admin/user/update-password", {
        data: {
          user_seq: compData["user_seq"],
          current_password: getValues("old_user_pwd"),
          new_password: getValues("new_user_pwd"),
        },
        headers: { "Content-Type": "application/json" },
      }).then(async (data) => await data.json());

      setIsUpdating(false);

      console.log(response);

      if (response.messageCode === 404) setErrors((prev) => ({ ...prev, incorrectCurrentPassword: true }));
      else if (response.messageCode === 200) setPasswordModalActive(false);

      await refetch();
    }
  };

  if (isFetching && !isRefetching) return <i className="gg-spinner-alt" />;

  if (compData)
    return (
      <main>
        {/* Company Info */}
        <h2 className="adm_com_pg_tit2 bottom_line"> 회원 관리</h2>

        <div className="adm_com_pg_content">
          <h2 className="sub_tit">- 기업 회원 관리 &gt; 상세 </h2>
          <table className="adm_com_board_list type_row">
            <colgroup>
              <col width="10%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                {/* Company Registration Number */}
                <th>사업자등록번호</th>
                <td>{compData["comp_reg_no"]}</td>
              </tr>
              <tr>
                {/* Company Name */}
                <th>회사명</th>
                <td>{compData["comp_name"]}</td>
              </tr>
              <tr>
                {/* Representative Name */}
                <th>대표자명</th>
                <td>{compData["ceo_name"]}</td>
              </tr>
              <tr>
                {/* Company Address */}
                <th>회사주소</th>
                <td>{compData["comp_address"]}</td>
              </tr>
              <tr>
                {/* Attached business registration certificate */}
                <th>사업자등록증 첨부</th>
                <td>
                  <Link to={getFileLink(compData["reg_certi_seq"])} style={{ textDecoration: "underline" }}>
                    Document
                  </Link>
                </td>
              </tr>
              <tr>
                {/* About Us */}
                <th>회사소개</th>
                <td>{compData["comp_intro"]}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <form className="adm_com_pg_content" onSubmit={handleSubmit(handleUserUpdate)}>
          <h2 className="sub_tit"> 회원관리</h2>

          {/* <div className="sub_tit">개인정보</div> */}
          <table className="adm_com_board_list type_row">
            <colgroup>
              <col width="10%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                {/* User ID */}
                <th>아이디</th>
                <td>{compData["user_id"]}</td>
              </tr>
              <tr>
                {/* Password */}
                <th>비밀번호</th>
                <td className="input_wrap">
                  <button onClick={() => setPasswordModalActive(true)} className="adm_com_btn gray">
                    비밀번호 변경
                  </button>
                </td>
              </tr>
              <tr>
                {/* Username */}
                <th>이름</th>
                <td>
                  <input
                    type="text"
                    defaultValue={compData["user_name"]}
                    {...register("user_name")}
                    className="input"
                  />
                </td>
              </tr>
              <tr>
                {/* Phone Number */}
                <th>휴대폰 번호</th>
                <td>
                  <input
                    type="text"
                    defaultValue={compData["user_mobile"]}
                    {...register("user_mobile")}
                    className="input"
                  />
                </td>
              </tr>
              <tr>
                {/* Email */}
                <th>이메일</th>
                <td>
                  <input
                    type="text"
                    defaultValue={compData["user_email"]}
                    {...register("user_email")}
                    className="input"
                  />
                </td>
              </tr>
              <tr>
                {/* User Status */}
                <th>승인여부</th>
                <td>
                  {compData["user_status"] && (
                    <>
                      {/* Approve */}
                      <input
                        type="checkbox"
                        className="com_chk"
                        id="Approve"
                        defaultChecked={compData["user_status"] === "US10"}
                        onClick={() => !watch("user_accept") && setValue("user_reject", false)}
                        {...register("user_accept")}
                      />
                      <label htmlFor="Approve">승인</label>
                      {/* Reject */}

                      <input
                        type="checkbox"
                        className="com_chk"
                        id="Reject"
                        defaultChecked={compData["user_status"] === "US50"}
                        onClick={() => !watch("user_reject") && setValue("user_accept", false)}
                        {...register("user_reject")}
                      />
                      <label htmlFor="Reject">거부</label>
                      {watch("user_reject") && (
                        <input
                          className="input"
                          style={{ marginBlock: "4px" }}
                          type="text"
                          {...register("rejection_reason", { required: watch("user_reject") })}
                        />
                      )}
                    </>
                  )}
                </td>
              </tr>
              <tr>
                {/* Registration Date */}
                <th>가입일자</th>
                <td>{new Date(compData["reg_dtm"]).toLocaleString()}</td>
              </tr>
              <tr>
                {/* Membership Type */}
                <th>회원유형</th>
                <td>
                {compData["user_type"] === "PSN" ? "일반회원" : "기업회원"}
                  {/* <select className="select" defaultValue={compData["user_type"]} {...register("user_type")}>
                    <option value={"COM"}>기업회원</option>
                    <option value={"PSN"}>일반회원</option>
                  </select> */}
                </td>
              </tr>
            </tbody>
          </table>
        </form>
        <div className="adm_com_btn_wrap center bottom">
          <button className="adm_com_btn gray" onClick={handleUserUpdate}>
            수정하기 {isUpdating && <i className="gg-spinner-alt" />}
          </button>
          <button className="adm_com_btn gray" onClick={() => navigate(-1)}>
            돌아가기
          </button>
        </div>
        {/* Modal */}
        {/* Modal */}
        <Modal open={passwordModalActive} close={() => setPasswordModalActive(false)}>
          <div className="pop_tit">비밀번호 변경</div>
          <div className="content_container scroll">
            <ul className="adm_com_input_sec">
              <li className="tit_field">현재 비밀번호</li>
              <li className="input_field">
                <input
                  type="password"
                  className="input"
                  placeholder="영문, 숫자, 특수문자 포함 8~16 자리"
                  {...register("old_user_pwd", {
                    minLength: 8,
                    maxLength: 32,
                    validate: (val) => {
                      if (getValues("user_pwd") !== val) {
                        setErrors((prev) => ({ ...prev, mismatchedPassword: true }));
                        return false;
                      }
                    },
                  })}
                />
                {errors["incorrectCurrentPassword"] && (
                  <span className="error_txt">* 보안에 취약한 비밀번호 입니다.</span>
                )}

                {/* <span className="error_txt">* 보안에 취약한 비밀번호 입니다.</span> */}
              </li>
              <li className="tit_field">새로운 비밀번호</li>
              <li className="input_field">
                <input
                  type="password"
                  className="input"
                  placeholder="영문, 숫자, 특수문자 포함 8~16 자리"
                  {...register("new_user_pwd", {
                    minLength: 8,
                    maxLength: 32,
                  })}
                />
                {errors["mismatchedPassword"] && <span className="error_txt">* 비밀번호가 일치하지 않습니다.</span>}
              </li>
              <li className="tit_field">비밀번호 재입력</li>
              <li className="input_field">
                <input
                  type="password"
                  className="input"
                  placeholder="영문, 숫자, 특수문자 포함 8~16 자리"
                  {...register("new_user_pwd_match", {
                    minLength: 8,
                    maxLength: 32,
                    validate: (val) => {
                      if (getValues("new_user_pwd") !== val) {
                        setErrors((prev) => ({ ...prev, mismatchedPassword: true }));
                        return false;
                      }
                    },
                  })}
                />
                {errors["mismatchedPassword"] && <span className="error_txt">* 비밀번호가 일치하지 않습니다.</span>}
              </li>
            </ul>
          </div>
          <div className="btn_container">
            <button
              className="adm_com_btn line gray xs"
              onClick={async () => {
                setPasswordModalActive(false);
                await refetch();
              }}>
              취소
            </button>
            <button className="adm_com_btn gray xs" onClick={handlePasswordUpdate}>
              변경 {isUpdating && <i className="gg-spinner-alt" />}
            </button>
          </div>
        </Modal>
        <Modal open={pointsModalActive} close={() => setPointsModalActive(false)}>
          <div className="pop_tit">포인트 내역</div>
          <Table
            data={[]}
            columns={{
              division: "구분",
              accumulation: "적립 내용",
              floating_amount: "변동금액",
              floating_date: "변동일자",
            }}
          />
        </Modal>
      </main>
    );
}
