import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

//import VideoImage from "../../assets/img/img_video.png";
import ImgInsight01 from "../../assets/img/img_insight01.png";
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import Modal from "../../components/modal";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import { AuthContext } from "../../../../../context/Auth";
import { mutateBackend } from "../../../../../api";
import { addComma } from "../../../../../utils/misc";

function Detail() {
  const { isAuthenticated } = useContext(AuthContext);
  const [paymentModal, setPaymentModal] = useState(false);
  const [Watch, setWatch] = useState(false);

  const { insight_seq } = useParams();
  const { user: userData } = useContext(AuthContext);

  const { newInfo: user } = userData;

  const commentRef = useRef({ value: "" });
  const navigate = useNavigate();
  
  const { data: insight, refetch: refetchInsight } = useCustomQuery(`/insight/${+insight_seq}`, {
    queryKey: ["getInsightDetail", { insight_seq }],
    select: (res) => res?.data,
  });

  const { data: insightComments, refetch: refetchComments } = useCustomQuery(
    `/insight/comments?insig_seq=${+insight_seq}`,
    {
      queryKey: ["getInsightComments", { insight_seq }],
      select: (res) => res?.data?.comments,
    },
  );

  useEffect(() => {
    insight?.insig_seq && user?.user_seq && updateViewCount();
  }, [insight?.insig_seq, user?.user_seq]);

  const handleComment = async () => {
    const response = await mutateBackend("/insight/comments", {
      data: {
        insig_seq: +insight_seq,
        comment: commentRef.current?.value,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (data) => await data.json())
      .catch((err) => {
        throw new Error(err);
      });
    if (response.messageString === "SUCCESS" && response.messageCode === 200) {
      console.log(response.data);
      commentRef.current.value = "";
      refetchComments();
    } else {
      console.log(response);
    }
  };

  const handleLike = async (obj, isLikeComment = false) => {
    const data = {
      user_seq: user?.user_seq,
    };
    isLikeComment ? (data.cmt_idx = obj.cmt_idx) : (data.insig_seq = obj.insig_seq);
    const response = await mutateBackend(
      `/insight${isLikeComment ? "/comments/" : "/"}${obj?.likedBy?.includes(user?.user_seq) ? "unlike" : "like"}`,
      {
        data,
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
      .then(async (data) => await data.json())
      .catch((err) => {
        throw new Error(err);
      });
    if (response.messageString === "SUCCESS" && response.messageCode === 200) {
      console.log(response.data);
      isLikeComment ? await refetchComments() : await refetchInsight();
    } else {
      console.log(response);
    }
  };

  const updateViewCount = async () => {
    const data = {
      user_seq: user?.user_seq,
      insig_seq: insight?.insig_seq,
    };
    const response = await mutateBackend(`/insight/view`, {
      data,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (data) => await data.json())
      .catch((err) => {
        throw new Error(err);
      });
    if (response.messageString === "SUCCESS" && response.messageCode === 200) {
      await refetchInsight();
    } else {
      console.log(response);
    }
  };

  const [replyToComment, setReplyToComment] = useState(null);

  const handleReply = async (parentComment, replyText) => {
    const response = await mutateBackend("/insight/comments", {
      data: {
        insig_seq: +insight_seq,
        comment: replyText,
        parent_cmt_idx: parentComment,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (data) => await data.json())
      .catch((err) => {
        throw new Error(err);
      });
    if (response.messageString === "SUCCESS" && response.messageCode === 200) {
      console.log(response.data);
      commentRef.current.value = "";
      refetchComments();
    } else {
      console.log(response);
    }
    setReplyToComment(null);
  };

  //EV::시청하기
  const handleClickWatch = () => {
    if(isAuthenticated){
      setWatch(true);
    } else {
      console.log('로그인');
      navigate('/member/login');
    }
  };

  return (
    <main className="com_detail p_detail">
      <div className="com_center_wrap flex">
        <section className="sec_detail">
          <div className="title_wrap">
            <div className="heading_wrap">
              <div className="header">
                <h2 className="tit">{insight?.insig_name}</h2>
                <span className="price">{insight?.insig_price > 0 ? `${addComma(insight?.insig_price)} P` : '무료'}</span>
              </div>
              <div className="like_wrap">
                <span className="com_icon eye">{insight?.insig_views}</span>
                <span
                  className={`com_icon ${insight?.likedBy?.includes(user?.user_seq) ? "liked" : "like"}`}
                  onClick={() => handleLike(insight)}>
                  {insight?.insig_likes}
                </span>
                <span className="com_icon comment">{insight?.insig_comments}</span>
                <span className="com_icon bell">신고</span>
              </div>
            </div>
            <div className="com_tag_list">
              {insight?.tags.map((tag, index) => {
                return (
                  <span key={index} className="com_btn bg s lightblue">
                    #{tag}
                  </span>
                );
              })}
            </div>
          </div>
          <div className="video_wrap">
            {Watch ? (
              <video muted autoPlay loop width={"100%"} controls playsInline>
                <source src={insight?.media_url} type="video/mp4" />
              </video>
            ) : (
              /*썸네일 - 꽉차게 해주세요 */
              <img className="img" src={insight?.media_thumb} width={"100%"} alt="동영상" />

            )}
          </div>
          <div className="button_wrap">
            {insight?.insig_price === 0 || insight?.paied_YN === "Y" ? (
              <button type="button" className="com_btn blue payment" onClick={() => handleClickWatch()}>
              시청하기
            </button>
            ) : (
                <Link to={`/Insight/Pay/${insight_seq}`} className="com_btn blue payment">
                  결제하기
                </Link>
                )
            }
          </div>
          <div className="description_wrap">{insight?.insig_desc}</div>
          <div className="comment_box_wrap">
            <div className="comment_input_box">
              <input type="text" placeholder="댓글 내용을 입력해주세요." className="comment_input" ref={commentRef} />
              <button className="com_btn blue btn" onClick={handleComment}>
                등록
              </button>
            </div>

            {insightComments?.map((comment) => {
              return (
                <>
                  <div className="comment_wrap">
                    <div className="user">
                      <p className="username">
                        {comment.user_name} <span className="duration">1시간 전</span>
                      </p>
                      <span
                        className={comment?.likedBy?.includes(user?.user_seq) ? "liked" : "like"}
                        onClick={() => handleLike(comment, true)}>
                        {comment.favor_cnt}
                      </span>
                    </div>
                    <p className="desc">{comment.comment}</p>

                    <span
                      style={{
                        color: "#4e92ff",
                        fontSize: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => setReplyToComment(comment)} // Set the comment being replied to
                    >
                      ↩ 답글
                    </span>
                  </div>
                  {comment === replyToComment && ( // Show input field if replying to this comment
                    <div className="comment_input_box">
                      <input
                        type="text"
                        placeholder="댓글 내용을 입력해주세요."
                        className="comment_input"
                        ref={commentRef}
                      />
                      <button
                        className="com_btn blue btn"
                        onClick={() => handleReply(comment.cmt_idx, commentRef.current.value)}>
                        등록
                      </button>
                    </div>
                  )}
                  {comment?.replies?.length > 0 &&
                    comment.replies.map((reply) => {
                      return (
                        <div className="comment_wrap reply" key={reply.cmt_idx}>
                          <>
                            <div className="user">
                              <p className="username">
                                {reply.user_name} <span className="duration">1시간 전</span>
                              </p>
                              <span
                                className={reply?.likedBy?.includes(user?.user_seq) ? "liked" : "like"}
                                onClick={() => handleLike(reply, true)}>
                                {reply.favor_cnt}
                              </span>
                            </div>
                            <p className="desc">{reply.comment}</p>
                          </>
                        </div>
                      );
                    })}
                </>
              );
            })}
          </div>
        </section>
        <section className="sec_other">
          <div className="list">
            <div className="heading">다른 인사이트</div>
            <div className="content_wrap">
              <img src={ImgInsight01} alt="thumbnail" className="img" />
              <div className="description">
                <p className="title">자동차 설비분야의 엔지니어 멘토들에게 특급</p>
                <div className="username">dief568</div>
                <span className="details">좋아요 54 ㆍ 댓글 2 ㆍ 조회수 2</span>
              </div>
            </div>
            <div className="content_wrap">
              <img src={ImgInsight01} alt="thumbnail" className="img" />
              <div className="description">
                <p className="title">자동차 설비분야의 엔지니어 멘토들에게 특급</p>
                <div className="username">dief568</div>
                <span className="details">좋아요 54 ㆍ 댓글 2 ㆍ 조회수 2</span>
              </div>
            </div>
            <div className="content_wrap">
              <img src={ImgInsight01} alt="thumbnail" className="img" />
              <div className="description">
                <p className="title">자동차 설비분야의 엔지니어 멘토들에게 특급</p>
                <div className="username">dief568</div>
                <span className="details">좋아요 54 ㆍ 댓글 2 ㆍ 조회수 2</span>
              </div>
            </div>
            <div className="content_wrap">
              <img src={ImgInsight01} alt="thumbnail" className="img" />
              <div className="description">
                <p className="title">자동차 설비분야의 엔지니어 멘토들에게 특급</p>
                <div className="username">dief568</div>
                <span className="details">좋아요 54 ㆍ 댓글 2 ㆍ 조회수 2</span>
              </div>
            </div>
          </div>
          <Link to="/Insight" className="list_btn">
            인사이트 목록으로{" "}
          </Link>
        </section>
      </div>
      {/* top button */}
      <ScrollToTopButton />
      {/* modal */}
      <Modal open={paymentModal} close={() => setPaymentModal(false)} type="type_apply">
        <p className="message">신고가 완료되었습니다.</p>
        <button className="com_btn blue check">확인</button>
      </Modal>
    </main>
  );
}

export default Detail;
