import React, { useState, useCallback, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import "../../assets/scss/page/mypage.scss";
import "../../assets/scss/page/company.scss";
import ProfileImg from "../../assets/img/user-profile-placeholder.svg";

import IconEdit from "../../assets/img/icon_write.png";
import { AuthContext } from "../../../../../context/Auth";
import { getImageLink } from "../../../../../utils/misc";

function CompanySideBar() {
  const { user } = useContext(AuthContext);

  const sideBarLinks = [
    {
      title: "기업정보",
      link: "/Company/Info",
    },
    {
      title: "공고관리",
      link: "/Company/Job",
    },
    {
      title: "채용관리",
      link: "/Company/Recruit",
    },
    {
      title: "기업성향",
      link: "/Company/Vision",
    },
    {
      title: "알림내역",
      link: "/Company/Notice",
    },
    {
      title: "고객센터",
      link: "/Company/Cs",
    },
    {
      title: "헤드헌팅",
      link: "/Company/Hh",
    },
  ];

  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive((prev) => !prev);
  };

  const renderLnb = useCallback(() => {
    return sideBarLinks.map((v, idx) => {
      return (
        <NavLink to={v.link} key={idx} className={`menu ${isActive ? "active" : ""}`}>
          {v.title}
        </NavLink>
      );
    });
  }, [isActive, sideBarLinks]);

  return (
    <>
      <aside className="aside">
        <section className="profile_area">
          <button className="btn_edit">
            <img src={IconEdit} alt="수정" />
          </button>
          <div className="profile">
            <img
              src={user.comp_image_seqs ? getImageLink(Number(user.comp_image_seqs.split(",")[0])) : ProfileImg}
              alt="프로필"
            />
          </div>
        </section>
        <section className="id_area">
          {user["comp_name"]}
          <Link to="/Company/Info" className="com_btn s lightgray oval">
            수정
          </Link>
        </section>
        <section className={isActive ? "lnb active" : "lnb"}>{renderLnb()}</section>
        <div className="notice_txt">기업성향 설문을 진행하시면 회사와 성향이 맞는 인재를 구인 할 수 있습니다.</div>
        <button className="selected_menu" onClick={handleClick}>
          Company Page Title
        </button>
      </aside>
    </>
  );
}

export default CompanySideBar;
