import React, { useState } from "react";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import Table from "../../../screens/components/boffice/Table";
import Paging from "../../component/Paging";
import ApplyFilter from "../../component/ApplyFilter";
import useCommCode from "../../../../../hooks/useCommCode";

const columns = {
  index: "번호",
  comp_name: "회사명",
  recruit_title: "공고 제목",
  emp_type: "고용형태",
  total: "총 지원자",
  SS00: "지원신청",
  SS10: "지원중",
  SS20: "서류통과",
  SS90: "서류거절",
  SS30: "면접제안",
  SS50: "합격",
  SS99: "불합격",
  // * If it is a link, it passes an object
  detail: {
    label: "상세",
    getLink: (item) => "/boffice/ai/apply/detail/" + item["recruit_seq"],
    linkLabel: "상세",
  },
};

export default function AiMatchingApplyList() {
  const [page, setPage] = useState(1);
  const [recruitsPerPage, setRecruitsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const [filterItems, setFilterItems] = useState({});
  const [paramArguments, setParamArguments] = useState("");

  const [applicationList, setApplicationList] = useState([]);

  // const { data: applicationStatus } = useCommCode("SUPP_STATUS");
  const { data: employmentType } = useCommCode("EMP_TYPE");

  const { isFetching } = useCustomQuery(
    `/recruit/list?per_page=${recruitsPerPage}&curr_page=${page}&admin=admin&` + paramArguments,
    {
      queryKey: ["getRecruitApplication/", paramArguments, page, recruitsPerPage],
      select: (res) => res?.data,
      onSuccess: async (data) => {
        setTotalCount(data.total_cnt);
        setApplicationList(data.data_list);
      },
      refetchOnWindowFocus: false,
    },
  );

  // 조회 버튼 클릭 시 이벤트 처리
  const handleSearchParamsChange = () => {
    const params = new URLSearchParams(paramArguments);
    Object.keys(filterItems).forEach((key) => params.set(key, filterItems[key]));

    setParamArguments(params.toString());
  };

  const handlePageClick = (toPage) => {
    if (Number.isInteger(toPage)) return setPage(toPage);

    switch (toPage) {
      case "first": {
        setPage(1);
        break;
      }
      case "next": {
        page < Math.floor(totalCount / recruitsPerPage) && setPage((prev) => prev + 1);
        break;
      }
      case "prev": {
        page > 1 && setPage((prev) => prev - 1);
        break;
      }
      case "last": {
        setPage(Math.ceil(totalCount / recruitsPerPage));
        break;
      }
    }
  };

  return (
    <main>
      <h2 className="adm_com_pg_tit2 bottom_line">지원 관리</h2>
      <div className="adm_com_pg_content">
        <h2 className="sub_tit">- 지원 관리 &gt; 상세</h2>
        <ApplyFilter
          columns={columns}
          dataPerPage={recruitsPerPage}
          setDataPerPage={setRecruitsPerPage}
          setParamArguments={setParamArguments}
          filterItems={filterItems}
          setFilterItems={setFilterItems}
          handleSearchParamsChange={handleSearchParamsChange}
          additionalFields={
            <>
              <div className="adm_com_input_wrap flex_row">
                <span className="tit">고용형태</span>
                <select
                  className="select"
                  onChange={(e) =>
                    setFilterItems((prev) => ({
                      ...prev,
                      emp_type: e.target.value,
                    }))
                  }>
                  <option value={""}>전체</option>
                  {employmentType &&
                    employmentType.map((el, index) => (
                      <option key={index} value={el.comm_code}>
                        {el.code_nm_kr}
                      </option>
                    ))}
                </select>
              </div>
              {/* <div className="adm_com_input_wrap flex_row">
                <span className="tit">상태</span>
                <select
                  className="select"
                  onChange={(e) =>
                    setFilterItems((prev) => ({
                      ...prev,
                      appli_status: e.target.value,
                    }))
                  }>
                  <option value={""}>전체</option>
                  {applicationStatus &&
                    applicationStatus.map((el, index) => (
                      <option key={index} value={el.comm_code}>
                        {el.code_nm_kr}
                      </option>
                    ))}
                </select>
              </div> */}
            </>
          }
        />

        <div className="sub_tit">
          <span className="txt flex-start">
            {totalCount} 건 {isFetching && <i className="gg-spinner-alt" />}
          </span>
        </div>
        <Table
          data={applicationList?.map((apply) => ({ ...apply }))}
          columns={columns}
          checked={false}
          handleCheck={null}
          page={page}
        />
        <Paging
          handlePageClick={handlePageClick}
          page={page}
          totalPageCount={Math.ceil(totalCount / recruitsPerPage)}
        />
      </div>
    </main>
  );
}
