import React, { useContext } from "react";
import { Link } from "react-router-dom";
//component
import Pagination from "../../../components/Pagination";
//image
import iconGraph from "../../../assets/img/icon_match_graph.png";
import { mutateBackend } from "../../../../../../api";
import { AuthContext } from "../../../../../../context/Auth";
import useCommCode from "../../../../../../hooks/useCommCode";

function MatchWishCompany({ jobList, total_page, refetchJobList, setSearchParams, page, limit }) {
  const { user } = useContext(AuthContext);

  const { data: locationTypeData } = useCommCode(`LOCA_CD`);

  const handleNextClick = () => {
    if (page < total_page) {
      setSearchParams((prev) => {
        prev.set("page", page + 1);
        return prev;
      });
    }
  };

  const handlePrevClick = () => {
    if (page > 1) {
      setSearchParams((prev) => {
        prev.set("page", page - 1);
        return prev;
      });
    }
  };

  const handlePerPageChange = (e) => {
    setSearchParams((prev) => {
      prev.set("page", 1);
      prev.set("limit", +e.target.value);
      return prev;
    });
  };

  const handleLike = async (obj) => {
    const data = {
      recruit_seq: obj?.recruit_seq,
    };
    const response = await mutateBackend(`/recruit${obj?.likedBy?.includes(user?.user_seq) ? "/unlike" : "/like"}`, {
      data,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (data) => await data.json())
      .catch((err) => {
        throw new Error(err);
      });
    if (response.messageString === "SUCCESS" && response.messageCode === 200) {
      console.log(response.data);
      refetchJobList();
    } else {
      console.log(response);
    }
  };
  return (
    <div>
      <ul className="list">
        {jobList?.map((item, index) => (
          <li
            className="com_job_item"
            key={index}
            style={{
              margin: "8px",
            }}>
            <p className="tit">{item?.recruit_title}</p>
            <div className="info">
              <p className="info_tit">
                {item?.recruit_consider} ·{" "}
                {locationTypeData &&
                  locationTypeData.find((location) => location.comm_code == item?.recruit_location)?.code_nm_kr}{" "}
                근무 마감: ~{new Date(item?.close_date).toLocaleString()}
              </p>
              <p className="info_s">
                {item?.comp_name} <span className="icon">{item?.recruit_job}</span>
              </p>
              <ul className="tag_list">
                {item?.recruit_tags &&
                  item?.recruit_tags?.split(",")?.map((tag, i) => (
                    <li key={i} className="tag_item">
                      #{tag}
                    </li>
                  ))}
              </ul>
            </div>
            <Link to={`/match?recruit_seq=${item?.recruit_seq}`} className="btn_more" />
            <button className="btn_like active" onClick={() => handleLike(item)} />

            {/* 아이콘 active 시 active class 추가 */}
            <div className="graph">
              <span className="percent">95%</span>
              <span className="icon_graph">
                <img src={iconGraph} alt="graph icon" />
              </span>
            </div>
            {item?.recruit_status === "CLOSED" && (
              <div className="com_overlay type_circle">
                <div className="bg"></div>
                <div className="alert_box">
                  <span className="txt">마감된 공고</span>
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
      <Pagination
        currentPage={page}
        perPage={limit}
        totalPages={total_page}
        onNextClick={handleNextClick}
        onPrevClick={handlePrevClick}
        handlePerPageChange={handlePerPageChange}
      />
    </div>
  );
}

export default MatchWishCompany;
