import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import "../../assets/scss/page/member.scss";
import Pagination from "../../components/Pagination";
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";

function PayInsight() {
  const [searchParams, setSearchParams] = useSearchParams({ page: 1, limit: 6 });
  const page = +searchParams.get("page");
  const limit = +searchParams.get("limit");

  const { isFetching, data: { dataList = [], totalCnt = 0 } = { dataList: [], totalCnt: 0 } } = useCustomQuery(
    `/payments/comm?goods_type=INSIGHT&curr_page=${page}&per_page=${limit}`,
    {
      queryKey: ["getPaymentListPoints", page, limit],
      select: (res) => res?.data ?? { dataList: [], totalCnt: 0 },
    },
  );

  return (
    <>
      <div className="container">
        <h2 className="com_pg_tit">결제 콘텐츠 목록</h2>
        <section className="com_pg_tab">
          <Link to="/Mypage/PayInsight" className="tab active">
            결제 인사이트 목록
          </Link>
          <Link to="/Mypage/PayMentoring" className="tab">
            결제 멘토링 목록
          </Link>
        </section>
        <section className="com_board_list__top">
          <article className="btn_wrap">
            <button className="com_btn blue oval xs">삭제</button>
          </article>
        </section>
        <table className="com_board_list">
          <colgroup>
            <col width="6%" />
            <col />
            <col width="13%" />
            <col width="13%" />
            <col width="13%" />
            <col width="13%" />
          </colgroup>
          <thead>
            <tr>
              <th></th>
              <th>상품명</th>
              <th>카테고리</th>
              <th>결제금액</th>
              <th>구매일자</th>
              <th>남은 시청기간</th>
            </tr>
          </thead>
          <tbody>
            {isFetching ? (
              <i className="gg-spinner-alt" />
            ) : dataList?.length > 0 ? (
              dataList?.map((obj) => (
                <tr key={obj?.pay_seq}>
                  <td className="chk_field">
                    <input type="checkbox" className="com_chk" id={obj?.user_seq} />
                    <label htmlFor={obj?.user_seq}></label>
                  </td>
                  <td className="tit_field">{obj?.goods_name}</td>
                  <td className="state_field">{obj?.goods_type}</td>
                  <td className="info_field">
                    <span className="th_m">결제금액 :</span>
                    {obj?.pay_method === "POINTS" ? `${obj?.use_point}P` : `${+obj?.request_amt * +obj?.join_num}원`}
                  </td>
                  <td className="date_field">
                    <span className="th_m">구매일자 :</span>
                    {new Date(obj.reg_dtm).toLocaleDateString()}
                  </td>
                  <td className="info_field last">
                    <span className="th_m">남은 시청기간 :</span>
                    {obj?.pay_status}일
                  </td>
                </tr>
              ))
            ) : (
              <h3>등록된 내역이 없습니다.</h3>
            )}
          </tbody>
        </table>

        <Pagination
          currentPage={page}
          totalPages={Math.ceil(totalCnt / limit)}
          perPage={limit}
          setSearchParams={setSearchParams}
        />
      </div>
      {/* top button */}
      <ScrollToTopButton />
    </>
  );
}

export default PayInsight;
