import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import useCommCode from "../../../../../hooks/useCommCode";
import Table from "../../../screens/components/boffice/Table";
import useCommCate from "../../../../../hooks/useCommCate";
import { getNameCommCode, nvl } from "../../../../../utils/Common.utils";
import { getFileLink } from "../../../../../utils/misc";

const getDesiredJob = (cate_seq, desiredJob) => {
  const child_job = desiredJob.find((job) => job["cate_seq"] === cate_seq);

  if (child_job) {
    const parent_job = desiredJob.find((job) => job["cate_seq"] === child_job["parent_seq"]);
    return parent_job ? `${parent_job["cate_name"]} > ${child_job["cate_name"]}` : child_job["cate_name"];
  }

  return "";
};

export default function ResumeView() {
  const navigate = useNavigate();

  const { resume_seq } = useParams();

  const [resumeDetail, setResumeDeatil] = useState("");

  const { isFetching } = useCustomQuery(`/resume/view/${resume_seq}`, {
    queryKey: ["getResumeDetail", resume_seq],
    select: (res) => res?.data,
    onSuccess: async (data) => {
      setResumeDeatil(data);
    },
  });

  const { data: employmentType = [] } = useCommCode("EMP_TYPE");
  const { data: locationType = [] } = useCommCode("LOCA_CD");
  const { data: desiredJob = [] } = useCommCate("RECU_JOB");
  const { data: linkType = [] } = useCommCode("LINK_CATE");

  if (isFetching || !resumeDetail) return <i className="gg-spinner-big" />;

  return (
    <main>
      <h2 className="adm_com_pg_tit2 bottom_line"> 지원 관리</h2>
      <div className="adm_com_pg_content">
        <h2 className="sub_tit">- 지원 관리 &gt; 상세 &gt; 이력서 상세</h2>

        <h2 className="sub_tit">근무희망조건</h2>
        <table className="adm_com_board_list type_row">
          <colgroup>
            <col width="10%" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th>고용형태</th>
              <td>{getNameCommCode(resumeDetail.resume_info.emp_type, employmentType)}</td>
            </tr>
            <tr>
              <th>희망연봉</th>
              <td>
                {resumeDetail.resume_info.resume_salary === 0 ? "면접 후 결정" : resumeDetail.resume_info.resume_salary}
              </td>
            </tr>
            <tr>
              <th>희망근무지</th>
              <td>{resumeDetail.desired_loca.map((el) => getNameCommCode(el.location_cd, locationType)).join(", ")}</td>
            </tr>
            <tr>
              <th>희망 직무</th>
              <td>{resumeDetail.desired_job.map((el) => getDesiredJob(el.cate_seq, desiredJob)).join(", ")}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="adm_com_pg_content">
        <h2 className="sub_tit">인적사항</h2>
        <table className="adm_com_board_list type_row">
          <colgroup>
            <col width="10%" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th>이름</th>
              <td>{resumeDetail.resume_info.resume_name}</td>
            </tr>
            <tr>
              <th>연락처</th>
              <td>{resumeDetail.resume_info.resume_mobile}</td>
            </tr>
            <tr>
              <th>생년월일</th>
              <td>{resumeDetail.resume_info.resume_birthday}</td>
            </tr>
            <tr>
              <th>성별</th>
              <td>{resumeDetail.resume_info.resume_gender === "M" ? "남성" : "여성"}</td>
            </tr>
            <tr>
              <th>이메일</th>
              <td>{resumeDetail.resume_info.resume_email}</td>
            </tr>
            <tr>
              <th>주소</th>
              <td>{resumeDetail.resume_info.resume_addr1 + resumeDetail.resume_info.resume_addr2}</td>
            </tr>
            <tr>
              <th>이력서 이미지</th>
              <td>
                <Link to={getFileLink(resumeDetail.resume_info.image_seq)}>
                  {resumeDetail.resume_info.file_real_name}
                </Link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="adm_com_pg_content">
        <h2 className="sub_tit">학력</h2>
        <Table
          columns={{
            index: "번호",
            acad_type: "학력",
            acad_name: "학교명",
            acad_major: "전공명",
            entrance_date: "입학년월",
            finish_date: "졸업년월",
            acad_status: "상태",
          }}
          data={resumeDetail.resume_academic.map((academy) => ({ ...academy }))}
          page={1}
        />
      </div>
      <div className="adm_com_pg_content">
        <h2 className="sub_tit">인증경력</h2>
        <Table
          columns={{
            index: "번호",
            career_company: "회사명",
            career_depart: "부서명",
            career_posi: "직책",
            join_date: "입사일",
            leave_date: "퇴직일",
            career_status: "상태",
          }}
          data={resumeDetail.resume_career.filter((el) => el.career_type === "CERTI")?.map((career) => ({ ...career }))}
          page={1}
        />
      </div>
      <div className="adm_com_pg_content">
        <h2 className="sub_tit">추가경력</h2>
        <Table
          columns={{
            index: "번호",
            career_company: "회사명",
            career_depart: "부서명",
            career_posi: "직책",
            join_date: "입사일",
            leave_date: "퇴직일",
            career_status: "상태",
          }}
          data={resumeDetail.resume_career.filter((el) => el.career_type === "ADD")?.map((career) => ({ ...career }))}
          page={1}
        />
      </div>
      <div className="adm_com_pg_content">
        <h2 className="sub_tit">자격증</h2>
        <Table
          columns={{
            index: "번호",
            certi_name: "자격증명",
            certi_insti: "발행처",
            certi_acqu_date: "취득월",
          }}
          data={resumeDetail.resume_certificate.map((certificate) => ({ ...certificate }))}
          page={1}
        />
      </div>

      <div className="adm_com_pg_content">
        <h2 className="sub_tit">보유스킬</h2>
        <textarea readOnly value={resumeDetail.resume_info.resume_skill}></textarea>
      </div>
      <div className="adm_com_pg_content">
        <h2 className="sub_tit">Tool</h2>
        <textarea readOnly value={resumeDetail.resume_info.resume_tools}></textarea>
      </div>
      <div className="adm_com_pg_content">
        <h2 className="sub_tit">링크</h2>
        <table className="adm_com_board_list type_row">
          <colgroup>
            <col width="10%" />
            <col />
          </colgroup>
          <tbody>
            {resumeDetail.link_url.map((link) => (
              <tr key={link.link_seq}>
                <th>{getNameCommCode(link.link_cate, linkType)}</th>
                {nvl(link.file_real_name) !== "" ? (
                  <td>
                    <Link to={getFileLink(link.link_val)}>{link.file_real_name}</Link>
                  </td>
                ) : (
                  <td>{link.link_val}</td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="adm_com_pg_content">
        <h2 className="sub_tit">자기소개</h2>
        <textarea readOnly value={resumeDetail.resume_info.resume_intro}></textarea>
      </div>

      <div className="adm_com_btn_wrap center bottom">
        <button className="adm_com_btn gray" onClick={() => navigate(-1)}>
          목록 보기
        </button>
      </div>
    </main>
  );
}
