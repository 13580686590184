import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../../../../context/Auth";
import SnsNaver from "../../assets/img/login_sns_naver.png";
import { useNavigate } from "react-router-dom";

export default function NaverLogin({ formType, FormType }) {
  const { naver } = window;
  const { snslogin } = useContext(AuthContext);
  const navigate = useNavigate();
  const naverLogin = naver && new naver.LoginWithNaverId({
    clientId: process.env.REACT_APP_NAVER_CLIENT_ID,
    callbackUrl: `https://${window.location.host}/member/login`,
    isPopup: true,
    loginButton: {
      color: "green",
      type: 3,
      height: 50,
    },
  });

  useEffect(() => {
    naverLogin?.init();
    console.log("init!");
    getUser();
  }, []);

  const getUser = async () => {
    await naverLogin?.getLoginStatus(async (status) => {
      if (status) {
        const url = new URL(window.location.href);
        // 토큰 체크
        if (String(url).indexOf("access_token") > 0) {
          window.opener.location.href = `https://${window.location.host}/member/login?click`;
          window.close();
        }
        //백엔드로 회원정보 보내기
        const result = await snslogin({ credentialResponse: naverLogin?.user, type: "naver", userType: formType === FormType.General ? "PSN" : "COM" });

        //회원가입

        if (String(url).indexOf("click") > 0) {
          if (result === true) {
            const redirectPath = sessionStorage.getItem("redirectPath");
            //로그인 및 회원 가입 분기
            if (redirectPath) {
              if (redirectPath.toLocaleLowerCase().startsWith("/company") && formType === FormType.General)
                navigate("/");
              else if (redirectPath.toLocaleLowerCase().startsWith("/mypage") && formType === FormType.Corporate)
                navigate("/");
              else navigate(redirectPath);
              sessionStorage.removeItem("redirectPath");
            } else {
              navigate("/");
            }
          } else {
            naverLogin?.init();
            navigate(`/member/join?snstype=naver&email=${result.email}&cid=${result.cid}`);
          }
        }
      }
    });
  };

  function onClickNaverlogin() {
    document.querySelector("a#naverIdLogin_loginButton").click();
  }

  return (
    <>
      <div style={{ display: "none" }}>
        <div id="naverIdLogin"></div>
      </div>

      <button type="button" className="btn" onClick={() => onClickNaverlogin()}>
        <img src={SnsNaver} alt="naver" />
      </button>
    </>
  );
}
