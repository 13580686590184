/* eslint-disable no-unused-vars */
import React, {lazy }from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { AuthProvider } from "../../context/Auth";
import BofficeLogin from "../views/boffice/page/Login";
import "../views/screens/assets/scss/styles.scss";
import Footer from "../views/screens/components/Footer";
import Header from "../views/screens/components/Header";
import Main from "../views/screens/page/main";
import AdminRoute from "./AdminRoute";
import BofficeRouter from "./BofficeRouter";
import RouterMember from "./MemberRouter";
import RouterMyPage from "./MyPageRouter";
import ProtectedRoute from "./ProtectedRoute";

import CommunityRouter from "./CommunityRouter";
import CompanyRoutes from "./CompanyRouter";
// const FrontRouter = lazy(() => import('./FrontRouter'))
// import FrontRouter from "./FrontRouter";
import InsightRoutes from "./InsightsRouter";
import MagazineRoutes from "./MagazineRouter";
import MatchRouter from "./MatchRouter";
import MentoringRoutes from "./MentoringRouter";
import SeminarRoutes from "./SeminarRouter";
import EtcRoutes from "./EtcRouter";
import NiceRoutes from "./NiceRouter";

/**
 *
 * @path
 * /screens/*
 */
export default function RouterScreens() {
  const location = useLocation();

  const showHeaderAndFooter =
    !location.pathname.startsWith("/front") &&
    !location.pathname.startsWith("/member") &&
    !location.pathname.startsWith("/boffice");

  return (
    <AuthProvider>
      {showHeaderAndFooter && <Header />}
      <Routes>
        <Route path="/" element={<Main />} />
        {/* <Route path="/front/*" element={<FrontRouter />} /> */}
        <Route path="/boffice/login" element={<BofficeLogin />} />
        <Route
          path="/boffice/*"
          element={
            <AdminRoute>
              <BofficeRouter />
            </AdminRoute>
          }></Route>
        <Route path="/member/*" element={<RouterMember />} />
        <Route
          path="/MyPage/*"
          element={
            <ProtectedRoute>
              <RouterMyPage />
            </ProtectedRoute>
          }
        />
        <Route path="/seminar/*" element={<SeminarRoutes />} />
        <Route path="/magazine/*" element={<MagazineRoutes />} />
        <Route path="/insight/*" element={<InsightRoutes />} />
        <Route path="/mentoring/*" element={<MentoringRoutes />} />
        <Route
          path="/company/*"
          element={
            <ProtectedRoute>
              <CompanyRoutes />
            </ProtectedRoute>
          }
        />
        <Route path="/community/*" element={<CommunityRouter />} />
        <Route path="/match/*" element={<MatchRouter />} />
        <Route path="/etc/*" element={<EtcRoutes />} /> {/* 20231024 added by wem  */}
        {/* <Route path="/mypage/*" element={<RouterMyPage />} /> */}

        <Route path="/nice/*" element={<NiceRoutes />} />
      </Routes>
      {showHeaderAndFooter && <Footer />}
    </AuthProvider>
  );
}
