import React, { useState } from "react";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import useCommCode from "../../../../../hooks/useCommCode";
import PopSuggestion from "./_PopSuggestion";
import Pagination from "../../components/Pagination";
import { Link } from "react-router-dom";

export default function ResumeInterviewApplied() {
  const [per_page, setPerPage] = useState(9);
  const [curr_page, setCurrPage] = useState(1);

  const [modalSuggestionOpen, setModalSuggestionOpen] = useState(false);

  const { data: applications, isFetching: isFetchingApplications } = useCustomQuery(
    `/application/list?per_page=10&curr_page=1&appli_status=SS30`,
    {
      queryKey: ["getApplications", "SS30"],
      select: (res) => res?.data,
    },
  );

  const { data: applicationStatus } = useCommCode("SUPP_STATUS");

  const handleNextClick = () => {
    if (curr_page < Math.ceil(applications?.total_count / per_page)) {
      setCurrPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevClick = () => {
    if (curr_page > 1) {
      setCurrPage((prevPage) => prevPage - 1);
    }
  };

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value, 10));
  };

  return (
    <div className="tab_content">
      <p className="notice_txt type_suggestion">
        <span className="dot blue"></span>면접제안을 보낸 인재들의 목록입니다.
        <button className="com_btn bg blue oval btn_del">삭제</button>
      </p>
      <table className="com_board_list">
        <colgroup>
          <col width="5%" />
          <col width="11%" />
          <col />
          <col width="8%" />
          <col width="12%" />
          <col width="10%" />
          <col width="10%" />
          <col width="10%" />
          <col width="11%" />
        </colgroup>
        <thead>
          <tr>
            <th></th>
            <th>날짜</th>
            <th>공고 제목</th>
            <th>이름</th>
            <th>제안을 보낸 문구</th>
            <th>수락 상태</th>
            <th>제안 마감 기한</th>
            <th>면접 확정일자</th>
            <th>이력서</th>
          </tr>
        </thead>
        <tbody>
          {isFetchingApplications ? (
            <i className="gg-spinner-alt" />
          ) : applications?.data_list?.length !== 0 ? (
            applications?.data_list?.map((application, index) => {
              return (
                <tr key={index}>
                  <td className="info_field">
                    <span className="td_m">
                      <input type="checkbox" id={`chk` + index + ``} className="com_chk circle" />
                      <label htmlFor={`chk` + index + ``}></label>
                    </span>
                  </td>
                  <td className="info_field info_date">{application.reg_dtm.split("T")[0]}</td>
                  <td className="tit_field">{application.recruit_title}</td>
                  <td className="tit_field first com_point_txt">{application.name}</td>
                  <td className="info_field info_btn">
                    <button
                      className="com_btn oval line s"
                      onClick={() => {
                        setModalSuggestionOpen(true);
                      }}>
                      문구 보기
                    </button>
                  </td>
                  <td className="info_field info_status">
                    {applicationStatus &&
                      applicationStatus.find((status) => status["comm_code"] === application.appli_status)?.[
                        "code_nm_kr"
                      ]}
                  </td>
                  <td className="info_field info_due_date">
                    <span className="th_m">제안 마감 기한</span>
                    {application.proposal_deadline?.split("T")[0]}
                  </td>
                  <td className="info_field info_due_date">
                    <span className="th_m">면접 확정일자</span>2023.01.01
                  </td>
                  <td className="info_field info_btn">
                    <Link
                      to={`/Company/Resume/${application.resume_seq}?recruit_seq=${application.recruit_seq}`}
                      className="com_btn oval line s">
                      이력서 보기
                    </Link>
                  </td>
                </tr>
              );
            })
          ) : (
            <h3>등록된 내역이 없습니다.</h3>
          )}
        </tbody>
      </table>
      {!isFetchingApplications && (
        <Pagination
          currentPage={curr_page}
          totalPages={Math.ceil(applications?.total_cnt / per_page)}
          onNextClick={handleNextClick}
          onPrevClick={handlePrevClick}
          perPage={per_page}
          handlePerPageChange={handlePerPageChange}
        />
      )}
      <PopSuggestion open={modalSuggestionOpen} close={() => setModalSuggestionOpen(false)} />
    </div>
  );
}
