import React from "react";
import { Route, Routes } from "react-router-dom";

import MentoringList from "./../views/screens/page/mentoring/List";
import MentoringListMember from "./../views/screens/page/mentoring/ListMember";
import MentoringProduct from "./../views/screens/page/mentoring/MentoringProduct";
import MentoringIntro from "./../views/screens/page/mentoring/Intro";
import MentoringPay from "./../views/screens/page/mentoring/MentoringPay";
import ProtectedRoute from "./ProtectedRoute";

function mentoringRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<MentoringList />} />
        <Route path="/Intro" element={<MentoringIntro />} />
        <Route path="/ListMember" element={<MentoringListMember />} />
        <Route
          path="/MentoringProduct"
          element={
            <ProtectedRoute>
              <MentoringProduct />
            </ProtectedRoute>
          }
        />
        <Route
          path="/MentoringProduct/:mento_seq"
          element={
            <ProtectedRoute>
              <MentoringProduct />
            </ProtectedRoute>
          }
        />
        <Route path="/MentoringPay/:mento_seq" element={<MentoringPay />} />
      </Routes>
    </>
  );
}

export default mentoringRoutes;
