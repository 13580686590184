import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import moment from "moment";
import useCommCode from "../../../../../hooks/useCommCode";
import useCommCate from "../../../../../hooks/useCommCate";
import { getNameCommCode } from "../../../../../utils/Common.utils";

export default function RecruitView() {
  const navigate = useNavigate();
  const { recruit_seq } = useParams();

  const [recruit, setRecruit] = useState("");
  const [parentCategory, setParentCategory] = useState();
  // const [selectedCategories, setSelectedCategories] = useState([]);

  const { isFetching } = useCustomQuery(`/recruit/list?per_page=${1}&curr_page=${1}&recruit_seq=${recruit_seq}`, {
    queryKey: ["getRecruitList"],
    select: (res) => res?.data,
    onSuccess: (data) => {
      const recruit = data.data_list[0];
      setRecruit(recruit);
      // setImageSeq(recruit.image_seq);
      // setRecruitTags(String(recruit.recruit_tags).split(","));
      // setSelectedCategories(
      //   String(recruit.recruit_categories)
      //     .split(",")
      //     .map((cate_seq) => Number(cate_seq)),
      // );
    },
    refetchOnWindowFocus: false,
  });

  const { data: locationTypeData = [] } = useCommCode(`LOCA_CD`);
  const { data: employmentTypes = [] } = useCommCode("EMP_TYPE");
  // const { data: recruitTypes = [] } = useCommCode("RECU_CLOSE");
  const { data: categoryTypes = [] } = useCommCate("RECU_JOB");
  console.log(recruit);

  if (isFetching || !recruit) return <i className="gg-spinner-big" />;
  return (
    <main>
      <h2 className="adm_com_pg_tit2 bottom_line"> 공고 관리</h2>
      <div className="adm_com_pg_content">
        <h2 className="sub_tit">- 공고 내역 &gt; 상세 </h2>
        <table className="adm_com_board_list type_row">
          <colgroup>
            <col width="10%" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th>회사명</th>
              <td className="input_wrap">
                <input className="input" type="text" value={recruit.comp_name} />
              </td>
            </tr>
            <tr>
              <th>공고 제목</th>
              <td className="input_wrap">
                <input className="input" type="text" value={recruit.recruit_title} style={{ width: "25%" }} />
              </td>
            </tr>
            <tr>
              <th>공고 태그</th>
              <td className="input_wrap">
                <ul className="com_tag_list select_list">
                  {recruit.recruit_tags &&
                    recruit.recruit_tags
                      .split(",")
                      .map((el, i) => <button className="com_btn lightgray" key={i}>{`#${el}`}</button>)}
                </ul>
              </td>
            </tr>
            <tr>
              <th>고용 형태</th>
              <td>
                <select style={{ width: "11%" }} value={recruit.emp_type}>
                  <option value="">전체</option>
                  {employmentTypes &&
                    employmentTypes.map((type, i) => (
                      <option key={i} value={type.comm_code}>
                        {type.code_nm_kr}
                      </option>
                    ))}
                </select>
              </td>
            </tr>
            <tr>
              <th>공고기간</th>
              <td>
                <input type="date" value={moment(recruit.open_date).format("YYYY-MM-DD")} />
                <input type="date" value={moment(recruit.close_date).format("YYYY-MM-DD")} />
                <input
                  id="date_01"
                  className="com_chk"
                  type="checkbox"
                  name="date"
                  checked={recruit.close_type === "ANY"}
                />
                <label htmlFor="date_01">상시채용</label>
                <input
                  id="date_02"
                  className="com_chk"
                  type="checkbox"
                  name="date"
                  checked={recruit.close_type === "UNTI"}
                />
                <label htmlFor="date_02">채용 시 마감</label>
              </td>
            </tr>
            <tr>
              <th>직무</th>
              <td className="input_wrap">
                <select
                  onChange={(e) => {
                    setParentCategory(Number(e.target.value));
                  }}
                  style={{ width: "11%" }}>
                  <option value="">선택해주세요</option>
                  {categoryTypes?.map(
                    (category, i) =>
                      category &&
                      category["parent_seq"] === 0 && (
                        <option value={category["cate_seq"]} key={i}>
                          {category["cate_name"]}
                        </option>
                      ),
                  )}
                </select>
                <select style={{ width: "13%" }}>
                  <option value="">선택해주세요</option>
                  {categoryTypes?.map(
                    (category, i) =>
                      category &&
                      category["parent_seq"] === parentCategory && (
                        <option value={category["cate_seq"]} key={i}>
                          {category["cate_name"]}
                        </option>
                      ),
                  )}
                </select>
                <ul className="com_tag_list select_list">
                  <button className="com_btn lightgray">#11</button>
                  <button className="com_btn lightgray">#22</button>
                </ul>
              </td>
            </tr>
            <tr>
              <th>경력</th>
              <td className="input_wrap">
                <input className="input" type="text" value={recruit.career_min} />
                <input className="input" type="text" value={recruit.career_max} />
                <input id="chk_01" className="com_chk" type="checkbox" checked={recruit.newcomer_YN === "Y"} />
                <label htmlFor="chk_01">신입</label>
              </td>
            </tr>
            <tr>
              <th>근무지역</th>
              <td>
                <input
                  className="input"
                  type="text"
                  value={getNameCommCode(recruit.recruit_location, locationTypeData) || ""}
                />
                <button className="adm_com_btn gray">주소 검색</button>
              </td>
            </tr>
            <tr>
              <th>주요업무</th>
              <td className="input_wrap">
                <textarea className="input" type="text" value={recruit.recruit_job} style={{ width: "100%" }} />
              </td>
            </tr>
            <tr>
              <th>우대사항</th>
              <td className="input_wrap">
                <textarea className="input" type="text" value={recruit.recruit_consider} style={{ width: "100%" }} />
              </td>
            </tr>
            <tr>
              <th>혜택 및 복지</th>
              <td className="input_wrap">
                <textarea className="input" type="text" value={recruit.recruit_benefit} style={{ width: "100%" }} />
              </td>
            </tr>
            <tr>
              <th>노출여부</th>
              <td>
                <select style={{ width: "11%" }}>
                  <option></option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="adm_com_btn_wrap center bottom">
        <button className="adm_com_btn gray" onClick={() => navigate(-1)}>
          목록
        </button>
        <button className="adm_com_btn blue">수정하기</button>
      </div>
    </main>
  );
}
