import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCustomQuery } from "../../../../../../hooks/useAPI";
import useCommCate from "../../../../../../hooks/useCommCate";

export default function MentoringPaymentDetail() {
  const navigate = useNavigate();

  const { pay_seq } = useParams();

  const [payment, setPayment] = useState();

  const { isFetching } = useCustomQuery("/payments/mentoring/" + pay_seq, {
    queryKey: ["getMentoringPayment/" + pay_seq],
    select: (res) => res?.data.dataList[0],
    onSuccess: async (data) => {
      if (!data) navigate(-1);
      setPayment(data);
    },
    refetchOnWindowFocus: false,
  });

  const { data: categories, isFetching: isCateFetching } = useCommCate("MENTO_CATE");

  console.log(payment);
  if (isFetching || isCateFetching || !payment) return <i className="gg-spinner-big" />;

  return (
    <main>
      <h2 className="adm_com_pg_tit2 bottom_line"> 멘토 상품 관리</h2>

      <div className="adm_com_pg_content">
        <h2 className="sub_tit">- 상품 결제 내역 &gt; 상세 </h2>
        <h2 className="sub_tit">상품 정보</h2>

        {/* Product Information */}
        <table className="adm_com_board_list type_row">
          <colgroup>
            <col width="10%" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              {/* Product Name */}
              <th>상품명</th>
              <td>{payment["goods_name"]}</td>
            </tr>
            <tr>
              {/* Product Amount */}
              <th>상품 금액</th>
              <td>{payment["pay_amt"]}</td>
            </tr>
            <tr>
              {/* Category */}
              <th>이메일</th>
              <td>
                {categories.find((cate) => cate["comm_code"] === payment["mento_cate"]) &&
                  categories.find((cate) => cate["comm_code"] === payment["mento_cate"])["code_nm_kr"]}
              </td>
            </tr>
            <tr>
              {/* Registration Date */}
              <th>승인일</th>
              <td>{new Date(payment["mento_reg_dtm"]).toISOString().split("T")[0]}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* Registrant Information */}
      <div className="adm_com_pg_content">
        <h2 className="sub_tit"> 상품 등록자 정보</h2>
        <table className="adm_com_board_list type_row">
          <colgroup>
            <col width="10%" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              {/* User ID */}
              <th>아이디</th>
              <td>{payment["registrant_id"]}</td>
            </tr>
            <tr>
              {/* User Name */}
              <th>이름</th>
              <td>{payment["registrant_name"]}</td>
            </tr>
            <tr>
              {/* User Email */}
              <th>이메일</th>
              <td>{payment["registrant_email"]}</td>
            </tr>
            <tr>
              {/* User Mobile */}
              <th>휴대폰 번호</th>
              <td>{payment["registrant_mobile"]}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Buyer Information */}
      <div className="adm_com_pg_content">
        <h2 className="sub_tit"> 상품 구매자 정보</h2>
        <table className="adm_com_board_list type_row">
          <colgroup>
            <col width="10%" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              {/* User ID */}
              <th>아이디</th>
              <td>{payment["buyer_id"]}</td>
            </tr>
            <tr>
              {/* User Name */}
              <th>이름</th>
              <td>{payment["buyer_name"]}</td>
            </tr>
            <tr>
              {/* User Email */}
              <th>이메일</th>
              <td>{payment["buyer_email"]}</td>
            </tr>
            <tr>
              {/* User Mobile */}
              <th>휴대폰 번호</th>
              <td>{payment["buyer_mobile"]}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Payment Information */}
      <div className="adm_com_pg_content">
        <h2 className="sub_tit"> 결제 정보</h2>
        <table className="adm_com_board_list type_row">
          <colgroup>
            <col width="10%" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              {/* Payment Method */}
              <th>결제방식</th>
              <td>{payment["pay_method"]}</td>
            </tr>
            <tr>
              {/* Payment Amount */}
              <th>결제금액</th>
              <td>{payment["goods_amt"]}</td>
            </tr>
            <tr>
              {/* Used Points */}
              <th>사용한 이벤트 포인트</th>
              <td>{payment["use_point"]}</td>
            </tr>
            <tr>
              {/* Normal Points */}
              <th>사용한 일반 포인트</th>
              <td>0 p</td>
            </tr>
            <tr>
              {/* Amount Paid */}
              <th>총 결제금액</th>
              <td>{payment["pay_amt"]}</td>
            </tr>
            <tr>
              {/* Payment Date */}
              <th>결제일자</th>
              <td>{new Date(payment["reg_dtm"]).toLocaleString()}</td>
            </tr>
            <tr>
              {/* Payment Status */}
              <th>결제상태</th>
              <td>{payment["pay_status"]}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="adm_com_btn_wrap center bottom">
        <button className="adm_com_btn gray" onClick={() => navigate(-1)}>
          돌아가기
        </button>
      </div>
    </main>
  );
}
