import React from "react";

import ArrowRight from "../assets/img/icon_arrow_right.png";
import ArrowRightEnd from "../assets/img/icon_arrow_right_end.png";

function Paging({
  page = 1,
  totalPageCount = 10,
  numPagesToShow = 2,
  handlePageClick = () => false,
  setSearchParams = () => false,
}) {
  return (
    <div className="adm_com_paging">
      <button
        className="btn_first"
        onClick={() => {
          if (page > 1) {
            handlePageClick?.("first");
            setSearchParams?.((prev) => {
              prev.set("page", 1);
              return prev;
            });
          }
        }}>
        <img src={ArrowRightEnd} alt="end" />
      </button>
      <button
        className="btn_prev"
        onClick={() => {
          if (page > 1) {
            handlePageClick?.("prev");
            setSearchParams?.((prev) => {
              prev.set("page", page - 1);
              return prev;
            });
          }
        }}>
        <img src={ArrowRight} alt="prev" />
      </button>

      {[...Array(2 * numPagesToShow + 1).keys()]
        .map((i) => page - numPagesToShow + i)
        .filter((i) => i > 0 && i <= totalPageCount)
        .map((i) => (
          <button
            key={i}
            className={`btn_page ${i === page && "active"}`}
            onClick={() => {
              handlePageClick?.(i);
              setSearchParams?.((prev) => {
                prev.set("page", i);
                return prev;
              });
            }}>
            {i}
          </button>
        ))}

      <button className="btn_next">
        <img
          src={ArrowRight}
          alt="next"
          onClick={() => {
            if (page < totalPageCount) {
              handlePageClick?.("next");
              setSearchParams?.((prev) => {
                prev.set("page", page + 1);
                return prev;
              });
            }
          }}
        />
      </button>
      <button className="btn_last">
        <img
          src={ArrowRightEnd}
          alt="end"
          onClick={() => {
            if (page < totalPageCount) {
              handlePageClick?.("last");
              setSearchParams?.((prev) => {
                prev.set("page", totalPageCount);
                return prev;
              });
            }
          }}
        />
      </button>
    </div>
  );
}

export default Paging;
