import React, { useCallback, useContext, useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import "../../assets/scss/page/match.scss";
//image
import iconAlertBlue from "../../assets/img/icon_alert_blue.png";
//component
import { useCustomQuery } from "../../../../../hooks/useAPI";
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import Modal from "../../components/modal";
import CompanyInfo from "../company/_CompanyInfo";
// import JobItem from "./_JobItem";
import { AuthContext } from "../../../../../context/Auth";
import { getImageLink } from "../../../../../utils/misc";
import PopApply from "./_PopApply";
import PopReview from "./_PopReview";
import TopSlideBanner from "./_TopSlideBanner";
import { mutateBackend } from "../../../../../api";
import useCommCode from "../../../../../hooks/useCommCode";

//2023-11-22 공고상세, 기존 MatchList 에 있는 것을 분리 - KSR

function Detail() {
  const { recruit_seq } = useParams();
  const { isAuthenticated, user } = useContext(AuthContext);
  //const [searchParams] = useSearchParams({ recruit_seq: 0 });
  const { data: locationTypeData } = useCommCode(`LOCA_CD`);
  //const recruit_seq = +searchParams.get("recruit_seq");

  const [tabState, setTabState] = useState(0);
  const tab = [
    { text: "주요업무", value: 0 },
    { text: "회사소개", value: 1 },
  ];
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMatchOpen, setModalMatchOpen] = useState(false);
  const [modalApplyOpen, setModalApplyOpen] = useState(false);
  const [isResumeCompleted, setIsResumeCompleted] = useState(false);  //이력서 유무
  const [isActive, setIsActive] = useState(false); //찜하기 버튼
  const [perPage, setPerPage] = useState(4);

  const navigate = useNavigate();

  const { data: jobDetails } = useCustomQuery(`/recruit/list?curr_page=1&per_page=1&recruit_seq=${recruit_seq}`, {
    queryKey: ["getRecruit", recruit_seq],
    select: (res) => res?.data?.data_list[0],
    enabled: !!recruit_seq,
  });

  const { data: myRecruitList } = useCustomQuery(`/recruit/list?curr_page=1&per_page=${perPage}&comp_seq=${jobDetails?.comp_seq}`, {
    queryKey: ["getRecruit", jobDetails?.comp_seq, perPage],
    select: (res) => res?.data?.data_list,
    // enabled: tabState === 1,
  });

  /* 2023-11-13 API 변경(내 이력서) KSR
  const { data: resumes } = useCustomQuery(`/resume`, {
    queryKey: ["getResumes"],
    select: (res) => res?.data?.resumes,
    enabled: !!recruit_seq,
  });
  */

  //2023-11-13 내 이력서 - KSR
  const { data: resumes } = useCustomQuery(`/resume/my/list?orderBy=CR.resume_seq`, {
    queryKey: ["getResumes"],
    select: (res) => res?.data?.resumes,
    enabled: !!recruit_seq,
  });

  useEffect(() => {
    // console.log('resumes', resumes); // 임시
    if(resumes?.length > 0){
      setIsResumeCompleted(true);
    }
  }, [resumes]);

  const { data: attachments } = useCustomQuery(`/resume-link?limit=3`, {
    queryKey: ["getAttachments"],
    select: (res) => res?.data?.attachments,
    enabled: !!recruit_seq,
  });

  const checkAuthentication = async () => {
    if (!isAuthenticated) {
      sessionStorage.setItem("redirectPath", location.pathname);
      navigate("/member/login");
    }
    return isAuthenticated;
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  //EV::지원하기
  const handleApply = async () => {
    isResumeCompleted ? setModalApplyOpen(true) : await checkAuthentication();
  };

  const renderTab = useCallback(() => {
    return tab.map((v, idx) => {
      return (
        <button
          key={idx}
          className={`tab ${(tabState === v.value && "active") || ""}`}
          onClick={() => setTabState(v.value)}>
          {v.text}
        </button>
      );
    });
  }, [tab]);

  const handleLike = async (obj) => {
    const data = {
      recruit_seq: obj?.recruit_seq,
    };
    const response = await mutateBackend(`/recruit${obj?.likedBy?.includes(user?.user_seq) ? "/unlike" : "/like"}`, {
      data,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (data) => await data.json())
      .catch((err) => {
        throw new Error(err);
      });
    if (response.messageString === "SUCCESS" && response.messageCode === 200) {
      console.log(response.data);
      // refetchJobList();
    } else {
      console.log(response);
    }
  };

  /* 2023-11-13 사용안함, useState 로 관리 - KSR
  const isResumeCompleted = useMemo(
    () => resumes?.findIndex((item) => item?.top_YN === "Y" || item?.resume_progr >= 50) > -1,
    [resumes],
  );
  */

  return (
    <main className="p_match">
      {/* long banner */}
      <TopSlideBanner />


      {/* company info */}
      <section className="sec_company_info com_bg">
        <div className="com_center_wrap">
          {/* company info */}
          {jobDetails &&
          <div className="com_top_box">
            <CompanyInfo data={jobDetails} />
          </div>}

          {/* buttons */}
          {(jobDetails && user.user_type !== "COM") &&
          <div className="com_btn_wrap center">
            <button className={`com_btn l line icon_star ${isActive ? 'active' : ''}`} onClick={()=> {setIsActive(prevState => !prevState);}}>찜하기</button>
            <button
              className="com_btn l blue"
              onClick={handleApply}
              disabled={/*!recruit_seq || */jobDetails?.recruit_status === "CLOSED"}>
              지원하기
            </button>
          </div>}

          {/* job description */}
          {jobDetails &&
          <div className="contents_box">
            <div className="content_tab">{renderTab()}</div>
            <div className="contents">
              <div className="contents_top">
                <ul className="com_tag_list">
                  {jobDetails?.recruit_tags &&
                    jobDetails?.recruit_tags?.split(",")?.map((tag, i) => (
                      <li key={i} className="com_btn lightblue">
                        #{tag}
                      </li>
                    ))}
                </ul>
                <div className="graph_wrap">
                  <ul className="graph_list">
                    <li className="graph_item">
                      <span className="bg bg_blue" style={{ height: "30%" }}>
                        <span className="bg_txt">30%</span>
                      </span>
                      <span className="graph_txt">근무환경</span>
                    </li>
                    <li className="graph_item">
                      <span className="bg bg_emerald" style={{ height: "55%" }}>
                        <span className="bg_txt">55%</span>
                      </span>
                      <span className="graph_txt">성향태그</span>
                    </li>
                    <li className="graph_item">
                      <span className="bg bg_green" style={{ height: "95%" }}>
                        <span className="bg_txt">95%</span>
                      </span>
                      <span className="graph_txt">인재상</span>
                    </li>
                  </ul>
                  <div className="com_overlay">
                    <div className="bg"></div>
                    <div className="alert_box">
                      <img src={iconAlertBlue} className="icon" alt="alert" />
                      <span className="txt">
                        매칭률을 보기 위해서는 <br />
                        나의 성향을 작성해주세요.
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="p_company_job tab_content">
                <div className="detail_wrap">
                {tabState === 0 ? (
                  <>
                    <img src={getImageLink(jobDetails?.image_seq)} alt="" />
                    <p className="tit">고용형태</p>
                    <p className="txt">{jobDetails?.emp_type_name}</p>
                    <p className="tit">직무</p>
                    <p className="txt">{jobDetails?.recruit_categories_name}</p>
                    <p className="tit">경력</p>
                    <p className="txt">{jobDetails?.newcomer_YN === "Y" ? "신입" : (jobDetails && `경력 ${jobDetails?.career_min} ~ ${jobDetails?.career_max}년`)}</p>
                    <p className="tit">근무지역</p>
                    <p className="txt">{jobDetails?.location}</p>
                    <p className="tit">주요업무</p>
                    <pre className="txt">{jobDetails?.recruit_job}</pre>
                    <p className="tit">자격요건</p>
                    <pre className="txt">{jobDetails?.recruit_qualify}</pre>
                    <p className="tit">우대사항</p>
                    <pre className="txt">{jobDetails?.recruit_consider}</pre>
                    <p className="tit">혜택 및 복지</p>
                    <pre className="txt">{jobDetails?.recruit_benefit}</pre>
                  </>
                  ) : (
                  <div className="p_company_job tab_content">
                    <div className="detail_wrap">
                      <p className="tit">회사소개</p>
                      {jobDetails?.comp_image_seqs &&
                        jobDetails?.comp_image_seqs.split(",").map((imageSeq, index) => (
                          <img
                            key={index}
                            src={getImageLink(Number(imageSeq))}
                            alt={`기업로고-${index}`}
                          />
                        ))}
                      <pre>{jobDetails?.comp_intro}</pre>
                    </div>
                  </div>
                )}
                </div>
                <hr className="divider" />
              </div>
            </div>
          </div>}

          {/* buttons */}
          {(jobDetails && user.user_type !== "COM") &&
          <div className="com_btn_wrap center">
            <button className={`com_btn l line icon_star ${isActive ? 'active' : ''}`} onClick={()=> {setIsActive(prevState => !prevState);}}>찜하기</button>
            <button
              className="com_btn l blue"
              onClick={handleApply}
              disabled={!recruit_seq || jobDetails?.recruit_status === "CLOSED"}>
              지원하기
            </button>
          </div>}

          {(recruit_seq !== 0 && myRecruitList?.length > 1) && (
            <>
              {/* job list */}
              <div className="job_wrap">
                <div className="pg_tit_wrap">
                  <h2 className="com_pg_tit">{jobDetails?.comp_name} &nbsp;채용공고</h2>
                </div>
                <div className="list_wrap">
                <ul className="list">
                  {myRecruitList?.map((item, index) => (
                    <li className="com_job_item" key={index}>
                      <p className="tit">{item?.recruit_title}</p>
                      <div className="info">
                      <p className="info_tit">
                        {jobDetails?.newcomer_YN === "Y" ? "신입" : (jobDetails && `경력 ${jobDetails?.career_min} ~ ${jobDetails?.career_max}년`)}
                      </p>
                      <p className="info_tit">
                        등록일 <span className="bar" /> <span className="date">{item?.open_date.split("T")[0]}</span>{" "}
                      </p>
                      <p className="info_tit">
                        마감일 <span className="bar" />{item?.close_type === "ANY" ? "채용 시 마감" : (item?.close_date.split("T")[0] === "1899-11-29" ? "-" : item?.close_date.split("T")[0])}
                      </p>
                      <p className="info_tit">
                        근무지 <span className="bar" /><span>{locationTypeData?.find((location) => location.comm_code == item?.recruit_location)?.code_nm_kr}</span>
                      </p>
                        <ul className="com_tag_list">
                          {item?.recruit_tags &&
                            item?.recruit_tags?.split(",")?.map((tag, i) => (
                              <li key={i} className="com_btn xs">
                                #{tag}
                              </li>
                            ))}
                        </ul>
                      </div>
                      <Link to={`/match/Detail/${item?.recruit_seq}`} className="btn_more" />
                      <button
                        className={`btn_like ${item?.likedBy?.includes(user?.user_seq) ? "active" : ""}`}
                        onClick={() => handleLike(item)}
                      />
                      {/* 아이콘 active 시 active class 추가 */}
                      {item?.recruit_status === "CLOSED" && (
                        <div className="com_overlay type_circle">
                          <div className="bg"></div>
                          <div className="alert_box">
                            <span className="txt">마감된 공고</span>
                          </div>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
                  <button className="btn_more_job arrow_right" onClick={() => {setPerPage("all")}}>더보기</button>
                </div>
              </div>
              {(user.user_type === "PSN" && Object.keys(user).length > 0) &&
              <div className="job_wrap">
                <div className="pg_tit_wrap">
                  <h2 className="com_pg_tit">
                    AI로 매칭된 <span className="com_point_txt">{user.user_name}</span>님과 딱 맞는 기업입니다.
                  </h2>
                </div>
                {(user.user_type !== "COM" || Object.keys(user).length > 0) &&
                  <div className="list_wrap">
                    <ul className="list">
                      {/* @maheshkumarsoni this is not taking in data so update that */}
                      {/* {Arr4.map((item, index) => (
                        <JobItem key={index} />
                      ))} */}
                    </ul>
                    <button className="btn_more_job arrow_right">더보기</button>
                    <div className="com_overlay">
                      <div className="bg"></div>
                      <div className="alert_box">
                        <img src={iconAlertBlue} className="icon" alt="alert" />
                        <span className="txt">
                          회원가입 및 나의 성향을 작성하시면 ai 매칭을 통해 <br />
                          나에게 맞는 정확한 공고를 추천해드려요!
                        </span>
                      </div>
                    </div>
                  </div>
                }
              </div>}
            </>
          )}
        </div>
      </section>
      {/* top button */}
      <ScrollToTopButton />
      {/* modal */}
      <PopApply
        jobDetails={jobDetails}
        resumes={resumes}
        attachments={attachments}
        open={modalApplyOpen}
        close={() => setModalApplyOpen(false)}
      />
      <PopReview open={modalMatchOpen} close={() => setModalMatchOpen(false)} type="pop_review" />
      {/* alert */}
      <Modal open={modalOpen} close={handleCloseModal} type="type_alert">
        <div className="content_container scroll">
          <strong>이력서 작성이 완료되지 않았습니다.</strong> <br />
          이력서를 작성하시겠습니까?
        </div>
        <div className="btn_container">
          <button className="com_btn blue s" onClick={() => handleCloseModal()}>
            취소
          </button>
          <button className="com_btn line blue s" onClick={() => navigate("/MyPage/MyResume")}>
            확인
          </button>
        </div>
      </Modal>
    </main>
  );
}

export default Detail;
