import React, { useState } from "react";
//component
import Pagination from "../../../components/Pagination";
import { useCustomQuery } from "../../../../../../hooks/useAPI";
import moment from "moment";
import { Link } from "react-router-dom";

function MatchRead() {
  const [applicationPerPage, setApplicationPerPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);

  const [totalReadCount, setTotalReadCount] = useState(0);
  const [readList, setReadList] = useState([]);

  useCustomQuery(
    `/application/list?per_page=${applicationPerPage}&curr_page=${currentPage}&appli_status=SS15&user_type=PSN`,
    {
      queryKey: ["getApplicationList", applicationPerPage, currentPage],
      select: (res) => res?.data,
      onSuccess: (data) => {
        // console.log(data);
        setTotalReadCount(data.total_cnt);
        setReadList(data.data_list);
      },
      refetchOnWindowFocus: false,
    },
  );

  const handleNext = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setCurrentPage((prev) => prev - 1);
  };

  return (
    <div>
      <table className="com_board_list">
        <colgroup>
          <col width="17%" />
          <col />
          <col width="10%" />
          <col width="10%" />
          <col width="25%" />
        </colgroup>
        <thead>
          <tr>
            <th>지원 회사</th>
            <th>지원 공고</th>
            <th>지원일</th>
            <th>고용형태</th>
            <th>지원관리</th>
          </tr>
        </thead>
        <tbody>
          {readList &&
            readList.map((read) => (
              <tr key={read.recruit_seq}>
                <td className="company_field">{read.com_name}</td>
                <td className="tit_field">{read.recruit_title}</td>
                <td className="date_field">
                  <span className="th_m">지원일 :</span>
                  {moment(read.reg_dtm).format("YY.MM.DD")}
                </td>
                <td className="type_field">{read.emp_type_kr}</td>
                <td className="btn_field">
                  <Link to={`/Mypage/ResumeWriting/${read.resume_seq}`} className="com_btn blue line oval s">
                    지원 이력서 보기
                  </Link>
                  <button className="com_btn gray line oval s">취소</button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <section className="com_board_list__bottom">
        <div className="total">
          총 지원 수 <span className="num">{totalReadCount}</span>
        </div>
      </section>
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(totalReadCount / applicationPerPage)}
        onNextClick={handleNext}
        onPrevClick={handlePrevious}
        perPage={applicationPerPage}
        handlePerPageChange={(e) => setApplicationPerPage(e.target.value)}
      />
    </div>
  );
}

export default MatchRead;
