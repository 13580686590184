import React, { useContext } from "react";
import { AuthContext } from "../../../../../context/Auth";
import SnsKakao from "../../assets/img/login_sns_kakao.png";
import { useNavigate } from "react-router-dom";

const { Kakao } = window;

export default function KakaoLogin({ formType, FormType }) {
  const { snslogin } = useContext(AuthContext);

  const navigate = useNavigate();

  const kakaoLoginClickHandler = () => {
    Kakao.Auth.login({
      success: async function (obj) {
        //백엔드로 토큰값 보내기
        const result = await snslogin({ credentialResponse: obj, type: "kakao", userType: formType === FormType.General ? "PSN" : "COM"  });
        //로그인 및 회원 가입 분기
        if (result === true) {
          const redirectPath = sessionStorage.getItem("redirectPath");
          if (redirectPath) {
            if (redirectPath.toLocaleLowerCase().startsWith("/company") && formType === FormType.General) navigate("/");
            else if (redirectPath.toLocaleLowerCase().startsWith("/mypage") && formType === FormType.Corporate)
              navigate("/");
            else navigate(redirectPath);

            sessionStorage.removeItem("redirectPath");
          } else {
            navigate("/");
          }
        } else {
          navigate(`/member/join?type=${formType === FormType.General ? "PSN" : "COM"}&snstype=kakao&email=${result.email}&cid=${result.cid}`);
        }
      },
      fail: function (err) {
        alert(JSON.stringify(err));
      },
    });
  };

  return (
    <>
      <button type="button" className="btn" onClick={() => kakaoLoginClickHandler()}>
        <img src={SnsKakao} alt="kakao" />
      </button>
    </>
  );
}
