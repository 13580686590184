import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/Auth";

function ProtectedRoute({ children }) {
  const { isAuthenticated } = useContext(AuthContext);

  const location = useLocation();

  if (!isAuthenticated) {
    sessionStorage.setItem("redirectPath", location.pathname);
    return <Navigate to="/member/login" />;
  }

  return children;
}

export default ProtectedRoute;
