import { queryBackend } from ".";

export const isIdTaken = async (id) => {
  const statusCode = await queryBackend("/user/check/" + encodeURIComponent(id))
    .then((data) => data.status)
    .catch((err) => {
      throw new Error(err);
    });

  return statusCode === 200 ? true : false;
};

export const isNicknameTaken = async (nickname) => {
  const statusCode = await queryBackend("/user/check-nick/" + encodeURIComponent(nickname))
    .then((data) => data.status)
    .catch((err) => {
      throw new Error(err);
    });
  return statusCode === 200 ? true : false;
};

export const isEmailTaken = async (email) => {
  const statusCode = await queryBackend("/user/check-email/" + encodeURIComponent(email))
    .then((data) => data.status)
    .catch((err) => {
      throw new Error(err);
    });
  return statusCode === 200 ? true : false;
};

export const isMobilePhoneTaken = async (phone) => {
  const statusCode = await queryBackend("/user/check-phone/" + encodeURIComponent(phone))
    .then((data) => data.status)
    .catch((err) => {
      throw new Error(err);
    });
  return statusCode === 200 ? true : false;
};

export const getUserCountByLicense = async (license_number) => {
  const data = await queryBackend("/user/count/business-registration/" + encodeURIComponent(license_number))
    .then(async (data) => await data.json())
    .catch((err) => {
      throw new Error(err);
    });
  return data;
};

export const isMobilePhoneTaken2 = async (phone, type) => {
  const statusCode = await queryBackend("/user/check-phone2/" + encodeURIComponent(phone) + "/" + encodeURIComponent(type))
    .then((data) => data.status)
    .catch((err) => {
      throw new Error(err);
    });
  return statusCode === 200 ? true : false;
};

export const getCompanyInfo = async (comp_reg_no) => {
  const data = await queryBackend("/company/info/" + encodeURIComponent(comp_reg_no))
    .then(async (data) => await data.json())
    .catch((err) => {
      throw new Error(err);
    });
  return data;
};