import React from "react";
import Modal from "../../components/modal";

function PopCategory({ open, close, categories, currentCategory, setValue }) {
  return (
    <div className="p_modal modal_filter">
      {/* modal */}
      <Modal open={open} close={close} type="pop_apply pop_filter">
        <h2 className="pop_tit">카테고리</h2>
        <div className="content_container scroll">
          <div className="info_wrap">
            <div className="list">
              {categories?.length > 0 &&
                categories?.map((obj, index) => (
                  <label key={index} className={"item type_btn " + (obj?.cate_seq === currentCategory ? "active" : "")}>
                    {obj?.cate_name}
                    <input type="checkbox" key={index} onClick={() => setValue("cate_seq", obj?.cate_seq)} />
                  </label>
                ))}
            </div>
          </div>
        </div>
        <div className="btn_container">
          <button className="com_btn blue m BtnPopClose" onClick={close}>
            적용하기
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default PopCategory;
