import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../assets/scss/page/member.scss";
//component
import Pagination from "../../components/Pagination";
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import Modal from "../../components/modal";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import { deleteBackend, mutateBackend } from "../../../../../api";
import { toast } from "react-toastify";
//import { AuthContext } from "../../../../../context/Auth";
import moment from "moment";
import iconTemporary from '../../assets/img/icon_temporary.png';
import iconSave from '../../assets/img/icon_save.png';

function MyResume() {
  //const [disposition, setDisposition] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [per_page, setPerPage] = useState(10);
  const [curr_page, setCurrPage] = useState(1);
  const [seletedRes, setSeletedRes] = useState(null);
  const [maxProg, setMaxProg] = useState(0);

  //const [naviMyFit, setNaviMyFit] = useState("MyFit");

  //const navigate = useNavigate();

  //const { user } = useContext(AuthContext);

  //2023-11-15 이력서(성향) 작성률
  const { data: myQual } = useCustomQuery("/user/qualification?qual_type=recruit&resume_seq=0", {
    queryKey: ["getQual"],
    select: (res) => res?.data,
    enabled: true
  });

  useEffect(() => {
    setMaxProg(myQual?.qualScore);
    console.log('myQual', myQual);
  }, [myQual]);

  const {
    data: resumes,
    isFetching: isFetchingResumes,
    refetch,
  } = useCustomQuery(`/resume/my/list?per_page=${per_page}&curr_page=${curr_page}&orderBy=CR.resume_seq`, {
    queryKey: ["getResumes", per_page, curr_page],
    select: (res) => res?.data,
    refetchOnWindowFocus: false,
  });

  const handleNextClick = () => {
    if (curr_page < Math.ceil(resumes?.total_count / per_page)) {
      setCurrPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevClick = () => {
    if (curr_page > 1) {
      setCurrPage((prevPage) => prevPage - 1);
    }
  };

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value, 10));
  };

  const handleResumeDelete = async (e) => {
    e.preventDefault();

    try {
      await deleteBackend("/resume", {
        data: {
          resume_seq: seletedRes,
        },
        headers: { "Content-Type": "application/json" },
      });
      refetch();
      toast.success("이력서가 삭제되었습니다.");
      setDeleteModal(false);
    } catch (error) {
      toast.error("이력서를 삭제하지 못했습니다.");
    }
  };

  const handleMakeRepresentative = async (e, resume_seq) => {
    e.preventDefault();

    try {
      await mutateBackend("/resume/makeRepresentative", {
        data: {
          resume_seq,
        },
        headers: { "Content-Type": "application/json" },
      });
      refetch();
      toast.success("Resume made representative successfully!");
      setDeleteModal(false);
    } catch (error) {
      toast.error("Failed to make resume representative!");
    }
  };

  return (
    <>
      <div className="container">
        <h2 className="com_pg_tit">이력서</h2>

        <div className="input_description_box">
          <div className="com_resume_progress">
            <p className="tit">이력서 완성도</p>
            <div className="progress_bar tit text_blue">
              <span className="progress">
                <span className="filled" style={{ width: `${maxProg}%` }}></span>
              </span>
              {maxProg}%
            </div>
          </div>
        </div>

        <section className="com_pg_tab">
          <Link to="/mypage/MyResume" className="tab active">
            이력서 관리
          </Link>
          <Link to="/mypage/MyFit2" className="tab">
            나의 성향
          </Link>
          <Link to="/mypage/Attachment" className="tab">
            첨부파일
          </Link>
        </section>

        {/*대표이력서*/}
        <div className="input_description_box">
          <div className="com_resume_progress">
            대표이력서 자리
          </div>
        </div>

        {/*이력서 작성 버튼*/}
        <div className="input_description_box">
          <section className="btn_wrapper">
            {/* <div style={{marginRight: '45px'}}> */}
            <h3 style={{marginRight: '10px'}}><img src={iconSave} alt="icon save" style={{width:'25px', height:'25px'}} /> : 저장</h3>
            <h3 style={{marginRight: '20px'}}><img src={iconTemporary} alt="icon temporary" style={{width:'25px', height:'25px'}} /> : 임시저장</h3>
            {/* </div> */}
            <Link to="/Mypage/ResumeWriting" className="com_btn blue">
              이력서 작성
            </Link>
          </section>
        </div>
        <table className="com_board_list">
          <colgroup>
            <col width="6.3%" />
            <col width="13%" />
            <col />
            <col width="10%" />
            <col width="9%" />
            <col width="11%" />
            <col width="150px" />
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>작성날짜</th>
              <th>이력서 제목</th>
              <th>이력서 구분</th>
              <th>완성도</th>
              <th>대표이력서</th>
              <th>이력서 관리</th>
            </tr>
          </thead>
          <tbody>
            {resumes?.resumes?.length !== 0 ? (
              resumes?.resumes?.map((resume, index) => {
                console.log(resume);
                return (
                  <tr key={resume.resume_seq}>
                    <td className="td_pc">{index + 1}</td>
                    <td className="date_field">{moment(resume.reg_dtm).format("YYYY-MM-DD")}</td>
                    <td className="tit_field">{resume.resume_title}</td>
                    <td className="state_field">{resume.resume_status === 'TEMP' ? <img src={iconTemporary} alt="icon temporary" style={{width:'25px', height:'25px'}} /> : <img src={iconSave} alt="icon save" style={{width:'25px', height:'25px'}} />}</td>
                    <td className="complete_field">{resume.resume_progr}</td>
                    <td className="state_field">
                      {resume.top_YN === "Y" ? (
                        <button className="com_btn line blue oval s">대표</button>
                      ) : (
                        <button
                          className="com_btn line gray oval s"
                          onClick={(e) => {
                            handleMakeRepresentative(e, resume.resume_seq);
                          }}>
                          설정
                        </button>
                      )}
                    </td>
                    <td className="btn_field">
                      <Link to={`/Mypage/ResumeWriting/${resume.resume_seq}`} className="com_btn line gray oval s">
                        수정
                      </Link>
                      <button
                        className="com_btn line gray oval s"
                        onClick={() => {
                          setSeletedRes(resume.resume_seq);
                          setDeleteModal(true);
                        }}>
                        삭제
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="6">데이터가 없습니다</td>
              </tr>
            )}
          </tbody>
        </table>
        {!isFetchingResumes && (
          <Pagination
            currentPage={curr_page}
            totalPages={Math.ceil(resumes?.total_count / per_page) === 0 ? 1 : Math.ceil(resumes?.total_count / per_page)}
            onNextClick={handleNextClick}
            onPrevClick={handlePrevClick}
            perPage={per_page}
            handlePerPageChange={handlePerPageChange}
          />
        )}
      </div>
      {/* top button */}
      <ScrollToTopButton />
      {/* modal */}
      {/* 2023-11-24 모달(성향 작성 유도) 사용 안함 - KSR
      <Modal open={disposition} close={() => setDisposition(false)} type="type_alert">
        <div className="content_container scroll">
          <div className="message_wrapper">
            <strong className="pop_sub_tit">나의 성향을 작성하지 않으셨습니다.</strong>
            <p>나의 성향을 작성하시면 이력서 완성도가 50% 채워집니다</p>
            <p className="point_txt">나의 성향을 작성하시겠습니까?</p>
          </div>
          <div className="com_btn_wrap">
            <button className="com_btn blue s" onClick={() => navigate("/mypage/MyFit")}>
              작성하기
            </button>
            <button className="com_btn line s" onClick={() => setDisposition(false)}>
              취소
            </button>
          </div>
        </div>
      </Modal>
      */}
      <Modal open={deleteModal} close={() => setDeleteModal(false)} type="type_alert">
        <div className="content_container scroll">
          <p>이력서를 삭제 하시겠습니까?</p>
          <div className="com_btn_wrap">
            <button className="com_btn blue s" onClick={handleResumeDelete}>
              이력서 삭제
            </button>
            <button className="com_btn line s" onClick={() => setDeleteModal(false)}>
              취소
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default MyResume;
