import React, { useEffect } from "react";

export default function Modal(props) {
  const { open, close, type, popSize, isLoading = false } = props;
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "";
      };
    }
  });
  return (
    <>
      {open ? (
        <section className={`com_popup ${(type && type) || ""}`}>
          <div className={`pop_container ${(popSize && popSize) || ""}`}>
            <button type="button" className="btn_close" onClick={close} disabled={isLoading} />
            {props.children}
          </div>
          <i className="bg_close" onClick={() => !isLoading && close()}></i>
        </section>
      ) : null}
    </>
  );
}
