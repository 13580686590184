import React from "react";
import { Link } from "react-router-dom";
import "../../assets/scss/page/member.scss";

import Logo from "../../assets/img/logo.png";

export default function CorporateOnly() {
  return (
    <>
      <main className="com_member">
        <h1 className="logo">
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </h1>
        <div className="box com_ok">
          <h2 className="tit">이 페이지는 비즈니스 사용자를위한 것입니다.</h2>
          <p className="tip">비즈니스 사용자라면 다시 로그인하십시오.</p>

          <section className="com_btn_wrap">
            <Link to="/" className="com_btn blue m">
              돌아가기
            </Link>
          </section>
        </div>
      </main>
    </>
  );
}
