import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { deleteBackend, mutateBackend, queryBackend } from "../../../../../api";
import { AuthContext } from "../../../../../context/Auth";
import { getImageLink } from "../../../../../utils/misc";
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import PopDelete from "./_popDelete";
import PopReport from "./_popReport";

function Detail() {
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { isAuthenticated, user } = useContext(AuthContext);

  const [isActionOpen, setIsActionOpen] = useState(false);
  const [article, setArticle] = useState({});
  const [comments, setComments] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isReportOpen, setIsReportOpen] = useState(false);

  const commentRef = useRef({ value: "" });

  useEffect(() => {
    if (+id > 0) {
      fetchArticle();
    }
  }, [id]);

  useEffect(() => {
    article?.board_seq && user?.user_seq && updateViewCount();
  }, [article?.board_seq, user?.user_seq]);

  const fetchArticle = async (refetch = false) => {
    await queryBackend(`/community?board_type=COMMTY&board_seq=${+id}`)
      .then((res) => res.json())
      .then((res) => {
        console.log({ data: res.data });
        setArticle(res?.data?.communities?.[0]);
      })
      .catch((reason) => {
        console.error(reason);
      });
    if (refetch) return;
    await fetchAttachments();
    await fetchComments();
  };

  const fetchComments = async () => {
    await queryBackend(`/community/comments?board_seq=${+id}`)
      .then((res) => res.json())
      .then((res) => {
        console.log({ data: res.data });
        setComments(res?.data?.comments);
      })
      .catch((reason) => {
        console.error(reason);
      });
  };

  const fetchAttachments = async () => {
    await queryBackend(`/community/attachments?board_seq=${+id}`)
      .then((res) => res.json())
      .then((res) => {
        console.log({ data: res.data });
        setAttachments(res?.data?.attachments);
      })
      .catch((reason) => {
        console.error(reason);
      });
  };

  const checkAuthentication = async () => {
    if (!isAuthenticated) {
      sessionStorage.setItem("redirectPath", location.pathname);
      navigate("/member/login");
    }
    return true;
  };

  const handleComment = async () => {
    if ((await checkAuthentication()) && commentRef.current.value) {
      const response = await mutateBackend("/community/comments", {
        data: {
          board_seq: +id,
          user_seq: user?.user_seq,
          comment: commentRef.current?.value,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (data) => await data.json())
        .catch((err) => {
          throw new Error(err);
        });
      if (response.messageString === "SUCCESS" && response.messageCode === 200) {
        console.log(response.data);
        commentRef.current.value = "";
        await fetchComments();
      } else {
        console.log(response);
      }
    }
  };

  const handleOptions = async () => {
    if (await checkAuthentication()) {
      setIsActionOpen(!isActionOpen);
    }
  };

  const handleLike = async (obj, isLikeComment = false) => {
    if (await checkAuthentication()) {
      const data = {
        user_seq: user?.user_seq,
      };
      isLikeComment ? (data.cmt_idx = obj.cmt_idx) : (data.board_seq = obj.board_seq);
      const response = await mutateBackend(
        `/community${isLikeComment ? "/comments/" : "/"}${obj?.likedBy?.includes(user?.user_seq) ? "unlike" : "like"}`,
        {
          data,
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
        .then(async (data) => await data.json())
        .catch((err) => {
          throw new Error(err);
        });
      if (response.messageString === "SUCCESS" && response.messageCode === 200) {
        console.log(response.data);
        isLikeComment ? await fetchComments() : await fetchArticle(true);
      } else {
        console.log(response);
      }
    }
  };

  const handleDeletePost = async () => {
    if (await checkAuthentication()) {
      await deleteBackend(`/community?board_seq=${+id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          console.log({ data: res.data });
          navigate("/community");
        })
        .catch((reason) => {
          console.error(reason);
        });
    }
  };

  const handleReportPost = async (reason = "") => {
    if (await checkAuthentication()) {
      await mutateBackend("/community/report", {
        data: { board_seq: article?.board_seq, user_seq: user?.user_seq, reason },
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          console.log({ data: res.data });
          setIsReportOpen(false);
        })
        .catch((reason) => {
          console.error(reason);
        });
    }
  };

  const updateViewCount = async () => {
    if ((await checkAuthentication()) && !article?.viewedBy?.includes(user?.user_seq)) {
      const data = {
        user_seq: user?.user_seq,
        board_seq: article?.board_seq,
      };
      const response = await mutateBackend(`/community/view`, {
        data,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (data) => await data.json())
        .catch((err) => {
          throw new Error(err);
        });
      if (response.messageString === "SUCCESS" && response.messageCode === 200) {
        await fetchArticle(true);
      } else {
        console.log(response);
      }
    }
  };
  console.log(comments);

  return (
    <main className="com_detail type_community">
      <div className="com_center_wrap">
        <section className="sec_detail">
          <div className="title_wrap">
            <div className="heading_wrap">
              <div className="header">
                <span className="navigation">커뮤니티 &gt; 회사생활</span>
                <h2 className="tit">{article?.board_title}</h2>
                <div className="user">
                  <span className="username">{article?.user_nickname}</span>{" "}
                  {new Date(article?.reg_dtm).toLocaleString()}
                </div>
              </div>
              <div className="like_wrap">
                <span className="com_icon eye">{article?.hit_cnt}</span>
                <span
                  className={`com_icon ${article?.likedBy?.includes(user?.user_seq) ? "liked" : "like"}`}
                  onClick={() => handleLike(article)}>
                  {article?.favor_cnt}
                </span>
                <span className="com_icon comment">{comments?.length}</span>
                <div onClick={handleOptions} className="action_dropdown">
                  <span className="dot"></span>
                  <span className="dot"></span>
                  <span className="dot"></span>
                  {isActionOpen &&
                    (article.user_seq === user.user_seq ? (
                      <ul className="values">
                        <li className="action">
                          <button onClick={() => navigate(`/community/write/${+id}`)}>게시글 수정</button>
                        </li>
                        <li className="action">
                          <button onClick={() => setIsDeleteOpen(true)}>게시글 삭제</button>
                        </li>
                      </ul>
                    ) : (
                      <ul className="values">
                        <li className="action">
                          <button onClick={() => setIsReportOpen(true)}>신고하기</button>
                        </li>
                      </ul>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="description_wrap">
            <pre>{article.board_contents}</pre>
            {attachments?.length > 0 && (
              <div className="img_wrap">
                {attachments?.map((obj) => (
                  <img
                    key={obj.up_file_seq}
                    src={getImageLink(obj.up_file_seq)}
                    alt={obj.file_real_name}
                    className="img"
                  />
                ))}
              </div>
            )}
          </div>
          <div className="comment_box_wrap">
            <div className="comment_input_box">
              <p className="comment">
                댓글 <span className="total">{comments?.length}</span>
              </p>
              <input type="text" placeholder="댓글 내용을 입력해주세요." className="comment_input" ref={commentRef} />
              <button className="com_btn blue btn" onClick={handleComment}>
                등록
              </button>
            </div>
            {comments?.length > 0 &&
              comments?.map((obj) => (
                <div className="comment_wrap" key={obj.cmt_idx}>
                  <div className="user">
                    <p className="username">
                      {obj.user_nickname} <span className="duration">{obj.reg_dtm}</span>
                    </p>
                    <span
                      className={obj?.likedBy?.includes(user?.user_seq) ? "liked" : "like"}
                      onClick={() => handleLike(obj, true)}>
                      {obj.favor_cnt}
                    </span>
                  </div>
                  <pre className="desc">{obj.comment}</pre>
                </div>
              ))}
          </div>
        </section>
      </div>
      {/* top button */}
      <ScrollToTopButton />
      {/* modal */}

      <PopDelete open={isDeleteOpen} close={() => setIsDeleteOpen(false)} onSubmit={handleDeletePost} />

      <PopReport open={isReportOpen} close={() => setIsReportOpen(false)} onSubmit={handleReportPost} />
    </main>
  );
}

export default Detail;
