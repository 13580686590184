import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminSeminarDetails from "../views/boffice/page/seminar/Details";
import AdminSeminarList from "../views/boffice/page/seminar/List";
import AdminSeminarApplicationDetails from "../views/boffice/page/seminar/application/Details";
import AdminSeminarApplicationList from "../views/boffice/page/seminar/application/List";
import AdminSeminarRefundRequestDetails from "../views/boffice/page/seminar/refund-request/Details";
import AdminSeminarRefundRequestList from "../views/boffice/page/seminar/refund-request/List";

export default function BofficeSeminarRouter() {
  return (
    <Routes>
      <Route path="/list" element={<AdminSeminarList />} />
      <Route path="/details/:id" element={<AdminSeminarDetails />} />
      <Route path="/application/list" element={<AdminSeminarApplicationList />} />
      <Route path="/application/details/:id" element={<AdminSeminarApplicationDetails />} />
      <Route path="/refund-request/list" element={<AdminSeminarRefundRequestList />} />
      <Route path="/refund-request/details/:id" element={<AdminSeminarRefundRequestDetails />} />
    </Routes>
  );
}
