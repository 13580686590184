import React from "react";
import { Link, useParams } from "react-router-dom";
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import { getImageLink } from "../../../../../utils/misc";
import DOMPurify from "dompurify";

function Detail() {
  const { mag_seq } = useParams();
  const { data: magazine } = useCustomQuery(`/magazine?mag_seq=${mag_seq}`, {
    queryKey: ["getMagazine", mag_seq],
    select: (res) => res?.data?.magazines?.[0] ?? {},
    enabled: +mag_seq > 0,
    refetchOnWindowFocus: false,
  });

  const { data: relatedMagazines } = useCustomQuery(`/magazine/related?mag_seq=${mag_seq}&tags=${magazine?.mag_tags}`, {
    queryKey: ["getRelatedMagazines", mag_seq],
    select: (res) => res?.data?.relatedMagazines ?? [],
    enabled: magazine?.mag_tags !== undefined,
    refetchOnWindowFocus: false,
  });

  const sanitizedContent = DOMPurify.sanitize(magazine?.mag_content);

  return (
    <main className="com_detail p_magazine_detail">
      <div className="com_center_wrap flex">
        {magazine && (
          <section className="sec_detail">
            <div className="title_wrap">
              <h2 className="tit">{magazine.mag_title}</h2>
              <p className="time">2022.11.24 14:00:20</p>
              <div className="com_tag_list">
                {magazine.mag_tags
                  ? magazine.mag_tags.split(",").map((tag, index) => (
                      <span key={index} className="com_btn s bg lightblue">
                        {tag}
                      </span>
                    ))
                  : null}
              </div>
            </div>
            <div className="description_wrap">
              {/* <img src={getImageLink(magazine.mag_image)} alt="" className="image" /> */}
              <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
            </div>
            <div className="button_wrap">
              <Link to={"/Magazine"} className="com_btn blue payment">
                목록으로
              </Link>
            </div>
          </section>
        )}
        <section className="sec_other">
          <div className="list magazine_list">
            <div className="heading">다른 매거진</div>
            {relatedMagazines?.map((mag) => {
              return (
                <Link to={`/Magazine/${mag.mag_seq}`} className="content_wrap" key={mag.mag_seq}>
                  <img src={getImageLink(mag.mag_image)} alt="thumbnail" className="img" />
                  <div className="description">
                    <p className="title">{mag.mag_title}</p>
                    <div className="com_tag_list">
                      {mag.mag_tags
                        ?.trim()
                        .split(",")
                        .map((tag, index) => {
                          return (
                            <span key={index} className="com_btn s bg lightblue">
                              #{tag}
                            </span>
                          );
                        })}
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </section>
      </div>
      {/* top button */}
      <ScrollToTopButton />
    </main>
  );
}

export default Detail;
