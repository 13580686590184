import React from "react";
import { Link } from "react-router-dom";

import iconGraph from "../../assets/img/icon_match_graph.png";
import { getUserImageLink } from "../../../../../utils/misc";

export default function ResumeItem({ desiredJobs, resume }) {
  return (
    <li className="item">
      <p className="profile">
        <img src={getUserImageLink(resume["image_seq"])} alt="" />
      </p>
      <ul className="info_list">
        <li className="info_item">
          <span className="name">이름</span>
          <span className="txt">
            {resume?.resume_name.split(" ")[0]}
            <span className="com_icon_status bg_orange line_orange">{resume?.resume_name.split(" ").slice(0)}</span>
          </span>
        </li>
        <li className="info_item">
          <span className="name">연락처</span>
          <span className="txt">{resume["resume_mobile"]}</span>
        </li>

        <li className="info_item">
          <span className="name">경력</span>
          <span className="txt">{resume["career_depart"] ? resume["career_depart"] : "N/A"}</span>
        </li>

        <li className="info_item">
          <span className="name">성별</span>
          <span className="txt">{resume["resume_gender"] === "M" ? "남성" : "여성"}</span>
        </li>
        <li className="info_item">
          <span className="name">근무회사</span>
          <span className="txt">{resume["career_company"] ? resume["career_company"] : "N/A"}</span>
        </li>
        <li className="info_item">
          <span className="name">희망직무</span>
          <span className="txt">
            {desiredJobs &&
              resume["desired_job_cate_seqs"] &&
              resume["desired_job_cate_seqs"]
                .split(",")
                .filter((cate_seq) => desiredJobs.find((job) => Number(job.cate_seq) === Number(cate_seq)))
                .map((cate_seq) => desiredJobs.find((job) => Number(job.cate_seq) === Number(cate_seq))["cate_name"])
                .join(", ")}
          </span>
        </li>
      </ul>
      <div className="graph">
        <span className="percent">95%</span>
        <span className="icon_graph">
          <img src={iconGraph} alt="graph icon" />
        </span>
      </div>
      <div className="com_btn_wrap">
        <button className="com_btn line xs btn_like">찜하기</button>
        <Link to={"/Company/Resume/" + resume["resume_seq"]} className="com_btn bg blue xs">
          이력서 보기
        </Link>
      </div>
    </li>
  );
}
