import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import { useForm } from "react-hook-form";
import { queryBackend, updateBackend } from "../../../../../api";
import { toast } from "react-toastify";

const CategoryStatus = Object.freeze({
  ACC: "받아 들여졌다",
  REJ: "거부",
  REQ: "승인 대기 중",
});

export default function CommunityApplicationDetail() {
  const navigate = useNavigate();

  const { req_seq } = useParams();

  const [categoryApplication, setCategoryApplication] = useState();
  const [user, setUser] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const { isFetching } = useCustomQuery("/category/reqCate?per_page=10&curr_page=1&req_seq=" + req_seq, {
    queryKey: ["getCategory/" + req_seq],
    select: (res) => (res?.data?.categories?.length > 0 ? res?.data.categories[0] : null),
    onSuccess: async (data) => {
      console.log(data);
      if (!data) navigate(-1);
      setCategoryApplication(data);

      if (data.user_seq) {
        const userInfo = await queryBackend("/user/info/" + data.user_seq).then(
          async (data) => (await data.json()).data,
        );
        setUser(userInfo.data_list[0]);
      }
    },
    refetchOnWindowFocus: false,
  });

  const { getValues, register } = useForm({
    mode: "onChange",
  });

  const handleUpdate = async () => {
    if (!categoryApplication) return;
    setIsUpdating(true);

    const cate_status = getValues("req_status");

    const updateStatusResponse = await updateBackend("/category/reqCate", {
      data: { req_status: cate_status, req_seq },
      headers: { "Content-Type": "application/json" },
    }).then(async (data) => await data.json());

    setIsUpdating(false);

    if (updateStatusResponse.messageCode === 200) {
      toast.success("요청 된 카테고리 상태 상태가 성공적으로 업데이트되었습니다!");
    } else toast.error("업데이트에 실패했습니다!");
  };

  if (isFetching || !categoryApplication) return <i className="gg-spinner-big" />;

  return (
    <main>
      <h1 className="adm_com_pg_tit2">멘토링 관리</h1>
      <h2 className="adm_com_pg_tit">- 멘토 신청 내역 {" > "} 상세</h2>

      {/* User Information */}
      {user && (
        <div className="adm_com_pg_content">
          <div className="sub_tit">- 카테고리 신청자 정보</div>
          <table className="adm_com_board_list type_row">
            <colgroup>
              <col width="10%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                {/* User ID */}
                <th>사용자 ID</th>
                <td>{user["user_id"]}</td>
              </tr>
              <tr>
                {/* User name */}
                <th>이름</th>
                <td>{user["user_name"]}</td>
              </tr>
              <tr>
                {/* User nickname */}
                <th>별명</th>
                <td>{user["user_nickname"]}</td>
              </tr>
              <tr>
                {/* Email */}
                <th>이메일</th>
                <td>{user["user_email"]}</td>
              </tr>
              <tr>
                {/* Phone Number */}
                <th>전화 번호</th>
                <td>{user["user_mobile"]}</td>
              </tr>
              <tr>
                {/* Membership type */}
                <th>전화 번호</th>
                <td>{user["user_type"] === "PSN" ? "일반 회원" : "기업 회원"}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {!categoryApplication ? (
        <>
          사용자 이력서로드
          <i className="gg-spinner-alt" />
        </>
      ) : (
        <div className="adm_com_pg_content">
          <div className="sub_tit">- 카테고리 응용 프로그램 정보</div>
          {categoryApplication && (
            <table className="adm_com_board_list type_row">
              <colgroup>
                <col width="10%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  {/* Category name */}
                  <th>카테고리 이름</th>
                  <td>{categoryApplication.req_cate_nm}</td>
                </tr>
                <tr>
                  {/* Category description */}
                  <th>개방 이유</th>
                  <td>{categoryApplication.req_cate_desc}</td>
                </tr>
                <tr>
                  {/* Application date */}
                  <th>지원 날짜</th>
                  <td>{new Date(categoryApplication.reg_dtm).toLocaleString()}</td>
                </tr>
                {/* Approval Status */}
                <tr>
                  <th>승인 상태</th>
                  <td>
                    <select
                      className="select"
                      defaultValue={categoryApplication["req_status"]}
                      {...register("req_status")}>
                      <option value={"ACC"}>{CategoryStatus["ACC"]}</option>
                      <option value={"REJ"}>{CategoryStatus["REJ"]}</option>
                      <option value={"REQ"}>{CategoryStatus["REQ"]}</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      )}
      <div className="adm_com_btn_wrap center bottom">
        <button className="adm_com_btn gray" onClick={handleUpdate}>
          수정하기 {isUpdating && <i className="gg-spinner-alt" />}
        </button>
        <button className="adm_com_btn gray" onClick={() => navigate(-1)}>
          돌아가기
        </button>
      </div>
    </main>
  );
}
