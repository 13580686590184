import React, { useState } from "react";
import Errors from "../../../../../../utils/forms/ErrorMap";

export default function FormInput(props) {
  const {
    buttonText,
    check,
    handleUpload,
    formState,
    id,
    label,
    placeholder,
    register,
    required,
    rules,
    subText,
    type,
    trigger,
    watch,
    disabled,
    multiple = false,
    readonly = false
  } = props;

  const getErrors = () => {
    if (formState.errors[id]) {
      switch (formState.errors[id]?.type) {
        case "minLength":
          return <span style={{ color: "red" }}>{Errors.minLength}</span>;
        case "maxLength":
          return <span style={{ color: "red" }}>{Errors.maxLength}</span>;
        case "pattern":
          return <span style={{ color: "red" }}>{Errors.pattern}</span>;
        case "required":
          return <span style={{ color: "red" }}>{Errors.required}</span>;
        default:
          break;
      }
    }
    if (formState.errors[id] && formState.errors[id]?.message)
      return <span style={{ color: "red" }}>{formState.errors[id].message}</span>;
  };

  const [isValid, setIsValid] = useState(false);

  const [checkLoading, setCheckLoading] = useState(false);

  const handleCheck = async (ev) => {
    ev.preventDefault();

    if (!(await trigger(id))) return;
    setCheckLoading(true);
    const notValid = await check(watch(id));
    setCheckLoading(false);
    setIsValid(!notValid && !formState.errors[id]);
  };

  const handleBlur = async (e) => {
    e.preventDefault();
    if (e.target.value === "") await trigger(id);
    // setIsValid(await trigger(id));
  };

  return (
    <>
      {label && (
        <li className="tit_field">
          {label}
          {required && <i className="icon_must">*</i>}
        </li>
      )}
      <li className="input_field">
        <div className="input_wrap">
          {type === "file" ? (
            <input
              type={"file"}
              placeholder={placeholder}
              className="input"
              onChange={handleUpload}
              required={!!required}
              accept="image/*, .pdf, .doc"
              multiple={multiple}
            />
          ) : type === "textarea" ? (
            <textarea
              onBlur={handleBlur}
              placeholder={placeholder}
              {...register(id, {
                required,
                ...rules,
              })}
              onChange={async () => {
                // console.log(e.target.value);
                setIsValid(false);
              }}
              required={!!required}
              disabled={disabled}
            />
          ) : (
            <input
              id={id}
              type={type}
              onBlur={handleBlur}
              placeholder={placeholder}
              className="input"
              {...register(id, {
                required,
                ...rules,
              })}
              onChange={async () => {
                // console.log(e.target.value);
                setIsValid(false);
              }}
              required={!!required}
              disabled={disabled}
              readOnly={readonly}
            />
          )}
          {check && (
            <button className="com_btn line blue s" onClick={handleCheck}>
              {buttonText ? buttonText : "중복확인"}
              {checkLoading && <i className="gg-spinner-alt" />}
              {isValid && <i className="gg-check" />}
            </button>
          )}
        </div>
        {formState.errors[id] && <span style={{ color: "red" }}>{formState.errors[id]?.message || getErrors()}</span>}
        {subText && <p className="tip_txt">{subText}</p>}
      </li>
    </>
  );
}
