import Base64 from "crypto-js/enc-base64";
import CryptoJS from "crypto-js";

export const generateSignature = (timestamp) => {
  const accessKey = process.env.REACT_APP_API_ACC_KEY;
  if (typeof accessKey !== "string" || accessKey.length <= 0) throw new Error("Access key not set");

  const hash = CryptoJS.algo.SHA256.create().update(timestamp).update(accessKey).finalize();

  return Base64.stringify(hash);
};

export const _getAuthHeaders = (timestamp, isSecureRoute) => {
  const headers = {
    "x-awapp-apigw-timestamp": timestamp,
    "x-awapp-apigw-signature-v2": generateSignature(timestamp),
    "Content-Type": "application/json",
  };

  if (isSecureRoute) {
    headers.Authorization = `Bearer ${sessionStorage.getItem("token")}`;
  }

  return headers;
};

export const getAuthHeaders = () => {
  const timestamp = Date.now().toString();

  const headers = {
    "x-awapp-apigw-timestamp": timestamp,
    "x-awapp-apigw-signature-v2": generateSignature(timestamp),
  };

  if (sessionStorage.getItem("accessToken")) headers.Authorization = `Bearer ${sessionStorage.getItem("accessToken")}`;
  if (sessionStorage.getItem("adminAccessToken"))
    headers.Authorization = `Bearer ${sessionStorage.getItem("adminAccessToken")}`;

  return headers;
};
