import React, { useContext, useState } from "react";
import "../../assets/scss/page/member.scss";
//component
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import Modal from "../../components/modal";
import { useForm } from "react-hook-form";
import { mutateBackend } from "../../../../../api";
import { AuthContext } from "../../../../../context/Auth";
import { useNavigate } from "react-router-dom";

export default function Leave() {
  const [modalOpen, setModalOpen] = useState(false);

  const { logout } = useContext(AuthContext);

  const navigate = useNavigate();

  const {
    formState: { errors },
    handleSubmit,
    register,
    setError,
  } = useForm();

  const onValidSubmit = async (data) => {
    const response = await mutateBackend("/user/withdraw", {
      headers: {
        "Content-Type": "application/json",
      },
      data: { ...data },
    });

    if (response.status === 200) {
      navigate("/");
      logout();
    } else if (response.status === 404) {
      setModalOpen(false);
      setError("current_password", { message: "잘못된 비밀번호" });
    }
  };

  return (
    <>
      <form className="container" onSubmit={handleSubmit(onValidSubmit, () => setModalOpen(false))}>
        <h2 className="com_pg_tit">회원탈퇴</h2>

        <div className="sec_notice">
          <ul className="notice_list">
            <li className="notice_item">
              <span className="dot" />
              회원탈퇴 후 개인정보 및 활동내역은 개인정보처리방침에 따라 삭제됩니다.
            </li>
            <li className="notice_item">
              <span className="dot" />
              삭제된 정보는 복구가 불가능합니다.
            </li>
            <li className="notice_item">
              <span className="dot" />
              같은 SNS아이디로 재가입시 신규회원으로 등록됩니다.
            </li>
            <li className="notice_item">
              <span className="dot" />
              회원 탈퇴 시 남은 포인트는 보상하지 않으니, 탈퇴 전에 환전하시기 바랍니다.
            </li>
          </ul>
        </div>
        <ul className="com_input_sec type_row">
          <li className="field_box">
            <span className="tit">탈퇴 사유</span>
            <div className="input_box">
              <textarea
                placeholder="회원탈퇴 사유를 입력해주세요. (500자 이내)"
                className="textarea s"
                required
                {...register("withdrawal_reason", { required: true })}
              />
            </div>
          </li>
          <li className="field_box">
            <span className="tit">비밀번호</span>
            <div className="input_box">
              <input
                type="password"
                className="input s"
                required
                {...register("current_password", { required: true })}
              />
              {errors["current_password"] && <p className="error_txt">비밀번호를 수정하십시오.</p>}
            </div>
          </li>
        </ul>
        <div className="com_btn_wrap center">
          <button className="com_btn blue s" type="button" onClick={() => setModalOpen(true)}>
            회원탈퇴
          </button>
          <button className="com_btn line blue s" type="button">
            취소
          </button>
        </div>
        <Modal open={modalOpen} close={() => setModalOpen(false)} popSize="s" type="type_alert">
          <div className="content_container scroll">
            회원탈퇴를 하시겠습니까?
            <section className="com_btn_wrap">
              <button className="com_btn blue s" type="submit">
                예
              </button>
              <button className="com_btn line blue s" onClick={() => setModalOpen(false)}>
                아니오
              </button>
            </section>
          </div>
        </Modal>
      </form>

      <ScrollToTopButton />
    </>
  );
}
