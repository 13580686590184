import React, { useRef, useContext } from "react";
import useScript from "../../../../../hooks/useScript";
import SnsGoogle from "../../assets/img/login_sns_google.png";
import { AuthContext } from "../../../../../context/Auth";
import { useNavigate } from "react-router-dom";

export default function GoogleDiv({ formType, FormType }) {
  const navigate = useNavigate();
  const googleSignInButton = useRef(null);

  const { snslogin } = useContext(AuthContext);

  const onGoogleSignIn = async (res) => {
    const result = await snslogin({ credentialResponse: res, type: "google", userType: formType === FormType.General ? "PSN" : "COM"  });
    //회원가입
    if (result === true) {
      //백엔드로 토큰값 보내기
      const redirectPath = sessionStorage.getItem("redirectPath");
      //로그인 및 회원 가입 분기
      if (redirectPath) {
        if (redirectPath.toLocaleLowerCase().startsWith("/company") && formType === FormType.General) navigate("/");
        else if (redirectPath.toLocaleLowerCase().startsWith("/mypage") && formType === FormType.Corporate)
          navigate("/");
        else navigate(redirectPath);
        sessionStorage.removeItem("redirectPath");
      } else {
        navigate("/");
      }
    } else {
      navigate(`/member/join?type=${formType === FormType.General ? "PSN" : "COM"}&snstype=google&email=${result.email}&cid=${result.cid}`);
    }
    //콜백 함수
  };

  useScript("https://accounts.google.com/gsi/client", () => {
    window.google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: onGoogleSignIn,
    });
    window.google.accounts.id.renderButton(googleSignInButton.current, {
      theme: "outline",
      size: "large",
      type: "icon",
      width: "400",
      // type: 'icon',
      shape: "circle",
    });
  });

  function onClickGooglelogin() {
    document.querySelector('[aria-labelledby="button-label"]').click();
  }

  return (
    <>
      <div aria-hidden="true" id="google-login-api" ref={googleSignInButton} style={{ display: "none" }} />

      <button type="button" onClick={onClickGooglelogin} className="btn">
        <img src={SnsGoogle} alt="google" />
      </button>
    </>
  );
}
