/* eslint-disable */
import React from 'react';
import "../../assets/scss/page/etc.scss";
function RecruitService() {
  return (
    <>
      <main className='com_term'>
        <div className='com_center_wrap'>
          <div className='box'>
            <dl className='sec'>
              <dt className="tit">엔지니어스 적극구직 서비스 이용 계약서</dt>
              <dd className="txt">서비스 이용을 위해 계약내용에 대한 동의가 필요합니다.</dd>
              <dd className="txt">엔지니어스(이하 회사)는 개인정보보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법류 등 관련 법령상의 개인정보보호 규정을 준수하며, 지원자의 개인정보 보호에 최선을 다하고 있습니다. 회사는 적극구직 서비스 지원자의 개인정보를 수집 및 이용하기 위한 동의를 구하고자 하오니 아래의 내용을 충분히 숙지하신 후 동의하시는 경우 문서 하단에 계약에 동의해 주시기 바랍니다.</dd>

              <dt className="tit">1. 적극구직 제공 서비스 및 이용 안내</dt>
              <dd className="txt">• 서비스 이용 안내</dd>
              <dd className="txt">엔지니어스 플랫폼 가입 회원이라면 누구나 신청 가능하며, 적극구직 서비스 이용 신청일로부터 채용 성공 또는 신청해지시까지 유효 합니다.
              <br />• 서비스 비용
              <br />① 적극구직 서비스 이용 신청은 무료입니다.
              <br />② 채용계약이 성사될 경우, “지원자”는 계약금액의 1%를 수수료로서 “회사”에 지급하여야 합니다. ③ 지원자는 입사일 확정 시 즉시 “회사”에 알려야 할 의무를 가집니다.
              <br />④ “회사”는 입사일 기준 30일 경과 후 수수료를 청구하며, “지원자”는 청구된 즉시 “회사”에게 수수료를 지급해야 합니다. 
              <br />• 제공 서비스 안내
              <br />전담 채용 매니저 배정을 통해 구직자 개인별 특성에 맞는 고용서비스를 제공합니다.
              <br />① 취업활동 계획 수립
              <br />② 구직서류(이력서 및 자기소개서) 코칭
              <br />③ 구직전략 수립
              <br />④ 구직알선 및 취업
              <br />⑤ 사후관리</dd>

              <dd className="txt">지원자는 위와 같이 적극구직 서비스 이용 안내를 충분히 이해하고 이에 동의합니다.
              <br />[예] [아니오] 버튼</dd>

              <dt className="tit"></dt>2. 개인정보의 수집항목 및 수집방법, 수집 및 이용목적, 보유 및 이용기간 (개인정보보호법 제15조)
              <dd className="txt">• 수집 및 이용 목적
              <br />채용전형의 진행, 진행 단계별 결과 등 채용관련 정보 안내 및 당사 인재풀 구성을 목적으로 합니다.
              <br />• 개인정보 수집항목
              <br />① 성명(국문/영문), 생년월일, 주소, 이메일, 전화번호, 핸드폰번호, 학교, 전공, 입학일, 졸업일, 논문제목
              <br />② 병역정보, 국가유공자 대상여부 및 관련정보, 장애여부
              <br />③ 경력정보(재직기업, 재직기간, 담당직무), 자격증 정보, 어학정보, 연봉정보
              <br />• 보유 및 이용기간
              <br />지원자의 삭제 및 파기 요청 시 즉시 처리</dd>

              <dd className="txt">지원자는 위와 같이 개인정보를 수집 및 이용하는 것을 충분히 이해하고 이에 동의합니다.
              <br />[예] [아니오] 버튼</dd>

              <dt className="tit"></dt>3. 개인정보의 제3자 제공동의 (개인정보보호법 제17조)
              <dd className="txt">• 제공받는 자
              <br />지원자가 지원한 당사 채용대행서비스 이용 기업
              <br />• 제공하는 개인정보 항목
              <br />성명, 생년월일, 전화번호, 학력정보, 경력정보, 자기소개서 등 이력서상 기재 내용
              <br />• 제공받는 자의 이용 목적
              <br />인재채용과 관련된 경력, 학력, 자격 확인 및 검증 등 일련의 채용프로세스 활용
              <br />• 보유 및 이용기간
              <br />지원자가 파기를 요청 시 즉시 처리</dd>

              <dd className="txt">당사가 위와 같이 개인정보를 제3자에게 제공하는 것을 충분히 이해하고 이에 동의합니다.
              <br />[예] [아니오] 버튼</dd>

              <dd className="txt">※ 지원자께서는 지원자의 개인정보 수집 및 이용에 대한 동의를 거부하실 권리가 있습니다. 하지만 개인정보 수집 및 이용에 대해 동의를 거부하실 경우 당사를 통한 채용전형 지원 및 진행이 제한될 수 있습니다.</dd>

              <dd className="txt">귀사는 적극구직 서비스 상품을 계약함에 있어 본 계약 체결을 위해 해당 계약사항을 충분히 확인하였으며, 위 계약 내용에 동의 합니다.</dd>

              <dd className="txt">[닫기] [계약에 동의합니다] 버튼</dd>
            </dl>
          </div>
        </div>
      </main>
    </>
  );
}

export default RecruitService;
