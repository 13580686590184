import React, { useState } from "react";

export function Rating({ handleRate, initialRating = 0 }) {
  const [rating, setRating] = useState(initialRating);
  const [tempRating, setTempRating] = useState(initialRating);

  const rate = (rating) => {
    setRating(rating);
    setTempRating(rating);
    handleRate(rating);
  };

  const star_over = (new_rating) => {
    setTempRating(new_rating);
  };

  const star_out = () => {
    setTempRating(rating);
  };

  const stars = [];

  for (let i = 0.5; i < 5.5; i++) {
    const isHalfStar = tempRating % 1 !== 0;
    let starClass = "";

    if (tempRating >= i) {
      starClass += " is-selected";
    }

    stars.push(
      <div className="split-star" key={i}>
        <label
          className={"half" + starClass}
          onClick={() => rate(i)}
          onMouseOver={() => star_over(i)}
          onMouseOut={() => star_out()}>
          ★
        </label>
        <label
          className={"half flip" + (isHalfStar && tempRating === i ? "" : starClass)}
          onClick={() => rate(i + 0.5)}
          onMouseOver={() => star_over(i + 0.5)}
          onMouseOut={() => star_out()}>
          ★
        </label>
      </div>,
    );
  }

  return (
    <div className="star-component">
      <div className="rating-text-title">이 제품을 평가하십시오</div>
      <div className="rating-text">{rating}</div>
      <div className="star-container">{stars}</div>
    </div>
  );
}

export function RenderStars({ rating }) {
  return (
    <div className="star-container">
      {[...Array(5)]
        .map((_, i) => i + 0.5)
        .map((i) => {
          let starClass = " is-selected";

          if (rating >= i) {
            starClass += " is-selected";
          }

          return (
            <div className="split-star" key={i}>
              <label className={"half" + (rating >= i ? starClass : "")}>★</label>
              <label className={"half flip" + (rating >= i + 0.5 ? starClass : "")}>★</label>
            </div>
          );
        })}
    </div>
  );
}
