import React, { useState } from "react";
import Modal from "../../components/modal";

import { useCustomQuery } from "../../../../../hooks/useAPI";
import { mutateBackend } from "../../../../../api";
import { toast } from "react-toastify";
function PopApply({ close, open, user, setApplySucess }) {
  const [isApplying, setIsApplying] = useState(false);
  const [selectedResume, setSelectedResume] = useState(0);

  const { data: resumes } = useCustomQuery(`/resume/my/list?orderBy=CR.resume_seq`, {
    queryKey: ["getResumes"],
    select: (res) => res?.data?.resumes,
    onSuccess: (res) => {
      setSelectedResume(res?.data?.resumes?.resume_seq);
    },
    enabled: open,
  });

  //제출
  const handleMentorshipApplication = async () => {
    if( selectedResume > 0){
      setIsApplying(true);
      const response = await mutateBackend("/mentoring/apply", {
        data: {
          user_seq: user.user_seq,
          resume_seq: selectedResume,
        },
        headers: { "Content-Type": "application/json" },
      }).then(async (data) => await data.json());
  
      if (response.messageCode === 200) {
        setApplySucess(true);
        toast.success("성공적으로 멘토지원이 완료되었습니다.");
        close();
      } else {
        toast.error("멘토지원에 실패하였습니다. 오류가 계속되면 관리자에게 문의하세요.");
      }
      setIsApplying(false);
    } else {
      toast.error("이력서를 선택하세요.");
    }

  };

  return (
    <Modal open={open} close={close} type="pop_apply">
      <h2 className="pop_tit">이력서 선택</h2>
      <div className="content_container scroll">
        <div className="info_wrap">
          <div className="box">
            <ul className="rule_list">
              <li className="rule">
                <span className="dot blue"></span>
                멘토 신청을 하기 위해서는 인증된 경력이 필요합니다.
              </li>
              <li className="rule">
                <span className="dot blue"></span>
                등록된 이력서 제출 후 관리자 승인을 통해 영업일 기준 2~3일 내에 멘토링 프로그램 등록 권한을 드립니다.{" "}
              </li>
            </ul>
          </div>
          <div className="box">
            <p className="input_tit">이력서 선택</p>

            <ul className="list scroll">
              {resumes?.length > 0 ? (
                <>
                  {resumes?.map((item, index) => (
                    <li className="item" key={index}>
                      <input
                        type="radio"
                        className="com_chk"
                        id={`selected_resume_${item?.resume_seq}`}
                        name="selected_resume"
                        value={item?.resume_seq}
                        checked={selectedResume === item?.resume_seq}
                        defaultChecked={item?.top_YN === "Y" || item?.resume_progr >= 80}
                      />
                      <label
                        htmlFor={`rate-${index}`}
                        onClick={() => {
                          setSelectedResume(item?.resume_seq);
                        }}>
                        <span className="sel_tit">
                          {item?.resume_name} | {item?.resume_title}
                        </span>
                      </label>
                    </li>
                  ))}
                </>
              ) : (
                <p className="text">계속하려면 적어도 하나의 이력서를 작성하세요.</p>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="btn_container">
        <button className="com_btn line blue s" onClick={() => handleMentorshipApplication()}>
          확인 {isApplying && <i className="gg-spinner-alt" />}
        </button>
        <button className="com_btn blue s" onClick={close}>
          취소
        </button>
      </div>
    </Modal>
  );
}

export default PopApply;
