import React from "react";
import { useSearchParams } from "react-router-dom";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import Table from "../../../screens/components/boffice/Table";
import Paging from "../../component/Paging";
import SeminarFilter from "../../component/SeminarFilter";

const columns = {
  semin_seq: "번호",
  semin_title: "세미나 명",
  reg_dtm: "등록일자",
  semin_limit: "정원",
  semin_date: "일정",
  semin_price: "금액",
  semin_address: "장소",
  semin_status: "노출여부",
  detail: {
    label: "관리",
    getLink: (item) => "/boffice/board/seminar/details/" + item["semin_seq"],
    linkLabel: "상세",
  },
};

const defaultSearchParams = {
  page: 1,
  limit: 10,
  search_query: "",
  date_from: "",
  date_to: "",
  status: "",
};

export default function AdminSeminarList() {
  const [searchParams, setSearchParams] = useSearchParams(defaultSearchParams);
  const page = +searchParams.get("page");
  const limit = +searchParams.get("limit");
  const date_from = searchParams.get("date_from");
  const date_to = searchParams.get("date_to");
  const status = searchParams.get("status");
  const search_query = searchParams.get("search_query");

  const {
    data: { seminars = [], total_count = 0 } = {
      seminars: [],
      total_count: 0,
    },
    isFetching,
  } = useCustomQuery(
    `/seminar/details?page=${page}&limit=${limit}&search_query=${search_query}&date_from=${date_from}&date_to=${date_to}&semin_status=${status}`,
    {
      queryKey: ["getAdminSeminarList", page, limit, search_query, date_from, date_to, status],
      select: (res) => res?.data ?? { seminars: [], total_count: 0 },
    },
  );

  return (
    <main>
      <h2 className="adm_com_pg_tit">세미나 관리</h2>
      <div className="adm_com_pg_content">
        <SeminarFilter
          defaultSearchParams={defaultSearchParams}
          setSearchParams={setSearchParams}
          total_count={total_count}
          isFetching={isFetching}
          limit={limit}
          search_query={search_query}
          date_from={date_from}
          date_to={date_to}
          status={status}
          showRegister
          showDelete
        />
        <Table
          data={seminars}
          columns={columns}
          checked
          handleCheck={(e) => {
            console.log(e);
          }}
        />
        <Paging page={page} totalPageCount={Math.ceil(total_count / limit)} setSearchParams={setSearchParams} />
      </div>
    </main>
  );
}
