import React from "react";
import { Routes, Route } from "react-router-dom";
import "../views/boffice/assets/scss/styles.scss";

import CategoryList from "../views/boffice/page/mentor/category/List";
import NewCategory from "../views/boffice/page/mentor/category/NewCategory";
import CategoryDetail from "../views/boffice/page/mentor/category/CategoryDetail";
import MentorApplyList from "../views/boffice/page/mentor/manage/ApplyList";
import MentoringApplicationDetail from "../views/boffice/page/mentor/manage/ApplyDetail";
import MentorList from "../views/boffice/page/mentor/manage/List";
import MentorDetail from "../views/boffice/page/mentor/manage/Detail";
import MentorProductDetail from "../views/boffice/page/mentor/manage/MentoringProductDetail";
import MentoringProductList from "../views/boffice/page/mentor/product/List";
import ProductDetail from "../views/boffice/page/mentor/product/ProductDetail";
import MentoringProductPayList from "../views/boffice/page/mentor/product/ProductPayList";
import MentoringPaymentDetail from "../views/boffice/page/mentor/product/ProductPayDetail";

export default function RouterBofficeMentoring() {
  // Authentication logic is done in the router, for now the /screens
  return (
    <Routes>
      <Route path="/cate" element={<CategoryList />} />
      <Route path="/cate/new" element={<NewCategory />} />
      <Route path="/cate/:cate_seq" element={<CategoryDetail />} />
      <Route path="/manage/apply" element={<MentorApplyList />} />{/*멘토링 신청회원*/}
      <Route path="/manage/apply/:user_seq" element={<MentoringApplicationDetail />} />
      <Route path="/manage/member" element={<MentorList />} />
      <Route path="/manage/member/:user_seq" element={<MentorDetail />} />
      <Route path="/manage/member/:user_seq/product/:product_seq" element={<MentorProductDetail />} />
      <Route path="/product/list" element={<MentoringProductList />} />
      <Route path="/product/:product_seq" element={<ProductDetail />} />
      <Route path="/product/pay" element={<MentoringProductPayList />} />
      <Route path="/product/pay/:pay_seq" element={<MentoringPaymentDetail />} />
      <Route path="/product/refund" element={<></>} />
    </Routes>
  );
}
