import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
//css
import "../../assets/scss/page/member.scss";
import ScrollToTopButton from "../miscellaneous/ScrollToTopButton";
import { useForm } from "react-hook-form";
import { mutateBackend, mutateBackendFormData, queryBackend, updateBackend } from "../../../../../api";
import Modal from "../modal";
import { toast } from "react-toastify";
import { nvlNumber } from "../../../../../utils/Common.utils";
//component

export default function QnaWrite() {
  const location = useLocation();
  const { mento_seq, parent_seq, board_seq } = location.state;

  const navigate = useNavigate();

  const fileRef = useRef();
  const [uploadFile, setUploadFile] = useState([]);
  const [modalAlert, setModalAlert] = useState({
    visible: false,
    message: "",
  });

  const { handleSubmit, register, reset, watch, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      board_type: "MENTO",
      cate_seq: mento_seq,
      parent_seq: parent_seq,
      board_title: "",
      board_contents: "",
    },
  });

  const handleFileClick = () => {
    fileRef.current.click();
  };

  const getBoardDetail = async () => {
    const response = await queryBackend(`/community?board_type=MENTO&board_seq=${board_seq}`);

    const result = await response.json();

    if (result && result.messageCode === 200) {
      if (parent_seq > 0) {
        setValue("board_title", result.data.a_data.board_title);
        setValue("board_contents", result.data.a_data.board_contents);
        // setUploadFile(result.data.a_data.file_info);
      } else {
        setValue("board_title", result.data.q_data.board_title);
        setValue("board_contents", result.data.q_data.board_contents);
        // setUploadFile(result.data.q_data.file_info);
      }
    }
  };
  useEffect(() => {
    if (nvlNumber(board_seq) > 0) {
      getBoardDetail();
    }
  }, []);

  const handleFileUpload = async (board_seq) => {
    const formData = new FormData();
    formData.append("file", uploadFile);
    uploadFile.forEach((file) => {
      formData.append("file", file);
    });

    const response = await mutateBackendFormData("/upload/file", formData)
      .then(async (data) => await data.json())
      .catch(() => ({ messageCode: 400 }));
    if (response.messageCode === 200) {
      handleCommunityUpload(
        board_seq,
        response.data.map((obj) => obj?.up_file_seq),
      );
    } else {
      if (response.messageCode === 403) {
        setModalAlert({ visible: true, message: "유효하지 않은 파일 형식입니다.." });
      }
      setModalAlert({ visible: true, message: "첨부파일 등록에 실패했습니다." });
      setUploadFile([]);
    }
  };
  const handleCommunityUpload = async (board_seq, ids) => {
    const response = await mutateBackend("/community/upload", {
      data: { board_seq, ids },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (data) => await data.json())
      .catch((err) => {
        throw new Error(err);
      });

    if (response.messageString === "SUCCESS" && response.messageCode === 200) {
      setModalAlert({ visible: true, message: "제출되었습니다." });
      reset();
      setUploadFile([]);
    } else {
      console.log("Upload BoardFile failed");
    }
  };

  const onValidSubmit = async (data) => {
    let responseData;
    if (board_seq) {
      responseData = await updateBackend(`/community`, {
        method: "PUT",
        data: {
          ...data,
          board_seq: board_seq,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      const response = await responseData.json();
      console.log(response);
      if (response.messageCode === 200) {
        if (uploadFile.length > 0) {
          handleFileUpload(board_seq);
        } else {
          setModalAlert({ visible: true, message: "수정되었습니다." });
          reset();
        }
      } else {
        console.log("Submit failed");
      }
    } else {
      responseData = await mutateBackend("/community", {
        data: {
          ...data,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      const response = await responseData.json();
      if (response.messageCode === 200) {
        if (uploadFile.length > 0) {
          handleFileUpload(response.data.communityId);
        } else {
          setModalAlert({ visible: true, message: "제출되었습니다." });
          reset();
        }
      } else {
        console.log("Submit failed");
      }
    }
    navigate("/Mypage/QnaList", { state: { mento_seq: mento_seq } });
  };
  console.log(watch());

  return (
    <>
      {/* <Lnb PageTitle="결제 콘텐츠 목록" MenuPayContents="active"/> */}
      <form className="container" onSubmit={handleSubmit(onValidSubmit)}>
        <h2 className="com_pg_tit">질문과 답변 게시판</h2>
        <ul className="com_input_sec type_row">
          <li className="field_box">
            <span className="tit">제목</span>
            <div className="input_box">
              <input
                type="text"
                required
                placeholder="제목을 입력해주세요."
                {...register("board_title", {
                  required: true,
                })}
              />
            </div>
          </li>
          <li className="field_box">
            <span className="tit">내용</span>
            <div className="input_box">
              <textarea
                required
                placeholder="본문 내용을 입력해주세요."
                {...register("board_contents", {
                  required: true,
                })}
              />
            </div>
          </li>
          <li className="field_box">
            <span className="tit">첨부 파일</span>
            <div className="input_box">
              <div className="input_wrap">
                <input
                  type="text"
                  defaultValue={uploadFile.map((el) => el.name || el.file_real_name).join(",") || ""}
                />
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={fileRef}
                  onChange={(e) => {
                    if (uploadFile.length < 3) {
                      setUploadFile([...uploadFile, ...e.target.files]);
                    } else {
                      toast.error("첨부 파일은 3개까지만 가능합니다.");
                      return;
                    }
                  }}
                  multiple
                />
                <button type="button" className="com_btn blue line s" onClick={() => handleFileClick()}>
                  파일추가
                </button>
              </div>
              <p className="tip_field">첨부 파일은 최대 3개, 30MB까지 등록 가능합니다.</p>
            </div>
          </li>
        </ul>
        <section className="com_btn_wrap center">
          {board_seq > 0 ? (
            <button type="submit" className="com_btn blue s ">
              {parent_seq > 0 ? "답변 수정하기" : "질문 수정하기"}
            </button>
          ) : (
            <button type="submit" className="com_btn blue s ">
              {parent_seq > 0 ? "답변 등록하기" : "질문 등록하기"}
            </button>
          )}
          <Link to="/Mypage/QnaList" state={{ mento_seq: mento_seq }} className="com_btn blue s line ">
            취소
          </Link>
        </section>
      </form>
      <Modal open={modalAlert.visible} close={() => setModalAlert((prev) => ({ ...prev, visible: false }))}>
        <div className="content_container scroll">
          <p>{modalAlert.message}</p>
        </div>
        <div className="btn_container">
          <button
            className="adm_com_btn orange xs"
            onClick={() => setModalAlert((prev) => ({ ...prev, visible: false }))}>
            확인
          </button>
        </div>
      </Modal>
      {/* top button */}
      <ScrollToTopButton />
    </>
  );
}
