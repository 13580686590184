import React from "react";
import { Link } from "react-router-dom";
import { getImageLink } from "../../../../../utils/misc";
import useCommCode from "../../../../../hooks/useCommCode";
import Modal from "../../../screens/components/modal";
import Map from "./Map";

function CompanyInfo({ data }) {
  const { isFetching: LocationDataFetching, data: locationTypeData } = useCommCode(`LOCA_CD`);
  const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);

  const openModal = () => {
    setShowConfirmDelete(true);
  };


  if (!data || LocationDataFetching) return <></>;
  return (
    <>
      {data["comp_image_seqs"] && (
        <div className="company_logo">
          <img src={data?.comp_image_seqs && getImageLink(data?.comp_image_seqs?.split(",")[0])} alt="logo" />
        </div>
      )}
      <div className="company_info">
        <div className="info_box">
          <span className="name">{data?.comp_name}</span>
          <Link to={data?.comp_home_url} className="com_icon_status arrow_right arrow_green">
            홈페이지
          </Link>
          <span className="bar" />
          <span className="com_icon_status bg_green line_green">{data?.emp_type_name}</span>
          <span className="desc">
            <span className="bar" />
            <span>{data?.newcomer_YN === "Y" ? "신입" : (data && `경력 ${data?.career_min} ~ ${data?.career_max}년`)}</span>
            <span className="bar" />
            <span>{locationTypeData.find((location) => location.comm_code == data?.recruit_location)?.code_nm_kr}</span>
          </span>
          <button type="button" onClick={() => openModal()} className="com_icon_status arrow_right arrow_green">
            지도보기
          </button>
        </div>
        <div className="info_tit">{data?.recruit_title}</div>
      </div>
      <div className="info_date">
        <div className="info_txt">
          등록일 <span className="bar" />
          <span className="date">{new Date(data?.open_date).toLocaleDateString()}</span>{" "}
        </div>
        <div className="info_txt">
          마감일 <span className="bar" />
          <span className="date">
            {data?.close_type === "ANY" ? "상시 채용" : data?.close_type === "UNTI" ? "채용 시 마감" : (new Date(data?.close_date).toLocaleDateString() === "1899-11-29" ? "-" : new Date(data?.close_date).toLocaleDateString())}
          </span>{" "}
        </div>
      </div>
      <Modal open={showConfirmDelete} close={() => setShowConfirmDelete(false)} type="pop_desired_work" popSize="l">
        <div className="content_container" >
          {data?.comp_address ? 
          <Map map_address={data?.comp_address} map_title={data?.comp_name} />
            : 
          <h3 className="tit">지도 정보를 호출하지 못했습니다. 다시 시도해 주세요.</h3>

          }
          
        </div>
        <div className="btn_container">
          <button className="com_btn blue m" onClick={() => setShowConfirmDelete(false)}>확인</button>
        </div>
      </Modal>
    </>
  );
}

export default CompanyInfo;
