  /***********************************************************************************
  * 2023-11-24 환전 계좌 추가 - KSR
  *************************************************************************************/
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../assets/scss/page/member.scss";

import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import { AuthContext } from "../../../../../context/Auth";
import Modal from "../../components/modal";
import { useForm } from "react-hook-form";
import { updateBackend } from "../../../../../api";
import { toast } from "react-toastify";
import { useAuthenticatedQueryJson } from "../../../../../hooks/useAPI";
import { isNicknameTaken } from "../../../../../api/miscellaneous";

export default function Profile() {

  const { user, refetchUserInfo } = useContext(AuthContext);

  const [passwordModalOpen, setPasswordModalOpen] = useState(false);

  const [isUpdating, setIsUpdating] = useState(false);

  const [checkingNickname, setCheckingNickname] = useState(false);

  const { data, isFetching } = useAuthenticatedQueryJson("user-info", "/user/my-info");

  const {
    formState: { errors, touchedFields },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (!isFetching && data) {
      refetchUserInfo();
    }
    reset();
  }, [isFetching]);

  const handleUpdate = async () => {
    setIsUpdating(true);

    const updates = Object.keys(touchedFields).reduce(
      (keys, currentKey) =>
        user[currentKey] !== getValues(currentKey)
          ? {
              ...keys,
              [currentKey]: getValues(currentKey),
            }
          : { ...keys },
      {},
    );

    if (touchedFields["email1"] || touchedFields["email2"])
      updates["user_email"] = getValues("email1") + "@" + getValues("email2");

    user &&
      Object.keys(updates).length > 0 &&
      (await updateBackend("/user/update", {
        data: { ...updates, user_seq: user["user_seq"] },
        headers: { "Content-Type": "application/json" },
      }).then(async (data) => {
        if (data.status === 200) toast("정보를 성공적으로 업데이트했습니다.");
        if (data.status === 409) {
          const response = await data.json();
          setError(response.data.split(" ")[response.data.split(" ").length - 1].slice(1, -1), {
            message: "Duplicate key.",
          });
        }
      }));

    setIsUpdating(false);
  };

  const handlePasswordUpdate = async (data) => {
    if (data["new_user_pwd"] !== data["new_user_pwd_match"]) {
      setError("new_user_pwd_match", { message: "암호가 일치하지 않습니다." });
      return false;
    }

    if (user) {
      setIsUpdating(true);
      const response = await updateBackend("/user/update-password", {
        data: {
          user_seq: user["user_seq"],
          current_password: getValues("old_user_pwd"),
          new_password: getValues("new_user_pwd"),
        },
        headers: { "Content-Type": "application/json" },
      })
        .then(async (data) =>
          data.status === 200 ? { ...data, ...(await data.json()) } : { messageCode: data.status },
        )
        .catch((err) => {
          setIsUpdating(false);
          console.log(err);
          return err;
        });
      setIsUpdating(false);

      console.log(response);

      if (response.messageCode === 404) setError("old_user_pwd", { message: "잘못된 비밀번호" });
      else if (response.messageCode === 200) {
        toast.success("비밀번호가 성공적으로 변경되었습니다!", { position: "bottom-right" });
        setPasswordModalOpen(false);
        reset();
      }
    }
  };

  const checkNickname = async (user_nickname) => {
    setCheckingNickname(true);

    const nicknameTaken = await isNicknameTaken(user_nickname).catch(() => {
      // TODO Add server error
      toast.error("서버 오류");
      return true;
    });

    setCheckingNickname(false);
    return !nicknameTaken;
  };

  return (
    <>
      <form className="container" onSubmit={handleSubmit(handleUpdate)}>
        <h2 className="com_pg_tit">
          <span className="flex-start">내 정보 수정 {isFetching && <i className="gg-spinner-alt" />}</span>
        </h2>
        <ul className="com_input_sec type_row">
          <li className="field_box">
            <span className="tit">아이디</span>
            <div className="input_box">
              {/* User ID */}
              <input type="text" className="input s" disabled defaultValue={user["user_id"]} />
            </div>
          </li>
          <li className="field_box">
            <span className="tit">비밀번호 변경</span>
            <div className="input_box">
              {/* Change Password */}
              <button
                className="com_btn line s"
                onClick={() => {
                  setPasswordModalOpen(true);
                }}>
                비밀번호 변경
              </button>
            </div>
          </li>
          <li className="field_box">
            <span className="tit">닉네임</span>
            {/* User Nickname */}
            <div className="input_box">
              <div className="input_wrap s">
                <input
                  type="text"
                  placeholder="닉네임을 입력해주세요"
                  className="input s"
                  defaultValue={user["user_nickname"]}
                  {...register("user_nickname", {
                    validate: {
                      checkNicknameAvailable: async (val) =>
                        touchedFields["user_nickname"] && (await checkNickname(val)),
                    },
                  })}
                />
                <button className="com_btn line s " type="button" onClick={checkNickname}>
                  중복 확인
                  {checkingNickname && <i className="gg-spinner-alt" />}
                  {touchedFields["user_nickname"] && !errors["user_nickname"] && <i className="gg-check" />}
                  {touchedFields["user_nickname"] && errors["user_nickname"] && (
                    <div className="tooltip blue">
                      ⓘ<span className="tooltiptext">별명이 이미 가져 왔습니다.</span>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </li>
          <li className="field_box">
            <span className="tit">이름</span>
            {/* User Name */}
            <div className="input_box">
              <input
                type="text"
                placeholder="실명을 입력해주세요"
                className="input s"
                defaultValue={user["user_name"]}
                {...register("user_name")}
              />
            </div>
          </li>
          <li className="field_box">
            <span className="tit">
              이메일
              {(touchedFields["email1"] || touchedFields["email2"]) && errors["user_email"] && (
                <div className="tooltip blue">
                  ⓘ<span className="tooltiptext">이미 찍은 이메일.</span>
                </div>
              )}
            </span>
            <div className="input_box">
              <div className="input_wrap email">
                <input
                  type="text"
                  className="input"
                  defaultValue={user["user_email"] && user["user_email"].split("@")[0]}
                  {...register("email1")}
                />
                <span className="icon">@</span>
                <input
                  type="text"
                  className="input"
                  defaultValue={user["user_email"] && user["user_email"].split("@")[1]}
                  {...register("email2")}
                />
                <select className="select" onChange={(e) => setValue("email2", e.target.value)}>
                  <option value="직접입력">직접입력</option>
                  <option value="naver.com">naver.com</option>
                  <option value="gmail.com">gmail.com</option>
                </select>
              </div>
            </div>
          </li>
          <li className="field_box">
            <span className="tit">휴대폰 번호</span>
            <div className="input_box">
              <div className="input_wrap s">
                <input
                  type="text"
                  placeholder="- 없이 숫자를 입력해주세요"
                  className="input"
                  defaultValue={user["user_mobile"]}
                  {...register("user_mobile")}
                />
                <button className="com_btn line s">휴대폰 인증</button>
              </div>
            </div>
          </li>

          {user["user_type"] === "PSN" && (
          <li className="field_box">
            <span className="tit">
              환전 계좌
            </span>
            <div className="input_box">
              <div className="input_wrap email">
                <input
                  type="text"
                  className="input"
                  placeholder="은행명"
                  defaultValue={''}
                  {...register("bank_name")}
                />
                
                <input
                  type="text"
                  className="input"
                  placeholder="계좌번호"
                  defaultValue={''}
                  {...register("bank_account")}
                />

                <input
                  type="text"
                  className="input"
                  placeholder="예금주 명"
                  defaultValue={''}
                  {...register("bank_owner")}
                />
              </div>
            </div>
          </li>
          )}
          
          {user["user_type"] === "COM" && (
            <li className="field_box">
              <span className="tit">소속</span>
              <div className="input_box">
                <input
                  type="text"
                  placeholder="기획팀"
                  className="input s"
                  defaultValue={user["user_department"]}
                  {...register("user_department")}
                />
              </div>
            </li>
          )}
        </ul>
        <div className="btn_wrap">
          <div className="com_btn_wrap center">
            <button className="com_btn blue s" type="submit">
              수정 완료 {isUpdating && <i className="gg-spinner-alt" />}
            </button>
            <button className="com_btn line blue s">취소</button>
          </div>
          <Link to="/Mypage/Leave" className="btn_leave">
            회원탈퇴
          </Link>
        </div>
        {/* top button */}
      </form>
      <ScrollToTopButton />
      {passwordModalOpen && (
        <Modal open={passwordModalOpen} close={() => setPasswordModalOpen(false)}>
          <form onSubmit={handleSubmit(handlePasswordUpdate)}>
            <div className="pop_tit">비밀번호 변경</div>
            <div className="content_container scroll">
              <ul className="adm_com_input_sec">
                <li className="tit_field">현재 비밀번호</li>
                <li className="input_field">
                  <input
                    type="password"
                    className="input"
                    placeholder="영문, 숫자, 특수문자 포함 8~16 자리"
                    {...register("old_user_pwd", {
                      minLength: 8,
                      maxLength: 32,
                    })}
                    required
                  />
                  {errors["old_user_pwd"] && <span className="error_txt">* 보안에 취약한 비밀번호 입니다.</span>}
                </li>
                <li className="tit_field">새로운 비밀번호</li>
                <li className="input_field">
                  <input
                    type="password"
                    className="input"
                    placeholder="영문, 숫자, 특수문자 포함 8~16 자리"
                    {...register("new_user_pwd", {
                      minLength: 8,
                    })}
                    required
                  />
                  {errors["new_user_pwd"] && <span className="error_txt">* 비밀번호가 일치하지 않습니다.</span>}
                </li>
                <li className="tit_field">비밀번호 재입력</li>
                <li className="input_field">
                  <input
                    type="password"
                    className="input"
                    placeholder="영문, 숫자, 특수문자 포함 8~16 자리"
                    {...register("new_user_pwd_match", {
                      required: true,
                      minLength: 8,
                      validate: (val) => {
                        if (getValues("new_user_pwd") !== val) {
                          setError("new_user_pwd_match", { message: "암호가 일치하지 않습니다." });
                          return false;
                        }
                      },
                    })}
                    required
                  />
                  {errors["new_user_pwd_match"] && <span className="error_txt">* 비밀번호가 일치하지 않습니다.</span>}
                </li>
              </ul>
            </div>
            <div className="btn_container">
              <button className="com_btn blue s" type="submit">
                변경 {isUpdating && <i className="gg-spinner-alt" />}
              </button>
              <button
                className="com_btn line blue s"
                onClick={async () => {
                  setPasswordModalOpen(false);
                  reset();
                }}>
                취소
              </button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
}
