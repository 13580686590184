import React, {useEffect} from "react";

function Fail() {

    const params = new URLSearchParams(window.location.search);
    const data = params.get("EncodeData");

    useEffect(()=>{
        //백엔드 통신
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const requestOptions = {
        method: "POST",
        body: JSON.stringify({EncodeData : data}),
        headers: myHeaders,
        };
        fetch(`${process.env.REACT_APP_SERVER_BASE_URL_PROD}/nice/fail`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                const CertData = JSON.parse(result);
                console.log(CertData);
                if(CertData.messageCode === 200 && CertData.messageString === "SUCCESS"){
                    //window.close();
                }   
            })
        .catch((error) => console.log("error", error));
    }, [data]);


  return (
    <>  
        <h2>인증 실패</h2>

    </>
  );
}

export default Fail;
