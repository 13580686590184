import React, { useContext, useRef, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import "../../assets/scss/page/mypage.scss";

//image
import IconEdit from "../../assets/img/icon_write.png";
import { AuthContext } from "../../../../../context/Auth";
import { mutateBackend, mutateBackendFormData } from "../../../../../api";
import { toast } from "react-toastify";
import { addComma, getUserImageLink } from "../../../../../utils/misc";
import { useCustomQuery } from "../../../../../hooks/useAPI";

function MyPageSideBar() {
  const { refetchUserInfo, user } = useContext(AuthContext);

  const SideBarLinks = [
    {
      link: "MyResume",
      text: "이력서 관리",
    },
    {
      link: "ai-match",
      text: "AI 매칭 지원 현황",
    },
    {
      link: "PayInsight",
      text: "결제 콘텐츠 목록",
    },
    {
      link: "ProductInsight",
      text: "상품관리",
    },
    {
      link: "Community",
      text: "My 커뮤니티",
    },
    {
      link: "Seminar",
      text: "세미나 신청내역",
    },
    /* 2023-11-16 사용안함 - KSR
    {
      link: "PayList",
      text: "결제 내역",
    },
    {
      link: "Exchange",
      text: "환전 내역",
    },
    */
    {
      link: "Notification",
      text: "알림",
    },
    {
      link: "Faq",
      text: "고객센터",
    },
  ];

  const uploadInput = useRef();

  const [isActive, setIsActive] = useState(false);
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const handleClick = () => {
    setIsActive((prev) => !prev);
  };

  // console.log('LNB~~~~');

  const location = useLocation();

  //2023-11-16 현재 포인트(잔액) - KSR
  const { data: myPoints = {} } = useCustomQuery(`/points/current`, {
    queryKey: ["getMyPoints"],
    select: (res) => res?.data,
    enabled: true,
    /*
    "user_seq": 102,
    "points": -1062100,
    "can_points": 1062202,  //환전 가능 금액
    "req_exchange": 0,      //환전 요청 금액
    "done_exchange": 0      //환전 완료 금액
    */
  });

  async function handleUpload(event) {
    setIsUploadingImage(true);

    const formData = new FormData();

    formData.append("file", event.target.files[0]);
    formData.append("upload_ty", new Date().toISOString());

    const response = await mutateBackendFormData("/upload/file", formData)
      .then(async (data) => await data.json())
      .catch(() => ({ messageCode: 400 }));

    if (response.messageCode === 200) {
      const image_seq = response.data[0]["up_file_seq"];

      const imageResponse = await mutateBackend("/user/upload-image", {
        data: {
          user_seq: user.user_seq,
          image_seq,
        },
        headers: { "Content-Type": "application/json" },
      }).then(async (data) => await data.json());

      if (imageResponse.messageCode === 200) {
        toast.success("사용자 이미지를 성공적으로 업로드했습니다.");
        await refetchUserInfo();
      } else {
        toast.error("사용자 이미지를 업로드하지 못했습니다.");
      }
    } else toast.error("사용자 이미지를 업로드하지 못했습니다.");

    setIsUploadingImage(false);
  }

  return (
    <>
      <aside className="aside">
        <section className="profile_area">
          <input type="file" className="input_file" ref={uploadInput} onChange={handleUpload} hidden={true} />
          <button className="btn_edit" onClick={() => uploadInput.current && uploadInput.current.click()}>
            <img src={IconEdit} alt="수정" />
          </button>
          <div className="profile">
            {isUploadingImage ? (
              <i className="gg-spinner-alt" />
            ) : (
              <img src={getUserImageLink(user["user_img"])} alt="프로필" />
            )}
          </div>
        </section>
        <section className="id_area">
          {user["user_id"]}
          <Link to="/mypage/Profile" className="com_btn s lightgray oval">
            수정
          </Link>
        </section>
        <section className="com_btn_wrap">
          {/* 2023-11-24 사용안함 - KSR
          <Link to="/mypage/MyFit" className="com_btn blue xs oval btn_my">
            나의 성향
          </Link>
          */}
          <Link to="/mypage/ExchangeAccount" className="com_btn blue xs oval btn_account">
            환전 계좌 관리
          </Link>

          {/* </section>
        <section className='com_btn_wrap'> */}
          <Link to="/mypage/JobSearchApply" className="com_btn line blue xs oval btn_apply">
            적극 구직 신청하기
          </Link>
          <button className="com_btn_tip">!</button>
        </section>
        <ul className="point_area">
          <li className="item">
            <Link to="/mypage/Exchange">
              <p>포인트</p>
            </Link>
            <Link to="/mypage/Exchange">
              <b className="num">{addComma(myPoints.points)}</b>
            </Link>
          </li>
          {/*
          <li className="item">
            <p>포인트</p>
            <Link to="/mypage/PointCharge" className="com_btn line blue oval xxs">
              충전
            </Link>
            <b className="num">0</b>
          </li>
          */}
          {/* 2023-11-16 사용안함 - KSR
          <li className="item">
            <p>이벤트 포인트</p>
            <b className="num">2,000P</b>
          </li>
          */}
        </ul>
        <button className="selected_menu" onClick={handleClick}>
          {SideBarLinks.find((link) => link.link === location.pathname.split("/").pop())?.text}
        </button>
        <section className={isActive ? "lnb active" : "lnb"}>
          {SideBarLinks.map((link) => (
            <NavLink
              to={"/mypage/" + link.link}
              className={({
                isActive,
                // isPending
              }) => `menu ${isActive ? "active" : ""}`}
              onClick={() => {
                handleClick();
              }}
              key={link.link}>
              {link.text}
            </NavLink>
          ))}
        </section>
      </aside>
    </>
  );
}

export default MyPageSideBar;
