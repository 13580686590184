import React, { useState } from "react";
import Paging from "../../../component/Paging";
import Table from "../../../../screens/components/boffice/Table";
import MemberFilter from "../../../component/MemberFilter";
import { useCustomQuery } from "../../../../../../hooks/useAPI";

const columns = {
  pay_seq: "번호",
  user_id: "구매자 아이디",
  user_name: "이름",
  goods_name: "상품명",
  goods_amt: "상품 금액",
  reg_dtm: "결제일자",
  pay_status: "결제상태",
  // * If it is a link, it passes an object
  detail: {
    label: "관리",
    getLink: (item) => "/boffice/mentoring/product/pay/" + item["pay_seq"],
    linkLabel: "상세",
  },
};

export default function MentoringProductPayList() {
  const [page, setPage] = useState(1);
  const [paymentsPerPage, setPaymentsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const [paramArguments, setParamArguments] = useState("");

  const [mentoringPaymentList, setMentoringPaymentList] = useState([]);

  const { isFetching } = useCustomQuery(
    `/payments/mentoring/list?per_page=${paymentsPerPage}&curr_page=${page}&` + paramArguments,
    {
      queryKey: ["getMentoringPayments/", paramArguments, page, paymentsPerPage],
      select: (res) => res?.data,
      onSuccess: async (data) => {
        setTotalCount(data.totalCnt);
        setMentoringPaymentList(data.dataList);
      },
      refetchOnWindowFocus: false,
    },
  );

  const handlePageClick = (toPage) => {
    if (Number.isInteger(toPage)) return setPage(toPage);

    switch (toPage) {
      case "first": {
        setPage(1);
        break;
      }
      case "next": {
        page < Math.floor(totalCount / paymentsPerPage) && setPage((prev) => prev + 1);
        break;
      }
      case "prev": {
        page > 1 && setPage((prev) => prev - 1);
        break;
      }
      case "last": {
        setPage(Math.ceil(totalCount / paymentsPerPage));
        break;
      }
    }
  };

  return (
    <main>
      <h2 className="adm_com_pg_tit2 bottom_line"> 멘토 상품 관리</h2>

      <div className="adm_com_pg_content">
        <h2 className="sub_tit">- 상품 결제 내역</h2>

        <MemberFilter
          columns={columns}
          dataPerPage={paymentsPerPage}
          setDataPerPage={setPaymentsPerPage}
          defaultSearchColumn={"user_id"}
          setParamArguments={setParamArguments}
        />
        <div className="sub_tit">
          <span className="txt flex-start">
            {totalCount} 건 {isFetching && <i className="gg-spinner-alt" />}
          </span>
        </div>
        <Table data={mentoringPaymentList} columns={columns} checked={false} handleCheck={null} />
        <Paging
          handlePageClick={handlePageClick}
          page={page}
          totalPageCount={Math.ceil(totalCount / paymentsPerPage)}
        />
      </div>
    </main>
  );
}
