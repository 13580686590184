import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { mutateBackend } from "../../../../../../api";
import { toast } from "react-toastify";

export default function NewCategory() {
  const navigate = useNavigate();

  const [isSaving, setIsSaving] = useState(false);

  const { handleSubmit, register } = useForm({
    mode: "onChange",
  });

  const handleCreateCategory = async (data) => {
    setIsSaving(true);
    const finalData = {
      sort_id: 1,
      cate_gr: "MAG_CATE",
      parent_seq: 0,
      ...data,
    };

    const response = await mutateBackend("/category", {
      data: finalData,
      headers: { "Content-Type": "application/json" },
    }).then(async (data) => await data.json());

    setIsSaving(false);

    if (response.messageCode === 200) {
      toast.success("성공적으로 카테고리를 생성했습니다");
      navigate(-1);
    } else toast.error("카테고리를 만들지 못했습니다.");
  };

  return (
    <form onSubmit={handleSubmit(handleCreateCategory)}>
      <h1 className="adm_com_pg_tit2">카테고리 관리</h1>
      <h2 className="adm_com_pg_tit bottom_line">- 카테고리 추가</h2>
      <div className="adm_com_pg_content">
        <div className="sub_tit"> </div>
        <table className="adm_com_board카테고리 추가ow" style={{ width: "50%" }}>
          <colgroup>
            <col width="10%" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              {/* Category Name */}
              <th>항목명</th>
              <td className="input_wrap">
                <input type="text" required {...register("cate_name")} className="input" />
              </td>
            </tr>
            <tr>
              {/* Category Description */}
              <th>카테고리 설명</th>
              <td className="input_wrap">
                <input type="text" required {...register("cate_desc")} className="input" />
              </td>
            </tr>
            <tr>
              {/* Actively Seeking Employment */}
              <th>사용여부</th>
              <td>
                <select required {...register("use_YN")}>
                  <option value={"Y"}>Y</option>
                  <option value={"N"}>N</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="adm_com_btn_wrap center bottom">
        <button className="adm_com_btn gray" type="submit">
          수정하기 {isSaving && <i className="gg-spinner-alt" />}
        </button>
        <button className="adm_com_btn gray" type="button" onClick={() => navigate(-1)}>
          돌아가기
        </button>
      </div>
    </form>
  );
}
