import React, { useCallback, useState } from "react";
import Paging from "../../../component/Paging";
import Table from "../../../../screens/components/boffice/Table";
import MemberFilter from "../../../component/MemberFilter";
import { useCustomQuery } from "../../../../../../hooks/useAPI";
import useCommCate from "../../../../../../hooks/useCommCate";

const columns = {
  mento_seq: "번호",
  user_id: "아이디",
  user_name: "사용자 이름",
  mento_title: "상품명",
  mento_cate: "카테고리",
  mentee_cnt: "총 멘티 수",
  avg_rating: "후기 총 평점",
  reg_dtm: "등록일자",
  // * If it is a link, it passes an object
  detail: {
    label: "상세",
    getLink: (item) => "/boffice/mentoring/product/" + item["mento_seq"],
    linkLabel: "상세",
  },
};

export default function MentoringProductList() {
  const [page, setPage] = useState(1);
  const [memberPerPage, setMemberPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const [paramArguments, setParamArguments] = useState("");

  const [mentoringProductList, setMentoringProductList] = useState([]);

  const { isFetching } = useCustomQuery(`/mentoring?per_page=${memberPerPage}&curr_page=${page}&` + paramArguments, {
    queryKey: ["getMentoring/", paramArguments, page, memberPerPage],
    select: (res) => res?.data,
    onSuccess: async (data) => {
      setTotalCount(data.total_count);
      setMentoringProductList(data.data_list);
    },
    refetchOnWindowFocus: false,
  });

  const { data: categories } = useCommCate("MENTO_CATE");

  const getMentoCate = useCallback(
    (cate_id) => {
      if (!categories) return "";

      const category = categories.find((category) => Number(category["cate_seq"]) === Number(cate_id));

      if (category) {
        return category["cate_name"];
      } else "N/A";
    },
    [categories],
  );

  const handlePageClick = (toPage) => {
    if (Number.isInteger(toPage)) return setPage(toPage);

    switch (toPage) {
      case "first": {
        setPage(1);
        break;
      }
      case "next": {
        page < Math.floor(totalCount / memberPerPage) && setPage((prev) => prev + 1);
        break;
      }
      case "prev": {
        page > 1 && setPage((prev) => prev - 1);
        break;
      }
      case "last": {
        setPage(Math.ceil(totalCount / memberPerPage));
        break;
      }
    }
  };

  return (
    <main>
      <h2 className="adm_com_pg_tit2 bottom_line"> 멘토 상품 관리</h2>
      <div className="adm_com_pg_content">
        <h2 className="sub_tit">- 상품 내역 </h2>
        <MemberFilter
          columns={columns}
          dataPerPage={memberPerPage}
          setDataPerPage={setMemberPerPage}
          defaultSearchColumn={"mento_title"}
          setParamArguments={setParamArguments}
        />
        <div className="sub_tit">
          <span className="txt flex-start">
            {totalCount} 건 {isFetching && <i className="gg-spinner-alt" />}
          </span>
        </div>
        <Table
          data={mentoringProductList.map((product) => ({
            ...product,
            avg_rating: product["avg_rating"] ? Number(product["avg_rating"]).toFixed(1) : "N/A",
            mento_cate: getMentoCate(product["mento_cate"]),
          }))}
          columns={columns}
          checked={false}
          handleCheck={null}
        />
        <Paging handlePageClick={handlePageClick} page={page} totalPageCount={Math.ceil(totalCount / memberPerPage)} />
      </div>
    </main>
  );
}
