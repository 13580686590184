import React, { useEffect, useState } from "react";
import Paging from "../../component/Paging";
import Table from "../../../screens/components/boffice/Table";
import CommunityCategoryFilter from "../../component/CommunityCategoryFilter";
import { useCustomQuery } from "../../../../../hooks/useAPI";

const columns = {
  req_seq: "번호",
  applicant_id: "신청자 ID",
  applicant_name: "신청자 이름",
  req_cate_nm: "이름",
  reg_dtm: "지원 날짜",
  req_status: "승인 상태",
  // * If it is a link, it passes an object
  detail: {
    label: "상세",
    getLink: (item) => "/boffice/board/community/cate/" + item["req_seq"],
    linkLabel: "상세",
  },
};

const CategoryStatus = Object.freeze({
  ACC: "받아 들여졌다",
  REJ: "거부",
  REQ: "승인 대기 중",
});

export default function CommunityCategoryApplyList() {
  const [page, setPage] = useState(1);
  const [categoryPerPage, setCategoryPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const [approvalStatus, setApprovalStatus] = useState("");

  const [paramArguments, setParamArguments] = useState("");

  const [categoryList, setCategoryList] = useState([]);

  const { isFetching } = useCustomQuery(
    `/category/reqCate?per_page=${categoryPerPage}&curr_page=${page}&` + paramArguments,
    {
      queryKey: ["getCategoryApplication/", paramArguments, page, categoryPerPage],
      select: (res) => res?.data,
      onSuccess: async (data) => {
        setTotalCount(data?.categories?.length);
        setCategoryList(data?.categories);
      },
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    const params = new URLSearchParams(paramArguments);
    if (approvalStatus !== "") params.set("req_status", approvalStatus);
    else params.delete("req_status");

    setParamArguments(params.toString());
  }, [approvalStatus]);

  const handlePageClick = (toPage) => {
    if (Number.isInteger(toPage)) return setPage(toPage);

    switch (toPage) {
      case "first": {
        setPage(1);
        break;
      }
      case "next": {
        page < Math.floor(totalCount / categoryPerPage) && setPage((prev) => prev + 1);
        break;
      }
      case "prev": {
        page > 1 && setPage((prev) => prev - 1);
        break;
      }
      case "last": {
        setPage(Math.ceil(totalCount / categoryPerPage));
        break;
      }
    }
  };

  return (
    <main>
      <h2 className="adm_com_pg_tit">회원목록</h2>
      <div className="adm_com_pg_content">
        <CommunityCategoryFilter
          columns={columns}
          dateLabel={"신청일자"}
          dataPerPage={categoryPerPage}
          setDataPerPage={setCategoryPerPage}
          defaultSearchColumn={"user_name"}
          setParamArguments={setParamArguments}
          additionalFields={
            <div className="adm_com_input_wrap flex_row">
              <span className="tit">승인상태</span>
              <select className="select" onChange={(e) => setApprovalStatus(e.target.value)}>
                <option value={""}>전체</option>
                <option value={"ACC"}>{CategoryStatus["ACC"]}</option>
                <option value={"REJ"}>{CategoryStatus["REJ"]}</option>
                <option value={"REQ"}>{CategoryStatus["REQ"]}</option>
              </select>
            </div>
          }
        />

        <div className="sub_tit">
          <span className="txt flex-start">
            {totalCount} 건 {isFetching && <i className="gg-spinner-alt" />}
          </span>
        </div>
        <Table
          data={categoryList?.map((category) => ({ ...category, req_status: CategoryStatus[category.req_status] }))}
          columns={columns}
          checked={false}
          handleCheck={null}
        />
        <Paging
          handlePageClick={handlePageClick}
          page={page}
          totalPageCount={Math.ceil(totalCount / categoryPerPage)}
        />
      </div>
    </main>
  );
}
