import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../assets/scss/page/member.scss";
//component
import Pagination from "../../components/Pagination";
import Modal from "../../components/modal";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import { addComma } from "../../../../../utils/misc";
import moment from "moment";

function Exchange() {
  const [modalOpen, setModalOpen] = useState(false);
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const [per_page, setPerPage] = useState(10);
  const [curr_page, setCurrPage] = useState(1);

  //2023-11-16 현재 포인트(잔액) - KSR
  const { data: myPoints = {} } = useCustomQuery(`/points/current`, {
    queryKey: ["getMyPoints"],
    select: (res) => res?.data,
    enabled: true,
    /*
    "user_seq": 102,
    "points": -1062100,
    "can_points": 1062202,  //환전 가능 금액
    "req_exchange": 0,      //환전 요청 금액
    "done_exchange": 0      //환전 완료 금액
    */
  });

  const {
      data: myPointsList,
      isFetching: isFetchingPointsLIst,
      refetch,
    } = useCustomQuery(`/points/list?per_page=${per_page}&curr_page=${curr_page}`, {
    queryKey: ["getPointsList"],
    select: (res) => res?.data,
    //enabled: per_page,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    refetch();
  },[per_page, curr_page]);

  //이전 페이지
  const handlePrevClick = () => {
    if (curr_page > 1) {
      setCurrPage((curr_page) => curr_page - 1);
    }
  };

  //다음 페이지
  const handleNextClick = () => {
    if (curr_page < Math.ceil(myPointsList?.totalCnt / per_page)) {
      setCurrPage((curr_page) => curr_page + 1);
    }
  };

  //per page 변경
  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value));
  };
  

  return (
    <>
      <div className="container">
        <h2 className="com_pg_tit">환전내역</h2>
        <section className="sec_point">
          <article className="item">
            <h3 className="tit">총 포인트</h3>
            <p className="num">{addComma(myPoints?.points)}p</p>
          </article>
          <article className="item">
            <h3 className="tit">환전 가능 포인트</h3>
            <p className="num">
              {addComma(myPoints?.can_points)}p
              <button className="com_btn blue xs oval" onClick={() => setModalOpen(true)}>
                환전
              </button>
            </p>
          </article>
          <article className="item">
            <h3 className="tit">환전 신청 포인트</h3>
            <p className="num">{addComma(myPoints?.req_exchange)}p</p>
          </article>
          <article className="item">
            <h3 className="tit">환전 완료 포인트</h3>
            <p className="num">{addComma(myPoints?.done_exchange)}p</p>
          </article>
        </section>
        <section className="com_board_search">
          <select className="seclect">
            <option>전체</option>
          </select>
          <article className="date_select type_btns">
            <button className="com_btn blue line s">전체</button>
            <button className="com_btn lightgray s">1개월</button>
            <button className="com_btn lightgray s">3개월</button>
          </article>
          <article className="date_select type_input">
            <input type="date" className="com_datepicker" placeholder="2022. 01. 01" />
            <input type="date" className="com_datepicker" placeholder="2022. 01. 01" />
            <button className="com_btn blue s">조회</button>
          </article>
        </section>
        <table className="com_board_list">
          <colgroup>
            <col width="26%" />
            <col />
            <col width="16%" />
            <col width="18%" />
          </colgroup>
          <thead>
            <tr>
              <th>날짜</th>
              <th>구분</th>
              <th>금액</th>
              <th>상태</th>
            </tr>
          </thead>
          <tbody>
          {myPointsList?.dataList?.length > 0 ? (
            myPointsList?.dataList?.map((points) => {
              return (
                <tr key={points.points_no}>
                  <td className="info_field">
                    <span className="th_m">날짜 :</span>
                    {moment(points.reg_dtm).format("YYYY.MM.DD HH:MM")}
                  </td>
                  <td className="tit_field">{points.points_desc}</td>
                  <td className="info_field last">
                    <span className="th_m">금액 :</span>
                    {addComma(points.points_val)}p
                  </td>
                  <td className="state_field">{points.points_val < 0 ? '사용' : '적립'}</td>
                </tr>
              );
          })
          ) : (
            <tr>
              <td colSpan="6">데이터가 없습니다</td>
            </tr>
          )}
          </tbody>
        </table>
        {!isFetchingPointsLIst && (
          <Pagination
            currentPage={curr_page}
            totalPages={Math.ceil(myPointsList?.totalCnt / per_page) === 0 ? 1 : Math.ceil(myPointsList?.totalCnt / per_page)}
            onNextClick={handleNextClick}
            onPrevClick={handlePrevClick}
            perPage={per_page}
            handlePerPageChange={handlePerPageChange}
          />
        )}
      </div>
      {/* top button */}
      <Modal open={modalOpen} close={handleCloseModal} type="type_alert">
        <div className="content_container scroll">
          <strong>계좌 내용이 확인되지 않습니다.</strong> <br />
          환전계좌를 입력 하시겠습니까?
        </div>
        <div className="btn_container">
          <Link to="/mypage/account" className="com_btn blue s">
            작성하기
          </Link>
          <button className="com_btn blue line s" onClick={() => handleCloseModal()}>
            취소
          </button>
        </div>
      </Modal>
    </>
  );
}

export default Exchange;
