import React from "react";

const Main = () => {
  return (
    <main>
      <div className="adm_com_center_wrap">메인페이지</div>
    </main>
  );
};

export default Main;
