import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
//image
import imgCertify from "../../assets/img/logo.png";
//component
import { useCustomQuery } from "../../../../../hooks/useAPI";
import { getImageLink } from "../../../../../utils/misc";
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import PopJobSelect from "./_PopJobSelect";
import PopMentoRecommend from "./_PopMentoRecommend";
import PopSuggestionResume from "./_PopSuggestionResume";
import PopJobWord from "./_PopWord";

function Resume() {
  const [modalRecommendOpen, setModalRecommendOpen] = useState(false);
  const [modalSuggestionOpen, setModalSuggestionOpen] = useState(false);
  const [modalJobWordOpen, setModalJobWordOpen] = useState(false);
  const [modalJobSelectOpen, setModalJobSelectOpen] = useState(false);
  const handleCloseRecommendModal = () => {
    setModalRecommendOpen(false);
  };

  const { resume_seq } = useParams();

  const navigate = useNavigate();

  if (!resume_seq) navigate(-1);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const recruit_seq = params.get("recruit_seq");

  const { data: resume, isFetching: resumeFetching } = useCustomQuery(`/resume/view/${resume_seq}`, {
    queryKey: ["getResume"],
    select: (res) => res?.data,
    refetchOnWindowFocus: false,
  });

  const { data: recriutDetail } = useCustomQuery(`/recruit/${recruit_seq}`, {
    queryKey: ["getRecruit"],
    select: (res) => res?.data?.recruit,
    refetchOnWindowFocus: false,
  });

  const calculateExperience = (careers) => {
    let totalExperience = 0;

    for (const career of careers) {
      const startDate = new Date(career.join_date);
      const endDate = new Date(career.leave_date);

      const timeDiff = Math.abs(endDate - startDate);
      const yearsDiff = timeDiff / (1000 * 60 * 60 * 24 * 365.25);

      totalExperience += yearsDiff;
    }
    return Math.ceil(totalExperience);
  };

  const additionalCareers = resume?.resume_career.filter((career) => career.career_type === "ADD");
  const certifiedCareers = resume?.resume_career.filter((career) => career.career_type === "CERTI");

  if (resumeFetching) return <i className="gg-spinner-alt" />;

  return (
    <>
      <div className="container">
        <div className="tab_content">
          <ul className="profile_list">
            <li className="item">
              <p className="profile">
                <img
                  src={
                    recriutDetail?.comp_image_seqs
                      ? getImageLink(recriutDetail?.comp_image_seqs)
                      : `https://icons.veryicon.com/png/o/miscellaneous/zr_icon/company-23.png`
                  }
                  alt="profile"
                />
              </p>
              <ul className="info_list">
                <li className="info_item">
                  <span className="name">이름</span>
                  <span className="txt">
                    {resume?.resume_info.resume_name}{" "}
                    <span className="com_icon_status bg_orange line_orange">멘토 추천서</span>
                  </span>
                </li>
                <li className="info_item">
                  <span className="name">연락처</span>
                  <span className="txt">{resume?.resume_info.resume_mobile}</span>
                </li>
                <li className="info_item">
                  <span className="name">경력</span>
                  {resume && <span className="txt">{calculateExperience(resume?.resume_career)} 년 경력</span>}
                </li>
                <li className="info_item">
                  <span className="name">성별</span>
                  <span className="txt">남성</span>
                </li>
                <li className="info_item">
                  <span className="name">근무회사</span>
                  <span className="txt">Awesome (5년)Awesome (5년)Awesome (5년)..</span>
                </li>
                <li className="info_item">
                  <span className="name">희망직무</span>
                  <span className="txt">제품 엔지니어, Corap</span>
                </li>
              </ul>
              <div className="com_btn_wrap">
                <button className="com_btn line xs oval btn_print">인쇄하기</button>
              </div>
            </li>
          </ul>
          <div className="profile_info">
            <div className="graph_wrap">
              <ul className="graph_list">
                <li className="graph_item">
                  <span className="bg bg_blue" style={{ height: "30%" }}>
                    <span className="bg_txt">30%</span>
                  </span>
                  <span className="graph_txt">조직적합성</span>
                </li>
                <li className="graph_item">
                  <span className="bg bg_emerald" style={{ height: "55%" }}>
                    <span className="bg_txt">55%</span>
                  </span>
                  <span className="graph_txt">직무적합성</span>
                </li>
                <li className="graph_item">
                  <span className="bg bg_green" style={{ height: "95%" }}>
                    <span className="bg_txt">95%</span>
                  </span>
                  <span className="graph_txt">복리후생</span>
                </li>
              </ul>
            </div>
            <ul className="com_info_sec">
              <li className="field_box">
                <span className="tit">학력</span>
                <ul className="info_box">
                  {resume?.resume_academic.map((acad) => {
                    return (
                      <li key={acad.acad_no}>
                        • {acad.acad_name}({acad.acad_major})ㆍ{acad.finish_date.split("T")[0]}(졸업)
                      </li>
                    );
                  })}
                </ul>
              </li>
              <li className="field_box">
                <span className="tit">인증 경력</span>
                <div className="info_box">
                  {certifiedCareers?.length === 0 && <p className="txt">No certified careers</p>}
                  {certifiedCareers?.map((career) => {
                    return (
                      <li
                        key={career.career_no}
                        style={{
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        }}>
                        <p className="txt">
                          • (주) {career.career_company}({career.career_depart} / {career.career_posi}
                          )ㆍ{career.join_date}~{career.leave_date}(퇴사){" "}
                          <span className="icon_certify">
                            <img src={imgCertify} alt="certify icon" />
                          </span>
                        </p>
                        <p className="txt">{career.career_desc}</p>
                        <br />
                        <p className="txt">Projects</p>
                        <ul
                          style={{
                            marginLeft: "15px",
                          }}>
                          {career?.career_proj?.map((proj) => {
                            return (
                              <li className="txt" key={proj.proj_no}>
                                <b>프로젝트명</b>: {proj.proj_name} ㆍ<b>프로젝트 기간</b>: {proj.proj_start} -{" "}
                                {proj.proj_end}ㆍ<b>프로젝트 사용 툴</b>: {proj.proj_tools} ㆍ<b>발주처</b>: -{" "}
                                {proj.proj_no}
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    );
                  })}
                </div>
              </li>
              <li className="field_box">
                <span className="tit">추가 경력</span>
                <ul className="info_box">
                  {additionalCareers?.length === 0 && <p className="txt">No Additional careers</p>}
                  {additionalCareers?.map((career) => {
                    return (
                      <li
                        key={career.career_no}
                        style={{
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        }}>
                        <p className="txt">
                          • (주) {career.career_company}({career.career_depart} / {career.career_posi}
                          )ㆍ{career.join_date}~{career.leave_date}(퇴사){" "}
                        </p>
                        <p className="txt">{career.career_desc}</p>
                      </li>
                    );
                  })}
                </ul>
              </li>
              <li className="field_box">
                <span className="tit">자격증</span>
                <ul className="info_box">
                  {resume?.resume_certificate?.length === 0 && <p className="txt">No certificates</p>}
                  {resume?.resume_certificate.map((cert) => {
                    return (
                      <li key={cert.cert_no}>
                        • {cert.cert_name}({cert.certi_insti})ㆍ{cert.certi_acqu_date.split("T")[0]}(졸업)
                      </li>
                    );
                  })}
                </ul>
              </li>
              <li className="field_box">
                <span className="tit">교육</span>
                <ul className="info_box">
                  {resume?.resume_education?.length === 0 && <p className="txt">No education</p>}
                  {resume?.resume_education.map((edu) => {
                    return (
                      <li key={edu.edu_no} className="txt">
                        • {edu.edu_insti}({edu.edu_name})ㆍ{edu.edu_end.split("T")[0]}
                      </li>
                    );
                  })}
                </ul>
              </li>
              <li className="field_box">
                <span className="tit">보유스킬</span>
                <div className="info_box">{resume?.resume_info.resume_skill}</div>
              </li>
              <li className="field_box">
                <span className="tit">TOOL</span>
                <div className="info_box">{resume?.resume_info.resume_tools}</div>
              </li>
              <li className="field_box">
                <span className="tit">링크</span>
                <ul className="info_box">
                  {resume?.link_url?.length === 0 && <p className="txt">No links</p>}
                  {resume?.link_url?.map((link) => {
                    return (
                      <li className="txt" key={link.link_seq}>
                        •
                        {link.link_type === "file" ? (
                          <a href={`/`}>Download File</a>
                        ) : (
                          <a href={link.link_val} className="txt" target="_blank" rel="noreferrer">
                            {link.link_val}
                          </a>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </li>
              <li className="field_box">
                <span className="tit">멘토 추천서</span>
                <div className="info_box">
                  <p className="txt">
                    멘토명{" "}
                    <button
                      className="com_icon_status arrow_right arrow_green"
                      onClick={() => {
                        setModalRecommendOpen(true);
                      }}>
                      추천서 보기
                    </button>
                  </p>
                  <p className="txt">추천서 제목</p>
                </div>
              </li>
            </ul>
          </div>
          <div className="com_btn_wrap center">
            <Link to="/Company/Recruit" className="com_btn line blue s">
              공고 목록으로
            </Link>
            <button
              className="com_btn blue s"
              onClick={() => {
                setModalSuggestionOpen(true);
              }}>
              면접 제안
            </button>
          </div>
        </div>
      </div>
      {/* top button */}
      <ScrollToTopButton />
      {/* Modal - mento recommend */}
      <PopMentoRecommend open={modalRecommendOpen} close={() => setModalRecommendOpen(false)} />
      {/* Modal - interview suggestion */}
      <PopSuggestionResume open={modalSuggestionOpen} close={() => setModalSuggestionOpen(false)}>
        <div className="btn_container">
          {/* <button
            className="com_btn line s"
            onClick={() => {
              setModalJobWordOpen(true);
            }}>
            문구 가져오기
          </button>
          <button
            className="com_btn line s"
            onClick={() => {
              setModalJobSelectOpen(true);
            }}>
            공고 설정
          </button> */}
          <button className="com_btn bg blue s btn_suggestion" onClick={() => handleCloseRecommendModal()}>
            면접 제안 보내기
          </button>
        </div>
      </PopSuggestionResume>
      {/* Modal - job word select */}
      <PopJobWord open={modalJobWordOpen} close={() => setModalJobWordOpen(false)} />
      {/* Modal - job select */}
      <PopJobSelect open={modalJobSelectOpen} close={() => setModalJobSelectOpen(false)} />
    </>
  );
}

export default Resume;
