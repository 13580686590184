import React from "react";
import { Routes, Route } from "react-router-dom";
import "../views/boffice/assets/scss/styles.scss";

import CommunityCategoryApplyList from "../views/boffice/page/community/ApplyList";
import CommunityApplicationDetail from "../views/boffice/page/community/ApplyDetail";

export default function RouterBofficeCommunity() {
  // Authentication logic is done in the router, for now the /screens
  return (
    <Routes>
      <Route path="/cate" element={<CommunityCategoryApplyList />} />
      <Route path="/cate/:req_seq" element={<CommunityApplicationDetail />} />
    </Routes>
  );
}
