import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer">
      <div className="com_center_wrap">
        <section className="link">
          <Link to="/company/hh" className="com_btn blue oval xs btn_hH">
            채용대행신청
          </Link>
          <Link to="/etc/terms" className="btn com_txt_point">
            서비스 이용약관
          </Link>
          <span className="bar"></span>
          <Link to="/etc/privacy" className="btn">
            개인정보 처리방침
          </Link>
        </section>
        <section className="info">
          <address className="address">
            <div>
              상호명 : (주)씨이에스 <span className="bar"></span>
              대표명 : 김남구<span className="bar"></span>
              사업자번호 : 139-81-40750
            </div>
            <div>
              주소 : 인천시 연수구 갯벌로12, 갯벌타워 10층
              <span className="bar"></span>
              개인정보책임자 : 서경식
              <span className="bar"></span>
              대표문의전화 : 032-250-1256
            </div>
            <div>
              제휴문의 이메일 : hr-partnership@cescom.co.kr
            </div>
          </address>
          <p className="copy">Copyright by ㈜씨이에스 All rights reserved.</p>
        </section>
      </div>
    </footer>
  );
}
export default Footer;
