import React, { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import { AuthContext } from "../../../../../context/Auth";
import moment from "moment";
import { getFileLink } from "../../../../../utils/misc";
import { updateBackend } from "../../../../../api";
//component

function QnaView() {
  const { user } = useContext(AuthContext);

  const location = useLocation();
  const navigate = useNavigate();

  const { parent_seq, mento_seq } = location.state;

  const {
    data: { q_data: question, a_data: answer } = {
      q_data: [],
      a_data: [],
    },
  } = useCustomQuery(`/community?board_type=MENTO&board_seq=${parent_seq}`, {
    queryKey: ["getDetail", parent_seq],
    select: (res) => res?.data,
  });

  const deleteBoard = async (board_seq) => {
    const responseData = await updateBackend("/community/delete", {
      data: {
        board_seq: board_seq,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    const response = await responseData.json();

    if (response.messageCode === 200) {
      navigate("/Mypage/QnaList", { state: { mento_seq: mento_seq } });
    }
  };

  return (
    <>
      <div className="container">
        <section className="sec_detail">
          <div className="title_wrap">
            <div className="heading_wrap">
              <div className="header">
                <h2 className="tit">{question.board_title}</h2>
                <div className="user">
                  <span className="username">{question.user_nickname}</span>
                  <span className="date">{moment(question.reg_dtm).format("YYYY.MM.DD HH:MM")}</span>
                </div>
              </div>
              {user.user_seq === question.user_seq && question.board_contents !== "삭제된 게시글입니다." && (
                <ul className="like_wrap">
                  <li className="action">
                    <Link
                      to="/Mypage/QnaWrite"
                      state={{ mento_seq: mento_seq, parent_seq: 0, board_seq: question.board_seq }}
                      className="com_btn xxs oval lightgray">
                      수정
                    </Link>
                  </li>
                  <li className="action">
                    <button className="com_btn xxs oval lightgray" onClick={() => deleteBoard(question.board_seq)}>
                      삭제
                    </button>
                  </li>
                </ul>
              )}
            </div>
          </div>
          <div className="description_wrap">
            <p>{question.board_contents}</p>

            <div className="file_area">
              {question.file_info?.length > 0
                ? question.file_info.map((file) => (
                    <Link to={getFileLink(file.up_file_seq)} className="com_btn_file" key={file.up_file_seq}>
                      {file.file_real_name}
                    </Link>
                  ))
                : null}
            </div>
          </div>
          {answer ? (
            <div className="comment_box_wrap">
              <div className="comment_input_box">
                <p className="comment">{`[답변] ${answer.board_title}`}</p>
                {user.user_seq === answer.mentoring_user_seq && answer.board_contents !== "삭제된 게시글입니다." && (
                  <li className="action">
                    <Link
                      to="/Mypage/QnaWrite"
                      state={{ mento_seq: mento_seq, parent_seq: parent_seq, board_seq: answer.board_seq }}
                      className="com_btn xxs oval lightgray">
                      수정
                    </Link>
                    <button className="com_btn xxs oval lightgray" onClick={() => deleteBoard(answer.board_seq)}>
                      삭제
                    </button>
                  </li>
                )}
              </div>
              <div className="comment_wrap">
                <div className="user">
                  <p className="username">
                    {answer.user_nickname}
                    <span className="duration">{moment(question.reg_dtm).format("YYYY.MM.DD HH:MM")}</span>
                  </p>
                </div>
                <p className="desc">{answer.board_contents}</p>
                <div className="file">
                  {answer.file_info?.length > 0
                    ? answer.file_info.map((file) => (
                        <Link to={getFileLink(file.up_file_seq)} className="com_btn_file" key={file.up_file_seq}>
                          {file.file_real_name}
                        </Link>
                      ))
                    : null}
                </div>
              </div>
            </div>
          ) : user.user_seq !== question.mentoring_user_seq ||
            answer.board_contents !== "삭제된 게시글입니다." ? null : (
            <Link
              to="/Mypage/QnaWrite"
              state={{ mento_seq: mento_seq, parent_seq: question.board_seq }}
              className="com_btn blue xs oval">
              답변 작성하기
            </Link>
          )}
        </section>
      </div>
    </>
  );
}

export default QnaView;
