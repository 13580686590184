/* eslint-disable */
import React from 'react';
import "../../assets/scss/page/etc.scss";
function AgreeCompany() {
  return (
    <>
      <main className='com_term'>
        <div className='com_center_wrap'>
          <h2 className='pg_tit'>기업회원 서비스 이용계약서</h2>
          <div className='box'>
            <dl className='sec'>
            <dt className="tit">기업회원 서비스 이용계약서</dt>
            <dt className="tit">제1조 계약의 목적</dt>
            <dd className="txt">본 계약은 “회사”가 엔지니어스 웹/앱 서비스(이하 ‘채용플랫폼’)를 통한 인력채용, 정보제공 등 서비스 (이하 ‘서비스’)를 제공하고 “고객”이 서비스를 이용함에 있어서 “회사”와 “고객”(이하 각 ‘당사자’)에게 주어지는 각종 권리와 의무와 관련된 사항을 정한다.</dd>

            <dt className="tit">제2조 계약기간</dt>
            본 계약은 “회사”와 “고객”이 계약을 체결한 날부터 1년 간(이하 ‘계약기간’) 유효하며, 계약기간 만료 1개월 전에 일방으로부터 별도의 의사 표시가 없는 한 동일한 조건으로 자동으로 1년 연장된다.

            <dt className="tit">제3조 서비스의 이용</dt>
            <dd className="txt">① “고객”은 채용플랫폼에 구인공고를 게시하여 해당 공고를 통해 구직하려는 자(이하 ‘후보자’)의 지원을 받거나 먼저 후보자의 정보를 확인하여 면접할 자(이하 ‘면접대상자’)를 지정할 수 있다. “고객”은 후보자가 면접대상자가 되는 즉시 “회사”에 그 사실을 고지해야 한다.</dd>
            <dd className="txt">② “고객”은 후보자 또는 면접대상자와 근로관계의 주요사항에 대한 합의를 완료(이하 ‘채용확정’)한 즉시 채용플랫폼을 통해 “회사”에게 이를 고지해야 하며, 연봉, 입사일(실제 출근일과 계약상 출근일이 다른 경우 선도래하는 일자/수습기간이 있을 경우 시작일), 세금계산서 수신을 위한 이메일주소 등 정보를 채용플랫폼에 입력하고 채용확정한 자(이하 ‘채용대상자’)와 사이에 체결한 계약서 등 근거자료를 지체없이 “회사”에 전달한다.</dd>
            <dd className="txt">③ “고객”은 서비스를 통해 정규직 근로자 또는 1년 이상의 기간을 정하여 근로할 자만을 기준으로 구인할 수 있고, 계약기간을 1년 미만으로 정하여 일할 자(인턴, 프리랜서, 위촉직, 파견직, 근로자 등 포함)를 구인할 공고는 별도의 “회사”에 의뢰 요청하여 진행해야 한다.</dd>
            <dd className="txt">④ “고객”은 후보자 검색, 면접대상자 연락, 채용확정 등 본 계약을 이행하거나 서비스를 이용하는 과정에서 “회사”가 제공한 정보를 제3자에게 제공하지 않는다.</dd>

            <dt className="tit">제4조 채용수수료 지급 등</dt>
            <dd className="txt">① “고객”은 서비스 이용에 관한 대가로 “회사”에게 수수료(이하 ‘채용수수료’)를 지급하며, 채용대상자가 출근하거나 “고객”을 위하여 실제 근로를 제공한 경우 지급한다.</dd>
            <dd className="txt">② 채용수수료는 채용대상자의 연봉(입사일로부터 1년 동안 “갑”으로부터 수령하는 대가를 말하며, 계약상 기본연봉과 고정지급하는 제수당을 포함하나 성과에 따른 변동성 급여는 제외)의 7%(부가가치세 별도)로 정한다.</dd>
            <dd className="txt">③ “고객”이 제 3조 2항의 연봉 등 고지의무를 이행하지 않을 경우, “회사”는 전항의 연봉을 해당 연도 최저임금의 3배로 산정하여 채용수수료를 정한다.</dd>
            <dd className="txt">④ “회사”는 입사일 이후 지체없이 “고객”에게 채용수수료에 대한 세금계산서를 발행하고, “고객”은 발행일로부터 2주 이내에 “회사”에게 채용수수료를 지급하여야 한다.</dd>
            <dd className="txt">⑤ 채용대상자가 입사일로부터 90일 이내에 퇴사하고 “고객”이 그 퇴사한 날로부터 5영업일 이내에 “회사”에 서면으로 통지한 경우, “회사”는 “고객”이 지급한 채용수수료의 20%를 제하고 나머지 금원을 사전에 통지한 계좌로 반환한다.</dd>
            <dd className="txt">⑥ “고객”이 “회사”을 통하여 계약기간을 1년 미만으로 정한 자를 채용확정한 경우, 채용대상자에게 지급하는 대가를 1년 기준으로 환산한 금액의 7%를 채용수수료로 “회사”에게 지급한다.</dd>

            <dt className="tit">제5조 후보자지위 유지</dt>
            <dd className="txt">① “고객”이 후보자의 지원을 받거나 면접을 제안한 경우, 해당 일자로부터 6개월간 후보자(본 조에서 면접대상자 포함)의 지위(이하 ‘후보자지위’)가 유지되며, 후보자가 “회사”가 아닌 제3자를 통하여 채용되거나 “회사”를 배제하고 직접 계약을 체결하더라도 후보자지위는 유지된다.</dd>
            <dd className="txt">② 전항은 후보자가 최초 지원한 직종과 다른 직종으로 채용하는 경우에도 적용하며, “고객”이 전항에 따라 후보자지위가 있는 자를 채용한 경우 “회사”에게 본 계약 제3조 및 제4조에 따른 의무를 부담한다.</dd>
            <dd className="txt">③ 후보자가 “고객”의 구인공고에 지원한 날부터 소급하여 6개월 이내에 다른 방법으로 같은 구인공고에 지원한 경우, “고객”은 “회사”에게 해당 사실을 증명할 자료를 전달하고 “회사”가 이의를 제기하지 않은 경우에는 후보자지위를 상실한다.</dd>

            <dt className="tit">제6조 비밀유지의무</dt>
            <br />① 각 당사자는 본 계약을 이행하는 과정에서 취득한 상대방의 영업, 판매, 기술 정보와 노하우 등(이하 ‘비밀보장’)을 상대방의 사전 서면 동의 없이 제3자에게 제공 또는 누설하지 않는다.
            <br />② “고객”은 후보자, 면접대상자, 채용확정자의 개인정보를 법령에 따라 보호하며, 서비스 이용 또는 채용 진행 중 알게 된 해당 인력의 지원사실, 경력정보 등에 대해 사전 동의 없이 제3자에게 제공하지 않는다.

            <dt className="tit">제7조 기타</dt>
            <dd className="txt">① “고객”이 제3조를 위반하거나 채용수수료 지급을 지체하는 경우, “회사”는 “고객”에 대한 서비스 이용제한 또는 회원자격 상실 등의 조치를 취할 수 있다.</dd>
            <dd className="txt">② 각 당사자는 상대방이 본 계약을 위반하는 경우 그로 인한 손해배상을 청구할 수 있으며, “고객”이 채용수수료를 지급하지 않는 경우 “회사”는 “고객”에게 원금 및 그에 대한 지연이자와 이행지체로 인하여 “회사”에 발생한 손해를 배상할 것을 청구한다.</dd>
            <dd className="txt">③ 본 계약은 서명한 날로부터 유효하며, 양 당사자 사이에 본 계약에 관한 분쟁이 발생하는 경우에는 회사의 주소지 관할법원을 전속적 관할로 정하여 해결한다.</dd>

            <dd className="txt">본 기업회원 서비스 이용계약서 내용을 충분히 확인하고 이해하였습니다.<br />
            [예] [아니오] 버튼</dd>

            <dd className="txt">“고객”은 상기와 같이 ‘기업회원 서비스 이용계약서’를 이용함에 있어 충분히 내용을 확인하였으며, 이에 계약체결에 동의합니다.<br />
            [동의합니다] 버튼</dd>
            </dl>
          </div>
        </div>
      </main>
    </>
  );
}

export default AgreeCompany;
