import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Upload from "../../components/Upload";
import Pagination from "../../components/Pagination";
import { mutateBackend, mutateBackendFormData, queryBackend } from "../../../../../api";
import { toast } from "react-toastify";
import moment from "moment";

export const NonMember = ({ user, mento_seq }) => {

  const navigate = useNavigate();
  const { getValues, register } = useForm();

  
  const [localFiles, setLocalFiles] = useState([]);

  const handleMentoringUpload = async (mento_seq, ids = []) => {
    const response = await mutateBackend("/mentoring/upload", {
      data: { mento_seq, ids, table_name: "ces_mentoring_question" },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (data) => await data.json())
      .catch((err) => {
        throw new Error(err);
      });

    if (response.messageString === "SUCCESS" && response.messageCode === 200) {
      console.log("handleCommunityUpload", response.data);
      return;
    } else {
      return;
    }
  };

  const handleUpload = async (mento_seq) => {
    const formData = new FormData();
    localFiles.forEach((file) => {
      formData.append("file", file);
    });
    formData.append("upload_ty", "mentoring-board");

    const response = await mutateBackendFormData("/upload/image", formData)
      .then(async (data) => await data.json())
      .catch(() => ({ messageCode: 400 }));

    if (response.messageCode === 200) {
      response.data?.length > 0 &&
        (await handleMentoringUpload(
          mento_seq,
          response.data.map((obj) => obj?.up_file_seq),
        ));
      return;
    } else {
      console.log("file failed");
      return;
    }
  };

  const handleQuestionSubmit = async () => {

    try{
      const responseData = await mutateBackend("/mentoring/user/ask-question", {
        data: {
          ...getValues(),
          user_seq: user.user_seq,
          mento_seq: mento_seq,
          is_registered: true
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      const response = await responseData.json();

      if (localFiles.length > 0) await handleUpload(mento_seq);
  
      if (response.messageCode === 200) {
        toast.success("질문을 등록하였습니다.");
        navigate("/mentoring");
      }

    } catch (error) {
      toast.error("질문을 등록하는데 오류가 생겼습니다.");
    }
  }


  return (
    <>
      <div className="tab_box bg_box">
        - 게시글은 모두 공개이오니 내용에 개인정보가 포함되지 않도록 유의 해 주시기 바랍니다.<br />
        - 광고, 비방게시물은 관리자 권한으로 삭제 될 수 있습니다.<br />
        - Email, 연락처는 관리자만 볼 수 있으며 필요시 기재 바랍니다.
      </div>
      <ul className="com_input_sec">
        <li className="tit_field">
          제목 <i className="icon_must">*</i>
        </li>
        <li className="input_field">
          <input type="text" className="input" {...register("que_title", { required: "제목을 입력해주세요." })}/>
        </li>
        <li className="tit_field">
          내용 <i className="icon_must">*</i>
        </li>
        <li className="input_field">
          <textarea className="textarea" {...register("que_content", { required: "내용을 입력해주세요." })} />
        </li>
        <li className="tit_field">
          <strong>파일 첨부</strong> <span>(선택 , 5mb미만의 이미지 파일(.jpg,.png.gif)만 가능)</span>
        </li>
        <li className="com_attach_section">
          {localFiles?.length > 0 &&
            localFiles?.map((obj, i) => {
              const url = URL.createObjectURL(obj);
              return (
                <p className="img_box" key={i}>
                  <img src={url} className="img_upload" alt={obj.up_file_seq} />
                  <button
                    type="button"
                    className="btn_del"
                    onClick={() => setLocalFiles(localFiles?.filter((_, index) => index !== i))}
                  />
                </p>
              );
          })}
          <Upload title="파일선택" className="com_btn line m btn_upload" multiple setFiles={(newFiles) => setLocalFiles([...localFiles, ...newFiles])} files={localFiles}/>
        </li>
      </ul>

      <div className="com_btn_wrap center" onClick={handleQuestionSubmit}>
        <button className="com_btn blue m btn_answer">글올리기</button>
      </div>
    </>
  );
};

export const QuestionsList = ({ mento_seq }) => {

    const [questionList, setQuestionsList] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await queryBackend(`/mentoring/user/ask-question/${mento_seq}`);

          if (response.ok) {
            const jsonData = await response.json();
            setQuestionsList(jsonData.data);
          } else {
            console.error("에러 발생");
          }
        } catch (error) {
          console.error("에러 발생: ", error);
        }
      };
  
      fetchData();
    }, [mento_seq]);

  return (
    <>
      <table className="com_board_list">
        <colgroup>
          <col width="10%" />
          <col width="50%" />
          <col width="10%" />
          <col width="10%" />
        </colgroup>
        <thead>
          <tr>
            <th>번호</th>
            <th>제목</th>
            <th>작성자</th>
            <th>날짜</th>
          </tr>
        </thead>
        <tbody>
        {questionList?.map((question, index) => (
          <tr key={index}>
            <td>{questionList.length - index}</td>
            <td className="left">{question.que_title}</td>
            <td>{question.user_id}</td>
            <td>{moment(question.reg_dtm).format("YYYY-MM-DD")}</td>
          </tr>
        ))}
        </tbody>
      </table>
      <Pagination />
    </>
  );
};

export const Mentor = () => {
  return (
    <>
      <ContentReview />
      <ul className="com_input_sec">
        <li className="tit_field">
          내용 <i className="icon_must">*</i>
        </li>
        <li className="input_field">
          <textarea className="textarea" />
        </li>
        <li className="tit_field">
          <strong>파일 첨부</strong> <span>(선택 , 5mb미만의 이미지 파일(.jpg,.png.gif)만 가능)</span>
        </li>
        <li className="input_field">
          <Upload title="파일선택" className="com_btn line m btn_upload" />
        </li>
        <div className="com_btn_wrap center">
          <button className="com_btn blue m btn_answer">Answer</button>
        </div>
        <QuestionsList />
      </ul>
    </>
  );
};

export const Mentee = () => {
  return (
    <>
      <ContentReview />
      <ul className="com_input_sec">
        <li className="tit_field">
          내용 <i className="icon_must">*</i>
        </li>
        <li className="input_field">
          <textarea className="textarea" />
        </li>
        <li className="tit_field">
          <strong>파일 첨부</strong> <span>(선택 , 5mb미만의 이미지 파일(.jpg,.png.gif)만 가능)</span>
        </li>
        <li className="input_field">
          <Upload title="파일선택" className="com_btn line m btn_upload" />
        </li>
        <div className="com_btn_wrap center">
          <button className="com_btn blue m btn_answer">Write</button>
        </div>
        <QuestionsList />
      </ul>
    </>
  );
};

export const Member = () => {
  return (
    <>
      <ContentReview />
      <ul className="com_input_sec">
        <li className="tit_field">
          내용 <i className="icon_must">*</i>
        </li>
        <li className="input_field">
          <textarea className="textarea" />
        </li>
        <li className="tit_field">
          <strong>파일 첨부</strong> <span>(선택 , 5mb미만의 이미지 파일(.jpg,.png.gif)만 가능)</span>
        </li>
        <li className="input_field">
          <Upload title="파일선택" className="com_btn line m btn_upload" />
        </li>
        <div className="com_btn_wrap center">
          <button className="com_btn blue m btn_answer">Write</button>
        </div>
        <QuestionsList />
        <div className="com_btn_wrap center">
          <button className="com_btn blue m btn_answer">Make Payment</button>
        </div>
      </ul>
    </>
  );
};

export const ContentReview = () => {
  return (
    <>
      <div className="tab_box review">
        안녕하세요 . 멘토링 문의드립니다. <br />
        작성자 : 홍길도 / 작성일 :2023-06-29/조회수/고유번호:201 <br />
        첨부파일 : 20234523424.jpg <br /> <br />
        멘토링 관련해서 문의드립니다. 어떻게 신청을 해야 할지 좀더 상세 설명좀 부탁드려요.
      </div>
      <div className="com_btn_wrap center">
        <button className="com_btn line point m">목록</button>
        <button className="com_btn blue m">수정</button>
        <button className="com_btn gray m">삭제</button>
      </div>
    </>
  );
};
