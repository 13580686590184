import React, { useState } from "react";
import Modal from "../modal";
import { useCustomQuery } from "../../../../../hooks/useAPI";

function PopAddUrl2(props) {
  const { open, close, handleAttach, fields } = props;
  const [selectedAttachments, setSelectedAttachments] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleAttachments = (e) => {
    e.preventDefault();
    const selectedAttachmentsFiltered = selectedAttachments.map(
      ({ link_type, link_title, link_val, link_seq, file_real_name }) => ({
        link_type,
        link_cate : "",
        link_title,
        link_val,
        link_seq,
        file_real_name
      }),
    );
    console.log("<====> ", selectedAttachmentsFiltered);
    handleAttach(e, selectedAttachmentsFiltered);
    setSelectedAttachments([]);
    close();
  };

  const handleCheckboxChange = (attachment) => {
    setSelectedAttachments((prevSelected) => {
      if (prevSelected.includes(attachment)) {
        return prevSelected.filter((item) => item !== attachment);
      } else {
        return [...prevSelected, attachment];
      }
    });
  };

  const { data: attachments, isFetching: isFetchingAttachments } = useCustomQuery(`/resume-link`, {
    queryKey: ["getAttachments"],
    select: (res) => res?.data,
    refetchOnWindowFocus: false,
  });

  return (
    <Modal open={open} close={close}>
      <h2 className="pop_tit type_center">내 첨부파일</h2>
      <div className="content_container">
        <table className="com_board_list">
          <colgroup>
            <col width="10%" />
            <col width="25%" />
            <col width="45%" />
            <col width="25%" />
          </colgroup>
          <thead>
            <tr>
              <th className="chk_field">
                <input
                  type="checkbox"
                  className="com_chk"
                  checked={selectAll}
                  onChange={() => setSelectAll(!selectAll)}
                />
                <label></label>
              </th>

              <th>종류</th>
              <th>파일명</th>
              <th>지원일</th>
            </tr>
          </thead>
          <tbody>
            {isFetchingAttachments ? (
              <i className="gg-spinner-alt" />
            ) : attachments?.attachments?.length !== 0 ? (
              attachments?.attachments.map((attachment) => {
                console.log(attachment);
                // console.log("&***************&*&*&* ", fields, attachment, fields.some((field) => field.link_seq && field.link_seq === attachment.link_seq));
                if(fields.some((field) => field.link_seq && field.link_seq === attachment.link_seq)) return false;
                return (
                  <tr key={attachment.link_seq} className="q">
                    <td className="chk_field">
                      <input
                        type="checkbox"
                        className="com_chk"
                        id={attachment.link_seq}
                        checked={
                          selectedAttachments.includes(attachment) ||
                          fields.some((field) => field.link_seq && field.link_seq === attachment.link_seq)
                        }
                        onChange={() => handleCheckboxChange(attachment)}
                        disabled={fields.some((field) => field.link_seq && field.link_seq === attachment.link_seq)}
                      />
                      <label htmlFor={attachment.link_seq}></label>
                    </td>
                    <td className="state_field">{attachment.link_type === 'LINK' ? '링크' : '파일'}</td>
                    <td className="state_field">{attachment.link_type === 'LINK' ? attachment.link_val : attachment.file_real_name}</td>
                    <td className="state_field">{attachment.reg_dtm.split("T")[0]}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="4">데이터가 없습니다</td>
              </tr>
            )}
          </tbody>
        </table>
        <section className="com_btn_wrap center">
          <button className="com_btn blue s" onClick={handleAttachments}>
            파일첨부
          </button>
        </section>
      </div>
    </Modal>
  );
}

export default PopAddUrl2;
