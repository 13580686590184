/* eslint-disable */
import React from 'react';
import "../../assets/scss/page/etc.scss";
function Agree() {
  return (
    <>
      <main className='com_term'>
        <div className='com_center_wrap'>
          <h2 className='pg_tit'>개인회원 이용약관</h2>
          <div className='box'>
            <dl className='sec'>

              <dt className='tit'>제1조 목적</dt>
              <dd className='txt'>본 약관은 주식회사 씨이에스(이하 ‘회사’)가 엔지니어스 웹페이지(이하 ‘서비스’)를 통해 제공하는 엔지니어스 관련 제반 서비스를 이용함에 있어, 사이트와 회원 간의 이용 조건 및 제반 절차, 기타 필요한 사항을 규정함을 목적으로 한다.</dd>

              <dt className='tit'>제2조 용어의 정의</dt>
              <dd className='txt'>이 약관에서 사용하는 용어의 정의는 아래와 같다.</dd>
              <dd className='txt'>① “사이트”라 함은 “회사”가 서비스를 “회원”에게 제공하기 위하여 컴퓨터 등 정보 통신 설비를 이용하여 설정한 가상의 영업장 또는 “회사”가 운영하는 아래 웹사이트를 말한다.
                <br/>가. en-genius.co.kr</dd>
              <dd className='txt'>② “서비스”라 함은 “회사”가 “사이트”를 통해 개인이 등록한 자료를 DB화하여 각각의 목적에 맞게 분류, 가공, 집계하여 정보를 제공하는 서비스, 개인이 구직 등의 목적으로 등록한 자료를 제공받는 서비스, 기업에 관한 자료를 수집, 분류, 가공하여 정보를 제공하는 서비스 등 및 이들 서비스와 관련하여 각 “사이트”에서 제공하는 모든 부대/제휴 서비스를 총칭한다.</dd>
              <dd className='txt'>③ “회원”이라 함은 서비스를 이용하기 위하여 동 약관에 동의하고 페이스북 등 SNS와 연동된 서비스를 통해 “회사”와 이용 계약을 체결한 개인을 말한다.</dd>
              <dd className='txt'>④ “아이디”라 함은 회원의 식별과 회원의 서비스 이용을 위하여 “회원”이 가입 시 사용한 이메일 주소를 말한다.</dd>
              <dd className='txt'>⑤ “비회원”이라 함은 “회원”에 가입하지 않고 “회사”가 제공하는 서비스를 이용하는 자를 말한다.</dd>
              <dd className='txt'>⑥ “콘텐츠”라 함은 “회원”이 등록한 개인정보 및 이력서와 사이트에 게시한 게시물을 말한다.</dd>
              <dd className='txt'>⑦ “커뮤니티”라 함은 “사이트”에 가입한 “회원”이 자신의 정보를 공개 및 공유하고, 다른 “회원”과 의사, 생각, 지식 등을 표현 및 소통할 수 있도록 “회사”가 “사이트” 내에 부가적으로 만든 인터넷 게시판을 의미한다.</dd>
              <dd className='txt'>⑧ “포인트”라 함은 서비스 이용 빈도 및 참여도에 따라 포인트를 지급할 수 있고, 회원이 지급받은 포인트를 교환할 수 있는 특전/편익을 수시로 설정하여 회사가 적절하다고 판단하는 방법으로 회원에게 제공하는 현금성 재화를 의미한다.</dd>

              <dt className='tit'>제3조 약관의 명시와 개정</dt>
              <dd className='txt'>① “회사”는 이 약관의 내용과 상호, 영업소 소재지, 대표자의 성명, 사업자등록번호, 연락처 등을 “회원”이 알 수 있도록 초기 화면에 게시하거나 기타의 방법으로 “회원”에게 공지해야 한다.</dd>
              <dd className='txt'>② “회사”는 약관의규제등에관한법류, 전기통신기본법, 전기통신사업법, 정보통신망이용촉진등에관한법류, 개인정보보호법 등 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있다.</dd>
              <dd className='txt'>③ “회사”가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 그 개정약관의 적용일자 7일 전부터 적용일자 전일까지 공지한다. 단 “회원”의 권리, 의무에 중대한 영향을 주는 변경의 경우에는 적용일자 30일 전부터 공지하도록 한다.</dd>
              <dd className='txt'>④ “회원”은 변경된 약관에 대해 거부할 권리가 있다. “회원”은 변경된 약관이 공지된 지 15일 이내에 거부의사를 표명할 수 있다. “회원”이 거부하는 경우 본 서비스 제공자인 “회사”는 15일의 기간을 정하여 “회원”에게 사전 통지 후 당해 “회원”과의 계약을 해지할 수 있다. 만약, “회원”이 거부의사를 표시하지 않거나, 전항에 따라 시행일 이후에 서비스를 이용하는 경우에는 동의한 것으로 간주한다.</dd>
              <dd className='txt'>⑤ “회사”는 “회원”이 가입 신청 시 등록한 이메일, 휴대전화 문자메시지 중 “회원”이 수신에 동의한 수단을 활용하여, “회원”에 대한 각종 고지나 통지를 이행할 수 있다. 단, “회원”에게 서비스 이용에 대한 정보를 고지, 통지 및 공지가 필요할 경우 수신에 동의한 이외의 수단으로 이행할 수 있다.</dd>
              <dd className='txt'>⑥ ‘회사’가 필요하다고 판단한 경우 ‘회사’와 ‘회원’은 별도의 서면 계약서를 체결할 수 있으며, 본 약관과 서면 계약서의 내용이 다른 경우 서면 계약서의 내용을 따른다.</dd>
              <dd className='txt'>⑦ 본 약관에서 정하지 아니한 사항과 약관의 해석에 관하여는 정부가 재정한 전자거래소비자보호지침 및 관계법령 또는 일반 상관례에 따른다.</dd>

              <dt className='tit'>제4조 약관의 해석</dt>
              <dd className='txt'>① 이 약관에서 규정하지 않은 사항에 관해서는 약관의규제에관한법률, 전기통신기본법, 전기통신사업법, 정보통신망이용촉진등에관한법률 등의 관계법령에 따른다.</dd>
              <dd className='txt'>② “회원”이 “회사”와 개별 계약을 체결하여 서비스를 이용하는 경우에는 개별 계약이 우선한다.</dd>

              <dt className='tit'>제5조 서비스 이용 신청 등</dt>
              <dd className='txt'>① “회사”는 “회사”의 서비스를 이용하고자 하는 자가 본 약관과 개인정보취급방침을 읽고 이에 대하여 “동의” 또는 “확인” 버튼을 누르는 경우 이를 서비스 이용에 대한 신청으로 간주한다.</dd>
              <dd className='txt'>② 제1항 신청에 있어 “회사”는 “회원”의 종류에 따라 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있다. “회원”은 본인인증에 필요한 이름, 생년월일, 연락처 등을 제공하여야 한다.</dd>
              <dd className='txt'>③ “회원”이 이용신청(회원가입 신청) 작성 후에 “회사”가 웹 상의 안내 및 전자메일로 “회원”에게 통지함으로써 서비스를 개시한다.</dd>
              <dd className='txt'>④ 페이스북 및 각종 SNS등 외부서비스와의 연동을 통해 이용을 신청해야 하며, 본 약관과 개인정보취급방침, 서비스 제공을 위해 “회사”가 “회원”의 외부 서비스 계정 정보 접근 및 활용에 “동의” 또는 “확인” 버튼을 누르면 “회사”가 웹 상의 안내 및 전자메일로 “회원”에게 통지함으로써 서비스를 개시한다.</dd>

              <dt className='tit'>제6조 이용신청의 승낙과 제한</dt>
              <dd className='txt'>① “회사”는 전조의 규정에 의한 이용신청 고객에 대하여 업무수행 상 또는 기술상 지장이 없는 경우에는 원칙적으로 접수 순서에 따라 서비스 이용을 승낙한다.</dd>
              <dd className='txt'>② “회사”는 아래 사항에 해당하는 경우에 대해서는 이용 신청을 승낙하지 아니하며, 추후 아래 사항에 해당함이 확인된 경우 “회사”는 이용계약을 해지한다.
                <br />가. 실명이 아니거나 타인의 명의를 이용하여 신청한 경우
                <br />나. 서비스 이용신청서의 내용을 허위로 기재한 경우
                <br />다. 만 15세 미만의 아동이 신청한 경우
              </dd>
              <dd className='txt'>③ “회사”는 아래 사항에 해당하는 경우에는 그 신청에 대하여 승낙 제한 사유가 해소될 때까지 승낙을 유보할 수 있다.
                <br />가. “회사”가 설비의 여유가 없는 경우
                <br />나. “회사”의 기술상 지장이 있는 경우
                <br />다. 기타 “회사”의 귀책사유로 이용승낙이 곤란한 경우
              </dd>

              <dt className='tit'>제7조 서비스의 내용</dt>
              <dd className='txt'>① “회사”는 제2조 2항의 서비스를 제공할 수 있으며 그 내용은 다음 각호와 같다.
                <br />가. 기업의 채용공고 등록과 조회 서비스
                <br />나. 기업 정보조회와 관련된 제반 서비스
                <br />다. 이용자 간의 교류와 소통에 관련한 서비스
                <br />라. 이력서 등록 및 인재정보 게재 서비스
                <br />마. 온라인 입사지원 서비스
                <br />바. 채용대행서비스(헤드헌팅) 서비스
                <br />사. 구인/구직과 관련된 제반 서비스
                <br />아. 커리어 개발 교육과 관련된 제반 서비스
                <br />자. 지식/자료 정보거래에 관련한 서비스
                <br />차. 기타 “회사”가 추가 개발하거나 제휴계약 등을 통해 “회원”에게 제공하는 일체의 서비스
              </dd>
              <dd className='txt'>② “회사”는 필요한 경우 서비스의 내용을 추가 또는 변경할 수 있다. 단, 이 경우 “회사”는 추가 또는 변경 내용을 “회원”에게 공지해야 한다.</dd>
              <dd className='txt'>③ “회사”는 “회원”에게 서비스를 제공함에 있어서 “회원” 또는 개인회원이 “사이트”에 등록하거나 제공한 각종 정보를 관련 법령에 따른 조치(예: 비식별처리 등)을 통하여 통계적 자료로 만들거나 DB화하여 활용할 수 있다.</dd>

              <dt className='tit'>제8조 서비스 이용시간</dt>
              <dd className='txt'>① “회사”는 특별한 사유가 없는 한 연중무휴, 1일 24시간 서비스를 제공한다. 다만, “회사”는 서비스(또는 “사이트”)의 종류나 성질에 따라 제공하는 서비스 중 일부에 대해서는 별도로 이용시간을 정할 수 있으며, 이 경우 “회사”는 그 이용시간을 사전에 “회원”에게 공지하여야 한다.</dd>
              <dd className='txt'>② “회사”는 자료의 가공과 갱신을 위한 시스템 작업시간, 장애해결을 위한 보수작업 시간, 회선 장애 등이 발생한 경우 일시적으로 서비스를 중단할 수 있으며 계획된 작업의 경우 공지사항 게시 등 적절한 수단으로 서비스 중단 시간과 작업 내용을 알려야 한다. 다만, “회사”가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후 통지할 수 있다.</dd>

              <dt className='tit'>제9조 회원 정보, 이력서 노출</dt>
              <dd className='txt'>① “회원”의 이력서는 개인이 회원가입 또는 이력서 작성 및 수정 시 희망한 형태로 이력서를 노출한다.</dd>
              <dd className='txt'>② “회사”는 “회원”이 회원정보, 이력서의 공개/비공개 지정, 이력서상의 연락처 공개/비공개를 자유롭게 선택할 수 있도록 하여야 한다.</dd>
              <dd className='txt'>③ “회사”는 “회원”이 이력서 공개를 희망했을 경우, 기업회원의 이력서 열람에 동의한 것으로 간주하며 “회사”는 이들 기업회원에게 무료/유료로 이력서 열람 서비스를 제공할 수 있다. 다만, 연락처 각 항목이 비공개로 지정된 경우 해당 항목별 연락처를 노출할 수 없다.</dd>
              <dd className='txt'>④ “회사”는 안정적인 서비스를 제공하기 위해 테스트 및 모니터링 용도로 각 “사이트”의 운영자가 이력서 정보를 열람하도록 할 수 있다.</dd>
              <dd className='txt'>⑤ “사이트”에서 정당한 절차를 거쳐 기업회원이 열람한 “회원”의 이력서 등 정보는 해당 기업회원이 관련 법령에 따라서 보호할 책임을 부담하며, “회사”는 그에 대한 책임을 부담하지 않는다.</dd>
              <dd className='txt'>⑥ “커뮤니티”를 이용하고자 하는 “회원”은 다른 “회원”에게 “사이트” 가입 시 등록한 본인의 이름이 노출될 수 있음을 동의한 것으로 간주한다.</dd>

              <dt className='tit'>제10조 제휴를 통한 서비스</dt>
              <dd className='txt'>① “회사”는 제휴 관계자를 체결한 여타 인터넷 웹 사이트 및 채용박람회 또는 신문, 잡지 등의 오프라인 매체를 통해 사이트에 등록한 “회원”의 이력서 정보가 열람될 수 있도록 서비스를 제공할 수 있다. 단, 제휴 서비스를 통해 노출되는 이력서, 연락처 등 정보(이하 ‘정보’)에 대해서는 제9조의 각 항에 따른다.</dd>
              <dd className='txt'>② “회사”는 제휴를 통해 타 사이트 및 매체에 등록될 수 있음을 고지해야 하며, 제휴 사이트 목록을 사이트 내에서 상시 열람할 수 있도록 해야 한다. 단, 등록의 형태가 “회사”가 직접 구축하지 않고, 제휴사가 CSV 또는 DB 형태로 “회사”로부터 제공받아 구축한 매체 목록은 본 약관 외 별도의 제휴리스트에서 열람할 수 있도록 한다.</dd>
              <dd className='txt'>③ “회사”는 사이트와 제휴를 통해 “회원”이 공개에 동의한 정보에 한해 제1항에 따라 정보를 제공한다.</dd>
              <dd className='txt'>④ 본조 제3호 제휴를 통한 사이트의 변동사항 발생시 공지사항을 통해 고지 후 진행한다.</dd>

              <dt className='tit'>제11조 서비스의 요금</dt>
              <dd className='txt'>① “회원”의 서비스 가입과 이력서 등록은 무료이다. 다만 기업회원 또는 사이트에 방문한 기업체에게 자신의 이력서 정보를 보다 효과적으로 노출시키기 위한 유료서비스 및 인성, 적성 검사 등 회원가입 목적 외 기타 서비스를 이용하기 위한 별도의 서비스는 유료로 제공될 수 있다.</dd>
              <dd className='txt'>② “회사”는 유료서비스를 제공할 경우 관련 “사이트”에 요금에 대해서 공지를 하여야 한다.</dd>
              <dd className='txt'>③ “회사”는 유료서비스 이용금액을 서비스의 종류 및 기간에 따라 “회사”가 예고 없이 변경할 수 있다.</dd>
              <dd className='txt'>④ 유료 서비스 신청 후 “회원” 사정에 의해 서비스가 취소될 경우, 정부가 고시하는 ‘디지털콘텐츠 이용자보호지침’에 따라 “회사”는 본 지침이 제시하는 범위 내에서 환불 수수료를 부과할 수 있다.</dd>

              <dt className='tit'>제12조 포인트 규정</dt>
              <dd className='txt'>① 포인트는 유료상품 구매시의 사은, 이벤트, 고객보상 등의 사유로 적립된다. ‘적립된 포인트’는 유료상품 결제시 사용할 수 있으며, 현금으로 출금되지 않는다.</dd>
              <dd className='txt'>② 위의 적립사유가 해제되었을 경우, ‘적림된 포인트’를 회원보유분에서 회수하며, 결제시 사용한 서비스를 취소할 경우, 사용분에 해당하는 포인트를 제외하고 사용 이전의 상태로 재적립 받을 수 있다.</dd>
              <dd className='txt'>③ 서비스 운영 정책에 따라 포인트의 상품별 적립기준, 적립비율, 사용조건 및 제한 등은 달라질 수 있으며, 이에 대한 사항은 서비스 화면에 게시하거나 통지한다. 포인트 적립 시 별도의 유효기간을 정한 바가 없으면 적립일로부터 3개월의 유효기간을 보장한다.</dd>
              <dd className='txt'>④ 포인트의 적립시점과 무관하게 유효기간이 지나거나, “회원”이 12개월 동안 사이트에 로그인 하지 않을 경우, “회원”이 탈퇴하거나 서비스가 중지된 경우 포인트는 전액 자동 소멸되어 복구되지 않는다.</dd>

              <dt className='tit'>제13조 서비스 제공의 중지</dt>
              <dd className='txt'>“회사”는 다음 각호에 해당하는 경우 서비스의 제공을 중지할 수 있다.</dd>
              <dd className='txt'>① 설비의 보수 등 “회사”의 필요에 의해 사전에 “회원”들에게 통지한 경우</dd>
              <dd className='txt'>② 기간통신사업자가 전기통신서비스 제공을 중지하는 경우</dd>
              <dd className='txt'>③ 기타 불가항력적인 사유에 의해 서비스 제공이 객관적으로 불가능한 경우</dd>

              <dt className='tit'>제14조 정보의 제공 및 광고의 게재</dt>
              <dd className='txt'>① “회사”는 “회원”에게 서비스 이용에 필요가 있다고 인정되거나 서비스 개선 및 “회원” 대상의 서비스 소개 등의 목적으로 하는 각종 정보에 대해서 전자우편이나 앱푸쉬 알림, 모바일 장치 등 신종 기술 및 기기를 이용한 방법으로 제공할 수 있다.</dd>
              <dd className='txt'>② “회사”는 제공하는 서비스와 관련되는 정보 또는 광고를 서비스 화면, 홈페이지 등에 게재할 수 있으며, 광고가 게재된 전자우편을 수신한 “회원”은 수신거절을 “회사”에게 할 수 있다.</dd>
              <dd className='txt'>③ “회사”는 서비스상에 게재되어 있거나 본 서비스를 통한 광고주의 판촉활동에 “회원”이 참여하거나 교신 또는 거래를 함으로써 발생하는 모든 손실과 손해에 대해 책임을 지지 않는다.</dd>
              <dd className='txt'>④ 본 서비스의 “회원”은 서비스 이용 시 노출되는 광고게재에 대해 동의하는 것으로 간주한다.</dd>

              <dt className='tit'>제15조 콘텐츠의 책임과 회사의 정보 수정 권한</dt>
              <dd className='txt'>① “회원”은 콘텐츠를 사실에 근거하여 성실하게 작성해야 하며, “회원” 본인이 작성하는 것을 원칙으로 한다.</dd>
              <dd className='txt'>② “회사”는 “회원”이 작성한 콘텐츠를 본 약관의 규정 및 “회사”의 내부 규정에 따라 검토하여 문제가 없을 경우 “사이트”에 게시하는 방식의 필터링 작업을 수행할 수 있으며, 법령 위반 등의 문제가 있다고 판단할 경우 “회원”에게 사전 통보 없이 “사이트”에 게시하지 않는다.</dd>
              <dd className='txt'>③ 콘텐츠 내용에 오자, 탈자 또는 사회적 통념에 어긋나는 문구와 내용, 명백하게 허위의 사실에 기초한 내용이 있을 경우 “회사”는 이를 언제든지 삭제하거나 수정할 수 있다.</dd>
              <dd className='txt'>④ “회원”이 등록한 자료로 인해 타인(또는 타법인)으로부터 허위사실 및 명예훼손 등으로 삭제 요청이 접수된 경우 “회사”는 “회원”에게 사전 통지 없이 본 자료를 삭제 혹은 비노출 처리할 수 있으며, 삭제 혹은 비노출 처리 후 메일 등의 방법으로 통지할 수 있다.</dd>
              <dd className='txt'>⑤ “회사”는 “회원”이 작성한 “콘텐츠”에 허위사실, 사회적 통념에 어긋나는 내용, 특정 기업 또는 인물에 대한 명예훼손 또는 비방, 사칭, 욕설 및 비속어, 영리 목적의 광고, 음란성을 띄는 내용, 지적재산권 침해, 개인정보 노출, 현행 법률 및 본 약관에 위배되는 내용이 있을 경우 이를 사전 통지 없이 언제든지 삭제할 수 있으며, 관련 법령에 따른 책임을 물을 수 있다.</dd>
              <dd className='txt'>⑥ “회사”는 “회원”의 탈퇴를 이유로 “콘텐츠”를 삭제하지 않으며, “회원”이 서비스 탈퇴 시 삭제되기를 원할 경우 직접 삭제해야 한다.</dd>

              <dt className='tit'>제16조 콘텐츠의 권한 및 활용</dt>
              <dd className='txt'>① “회원”이 각 “사이트”에 입력한 콘텐츠는 부분적으로 익명으로 공개될 수 있으며, 모든 콘텐츠는 게시와 동시에 “회사”가 사용 권리를 획득하게 된다.</dd>
              <dd className='txt'>② “회원”이 각 “사이트”에 입력한 콘텐츠는 취업 및 관련 동향의 통계자료로 활용될 수 있고 그 자료는 매체를 통해 언론에 배포될 수 있다. 단, “회사”는 “회원”의 개인정보를 관련 법령에 따라서 보호한다.</dd>
              <dd className='txt'>③ “회원”이 작성, 편집 또는 업로드한 “콘텐츠”가 제3자의 지적재산권 등 권리를 침해하는 경우, “회원”은 직접 그에 대한 책임을 부담한다.</dd>
              <dd className='txt'>④ “회원”이 작성한 “콘텐츠”는 “회사”가 서비스 홍보 목적으로 활용할 수 있다.</dd>

              <dt className='tit'>제17조 회사의 의무</dt>
              <dd className='txt'>① “회사”는 본 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를 제공할 수 있도록 최선의 노력을 다해야 한다.</dd>
              <dd className='txt'>② “회사”는 서비스와 관련한 “회원”의 불만사항이 접수되는 경우 이를 즉시 처리하여야 하며, 즉시 처리가 곤란한 경우에는 그 사유와 처리일정을 서비스 화면 또는 기타 방법을 통해 동 “회원”에게 통지하여야 한다.</dd>
              <dd className='txt'>③ 천재지변 등 예측하지 못한 일이 발생하거나 시스템의 장애가 발생하여 서비스가 중단될 경우 이에 대한 손해에 대해서는 “회사”가 책임을 지지 않는다. 다만 자료의 복구나 정상적인 서비스 지원이 되도록 최선을 다할 의무를 진다.</dd>
              <dd className='txt'>④ “회사”는 “회원”의 유료 결제와 관련한 결제 사항 정보 등을 관련 법령에 따라서 1년 이상 보존한다.</dd>

              <dt className='tit'>제18조 회원의 의무</dt>
              <dd className='txt'>① “회원”은 관계법령과 본 약관의 규정 및 기타 “회사”가 통지하는 사항을 준수하여야 하며, 기타 “회사”의 업무에 방해되는 행위를 해서는 안 된다.</dd>
              <dd className='txt'>② “회원”은 서비스를 이용하여 얻은 정보를 “회사”의 사전 동의 없이 복사, 복제, 번역, 출판, 방송 기타 방법으로 상업적으로 사용하거나 제3자에게 제공할 수 없다.</dd>
              <dd className='txt'>③ “회원”은 본 서비스를 기업정보 확인 및 건전한 취업이외의 목적으로 사용해서는 안 되며 이용중 다음 각 호의 행위를 해서는 안 된다.
                <br />가. 다른 회원의 아이디를 부정 사용하는 행위
                <br />나. 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위
                <br />다. 타인의 명예를 훼손하거나 모욕하는 행위
                <br />라. 타인의 지적재산권 등의 권리를 침해하는 행위
                <br />마. 해킹행위 또는 바이러스의 유포 행위
                <br />바. 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 계속적으로 전송하는 행위
                <br />사. 서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있다고 판단되는 행위
                <br />아. 사이트의 정보 및 서비스를 이용한 영리행위
                <br />자. 그 밖에 선량한 풍속, 기타 사회질서를 해하거나 관계법령에 위반하는 행위
              </dd>
              <dd className='txt'>④ “회원”이 신청한 유료서비스는 등록 또는 신청과 동시에 “회사”와 사이에 채권채무 관계가 발생하며, “회원”은 이에 대한 요금 등 대가를 지정한 기일 내에 납부하여야 한다.</dd>
              <dd className='txt'>⑤ “회원”이 결제 수단으로 신용카드를 사용할 경우 비밀번호 등 정보 유실 방지는 “회원” 스스로 관리해야 한다. 단, “사이트”의 결함에 따른 정보유실의 발생에 대한 책임은 “회원”의 의무에 해당하지 아니한다.</dd>
              <dd className='txt'>⑥ “회사”는 “회원”이 자동화된 수단을 활용하는 등 서비스의 기능을 비정상적으로 이용하여 게재한 게시물 또는 서비스의 제공 취지와 부합하지 않는 내용의 게시물을 삭제하는 등의 방법으로 제한할 수 있다.</dd>
              <dd className='txt'>⑦ “회원”은 “회사”의 사전 허락 없이 자동화된 수단(예: 매크로 프로그램, 로봇(봇), 스파이더, 스크래퍼 등)을 이용하여 서비스 회원으로 가입을 시도 또는 가입하거나, 서비스에 로그인을 시도 또는 로그인하거나, 각 “사이트”에 게시물을 게재하거나, 서비스에 게재된 다른 회원의 아이디(ID), 게시물 등을 수집하거나, 서비스의 제공 취지에 부합하지 않는 방식으로 서비스를 이용하는 어뷰징(남용) 행위를 막기 위한 “회사”의 기술적 조치를 무력화하는 일체의 행위(예: IP를 지속적으로 바꿔가며 접속하는 행위, Captcha를 외부 솔루션 등을 통해 우회하거나 무력화하는 행위 등)를 시도하지 않아야 한다.</dd>
              <dd className='txt'>⑧ “회원”은 “회사”의 동의 없이 자동화된 수단에 의해 서비스 상에 광고가 게재되는 영역 또는 그 밖의 영역에 부호, 문자, 음성, 음향, 그림, 사진, 동영상, 링크 등으로 구성된 각종 콘텐츠 자체 또는 파일을 삽입해서는 안 되고, 서비스 또는 이에 포함된 소프트웨어를 복사, 수정할 수 없으며, 이를 판매, 양도, 대여 또는 담보로 제공하거나 타인에게 그 이용을 허락하지 않아야 한다. “회원”이 서비스에 포함된 소프트웨어를 역설계, 소스코드 추출 시도, 복제, 분해, 모방, 기타 변형하는 등의 행위도 금지되나, 오픈소스에 해당되는 경우 그 자체의 조건에 따른다. “회원”은 그 밖에 바이러스나 기타 악성 코드를 업로드하거나 서비스의 원활한 운영을 방해할 목적으로 서비스 기능을 비정상적으로 이용하지 않아야 한다.</dd>

              <dt className='tit'>제19조 회원탈퇴 및 회원자격 상실</dt>
              <dd className="txt">① 회원은 회사에 언제든지 탈퇴를 요청할 수 있으며, 탈퇴 요청은 엔지니어스 서비스 내 기능 혹은 hr-support@en-genius.co.kr을 통해 할 수 있다.</dd>
              <dd className='txt'>② 회원이 회원탈퇴를 하는 경우 탈퇴 이전에 작성한 게시물은 이 약관 제16조 제6항에 근거하여 삭제되지 않는다.</dd>
              <dd className='txt'>③ 회사는 회원이 이 약관 및 개별서비스 이용약관을 위반한 경우 경고, 일시적 이용정지, 영구적 이용정지 등의 단계로 서비스 이용을 제한하거나 이용계약을 해지할 수 있다.</dd>
              <dd className='txt'>④ 회원은 제3항에 따른 서비스 이용정지 기타 서비스이용과 관련된 이용제한에 대한 회사가 정한 절차에 따라 이의신청을 할 수 있으며, 회사는 회원의 이의신청이 정당하다고 판단되는 경우 즉시 서비스 이용을 재개한다.</dd>

              <dt className='tit'>제20조 손해배상</dt>
              <dd className='txt'>① “회사”가 “회원”에게 손해를 입히거나 기타 “회사”가 제공하는 모든 서비스와 관련하여 “회사”의 책임 있는 사유로 인해 “회원”에게 손해가 발생한 경우 “회사”는 그 손해를 배상하여야 한다.</dd>
              <dd className='txt'>② “회원”이 약관의 규정에 위반한 행위로 “회사” 및 제3자에게 손해를 입히거나 “회원”의 책임 있는 사유에 의해 “회사” 및 제3자에게 손해를 입힌 경우에는 “회원”은 구 손해를 배상하여야 한다.</dd>
              <dd className='txt'>③ 타 회원(개인회원, 기업회원 모두 포함)의 귀책사유로 “회원”의 손해가 발생한 경우 “회사”는 이에 대한 배상 책임이 없다.</dd>

              <dt className='tit'>제21조 회원의 개인정보보호</dt>
              <dd className='txt'>“회사”는 “회원”의 개인정보보호를 위하여 노력해야 한다. “회원”의 개인정보보호에 관해서는 정보통신망이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법 등 관련 법령과 “회사”가 “사이트”에 게시하는 개인정보취급방침에 따른다.</dd>

              <dt className='tit'>제22조 이용요금 오류의 조정</dt>
              <dd className='txt'>“회사”는 이용요금과 관련하여 오류가 있는 경우에 “회원”의 요청, 또는 “회사”의 사전 통지에 의하여 다음에 해당하는 조치를 취한다.
              <br />① 과다 납입한 요금에 대하여는 그 금액을 반환한다. 다만, “회원”이 동의할 경우 다음 달에 청구할 요금에서 해당 금액만큼 감하여 청구한다.
              <br />② 요금을 반환 받아야 할 “회원”이 요금체납이 있는 경우에는 반환해야 할 요금에서 이를 우선 공제하고 반환한다.
              <br />③ “회사”는 과소청구액에 대해서는 익월에 합산 청구한다.</dd>

              <dt className='tit'>제23조 신용정보의 제공 활용 동의</dt>
              <dd className='txt'>① “회사”가 회원가입과 관련하여 취득한 “회원”의 개인신용정보를 타인에게 제공하거나 활용하고자 할 때에는 신용정보의 이용 및 보호에 관한 법률 제23조의 규정에 따라 사전에 그 사유 및 해당 기관 또는 업체명 등을 밝히고 해당 “회원”의 동의를 얻어야 한다.</dd>
              <dd className='txt'>② 본 서비스와 관련하여 “회사”가 “회원”으로부터 신용정보의 이용 및 보호에 관한 법률에 따라 신용정보의 제3자 제공에 대한 동의를 얻은 경우, “회사”는 신용정보 사업자 또는 신용정보 집중기관에 해당 신용정보를 제공하여 “회원”의 신용을 판단하기 위한 자료로 활용하거나, 공공기관에서 정책자료로 활용되도록 정보를 제공할 수 있다.</dd>

              <dt className='tit'>제24조 분쟁의 해결</dt>
              <dd className='txt'>① “회사”와 “회원”은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 하여야 한다.</dd>
              <dd className='txt'>② 전항의 노력에도 불구하고, 동 분쟁에 관한 소송발생시 소송은 “회사”의 주소지 관할법원을 전속적 관할로 정한다.</dd>

              <dt className='tit'>부칙</dt>
              <dd className='txt'>본 약관은 2023년 11월 01일부터 시행한다.</dd>
            </dl>
          </div>
        </div>
      </main>
    </>
  );
}

export default Agree;
