import React, { useContext, useMemo } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { mutateBackend } from "../../../../../api";
import { AuthContext } from "../../../../../context/Auth";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import "../../assets/scss/page/main.scss";
import Pagination from "../../components/Pagination";
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";

const checkNullNum = (val, num) => (val ? val : num);
const checkNullString = (val) => (val ? val : "");

function JobList() {
  const [searchParams, setSearchParams] = useSearchParams({ page: 1, limit: 12 });
  const page = +checkNullNum(searchParams.get("page"), 1);
  const limit = +checkNullNum(searchParams.get("limit"), 12);
  const recruit_location = checkNullString(searchParams.get("recruit_location"));
  const recruit_job = checkNullString(searchParams.get("recruit_job"));
  const recruit_categories = checkNullString(searchParams.get("recruit_categories"));

  const { user } = useContext(AuthContext);

  const { data: { data_list: jobList, total_page } = { data_list: [], total_page: 0 }, refetch: refetchJobList } =
    useCustomQuery(
      `/recruit/list?recruit_location=${recruit_location}&recruit_job=${recruit_job}&recruit_categories=${recruit_categories}&curr_page=${page}&per_page=${limit}`,
      {
        queryKey: ["getRecruitList", recruit_location, recruit_job, recruit_categories, page, limit],
        select: (res) => res?.data ?? { data_list: [], total_page: 0 },
      },
    );

  const { data: jobCategoryData } = useCustomQuery(`/category?cate_gr=RECU_JOB`, {
    queryKey: ["selectJobCate"],
    select: (res) => res?.data?.categories,
  });

  const { data: locationTypeData } = useCustomQuery(`/comm/list?code_gr=LOCA_CD`, {
    queryKey: ["selectLocationTypeCate"],
    select: (res) => res?.data?.dataList,
  });

  const parentCategories = useMemo(
    () => jobCategoryData?.filter((category) => category.parent_seq === 0) || [],
    [jobCategoryData],
  );

  const childCategories = useMemo(() => {
    const parent_seq = jobCategoryData?.find((category) => category.cate_name === recruit_job)?.cate_seq || -1;
    return parent_seq !== -1 ? jobCategoryData?.filter((category) => category.parent_seq === parent_seq) : [];
  }, [recruit_job, jobCategoryData]);

  const handleLike = async (obj) => {
    const data = {
      recruit_seq: obj?.recruit_seq,
    };
    const response = await mutateBackend(`/recruit${obj?.likedBy?.includes(user?.user_seq) ? "/unlike" : "/like"}`, {
      data,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (data) => await data.json())
      .catch((err) => {
        throw new Error(err);
      });
    if (response.messageString === "SUCCESS" && response.messageCode === 200) {
      console.log(response.data);
      refetchJobList();
    } else {
      console.log(response);
    }
  };

  return (
    <main className="p_main">
      {/* long banner */}
      <section className="sec_banner">
        <div className="com_center_wrap"></div>
      </section>
      <section className="sec_job com_bg">
        <div className="com_center_wrap">
          <div className="job_wrap">
            <div className="pg_tit_wrap">
              <h2 className="com_pg_tit">진행중인 공고</h2>
              <div className="select_wrap">
                <select
                  className="select"
                  title="지역"
                  value={recruit_location || ""}
                  onChange={(e) =>
                    setSearchParams((prev) => {
                      prev.set("page", 1);
                      prev.set("recruit_location", e.target.value);
                      return prev;
                    })
                  }>
                  <option value={""}>All</option>
                  {locationTypeData?.map((location) => (
                    <option key={location.comm_code} value={location.comm_code}>
                      {location.code_nm_kr}
                    </option>
                  ))}
                </select>
                <select
                  className="select"
                  title="직무"
                  value={recruit_job || ""}
                  onChange={(e) =>
                    setSearchParams((prev) => {
                      prev.set("page", 1);
                      prev.set("recruit_job", e.target.value);
                      prev.set("recruit_categories", "");
                      return prev;
                    })
                  }>
                  <option value={""}>All</option>
                  {parentCategories?.map((category) => (
                    <option key={category.cate_seq} value={category.cate_name}>
                      {category.cate_name}
                    </option>
                  ))}
                </select>
                <select
                  className="select"
                  title="상세 직무"
                  value={recruit_categories || ""}
                  onChange={(e) =>
                    setSearchParams((prev) => {
                      prev.set("page", 1);
                      prev.set("recruit_categories", e.target.value);
                      return prev;
                    })
                  }>
                  <option value={""}>All</option>
                  {childCategories?.map((category) => (
                    <option key={category.cate_seq} value={category.cate_name}>
                      {category.cate_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <ul className="list">
              {jobList?.map((item, index) => (
                <li className="com_job_item" key={index}>
                  <p className="tit">{item?.recruit_title}</p>
                  <div className="info">
                    <p className="info_tit">
                      {item?.newcomer_YN === "Y" ? "신입" : (item && `경력 ${item?.career_min} ~ ${item?.career_max}년`)}
                    </p>
                    <p className="info_tit">
                        등록일 : <span className="date">{new Date(item?.open_date).toLocaleDateString()}</span>{" "}
                    </p>
                    <p className="info_tit">
                      마감일 : {item?.close_type === "ANY" ? "상시 채용" : item?.close_type === "UNTI" ? "채용 시 마감" : (new Date(item?.close_date).toLocaleDateString() === "1899-11-29" ? "-" : new Date(item?.close_date).toLocaleDateString())}
                    </p>
                    <p className="info_tit">
                      근무지 : <span>{locationTypeData?.find((location) => location.comm_code == item?.recruit_location)?.code_nm_kr}</span>
                    </p>
                    
                    <p className="info_s">
                      {item?.comp_name} {item?.emp_type_name &&<span className="icon">{item?.emp_type_name}</span>}
                    </p>
                    <ul className="com_tag_list">
                      {item?.recruit_tags &&
                        item?.recruit_tags?.split(",")?.map((tag, i) => (
                          <li key={i} className="com_btn xs">
                            #{tag}
                          </li>
                        ))}
                    </ul>
                  </div>
                  {/* 2023-11-22 MatchList 에서 분리
                  <Link to={`/match?recruit_seq=${item?.recruit_seq}`} className="btn_more" />
                  */}
                  <Link to={`/match/Detail/${item?.recruit_seq}`} className="btn_more" />
                  <button
                    className={`btn_like ${item?.likedBy?.includes(user?.user_seq) ? "active" : ""}`}
                    onClick={() => handleLike(item)}
                  />
                  {/* 아이콘 active 시 active class 추가 */}
                  {item?.recruit_status === "CLOSED" && (
                    <div className="com_overlay type_circle">
                      <div className="bg"></div>
                      <div className="alert_box">
                        <span className="txt">마감된 공고</span>
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <Pagination currentPage={page} perPage={limit} totalPages={total_page} setSearchParams={setSearchParams} />
        </div>
      </section>

      {/* top button */}
      <ScrollToTopButton />
    </main>
  );
}

export default JobList;
