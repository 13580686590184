import React, { useContext, useState } from "react";
import "../../assets/scss/page/mypage.scss";
import Modal from "../../components/modal";
import { Rating } from "../mentoring/_Rating";
import { toast } from "react-toastify";
import { mutateBackend } from "../../../../../api";
import { AuthContext } from "../../../../../context/Auth";

function ReviewWrite(props) {
  const { open, close, mento_seq } = props;

  const { user } = useContext(AuthContext);

  const [rating, setRating] = useState();
  const [reviewContent, setReviewContent] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);

  async function handleSubmitReview() {
    setIsSubmitting(true);

    const response = await mutateBackend("/mentoring/user/review", {
      data: {
        mento_seq: mento_seq,
        user_seq: user.user_seq,
        rate_score: rating,
        review_contents: reviewContent,
      },
      headers: { "Content-Type": "application/json" },
    }).then(async (data) => await data.json());

    if (response.messageCode === 200) {
      toast.success("성공적으로 검토를 제출했습니다.");
    } else if (response.messageCode === 409) toast.error("하나의 리뷰 만 제출할 수 있습니다.");
    else toast.error("검토를 제출하지 못했습니다.");

    setIsSubmitting(false);

    close();
  }

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        await handleSubmitReview();
      }}>
      <Modal open={open} close={close} popSize="s" type="pop_review">
        <section className="pop_tit">후기 작성</section>
        <Rating handleRate={(rating) => setRating(rating)} />
        <div className="content_container scroll">
          <textarea
            placeholder="후기를 200자 이내로 작성해주세요."
            required
            onChange={(e) => setReviewContent(e.target.value)}
          />
        </div>
        <section className="btn_container">
          <button className="com_btn blue s" type="submit">
            후기 작성 {isSubmitting && <i className="gg-spinner-alt" />}
          </button>
          <button className="com_btn line blue s" type="button" onClick={close}>
            취소
          </button>
        </section>
      </Modal>
    </form>
  );
}

export default ReviewWrite;
