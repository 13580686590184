import React, { useState } from "react";
//component
import Pagination from "../../../components/Pagination.js";
import { useCustomQuery } from "../../../../../../hooks/useAPI.js";
import moment from "moment";
import { Link } from "react-router-dom";
function MatchUnread() {
  const [applicationPerPage, setApplicationPerPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);

  const [totalUnreadCount, setTotalUnreadCount] = useState(0);
  const [unreadList, setUnreadList] = useState([]);

  useCustomQuery(
    `/application/list?per_page=${applicationPerPage}&curr_page=${currentPage}&appli_status=SS10&user_type=PSN`,
    {
      queryKey: ["getApplicationList", applicationPerPage, currentPage],
      select: (res) => res?.data,
      onSuccess: (data) => {
        // console.log(data);
        setTotalUnreadCount(data.total_cnt);
        setUnreadList(data.data_list);
      },
      refetchOnWindowFocus: false,
    },
  );

  const handleNext = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setCurrentPage((prev) => prev - 1);
  };

  return (
    <div>
      <table className="com_board_list">
        <colgroup>
          <col width="17%" />
          <col />
          <col width="10%" />
          <col width="10%" />
          <col width="25%" />
        </colgroup>
        <thead>
          <tr>
            <th>지원 회사</th>
            <th>지원 공고</th>
            <th>지원일</th>
            <th>고용형태</th>
            <th>지원관리</th>
          </tr>
        </thead>
        <tbody>
          {unreadList &&
            unreadList.map((unread) => (
              <tr key={unread.recruit_seq}>
                <td className="company_field">{unread.com_name}</td>
                <td className="tit_field">{unread.recruit_title}</td>
                <td className="date_field">
                  <span className="th_m">지원일 :</span>
                  {moment(unread.reg_dtm).format("YY.MM.DD")}
                </td>
                <td className="type_field">{unread.emp_type_kr}</td>
                <td className="btn_field">
                  {/* <Link
                      to={`/Company/Resume/${application.resume_seq}?recruit_seq=${application.recruit_seq}`}
                      className="com_btn oval line s">
                      이력서 보기
                    </Link> */}
                  <Link to={`/Mypage/ResumeWriting/${unread.resume_seq}`} className="com_btn blue line oval s">
                    지원 이력서 보기
                  </Link>
                  <button className="com_btn gray line oval s">취소</button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <section className="com_board_list__bottom">
        <div className="total">
          총 지원 수 <span className="num">{totalUnreadCount}</span>
        </div>
      </section>
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(totalUnreadCount / applicationPerPage)}
        onNextClick={handleNext}
        onPrevClick={handlePrevious}
        perPage={applicationPerPage}
        handlePerPageChange={(e) => setApplicationPerPage(e.target.value)}
      />
    </div>
  );
}

export default MatchUnread;
