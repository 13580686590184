import { getAuthHeaders } from "../utils/auth";

const backendUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_SERVER_BASE_URL_DEV
    : process.env.REACT_APP_SERVER_BASE_URL_PROD;

if (typeof backendUrl !== "string" || backendUrl.length <= 0) throw new Error("Access key not set");

export const queryBackend = async (url) => {
  if (Number(sessionStorage.getItem("expiresAt")) < new Date().getTime()) console.log("Token has expired.");

  const data = await fetch(backendUrl + url, {
    headers: getAuthHeaders(),
  }).catch((err) => {
    throw new Error(err);
  });

  return data;
};

// * Don't forget to set headers on mutations
export const mutateBackend = async (url, { data, headers = {} }) => {

  const response = await fetch(backendUrl + url, {
    headers: { ...getAuthHeaders(), ...headers },
    method: "POST",
    body: JSON.stringify(data),
  }).catch((err) => {
    throw new Error(err);
  });

  return response;
};

export const updateBackend = async (url, { data = {}, headers = {} }) => {
  const response = await fetch(backendUrl + url, {
    headers: { ...getAuthHeaders(), ...headers },
    method: "PUT",
    body: JSON.stringify(data),
  }).catch((err) => {
    throw new Error(err);
  });

  return response;
};

export const mutateBackendFormData = async (url, body) => {
  const response = await fetch(backendUrl + url, {
    headers: { ...getAuthHeaders() },
    method: "POST",
    body,
  }).catch((err) => {
    throw new Error(err);
  });

  return response;
};

export const deleteBackend = async (url, { data, headers = {} }) => {
  const response = await fetch(backendUrl + url, {
    headers: { ...getAuthHeaders(), ...headers },
    method: "DELETE",
    body: JSON.stringify({ ...data }),
  }).catch((err) => {
    throw new Error(err);
  });

  return response;
};
