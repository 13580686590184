import { useMutation, useQuery } from "react-query";
import { queryBackend } from "../api";
import customFetch from "../config/API";

export function useAuthenticatedQuery(queryKey, endpoint) {
  return useQuery(queryKey, async () => queryBackend(endpoint), {
    refetchOnWindowFocus: false,
  });
}

export function useAuthenticatedQueryJson(queryKey, endpoint) {
  return useQuery(queryKey, async () => await (await queryBackend(endpoint)).json(), {
    refetchOnWindowFocus: false,
  });
}

/**
 *
 * @param {String} endpoint
 * @param {import("react-query").UseQueryOptions} params
 * @returns
 */
export function useCustomQuery(endpoint, params) {
  return useQuery({
    queryFn: async () => {
      const res = await queryBackend(endpoint);
      return res.json();
    },
    ...params,
  });
}

export function useAuthenticatedMutation(endpoint, isSecureRoute = true) {
  return useMutation((data) =>
    customFetch(endpoint, {
      method: "POST",
      body: JSON.stringify(data),
      isSecureRoute,
    }),
  );
}
