import React, { useEffect, useState } from "react";
import PopMentoringDetail from "./_PopMentoringDetail";
import { useNavigate } from "react-router-dom";
import { getImageLink } from "../../../../../utils/misc";

export default function MentoringItem(props) {
  const { mento_title, mento_cate, mento_seq, image_seq, avg_rating, selectedMentoring, user_name, career_history, user } =
    props;
  
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const handleCloseApplyModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (selectedMentoring && selectedMentoring.mento_seq === mento_seq) {
      setModalOpen(true);
    }
  }, [selectedMentoring, mento_seq]);

  return (
    <>
      <div className="com_card_item" onClick={() =>  Object.keys(user).length > 0 ? setModalOpen(true) : navigate("/member/login")}>
        <p className="thumb">
          <img src={getImageLink(image_seq)} className="thumb_img" alt="mentoring1" />
        </p>
        <div className="info">
          <div className="icon_wrap">
            <span className="icon_line_blue">{mento_cate}</span>
            {avg_rating && Number(avg_rating) !== 0 && <span className="grade">{Number(avg_rating).toFixed(1)}</span>}
          </div>
          <p className="tit">{mento_title}</p>
          <p className="info_s">
            <span className="com_point_txt dot">{user_name}</span>
            <span className="info_txt">{career_history}</span>
          </p>
        </div>
      </div>
      {/* modal */}
      {modalOpen && (
        <PopMentoringDetail
          open={modalOpen}
          close={handleCloseApplyModal}
          mento_seq={mento_seq}
          avg_rating={avg_rating}
        />
      )}{" "}
    </>
  );
}
