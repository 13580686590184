import React, { useContext, useEffect, useState } from "react";
import { useDaumPostcodePopup } from "react-daum-postcode";

import PopResume from "../../components/myPage/_PopResume";
import PopDesiredWork from "../../components/myPage/_PopDesiredWork";
import PopDesiredJob from "../../components/myPage/_PopDesiredJob";
//image
import IconCamera from "../../assets/img/icon_camera.png";
//css
import "../../assets/scss/page/write_resume.scss";
import { useForm } from "react-hook-form";
import Resume_career from "./resume_career";
import Resume_education from "./resume_education";
import Resume_certificate from "./resume_cerficate";
import Resume_academic from "./resume_academic";
import Resume_links from "./resume_link";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import { useNavigate, useParams } from "react-router-dom";
import { mutateBackend, mutateBackendFormData, queryBackend, updateBackend } from "../../../../../api";
// import { toast } from "react-toastify";
import { AuthContext } from "../../../../../context/Auth";
// eslint-disable-next-line no-unused-vars
import { getImageLink, getUserImageLink } from "../../../../../utils/misc";
import Modal from "../../../front/component/Inc/Modal";

function ResumeWriting() {
  const [resumeModal, setResumeModal] = useState(false);
  const [desireWorkModal, setDesireWorkModal] = useState(false);
  const [desireJobModal, setDesireJobModal] = useState(false);
  const [desiredWork, setDesiredWork] = useState([]);
  // const [categories, setCategories] = useState([]);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [createMode, setCreateMode] = useState(true);
  const [resumeImage, setResumeImage] = useState(null);
  const [address, setAddress] = useState({ fullAddress: "", extraAddress: "" });

  const [decideAfterInterview, setDecideAfterInterview] = useState(false);

  const [modalAlert, setModalAlert] = useState(false);
  const [modalText, setModalText] = useState('');
  const [saveError, setSaveError] = useState(false);

  const navigate = useNavigate();
  const { resume_seq } = useParams();
  const { user } = useContext(AuthContext);

  const {
    formState: { errors },
    control,
    handleSubmit,
    register,
    watch,
    // trigger,
    setValue,
    getValues
  } = useForm({
    mode: "onChange",
    // 20231012 fixed by wem - See postman Resume insert API(/resume/insert)
    defaultValues: {
      resume_info: {},
      desired_loca: [],
      desired_job: [],
      resume_academic: [],
      resume_career: [],
      resume_certificate: [],
      resume_education: [],
      link_url: [],
    },
  });

  const open = useDaumPostcodePopup("https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js");

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    setAddress((prev) => ({ ...prev, fullAddress, extraAddress }));

    setValue("resume_info.resume_addr1", fullAddress);
  };
  useEffect(() => {
    setValue("resume_info.resume_addr1", address?.fullAddress);
  }, [address]);

  const selectAddress = () => {
    open({ onComplete: handleComplete });
  };

  const handleAddDesiredWork = (options) => {
    setDesiredWork(options);
  };

  // 20231017 deleted by wem - Change to the same format as other common code calls
  // The 0th cate_seq of categories is assigned to mento_seq, but it is unclear what mento_seq is.

  // fetch recu_job categories
  // const { data, isFetching } = useAuthenticatedQuery(["selectAllCategories"], `/category?cate_gr=RECU_JOB`);

  // useEffect(() => {
  //   const updateData = async (data) => {
  //     const response = await data.json();

  //     if (response.messageCode === 200) {
  //       setCategories(response.data.categories);
  //       setValue("mento_cate", response.data.categories[0].cate_seq);
  //     }
  //   };

  //   !isFetching && data && !data.bodyUsed && updateData(data);
  // }, [data, isFetching]);

  useEffect(() => {
    setValue("resume_info.resume_email", user.user_email);
    setValue("resume_info.resume_mobile", user.user_mobile);
    setValue("resume_info.resume_name", user.user_name); // No resume_name input field - 20231012 added by wem
  }, [user]);

  const { data: jobCategoryData } = useCustomQuery(`/category?cate_gr=RECU_JOB`, {
    queryKey: ["selectJobCate"],
    select: (res) => res?.data?.categories,
  });

  const { data: empTypeData } = useCustomQuery(`/comm/list?code_gr=EMP_TYPE`, {
    queryKey: ["selectEmpTypeCate"],
    select: (res) => res?.data?.dataList,
  });

  const { data: schoolTypeData } = useCustomQuery(`/comm/list?code_gr=SCHO_TYPE`, {
    queryKey: ["selectSchoolTypeCate"],
    select: (res) => res?.data?.dataList,
  });

  const { data: schoolStatusData } = useCustomQuery(`/comm/list?code_gr=SCHO_STATUS`, {
    queryKey: ["selectSchoolStatusCate"],
    select: (res) => res?.data?.dataList,
  });

  // 20231012 added by wem - Change to location data comm_code API
  const { data: locationTypeData } = useCustomQuery(`/comm/list?code_gr=LOCA_CD`, {
    queryKey: ["selectLocationTypeCate"],
    select: (res) => res?.data?.dataList,
  });

  const { data: linkTypeData } = useCustomQuery(`/comm/list?code_gr=LINK_CATE`, {
    queryKey: ["selectLinkTypeCate"],
    select: (res) => res?.data?.dataList,
  });

  const { data: carrStatusData } = useCustomQuery(`/comm/list?code_gr=CARR_STATUS`, {
    queryKey: ["selectCarrStatusCate"],
    select: (res) => res?.data?.dataList,
  });

  const [desiredJobCategories, setDesiredJobCategories] = useState([]);

  const addDesiredJobCategories = (parentName, childData) => {
    if (desiredJobCategories.length < 3) {
      setDesiredJobCategories((prevDesiredJobCategories) => {
        const updatedCategories = [...prevDesiredJobCategories, { parent_name: parentName, ...childData }];
        return updatedCategories;
      });
    }
  };

  const handleResumeImageUpload = async (resumeImage) => {
    const formData = new FormData();
    formData.append("file", resumeImage);
    formData.append("upload_ty", "resume_writing");

    const response = await mutateBackendFormData("/upload/image", formData)
      .then(async (data) => await data.json())
      .catch(() => ({ messageCode: 400 }));

    if (response.messageCode === 200) {
      console.log("handleUpload", response.data);
      setValue("resume_info.image_seq", response.data[0]?.up_file_seq);
      return;
    } else {
      console.log("file failed");
      return;
    }
  };

  useEffect(() => {
    if (resume_seq && locationTypeData && jobCategoryData) {
      const fetchResumeData = async () => {
        try {
          const response = await queryBackend(`/resume/view/${resume_seq}`);
          const { data } = await response.json();

          console.log("---===========================================");
          console.log(data);
          console.log("---===========================================");

          setValue("desired_job", data.desired_job);
          setValue("desired_loca", data.desired_loca);
          setValue("resume_info", data.resume_info);
          setValue("resume_education", data.resume_education);
          setValue("resume_career", data.resume_career);
          setValue("resume_academic", data.resume_academic);
          setValue("resume_certificate", data.resume_certificate);
          setValue("link_url", data.link_url);

          if (data.resume_info.emp_type === "") setDecideAfterInterview(true);

          const desiredLoca = locationTypeData?.filter((location) =>
            data.desired_loca?.some((desired) => desired.location_cd === location.comm_code),
          );
          setDesiredWork(desiredLoca);

          const desiredJob = jobCategoryData
            .filter((job) => data.desired_job?.some((desired) => desired.cate_seq === job.cate_seq))
            .map((el) => ({
              ...el,
              parent_name: jobCategoryData.find((i) => i.cate_seq === el.parent_seq)?.cate_name,
            }));
          setDesiredJobCategories(desiredJob);
        } catch (error) {
          console.error("Error fetching resume data:", error);
        }
      };

      fetchResumeData();
      setCreateMode(false);
    }
  }, [resume_seq, locationTypeData, jobCategoryData]);

  /* Progress tracking logic */

  const isResumeTitleFilled = () => {
    return !!watch("resume_info.resume_title");
  };


  const isEmploymentInfoFilled = () => {
    return (
      !!watch("resume_info.emp_type") &&
      ((decideAfterInterview && watch("resume_info.resume_salary") === 0) || (!decideAfterInterview && !!watch("resume_info.resume_salary"))) &&
      !!watch("desired_job") &&
      !!watch("desired_loca") &&
      watch("desired_job").length > 0 &&
      watch("desired_loca").length > 0
    );
  };


  const isPersonalInfoFilled = () => {
    return (
      !!watch("resume_info.resume_name") &&
      !!watch("resume_info.resume_mobile") &&
      !!watch("resume_info.resume_birthday") &&
      !!watch("resume_info.resume_gender") &&
      !!watch("resume_info.resume_email") &&
      !!watch("resume_info.resume_addr1")
    );
  };


  const isCertificatesFilled = () => {
    const firstCertificate = watch("resume_certificate[0]");
    const isFirstCertificateFilled =
      firstCertificate &&
      !!firstCertificate.certi_name &&
      !!firstCertificate.certi_insti &&
      !!firstCertificate.certi_acqu_date;

    return !!watch("resume_certificate") && watch("resume_certificate").length > 0 && isFirstCertificateFilled;
  };

  // const isCareersFilled = () => {
  //   const firstCareer = watch("resume_career[0]");
  //   const isFirstCareerFilled =
  //     firstCareer &&
  //     !!firstCareer.career_type &&
  //     !!firstCareer.career_company &&
  //     !!firstCareer.career_department &&
  //     !!firstCareer.career_position &&
  //     !!firstCareer.join_date &&
  //     !!firstCareer.leave_date &&
  //     !!firstCareer.career_status &&
  //     !!firstCareer.career_desc;

  //   console.log(isFirstCareerFilled);

  //   return !!watch("resume_career") && watch("resume_career").length > 0;
  // };
  //추가경력 체크 함수 변경 (기존 함수 경력의 전체 스테이트가가 아닌 0번째 값을로만 체크하는 문제있음)
  // const isCareersFilled = () => {
  //   const firstCareer = watch("resume_career");

  //   const chkObj = Object.keys(firstCareer).some(function (v) {
  //     console.log(firstCareer[v].career_type);
  //     if (firstCareer[v].career_type && firstCareer[v].career_type === "ADD") {
  //       const isFirstCareerFilled =
  //         firstCareer[v] &&
  //         !!firstCareer[v].career_type &&
  //         !!firstCareer[v].career_company &&
  //         !!firstCareer[v].career_department &&
  //         !!firstCareer[v].career_position &&
  //         !!firstCareer[v].join_date &&
  //         !!firstCareer[v].leave_date &&
  //         !!firstCareer[v].career_status &&
  //         !!firstCareer[v].career_desc;
  //       if (isFirstCareerFilled) {
  //         return true;
  //       }
  //       return true;
  //     }
  //   });

  //   return chkObj;
  // };


  //인증경력 체크 함수
  const isCertificationExperienceFilled = () => {
    const firstCareer = watch("resume_career");

    //인증경력 개별 추가 건수 체크 시작
    let addCount = 0;
    for (let i = 0; i < firstCareer.length; i++) {
      if (firstCareer[i].career_type === "ADD") {
        addCount++;
      }
    }
    //인증경력 개별 추가 건수 체크 끝

    //인증경력 프르젝트 추가 건수 체크 시작
    let proCount = 0;
    for (let i = 0; i < firstCareer.length; i++) {
      if (firstCareer[i].career_proj) {
        proCount = proCount + firstCareer[i].career_proj.length;
      }
    }
    //인증경력 프르젝트 추가 건수 체크 끝

    //인증경력 인증경력 가져오기 체크 시작
    let certCount = 0;
    for (let i = 0; i < firstCareer.length; i++) {
      if (firstCareer[i].career_type === "CERTI") {
        certCount = 5;
      }
    }
    //인증경력 인증경력 가져오기 체크 끝
    // const chkObj = Object.keys(firstCareer).some(function (v) {
    //   // console.log(firstCareer[v].career_type);
    //   if (firstCareer[v].career_type && firstCareer[v].career_type === "CERTI") {
    //     // const isFirstCareerFilled =
    //     //   firstCareer[v] &&
    //     //   !!firstCareer[v].career_type &&
    //     //   !!firstCareer[v].career_company &&
    //     //   !!firstCareer[v].career_department &&
    //     //   !!firstCareer[v].career_position &&
    //     //   !!firstCareer[v].join_date &&
    //     //   !!firstCareer[v].leave_date &&
    //     //   !!firstCareer[v].career_status &&
    //     //   !!firstCareer[v].career_desc;
    //     // if (isFirstCareerFilled) {
    //     //   return true;
    //     // }
    //     return 5;
    //   }else{
    //     return 0;
    //   }
    // });

    const tot = certCount + addCount + proCount;
    const res = tot > 30 ? 30 : tot;
    return res;
  };


  const isEducationsFilled = () => {
    const firstEducation = watch("resume_education[0]");
    const isFirstEducationFilled =
      firstEducation &&
      !!firstEducation.edu_name &&
      !!firstEducation.edu_insti &&
      !!firstEducation.edu_start &&
      !!firstEducation.edu_end &&
      !!firstEducation.edu_desc;

    return !!watch("resume_education") && watch("resume_education").length > 0 && isFirstEducationFilled;
  };


  const isAcademicsFilled = () => {
    const firstAcademic = watch("resume_academic[1]");
    const isFirstAcademicFilled =
      firstAcademic &&
      !!firstAcademic.acad_type &&
      !!firstAcademic.acad_name &&
      !!firstAcademic.acad_major &&
      !!firstAcademic.entrance_date &&
      !!firstAcademic.finish_date &&
      !!firstAcademic.acad_status;

    return !!watch("resume_academic") && watch("resume_academic").length > 1 && isFirstAcademicFilled;
  };


  const isSkillsFilled = () => {
    return !!watch("resume_info.resume_skill");
  };

  const isToolsFilled = () => {
    return !!watch("resume_info.resume_tools");
  };

  const isLinksFilled = () => {
    return !!watch("link_url") && watch("link_url").length > 0;
  };

  const isIntroFilled = () => {
    return !!watch("resume_info.resume_intro");
  };

  // const isProjectFilled = () => {
  //   const careerProjects = watch("resume_career");
  //   console.log(careerProjects.some(project => project.career_proj && project.career_proj.length > 0));
  //   return careerProjects.some(project => project.career_proj && project.career_proj.length > 0); //인증경력에서 불러온 직장중 프로젝트를 추가한 적이 있을 때
  // };

  // console.log(isResumeTitleFilled(), isEmploymentInfoFilled(), isPersonalInfoFilled(), isAcademicsFilled(), isIntroFilled(), isLinksFilled(), isCertificationExperienceFilled(), isCertificatesFilled(),
  // isEducationsFilled(), isSkillsFilled(), isToolsFilled());
  const overallProgress =
    // ========== 필수 ==========
    (isResumeTitleFilled() ? 2 : 0) + //필수 > 이력서 제목
    (isEmploymentInfoFilled() ? 2 : 0) + //필수 > 근무희망조건
    (isPersonalInfoFilled() ? 6 : 0) + //필수 > 인적사항
    (isAcademicsFilled() ? 10 : 0) + //필수 > 학력
    (isIntroFilled() ? 6 : 0) + //필수 > 자기소개
    (isLinksFilled() ? 4 : 0) + //필수 > 링크

    // ========== 선택 ==========
    (isCertificationExperienceFilled()) + //선택 > 인증경력 > career_type === "CERTI" min : 0, max : 30
    // (isCareersFilled() ? 2 : 0) + //선택 > 인증경력 > career_type === "ADD"
    // (isProjectFilled() ? 2 : 0) + //선택 > 프로젝트 추가
    (isCertificatesFilled() ? 14 : 0) + //선택 > 자격증
    (isEducationsFilled() ? 14 : 0) + //선택 > 교육
    (isSkillsFilled() ? 6 : 0) + //선택 > 보유스킬
    (isToolsFilled() ? 6 : 0); //선택 > tool

  useEffect(() => {
    if (desiredJobCategories) {
      const jobiIds = desiredJobCategories.map((category) => ({ cate_seq: category.cate_seq }));
      setValue("desired_job", jobiIds);
    }
  }, [desiredJobCategories]);

  useEffect(() => {
    if (desiredWork) {
      const workIds = desiredWork.map((work) => ({ location_cd: work.comm_code }));
      setValue("desired_loca", workIds);
    }
  }, [desiredWork]);

  const handleDesiredWorkplaceDelete = (id) => {
    const filtered = desiredWork.filter((work) => work !== id);
    setDesiredWork(filtered);
  };

  const handleDesiredJobDelete = (category) => {
    const filtered = desiredJobCategories.filter((job) => job !== category);
    setDesiredJobCategories(filtered);
  };

  const onValidSubmit = async (data) => {
    console.log(">>>>>>>.................>>>>>>>>>");
    console.log(data);
    console.log(">>>>>>>.................>>>>>>>>>");
    setFormSubmitting(true);

    try {
      let responseData;
      if (resume_seq) {
        responseData = await updateBackend(`/resume/view/${resume_seq}`, {
          method: "PUT",
          data: {
            ...data,
          },
          headers: {
            "Content-Type": "application/json",
          },
        });
      } else {
        responseData = await mutateBackend("/resume/insert", {
          data: {
            ...data,
          },
          headers: {
            "Content-Type": "application/json",
          },
        });
      }

      const response = await responseData.json();

      if (response.messageCode === 200) {
        // toast.success("성공적으로 등록되었습니다!");
        // navigate("/mypage/MyResume");
        //성공적으로 등록되었습니다!
        const resume_status = getValues('resume_info.resume_status');
        if (resume_status === 'TEMP') {
          setModalText('이력서가 임시저장 되었습니다.');
        } else {
          setModalText('성공적으로 등록되었습니다.');
        }

        setModalAlert(true);
      }
    } catch (error) {
      // toast.error("뭔가 잘못되었습니다! 다시 시도하십시오!");
      setSaveError(true);
      setModalText('뭔가 잘못되었습니다! 다시 시도하십시오!');
      setModalAlert(true);

    }

    setFormSubmitting(false);
  };

  const saveModalClose = () => {

    if (saveError) {
      setModalAlert(false);
    } else {
      const resume_status = getValues('resume_info.resume_status');
      if (resume_status === 'TEMP') {
        setModalAlert(false);
      } else {
        setModalAlert(false);
        navigate("/mypage/MyResume");
      }
    }

  }


  return (
    <>
      <div className="container">
        <h2 className="com_pg_tit">이력서 작성</h2>
        <div className="input_description_box">
          {/* <div className="com_resume_progress">
            <p className="tit">
              이력서 완성도{" "}
              <button className="exclaim" onClick={() => setResumeModal(true)}>
                !
              </button>
            </p>
            <div className="progress_bar tit text_blue">
              <span className="progress">
                <span className="filled" style={{ width: `${overallProgress}%` }}></span>
              </span>
              {overallProgress}%
            </div>
            <div className="btn_wrapper">
              <button className="com_btn line">임시 저장</button>
              <button className="com_btn blue">작성완료</button>
            </div>
          </div> */}
          <form className="box" onSubmit={handleSubmit(onValidSubmit)}>
            <ul className="form_wrapper">
              {/* Type of employment and desired salary */}

              {/* section 1 이력서 제목*/}
              <li className="title_field">
                이력서 제목 <span className="required">*</span>
              </li>
              <li className="personal_field">
                <input
                  type="text" id="title" className="input" placeholder="이력서 제목"
                  style={{ width: '100%', marginTop: '20px' }}
                  {...register("resume_info.resume_title", {
                    required: "이력서 제목을 입력해주세요.",
                  })}
                />
              </li>
              {errors.resume_info?.resume_title && <p style={{ color: "red" }}>{errors.resume_info.resume_title?.message}</p>}

              {/* section 2 인적사항*/}
              <li className="title_field">
                인적사항 <span className="required">*</span>
              </li>

              <li className="personal_field">
                <div className="photo">
                  <label htmlFor="image_seq">
                    <p className="title">
                      이력서 사진 <span className="icon_pencil"></span>
                    </p>
                    {resumeImage ? (
                      <div className="resume_image">
                        <img src={URL.createObjectURL(resumeImage)} className="img_upload" alt={resumeImage.filename} />
                      </div>
                    ) : watch("resume_info[image_seq]") > 0 ? (
                      <div className="resume_image" >
                        <img
                          src={getImageLink(watch("resume_info[image_seq]"))}
                          alt="프로필"
                        />
                      </div>
                    ) : (
                      <div className="resume_image">
                        <img src={IconCamera} alt="프로필" />
                        {/* {getUserImageLink(user["user_img"]) ?
                          <img src={getUserImageLink(user["user_img"])} alt="프로필" style={{height:'100%', width: '100%'}} />
                          :
                          <img src={IconCamera} alt="프로필" />
                        } */}

                      </div>
                    )}
                  </label>

                  <input
                    type="file"
                    {...register("resume_info.image_seq")}
                    onChange={(e) => {
                      setResumeImage(e.target.files[0]);
                      handleResumeImageUpload(e.target.files[0]);
                    }}
                    id="image_seq"
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  {errors.resume_info?.image_seq && (
                    <p style={{ color: "red" }}>{errors.resume_info.image_seq?.message}</p>
                  )}
                </div>
                <div className="input_field">
                  <div className="com_input_sec">
                    <label htmlFor="name" className="tit">
                      이름
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="input"
                      placeholder="이름을 입력해주세요."
                      {...register("resume_info.resume_name", {
                        required: "이름을 입력해주세요.",
                      })}
                    />
                    {errors.resume_info?.resume_name && (
                      <p style={{ color: "red" }}>{errors.resume_info.resume_name?.message}</p>
                    )}
                  </div>
                  <div className="com_input_sec">
                    <label htmlFor="contact1" className="tit">
                      연락처
                    </label>
                    <input type="text" className="input" {...register("resume_info.resume_mobile")} />
                    {(errors.contact1 || errors.contact2 || errors.contact3) && (
                      <p style={{ color: "red" }}>
                        {errors.contact1?.message || errors.contact2?.message || errors.contact3}
                      </p>
                    )}
                  </div>
                  <div className="com_input_sec">
                    <label htmlFor="birthday" className="tit">
                      생년월일
                    </label>
                    <input type="date" {...register("resume_info.resume_birthday", {
                      required: watch("resume_info.resume_status") === "DONE" && "생년월일을 입력해주세요."
                    })} className="input" max={'9999-12-31'} />
                    {errors.resume_info?.resume_birthday && (
                      <div style={{ color: "red" }}>{"생년월일을 입력해주세요."}</div>
                    )}
                  </div>
                  <div className="com_input_sec">
                    <label htmlFor="birth" className="tit">
                      성별
                    </label>
                    <div className="radio_wrapper">
                      <input
                        type="radio"
                        name="gender"
                        className="com_chk circle"
                        id="male"
                        value="M"
                        {...register("resume_info.resume_gender", { required: "성별을 선택해주세요." })}
                        defaultChecked={true}
                      />
                      <label htmlFor="male" className="gender">
                        남성
                      </label>
                      <input
                        type="radio"
                        name="gender"
                        className="com_chk circle"
                        id="female"
                        value="F"
                        {...register("resume_info.resume_gender", { required: "성별을 선택해주세요." })}
                      />
                      <label htmlFor="female" className="gender">
                        여성
                      </label>
                    </div>
                    {errors.resume_info?.resume_gender && (
                      <p style={{ color: "red" }}>{errors.resume_info?.resume_gender.message}</p>
                    )}
                  </div>
                  <div className="com_input_sec">
                    <label htmlFor="email" className="tit">
                      이메일
                    </label>
                    <input
                      type="text"
                      placeholder="@ 포함 입력"
                      className="input"
                      {...register("resume_info.resume_email", {
                        required: "이메일을 입력해주세요.",
                        pattern: {
                          value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                          message: "유효한 이메일을 입력해주세요.",
                        },
                      })}
                    />
                    {errors.resume_info?.resume_email && (
                      <p style={{ color: "red" }}>{errors.resume_info.resume_email?.message}</p>
                    )}
                  </div>
                  <div className="com_input_sec">
                    <label htmlFor="address" className="tit">
                      주소
                    </label>
                    <input
                      type="text"
                      placeholder="주소를 입력해주세요."
                      className="input_add"
                      id="address"
                      {...register("resume_info.resume_addr1", { required: watch("resume_info.resume_status") === "DONE" && "주소를 입력해주세요." })}
                    />
                    <button type="button" className="com_btn line blue s" onClick={selectAddress}>
                      주소 찾기
                    </button>
                    {errors.resume_info?.resume_addr1 && (
                      <p style={{ color: "red" }}>{errors.resume_info.resume_addr1?.message}</p>
                    )}
                  </div>
                  <div className="com_input_sec">
                    <label htmlFor="add_addr" className="tit">
                      추가 주소
                    </label>
                    <input
                      type="text"
                      placeholder="상세 주소"
                      className="input"
                      defaultValue={address.extraAddress}
                      {...register("resume_info.resume_addr2")}
                      onChange={(e) => {
                        setAddress((prevAddress) => {
                          return {
                            ...prevAddress,
                            extraAddress: e.target.value,
                          };
                        });
                      }}
                    />
                  </div>
                  {/* <div className="com_input_sec"></div> */}
                </div>
              </li>

              {/* section 3 학력*/}
              {/* Academic - ces_resume_academic */}
              <Resume_academic {...{ control, register, errors, schoolTypeData, schoolStatusData, setValue, getValues, watch }} />
              <li className="title_field">자기소개<span className="required">*</span></li>
              <li className="textarea_field">
                <textarea
                  name=""
                  id=""
                  cols={30}
                  rows={10}
                  className="textarea_input"
                  placeholder="자기소개를 작성해주세요."
                  {...register("resume_info.resume_intro", {
                    required: watch("resume_info.resume_status") === "DONE" && "자기소개를 작성해주세요.",
                  })}>
                </textarea>
                <p className="count">( 0 / 500)</p>
              </li>
              {errors?.resume_info?.resume_intro && <p style={{ color: "red" }}>{errors?.resume_info?.resume_intro?.message}</p>}

              {/* section 4 근무희망조건*/}
              {/* Personal information */}
              <li className="title_field">
                근무희망조건 <span className="required">*</span>
              </li>
              <div className="bottom_line">
                <li className="decision_field working_condition">
                  <select
                    className="select"
                    {...register("resume_info[emp_type]", {
                      required: (watch("resume_info.resume_status") === "DONE" && !decideAfterInterview) && "고용 유형을 선택해주세요.",
                      // validate: (value) => {
                      //   if (decideAfterInterview) {
                      //     return true; // 무조건 유효한 경우
                      //   } else if (value !== "") {
                      //     return true; // 선택된 값이 유효한 경우
                      //   } else {
                      //     return "고용형태를 선택하세요"; // 선택된 값이 없는 경우에 메시지 반환
                      //   }
                      // },
                    })}
                    /*disabled={decideAfterInterview}*/>
                    <option value="" /* disabled */>고용형태</option>
                    {empTypeData?.map((empType) => {
                      return (
                        <option key={empType.comm_code} value={empType.comm_code}>
                          {empType.code_nm_kr}
                        </option>
                      );
                    })}
                  </select>
                  <input
                    className="input"
                    type="number"
                    placeholder="희망연봉"
                    {...register("resume_info.resume_salary", {
                      required: (watch("resume_info.resume_status") === "DONE" && !decideAfterInterview) && "희망연봉을 입력해주세요.",
                      // min: 1,
                    })}
                    disabled={decideAfterInterview}
                  />
                  <input
                    type="checkbox"
                    name="decision"
                    className="com_chk circle"
                    id="decision"
                    checked={decideAfterInterview}
                    onChange={() => {
                      setDecideAfterInterview(!decideAfterInterview);
                      if (!decideAfterInterview) {
                        // setValue("resume_info.emp_type", "");
                        setValue("resume_info.resume_salary", 0);
                      }
                    }}
                  />

                  <label htmlFor="decision" className="decision">
                    면접 후 결정
                  </label>
                </li>
                {errors.resume_info?.emp_type && <p style={{ color: "red" }}>{errors.resume_info?.emp_type.message}</p>}
                {(!errors.resume_info?.emp_type && !decideAfterInterview && errors.resume_info?.resume_salary) && <p style={{ color: "red" }}>
                  {errors.resume_info?.resume_salary.message}
                </p>}
              </div>
              <li className="decision_field column bottom_line">
                <div className="input_wrapper">
                  <button type="button" className="com_btn blue oval xs" onClick={() => setDesireWorkModal(true)}>
                    희망근무지 추가
                  </button>
                  <p className="description">최대 3개의 근무지를 추가할 수 있습니다.</p>
                </div>
                <div className="hashtags2">
                  {desiredWork?.map((option) => (
                    <div
                      className="hash"
                      key={option.comm_code}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <span>{option.code_nm_kr}</span>
                      <button
                        className="remove"
                        onClick={() => handleDesiredWorkplaceDelete(option)}
                        style={{
                          marginLeft: "10px",
                        }}>
                        {"   "}⨉
                      </button>
                    </div>
                  ))}
                </div>
                {(Object.keys(errors).length !== 0 && watch("resume_info.resume_status") === "DONE" && watch("desired_loca").length === 0) &&
                  <p style={{ color: "red" }}>{"희망근무지를 추가해주세요."}</p>}
              </li>
              <li className="decision_field column">
                <div className="input_wrapper">
                  <button type="button" className="com_btn blue oval xs" onClick={() => setDesireJobModal(true)}>
                    희망 직무 추가
                  </button>
                  <p className="description">최대 3개의 직무를 추가할 수 있습니다.</p>
                </div>
                <div className="hashtags2">
                  {desiredJobCategories.map((childData, index) => (
                    <div
                      className="hash"
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <span key={childData}>
                        {childData.parent_name} &gt; {childData.cate_name}
                      </span>
                      <button
                        className="remove"
                        onClick={() => handleDesiredJobDelete(childData)}
                        style={{
                          marginLeft: "10px",
                        }}>
                        {"   "}⨉
                      </button>
                    </div>
                  ))}
                </div>
                {(Object.keys(errors).length !== 0 && watch("resume_info.resume_status") === "DONE" && watch("desired_job").length === 0) &&
                  <p style={{ color: "red" }}>{"희망 직무를 추가해주세요."}</p>}
              </li>

              {/* section 5 포트폴리오*/}
              <Resume_links {...{ control, register, linkTypeData, errors, getValues, watch }} />

              {/* section 6 인증경력*/}
              {/* Career - ces_resume_career */}
              <Resume_career {...{ control, register, errors, carrStatusData, setValue, watch, decideAfterInterview, setDecideAfterInterview }} />

              {/* section 7 자격증*/}
              {/* Certificates - ces_certificate */}
              <Resume_certificate {...{ control, register, errors, setValue }} />

              {/* section 8 교육*/}
              {/* Education - ces_resume_education */}
              <Resume_education {...{ control, register, errors, setValue }} />

              {/* section 9 보유 스킬*/}
              <li className="title_field">보유 스킬</li>
              <li className="textarea_field bottom_line">
                <textarea
                  name=""
                  id=""
                  cols={30}
                  rows={10}
                  className="input"
                  placeholder="상세설명을 입력해주세요."
                  {...register("resume_info.resume_skill")}></textarea>
              </li>

              {/* section 10 TOOL*/}
              <li className="title_field">TOOL</li>
              <li className="textarea_field bottom_line">
                <textarea
                  name=""
                  id=""
                  cols={30}
                  rows={10}
                  className="input"
                  placeholder="상세설명을 입력해주세요."
                  {...register("resume_info.resume_tools")}></textarea>
              </li>

              <li className="button_wrapper">
                <button
                  className="com_btn line"
                  type="submit"
                  onClick={() => {
                    setValue("resume_info.resume_progr", overallProgress);
                    setValue("resume_info.resume_status", "TEMP");
                  }}>
                  임시 저장
                </button>
                <button
                  className="com_btn blue"
                  type="submit"
                  onClick={() => {
                    setValue("resume_info.resume_progr", overallProgress);
                    setValue("resume_info.resume_status", "DONE");
                  }}>
                  {createMode ? "작성 완료" : "수정 완료"}{formSubmitting && <i className="gg-spinner-alt" />}{" "}
                </button>
              </li>

            </ul>
          </form>
        </div>
      </div>

      <PopResume open={resumeModal} close={() => setResumeModal(false)} />
      <PopDesiredWork
        open={desireWorkModal}
        close={() => setDesireWorkModal(false)}
        handleAddDesiredWork={handleAddDesiredWork}
        locationTypeData={locationTypeData}
        desiredWork={desiredWork}
      />
      <PopDesiredJob
        open={desireJobModal}
        close={() => setDesireJobModal(false)}
        jobCategoryData={jobCategoryData}
        addDesiredJobCategories={addDesiredJobCategories}
      />
      {/* Modal */}
      <Modal open={modalAlert} close={() => saveModalClose()}>
        <div className="content_container scroll" style={{ textAlign: 'center' }}>
          <h2>
            {modalText}
            <br />
          </h2>
        </div>
        <div className="btn_container">
          <button className="adm_com_btn orange xs" type="button" onClick={() => saveModalClose()} >확인</button>
        </div>
      </Modal>
    </>
  );
}

export default ResumeWriting;
