import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import { getImageLink } from "../../../../../utils/misc";
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import Map from "./Map";

const tab = ["세미나 소개", "참여신청/환불 안내"];
function Detail() {
  const navigate = useNavigate();
  const { id = "new" } = useParams();

  const [count, setCount] = useState(1);
  const [currentBtn, setCurrentBtn] = useState(tab[0]);

  const { data: seminar = {} } = useCustomQuery(`/seminar?semin_seq=${id}`, {
    queryKey: ["getSeminar", id],
    select: (res) => res?.data?.seminars?.[0] ?? {},
    enabled: +id > 0,
  });

  return (
    <main className="com_detail">
      <div className="com_center_wrap">
        <section className="sec_detail">
          <div className="content_wrap">
            <div className="image_wrap">
              <img src={getImageLink(seminar?.thumbnail)} alt="seminar_image" className="img" />
              <div className="address_wrap">
                <div className="detail_wrap icon_calender">
                  <p className="title">일정</p>
                  <p className="detail">{new Date(seminar?.semin_date).toLocaleString()}</p>
                </div>
                <div className="detail_wrap icon_location">
                  <p className="title">장소</p>
                  <p className="detail">{seminar?.semin_address}</p>
                </div>
                <div className="detail_wrap icon_multi_user">
                  <p className="title">정원</p>
                  <p className="detail">
                    {" "}
                    {seminar?.semin_applications} / {seminar?.semin_limit}명
                  </p>
                </div>
              </div>
            </div>
            <div className="title_wrap">
              <h2 className="title">{seminar?.semin_title}</h2>
              <p className="description">{seminar?.semin_sub_title}</p>
              <div className="com_tag_list">
                {seminar?.semin_tag?.split(",")?.map(
                  (data, index) =>
                    data && (
                      <span className="com_btn s lightblue" key={index}>
                        # {data}
                      </span>
                    ),
                )}
              </div>
              <div className="wrapper">
                <div className="other_detail">
                  <p className="desc">
                    {new Date(seminar?.reg_end_dtm).toLocaleString()} 까지
                    <span className="com_point_txt bold"> {seminar?.semin_limit}명 </span> 신청가능
                  </p>
                  <p className="desc">
                    가격 {seminar?.semin_price}원 <span className="divider"></span> 인원수
                    <button
                      onClick={() => {
                        if (count <= 1) {
                          toast.error("Minimum Seminar Application Limit Reached!");
                        } else {
                          setCount(count - 1);
                        }
                      }}
                      className="counter minus"></button>
                    <span className="bold"> {count} </span>
                    <button
                      onClick={() => {
                        if (count + +seminar?.semin_applications >= seminar?.semin_limit || !seminar?.semin_limit) {
                          toast.error("Maximum Seminar Application Limit Reached!");
                        } else {
                          setCount(count + 1);
                        }
                      }}
                      className="counter plus"></button>
                  </p>
                </div>
                <button
                  className="com_btn m bg blue oval"
                  onClick={() => {
                    if (
                      new Date(seminar?.reg_end_dtm) >= new Date() &&
                      +seminar?.semin_applications < +seminar?.semin_limit
                    ) {
                      navigate(`/seminar/apply/${seminar?.semin_seq}`, { state: { ticketCount: count } });
                    } else {
                      toast.error("Invalid Seminar!");
                    }
                  }}>
                  신청하기
                </button>
              </div>
            </div>
          </div>
          <div className="seminar_description">
            <div className="btn_wrapper">
              {tab.map((tabButton, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentBtn(tabButton)}
                  className={currentBtn === tabButton ? "com_btn active" : "com_btn"}>
                  {tabButton}
                </button>
              ))}
              <span className="space"></span>
            </div>
            <div className="description_wrap">
              {currentBtn === tab[0] ? (
                <>
                  <pre>{seminar?.semin_content}</pre>
                  <Map map_address={seminar?.semin_address} map_title={seminar?.semin_title} />
                </>
              ) : (
                <pre className="desc">{seminar?.semin_terms}</pre>
              )}
            </div>
          </div>
        </section>
      </div>
      {/* top button */}
      <ScrollToTopButton />
    </main>
  );
}

export default Detail;
