import React, { useState } from "react";
import Pagination from "../../components/Pagination";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import useCommCate from "../../../../../hooks/useCommCate";
import ResumeItem from "./_ResumeItem";

export default function FavoriteTalent() {
  const [resumeList, setResumeList] = useState([]);

  const { isFetching: isResumeLoading } = useCustomQuery(`/resume/list?per_page=20`, {
    queryKey: ["getResumes"],
    select: (res) => res?.data?.resumes,
    onSuccess: (data) => {
      setResumeList(data);
    },
  });

  const { data: desiredJobs } = useCommCate("RECU_JOB");

  return (
    <div className="tab_content">
      <p className="notice_txt">
        <span className="dot blue"></span>내가 찜한 인재 목록입니다.
      </p>
      <ul className="profile_list">
        {isResumeLoading ? (
          <i className="gg-spinner-alt" />
        ) : (
          resumeList.map((item, index) => <ResumeItem desiredJobs={desiredJobs} resume={item} key={index} />)
        )}
      </ul>
      <Pagination />
    </div>
  );
}
