import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCustomQuery } from "../../../../../../hooks/useAPI";
import { useForm } from "react-hook-form";
import { queryBackend, updateBackend } from "../../../../../../api";
import { toast } from "react-toastify";
import useCommCode from "../../../../../../hooks/useCommCode";
import useCommCate from "../../../../../../hooks/useCommCate";

const getEmploymentType = (emp_type, employmentTypes) => {
  const employmentType = employmentTypes.find((type) => type["comm_code"] === emp_type);

  return employmentType ? employmentType["code_nm_kr"] : emp_type;
};

const getDesiredJob = (desired_jobs, jobCategory) => {
  const result = [];

  for (let i = 0; i < desired_jobs.length; i++) {
    const jobTitle = jobCategory.find((jobCate) => jobCate["cate_seq"] === desired_jobs[i]["cate_seq"]);

    if (jobTitle) result.push(jobTitle["cate_name"]);
  }

  console.log(result);
  return result;
};

const getLocations = (desired_job_locations, locationCodes) => {
  const locations = [];

  for (let i = 0; i < desired_job_locations.length; i++) {
    const location = locationCodes.find((type) => type["comm_code"] === desired_job_locations[i]["location_cd"]);
    if (location) locations.push(location["code_nm_kr"]);
  }
  return locations;
};

export default function MentoringApplicationDetail() {
  const navigate = useNavigate();

  const { user_seq } = useParams();

  const [mentoringApplication, setMentoringApplication] = useState();
  const [resume, setResume] = useState();
  const [loadingResume, setLoadingResume] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const { data: employmentTypes } = useCommCode("EMP_TYPE");
  const { data: locationCodes } = useCommCode("LOCA_CD");

  const { data: jobCategory } = useCommCate("RECU_JOB");

  const { isFetching } = useCustomQuery("/mentoring/application/list?per_page=10&curr_page=1&user_seq=" + user_seq, {
    queryKey: ["getMentoring/" + user_seq],
    select: (res) => (res?.data.data_list.length > 0 ? res?.data.data_list[0] : null),
    onSuccess: async (data) => {
      console.log(data);
      if (!data) navigate(-1);
      setMentoringApplication(data);

      // Load user resumes
      setLoadingResume(true);

      if (data.resume_seq) {
        const userResume = await queryBackend("/resume/view/" + data.resume_seq).then(
          async (data) => (await data.json()).data,
        );
        setResume(userResume);
        console.log(userResume);
        setLoadingResume(false);
      }
    },
    refetchOnWindowFocus: false,
  });

  const { getValues, register } = useForm({
    mode: "onChange",
  });

  //저장
  const handleUpdate = async () => {
    if (!mentoringApplication) return;
    setIsUpdating(true);

    const mentorStatus = getValues("mento_status");

    const updateStatusResponse = await updateBackend("/admin/user/" + mentoringApplication["user_seq"], {
      data: { mento_status: mentorStatus },
      headers: { "Content-Type": "application/json" },
    }).then(async (data) => await data.json());

    const updateMentoApplicationDate = await updateBackend(
      "/mentoring/application/" + mentoringApplication["user_seq"],
      {
        headers: { "Content-Type": "application/json" },
      },
    ).then(async (data) => await data.json());

    setIsUpdating(false);

    if (updateStatusResponse.messageCode === 200 && updateMentoApplicationDate.messageCode === 200) {
      toast.success("멘토십 상태를 성공적으로 업데이트했습니다.");
    } else toast.error("멘토링 상태를 업데이트하지 못했습니다.");
  };

  if (isFetching || !mentoringApplication) return <i className="gg-spinner-big" />;

  return (
    <main>
      <h2 className="adm_com_pg_tit2 bottom_line"> 멘토링 관리</h2>

      {/* User Information */}
      <div className="adm_com_pg_content">
        <h2 className="sub_tit">- 멘토 신청 내역 &gt; 상세</h2>

        <div className="sub_tit"> 멘토 신청자 정보</div>
        <table className="adm_com_board_list type_row">
          <colgroup>
            <col width="10%" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              {/* User ID */}
              <th>사용자 ID</th>
              <td>{mentoringApplication["user_id"]}</td>
            </tr>
            <tr>
              {/* User name */}
              <th>이름</th>
              <td>{mentoringApplication["user_name"]}</td>
            </tr>
            <tr>
              {/* Email */}
              <th>이메일</th>
              <td>{mentoringApplication["user_email"]}</td>
            </tr>
            <tr>
              {/* Phone Number */}
              <th>전화 번호</th>
              <td>{mentoringApplication["user_mobile"]}</td>
            </tr>
            <tr>
              {/* Application Date */}
              <th>신청일자</th>
              <td>{new Date(mentoringApplication["application_dtm"]).toISOString().split("T")[0]}</td>
            </tr>
            <tr>
              {/* Approval Status */}
              <th>승인 상태</th>
              <td>
                <select
                  className="select"
                  defaultValue={mentoringApplication["mento_status"]}
                  {...register("mento_status")}>
                  <option value={"MS10"}>승인요청</option>
                  <option value={"MS20"}>승인완료</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* User Resume */}
      {loadingResume ? (
        <>
          사용자 이력서로드
          <i className="gg-spinner-alt" />
        </>
      ) : (
        resume && (
          <div className="adm_com_pg_content">
            <div className="sub_tit"> 신청 이력서</div>
            <table className="adm_com_board_list type_row">
              <colgroup>
                <col width="10%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  {/* Employment Type */}
                  <th>고용형태</th>
                  <td>{getEmploymentType(resume["resume_info"]["emp_type"], employmentTypes)}</td>
                </tr>
                <tr>
                  {/* Resume Salary */}
                  <th>희망연봉</th>
                  <td>{resume["resume_info"]["resume_salary"]}</td>
                </tr>
                <tr>
                  {/* Desired Work Location */}
                  <th>희망근무지</th>
                  <td>{getDesiredJob(resume["desired_job"], jobCategory).join(", ")}</td>
                </tr>
                <tr>
                  {/* Desired Work Location */}
                  <th>희망 직무</th>
                  <td>
                    {resume["desired_loca"].length > 0 &&
                      getLocations(resume["desired_loca"], locationCodes).join(", ")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )
      )}
      <div className="adm_com_btn_wrap center bottom">
        <button className="adm_com_btn gray" onClick={handleUpdate}>
          저 장 {isUpdating && <i className="gg-spinner-alt" />}
        </button>
        <button className="adm_com_btn gray" onClick={() => navigate(-1)}>
          뒤로가기
        </button>
      </div>
    </main>
  );
}
