/* eslint-disable */
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, EffectCoverflow, Controller, FreeMode, Scrollbar, Autoplay } from "swiper"; // 추가
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-coverflow";
import "swiper/css/effect-fade";
import "../../assets/scss/page/main.scss";
//image
import imgVisual from "../../assets/img/img_visual.png";
import imgVisualBanner from "../../assets/img/img_visual_banner.png";
import iconShare from "../../assets/img/icon_share.png";
import iconChat from "../../assets/img/icon_chatbot.png";
import bgManage01 from "../../assets/img/img_manage01.png";
import bgManage02 from "../../assets/img/img_manage02.png";
import bgManage03 from "../../assets/img/img_manage03.png";
import TopBanner from "../mentoring/_TopBanner";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import { getImageLink } from "../../../../../utils/misc";
import useCommCode from "../../../../../hooks/useCommCode";

function Index() {
  const navigate = useNavigate();

  const { data: mentorings } = useCustomQuery(`/mentoring?per_page=${10}&curr_page=${1}`, {
    queryKey: ["getMentoringApplication/"],
    select: (res) => res?.data?.data_list,
    refetchOnWindowFocus: false,
  });

  const { data: insights } = useCustomQuery(`/insight?per_page=${18}&curr_page=${1}`, {
    queryKey: ["getInsights/"],
    select: (res) => res?.data?.insights,
    refetchOnWindowFocus: false,
  });

  const { data: magazines } = useCustomQuery(`/magazine?curr_page=${1}&per_page=${10}`, {
    queryKey: ["getMagazineList"],
    select: (res) => res?.data?.magazines,
  });

  const { data: seminars } = useCustomQuery(`/seminar?page=${1}&limit=${10}`, {
    queryKey: ["getSeminarList"],
    select: (res) => res?.data?.seminars,
  });

  const { data: communities } = useCustomQuery(`/community?board_type=COMMTY&page=${1}&limit=${10}`, {
    queryKey: ["getCommunityList"],
    select: (res) => res?.data?.communities,
  });

  const { data: jobs } = useCustomQuery(`/recruit/list?curr_page=${1}&per_page=${6}`, {
    queryKey: ["getJobList"],
    select: (res) => res?.data?.data_list,
  });

  const { data: locationTypeData } = useCommCode(`LOCA_CD`);

  const getMagContent = (mag_content) => {
    const div = document.createElement("div");
    div.innerHTML = mag_content;
    const plainTextContent = div.innerText;
    return plainTextContent.split(" ").slice(0, 10).join(" ");
  };

  return (
    <main className="p_main">
      {/* visual */}
      <section className="sec_visual">
        <div className="com_center_wrap">
          <div className="swiper_wrap">
            <Swiper
              modules={[Pagination, Autoplay]}
              slidesPerView={1}
              pagination={{
                el: ".custom_pagination.visual",
                clickable: true,
                renderBullet: (index, className) => {
                  return '<span className="com_swiper_bullet ' + className + '"></span>';
                },
              }}
              autoplay={{
                delay: 1500,
                disableOnInteraction: false,
              }}
              loop={true}>
              <SwiperSlide>
                <img src={imgVisual} alt="엔지니어 채용 전문 플랫폼" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={imgVisual} alt="엔지니어 채용 전문 플랫폼" />
              </SwiperSlide>
            </Swiper>
            <div className="custom_pagination visual visual_pagination"></div>
          </div>
          <div className="banner">
            <Link to="/match/JobList">
              <img src={imgVisualBanner} alt="내 까다로운 조건과 성격에 맞는 회사는?" />
            </Link>
          </div>
        </div>
      </section>
      {/* long banner */}
      <TopBanner />
      {/* AI match */}
      <section className="sec_match">
        <div className="com_center_wrap">
          <div className="tit_wrap">
            <h2 className="tit">
              <span className="ballon">AI로 매칭</span>된 기업을 확인해보세요!
            </h2>
            <p className="sub_tit">나의 성향과 맞는 기업과 나와 기업의 관계를 한 눈에 확인할 수 있습니다!</p>
            <ul className="com_tag_list">
              <li className="com_btn line xs oval">대우가 좋은</li>
              <li className="com_btn line xs oval">커리어 상승</li>
              <li className="com_btn line xs oval">프리랜서</li>
              <li className="com_btn line xs oval">정규직</li>
            </ul>
            <Link to="/match/JobList" className="com_btn l black oval icon_arrow btn_more_bottom">
              AI매칭 분석 바로가기
            </Link>
          </div>
          <div className="swiper_wrap">
            <Swiper
              modules={[Pagination, EffectCoverflow, Autoplay]}
              pagination={{
                el: ".custom_pagination.match",
                clickable: true,
                renderBullet: (index, className) => {
                  return '<span className="com_swiper_bullet ' + className + '"></span>';
                },
              }}
              // autoplay={{
              //   delay: 1500,
              //   disableOnInteraction: false,
              // }}
              loop={true}
              effect="coverflow"
              grabCursor={true}
              slidesPerView={"auto"}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 2.5,
                slideShadows: false,
              }}
              breakpoints={{
                200: {
                  centeredSlides: true,
                },
                1200: {
                  centeredSlides: false,
                },
              }}>
              {jobs?.map((item, index) => (
                <SwiperSlide key={index}>
                  <div
                    className="slide_box"
                    style={{
                      height: "fit",
                    }}>
                    <p className="tit">{item?.recruit_title}</p>
                    <div className="info">
                      <p className="info_tit">
                        {" "}
                        {item?.recruit_consider} ·{" "}
                        {locationTypeData &&
                          locationTypeData.find((location) => location.comm_code == item?.recruit_location)
                            ?.code_nm_kr}{" "}
                        근무 마감: ~{item.close_type !== "ANY" && new Date(item?.close_date).toLocaleString()}
                      </p>
                      <p className="info_s">
                        {" "}
                        {item?.comp_name} <span className="icon">{item?.recruit_job}</span>
                      </p>
                      <ul className="com_tag_list">
                        {item?.recruit_tags &&
                          item?.recruit_tags?.split(",")?.map((tag, i) => (
                            <li key={i} className="com_btn xs">
                              #{tag}
                            </li>
                          ))}
                      </ul>
                    </div>
                    <Link to={`/match?recruit_seq=${item.recruit_seq}`} className="com_btn blue oval btn_match_more">
                      전체 채용공고 보기 →
                    </Link>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="custom_pagination match"></div>
          </div>
        </div>
      </section>
      {/* Mentoring */}
      <section className="sec_mentoring sec_bg">
        <div className="com_center_wrap">
          <div className="tit_wrap center">
            <h2 className="tit">
              성장하기 위한 준비! <span className="ballon">전문가 멘토</span> 와 함께 하세요.
            </h2>
            <Link to="/mentoring" className="com_btn l black oval icon_arrow">
              멘토링 신청하기
            </Link>
          </div>
          <div className="swiper_wrap">
            <Swiper
              modules={[Pagination, Navigation, Autoplay]}
              // autoplay={{
              //   delay: 1500,
              //   disableOnInteraction: false,
              // }}
              loop={true}
              navigation={{
                nextEl: ".mentor .swiper-button-next",
                prevEl: ".mentor .swiper-button-prev",
              }}
              pagination={{
                el: ".mentor .swiper_fraction",
                type: "custom",
                renderCustom: function (swiper, current, total) {
                  return (
                    '<span className="com_swiper_fraction current">' +
                    current +
                    '</span> / <span className="com_swiper_fraction">' +
                    total +
                    "<span>"
                  );
                },
              }}
              breakpoints={{
                280: {
                  slidesPerView: 1.2,
                  slidesPerGroup: 1,
                  slidesPerGroupAuto: true,
                  spaceBetween: 20,
                  centeredSlides: true,
                  loop: true,
                },
                360: {
                  slidesPerView: 1.5,
                  slidesPerGroup: 1,
                  slidesPerGroupAuto: true,
                  spaceBetween: 15,
                  centeredSlides: true,
                  loop: true,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                  slidesPerGroup: 1,
                  slidesPerGroupAuto: true,
                },
              }}>
              {mentorings &&
                mentorings?.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div
                      className="com_card_item"
                      onClick={() => navigate("/mentoring", { state: { selectedMentoring: item } })}>
                      <p className="thumb">
                        <img src={getImageLink(item.image_seq)} className="thumb_img" alt="mentoring1" />
                      </p>
                      <div className="info">
                        <span className="icon_line_blue">{item.cate_name}</span>{" "}
                        <p className="tit">{item.mento_title}</p>
                        <p className="info_s">
                          <span className="com_point_txt">{item.user_name}</span>
                          <span className="dot"></span>
                          <span className="info_txt">{item.mento_desc}</span>
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
            <div className="custom_pagination mentor">
              <div className="swiper-button-prev"></div>
              <div className="swiper_fraction"></div>
              <div className="swiper-button-next"></div>
            </div>
          </div>
        </div>
      </section>
      {/* Insight */}
      <section className="sec_insight">
        <div className="com_center_wrap">
          <div className="tit_wrap center">
            <h2 className="tit">
              경력있는 엔지니어의 <span className="ballon">인사이트</span> 를 공유하세요
            </h2>
            <Link to="/insight" className="com_btn l black oval icon_arrow">
              인사이트 공유 신청하기
            </Link>
          </div>
          <div className="swiper_wrap">
            <Swiper
              modules={[Pagination, Navigation, Autoplay]}
              // autoplay={{
              //   delay: 1500,
              //   disableOnInteraction: false,
              // }}
              loop={true}
              navigation={{
                nextEl: ".insight .swiper-button-next",
                prevEl: ".insight .swiper-button-prev",
              }}
              pagination={{
                el: ".insight .swiper_fraction",
                type: "custom",
                renderCustom: function (swiper, current, total) {
                  return (
                    '<span className="com_swiper_fraction current">' +
                    current +
                    '</span> / <span className="com_swiper_fraction">' +
                    total +
                    "<span>"
                  );
                },
              }}
              breakpoints={{
                280: {
                  slidesPerView: 1.2,
                  slidesPerGroup: 1,
                  slidesPerGroupAuto: true,
                  spaceBetween: 20,
                  centeredSlides: true,
                  loop: true,
                },
                360: {
                  slidesPerView: 1.5,
                  slidesPerGroup: 1,
                  spaceBetween: 15,
                  centeredSlides: true,
                  loop: true,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                  slidesPerGroup: 1,
                },
              }}>
              {insights?.map((item, index) => (
                <SwiperSlide key={index}>
                  <Link to={`/insight/${item.insig_seq}`} className="com_card_item">
                    <p className="thumb">
                      <img
                        src={Number(item.insig_thumb) > 0 ? getImageLink(item.insig_thumb) : item.media_thumb}
                        className="thumb_img"
                        alt="img"
                      />
                    </p>
                    <span className="icon_bg_blue">{item.insig_price}P</span>
                    <div className="info">
                      <span className="icon_line_blue">자동차 공학</span>
                      {item.tag_names.map((tag) => (
                        <span key={tag} className="icon_line_blue">
                          {tag}
                        </span>
                      ))}
                      <p className="tit">{item.insig_name}</p>
                      <p className="info_s">
                        <span className="com_point_txt">{item.user_name}</span>
                        <span className="dot" />
                        <span className="info_txt">
                          좋아요 <span className="cnt">{item.insig_likes}</span>
                        </span>
                        <span className="dot" />
                        <span className="info_txt">
                          댓글 <span className="cnt">{item.insig_comments}</span>
                        </span>
                        <button className="btn_share">
                          <img src={iconShare} alt="share" />
                        </button>
                      </p>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="custom_pagination insight">
              <div className="swiper-button-prev"></div>
              <div className="swiper_fraction"></div>
              <div className="swiper-button-next"></div>
            </div>
          </div>
        </div>
      </section>
      {/* Magazine */}
      <section className="sec_magazine sec_bg">
        <div className="com_center_wrap">
          <div className="tit_wrap">
            <h2 className="tit">
              언제나 타이밍이 중요
              <br />
              <span className="ballon">시대의 흐름</span>을 파악하세요!
            </h2>
            <p className="sub_tit">
              인기 매거진에서 시대의 흐름을 파악하세요~
              <br />
              <strong>다양한 엔지니어 분야의 정보</strong>를 <br />
              확인할 수 있습니다.
            </p>
            <Link to="/magazine" className="com_btn l black oval icon_arrow btn_more_bottom">
              매거진 더보기
            </Link>
          </div>
          <div className="swiper_wrap">
            <Swiper
              modules={[Pagination, EffectCoverflow, FreeMode, Autoplay]}
              pagination={{
                el: ".custom_pagination.magazine",
                clickable: true,
                renderBullet: (index, className) => {
                  return '<span className="com_swiper_bullet ' + className + '"></span>';
                },
              }}
              // autoplay={{
              //   delay: 1500,
              //   disableOnInteraction: false,
              // }}
              loop={true}
              effect="coverflow"
              grabCursor={true}
              slidesPerView="auto"
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 2.5,
                slideShadows: false,
              }}
              breakpoints={{
                200: {
                  centeredSlides: true,
                },
                1200: {
                  centeredSlides: false,
                },
              }}>
              {magazines?.map((item, index) => (
                <SwiperSlide key={index}>
                  <div
                    className="slide_box"
                    style={{
                      overflow: "hidden",
                    }}>
                    <p className="top_tit">COMPRESS</p>
                    <div className="thumb">
                      <img src={getImageLink(item.mag_image)} alt="img" />
                    </div>
                    <p className="tit">
                      {index} {item.mag_title}
                    </p>
                    <p className="desc">{getMagContent(item.mag_content)}</p>
                    <Link to={`/Magazine/${item.mag_seq}`} className="btn_more"></Link>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="custom_pagination magazine"></div>
          </div>
        </div>
      </section>
      {/* Seminar */}
      <section className="sec_seminar">
        <div className="com_center_wrap">
          <div className="tit_wrap center">
            <h2 className="tit">
              권위 있는 엔지니어들의 <br />
              <span className="ballon">오프라인 강연</span> 지금 참여해보세요!
            </h2>
            <Link to="/seminar" className="com_btn l black oval icon_arrow">
              세미나 더보기
            </Link>
          </div>
          <div className="swiper_wrap">
            <Swiper
              modules={[Pagination, Controller, Navigation, Scrollbar, Autoplay]}
              navigation={{
                nextEl: ".seminar .swiper-button-next",
                prevEl: ".seminar .swiper-button-prev",
              }}
              pagination={{
                el: ".seminar .swiper_fraction",
                type: "custom",
                renderCustom: function (swiper, current, total) {
                  return (
                    '<span className="com_swiper_fraction current">' +
                    current +
                    '</span> / <span className="com_swiper_fraction">' +
                    total +
                    "<span>"
                  );
                },
              }}
              autoplay={{
                delay: 1500,
                disableOnInteraction: false,
              }}
              loop={true}
              scrollbar={true}
              breakpoints={{
                360: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                  slidesPerGroup: 1,
                },
                1200: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                  slidesPerGroup: 1,
                },
              }}>
              {seminars?.map((item, index) => (
                <SwiperSlide key={index}>
                  <Link to={`/seminar/detail/${item.semin_seq}`} className="com_card_item">
                    <p className="thumb">
                      <img src={getImageLink(item.thumbnail)} className="thumb_img" alt="img" />
                    </p>
                    <div className="info">
                      <p className="tit">{item.semin_title}</p>
                      <p className="info_s">
                        <span className="info_txt">{new Date(item?.semin_date).toLocaleString()}</span>
                        <span className="info_txt"> 20명</span>
                      </p>
                      {new Date(item?.reg_end_dtm) >= new Date() ? (
                        <div className="com_btn line s oval">참가신청</div>
                      ) : (
                        <div className="com_btn disabled">신청완료</div>
                      )}
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="custom_pagination seminar">
              <div className="swiper-scrollbar"></div>
              <div className="swiper-button-prev"></div>
              <div className="swiper_fraction"></div>
              <div className="swiper-button-next"></div>
            </div>
          </div>
        </div>
      </section>
      {/* Community */}
      <section className="sec_community sec_bg">
        <div className="com_center_wrap">
          <div className="tit_wrap center">
            <h2 className="tit">
              성장을 위한 가장 확실한 방법은 <br />
              뛰어난 동료들과<span className="ballon">소통</span> 하는 것입니다.
            </h2>
            <Link to="/community" className="com_btn l black oval icon_arrow">
              커뮤니티 더보기
            </Link>
          </div>
          <div className="swiper_wrap">
            <Swiper
              modules={[Pagination, Autoplay]}
              autoplay={{
                delay: 1500,
                disableOnInteraction: false,
              }}
              loop={true}
              pagination={{
                el: ".custom_pagination.community",
                clickable: true,
                renderBullet: (index, className) => {
                  return '<span className="com_swiper_bullet ' + className + '"></span>';
                },
              }}
              breakpoints={{
                360: {
                  slidesPerView: 1.1,
                  spaceBetween: 15,
                  slidesPerGroup: 1,
                  slidesPerGroupAuto: true,
                },
                968: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                  slidesPerGroup: 2,
                  slidesPerGroupAuto: true,
                },
              }}>
              {communities?.map((item, index) => (
                <SwiperSlide key={index}>
                  <div
                    className="com_card_item"
                    onClick={() => navigate(`/community/detail/${item.board_seq}`)}
                    style={{
                      cursor: "pointer",
                    }}>
                    <div className="info">
                      <p className="tit">{item.board_title}</p>
                      <p className="desc">{item.board_contents}</p>
                      <p className="info_s">
                        <span className="com_point_txt">홍길동</span> <span className="dot" />
                        <span className="info_txt">
                          {item.user_name} <span className="cnt">{item.favor_cnt}</span>
                        </span>
                        <span className="dot" />
                        <span className="info_txt">
                          댓글 <span className="cnt">{item.comnt_cnt}</span>
                        </span>
                      </p>
                    </div>
                    <span className="icon_chat">
                      <img src={iconChat} alt="chat bot" />
                    </span>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="custom_pagination community"></div>
          </div>
        </div>
      </section>
      {/* 1:1 Managing */}
      <section className="sec_managing">
        <div className="com_center_wrap">
          <div className="tit_wrap center">
            <h2 className="tit">당신만을 위한 1:1 매니징 서비스!</h2>
          </div>
          <ul className="list">
            <li className="item">
              <div className="box">
                <div className="info">
                  <p className="tit">서류부터 실제 면접 준비까지전문가가 도와드려요!</p>
                  <div className="info_txt">
                    엔지니어 경력자들이 대거 포진해 있는 엔지니어스에서 제안서도 작성해드려요!
                  </div>
                </div>
                <p className="bg">
                  <img src={bgManage01} className="bg_img" alt="bg" />
                </p>
              </div>
            </li>
            <li className="item">
              <div className="box">
                <div className="info">
                  <p className="tit">엔지니어스만의 시스템 적극구직을 이용해보세요.</p>
                  <div className="info_txt">최대한 빨리 취업하고 싶을때 좋아요!</div>
                </div>
                <p className="bg">
                  <img src={bgManage02} className="bg_img" alt="bg" />
                </p>
              </div>
            </li>
            <li className="item">
              <div className="box">
                <div className="info">
                  <p className="tit">이력서를 바탕으로 전문가가 직접 작성하는 구인 제안서</p>
                  <div className="info_txt">
                    엔지니어 경력자들이 대거 포진해 있는 엔지니어스에서 제안서도 작성해드려요!
                  </div>
                </div>
                <p className="bg">
                  <img src={bgManage03} className="bg_img" alt="bg" />
                </p>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </main>
  );
}

export default Index;
