import React, { useState } from "react";
//component
import Pagination from "../../../components/Pagination";
import { useCustomQuery } from "../../../../../../hooks/useAPI";
import useCommCode from "../../../../../../hooks/useCommCode";
import moment from "moment";

function MatchRecruit() {
  const [applicationPerPage, setApplicationPerPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);

  const [totalApplicationCount, setTotalApplicationCount] = useState(0);
  const [applicationList, setApplicationList] = useState([]);

  const { data: applyStatusData } = useCommCode("SUPP_STATUS");
  console.log(applyStatusData);

  useCustomQuery(`/application/list?per_page=${applicationPerPage}&curr_page=${currentPage}&user_type=PSN`, {
    queryKey: ["getApplicationList", applicationPerPage, currentPage],
    select: (res) => res?.data,
    onSuccess: (data) => {
      // console.log(data);
      setTotalApplicationCount(data.total_cnt);
      setApplicationList(data.data_list);
    },
    refetchOnWindowFocus: false,
  });

  const handleNext = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setCurrentPage((prev) => prev - 1);
  };

  return (
    <div>
      <section className="com_board_search">
        <select className="seclect">
          <option value="all">전체</option>
          {applyStatusData &&
            applyStatusData.map((el, i) => (
              <option key={i} value={el.comm_code}>
                {el.code_nm_kr}
              </option>
            ))}
        </select>
        <article className="date_select type_btns">
          <button className="com_btn blue line s">전체</button>
          <button className="com_btn lightgray s">1개월</button>
          <button className="com_btn lightgray s">3개월</button>
        </article>
        <article className="date_select type_input">
          <input type="date" className="com_datepicker" placeholder="2022. 01. 01" />
          <input type="date" className="com_datepicker" placeholder="2022. 01. 01" />
          <button className="com_btn blue s">조회</button>
        </article>
        <article className="search">
          <input type="text" placeholder="검색할 내용을 입력해 주세요." />
          <button className="com_btn blue s">조회</button>
        </article>
      </section>
      <table className="com_board_list">
        <colgroup>
          <col width="16%" />
          <col />
          <col width="13%" />
          <col width="13%" />
          <col width="13%" />
        </colgroup>
        <thead>
          <tr>
            <th>지원 회사</th>
            <th>지원 공고</th>
            <th>지원일</th>
            <th>고용형태</th>
            <th>상태</th>
          </tr>
        </thead>
        <tbody>
          {applicationList &&
            applicationList.map((list) => (
              <tr key={list.recruit_seq}>
                <td className="company_field">{list.com_name}</td>
                <td className="tit_field">{list.recruit_title}</td>
                <td className="date_field">
                  <span className="th_m">지원일 :</span>
                  {moment(list.reg_dtm).format("YY.MM.DD")}
                </td>
                <td className="type_field">{list.emp_type_kr}</td>
                <td className="state_field">
                  {applyStatusData.find((emp_type) => emp_type["comm_code"] === list["appli_status"])["code_nm_kr"]}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(totalApplicationCount / applicationPerPage)}
        onNextClick={handleNext}
        onPrevClick={handlePrevious}
        perPage={applicationPerPage}
        handlePerPageChange={(e) => setApplicationPerPage(e.target.value)}
      />
    </div>
  );
}

export default MatchRecruit;
