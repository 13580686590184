import React, { useEffect, useState } from "react";
import Modal from "../modal";

function PopDesiredWork(props) {
  const { open, close, handleAddDesiredWork, locationTypeData, desiredWork } = props;

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCheckboxChange = (id) => {
    if (selectedOptions.includes(id)) {
      setSelectedOptions((prevOptions) => prevOptions.filter((option) => option !== id));
    } else if (selectedOptions.length < 3) {
      setSelectedOptions((prevOptions) => [...prevOptions, id]);
    }
  };

  const handleAddButtonClick = () => {
    handleAddDesiredWork(selectedOptions);

    close();
  };

  useEffect(() => {
    setSelectedOptions(desiredWork);
  }, [desiredWork]);

  return (
    <Modal open={open} close={close} type="pop_desired_work" popSize="s">
      <h2 className="pop_tit type_center">희망근무지 추가</h2>
      <div className="content_container">
        <div className="list">
          {locationTypeData?.map((option) => (
            <React.Fragment key={option.comm_code}>
              <input
                type="checkbox"
                className="com_chk type_btn"
                id={option.comm_code}
                checked={selectedOptions?.includes(option)}
                onChange={() => handleCheckboxChange(option)}
              />
              <label htmlFor={option.comm_code}>{option.code_nm_kr}</label>
            </React.Fragment>
          ))}
        </div>
        <section className="com_btn_wrap">
          <button className="com_btn blue s" onClick={handleAddButtonClick}>
            추가
          </button>
          <button className="com_btn line blue s" onClick={close}>
            취소
          </button>
        </section>
      </div>
    </Modal>
  );
}

export default PopDesiredWork;
