import React, { useEffect, useState } from "react";
import Paging from "../../component/Paging";
import { useAuthenticatedQuery } from "../../../../../hooks/useAPI";
import Table from "../../../screens/components/boffice/Table";
import { queryBackend } from "../../../../../api";

const columns = {
  selected: "선택",
  admin_seq: "번호",
  admin_id: "아이디",
  admin_username: "이름",
  admin_last_access_date: "마지막 접속일자",
  permissions: "접근권한",
  active: "접근권한",
  // * If it is a link, it passes an object
  detail: {
    label: "관리",
    getLink: (item) => "/boffice/setting/manager/" + item["admin_seq"],
    linkLabel: "상세",
  },
};

export default function AdminList() {
  const [page, setPage] = useState(1);
  const [memberPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [isFetching, setIsFetching] = useState(false);

  const [adminList, setAdminList] = useState([]);

  const { data, isFetching: queryFetching } = useAuthenticatedQuery(
    ["getAdminList/" + page, memberPerPage],
    `/admin/list?per_page=${memberPerPage}&curr_page=${page}&`,
  );

  useEffect(() => {
    const updateData = async (data) => {
      setIsFetching(true);

      const response = await data.json();

      const permissions = await queryBackend("/admin/list-permissions").then(async (data) => (await data.json()).data);
      if (response.messageCode === 200) {
        setTotalCount(response.data.total_cnt);
        setAdminList(
          response.data.data_list.map((admin) => ({
            ...admin,
            permissions: admin.permissions
              .split(",")
              .map((permission) => permissions.find((p) => p.permission_name === permission)["permission_title"])
              .join(", "),
          })),
        );
      }
      setIsFetching(false);
    };

    !isFetching && data && !data.bodyUsed && updateData(data);
  }, [data, isFetching]);

  const handlePageClick = (toPage) => {
    if (Number.isInteger(toPage)) return setPage(toPage);

    switch (toPage) {
      case "first": {
        setPage(1);
        break;
      }
      case "next": {
        page < Math.floor(totalCount / memberPerPage) && setPage((prev) => prev + 1);
        break;
      }
      case "prev": {
        page > 1 && setPage((prev) => prev - 1);
        break;
      }
      case "last": {
        setPage(Math.ceil(totalCount / memberPerPage));
        break;
      }
    }
  };

  // 부관리자 선택하기
  const handleCheck = (index) => {
    const updatedAdminList = [...adminList];
    updatedAdminList[index].selected = !updatedAdminList[index].selected;
    setAdminList(updatedAdminList);
  };

  return (
    <main>
      {(isFetching || queryFetching) && <i className="gg-spinner-alt" />}
      <h2 className="adm_com_pg_tit2 bottom_line">운영자 관리</h2>
      <div className="adm_com_pg_content">
        <h2 className="sub_tit">- 부관리자 계정 관리</h2>
        <div className="adm_table_btn flex-end">
          <button className="adm_com_btn gray">삭제</button>
          <button className="adm_com_btn gray">등록하기</button>
        </div>
        <Table data={adminList} columns={columns} checked={true} handleCheck={handleCheck} />
        <Paging handlePageClick={handlePageClick} page={page} totalPageCount={Math.ceil(totalCount / memberPerPage)} />
      </div>
    </main>
  );
}
