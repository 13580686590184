import React from "react";
import { Routes, Route } from "react-router-dom";
import "../views/boffice/assets/scss/styles.scss";

import CategoryList from "../views/boffice/page/magazine/category/List";
import NewCategory from "../views/boffice/page/magazine/category/NewCategory";
import CategoryDetail from "../views/boffice/page/magazine/category/CategoryDetail";
import AdminMagazineList from "../views/boffice/page/magazine/List";
import MagazineDetail from "../views/boffice/page/magazine/Detail";

export default function RouterBofficeMagazine() {
  // Authentication logic is done in the router, for now the /screens
  return (
    <Routes>
      <Route path="/list" element={<AdminMagazineList />} />
      <Route path="/details/:id" element={<MagazineDetail />} />
      <Route path="/cate" element={<CategoryList />} />
      <Route path="/cate/new" element={<NewCategory />} />
      <Route path="/cate/:cate_seq" element={<CategoryDetail />} />
    </Routes>
  );
}
