import React from "react";
import { Route, Routes } from "react-router-dom";

import Privacy from "../views/screens/page/etc/Privacy";
import Agree from "../views/screens/page/etc/Agree";

function EtcRoutes() {
  return (
    <>
      <Routes>
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Agree />} />
      </Routes>
    </>
  );
}

export default EtcRoutes;
