import React from "react";
import "../../assets/scss/page/etc.scss";
function Terms() {
  return (
    <>
      <main className="com_term">
        <div className="com_center_wrap">
          <h2 className="pg_tit">마케팅 및 광고수신 동의서</h2>
          <div className="box">
            <dl className="sec">
              <dd className="txt">
                엔지니어스(이하 회사)의 서비스 및 상품 안내, 이벤트 행사 관련 정보 안내 목적으로 이메일, SMS를 통한 상품 및 이벤트 정보 송신을 위해 이용하고자 합니다. 마케팅 및 광고수신을 원하지 않을 경우 동의하지 않으셔도 됩니다.
                <table className="table">
                  <thead>
                    <tr>
                      <th>수집 • 이용 목적</th>
                      <th>수집항목</th>
                      <th>보유 및 이용기간</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>상품안내, 이벤트 행사 관련 정보 안내</td>
                      <td>이름, 핸드폰번호, 이메일, SNS</td>
                      <td>회원탈퇴 또는 고객 삭제 요청시까지</td>
                    </tr>
                  </tbody>
                </table>
              </dd>
              <dd className="txt">
                위의 개인정보 수집 • 이용에 대한 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우 상품 및 이벤트 정보를 받을 수 없습니다.
              </dd>
            </dl>
            {/* <div className="agree_box">
              <p className="txt">개인정보 수집 • 이용에 동의합니까?</p>
              <div className="check_box">
                <input
                  type="radio"
                  className="com_chk circle"
                  id="pop_agree01"
                  name="pop_agree"
                />
                <label htmlFor="pop_agree01">
                  동의
                </label>
                <input
                  type="radio"
                  className="com_chk circle"
                  id="pop_agree02"
                  name="pop_agree"
                />
                <label htmlFor="pop_agree02">
                  동의하지 않음
                </label>
              </div>
            </div> */}
          </div>
        </div>
      </main>
    </>
  );
}

export default Terms;
