import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../../context/Auth";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import "../../assets/scss/page/member.scss";
import Pagination from "../../components/Pagination";
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";

const tab = [
  { text: "최근 본 글", value: "VIEWED" },
  { text: "작성글", value: "POSTED" },
  { text: "작성댓글", value: "COMMENTED" },
  { text: "좋아요", value: "LIKED" },
];

function MyCommunity() {
  const { user } = useContext(AuthContext);

  const [user_action, setUserAction] = useState(tab[0]?.value);
  const [per_page, setPerPage] = useState(3);
  const [curr_page, setCurrPage] = useState(1);

  const { data: community = [] } = useCustomQuery(
    `/community/mypage?user_seq=${user?.user_seq}&user_action=${user_action}&page=${curr_page}&limit=${per_page}`,
    {
      queryKey: ["getUserCommunityList", { user_seq: user?.user_seq, user_action, curr_page, per_page }],
      select: (res) => res?.data,
    },
  );

  const handleNextClick = () => {
    if (curr_page < Math.ceil(community?.total_count / per_page)) {
      setCurrPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevClick = () => {
    if (curr_page > 1) {
      setCurrPage((prevPage) => prevPage - 1);
    }
  };

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value, 10));
    setCurrPage(1);
  };
  console.log(community.communities);

  return (
    <>
      <div className="container">
        <h2 className="com_pg_tit">My 커뮤니티</h2>
        <section className="com_pg_tab">
          {tab.map((v, idx) => (
            <button
              key={idx}
              className={`tab ${user_action === v.value && "active"}`}
              onClick={() => {
                setUserAction(v.value);
                setCurrPage(1);
              }}>
              {v.text}
            </button>
          ))}
        </section>

        <div className="wrap">
          {community?.communities?.length > 0 &&
            community?.communities?.map((obj) => (
              <Link to={`/community/detail/${obj.board_seq}`} key={obj.board_seq} className="com_community_card">
                <div className="info">
                  <div className="profile">
                    <p className="name">{obj.user_nickname}</p>
                    <span className="desc">{obj.reg_dtm}</span>
                  </div>
                  <div className="tit">{obj.board_title}</div>
                  <div className="description">{obj.board_contents}</div>
                  <div className="comment_box">
                    <span className="com_icon eye">{obj.hit_cnt}</span>
                    <span className={`com_icon ${obj?.likedBy?.includes(user?.user_seq) ? "liked" : "like"}`}>
                      {obj.favor_cnt}
                    </span>
                    <span className="com_icon comment">{obj.comnt_cnt}</span>
                  </div>
                </div>
              </Link>
            ))}
        </div>
        <Pagination
          currentPage={curr_page}
          totalPages={Math.ceil(community?.total_count / per_page)}
          onNextClick={handleNextClick}
          onPrevClick={handlePrevClick}
          perPage={per_page}
          handlePerPageChange={handlePerPageChange}
          pageSizeCounts={[3, 6, 9, 15, 30]}
        />
      </div>
      <ScrollToTopButton />
    </>
  );
}

export default MyCommunity;
