import React from "react";
import { Link, useParams } from "react-router-dom";
import { useCustomQuery } from "../../../../../../hooks/useAPI";
import Tag from "../../../../screens/components/company/recruit/Tag";

export default function AdminSeminarRefundRequestDetails() {
  const { id = "new" } = useParams();
  const { data = {} } = useCustomQuery(`/seminar/refund-requests?semin_apl_seq=${id}`, {
    queryKey: ["getAdminSeminarRefundApplicationList", id],
    select: (res) => res?.data?.seminarRefundApplications?.[0] ?? {},
    enabled: +id > 0,
    refetchOnWindowFocus: false,
  });

  const handleRefundReject = async () => {
    console.log("Handle Refund Reject Logic");
  };

  const handleRefundAccept = async () => {
    console.log("Handle Refund Accept Logic");
  };

  return (
    <main>
      <h1 className="adm_com_pg_tit2">세미나 관리</h1>

      <h2 className="adm_com_pg_tit">
        <Link to={"/boffice/member/manage/company"} style={{ color: "#034da2", paddingBottom: "10px" }}>
          세미나 환불 내역
        </Link>{" "}
        {" > "} 세부 사항
      </h2>

      <div className="adm_com_pg_content">
        <table className="adm_com_board_list type_row">
          <colgroup>
            <col width="13%" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th>세미나 명</th>
              <td>
                <span>{data?.semin_title}</span>
              </td>
            </tr>
            <tr>
              <th>부제목</th>
              <td>
                <span>{data?.semin_sub_title}</span>
              </td>
            </tr>
            <tr>
              <th>태그</th>
              <td>
                <span>{data?.semin_tag?.split(",")?.map((tag) => tag && <Tag key={tag} tag={tag} />)}</span>
              </td>
            </tr>
            <tr>
              <th>썸네일 이미지</th>
              <td>
                <span>{data?.thumbnail}</span>
              </td>
            </tr>
            <tr>
              <th>장소</th>
              <td>
                <span>{data?.semin_address}</span>
                <span>{data?.semin_address_details}</span>
              </td>
            </tr>
            <tr>
              <th>정원</th>
              <td>
                <span>{data?.semin_limit}</span>
              </td>
            </tr>
            <tr>
              <th>가격</th>
              <td>
                <span>{data?.semin_price}</span>
              </td>
            </tr>
            <tr>
              <th>일정</th>
              <td>
                <span>{data?.semin_date}</span>
              </td>
            </tr>
            <tr>
              <th>신청기간</th>
              <td>
                {data?.reg_start_dtm} - {data?.reg_end_dtm}
              </td>
            </tr>
            <tr>
              <th>노출여부</th>
              <td>
                <span>{data?.semin_status}</span>
              </td>
            </tr>
            <tr>
              <th>상세내용</th>
              <td>
                <span>{data?.semin_content}</span>
              </td>
            </tr>
            <tr>
              <th>참여신청/취소 안내</th>
              <td>
                <span>{data?.semin_terms}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <h2 className="adm_com_pg_tit">세미나 신청자 정보</h2>
      <div className="adm_com_pg_content">
        <table className="adm_com_board_list type_row">
          <colgroup>
            <col width="13%" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th>신청자 아이디</th>
              <td>
                <span>{data?.semin_apl_seq}</span>
              </td>
            </tr>
            <tr>
              <th>이름</th>
              <td>
                <span>{data?.user_id}</span>
              </td>
            </tr>
            <tr>
              <th>연락처</th>
              <td>
                <span>{data?.user_mobile}</span>
              </td>
            </tr>
            <tr>
              <th>회원유형</th>
              <td>
                <span>{data?.user_type}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <h2 className="adm_com_pg_tit">결제정보</h2>
      <div className="adm_com_pg_content">
        <table className="adm_com_board_list type_row">
          <colgroup>
            <col width="13%" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th>신청 인원 수</th>
              <td>
                <span>{data?.join_num}</span>
              </td>
            </tr>
            <tr>
              <th>신청일자</th>
              <td>
                <span>{data?.application_date}</span>
              </td>
            </tr>
            <tr>
              <th>결제금액</th>
              <td>
                <span>{data?.goods_amt}</span>
              </td>
            </tr>
            <tr>
              <th>사용한 이벤트 포인트</th>
              <td>
                <span>0P</span>
              </td>
            </tr>
            <tr>
              <th>사용한 일반 포인트</th>
              <td>
                <span>{data?.use_point}P</span>
              </td>
            </tr>
            <tr>
              <th>총 결제금액</th>
              <td>
                <span>{data?.pay_amt}</span>
              </td>
            </tr>
            <tr>
              <th>결제일자</th>
              <td>
                <span>{data?.pay_dtm}</span>
              </td>
            </tr>
            <tr>
              <th>취소/환불일자</th>
              <td>
                <span>{data?.up_dtm}</span>
              </td>
            </tr>
            <tr>
              <th>결제상태</th>
              <td>
                <select disabled value={data?.pay_status}>
                  <option value="PS10">결제완료</option>
                  <option value="PS00">보류 중인 결제</option>
                  <option value="PS90">환불 결제</option>
                  <option value="cancel">결제 취소</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>환불사유내용</th>
              <td>
                <span>{data?.refund_reason}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="adm_com_btn_wrap center bottom">
          <Link className="adm_com_btn gray" to="/boffice/board/seminar/refund-request/list">
            목록
          </Link>
          <button className="adm_com_btn red" onClick={handleRefundReject}>
            돌아가기
          </button>
          <button className="adm_com_btn green" onClick={handleRefundAccept}>
            수정하기
            {/* {isLoading && <i className="gg-spinner-alt" />} */}
          </button>
        </div>
      </div>
    </main>
  );
}
