import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "../../assets/scss/page/member.scss";
//component
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import { AuthContext } from "../../../../../context/Auth";

function MyResume() {
  /*
  * 2023-11-24 성향 작성 여부에 따라 작성 유도 또는 결과(추천)
  */

  const { user } = useContext(AuthContext);

  //2023-11-15 이력서(성향) 작성률
  const { data: myQual } = useCustomQuery("/user/qualification?qual_type=recruit&resume_seq=0", {
    queryKey: ["getQual"],
    select: (res) => res?.data,
    enabled: true
  });

  return (
    <>
      <div className="container">
        <h2 className="com_pg_tit">이력서</h2>

        <div className="input_description_box">
          <div className="com_resume_progress">
            <p className="tit">이력서 완성도</p>
            <div className="progress_bar tit text_blue">
              <span className="progress">
                <span className="filled" style={{ width: `${myQual?.qualScore}%` }}></span>
              </span>
              {myQual?.qualScore}%
            </div>
          </div>
        </div>

        <section className="com_pg_tab">
          <Link to="/mypage/MyResume" className="tab">
            나의 이력서
          </Link>
          <Link to="/mypage/MyFit2" className="tab active">
            나의 성향
          </Link>
          <Link to="/mypage/Attachment" className="tab">
            첨부파일
          </Link>
        </section>
        <div className="input_description_box">
          {(myQual?.tendency_ratio > 0) ? (
            <div className="com_resume_progress">
              <section className="com_btn_wrap">
                <h2>나의성향 설문 결과</h2> &nbsp; &nbsp;
                <Link to="/mypage/MyFit" className="com_btn blue xs oval btn_my">
                  나의성향 설문 다시하기
                </Link>
              </section>
              <h3>{user.user_name}님의 성향과 매칭되는 8개 기업을 찾았습니다.</h3>
              <ul>
                <li>조직적합성 매칭기업 : 1개</li>
                <li>직무적합성 매칭기업 : 5개</li>
                <li>복리후생 매칭기업 : 2개</li>
              </ul>
              <Link to="/match" className="com_btn blue xs oval btn_my">
                매칭기업 보러가기
              </Link>
            </div>
          ) : (
            <div className="com_resume_progress">
              나와 잘 맞는 기업이 얼마나 있을지 궁굼한가요?<br />
              나의성향 설문지를 작성해 보세요.
              <Link to="/mypage/MyFit" className="com_btn blue xs oval btn_my">
                나의성향 설문하러 가기
              </Link>
            </div>
          )}
        </div>
      </div>
      {/* top button */}
      <ScrollToTopButton />
    </>
  );
}

export default MyResume;
