import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

import Modal from "../../components/modal";
import PopAgree from "../../components/PopAgreement";
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import { mutateBackend } from "../../../../../api";
import { addComma } from "../../../../../utils/misc";

import { toast } from "react-toastify";

function Pay() {
  const [modalOpen, setModalOpen] = useState(false);
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const [modalAgreeOpen, setModalAgreeOpen] = useState(false);
  const [usedPoints, setUsedPoints] = useState(0);
  const [termsAgree, setTermsAgree] = useState(false);

  const { insight_seq } = useParams();

  const { data: insight } = useCustomQuery(`/insight/${+insight_seq}`, {
    queryKey: ["getInsightDetail", { insight_seq }],
    select: (res) => res?.data,
  });

  //보유 포인트
  const { data: myPoints = {}, refetch } = useCustomQuery(`/points/current`, {
    queryKey: ["getPoints"],
    select: (res) => res?.data,
    enabled: !!+insight_seq,
  });

  const handlePayment = async () => {
    //금액 체크
    if( Number(insight?.insig_price) !== Number(usedPoints) ){
      toast.error("상품 금액을 확인 후 다시 입력하세요.");
      return;
    }

    if (!termsAgree) {
      toast.error("구매조건 및 결제대행 서비스 약관에 동의하여야 합니다.(필수)");
      return;
    }

    /* 2023-11-17 end-point 변경
    const response = await mutateBackend("/payments", {
      data: {
        pay_status: "PS10",
        goods_type: "INSIGHT",
        goods_seq: insight?.insig_seq,
        goods_name: insight?.insig_name,
        dc_amt: 0,
        use_point: usedPoints,
        pay_method: "POINTS",
        pay_amt: 0,
        trans_id: "",
        join_num: 1,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
    */
    const response = await mutateBackend("/insight/buy", {
      data: {
        insig_seq: insight?.insig_seq
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (data) => await data.json())
      .catch((err) => {
        throw new Error(err);
      });
    console.log('response', response);
    if (response.messageString === "SUCCESS" && response.messageCode === 200) {
      console.log(response.data);
      //await handleUpdateUserPoint(response.data); 2023-11-04 사용안함 - KSR
      setModalOpen(true);
    } else if(response.messageCode === 999) {
      toast.error(response.data);
      refetch();
    } else {
      console.log(response);
    }
  };

  return (
    <main className="com_pay">
      <section className="sec_payment">
        <h2 className="pg_tit">인사이트 결제하기</h2>
        <div className="box">
          <ul className="com_info_sec">
            <li className="field_box">
              <span className="tit">인사이트 상품명</span>
              <div className="info_box">{insight?.insig_name}</div>
            </li>
            <li className="field_box">
              <span className="tit">판매자</span>
              <div className="info_box">{insight?.user_name}</div>
            </li>
            <li className="field_box">
              <span className="tit">구매일자</span>
              <div className="info_box">{new Date().toLocaleString()}</div>
            </li>
            <li className="field_box">
              <span className="tit">영상 시청기간</span>
              <div className="info_box">구매일로부터 {insight?.media_duration}일 간 시청가능</div>
            </li>
            <li className="field_box">
              <span className="tit">상품금액</span>
              <div className="info_box">{addComma(insight?.insig_price)}원</div>
            </li>
            <li className="field_box">
              <span className="tit">보유 포인트</span>
              <div className="info_box">{addComma(myPoints?.points) || 0}P
                {myPoints?.points < insight?.insig_price ? (
                  <span className="block_m" style={{color:'red',}}>(보유 포인트가 부족합니다.)</span>
                  ) : ''
                }
              </div>
            </li>
            {/* 2023-11-04 이벤트포인트 기능 보류 - KSR
            <li className="field_box">
              <span className="tit">이벤트 포인트</span>
              <div className="info_box">2000P</div>
            </li>
            */}
            <li className="field_box">
              <span className="tit">사용 포인트</span>
              <div className="info_box">
                <input type="number" className="input_num" onChange={(e) => setUsedPoints(e.target.value)} /> P{" "}
                {/*
                <span className="block_m">(이벤트 포인트가 먼저 차감됩니다)</span>
                */}
              </div>
            </li>
            {/* 2023-11-04 포인트로만 결제하므로 불필요 - KSR
            <li className="field_box">
              <span className="tit">총 결제금액</span>
              <div className="info_box">{insight.insig_price}원</div>
            </li>
          */}
          </ul>
          <section className="com_agree_section">
            <ul className="list">
              <li className="item">
                <div>
                  <input
                    type="checkbox"
                    className="com_chk"
                    checked={termsAgree}
                    id="agree01"
                    onChange={(e) => setTermsAgree(e.target.checked)}
                  />
                  <label htmlFor="agree01">
                    구매조건 및 결제대행 서비스 약관에 동의하여야 합니다.<span className="txt_required">(필수)</span>{" "}
                    <br />
                    구매조건 및 개인정보취급방침에 동의합니다.
                  </label>
                </div>
                <button className="com_btn txt" onClick={() => setModalAgreeOpen(true)}>
                  자세히
                </button>
              </li>
            </ul>
          </section>
          <div className="com_btn_wrap center">
            {/*
            <Link to="/Insight" className="com_btn line l">
              이전으로
            </Link>
            */}
            {myPoints?.points >= insight?.insig_price ? (
              <button className="com_btn blue l" onClick={handlePayment}>
                결제하기
              </button>
            ) : ''
            }
          </div>
        </div>
      </section>
      {/* top button */}
      <ScrollToTopButton />
      {/* modal */}
      {/*약관내용 팝업*/}
      <PopAgree open={modalAgreeOpen} close={() => setModalAgreeOpen(false)} title="이용약관" type="terms" />
      {/* alert */}
      <Modal open={modalOpen} close={handleCloseModal} type="type_alert">
        <div className="content_container scroll">
          <strong>결제가 완료되었습니다!</strong> <br />
          동영상은 결제 인사이트 목록에서 <br /> 확인 하실 수 있습니다.
        </div>
        <div className="btn_container">
          <Link to="/insight" className="com_btn blue s">
            확인
          </Link>
        </div>
      </Modal>
    </main>
  );
}

export default Pay;
