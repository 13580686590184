import React, { useContext, useState } from "react";
import Pagination from "../../components/Pagination";
import { AuthContext } from "../../../../../context/Auth";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import RecruitStatus from "./RecruitStatus";

const StatusPage = Object.freeze({
  // SUPP_STATUS	SS10	지원	application
  // SUPP_STATUS	SS15	열람	readed
  // SUPP_STATUS	SS20	서류통과	pass document
  // SUPP_STATUS	SS30	면접제안	request interview
  // SUPP_STATUS	SS50	합격	pass
  // SUPP_STATUS	SS90	서류거절	reject document
  // SUPP_STATUS	SS99	불합격	fail
  Root: "Root",
  Applied: "SS10",
  PassedDocument: "SS20",
  RejectedDocument: "SS90",
  Passed: "SS50",
  Rejected: "SS99",
});

export default function RecruitmentStatus() {
  const { user } = useContext(AuthContext);

  const [tabState, setTabState] = useState(StatusPage.Root);

  const [currentRecruit, setCurrentRecruit] = useState(0);

  const [recruitsPerPage, setRecruitsPerPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);

  const [totalRecruitCount, setTotalRecruitCount] = useState(0);
  const [recruitList, setRecruitList] = useState([]);

  const { refetch } = useCustomQuery(
    `/recruit/list?per_page=${recruitsPerPage}&curr_page=${currentPage}&comp_seq=${user.comp_seq}`,
    {
      queryKey: ["getRecruitList", recruitsPerPage, currentPage, user.comp_seq],
      select: (res) => res?.data,
      onSuccess: (data) => {
        setTotalRecruitCount(data.total_cnt);
        setRecruitList(data.data_list);
      },
    },
  );

  const handleNext = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setCurrentPage((prev) => prev - 1);
  };

  return tabState == StatusPage.Root ? (
    <div className="tab_content recruit_status">
      <div className="input_wrap">
        <input type="text" className="input" />
        <button className="com_btn bg blue s btn_search">조회</button>
      </div>
      <ul className="status_list">
        {!recruitList ? (
          <i className="gg-spinner-alt" />
        ) : (
          recruitList.map((item, index) => (
            <li className="item" key={index}>
              <div className="tit_box">
                <span className="status">모집중</span>
                <span className="tit">{item["recruit_title"]}</span>
              </div>
              <div className="list_box input_box">
                <div className="status_wrap">
                  <div
                    onClick={() => {
                      setCurrentRecruit(item["recruit_seq"]);
                      setTabState(StatusPage.Applied);
                    }}
                    className="status_link active">
                    <span>지원중 {item["SS10"]}명</span>
                  </div>
                  <div
                    onClick={() => {
                      setCurrentRecruit(item["recruit_seq"]);
                      setTabState(StatusPage.PassedDocument);
                    }}
                    className="status_link active">
                    <span>서류통과 {item["SS20"]}명</span>
                  </div>
                  <div
                    onClick={() => {
                      setCurrentRecruit(item["recruit_seq"]);
                      setTabState(StatusPage.RejectedDocument);
                    }}
                    className="status_link active">
                    <span>서류거절 {item["SS90"]}명</span>
                  </div>
                  <div
                    onClick={() => {
                      setCurrentRecruit(item["recruit_seq"]);
                      setTabState(StatusPage.Passed);
                    }}
                    className="status_link active">
                    <span>합격 {item["SS50"]}명</span>
                  </div>
                  <div
                    onClick={() => {
                      setCurrentRecruit(item["recruit_seq"]);
                      setTabState(StatusPage.Rejected);
                    }}
                    className="status_link active">
                    <span>불합격 {item["SS99"]}명</span>
                  </div>
                </div>
                <div className="info_date">
                  <div className="info_txt">
                    등록일 <span className="bar"></span>
                    <span className="date">{new Date(item["reg_dtm"]).toLocaleDateString()}</span>{" "}
                  </div>
                  {item["close_type"] !== "ANY" && item["close_date"] && (
                    <div className="info_txt">
                      마감일 <span className="bar"></span>
                      <span className="date">{new Date(item["close_date"]).toLocaleDateString()}</span>{" "}
                    </div>
                  )}
                </div>
              </div>
            </li>
          ))
        )}
      </ul>
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(totalRecruitCount / recruitsPerPage)}
        onNextClick={handleNext}
        onPrevClick={handlePrevious}
        perPage={recruitsPerPage}
        handlePerPageChange={(e) => setRecruitsPerPage(e.target.value)}
      />
    </div>
  ) : (
    <RecruitStatus
      currentRecruit={currentRecruit}
      recruitStatus={tabState}
      goBack={async () => {
        setTabState(StatusPage.Root);
        await refetch();
      }}
    />
  );
}
