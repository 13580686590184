import React, { useCallback, useContext, useState } from "react";
import ms from "ms";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { mutateBackend } from "../../../../../api";
import { AuthContext } from "../../../../../context/Auth";
import { useCustomQuery } from "../../../../../hooks/useAPI";
import PopAgree from "../../components/PopAgreement";
import ScrollToTopButton from "../../components/miscellaneous/ScrollToTopButton";
import Modal from "../../components/modal";

function MentoringPay() {
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const { mento_seq } = useParams();

  const [mentoring, setMentoring] = useState();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const [usedPoints, setUsedPoints] = useState(0);

  const [agreeWithTerms, setAgreeWithTerms] = useState(false);
  const [modalAgreeOpen, setModalAgreeOpen] = useState(false);

  const { isFetching } = useCustomQuery("/mentoring/" + mento_seq, {
    queryKey: ["/mentoring/" + mento_seq],
    select: (res) => res?.data,
    onSuccess: (data) => setMentoring(data),
  });

  const { data: points = {} } = useCustomQuery(`/points/current`, {
    queryKey: ["getPoints", user.user_seq],
    select: (res) => res?.data,
  });

  console.log(points);

  const handleMentoringPurchase = async () => {
    const response = await mutateBackend("/mentoring/user/register", {
      data: {
        mento_seq: mento_seq,
        user_seq: user.user_seq,
        supp_meet_cnt: mentoring ? mentoring["mento_meet_cnt"] : null,
        end_date: mentoring ? { period: mentoring["mento_period"], unit: mentoring["period_unit"] } : {},
      },
      headers: { "Content-Type": "application/json" },
    });

    if (response.status === 200) {
      toast.success("멘토링 제품을 성공적으로 구매했습니다.");
      navigate(0);
      navigate(-1);
    } else {
      toast.error("제품을 구매하지 못했습니다.");
    }
    setIsSubmitting(false);
  };

  const handleUpdateUserPoint = async (payRes) => {
    const pointsResponse = await mutateBackend("/points", {
      data: {
        points_val: payRes?.request_amt,
        rel_tables: "ces_mentoring",
        rel_seq: mento_seq,
        points_desc: "상품구매",
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (data) => await data.json())
      .catch((err) => {
        setIsSubmitting(false);

        throw new Error(err);
      });
    if (pointsResponse.messageString === "SUCCESS" && pointsResponse.messageCode === 200) {
      console.log(pointsResponse.data);
      await handleMentoringPurchase();
    } else {
      setIsSubmitting(false);

      console.log(pointsResponse);
    }
    setIsSubmitting(false);
  };

  const handlePayment = useCallback(async () => {
    setIsSubmitting(true);

    const response = await mutateBackend("/payments", {
      data: {
        pay_status: "PS10",
        goods_type: "MENTO",
        goods_seq: mento_seq,
        goods_name: mentoring?.mento_title,
        dc_amt: 0,
        use_point: usedPoints,
        pay_method: "POINTS",
        pay_amt: 0,
        trans_id: "",
        join_num: 0,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (data) => await data.json())
      .catch((err) => {
        setIsSubmitting(false);

        throw new Error(err);
      });
    if (response.messageString === "SUCCESS" && response.messageCode === 200) {
      console.log(response.data);
      await handleUpdateUserPoint(response.data);
    } else {
      setIsSubmitting(false);

      console.log(response);
    }
  }, [mento_seq, mentoring, usedPoints]);

  if (isFetching || !mentoring)
    return (
      <main className="com_pay">
        <div className="box">
          <i className="gg-spinner-big" />
        </div>
      </main>
    );

  const unit = mentoring["period_unit"];
  const period = mentoring["mento_period"];

  const startDate = new Date().toISOString().split("T")[0];
  let endDate = null;

  if (unit == "day") endDate = new Date(new Date().getTime() + ms(`${period} days`)).toISOString().split("T")[0];
  else if (unit == "month")
    endDate = new Date(new Date().getTime() + ms(`${period * 30} days`)).toISOString().split("T")[0];

  return (
    <main className="com_pay">
      <h2 className="pg_tit">멘토링 결제하기</h2>
      <form
        className="box"
        onSubmit={async (e) => {
          e.preventDefault();
          if (agreeWithTerms) await handlePayment();
        }}>
        <ul className="com_info_sec">
          <li className="field_box">
            <span className="tit">멘토링 상품명</span>
            <div className="info_box">{mentoring["mento_title"]}</div>
          </li>
          <li className="field_box">
            <span className="tit">멘토링 서비스 상품</span>
            <div className="info_box">{mentoring["mento_service"]}</div>
          </li>
          <li className="field_box">
            <span className="tit">멘토</span>
            <div className="info_box">{mentoring["mento_name"]}</div>
          </li>
          <li className="field_box">
            <span className="tit">멘티</span>
            <div className="info_box">{user["user_name"]}</div>
          </li>
          <li className="field_box">
            <span className="tit">이메일</span>
            <div className="info_box">{user["user_email"]}</div>
          </li>
          <li className="field_box">
            <span className="tit">기간</span>
            <div className="info_box">
              {startDate} ~ {endDate}
            </div>
          </li>
          <li className="field_box">
            <span className="tit">결제금액</span>
            <div className="info_box">{mentoring["mento_amt"] ? `${mentoring["mento_amt"]}원` : "-"}</div>
          </li>
          <li className="field_box">
            <span className="tit">보유 포인트</span>
            <div className="info_box">{points.points || 0}P</div>
          </li>
          <li className="field_box">
            <span className="tit">이벤트 포인트</span>
            <div className="info_box">0P</div>
          </li>
          <li className="field_box">
            <span className="tit">사용 포인트</span>
            <div className="info_box">
              <input
                type="number"
                className="input_num"
                max={points.points ? points.points : 0}
                onChange={(e) => setUsedPoints(e.target.value)}
              />{" "}
              P <span className="block_m">(이벤트 포인트가 먼저 차감됩니다)</span>
            </div>
          </li>
          <li className="field_box">
            <span className="tit">총 결제금액</span>
            <div className="info_box">{mentoring["mento_amt"] ? `${mentoring["mento_amt"]}원` : "-"} </div>
          </li>
        </ul>
        <section className="com_agree_section">
          <ul className="list">
            <li className="item">
              <div onClick={() => setAgreeWithTerms((prev) => !prev)}>
                <input
                  type="checkbox"
                  className="com_chk"
                  name="agree01"
                  checked={agreeWithTerms}
                  onChange={() => setAgreeWithTerms((prev) => !prev)}
                />
                <label htmlFor="agree01">
                  구매조건 및 결제대행 서비스 약관에 동의하여야 합니다.<span className="txt_required">(필수)</span>{" "}
                  <br />
                  구매조건 및 개인정보취급방침에 동의합니다.
                </label>
              </div>
              <button className="com_btn txt" onClick={() => setModalAgreeOpen(true)}>
                자세히
              </button>
            </li>
          </ul>
        </section>
        <div className="com_btn_wrap center">
          <Link to="/Mentoring" className="com_btn line m">
            멘토 목록으로
          </Link>
          <button className="com_btn blue m" type="submit">
            결제하기 {isSubmitting && <i className="gg-spinner-alt" />}
          </button>
        </div>
      </form>
      {/* top button */}
      <ScrollToTopButton />

      {/*약관내용 팝업*/}
      <PopAgree open={modalAgreeOpen} close={() => setModalAgreeOpen(false)} title="이용약관" type="terms" />
      {/* alert */}
      <Modal open={modalOpen} close={handleCloseModal} type="type_alert">
        <div className="content_container scroll">
          <strong>멘토링 신청이 완료되었습니다!</strong> <br />
          멘토가 신청을 수락하면 멘토링이 시작됩니다
        </div>
        <div className="btn_container">
          <button className="com_btn blue s" onClick={() => handleCloseModal()}>
            확인
          </button>
        </div>
      </Modal>
    </main>
  );
}

export default MentoringPay;
